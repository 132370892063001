const sst_ple = [
    {
        "instructions": null,
        "questions": [
            {
                "question": "Name the type of climate described as hot and wet throughout the year",
                "type": "text",
                "answer": [
                    "Equatorial climate"
                ],
                "id": "ple_sst_sect_A1_1"
            },
            {
                "question": "Name the largest Island country in Africa",
                "type": "text",
                "answer": [
                    "Madagascar"
                ],
                "id": "ple_sst_sect_A1_2"
            },
            {
                "question": "Give any one reason why it is important to use a common language in a community",
                "type": "multipleChoice",
                "answer": [
                    "It promotes unity"
                ],
                "options": [
                    "It promotes unity",
                    "It helps to increase revenue"
                ],
                "id": "ple_sst_sect_A1_3"
            },
            {
                "question": "Mention any one practice that can promote order in a home",
                "type": "multipleChoice",
                "answer": [
                    "By respecting one another"
                ],
                "options": [
                    "By respecting one another",
                    "By fighting corruption"
                ],
                "id": "ple_sst_sect_A1_4"
            },
            {
                "question": "Name the type of rainfall received in the plain areas of Uganda",
                "type": "text",
                "answer": [
                    "Frontal rainfall",
                    "Cyclonic rainfall"
                ],
                "id": "ple_sst_sect_A1_5"
            },
            {
                "question": "Name any one document that can be used to identify Ugandan citizens.",
                "type": "text",
                "answer": [
                    "Passport",
                    "Identity card",
                    "National identity card"
                ],
                "id": "ple_sst_sect_A1_6"
            },
            {
                "question": "Name the largest fresh water lake found on the central plateau of Uganda.",
                "type": "text",
                "answer": [
                    "LakeVictoria"
                ],
                "id": "ple_sst_sect_A1_7"
            },
            {
                "question": "How can road transport across rivers and streams be made possible?",
                "type": "multipleChoice",
                "answer": [
                    "By the use of bridges."
                ],
                "options": [
                    "By the use of bridges.",
                    "By use of boats.",
                    "By the use of airplanes."
                ],
                "id": "ple_sst_sect_A1_8"
            },
            {
                "question": "Name any one domestic animal kept for milk.",
                "type": "text",
                "answer": [
                    "Cow",
                    "Goat",
                    "Camel",
                    "Sheep"
                ],
                "id": "ple_sst_sect_A1_9"
            },
            {
                "question": "What basic need is provided by dogs in a home?",
                "type": "text",
                "answer": [
                    "Security",
                    "Protection",
                    "Defence",
                    "Guarding"
                ],
                "id": "ple_sst_sect_A1_10"
            },
            {
                "question": "Name one inland port found on Lake Victoria in Uganda",
                "type": "text",
                "answer": [
                    "Port bell",
                    "Port Bukakata",
                    "Jinja port"
                ],
                "id": "ple_sst_sect_A1_11"
            },
            {
                "question": "Name the largest natural forest found in the central part of Uganda",
                "type": "text",
                "answer": [
                    "Mabira forest"
                ],
                "id": "ple_sst_sect_A1_12"
            },
            {
                "question": "What name is given to moving air?",
                "type": "text",
                "answer": [
                    "Wind"
                ],
                "id": "ple_sst_sect_A1_13"
            },
            {
                "question": "Name one of the human basic needs. ",
                "type": "text",
                "answer": [
                    "Food",
                    "Education",
                    "Water",
                    "Medical care",
                    "Shelter",
                    "Clothes"
                ],
                "id": "ple_sst_sect_A1_14"
            },
            {
                "question": "What helps a person to find the actual distance between two places on a map?",
                "type": "text",
                "answer": [
                    "Scale",
                    "A scale"
                ],
                "id": "ple_sst_sect_A1_15"
            },
            {
                "question": "State one problem affecting cattle keeping in Karamoja region.",
                "type": "multipleChoice",
                "answer": [
                    "Lack of enough water and pasture for the cattle."
                ],
                "options": [
                    "Foot and mouth disease.",
                    "Lack of enough water and pasture for the cattle.",
                    "Infertile soil."
                ],
                "id": "ple_sst_sect_A1_16"
            },
            {
                "question": "Apart from sunshine, give any one other element of weather which helps people to dry their clothes.",
                "type": "text",
                "answer": [
                    "Wind"
                ],
                "id": "ple_sst_sect_A1_17"
            },
            {
                "question": "What helps a pilot flying a plane from Arua to Entebbe to find the direction to Entebbe Airport?",
                "type": "text",
                "answer": [
                    "A compass",
                    "Compass"
                ],
                "id": "ple_sst_sect_A1_18"
            },
            {
                "question": "Maria was walking to a well at 5 pm when she saw her shadow on her right. To which compass direction was she walking?",
                "type": "multipleChoice",
                "answer": [
                    "She was walking to the north."
                ],
                "options": [
                    "She was walking to the east.",
                    "She was walking to the west.",
                    "She was walking to the north."
                ],
                "id": "ple_sst_sect_A1_19"
            }
        ],
        "category": 1,
        "$id": "document_0"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Mention any one place where people get spiritual guidance. ",
                "type": "text",
                "answer": [
                    "Church",
                    "Mosque",
                    "Shrine"
                ],
                "id": "ple_sst_sect_A2_1"
            },
            {
                "question": "What causes days and nights?",
                "type": "multipleChoice",
                "answer": [
                    "The rotation of the earth on its axis"
                ],
                "options": [
                    "The sun and the moon",
                    "The rotation of the earth on its axis"
                ],
                "id": "ple_sst_sect_A2_2"
            },
            {
                "question": "State any one way of caring for elderly people in our homes.",
                "type": "multipleChoice",
                "answer": [
                    "By washing for them clothes"
                ],
                "options": [
                    "By teaching them",
                    "By washing for them clothes"
                ],
                "id": "ple_sst_sect_A2_3"
            },
            {
                "question": "What is the major tourist attraction in Mgahinga National Game Park?",
                "type": "text",
                "answer": [
                    "Mountain Gorillas",
                    "Gorillas"
                ],
                "id": "ple_sst_sect_A2_4"
            },
            {
                "question": "State one advantage of a music festival in a community.",
                "type": "multipleChoice",
                "answer": [
                    "It develops talents"
                ],
                "options": [
                    "It helps to fight corruption",
                    "It develops talents"
                ],
                "id": "ple_sst_sect_A2_5"
            },
            {
                "question": "Mention any one group of people who bring goods nearer to your community.",
                "type": "text",
                "answer": [
                    "Farmers",
                    "Truck drivers",
                    "Traders"
                ],
                "id": "ple_sst_sect_A2_6"
            },
            {
                "question": "Name the system of government that is practised in Uganda today.",
                "type": "multipleChoice",
                "answer": [
                    "Presidential System"
                ],
                "options": [
                    "Parliamentary System",
                    "Presidential System",
                    "Federalism"
                ],
                "id": "ple_sst_sect_A2_7"
            },
            {
                "question": "What name is given to laws made and passed by local authorities?",
                "type": "text",
                "answer": [
                    "By-laws",
                    "Bylaws",
                    "By laws"
                ],
                "id": "ple_sst_sect_A2_8"
            },
            {
                "question": "What does the black color of the Uganda national flag represent?",
                "type": "multipleChoice",
                "answer": [
                    "The black color represents the African people."
                ],
                "options": [
                    "The black color color represents the sun.",
                    "The black color represents the African people.",
                    "The black color represents the brotherhood of the African people."
                ],
                "id": "ple_sst_sect_A2_9"
            },
            {
                "question": "Which landform covers most areas of Uganda?",
                "type": "text",
                "answer": [
                    "Plateaus",
                    "Plateau"
                ],
                "id": "ple_sst_sect_A2_10"
            },
            {
                "question": "Name the type of clouds that bring rain.",
                "type": "text",
                "answer": [
                    "Nimbus clouds",
                    "Nimbus"
                ],
                "id": "ple_sst_sect_A2_11"
            },
            {
                "question": "Give any one traditional means of communication.",
                "type": "text",
                "answer": [
                    "Drumming",
                    "Clapping",
                    "Ululation"
                ],
                "id": "ple_sst_sect_A2_12"
            },
            {
                "question": "Mention the traditional cash crop that grows well on volcanic soils of East Africa.",
                "type": "text",
                "answer": [
                    "Arabica coffee",
                    "Coffee"
                ],
                "id": "ple_sst_sect_A2_13"
            },
            {
                "question": "Name the main cash crop grown in Kumasi in Ghana.",
                "type": "text",
                "answer": [
                    "Cocoa"
                ],
                "id": "ple_sst_sect_A2_14"
            },
            {
                "question": "Which type of climate covers a greater part of Africa?",
                "type": "text",
                "answer": [
                    "Tropical climate"
                ],
                "id": "ple_sst_sect_A2_15"
            },
            {
                "question": "Name any one food crop that grows well in a swamp.",
                "type": "text",
                "answer": [
                    "Rice",
                    "Yams"
                ],
                "id": "ple_sst_sect_A2_16"
            },
            {
                "question": "Give any one importance of mountains to farmers who live near them.",
                "type": "multipleChoice",
                "answer": [
                    "Mountains lead to formation of relief rainfall and help in fromation of fertile soil."
                ],
                "options": [
                    "Mountains lead to formation of relief rainfall and help in fromation of fertile soil.",
                    "Mountains promote tourism.",
                    "Mountains often contain valuable mineral resources."
                ],
                "id": "ple_sst_sect_A2_17"
            },
            {
                "question": "Name the country that borders Uganda on the eastern side.",
                "type": "text",
                "answer": [
                    "Kenya"
                ],
                "id": "ple_sst_sect_A2_18"
            },
            {
                "question": "Apart from the school uniform, give any one symbol of a school.",
                "type": "text",
                "answer": [
                    "School badge"
                ],
                "id": "ple_sst_sect_A2_19"
            },
            {
                "question": "Which latitude is marked 0<sub>o</sub> on the map of Uganda?",
                "type": "text",
                "answer": [
                    "Equator",
                    "The equator"
                ],
                "id": "ple_sst_sect_A2_20"
            }
        ],
        "category": 2,
        "$id": "document_1"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "What was the main reason for the signing of Frere treaty?",
                "type": "multipleChoice",
                "answer": [
                    "To close all the slave markets in East Africa"
                ],
                "options": [
                    "To partition East Africa",
                    "To close all the slave markets in East Africa"
                ],
                "id": "ple_sst_sect_A11_1"
            },
            {
                "question": "Write down one similarity between the United Nations and the Commonwealth of nations",
                "type": "multipleChoice",
                "answer": [
                    "Both aim at promoting peace among member states"
                ],
                "options": [
                    "Both aim at fighting corruption",
                    "Both aim at promoting peace among member states"
                ],
                "id": "ple_sst_sect_A11_2"
            },
            {
                "question": "How does the distance of an area from large water bodies affect the climate of the surrounding land?",
                "type": "multipleChoice",
                "answer": [
                    "Areas near large waterbodies receive heavier rainfall than those far away"
                ],
                "options": [
                    "Areas near large waterbodies are always swampy",
                    "Areas near large waterbodies receive heavier rainfall than those far away"
                ],
                "id": "ple_sst_sect_A11_3"
            },
            {
                "question": "How are hills important to telecommunication companies in Uganda?",
                "type": "multipleChoice",
                "answer": [
                    "They promote the setting up of telecommunication masts"
                ],
                "options": [
                    "They are used to transmit communications",
                    "They promote the setting up of telecommunication masts"
                ],
                "id": "ple_sst_sect_A11_4"
            },
            {
                "question": "Name the latitude marked 0<sup>o</sup> in the diagram of the globe below",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2020_11.png",
                "type": "text",
                "answer": [
                    "Equator"
                ],
                "id": "ple_sst_sect_A11_5"
            },
            {
                "question": "Mention any one of the citrus fruits grown in the Mediterranean climate region of Africa",
                "type": "text",
                "answer": [
                    "Oranges",
                    "Lemons",
                    "Grapefruits",
                    "Limes"
                ],
                "id": "ple_sst_sect_A11_6"
            },
            {
                "question": "Mention the weather condition suitable for winnowing grains.",
                "type": "text",
                "answer": [
                    "Windy"
                ],
                "id": "ple_sst_sect_A11_7"
            },
            {
                "question": "State any one reason why early missionaries in Uganda taught people how to read and write",
                "type": "multipleChoice",
                "answer": [
                    "They wanted to spread christianity which required people to be able to read and write"
                ],
                "options": [
                    "They wanted to spread christianity which required people to be able to read and write",
                    "They wanted to colonise the Ugandans"
                ],
                "id": "ple_sst_sect_A11_8"
            },
            {
                "question": "Select any one way in which people benefit from small scale industries in their areas.",
                "type": "multipleChoice",
                "answer": [
                    "They help people to get jobs",
                    "They help people to have an income"
                ],
                "options": [
                    "They help people to get jobs",
                    "They provide medical services to people",
                    "They provide education to people",
                    "They help people to have an income"
                ],
                "id": "ple_sst_sect_A11_9"
            },
            {
                "question": "How does the  heading of a map help a map reader?",
                "type": "multipleChoice",
                "answer": [
                    "It tells a map reader what a map is all about"
                ],
                "options": [
                    "It helps the map reader to explain the symbols used on the map",
                    "It tells a map reader what a map is all about",
                    "It helps the reader to know the actual distance between points on the map"
                ],
                "id": "ple_sst_sect_A11_10"
            },
            {
                "question": "Name the country whose flag is shown below",
                "type": "text",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2014_11.png",
                "answer": [
                    "Uganda"
                ],
                "id": "ple_sst_sect_A11_11"
            },
            {
                "question": "Give any one reason why Sir Andrew Cohen forced Kabaka Muteesa II to go to exile",
                "type": "multipleChoice",
                "answer": [
                    "He resisted colonial rule"
                ],
                "options": [
                    "He refused the spread of christianity",
                    "He refused the spread of islam",
                    "He resisted colonial rule"
                ],
                "id": "ple_sst_sect_A11_12"
            },
            {
                "question": "Which major activity do farmers in Uganda carry out during a dry season?",
                "type": "text",
                "answer": [
                    "Harvesting",
                    "Preparing of land",
                    "Drying"
                ],
                "id": "ple_sst_sect_A11_13"
            },
            {
                "question": "Mention any one way in which farmers in the rural areas can be helped to transport their produce to bigger markets.",
                "type": "multipleChoice",
                "answer": [
                    "By improving road transport",
                    "By construct railway lines"
                ],
                "options": [
                    "By improving road transport",
                    "By building hospitals",
                    "By construct railway lines",
                    "By building schools"
                ],
                "id": "ple_sst_sect_A11_14"
            },
            {
                "question": "State the use of a wind-sock at a weather station.",
                "type": "multipleChoice",
                "answer": [
                    "To measure the direction of wind"
                ],
                "options": [
                    "To measure atmospeheric pressure",
                    "To measure the direction of wind",
                    "To measure the amount of rainfall"
                ],
                "id": "ple_sst_sect_A11_15"
            },
            {
                "question": "Why was Uganda known as a 'protectorate'?",
                "type": "multipleChoice",
                "answer": [
                    "Because it was ruled by colonialist on behalf of British government"
                ],
                "options": [
                    "Because it was the pearl of Africa",
                    "Because it was ruled by colonialist on behalf of British government"
                ],
                "id": "ple_sst_sect_A11_16"
            },
            {
                "question": "Mention one way in which the coming of Arabs affected the people of East Africa",
                "type": "multipleChoice",
                "answer": [
                    "They introduced new crops such as cloves to the people",
                    "They lead to the birth of Swahili language"
                ],
                "options": [
                    "They introduced new crops such as cloves to the people",
                    "They introduced christianity to the people",
                    "They lead to the birth of Swahili language"
                ],
                "id": "ple_sst_sect_A11_17"
            },
            {
                "question": "In Uganda, which type of coffee grows best in volcanic soil?",
                "type": "text",
                "answer": [
                    "Arabic coffee",
                    "Arabica coffee"
                ],
                "id": "ple_sst_sect_A11_18"
            },
            {
                "question": "How was indirect rule exercised by the colonialists in East Africa?",
                "type": "multipleChoice",
                "answer": [
                    "Indirect rule was exercised through local leaders and chief who supervised their subjects"
                ],
                "options": [
                    "Indirect rule was exercised through colonial administrators running the colony",
                    "Indirect rule was exercised through local leaders and chiefs who supervised their subjects"
                ],
                "id": "ple_sst_sect_A11_19"
            },
            {
                "question": "The three arms of government are: Legislature, Judiciary and executive. What is the function of the Executive?",
                "type": "multipleChoice",
                "answer": [
                    "The executive implements laws and defends government policies"
                ],
                "options": [
                    "The executive makes laws",
                    "The executive interpretes the laws",
                    "The executive implements laws and defends government policies"
                ],
                "id": "ple_sst_sect_A11_20"
            }
        ],
        "category": 11,
        "$id": "document_10"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "How has prolonged drought contributed to loss of lives in Uganda?",
                "type": "multipleChoice",
                "answer": [
                    "It has led to famine due to drying of crops"
                ],
                "options": [
                    "It has led to corruption in Uganda",
                    "It has led to famine due to drying of crops"
                ],
                "id": "ple_sst_sect_A12_1"
            },
            {
                "question": "How was the formation of lake Albert different from that of Lake Victoria?",
                "type": "multipleChoice",
                "answer": [
                    "Lake Albert was formed as a result of faulting while Lake Victoria was formed by down warping"
                ],
                "options": [
                    "Lake Albert was formed as a result of faulting while Lake Victoria was formed by down warping",
                    "Lake Albert was formed as a result of down warping while Lake Victoria was formed by faulting"
                ],
                "id": "ple_sst_sect_A12_2"
            },
            {
                "question": "Select any one contribution of United Nations Educational Scientific and Cultural Organisation (UNESCO) to the development of Uganda",
                "type": "multipleChoice",
                "answer": [
                    "It promotes education in Uganda"
                ],
                "options": [
                    "It promotes education in Uganda",
                    "It promotes security in Uganda"
                ],
                "id": "ple_sst_sect_A12_3"
            },
            {
                "question": "Mention any one effect of a long period of drought on agriculture",
                "type": "multipleChoice",
                "answer": [
                    "It dries up water sources"
                ],
                "options": [
                    "It dries up water sources",
                    "It creates infertile soils"
                ],
                "id": "ple_sst_sect_A12_4"
            },
            {
                "question": "Give the type of weather suitable for maize harvesting period",
                "type": "text",
                "answer": [
                    "Sunny weather",
                    "Sunny"
                ],
                "id": "ple_sst_sect_A12_5"
            },
            {
                "question": "State any one challenge that fishermen in Uganda face in carrying out their work.",
                "type": "multipleChoice",
                "answer": [
                    "They face a problem of unregulated and illegal fishing"
                ],
                "options": [
                    "They face a problem of a poor road network",
                    "They face a problem of unregulated and illegal fishing"
                ],
                "id": "ple_sst_sect_A12_6"
            },
            {
                "question": "What name is given to the type of budget shown below?",
                "type": "multipleChoice",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2018_11.png",
                "answer": [
                    "Balanced budget"
                ],
                "options": [
                    "Deficit budget",
                    "Balanced budget",
                    "Surplus budget"
                ],
                "id": "ple_sst_sect_A12_7"
            },
            {
                "question": "Name the landlocked country located in the horn of Africa.",
                "type": "text",
                "answer": [
                    "Ethiopia"
                ],
                "id": "ple_sst_sect_A12_8"
            },
            {
                "question": "Give any one reason why elections are important in a country",
                "type": "multipleChoice",
                "answer": [
                    "They help to promote democracy"
                ],
                "options": [
                    "They help to promote democracy",
                    "They help to reduce illiteracy rate in Uganda"
                ],
                "id": "ple_sst_sect_A12_9"
            },
            {
                "question": "How are trees in a school compound useful to pupils?",
                "type": "text",
                "answer": [
                    "They provide fruits",
                    "They provide shade",
                    "They provide shelter",
                    "They provide fresh air"
                ],
                "id": "ple_sst_sect_A12_10"
            },
            {
                "question": "How did F.D clerk contribute to the end of Apartheid rule In South Africa?",
                "type": "multipleChoice",
                "answer": [
                    "He allowed the anti-apartheid movements to operate"
                ],
                "options": [
                    "He did not allow blacks to participate national elections",
                    "He was a racist leader",
                    "He allowed the anti-apartheid movements to operate"
                ],
                "id": "ple_sst_sect_A12_11"
            },
            {
                "question": "Mention any one climatic condition which has enabled diary farming to be carried out in the Kenya Highlands.",
                "type": "text",
                "answer": [
                    "Cool climate",
                    "Cool temperature",
                    "High rainfall",
                    "Reliable rainfall"
                ],
                "id": "ple_sst_sect_A12_12"
            },
            {
                "question": "Select any one way in which wild animals are important to the people of East Africa.",
                "type": "multipleChoice",
                "answer": [
                    "They attract tourists",
                    "They are used for study purposes"
                ],
                "options": [
                    "They are used for medicine",
                    "They attract tourists",
                    "They are used for study purposes"
                ],
                "id": "ple_sst_sect_A12_13"
            },
            {
                "question": "Apart from rainy season, under which other weather condition would one use an umbrella?",
                "type": "text",
                "answer": [
                    "Sunny",
                    "Dry season"
                ],
                "id": "ple_sst_sect_A12_14"
            },
            {
                "question": "Which ministry is responsible for major roads in Uganda?",
                "type": "multipleChoice",
                "answer": [
                    "Ministry of works and transport"
                ],
                "options": [
                    "Ministry of health",
                    "Ministry of internal affairs",
                    "Ministry of works and transport"
                ],
                "id": "ple_sst_sect_A12_15"
            },
            {
                "question": "Why is Dr. Kwame Nkurumah remembered in Ghana?",
                "type": "multipleChoice",
                "answer": [
                    "He fought for the independence of Ghana."
                ],
                "options": [
                    "He fought for the independence of Ghana",
                    "He was a chief in Ghana",
                    "He was a famous poet in Ghana"
                ],
                "id": "ple_sst_sect_A12_16"
            },
            {
                "question": "State one way in which the police contributes to the welfare of the people in a community.",
                "type": "multipleChoice",
                "answer": [
                    "Police maintains law and order",
                    "It protects and guard people and their properties"
                ],
                "options": [
                    "It provides medical services to people",
                    "Police maintains law and order",
                    "It protects and guard people and their properties",
                    "It protects Uganda's borders"
                ],
                "id": "ple_sst_sect_A12_17"
            },
            {
                "question": "State any one economic advantage that Uganda gets as a result of being a member of the East African Community.",
                "type": "multipleChoice",
                "answer": [
                    "The East African Community provides a bigger market for Uganda goods"
                ],
                "options": [
                    "The East African Community provides a bigger market for Uganda goods",
                    "The East African Community provides security to Ugandans"
                ],
                "id": "ple_sst_sect_A12_18"
            },
            {
                "question": "Give any one reason why Libya, which is a desert is on of the richest countries in Africa.",
                "type": "multipleChoice",
                "answer": [
                    "Libya produces and sells petroleum oil and products"
                ],
                "options": [
                    "Libya sells agricultural products",
                    "Libya produces and sells petroleum oil and products"
                ],
                "id": "ple_sst_sect_A12_19"
            },
            {
                "question": "Give a reason why Mombosa is hotter than Kampala",
                "type": "multipleChoice",
                "answer": [
                    "Mombasa is at a lower altitude compared to Kampala"
                ],
                "options": [
                    "Mombasa is at a higher altitude compared to Kampala",
                    "Mombasa is at a lower altitude compared to Kampala"
                ],
                "id": "ple_sst_sect_A12_20"
            }
        ],
        "category": 12,
        "$id": "document_11"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "What happens to a law breaker when the court has proved him/ her guilty?",
                "type": "multipleChoice",
                "answer": [
                    "He or she is sentenced"
                ],
                "options": [
                    "He or she is sentenced to death",
                    "He or she is sentenced"
                ],
                "id": "ple_sst_sect_A13_1"
            },
            {
                "question": "What is meant by a tributary?",
                "type": "multipleChoice",
                "answer": [
                    "A tributary is a small river that joins the main river"
                ],
                "options": [
                    "A tributary is a big river that drains a small river",
                    "A tributary is a small river that joins the main river"
                ],
                "id": "ple_sst_sect_A13_2"
            },
            {
                "question": "How are rubber trees important to the transport sector in Africa",
                "type": "multipleChoice",
                "answer": [
                    "They provide latex used for making car tyres and tubes"
                ],
                "options": [
                    "They provide employment to people in Africa",
                    "They provide latex used for making car tyres and tubes"
                ],
                "id": "ple_sst_sect_A13_3"
            },
            {
                "question": "Name the arm of the Great Rift Valley which passes through Uganda",
                "type": "text",
                "answer": [
                    "Western arm",
                    "Western rift valley"
                ],
                "id": "ple_sst_sect_A13_4"
            },
            {
                "question": "State any one resource we get from water bodies",
                "type": "text",
                "answer": [
                    "Fish",
                    "Papyrus",
                    "Clay",
                    "Sand",
                    "Salt"
                ],
                "id": "ple_sst_sect_A13_5"
            },
            {
                "question": "What does the symbol below represent on the Uganda National Coat of Arms?",
                "type": "multipleChoice",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2019_13.png",
                "answer": [
                    "The drum represents the cultural heritage of Uganda"
                ],
                "options": [
                    "It represents the willingness of the Ugandan people to defend their country",
                    "The drum represents the cultural heritage of Uganda",
                    "It represents a new era, hope and optimism"
                ],
                "id": "ple_sst_sect_A13_6"
            },
            {
                "question": "What is the advantage of having a balanced budget?",
                "type": "multipleChoice",
                "answer": [
                    "It helps to reduce excessive spending"
                ],
                "options": [
                    "It helps to reduce excessive spending",
                    "It helps to have extra income"
                ],
                "id": "ple_sst_sect_A13_7"
            },
            {
                "question": "State any one way in which the government of Uganda benefits from mobile telephone companies.",
                "type": "multipleChoice",
                "answer": [
                    "They help to improve communication",
                    "The government collects taxes from mobile telephone companies"
                ],
                "options": [
                    "They help to improve communication",
                    "The government collects taxes from mobile telephone companies",
                    "They provide education services to the people of Uganda"
                ],
                "id": "ple_sst_sect_A13_8"
            },
            {
                "question": "Give any one reason why the British colonialists promoted road construction in Uganda.",
                "type": "multipleChoice",
                "answer": [
                    "To improve on the transport infrastructure to support the movement of people and goods."
                ],
                "options": [
                    "To improve on the education services in Uganda",
                    "To improve on the transport infrastructure to support the movement of people and goods."
                ],
                "id": "ple_sst_sect_A13_9"
            },
            {
                "question": "What was the major item of trade from Africa to America during the triangular trade (Trans Atlantic Trade)?",
                "type": "text",
                "answer": [
                    "Slaves",
                    "People",
                    "Human beings"
                ],
                "id": "ple_sst_sect_A13_10"
            },
            {
                "question": "Give any one service being provided by Uganda National Roads Authority (UNRA)",
                "type": "text",
                "answer": [
                    "Construction of roads",
                    "Construction of bridges",
                    "Putting road signs",
                    "Mapping roads"
                ],
                "id": "ple_sst_sect_A13_11"
            },
            {
                "question": "Apart from water,give any one other commodity that can be transported using a pipe-line.",
                "type": "text",
                "answer": [
                    "Oil",
                    "Crude oil",
                    "Gas"
                ],
                "id": "ple_sst_sect_A13_12"
            },
            {
                "question": "Which king in Uganda did Captain Lugard sign an agreement with in 1890?",
                "type": "text",
                "answer": [
                    "Kabaka Mwanga",
                    "Mwanga",
                    "Ssekabaka Mwanga"
                ],
                "id": "ple_sst_sect_A13_13"
            },
            {
                "question": "How are forests important to capenters?",
                "type": "multipleChoice",
                "answer": [
                    "They provide them with timber",
                    "They provide them with wood"
                ],
                "options": [
                    "They provide them with timber",
                    "They provide them with shelter",
                    "They provide them with wood",
                    "They provide them with firewood"
                ],
                "id": "ple_sst_sect_A13_14"
            },
            {
                "question": "What kind of vegetation mostly provides food fro mountain gorillas?",
                "type": "text",
                "answer": [
                    "Forest vegetation",
                    "Tree vegetation"
                ],
                "id": "ple_sst_sect_A13_15"
            },
            {
                "question": "Mention one reason why goats are more common than cattle in most parts of East Africa.",
                "type": "multipleChoice",
                "answer": [
                    "They are cheaper than cattle to purchase"
                ],
                "options": [
                    "They need large areas to look after",
                    "They are cheaper than cattle to purchase",
                    "They take more time to mature and reproduce."
                ],
                "id": "ple_sst_sect_A13_16"
            },
            {
                "question": "Give one contribution made by Omukama Kasagama of Toro in the colonsation of Uganda by the British.",
                "type": "multipleChoice",
                "answer": [
                    "Omukama Kasagama led the signing of Toro agreement"
                ],
                "options": [
                    "Omukama Kasagama led the signing of 1900 agreement",
                    "Omukama Kasagama led the signing of Toro agreement"
                ],
                "id": "ple_sst_sect_A13_17"
            },
            {
                "question": "Which type of climate fovours the growing of citrus fruits?",
                "type": "text",
                "answer": [
                    "Mediterranean climate"
                ],
                "id": "ple_sst_sect_A13_18"
            },
            {
                "question": "Select any one reason why the British chose to settle in Kenya.",
                "type": "multipleChoice",
                "answer": [
                    "The presence of fertile soil on the Kenyan highlands",
                    "The of presence cool and conducive climate on the Kenyan highlands"
                ],
                "options": [
                    "The presence of the warm climate in Kenya",
                    "The presence of fertile soil on the Kenyan highlands",
                    "The of presence cool and conducive climate on the Kenyan highlands",
                    "The presence of fresh water from River Nile in Kenya"
                ],
                "id": "ple_sst_sect_A13_19"
            },
            {
                "question": "Give any one way in which an oasis is important to the people who live in Sahara desert.",
                "type": "multipleChoice",
                "answer": [
                    "An oasis is a source of water for people living in the Sahara desert"
                ],
                "options": [
                    "An oasis provides protection from the strong winds",
                    "An oasis is a source of water for people living in the Sahara desert"
                ],
                "id": "ple_sst_sect_A13_20"
            }
        ],
        "category": 13,
        "$id": "document_12"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "How did the Namirembe agreement bring back happiness in the Buganda kingdom?",
                "type": "multipleChoice",
                "answer": [
                    "It confirmed the return of Kabaka Muteesa II from exile"
                ],
                "options": [
                    "It confirmed the return of Kabaka Muteesa II from exile",
                    "It confirmed the end of slave trade in Buganda kingdom"
                ],
                "id": "ple_sst_sect_A14_1"
            },
            {
                "question": "Why was the Berlin conference of 1884 held? ",
                "type": "multipleChoice",
                "answer": [
                    "To find peaceful means of acquiring colonies in Africa"
                ],
                "options": [
                    "To end slave trade in Africa",
                    "To find peaceful means of acquiring colonies in Africa"
                ],
                "id": "ple_sst_sect_A14_2"
            },
            {
                "question": "Give one reason why plants in desert areas have deep roots",
                "type": "multipleChoice",
                "answer": [
                    "To absorb nutrients from the ground"
                ],
                "options": [
                    "To absorb nutrients from the ground",
                    "To be firm in the ground"
                ],
                "id": "ple_sst_sect_A14_3"
            },
            {
                "question": "State any one advantage of having an Environment Management Club in a school",
                "type": "multipleChoice",
                "answer": [
                    "It promotes sanitation"
                ],
                "options": [
                    "It promotes wild life",
                    "It promotes sanitation"
                ],
                "id": "ple_sst_sect_A14_4"
            },
            {
                "question": "Give any one reason why places that have many economic activities attract a high population",
                "type": "multipleChoice",
                "answer": [
                    "They have many employment opportunities"
                ],
                "options": [
                    "They have many slums",
                    "They have many employment opportunities"
                ],
                "id": "ple_sst_sect_A14_5"
            },
            {
                "question": "How did Semei Kakungulu contribute to the development of Eastern Uganda during colonial rule?",
                "type": "multipleChoice",
                "answer": [
                    "He planted mivule trees in Eastern Uganda"
                ],
                "options": [
                    "He signed the 1900 Buganda agreement",
                    "He planted mivule trees in Eastern Uganda"
                ],
                "id": "ple_sst_sect_A14_6"
            },
            {
                "question": "Give any one reason whay a health centre should have an Ambulance.",
                "type": "multipleChoice",
                "answer": [
                    "It helps to transport patients to the hospital"
                ],
                "options": [
                    "It transports medicine to the hospital",
                    "It transports doctors to the hospital",
                    "It helps to transport patients to the hospital"
                ],
                "id": "ple_sst_sect_A14_7"
            },
            {
                "question": "Name the line of latitude where Equinox is experienced",
                "type": "text",
                "answer": [
                    "The equator",
                    "Equator"
                ],
                "id": "ple_sst_sect_A14_8"
            },
            {
                "question": "Why are Ugandans below 18 years not allowed to participate in national elections?",
                "type": "multipleChoice",
                "answer": [
                    "Because they are not mature enough at 18"
                ],
                "options": [
                    "Because they are not adults at1 8",
                    "Because they can easily make decisions"
                ],
                "id": "ple_sst_sect_A14_9"
            },
            {
                "question": "Give any one main crop grown in Doha Irrigation Scheme",
                "type": "text",
                "answer": [
                    "Rice"
                ],
                "id": "ple_sst_sect_A14_10"
            },
            {
                "question": " State any one economic importance of River Tana to Kenya",
                "type": "multipleChoice",
                "answer": [
                    "It provides water for irrigation",
                    "It is a source of fish for Kenyans"
                ],
                "options": [
                    "It provides water for irrigation",
                    "It acts as a border between Kenya and Tanzania",
                    "It is a source of fish for Kenyans"
                ],
                "id": "ple_sst_sect_A14_11"
            },
            {
                "question": "Select any one way in which game parks promote economic development in Africa.",
                "type": "multipleChoice",
                "answer": [
                    "Through tourism",
                    "Through employment"
                ],
                "options": [
                    "Through tourism",
                    "Through providing safe and clean water",
                    "Through employment",
                    "Through better education services"
                ],
                "id": "ple_sst_sect_A14_12"
            },
            {
                "question": "Select any one reason why persons aged 18 and above take part in National elections.",
                "type": "multipleChoice",
                "answer": [
                    "They are considered adults"
                ],
                "options": [
                    "They are considered adults",
                    "They are easily influenced",
                    "They are not responsible enough"
                ],
                "id": "ple_sst_sect_A14_13"
            },
            {
                "question": "Ssendawula was facing the North. He turned clockwise though 90<sup>o</sup>. What was his new direction?",
                "type": "text",
                "answer": [
                    "East",
                    "Eastern",
                    "Eastward"
                ],
                "id": "ple_sst_sect_A14_14"
            },
            {
                "question": "How were the British colonialists able to communicate with the local people?",
                "type": "multipleChoice",
                "answer": [
                    "They used interpreters",
                    "Through collaborators"
                ],
                "options": [
                    "They taught the local people their language",
                    "They used interpreters",
                    "Through collaborators"
                ],
                "id": "ple_sst_sect_A14_15"
            },
            {
                "question": "Give one advantage of exporting processed goods other than raw materials.",
                "type": "multipleChoice",
                "answer": [
                    "Processed goods earn more revenue compared to unprocessed goods",
                    "Processed good last longer"
                ],
                "options": [
                    "Processed goods earn more revenue compared to unprocessed goods",
                    "Processed goods are expensive to produce",
                    "Processed good last longer"
                ],
                "id": "ple_sst_sect_A14_16"
            },
            {
                "question": "Mention the instrument that is used to measure the amount of rainfall at a weather station.",
                "type": "text",
                "answer": [
                    "Rain gauge",
                    "Rainfall gauge"
                ],
                "id": "ple_sst_sect_A14_17"
            },
            {
                "question": "Give any one reason why the people of Northern Nigeria practice nomadic pastrolism.",
                "type": "multipleChoice",
                "answer": [
                    "Northern Nigeria is dry which forces them to move from one place to another looking for pasture and water"
                ],
                "options": [
                    "Northern Nigeria is dry which forces them to move from one place to another looking for pasture and water",
                    "Nothern Nigeria has plenty of natural vegetation"
                ],
                "id": "ple_sst_sect_A14_18"
            },
            {
                "question": "State any one reason why some children have left their homes to go and live on the streets in towns",
                "type": "text",
                "answer": [
                    "Instability",
                    "Poverty"
                ],
                "id": "ple_sst_sect_A14_19"
            },
            {
                "question": "State any one reason why some traditional leaders in Uganda did not like missionaries.",
                "type": "multipleChoice",
                "answer": [
                    "The missionaries were preaching against their religions"
                ],
                "options": [
                    "The missionaries wanted to take away their land",
                    "The missionaries wanted to take away their kingdoms",
                    "The missionaries were preaching against their religions"
                ],
                "id": "ple_sst_sect_A14_20"
            }
        ],
        "category": 14,
        "$id": "document_13"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "State any one role played by civil servants",
                "type": "multipleChoice",
                "answer": [
                    "To implement government programs"
                ],
                "options": [
                    "To implement laws",
                    "To implement government programs"
                ],
                "id": "ple_sst_sect_A15_1"
            },
            {
                "question": "How can the problem of land shortage be solved in most urban areas?",
                "type": "multipleChoice",
                "answer": [
                    "By constructing storeyed houses"
                ],
                "options": [
                    "By constructing storeyed houses",
                    "By telling people to leave urban areas"
                ],
                "id": "ple_sst_sect_A15_2"
            },
            {
                "question": "What role did Ludwig Krapf play towards the spread of Christianity in East Africa",
                "type": "multipleChoice",
                "answer": [
                    "He translated the New Testament into Kiswahili"
                ],
                "options": [
                    "He built Fort Jesus",
                    "He translated the New Testament into Kiswahili"
                ],
                "id": "ple_sst_sect_A15_3"
            },
            {
                "question": "Give any one way in which Ugandans benefitted from representation on the Legislative Council (LEGCO) during the struggle for independence",
                "type": "multipleChoice",
                "answer": [
                    "Their views were voiced out"
                ],
                "options": [
                    "They got independence",
                    "Their views were voiced out"
                ],
                "id": "ple_sst_sect_A15_4"
            },
            {
                "question": "Why are warm ocean currents important to the areas along the coast of Africa?",
                "type": "multipleChoice",
                "answer": [
                    "They bring rainfall to the coast of Africa"
                ],
                "options": [
                    "They make the weather favourable at the coast of Africa",
                    "They bring rainfall to the coast of Africa"
                ],
                "id": "ple_sst_sect_A15_5"
            },
            {
                "question": "Mention any one danger that children living on streets face.",
                "type": "multipleChoice",
                "answer": [
                    "They lack basic needs"
                ],
                "options": [
                    "They lack basic needs",
                    "They face a problem of corrupt officials"
                ],
                "id": "ple_sst_sect_A15_6"
            },
            {
                "question": "State any one way in which a National constitution promotes people's rights",
                "type": "text",
                "answer": [
                    "It outlines and protects the people's human rights"
                ],
                "id": "ple_sst_sect_A15_7"
            },
            {
                "question": "Why are pipelines the best means of transporting water to homes?",
                "type": "multipleChoice",
                "answer": [
                    "They are safe since they protect the water from external threats"
                ],
                "options": [
                    "They are safe since they protect the water from external threats",
                    "They are cheap to setup"
                ],
                "id": "ple_sst_sect_A15_8"
            },
            {
                "question": "Name the continent which is not suitable for human settlement",
                "type": "text",
                "answer": [
                    "Antarctica"
                ],
                "id": "ple_sst_sect_A15_9"
            },
            {
                "question": "State any one way in which democracy is practiced in schools",
                "type": "multipleChoice",
                "answer": [
                    "By electing prefects"
                ],
                "options": [
                    "By electing teaching",
                    "By electing prefects"
                ],
                "id": "ple_sst_sect_A15_10"
            },
            {
                "question": "Mention the first form of currency to be used in East Africa.",
                "type": "text",
                "answer": [
                    "Cowrie shells"
                ],
                "id": "ple_sst_sect_A15_11"
            },
            {
                "question": "State any one problem farmers may face as a result of cutting down forests.",
                "type": "text",
                "answer": [
                    "Soil erosion",
                    "Drought",
                    "Loss of soils fertility"
                ],
                "id": "ple_sst_sect_A15_12"
            },
            {
                "question": "Apart from cultivating crops and grazing animals, mention any other way the people of Uganda use land to earn a living",
                "type": "text",
                "answer": [
                    "Mining",
                    "Building",
                    "Poultry keeping",
                    "Urbanization",
                    "Bee keeping",
                    "Road construction"
                ],
                "id": "ple_sst_sect_A15_13"
            },
            {
                "question": "Give any one danger of fishing using poison.",
                "type": "text",
                "answer": [
                    "Death of people",
                    "Extinction of fish",
                    "Water pollution",
                    "Loss of income"
                ],
                "id": "ple_sst_sect_A15_14"
            },
            {
                "question": "Why was the Berlin conference important to the European countries?",
                "type": "multipleChoice",
                "answer": [
                    "It helped them to acquire colonies in Africa peacefully"
                ],
                "options": [
                    "It helped them to acquire colonies in Africa peacefully",
                    "It helped to stop the colonization of Africa",
                    ""
                ],
                "id": "ple_sst_sect_A15_15"
            },
            {
                "question": "How can a small scale sugarcane grower in Jinja benefit from the nearby Kakira sugar factory?",
                "type": "multipleChoice",
                "answer": [
                    "It provides market to sugar cane from out growers",
                    "Kakira sugar factory provide employment to the workers"
                ],
                "options": [
                    "It provides education services to the sugarcane grower in Jinja",
                    "It provides market to sugar cane from out growers",
                    "Kakira sugar factory provide employment to the workers",
                    "It provides health services to the sugarcane grower in Jinja"
                ],
                "id": "ple_sst_sect_A15_16"
            },
            {
                "question": "Name the ethic group of people who occupy the North- Eastern part of Uganda.",
                "type": "text",
                "answer": [
                    "The Nile Hamites",
                    "Nile Hamites"
                ],
                "id": "ple_sst_sect_A15_17"
            },
            {
                "question": "State any one way in which traditional leaders are important in Uganda.",
                "type": "multipleChoice",
                "answer": [
                    "They preside over traditional ceremonies and help settle disputes among clans"
                ],
                "options": [
                    "They head different regions in the country",
                    "They preside over traditional ceremonies and help settle disputes among clans"
                ],
                "id": "ple_sst_sect_A15_18"
            },
            {
                "question": "How was the Union Jack (British National Flag) treated on the 9<sup>th</sup> October 1962?",
                "type": "multipleChoice",
                "answer": [
                    "It was lowered and the Uganda flag was raised symbolizing Uganda’s Independence"
                ],
                "options": [
                    "It was raised symbolizing Uganda’s Independence",
                    "It was lowered and the Uganda flag was raised symbolizing Uganda’s Independence"
                ],
                "id": "ple_sst_sect_A15_19"
            }
        ],
        "category": 15,
        "$id": "document_14"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Name the body responsible for conserving the environment",
                "type": "text",
                "answer": [
                    "National Environment Management Authority",
                    "NEMA"
                ],
                "id": "ple_sst_sect_A16_1"
            },
            {
                "question": "Under which ministry is the Uganda People’s Defence Forces (UPDF)?",
                "type": "text",
                "answer": [
                    "Ministry of Defence and Veteran Affairs"
                ],
                "id": "ple_sst_sect_A16_2"
            },
            {
                "question": "State any one way in which a high population may negatively affect the provision of social services in an area",
                "type": "multipleChoice",
                "answer": [
                    "It leads to shortage of social services"
                ],
                "options": [
                    "It leads to shortage of labour",
                    "It leads to shortage of social services"
                ],
                "id": "ple_sst_sect_A16_3"
            },
            {
                "question": "Name the line of longitude that passes through Accra in Ghana",
                "type": "text",
                "answer": [
                    "Prime meridian",
                    "Greenwich meridian"
                ],
                "id": "ple_sst_sect_A16_4"
            },
            {
                "question": "How did the early explorers promote the work of European traders in Africa?",
                "type": "multipleChoice",
                "answer": [
                    "The explorers drew sketch maps about the interior of Africa showing trade routes"
                ],
                "options": [
                    "The explorers drew sketch maps about the interior of Africa showing trade routes",
                    "The explorers practiced slave trade to boost economic growth"
                ],
                "id": "ple_sst_sect_A16_5"
            },
            {
                "question": "State any one way in which human labour is important in an industry",
                "type": "multipleChoice",
                "answer": [
                    "Human labor is crucial for innovation and creativity within an industry"
                ],
                "options": [
                    "Human labor is a source of foreign exchange",
                    "Human labor is crucial for innovation and creativity within an industry"
                ],
                "id": "ple_sst_sect_A16_6"
            },
            {
                "question": "How does altitude influence the climate of an area?",
                "type": "multipleChoice",
                "answer": [
                    "Temperature decreases with increase in altitude"
                ],
                "options": [
                    "Temperature increases with increase in altitude",
                    "Atmospheric pressure icreases with higher altitude",
                    "Higher altitudes may experience higher humidity levels"
                ],
                "id": "ple_sst_sect_A16_7"
            },
            {
                "question": "Give any one danger of fishing using undersized nets.",
                "type": "text",
                "answer": [
                    "Overfishing",
                    "Illegal and unregulated Fishing"
                ],
                "id": "ple_sst_sect_A16_8"
            },
            {
                "question": "Name the country that borders Uganda in the East",
                "type": "text",
                "answer": [
                    "Kenya"
                ],
                "id": "ple_sst_sect_A16_9"
            },
            {
                "question": "What does the road sign shown below mean?",
                "type": "text",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2015_16.png",
                "answer": [
                    "Humps ahead",
                    "Humps"
                ],
                "id": "ple_sst_sect_A16_10"
            },
            {
                "question": "Mention any one way in which Prince Henry the Navigator was useful to the Portuguese explorers.",
                "type": "text",
                "answer": [
                    "He invented the compass",
                    "He sponsored the explorers",
                    "He built ships",
                    "He started a school for navigation"
                ],
                "id": "ple_sst_sect_A16_11"
            },
            {
                "question": "Select any one similarity between the East Africa Community (EAC)  and the Economic Community of West Africa States ( ECOWAS)",
                "type": "multipleChoice",
                "answer": [
                    "Both centre on trade",
                    "Both emphasize on removal of tariffs",
                    "Both promote regional cooperation"
                ],
                "options": [
                    "Both deal in oil exports",
                    "Both centre on trade",
                    "Both emphasize on removal of tariffs",
                    "Both promote regional cooperation"
                ],
                "id": "ple_sst_sect_A16_12"
            },
            {
                "question": "State any one reason why Africa countries are not developing at the same level.",
                "type": "text",
                "answer": [
                    "Difference in literacy",
                    "Difference in distribution of natural resources",
                    "Difference in location"
                ],
                "options": [
                    ""
                ],
                "id": "ple_sst_sect_A16_13"
            },
            {
                "question": "Select any one way in which pupils keep laws and order in the school.",
                "type": "multipleChoice",
                "answer": [
                    "By following the timetable",
                    "By observing school rules and regulations"
                ],
                "options": [
                    "By following the timetable",
                    "By observing school rules and regulations",
                    "By organising elections"
                ],
                "id": "ple_sst_sect_A16_14"
            },
            {
                "question": "Apart from the Judiciary and the Executive. Name one other arm of government.",
                "type": "text",
                "answer": [
                    "The legislator",
                    "Parliament",
                    "National Assembly"
                ],
                "id": "ple_sst_sect_A16_15"
            },
            {
                "question": "What is Bwindi National Park famous for?",
                "type": "multipleChoice",
                "answer": [
                    "It is famous for mountain gorillas"
                ],
                "options": [
                    "It is famous for Chimpanzee",
                    "It is famous for mountain gorillas"
                ],
                "id": "ple_sst_sect_A16_16"
            },
            {
                "question": "Why is the symbol below important on a map?",
                "type": "multipleChoice",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2008_16.png",
                "answer": [
                    "It tells the direction and the location of a certain place from another"
                ],
                "options": [
                    "It explains symbols on a map",
                    "It tells the direction and the location of a certain place from another",
                    "It tells what the map is about"
                ],
                "id": "ple_sst_sect_A16_17"
            },
            {
                "question": "State any one bad fishing practice in Uganda.",
                "type": "text",
                "answer": [
                    "Catching Immature fish",
                    "Catching fish by poison"
                ],
                "id": "ple_sst_sect_A16_18"
            },
            {
                "question": "Which organization has been replaced by the African Union?",
                "type": "text",
                "answer": [
                    "The Organization of African Unity",
                    "Organization of African Unity",
                    "OAU"
                ],
                "id": "ple_sst_sect_A16_19"
            },
            {
                "question": "How does climate affect people’s way of dressing?",
                "type": "multipleChoice",
                "answer": [
                    "When it is hot",
                    "people wear light clothes whereas when it is cold",
                    "people wear heavy clothes to provide them warmth"
                ],
                "options": [
                    "When it is hot people wear light clothes whereas when it is cold people wear heavy clothes to provide them warmth",
                    "When it is hot people wear heavy clothes to provide them warmth whereas when it is cold people wear light clothes"
                ],
                "id": "ple_sst_sect_A16_20"
            }
        ],
        "category": 16,
        "$id": "document_15"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Give the importance of having voluntary workers in the district.",
                "type": "multipleChoice",
                "answer": [
                    "They provide free services to people"
                ],
                "options": [
                    "They help to fight corruption",
                    "They provide free services to people"
                ],
                "id": "ple_sst_sect_A17_1"
            },
            {
                "question": "How did Omukama Kasagama benefit from the coming Captain F.D Lugard?",
                "type": "multipleChoice",
                "answer": [
                    "Captain F.D Lugard restored Omukama Kasagama to his throne"
                ],
                "options": [
                    "Captain F.D Lugard restored Omukama Kasagama to his throne",
                    "Captain F.D Lugard built schools in Omukama Kasagama's kingdom"
                ],
                "id": "ple_sst_sect_A17_2"
            },
            {
                "question": "How has the government of Uganda helped the youths to improve their standard of living?",
                "type": "multipleChoice",
                "answer": [
                    "It has given loans to the youth"
                ],
                "options": [
                    "It has given loans to the youth",
                    "It has fought corruption"
                ],
                "id": "ple_sst_sect_A17_3"
            },
            {
                "question": "State any one reason why most pastoral communities are settled in the plains",
                "type": "multipleChoice",
                "answer": [
                    "Due to the presence of enough pasture for rearing cattle"
                ],
                "options": [
                    "Due to the presence of enough pasture for rearing cattle",
                    "The scarcity of land and spaces in other areas"
                ],
                "id": "ple_sst_sect_A17_4"
            },
            {
                "question": "How does a good road network promote development in an area?",
                "type": "multipleChoice",
                "answer": [
                    "It promotes trade"
                ],
                "options": [
                    "It helps to increase taxes",
                    "It promotes trade"
                ],
                "id": "ple_sst_sect_A17_5"
            },
            {
                "question": "Mention any one cause of domestic violence in our community.",
                "type": "text",
                "answer": [
                    "Poverty",
                    "Substance abuse",
                    "Personality disorders",
                    "Madness"
                ],
                "id": "ple_sst_sect_A17_6"
            },
            {
                "question": "Why was Africa called a dark continent by the Europeans?",
                "type": "multipleChoice",
                "answer": [
                    "Because it was unknown to them"
                ],
                "options": [
                    "Because it had black people living on it",
                    "Because it was unknown to them"
                ],
                "id": "ple_sst_sect_A17_7"
            },
            {
                "question": "Mention any one human activity that affects wild life.",
                "type": "text",
                "answer": [
                    "Deforestration",
                    "Farming",
                    "Road and dam building",
                    "Pesticide use"
                ],
                "id": "ple_sst_sect_A17_8"
            },
            {
                "question": "Name any one river that flows out lake kyoga",
                "type": "text",
                "answer": [
                    "Victoria Nile",
                    "River Nile"
                ],
                "id": "ple_sst_sect_A17_9"
            },
            {
                "question": "Mention any one way in which Africans showed that they were not happy with the European rule.",
                "type": "text",
                "answer": [
                    "They staged rebellions and riots",
                    "They formed political parties"
                ],
                "id": "ple_sst_sect_A17_10"
            },
            {
                "question": "Name the archeological site in Eastern Uganda where rock paintings are found.",
                "type": "text",
                "answer": [
                    "Nyero",
                    "Nyero rock paintings"
                ],
                "id": "ple_sst_sect_A17_11"
            },
            {
                "question": "Why were the caravans helpful to Arab traders in East Africa?",
                "type": "multipleChoice",
                "answer": [
                    "They acted as security against robbers",
                    "They carried trade goods in large quantities"
                ],
                "options": [
                    "They acted as security against robbers",
                    "They helped to end slave trade",
                    "They carried trade goods in large quantities"
                ],
                "id": "ple_sst_sect_A17_12"
            },
            {
                "question": "What is an import tax?",
                "type": "multipleChoice",
                "answer": [
                    "It is tax charged on imported goods"
                ],
                "options": [
                    "It is tax charged on exported goods",
                    "It is tax charged on imported goods"
                ],
                "id": "ple_sst_sect_A17_13"
            },
            {
                "question": "Name any one united Nations organization that has promoted medical treatment of children in Uganda today.",
                "type": "text",
                "answer": [
                    "World health organization",
                    "WHO",
                    "United Nations International children’s fund",
                    "UNICEF"
                ],
                "id": "ple_sst_sect_A17_14"
            },
            {
                "question": "Give any one way in which a time table puts order in a class",
                "type": "multipleChoice",
                "answer": [
                    "It helps to avoid collision of lessons",
                    "It helps in proper time management",
                    "It helps children and teachers to prepare for the next lesson"
                ],
                "options": [
                    "It helps to avoid collision of lessons",
                    "It helps in proper time management",
                    "It assigns different responsibilities to different pupils",
                    "It helps children and teachers to prepare for the next lesson",
                    "It helps to control late coming to class"
                ],
                "id": "ple_sst_sect_A17_15"
            },
            {
                "question": "Give one reason why the people living in the Sahara desert wear turbans on their heads.",
                "type": "multipleChoice",
                "answer": [
                    "To protect them from direct heat of the sun"
                ],
                "options": [
                    "To protect them from direct heat of the sun",
                    "Because they want to look good"
                ],
                "id": "ple_sst_sect_A17_16"
            },
            {
                "question": "In which way is the formation of Lake kyoga different from that lake of Edward?",
                "type": "multipleChoice",
                "answer": [
                    "Lake Kyoga was formed by down warping while Lake Edward was formed by faulting"
                ],
                "options": [
                    "Lake Kyoga was formed by faulting while Lake Edward was formed by down warping",
                    "Lake Kyoga was formed by down warping while Lake Edward was formed by faulting"
                ],
                "id": "ple_sst_sect_A17_17"
            },
            {
                "question": " Give any one advantage of growing crops by irrigation.",
                "type": "multipleChoice",
                "answer": [
                    "It helps to produce crops throughout the year without depending on rain season"
                ],
                "options": [
                    "It helps to produce crops throughout the year without depending on rain season",
                    "It helps to reduce soil erosion",
                    "It helps to reduce soil depletion"
                ],
                "id": "ple_sst_sect_A17_18"
            },
            {
                "question": "Give any one reason why a radio is better than a television as a means of communication in Uganda.",
                "type": "multipleChoice",
                "answer": [
                    "Radios cover a wider area compared to television",
                    "Radios are cheaper to acquire compared to television"
                ],
                "options": [
                    "Televisions cover a wider area compared to radios",
                    "Radios cover a wider area compared to television",
                    "Radios are cheaper to acquire compared to television"
                ],
                "id": "ple_sst_sect_A17_19"
            },
            {
                "question": "Give one reason why there are more goods transported by road than by train in East Africa.",
                "type": "multipleChoice",
                "answer": [
                    "Roads reach most places in East Africa while trains do not"
                ],
                "options": [
                    "Roads reach most places in East Africa while trains do not",
                    "Trains reach most places in East Africa while roads do not"
                ],
                "id": "ple_sst_sect_A17_20"
            }
        ],
        "category": 17,
        "$id": "document_16"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "How is a scale useful to a map?",
                "type": "multipleChoice",
                "answer": [
                    "It helps the map reader to calculate the actual ground distance on a map"
                ],
                "options": [
                    "It helps the map reader to interprete symbols on the map",
                    "It helps the map reader to calculate the actual ground distance on a map"
                ],
                "id": "ple_sst_sect_A18_1"
            },
            {
                "question": "Mention one cause of global warming",
                "type": "text",
                "answer": [
                    "Deforestation",
                    "Bush burning",
                    "Swamp drainage",
                    "Industrialization"
                ],
                "id": "ple_sst_sect_A18_2"
            },
            {
                "question": "Give any one reason why the Royal Geographical Society supported the explorers to come to East Africa",
                "type": "multipleChoice",
                "answer": [
                    "To find the source of River Nile"
                ],
                "options": [
                    "To find the source of River Nile",
                    "To transported slaves out of East Africa"
                ],
                "id": "ple_sst_sect_A18_3"
            },
            {
                "question": "Give any one way through which the African Union (AU) promotes peace among member countries",
                "type": "multipleChoice",
                "answer": [
                    "African union holds peace talks to solve disputes in member countries"
                ],
                "options": [
                    "African union holds peace talks to solve disputes in member countries",
                    "African union fights wars to maintain order in member countries"
                ],
                "id": "ple_sst_sect_A18_4"
            },
            {
                "question": "How is the symbol shown below useful to map?",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2020_18.png",
                "type": "multipleChoice",
                "answer": [
                    "It helps in the measuring of the actual ground distance between places on a map"
                ],
                "options": [
                    "It helps to explain physical features on the map",
                    "It helps in the measuring of the actual ground distance between places on a map"
                ],
                "id": "ple_sst_sect_A18_5"
            },
            {
                "question": "Why is cattle keeping one of the most important economic activities in Uganda?",
                "type": "multipleChoice",
                "answer": [
                    "It is a primary source of income"
                ],
                "options": [
                    "It is a primary source of income",
                    "Its because many Ugandans own cattle"
                ],
                "id": "ple_sst_sect_A18_6"
            },
            {
                "question": "Give any one way in which culture is important in a community",
                "type": "multipleChoice",
                "answer": [
                    "Culture provides individuals within a community with a sense of belonging"
                ],
                "options": [
                    "Culture provides individuals within a community with a sense of belonging",
                    "Culture helps people to earn a living"
                ],
                "id": "ple_sst_sect_A18_7"
            },
            {
                "question": "State any one way in which political parties have contributed towards promoting democracy in Uganda.",
                "type": "multipleChoice",
                "answer": [
                    "They offer citizens a wide range of candidates to choose from"
                ],
                "options": [
                    "They organise elections in the country",
                    "They offer citizens a wide range of candidates to choose from"
                ],
                "id": "ple_sst_sect_A18_8"
            },
            {
                "question": "How did the Masai make the movement of early explorers to East Africa difficult?",
                "type": "text",
                "answer": [
                    "The Masai fought the explorers"
                ],
                "id": "ple_sst_sect_A18_9"
            },
            {
                "question": "State any one advantage of getting a leader through inheritance",
                "type": "text",
                "answer": [
                    "There is no power struggle or competition"
                ],
                "id": "ple_sst_sect_A18_10"
            },
            {
                "question": "Mention any one type of mining used in East Africa.",
                "type": "text",
                "answer": [
                    "Open cast",
                    "Quarrying",
                    "Alluvial",
                    "Shaft",
                    "Underground",
                    "Deep shaft"
                ],
                "id": "ple_sst_sect_A18_11"
            },
            {
                "question": "Mention any one way in which industrials growth can be a problem to the Environment.",
                "type": "multipleChoice",
                "answer": [
                    "It leads to destruction of environment",
                    "It leads to pollution of the environment"
                ],
                "options": [
                    "It leads to destruction of environment",
                    "It leads to pollution of the environment"
                ],
                "id": "ple_sst_sect_A18_12"
            },
            {
                "question": "What causes ocean current?",
                "type": "multipleChoice",
                "answer": [
                    "The movement of wind on alarge mass of water in a specified direction",
                    "The effect of the Equator"
                ],
                "options": [
                    "Global warming",
                    "The movement of wind on alarge mass of water in a specified direction",
                    "The effect of the Equator"
                ],
                "id": "ple_sst_sect_A18_13"
            },
            {
                "question": "Why did cotton growing in Tanganyika lead to the Maji-Maji rebellion?",
                "type": "text",
                "answer": [
                    "Because Africans were not allowed to grow cotton on their own",
                    "Because Africans were paid low wedges",
                    "Because of forced labour"
                ],
                "id": "ple_sst_sect_A18_14"
            },
            {
                "question": "Where is snow found in East Africa",
                "type": "text",
                "answer": [
                    "On very tall mountaintops above the snow line",
                    "Mountain Rwenzori",
                    "Mountain Kenya"
                ],
                "id": "ple_sst_sect_A18_15"
            },
            {
                "question": "State any one way in which the discovery of iron improved the life of the early man.",
                "type": "multipleChoice",
                "answer": [
                    "He manage to make superior tool for hunting and agriculture"
                ],
                "options": [
                    "It helped early man to build industries",
                    "He manage to make superior tool for hunting and agriculture"
                ],
                "id": "ple_sst_sect_A18_16"
            },
            {
                "question": "Identify any one problem faced by people living near a cement industry.",
                "type": "text",
                "answer": [
                    "Air pollution"
                ],
                "id": "ple_sst_sect_A18_17"
            },
            {
                "question": "Give any one reason why Africans are not able to make use of all the natural resources in Africa.",
                "type": "multipleChoice",
                "answer": [
                    "African do not have adequate skills to exploit natural resources in Africa",
                    "Constant Civil wars also prevent natural resource exploitation"
                ],
                "options": [
                    "African are not interested in mining",
                    "African do not have adequate skills to exploit natural resources in Africa",
                    "Constant Civil wars also prevent natural resource exploitation"
                ],
                "id": "ple_sst_sect_A18_18"
            },
            {
                "question": "Why is it not advisable to transport tomatoes from Uganda to Europe using water transport?",
                "type": "text",
                "answer": [
                    "Tomatoes are perishable goods thus need a faster means of transport"
                ],
                "options": [
                    "Tomatoes are soft so they need to be transported faster",
                    "Tomatoes are perishable goods thus need a faster means of transport"
                ],
                "id": "ple_sst_sect_A18_19"
            },
            {
                "question": "State any one reason for which the Uganda national flag is flown at half mast.",
                "type": "multipleChoice",
                "answer": [
                    "When mourning the death of an important person in the country"
                ],
                "options": [
                    "When mourning the death of an important person in the country",
                    "When someone dies in the country"
                ],
                "id": "ple_sst_sect_A18_20"
            }
        ],
        "category": 18,
        "$id": "document_17"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Below is the number plate of Dr. Isiko's vehicle. What does letter U stand for on the number plate?<br> <b>UBN 600B</b>",
                "type": "text",
                "answer": [
                    "Uganda"
                ],
                "id": "ple_sst_sect_A19_1"
            },
            {
                "question": "Which body in Uganda is responsible for protecting wildlife? ",
                "type": "text",
                "answer": [
                    "Uganda Wildlife Authority",
                    "UWA"
                ],
                "id": "ple_sst_sect_A19_2"
            },
            {
                "question": "State any one way in which the national constitution guides people on the use of the Uganda National flag",
                "type": "multipleChoice",
                "answer": [
                    "It spells out on how it should be respected or handled"
                ],
                "options": [
                    "It raises the Uganda flag during national events",
                    "It spells out on how it should be respected or handled"
                ],
                "id": "ple_sst_sect_A19_3"
            },
            {
                "question": "Why do some people build their houses with slanting roofs",
                "type": "multipleChoice",
                "answer": [
                    "To allow the easy flow of rain water"
                ],
                "options": [
                    "Inorder to have high roofs",
                    "To allow the easy flow of rain water "
                ],
                "id": "ple_sst_sect_A19_4"
            },
            {
                "question": "State the importance of Kainji dam to the people of Nigeria",
                "type": "multipleChoice",
                "answer": [
                    "It is a source of hydro electric power"
                ],
                "options": [
                    "It provides water for irrigataion",
                    "It is a source of hydro electric power"
                ],
                "id": "ple_sst_sect_A19_5"
            },
            {
                "question": "What role did African traditional leaders play in defending their independence during colonial rule?",
                "type": "multipleChoice",
                "answer": [
                    "They organised their people to fight the colonialists"
                ],
                "options": [
                    "They organised their people to fight the colonialists",
                    "They fled into exile away from the colonialists"
                ],
                "id": "ple_sst_sect_A19_6"
            },
            {
                "question": "Mention any one reason why the Velds of South Africa are suitable for sheep rearing",
                "type": "multipleChoice",
                "answer": [
                    "The abundance of nutritious and diverse vegetation"
                ],
                "options": [
                    "The presence of many sheep farmers",
                    "The abundance of nutritious and diverse vegetation"
                ],
                "id": "ple_sst_sect_A19_7"
            },
            {
                "question": "How does Sudan benefit from River Nile?",
                "type": "multipleChoice",
                "answer": [
                    "It helps Sudan with water for irrigation"
                ],
                "id": "ple_sst_sect_A19_8"
            },
            {
                "question": "State any one danger of corruption in the economy of a country",
                "type": "text",
                "answer": [
                    "Poverty",
                    "Social Injustice",
                    "Income Inequality"
                ],
                "id": "ple_sst_sect_A19_9"
            },
            {
                "question": " Name the type of soil that is used in pottery industry",
                "type": "text",
                "answer": [
                    "Clay soil",
                    "Clay"
                ],
                "id": "ple_sst_sect_A19_10"
            },
            {
                "question": "Why was the railway line extended from Kampala to Kasese?",
                "type": "multipleChoice",
                "answer": [
                    "To transport coper ore",
                    "To transport goods"
                ],
                "options": [
                    "To transport coper ore",
                    "To transport oil",
                    "To transport goods"
                ],
                "id": "ple_sst_sect_A19_11"
            },
            {
                "question": "Give any one economic advantage that people living in mining area of south Africa get.",
                "type": "text",
                "answer": [
                    "They get social services",
                    "Workers provide market for local goods",
                    "Its a source of income to the people"
                ],
                "id": "ple_sst_sect_A19_12"
            },
            {
                "question": "Give any reason why people should not settle in swamps",
                "type": "text",
                "answer": [
                    "To avoid floods",
                    "To avoid waterborne diseases",
                    "To avoid global warming"
                ],
                "id": "ple_sst_sect_A19_13"
            },
            {
                "question": "Name the body that promote trade among the west Africa State.",
                "type": "text",
                "answer": [
                    "Economic community of West African States",
                    "ECOWAS"
                ],
                "id": "ple_sst_sect_A19_14"
            },
            {
                "question": "Mention any one way in which stories of origin of tribe are important to the people of Uganda.",
                "type": "multipleChoice",
                "answer": [
                    "They help Ugandans to know their origin",
                    "They help to promote their culture and heritage"
                ],
                "options": [
                    "They help Ugandans to know their origin",
                    "They help to promote their culture and heritage"
                ],
                "id": "ple_sst_sect_A19_15"
            },
            {
                "question": "State any one way in which freedom of association is practised in Uganda today.",
                "type": "multipleChoice",
                "answer": [
                    "People are allowed to gather for political rallies",
                    "People are allowed to church crusades and meetings"
                ],
                "options": [
                    "People are allowed to gather for political rallies",
                    "People are allowed to church crusades and meetings"
                ],
                "id": "ple_sst_sect_A19_16"
            },
            {
                "question": "Suggest any one way the Government can help the people leaving in rural areas to increase agricultural produce.",
                "type": "multipleChoice",
                "answer": [
                    "The government can provide modern farm equipments like tractors and harvesters",
                    "The government can train farmers with new skills"
                ],
                "options": [
                    "The government can provide modern farm equipments like tractors and harvesters",
                    "The government can train farmers with new skills"
                ],
                "id": "ple_sst_sect_A19_17"
            },
            {
                "question": "Suggest any one reason why the Bachwezi are remembered in Uganda.",
                "type": "multipleChoice",
                "answer": [
                    "They introduced kingdoms or centralized monarchies",
                    "They also introduced coffee growing",
                    "They also introducedlong horned cattle"
                ],
                "options": [
                    "They introduced kingdoms or centralized monarchies",
                    "They also introduced coffee growing",
                    "They also introduced long horned cattle"
                ],
                "id": "ple_sst_sect_A19_18"
            },
            {
                "question": "Give any one contribution of Asians to the economic development of Uganda.",
                "type": "text",
                "answer": [
                    "Asians boosted the trade of Uganda"
                ],
                "id": "ple_sst_sect_A19_19"
            },
            {
                "question": "Give any one way in which rivers and lakes influence the climate of East Africa.",
                "type": "multipleChoice",
                "answer": [
                    "Rivers and lakes lead to formation of convectional rainfall"
                ],
                "options": [
                    "Rivers and lakes lead to formation of tropical rainfall",
                    "Rivers and lakes lead to formation of convectional rainfall"
                ],
                "id": "ple_sst_sect_A19_20"
            }
        ],
        "category": 19,
        "$id": "document_18"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Why is tourism called an invisible trade?",
                "type": "multipleChoice",
                "answer": [
                    "It does not involve physical exchange of goods but income is earned"
                ],
                "options": [
                    "It does not involve physical exchange of goods but income is earned",
                    "Because it involves foreigners"
                ],
                "id": "ple_sst_sect_A20_1"
            },
            {
                "question": "State the major economic activity carried out by people living near a waterbody",
                "type": "text",
                "answer": [
                    "Fishing"
                ],
                "id": "ple_sst_sect_A20_2"
            },
            {
                "question": "Mention any one reason why the citizens of East Africa should live in peace among themselves",
                "type": "multipleChoice",
                "answer": [
                    "To promote trade"
                ],
                "options": [
                    "To promote trade",
                    "To promote their culture"
                ],
                "id": "ple_sst_sect_A20_3"
            },
            {
                "question": "Mention any one way in which families can solve the problems caused by high food prices in Uganda.",
                "type": "multipleChoice",
                "answer": [
                    "By storing food in granaries"
                ],
                "options": [
                    "By storing food in granaries",
                    "By buying alot of food"
                ],
                "id": "ple_sst_sect_A20_4"
            },
            {
                "question": "Why is a sign post important?",
                "type": "multipleChoice",
                "answer": [
                    "It directs people to the place where the company or institution is found"
                ],
                "options": [
                    "It is used to show directions on a map",
                    "It directs people to the place where the company or institution is found"
                ],
                "id": "ple_sst_sect_A20_5"
            },
            {
                "question": "How is proper disposal of plastics and polythene bags useful to the environment?",
                "type": "multipleChoice",
                "answer": [
                    "It helps to prevent land and water pollution"
                ],
                "options": [
                    "It helps to prevent land and water pollution"
                ],
                "id": "ple_sst_sect_A20_6"
            },
            {
                "question": "Why was the bird below chosen as the Uganda National Emblem?",
                "type": "multipleChoice",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2018_20.png",
                "answer": [
                    "The crested crane was chosen because of its graceful and elegant appearance"
                ],
                "options": [
                    "The crested crane was chosen because of its graceful and elegant appearance",
                    "Beacuse it represents Uganda's natural vegetation"
                ],
                "id": "ple_sst_sect_A20_7"
            },
            {
                "question": "Which missionary built the first hospital in Uganda?",
                "type": "text",
                "answer": [
                    "Dr. Albert Cook",
                    "Albert Cook",
                    "Doctor Albert Cook"
                ],
                "id": "ple_sst_sect_A20_8"
            },
            {
                "question": "What type of budget is represented by the diagram below?",
                "type": "text",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2016_20.png",
                "answer": [
                    "Deficit budget",
                    "Deficit"
                ],
                "id": "ple_sst_sect_A20_9"
            },
            {
                "question": "Name any one of the tools used in schools to change from the daily activity to another.",
                "type": "text",
                "answer": [
                    "Time table",
                    "Time tables",
                    "Alarm clocks",
                    "Alarm clock",
                    "School bell",
                    "Drum",
                    "Gong",
                    "Whistle",
                    "Siren"
                ],
                "id": "ple_sst_sect_A20_10"
            },
            {
                "question": "Which international organization unites all countries that were colonized by the British?",
                "type": "text",
                "answer": [
                    "Common Wealth Organization",
                    "Common Wealth",
                    "Common Wealth of Nations"
                ],
                "id": "ple_sst_sect_A20_11"
            },
            {
                "question": "State any one way in which a school can promote sanitation in the community.",
                "type": "multipleChoice",
                "answer": [
                    "By encouraging hygiene",
                    "By sensitising of people about health"
                ],
                "options": [
                    "By encouraging hygiene",
                    "By sensitising of people about health"
                ],
                "id": "ple_sst_sect_A20_12"
            },
            {
                "question": "Why is the government of Uganda building more classrooms in schools across the country?",
                "type": "text",
                "answer": [
                    "To reduce congestion in classrooms",
                    "To improve on education standards"
                ],
                "options": [
                    "To reduce congestion in classrooms",
                    "To improve on education standards"
                ],
                "id": "ple_sst_sect_A20_13"
            },
            {
                "question": "What is citizenship by naturalization?",
                "type": "multipleChoice",
                "answer": [
                    "This is when one has stayed in a country for long and asks to be registered as a citizen"
                ],
                "options": [
                    "This is when one has stayed in a country for long and asks to be registered as a citizen",
                    "This is when one is a citizen in a country he/she was born in"
                ],
                "id": "ple_sst_sect_A20_14"
            },
            {
                "question": "State any one problem an area would face if it received no rain throughout the year.",
                "type": "text",
                "answer": [
                    "Shortage of water",
                    "Scarcity of water",
                    "Shortage of pasture",
                    "Scarcity of pasture",
                    "Shortage of food",
                    "Famine"
                ],
                "id": "ple_sst_sect_A20_15"
            },
            {
                "question": "Mention any one way in which nomadic pastoralism in North-Eastern Uganda can be reduced ",
                "type": "multipleChoice",
                "answer": [
                    "By construction of valley dams to provide water to animals",
                    "By establishing of irrigation schemes to encourage agriculture"
                ],
                "options": [
                    "By construction of valley dams to provide water to animals",
                    "By establishing of irrigation schemes to encourage agriculture",
                    "By taking away the cattle from the pastrolistsists"
                ],
                "id": "ple_sst_sect_A20_16"
            },
            {
                "question": "In which way does peace promote development of the country?",
                "type": "multipleChoice",
                "answer": [
                    "Peace promotes trade",
                    "Peace promotes investment"
                ],
                "options": [
                    "Peace promotes trade",
                    "Peace promotes investment",
                    "Peace promotes development"
                ],
                "id": "ple_sst_sect_A20_17"
            },
            {
                "question": "Which important latitude lies at 23½ <sup>o</sup> north of the Equator?",
                "type": "text",
                "answer": [
                    "Tropic of cancer"
                ],
                "id": "ple_sst_sect_A20_18"
            },
            {
                "question": "How has the government tried to solve the problem of water shortage in drier parts of Uganda?",
                "type": "multipleChoice",
                "answer": [
                    "It has set up valley dams"
                ],
                "options": [
                    "It has set up valley dams",
                    "It has extended tap water to those people"
                ],
                "id": "ple_sst_sect_A20_19"
            },
            {
                "question": "What enabled the Bantu-speaking people to form kingdoms? ",
                "type": "multipleChoice",
                "answer": [
                    "The settling and establishment of homes near each other after discovering fertile soils"
                ],
                "options": [
                    "They fought off other kingdoms",
                    "The settling and establishment of homes near each other after discovering fertile soils"
                ],
                "id": "ple_sst_sect_A20_20"
            }
        ],
        "category": 20,
        "$id": "document_19"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "State any one factors that attracted the Bantu cultivators to settle within the lake regions",
                "type": "multipleChoice",
                "answer": [
                    "Presence of fertile soil within the lake regions"
                ],
                "options": [
                    "Presence of fish",
                    "Presence of fertile soil within the lake regions"
                ],
                "id": "ple_sst_sect_A3_1"
            },
            {
                "question": "Why is the government of Uganda creating new districts?",
                "type": "multipleChoice",
                "answer": [
                    "To ease administration"
                ],
                "options": [
                    "To build more roads",
                    "To ease administration"
                ],
                "id": "ple_sst_sect_A3_2"
            },
            {
                "question": "Name any one tax introduced by the colonialists in Uganda.",
                "type": "text",
                "answer": [
                    "Hut tax",
                    "Gun tax",
                    "Poll tax",
                    "Graduated tax"
                ],
                "id": "ple_sst_sect_A3_3"
            },
            {
                "question": "State one way in which the International Monetary Fund (IMF) helps to promote development in Uganda",
                "type": "multipleChoice",
                "answer": [
                    "It provides development funds to Uganda"
                ],
                "options": [
                    "It helps to fight corruption in Uganda",
                    "It provides development funds to Uganda"
                ],
                "id": "ple_sst_sect_A3_4"
            },
            {
                "question": "Name the most common type of transport used in Uganda",
                "type": "multipleChoice",
                "answer": [
                    "Road transport"
                ],
                "id": "ple_sst_sect_A3_5"
            },
            {
                "question": "Which sub-ethinic group in East Africa originated from the Ethiopian highlands?",
                "type": "text",
                "answer": [
                    "Nilo Hamites"
                ],
                "id": "ple_sst_sect_A3_6"
            },
            {
                "question": "Mention any one national symbol found on the Ugandan currency.",
                "type": "text",
                "answer": [
                    "Court of arms"
                ],
                "id": "ple_sst_sect_A3_7"
            },
            {
                "question": "Name one basic need provided by a school.",
                "type": "text",
                "answer": [
                    "Food",
                    "Shelter"
                ],
                "id": "ple_sst_sect_A3_8"
            },
            {
                "question": "What does the symbol below represent on a map?",
                "type": "text",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2016_3.png",
                "answer": [
                    "Contours"
                ],
                "id": "ple_sst_sect_A3_9"
            },
            {
                "question": "Give any one modern way of preserving fish.",
                "type": "text",
                "answer": [
                    "By canning/tinning",
                    "By canning",
                    "By tinning",
                    "By refrigeration",
                    "By freezing"
                ],
                "id": "ple_sst_sect_A3_10"
            },
            {
                "question": "Give the opposite of North East on a compass.",
                "type": "text",
                "answer": [
                    "Southwest",
                    "South-west Ward",
                    "South Western"
                ],
                "id": "ple_sst_sect_A3_11"
            },
            {
                "question": "What is the main cause of land fragmentation in Uganda?",
                "type": "text",
                "answer": [
                    "Over population",
                    "High population",
                    "Population growth",
                    "Population increase"
                ],
                "id": "ple_sst_sect_A3_12"
            },
            {
                "question": "Why did Uganda join World War II?",
                "type": "multipleChoice",
                "answer": [
                    "To help the British fight its enemies"
                ],
                "options": [
                    "To help the British fight its enemies",
                    "To protect its borders",
                    "To expand its territory"
                ],
                "id": "ple_sst_sect_A3_13"
            },
            {
                "question": "What can help a map reader to know the meaning of symbols on a map?",
                "type": "text",
                "answer": [
                    "Key",
                    "The key"
                ],
                "id": "ple_sst_sect_A3_14"
            },
            {
                "question": "What is the compass direction of Mt.Elgon from Mt.Rwenzori?",
                "type": "text",
                "answer": [
                    "North-east"
                ],
                "id": "ple_sst_sect_A3_15"
            },
            {
                "question": "Mention any one disadvantage of building in a wetland.",
                "type": "multipleChoice",
                "answer": [
                    "It may lead to frequent floods during rainy seasons"
                ],
                "options": [
                    "It may lead to frequent floods during rainy seasons",
                    "It may lead to soil erosion"
                ],
                "id": "ple_sst_sect_A3_16"
            },
            {
                "question": "Give any one reason why symbols are put on a map instead of real objects.",
                "type": "text",
                "answer": [
                    "Real objects cannot fit on the map"
                ],
                "id": "ple_sst_sect_A3_17"
            },
            {
                "question": "Give any one use of a zebra crossing",
                "type": "multipleChoice",
                "answer": [
                    "Zebra cross helps the pedestrians to safely cross the road"
                ],
                "options": [
                    "Zebra cross helps to control traffic on the road",
                    "Zebra cross helps the pedestrians to safely cross the road"
                ],
                "id": "ple_sst_sect_A3_18"
            },
            {
                "question": "How does a map reader tell the meaning of symbols used on the map?",
                "type": "multipleChoice",
                "answer": [
                    "By the use of the key"
                ],
                "options": [
                    "By the use of the scale",
                    "By the use of the key",
                    "By the use of the title"
                ],
                "id": "ple_sst_sect_A3_19"
            }
        ],
        "category": 3,
        "$id": "document_2"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Write NEMA in full.",
                "type": "text",
                "answer": [
                    "National Environment Management Authority"
                ],
                "id": "ple_sst_sect_A21_1"
            },
            {
                "question": "Apart from mountain Rwenzori , mention any other block mountain in East Africa",
                "type": "text",
                "answer": [
                    "Mountain Usambara",
                    "Mountain Uluguru",
                    "Mountain Livingstone",
                    "Mountain Pare",
                    "Mountain Mbeya"
                ],
                "id": "ple_sst_sect_A21_2"
            },
            {
                "question": "How does afforestration reduce land slide on mountain slopes",
                "type": "multipleChoice",
                "answer": [
                    "The roots of trees bind or hold soil particles together"
                ],
                "options": [
                    "The roots of trees bind or hold soil particles together",
                    "The trees help to keep the weather calm to stop land slides"
                ],
                "id": "ple_sst_sect_A21_3"
            },
            {
                "question": "Select any one way in which the setting up of small scale industries in rural areas can reduce rural-urban migration",
                "type": "multipleChoice",
                "answer": [
                    "They have provided jobs to people in rural areas"
                ],
                "options": [
                    "They have provided jobs to people in rural areas",
                    "They reduce congestion in rural areas so they attract people to stay"
                ],
                "id": "ple_sst_sect_A21_4"
            },
            {
                "question": "State the major crop grown in the high velds of South Africa",
                "type": "text",
                "answer": [
                    "Maize"
                ],
                "id": "ple_sst_sect_A21_5"
            },
            {
                "question": "How did the East African countries solve the problem of limited market for goods in 1967?",
                "type": "multipleChoice",
                "answer": [
                    "They formed the East African Community tocreate a common market for their goods"
                ],
                "options": [
                    "They looked for markets outside of EastAfrica",
                    "They formed the East African Community tocreate a common market for their goods"
                ],
                "id": "ple_sst_sect_A21_6"
            },
            {
                "question": "State one way in which games and sports are important in a community",
                "type": "multipleChoice",
                "answer": [
                    "They promote unity in a community"
                ],
                "options": [
                    "They promote unity in a community",
                    "They help to arrest wrong doers"
                ],
                "id": "ple_sst_sect_A21_7"
            },
            {
                "question": "How did the Kabaka Yekka help Obote to become the first Prime Minister of Uganda?",
                "type": "multipleChoice",
                "answer": [
                    "It formed a coalition with Obote that gave them a parliamentary majority hence becoming the first prime minister of Uganda"
                ],
                "options": [
                    "It formed a coalition with Obote that gave them a parliamentary majority hence becoming the first prime minister of Uganda",
                    "It helped Obote to chase away the colonialists"
                ],
                "id": "ple_sst_sect_A21_8"
            },
            {
                "question": "Which group of people introduced the idea of salt mining in Uganda?",
                "type": "text",
                "answer": [
                    "Bachwezi"
                ],
                "id": "ple_sst_sect_A21_9"
            },
            {
                "question": "What was the bolas used for during the Stone Age period?",
                "type": "multipleChoice",
                "answer": [
                    "They were used for hunting"
                ],
                "options": [
                    "TThey were used for hunting",
                    "They were used to make fire"
                ],
                "id": "ple_sst_sect_A21_10"
            },
            {
                "question": "What is citizenship descent?",
                "type": "multipleChoice",
                "answer": [
                    "It is when one’s parents or ancestors are citizens of that country"
                ],
                "options": [
                    "It is when one is born in that country",
                    "It is when one’s parents or ancestors are citizens of that country"
                ],
                "id": "ple_sst_sect_A21_11"
            },
            {
                "question": "Ghana lies on the prime Meridian. If the time in Ghana is 1:00p.m what time will it be East Africa standard time?",
                "type": "multipleChoice",
                "answer": [
                    "It will be 4:00p.m",
                    "It will be4 o'clock"
                ],
                "options": [
                    "It will be 4:00p.m",
                    "It will be4 o'clock",
                    "It will be 1:00p.m",
                    "It will be 3:00p.m"
                ],
                "id": "ple_sst_sect_A21_12"
            },
            {
                "question": "Give any one advantage a tarmac road has over marram road.",
                "type": "multipleChoice",
                "answer": [
                    "It is smoother than murram one",
                    "It is more durable than murram road",
                    "It makes movement faster than murram road"
                ],
                "options": [
                    "It is smoother than murram one",
                    "It is cheaper than a murram road",
                    "It is more durable than murram road",
                    "It makes movement faster than murram road"
                ],
                "id": "ple_sst_sect_A21_13"
            },
            {
                "question": "What name is given to the laws that are set by the local council of an area?",
                "type": "text",
                "answer": [
                    "By-laws",
                    "By laws"
                ],
                "id": "ple_sst_sect_A21_14"
            },
            {
                "question": "Give any one source of power used in copper smelting in Zambia",
                "type": "text",
                "answer": [
                    "Running water",
                    "Coal",
                    "River zambezi"
                ],
                "id": "ple_sst_sect_A21_15"
            },
            {
                "question": "What is the advantage of a country having its own seaport?",
                "type": "multipleChoice",
                "answer": [
                    "It reduces delay in importation and exportation",
                    "It reduces taxes on goods"
                ],
                "options": [
                    "It reduces delay in importation and exportation",
                    "It increases taxes on goods"
                ],
                "id": "ple_sst_sect_A21_16"
            },
            {
                "question": "Why is it difficult to carry out crop farming in north-eastern Kenya?",
                "type": "multipleChoice",
                "answer": [
                    "It is a semi-desert which make it difficult to grow plants"
                ],
                "options": [
                    "It is sparsely populated making farming difficult",
                    "It is a semi-desert which make it difficult to grow plants"
                ],
                "id": "ple_sst_sect_A21_17"
            },
            {
                "question": "Give any one reason why Africans in Kenya refused to work on the construction of the Kenya-Uganda railway.",
                "type": "multipleChoice",
                "answer": [
                    "They did not want to be ruled by British and more so leave their families and stay in camps"
                ],
                "options": [
                    "They didnot want to do tiresome work",
                    "They did not want to be ruled by British and more so leave their families and stay in camps"
                ],
                "id": "ple_sst_sect_A21_18"
            },
            {
                "question": "Apart from mountain, high lands, rift valleys, give any one other physical feature of Uganda.",
                "type": "text",
                "answer": [
                    "Rivers",
                    "Lakes",
                    "Plateaus"
                ],
                "id": "ple_sst_sect_A21_19"
            },
            {
                "question": "In which way is land important in the establishment of an industry? ",
                "type": "multipleChoice",
                "answer": [
                    "Land provides physical ground on which the industry is constructed"
                ],
                "options": [
                    "Land provides the raw materials for the industry",
                    "Land provides physical ground on which the industry is constructed"
                ],
                "id": "ple_sst_sect_A21_20"
            }
        ],
        "category": 21,
        "$id": "document_20"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Give any one way the early man protected himself from danger",
                "type": "multipleChoice",
                "answer": [
                    "He used fire to scare away wild animals"
                ],
                "options": [
                    "He used fire to scare away wild animals",
                    "He used guns to fight enemies and wild animals"
                ],
                "id": "ple_sst_sect_A22_1"
            },
            {
                "question": "Mention any one traditional method of preserving fish",
                "type": "text",
                "answer": [
                    "Smoking",
                    "Salting",
                    "Sun drying"
                ],
                "id": "ple_sst_sect_A22_2"
            },
            {
                "question": "State one way in which feeder roads promote development in rural areas of Uganda",
                "type": "multipleChoice",
                "answer": [
                    "They ease the movement of traders in rural areas"
                ],
                "options": [
                    "They reduce congestion in rural areas",
                    "They ease the movement of traders in rural areas"
                ],
                "id": "ple_sst_sect_A22_3"
            },
            {
                "question": "Name the feature on the Uganda coat of Arms which symbolises the tropical climate",
                "type": "text",
                "answer": [
                    "The sun"
                ],
                "id": "ple_sst_sect_A22_4"
            },
            {
                "question": "Mention one type of tax directly paid by civil servants in Uganda today.",
                "type": "text",
                "answer": [
                    "Pay As You Earn",
                    "PAYE",
                    "NSSF tax",
                    "National Social Security Fund tax"
                ],
                "id": "ple_sst_sect_A22_5"
            },
            {
                "question": "Mention any one in which electricty cost can be minimized in a home.",
                "type": "multipleChoice",
                "answer": [
                    "It can be minimized by using low power appliances",
                    "it can be minimized by switching off lights during the day"
                ],
                "options": [
                    "It can be minimized by using low power appliances",
                    "it can be minimized by switching off lights during the day",
                    "It can be minimized by using it to cook"
                ],
                "id": "ple_sst_sect_A22_6"
            },
            {
                "question": "What type of tax is paid on locally manufactured goods?",
                "type": "text",
                "answer": [
                    "Excise duty"
                ],
                "id": "ple_sst_sect_A22_7"
            },
            {
                "question": "What is a government?",
                "type": "multipleChoice",
                "answer": [
                    "A government is the system or group of people governing an organized community or state"
                ],
                "options": [
                    "A government is the system or group of people governing an organized community or state"
                ],
                "id": "ple_sst_sect_A22_8"
            },
            {
                "question": "Mention any one way in which droughts affect cattle keepers in East Africa.",
                "type": "multipleChoice",
                "answer": [
                    "It leads to scarcity of pasture and water"
                ],
                "options": [
                    "It leads to cattle rustling",
                    "It leads to scarcity of pasture and water"
                ],
                "id": "ple_sst_sect_A22_9"
            },
            {
                "question": "Give any one reason why a re-cycling factory can be useful in on area.",
                "type": "multipleChoice",
                "answer": [
                    "It is a source of income",
                    "It controls pollution by waste materials",
                    "It provides market for used materials"
                ],
                "options": [
                    "It is a source of income",
                    "It controls pollution by waste materials",
                    "It stops people from polluting the environment",
                    "It provides market for used materials"
                ],
                "id": "ple_sst_sect_A22_10"
            },
            {
                "question": "Name the organization that replace the league of Nations",
                "type": "text",
                "answer": [
                    "United nations",
                    "United Nations Organization"
                ],
                "id": "ple_sst_sect_A22_11"
            },
            {
                "question": "How did the work of Sir Samuel Baker benefit the people of Northern Uganda?",
                "type": "multipleChoice",
                "answer": [
                    "He protected them from slave traders",
                    "He gave them guns for protection"
                ],
                "options": [
                    "He protected them from slave traders",
                    "He gave them guns for protection",
                    "He built for them houses"
                ],
                "id": "ple_sst_sect_A22_12"
            },
            {
                "question": "Mention any one danger of polythene paper bags to soil.",
                "type": "multipleChoice",
                "answer": [
                    "They reduce soil fertility",
                    "They don’t allow water to enter the soil"
                ],
                "options": [
                    "They reduce soil fertility",
                    "They don’t allow water to enter the soil",
                    "They increase aeration of the soil"
                ],
                "id": "ple_sst_sect_A22_13"
            },
            {
                "question": "Why is Kiswahili connected to the coming of Arabs?",
                "type": "multipleChoice",
                "answer": [
                    "It was a result of intermarriage between Arab and the coastal Bantu"
                ],
                "options": [
                    "They introduced it from Arab countries",
                    "It was a result of intermarriage between Arab and the coastal Bantu"
                ],
                "id": "ple_sst_sect_A22_14"
            },
            {
                "question": "Select the direction of South East on the compass from the images below.",
                "type": "multipleChoice",
                "answer": [
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2010_22.png"
                ],
                "options": [
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2010_22B.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2010_22A.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2010_22.png"
                ],
                "id": "ple_sst_sect_A22_15"
            },
            {
                "question": "Give one reason why the North Eastern part of East Africa receives little rainfall.",
                "type": "multipleChoice",
                "answer": [
                    "The area lacks water bodies and forests that would lead to convention rainfall",
                    "The North-East trade winds which are dry and bring little moisture to the region"
                ],
                "options": [
                    "The area lacks water bodies and forests that would lead to convention rainfall",
                    "The region is a desert that is why it receives little rainfall",
                    "The North-East trade winds which are dry and bring little moisture to the region"
                ],
                "id": "ple_sst_sect_A22_16"
            },
            {
                "question": "State one reason controlling population increase is difficult.",
                "type": "multipleChoice",
                "answer": [
                    "Ignorance amongst the population makes it difficult",
                    "Traditional beliefs which associate many children with riches"
                ],
                "options": [
                    "Ignorance amongst the population makes it difficult",
                    "The ability of people to take care of their children",
                    "Traditional beliefs which associate many children with riches"
                ],
                "id": "ple_sst_sect_A22_17"
            },
            {
                "question": "Why is it generally hot at Dar-es- Salaam and yet it is near the Indian Ocean?",
                "type": "multipleChoice",
                "answer": [
                    "Dar-es- Salaam receives warm current from Indian Ocean",
                    "Dar-es- Salaam being at a lower altitude makes it hot"
                ],
                "options": [
                    "Dar-es- Salaam experiencing climate change makes it hot",
                    "Dar-es- Salaam receives warm current from Indian Ocean",
                    "Dar-es- Salaam being at a lower altitude makes it hot"
                ],
                "id": "ple_sst_sect_A22_18"
            },
            {
                "question": "State any one element of a map which tells the reader what a map is all about\t",
                "type": "text",
                "answer": [
                    "The title",
                    "Title"
                ],
                "id": "ple_sst_sect_A22_19"
            },
            {
                "question": "Give any one way in which the government is trying to reduce child abuse in Uganda.",
                "type": "multipleChoice",
                "answer": [
                    "By sensitizing the public and parents about children's rights and punishing the abusers of children’s rights",
                    "The government has set up child protection unit in the police force to fight for children's rights"
                ],
                "options": [
                    "By sensitizing the public and parents about children's rights and punishing the abusers of children’s rights",
                    "The government has set up child protection unit in the police force to fight for children's rights",
                    "The government takes away children from their parents"
                ],
                "id": "ple_sst_sect_A22_20"
            }
        ],
        "category": 22,
        "$id": "document_21"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Which type of timber is used in the ship building industry?",
                "type": "text",
                "answer": [
                    "Mangrove timber",
                    "Waterproof timber"
                ],
                "id": "ple_sst_sect_A23_1"
            },
            {
                "question": "Give one reason why the government of Uganda conducts population census",
                "type": "multipleChoice",
                "answer": [
                    "To know the total number of people living in the country"
                ],
                "options": [
                    "To provide shelter for the people",
                    "To know the total number of people living in the country"
                ],
                "id": "ple_sst_sect_A23_2"
            },
            {
                "question": "Why are traffic police officers encouraged to wear jackets with reflectors while on duty?",
                "type": "multipleChoice",
                "answer": [
                    "To ease identification"
                ],
                "options": [
                    "Because its part of their uniform",
                    "To ease identification"
                ],
                "id": "ple_sst_sect_A23_3"
            },
            {
                "question": "Mention any one way in which the National constitution helps to promote peace in a country",
                "type": "multipleChoice",
                "answer": [
                    "It promotes the rule of law"
                ],
                "options": [
                    "It arrests law breakers",
                    "It promotes the rule of law"
                ],
                "id": "ple_sst_sect_A23_4"
            },
            {
                "question": "Give any one factor that brings about differences in the vegetation distribution in mountaineous regions",
                "type": "multipleChoice",
                "answer": [
                    "Change in temperature"
                ],
                "options": [
                    "Electrol violence",
                    "Change in temperature"
                ],
                "id": "ple_sst_sect_A23_5"
            },
            {
                "question": "Give any one reason why smuggling should not be allowed in the country",
                "type": "multipleChoice",
                "answer": [
                    "It makes the country to lose revenue"
                ],
                "options": [
                    "It promotes trade in the country",
                    "It makes the country to lose revenue"
                ],
                "id": "ple_sst_sect_A23_6"
            },
            {
                "question": "How is a National Identity card useful to a citizen of Uganda?",
                "type": "multipleChoice",
                "answer": [
                    "It is used for identification of a citizen"
                ],
                "options": [
                    "It used to pass ports in different countries",
                    "It is used for identification of a citizen"
                ],
                "id": "ple_sst_sect_A23_7"
            }
        ],
        "category": 23,
        "$id": "document_22"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Why is there no hydro-electricity in Kalangala district?",
                "type": "multipleChoice",
                "answer": [
                    "It is difficult to install electric poles in Lake Victoria"
                ],
                "options": [
                    "It is difficult to install electric poles in Lake Victoria",
                    "Because there are no sources of hydro-electricity in Kalangala district"
                ],
                "id": "ple_sst_sect_A24_1"
            },
            {
                "question": "How are oases important to people living in desert areas?",
                "type": "multipleChoice",
                "answer": [
                    "They are sources of water for domestic use"
                ],
                "options": [
                    "They provide areas with fertile soils",
                    "They are sources of water for domestic use"
                ],
                "id": "ple_sst_sect_A24_2"
            },
            {
                "question": "Give any one factor that enables people to survive in semi-desert conditions",
                "type": "multipleChoice",
                "answer": [
                    "Wearing light clothes during sunny weather"
                ],
                "options": [
                    "Wearing light clothes during sunny weather",
                    "By growing trees"
                ],
                "id": "ple_sst_sect_A24_3"
            },
            {
                "question": "State any one cause of teenage pregnancies in Uganda.",
                "type": "multipleChoice",
                "answer": [
                    "Alcoholism"
                ],
                "options": [
                    "Corruption",
                    "Alcoholism"
                ],
                "id": "ple_sst_sect_A24_4"
            },
            {
                "question": "Apart from controlling soil erosion, give any one other reason why a school compound should have vegetation cover",
                "type": "multipleChoice",
                "answer": [
                    "To beautify the compound"
                ],
                "options": [
                    "Its a requirement by the ministry",
                    "To beautify the compound"
                ],
                "id": "ple_sst_sect_A24_5"
            },
            {
                "question": "How can farmers of area marked A control soil erosion?",
                "type": "multipleChoice",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2018_24.png",
                "answer": [
                    "They use terraces to control soil erosion"
                ],
                "options": [
                    "They use terraces to control soil erosion",
                    "They can use valley dams"
                ],
                "id": "ple_sst_sect_A24_6"
            },
            {
                "question": "How does afforestation influence the climatic condition of a place?",
                "type": "multipleChoice",
                "answer": [
                    "It helps to increase on the rain fall amounts received in a place"
                ],
                "options": [
                    "It helps to reduce floods in an area",
                    "It helps to increase on the rain fall amounts received in a place"
                ],
                "id": "ple_sst_sect_A24_7"
            }
        ],
        "category": 24,
        "$id": "document_23"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Give any one factor that influences the climate of an area",
                "type": "text",
                "answer": [
                    "Altitude",
                    "Human activities",
                    "Prevailing winds",
                    "Relief",
                    "Ocean currents",
                    "Latitude",
                    "Distance from the equator",
                    "Nature of vegetation",
                    "Drainage of an area"
                ],
                "id": "ple_sst_sect_A25_1"
            },
            {
                "question": "State any one benefit of giving information on weather to people",
                "type": "multipleChoice",
                "answer": [
                    "It enables farmers to know when to plant and harvest their crops"
                ],
                "options": [
                    "It enables farmers to know when to plant and harvest their crops",
                    "It helps to promote tourism in the country"
                ],
                "id": "ple_sst_sect_A25_2"
            },
            {
                "question": "Mention any one way in which oil drilling can benefit the people living in the Albertine region",
                "type": "multipleChoice",
                "answer": [
                    "It can lead to the development of infrastructure"
                ],
                "options": [
                    "It will help them to get better health services",
                    "It can lead to the development of infrastructure"
                ],
                "id": "ple_sst_sect_A25_3"
            },
            {
                "question": "Why is pastrolism not highly developed among the nomadic communities in Africa?",
                "type": "multipleChoice",
                "answer": [
                    "The shortage of pasture"
                ],
                "options": [
                    "The presence of fertile soils",
                    "The shortage of pasture"
                ],
                "id": "ple_sst_sect_A25_4"
            },
            {
                "question": "State any one challenge faced by people living in area marked B",
                "type": "multipleChoice",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2018_24.png",
                "answer": [
                    "They face a challenge of soil erosion"
                ],
                "options": [
                    "They face a challenge of floods",
                    "They face a challenge of soil erosion"
                ],
                "id": "ple_sst_sect_A25_5"
            },
            {
                "question": "Mention any one way through which poverty can be reduced in your community. ",
                "type": "multipleChoice",
                "answer": [
                    "By creating more jobs"
                ],
                "options": [
                    "By creating more jobs",
                    "By increasing taxes"
                ],
                "id": "ple_sst_sect_A25_6"
            }
        ],
        "category": 25,
        "$id": "document_24"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Who is a pedestrian?",
                "type": "multipleChoice",
                "answer": [
                    "A pedestrian is a person who walks along the road"
                ],
                "options": [
                    "A pedestrian is a person who walks home",
                    "A pedestrian is a person who walks along the road"
                ],
                "id": "ple_sst_sect_A26_1"
            },
            {
                "question": "Why would you encourage your fellow pupils to recite their school motto at every assembly",
                "type": "multipleChoice",
                "answer": [
                    "To promote love for the school"
                ],
                "options": [
                    "To promote patriotism",
                    "To promote love for the school"
                ],
                "id": "ple_sst_sect_A26_2"
            },
            {
                "question": "Select any one reason for the signing of the Anglo-German treaty of 1980",
                "type": "multipleChoice",
                "answer": [
                    "To finalise the partition of East Africa"
                ],
                "options": [
                    "To give independence to East African states",
                    "To finalise the partition of East Africa"
                ],
                "id": "ple_sst_sect_A26_3"
            },
            {
                "question": "Mention any one way through which the government of Uganda is able to find out the problems affecting its people",
                "type": "multipleChoice",
                "answer": [
                    "Through talk shows"
                ],
                "options": [
                    "Through talk shows",
                    "Through elections"
                ],
                "id": "ple_sst_sect_A26_4"
            },
            {
                "question": "How is Uganda different from Liberia in terms of colonization?",
                "type": "multipleChoice",
                "answer": [
                    "Uganda was colonized by the Europeans while Liberia was not"
                ],
                "options": [
                    "Uganda was colonized by the British while Liberia was colonized by the Germans",
                    "Uganda was colonized by the Europeans while Liberia was not"
                ],
                "id": "ple_sst_sect_A26_5"
            },
            {
                "question": "What is a population structure?",
                "type": "multipleChoice",
                "answer": [
                    "Population structure is the compostion of a population"
                ],
                "options": [
                    "Population structure is the is the number of people living in an area",
                    "Population structure is the compostion of a population"
                ],
                "id": "ple_sst_sect_A26_6"
            }
        ],
        "category": 26,
        "$id": "document_25"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "State any one advantage tarmac roads have over murram roads",
                "type": "multipleChoice",
                "answer": [
                    "Tarmac roads cannot easily be spoilt by bad weather unlike murram roads"
                ],
                "options": [
                    "Tarmac roads cannot easily be spoilt by bad weather unlike murram roads",
                    "Murram roads cannot easily be spoilt by bad weather unlike tarmac roads"
                ],
                "id": "ple_sst_sect_A27_1"
            },
            {
                "question": "State any one requirement for a citizen to be elected a chairperson of a local council committee in Uganda",
                "type": "multipleChoice",
                "answer": [
                    "One must be eighteen years and above"
                ],
                "options": [
                    "One must have attained higher education",
                    "One must be eighteen years and above"
                ],
                "id": "ple_sst_sect_A27_2"
            },
            {
                "question": "Mention any way in which a school can preserve the culture of a community",
                "type": "multipleChoice",
                "answer": [
                    "By teaching children their culture"
                ],
                "options": [
                    "By conducting elections",
                    "By teaching children their culture"
                ],
                "id": "ple_sst_sect_A27_3"
            },
            {
                "question": "State any one social challenge that has been caused by thick forests in the Democratic Republic of Congo",
                "type": "multipleChoice",
                "answer": [
                    "Attacks from rebels"
                ],
                "options": [
                    "Poor transport networks",
                    "Attacks from rebels"
                ],
                "id": "ple_sst_sect_A27_4"
            },
            {
                "question": "Mention any one benefit that people living near Lake Katwe have.",
                "type": "multipleChoice",
                "answer": [
                    "They benefit the ecomomic benefits of salt mining"
                ],
                "options": [
                    "They benefit the ecomomic benefits of salt mining",
                    "They have the benefit of developed infrastructure"
                ],
                "id": "ple_sst_sect_A27_5"
            },
            {
                "question": "Name the political party that led Kenya to independence.",
                "type": "text",
                "answer": [
                    "Kenya African National Union",
                    "KANU"
                ],
                "id": "ple_sst_sect_A27_6"
            }
        ],
        "category": 27,
        "$id": "document_26"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Who were the founders of Wanga kingdom? ",
                "type": "text",
                "answer": [
                    "Abaluhya",
                    "The Luhya"
                ],
                "id": "ple_sst_sect_A28_1"
            },
            {
                "question": "Mention any one through which parents can ensure equal treatment of boys and girls at home",
                "type": "multipleChoice",
                "answer": [
                    "By giving them similar tasks to perform"
                ],
                "options": [
                    "By teaching the cultural values",
                    "By giving them similar tasks to perform"
                ],
                "id": "ple_sst_sect_A28_2"
            },
            {
                "question": "Give any one reason why polling stations should have police officers during an election",
                "type": "multipleChoice",
                "answer": [
                    "To promote order during elections"
                ],
                "options": [
                    "To ensure that their candidates win",
                    "To promote order during elections"
                ],
                "id": "ple_sst_sect_A28_3"
            },
            {
                "question": "State any one way in which the use of biogas leads to conservation of natural vegetation",
                "type": "multipleChoice",
                "answer": [
                    "It limits deforestation for wood fuel"
                ],
                "options": [
                    "It encourages afforestration",
                    "It limits deforestation for wood fuel"
                ],
                "id": "ple_sst_sect_A28_4"
            },
            {
                "question": "Mention any one peaceful method that Europeans used to acquire colonies in Africa",
                "type": "multipleChoice",
                "answer": [
                    "They signed treaties"
                ],
                "options": [
                    "They signed treaties",
                    "They carried out elections"
                ],
                "id": "ple_sst_sect_A28_5"
            },
            {
                "question": "Apart from being used for transport, state any one other reason why the Sabiny keep donkeys.",
                "type": "multipleChoice",
                "answer": [
                    "They use the donkeys to plough in the gardens"
                ],
                "options": [
                    "They use the donkeys to plough in the gardens",
                    "Because its their cultural identity"
                ],
                "id": "ple_sst_sect_A28_6"
            }
        ],
        "category": 28,
        "$id": "document_27"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "How does peace and security promote development of an area?",
                "type": "multipleChoice",
                "answer": [
                    "It encourages investment in an area"
                ],
                "options": [
                    "It encourages investment in an area",
                    "It helps to fight corruption"
                ],
                "id": "ple_sst_sect_A29_1"
            },
            {
                "question": "Mention any one practice that shows that Uganda was at one time a British protectorate",
                "type": "multipleChoice",
                "answer": [
                    "It uses english as an official language"
                ],
                "options": [
                    "It carries out elections",
                    "It uses english as an official language"
                ],
                "id": "ple_sst_sect_A29_2"
            },
            {
                "question": "Mention any one way in which the temperate grasslands are economically important to the people of South Africa",
                "type": "multipleChoice",
                "answer": [
                    "They promote tourism"
                ],
                "options": [
                    "They promote tourism",
                    "They promote environmental conseravtion"
                ],
                "id": "ple_sst_sect_A29_3"
            },
            {
                "question": "Give any one economic result of the movement of the Boers to the interior of South Africa",
                "type": "multipleChoice",
                "answer": [
                    "It led to discovery of gold"
                ],
                "options": [
                    "It led to discovery of gold",
                    "It led to infrastructure development"
                ],
                "id": "ple_sst_sect_A29_4"
            },
            {
                "question": "State any one reason why the government of Uganda is encouraging people to join technical schools.",
                "type": "multipleChoice",
                "answer": [
                    "It wants to bridge the skill gap among Ugandans"
                ],
                "options": [
                    "It wants to bridge the skill gap among Ugandans",
                    "To help the people to fight corrurption"
                ],
                "id": "ple_sst_sect_A29_5"
            },
            {
                "question": "Why should busy roads have humps?",
                "type": "multipleChoice",
                "answer": [
                    "To control over speeding"
                ],
                "options": [
                    "To beautify the roads",
                    "To control over speeding"
                ],
                "id": "ple_sst_sect_A29_6"
            }
        ],
        "category": 29,
        "$id": "document_28"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "State any one reason why some people in Uganda do not read newspapers",
                "type": "multipleChoice",
                "answer": [
                    "Newspapers are not written in all languages in Uganda"
                ],
                "options": [
                    "Newspapers are not written in all languages in Uganda",
                    "Newspapers are only restricted to urban areas"
                ],
                "id": "ple_sst_sect_A30_1"
            },
            {
                "question": "Give one reason why the Antarctica continent is not suitable for human settlement",
                "type": "multipleChoice",
                "answer": [
                    "Because it is very cold"
                ],
                "options": [
                    "Because it is very cold",
                    "Because its far south"
                ],
                "id": "ple_sst_sect_A30_2"
            },
            {
                "question": "Apart from money, mention any one other personal thing that people can keep in a bank",
                "type": "text",
                "answer": [
                    "Land titles",
                    "Wills",
                    "Will"
                ],
                "id": "ple_sst_sect_A30_3"
            },
            {
                "question": "Give any one reason why a country should maintain peace and security",
                "type": "multipleChoice",
                "answer": [
                    "To promote trade"
                ],
                "options": [
                    "To fight corruption",
                    "To promote trade"
                ],
                "id": "ple_sst_sect_A30_4"
            },
            {
                "question": "Why do the coastal areas of East Africa experience high temperature?",
                "type": "multipleChoice",
                "answer": [
                    "Because they are at a lower altitude"
                ],
                "options": [
                    "Because  they are at a higher altitude",
                    "Because they are at a lower altitude"
                ],
                "id": "ple_sst_sect_A30_5"
            },
            {
                "question": "Mention any one place where a wind sock is found.",
                "type": "text",
                "answer": [
                    "Airports",
                    "Weather stations",
                    "Harbors",
                    "Ports"
                ],
                "id": "ple_sst_sect_A30_6"
            }
        ],
        "category": 30,
        "$id": "document_29"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Give any one way phosphates promotes agricultural development",
                "type": "multipleChoice",
                "answer": [
                    "Phosphates is used in the making of artificial fertilizers"
                ],
                "options": [
                    "Phosphates are used to make the ground more fertile",
                    "Phosphates is used in the making of artificial fertilizers"
                ],
                "id": "ple_sst_sect_A4_1"
            },
            {
                "question": "How do deciduous trees survive during a dry season?",
                "type": "multipleChoice",
                "answer": [
                    "By shedding their leaves"
                ],
                "options": [
                    "By being conifers",
                    "By shedding their leaves"
                ],
                "id": "ple_sst_sect_A4_2"
            },
            {
                "question": "State one reason why you would not advise fishermen to use herbs as a method of fishing",
                "type": "multipleChoice",
                "answer": [
                    "Because it leads to water pollution"
                ],
                "options": [
                    "Because it leads to over fishing",
                    "Because it leads to water pollution"
                ],
                "id": "ple_sst_sect_A4_3"
            },
            {
                "question": "Give any one way through which the colonialists communicated with the local people in Uganda",
                "type": "multipleChoice",
                "answer": [
                    "By using translators"
                ],
                "options": [
                    "By using translators",
                    "By using the railway"
                ],
                "id": "ple_sst_sect_A4_4"
            },
            {
                "question": "Who were the first group of foreigners to come to Uganda",
                "type": "text",
                "answer": [
                    "Arab Traders",
                    "Traders"
                ],
                "id": "ple_sst_sect_A4_5"
            },
            {
                "question": "What discovery enabled the early man to start sleeping in caves?",
                "type": "text",
                "answer": [
                    "The discovery of fire",
                    "Fire"
                ],
                "id": "ple_sst_sect_A4_6"
            },
            {
                "question": "Name one place at school where Physical Education lessons are conducted.",
                "type": "text",
                "answer": [
                    "The gym",
                    "Gym",
                    "Football pitch",
                    "Field",
                    "Pitch"
                ],
                "id": "ple_sst_sect_A4_7"
            },
            {
                "question": "What is population growth?",
                "type": "multipleChoice",
                "answer": [
                    "Population growth is the increase in the number of individuals in an area over a specific period of time"
                ],
                "options": [
                    "Population growth is the  number of live births per thousand people in a given area within a specific time period",
                    "Population growth is the increase in the number of individuals in an area over a specific period of time"
                ],
                "id": "ple_sst_sect_A4_8"
            },
            {
                "question": "Mention the use of a barometer at a weather station.",
                "type": "multipleChoice",
                "answer": [
                    "To measure atmospheric pressure"
                ],
                "options": [
                    "To measure humidity",
                    "To measure the speed of wind",
                    "To measure atmospheric pressure"
                ],
                "id": "ple_sst_sect_A4_9"
            },
            {
                "question": "Apart from Rwanda, name one other landlocked country that boarders Uganda.",
                "type": "text",
                "answer": [
                    "South Sudan"
                ],
                "id": "ple_sst_sect_A4_10"
            },
            {
                "question": "What safety wear is used by passengers crossing water bodies to protect their lives?",
                "type": "text",
                "answer": [
                    "Life saver",
                    "Life jackets",
                    "Life jacket"
                ],
                "id": "ple_sst_sect_A4_11"
            },
            {
                "question": "What title is given to the head of civil servants in a district?",
                "type": "text",
                "answer": [
                    "Chief Administrative officer",
                    "CAO"
                ],
                "id": "ple_sst_sect_A4_12"
            },
            {
                "question": "Give any one problem caused by too much rainfall to the community",
                "type": "text",
                "answer": [
                    "Floods",
                    "Landslides",
                    "Mudslides",
                    "Soil erosion"
                ],
                "id": "ple_sst_sect_A4_13"
            },
            {
                "question": "Which neighbouring country of Uganda is mainly covered by equatorial rain forests?",
                "type": "text",
                "answer": [
                    "Democratic Republic of Congo",
                    "DRC"
                ],
                "id": "ple_sst_sect_A4_14"
            },
            {
                "question": "What was the title given to the leader of government in Uganda after independence between 1962 and 1966?",
                "type": "text",
                "answer": [
                    "Prime minister",
                    "Executive Prime minister"
                ],
                "id": "ple_sst_sect_A4_15"
            },
            {
                "question": "Apart from colonial rulers, which other group of people helped to develop social services in Uganda?",
                "type": "text",
                "answer": [
                    "Missionaries",
                    "Explorers",
                    "Traders"
                ],
                "id": "ple_sst_sect_A4_16"
            },
            {
                "question": "Give one reason why it was easier for the British to rule Buganda and not Lango or West Nile.",
                "type": "mutipleChoice",
                "answer": [
                    "Buganda had an organized administrative structure under the kabaka whereas Lango was under a chiefdom with many leaders who could not agree."
                ],
                "options": [
                    "Buganda was close to the British yet Lango was far",
                    "Buganda had an organized administrative structure under the kabaka whereas Lango was under a chiefdom with many leaders who could not agree."
                ],
                "id": "ple_sst_sect_A4_17"
            },
            {
                "question": "How did I.K.Musaazi contribute to the struggle for independence in Uganda?",
                "type": "multipleChoice",
                "answer": [
                    "Dr. I.K. Musaazi formed the first national political party in Uganda called UNO"
                ],
                "options": [
                    "Dr. I.K. Musaazi composed the national anthem",
                    "Dr. I.K. Musaazi designed the Uganda flag",
                    "Dr. I.K. Musaazi formed the first national political party in Uganda called UNO"
                ],
                "id": "ple_sst_sect_A4_18"
            },
            {
                "question": "State any one way in which weather records are important to a farmer,",
                "type": "multipleChoice",
                "answer": [
                    "They help a farmer schedule his planting and harvesting season"
                ],
                "options": [
                    "They help a farmer to know whether to carry an umbrella or not",
                    "They help a farmer schedule his planting and harvesting season"
                ],
                "id": "ple_sst_sect_A4_19"
            },
            {
                "question": "Give one reason why it is important to study sites where the early man lived.",
                "type": "multipleChoice",
                "answer": [
                    "It provides historical knowledge about the way of life of the early man"
                ],
                "options": [
                    "It provides modern day knowledge",
                    "It provides historical knowledge about the way of life of the early man"
                ],
                "id": "ple_sst_sect_A4_20"
            }
        ],
        "category": 4,
        "$id": "document_3"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "What causes landslides in highland areas?",
                "type": "text",
                "answer": [
                    "Too much rainfall",
                    "Heavy rainfall"
                ],
                "id": "ple_sst_sect_A31_1"
            },
            {
                "question": "Give any one reason why Uganda has less irrigation projects compared to Libya",
                "type": "multipleChoice",
                "answer": [
                    "Uganda receives reliable rainfall unlike Libya"
                ],
                "options": [
                    "Uganda is in the East compared to Libya which is in the North",
                    "Uganda receives reliable rainfall unlike Libya"
                ],
                "id": "ple_sst_sect_A31_2"
            },
            {
                "question": "Mention any one desert found in Africa",
                "type": "text",
                "answer": [
                    "Sahara desert",
                    "Kalahari desert",
                    "Namib desert"
                ],
                "id": "ple_sst_sect_A31_3"
            },
            {
                "question": "Give one way in which forceful registration of guns led to Lamogi rebellion",
                "type": "multipleChoice",
                "answer": [
                    "The Acholi never wanted to be disarmed"
                ],
                "options": [
                    "The Acholi wanted to fight the government",
                    "The Acholi never wanted to be disarmed"
                ],
                "id": "ple_sst_sect_A31_4"
            },
            {
                "question": "What time is it at town X which is located 45<sup>o</sup> East if it’s 6:00am at Greenwich?",
                "type": "multipleChoice",
                "answer": [
                    "It is 9:00 am at place X"
                ],
                "explanation": "1hour = 15<sup>o</sup><br> 45<sup>0</sup> = (45/15) = 3 hours<br> 6:00 + 3:00 = 9:00am",
                "options": [
                    "It is 9:00 pm at place X",
                    "It is 9:00 am at place X"
                ],
                "id": "ple_sst_sect_A31_5"
            }
        ],
        "category": 31,
        "$id": "document_30"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Write UWEC in full",
                "type": "text",
                "answer": [
                    "Uganda Wildlife Conservation Education Center"
                ],
                "id": "ple_sst_sect_A32_1"
            },
            {
                "question": "State any one way in which the government of Uganda is promoting the children's right to education",
                "type": "multipleChoice",
                "answer": [
                    "By promoting Universal Primary Education"
                ],
                "options": [
                    "By keeping children safe at home",
                    "By promoting Universal Primary Education"
                ],
                "id": "ple_sst_sect_A32_2"
            },
            {
                "question": "Name any one block mountain found in Tanzania",
                "type": "text",
                "answer": [
                    "Mountain Usambara",
                    "Mountain Uluguru",
                    "Mountain Pare",
                    "Mountain Mbeya",
                    "Mountain Livingstone"
                ],
                "id": "ple_sst_sect_A32_3"
            },
            {
                "question": "Give any one way in which the finding of the sea route to India benefitted the Portuguese",
                "type": "multipleChoice",
                "answer": [
                    "They got new trade items ie silk and spices"
                ],
                "options": [
                    "They got new slave markets",
                    "They got new trade items ie silk and spices"
                ],
                "id": "ple_sst_sect_A32_4"
            },
            {
                "question": "Mention any one importance of oases to the crop farmers in the desert area of Africa.",
                "type": "multipleChoice",
                "answer": [
                    "Oases are water sources in the desert"
                ],
                "options": [
                    "Oases have fertile soils that help crop framers",
                    "Oases are water sources in the desert"
                ],
                "id": "ple_sst_sect_A32_5"
            },
            {
                "question": "How is Lake Victoria similar to Lake Kyoga in terms of formation?",
                "type": "multipleChoice",
                "answer": [
                    "They were both formed by down warping"
                ],
                "options": [
                    "They were both formed volcanicity",
                    "They were both formed by down warping"
                ],
                "id": "ple_sst_sect_A32_6"
            }
        ],
        "category": 32,
        "$id": "document_31"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Why is Mwalimu Julius Nyerere Kambarage remembered in the history of Tanzania?",
                "type": "multipleChoice",
                "answer": [
                    "He led Tanganyika to independence"
                ],
                "options": [
                    "He led Tanganyika to independence",
                    "He stopped slave trade in Tanzania"
                ],
                "id": "ple_sst_sect_A33_1"
            },
            {
                "question": "Give any one benefit of a market to a neighbouring school",
                "type": "multipleChoice",
                "answer": [
                    "It is a source of food to the school"
                ],
                "options": [
                    "It helps the secure the area near the school",
                    "It is a source of food to the school"
                ],
                "id": "ple_sst_sect_A33_2"
            },
            {
                "question": "Give any one reason why few people use newspapers as means of communication in East Africa.",
                "type": "multipleChoice",
                "answer": [
                    "Some people in East Africa can’t read newspapers"
                ],
                "options": [
                    "Newspapers are not easy to find",
                    "Some people in East Africa can’t read newspapers"
                ],
                "id": "ple_sst_sect_A33_3"
            },
            {
                "question": "State any one problem that has made plantation farming difficult in rural areas of Uganda.",
                "type": "multipleChoice",
                "answer": [
                    "Shortage of capital"
                ],
                "options": [
                    "Lack of enough land",
                    "Shortage of capital "
                ],
                "id": "ple_sst_sect_A33_4"
            },
            {
                "question": "What will the time be in a country at 60<sup>o</sup>E if it is 1:00 p.m at the Greenwich Mean Time?",
                "type": "multipleChoice",
                "answer": [
                    "It will be 5:00 p.m at a place 60<sup>o</sup>E"
                ],
                "explanation": "1 hr = 15<sup>o</sup><br> 60<sup>o</sup> = (60/15) = 4hrs<br> 1:00 p.m + + 4:00hrs = 5:00 p.m",
                "options": [
                    "It will be 5:00 a.m at a place 60<sup>o</sup>E",
                    "It will be 5:00 p.m at a place 60<sup>o</sup>E"
                ],
                "id": "ple_sst_sect_A33_5"
            },
            {
                "question": "State any one way in which forests improve people’s health.",
                "type": "multipleChoice",
                "answer": [
                    "By absorbing carbon dioxide and releasing oxygen"
                ],
                "options": [
                    "By absorbing carbon dioxide and releasing oxygen",
                    "By reducing soil erosion"
                ],
                "id": "ple_sst_sect_A33_6"
            }
        ],
        "category": 33,
        "$id": "document_32"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Give one characteristic of basin lakes",
                "type": "multipleChoice",
                "answer": [
                    "They have fresh water"
                ],
                "options": [
                    "They are salty lakes",
                    "They have fresh water"
                ],
                "id": "ple_sst_sect_A34_1"
            },
            {
                "question": "Why is East Africa reffered to as the cradle land of the human race",
                "type": "multipleChoice",
                "answer": [
                    "The oldest human skull of early man was discovered in East Africa"
                ],
                "options": [
                    "Early man lived in East Africa",
                    "The oldest human skull of early man was discovered in East Africa"
                ],
                "id": "ple_sst_sect_A34_2"
            },
            {
                "question": "Why is mob justice a bad practice in communities",
                "type": "multipleChoice",
                "answer": [
                    "It leads to violation of human rights"
                ],
                "options": [
                    "It lets wrong doers escape justice",
                    "It leads to violation of human rights"
                ],
                "id": "ple_sst_sect_A34_3"
            },
            {
                "question": "Which mountain forms a natural boundary between Rwanda and Uganda",
                "type": "text",
                "answer": [
                    "Mountain Mufumbiro"
                ],
                "id": "ple_sst_sect_A34_4"
            },
            {
                "question": "Mention any one advantage of electing leaders using the secret ballot system.",
                "type": "multipleChoice",
                "answer": [
                    "It promotes free will when voting"
                ],
                "options": [
                    "It promotes unity in the community",
                    "It promotes free will when voting"
                ],
                "id": "ple_sst_sect_A34_5"
            },
            {
                "question": "What moral lessons do we learn from the legend of the spear and the bead?",
                "type": "multipleChoice",
                "answer": [
                    "We learn to seek permission from other people before we use their property"
                ],
                "options": [
                    "We learn not to judge others",
                    "We learn to seek permission from other people before we use their property"
                ],
                "id": "ple_sst_sect_A34_6"
            }
        ],
        "category": 34,
        "$id": "document_33"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Select one challenge faced by miners who use the underground method of mining",
                "type": "multipleChoice",
                "answer": [
                    "Death of miners incase mines collapse"
                ],
                "options": [
                    "They face a problem of high taxes",
                    "Death of miners incase mines collapse"
                ],
                "id": "ple_sst_sect_A35_1"
            },
            {
                "question": "Select any one reason why the number of children dying of diseases has gone down in East Africa",
                "type": "multipleChoice",
                "answer": [
                    "Due to the improvement in the provision of health services"
                ],
                "options": [
                    "Due to the decrease in birth rates in East Africa",
                    "Due to the improvement in the provision of health services"
                ],
                "id": "ple_sst_sect_A35_2"
            },
            {
                "question": "Mention any one duty of a veterinary officer in our community",
                "type": "multipleChoice",
                "answer": [
                    "To treat sick animals"
                ],
                "options": [
                    "To treat the sick",
                    "To treat sick animals"
                ],
                "id": "ple_sst_sect_A35_3"
            },
            {
                "question": "How do civil servants benefit from government revenue?",
                "type": "multipleChoice",
                "answer": [
                    "They are paid their salaries"
                ],
                "options": [
                    "They get free health services",
                    "They are paid their salaries"
                ],
                "id": "ple_sst_sect_A35_4"
            },
            {
                "question": "Name any one common market in which Uganda is a member.",
                "type": "text",
                "answer": [
                    "East African Community"
                ],
                "id": "ple_sst_sect_A35_5"
            }
        ],
        "category": 35,
        "$id": "document_34"
    },
    {
        "instructions": "For each of the questions 36 to 40, answer EITHER the Christian OR Islamic question but not both. No marks will be awarded to a candidate who attempts both alternatives in a particular number.",
        "questions": [
            {
                "either": {
                    "question": "What sacrament makes one join God's family?",
                    "type": "text",
                    "answer": [
                        "Baptism"
                    ],
                    "id": "ple_sst_sectA_either_1"
                },
                "or": {
                    "question": "What pillar of Islam makes one join Islam?",
                    "type": "text",
                    "answer": [
                        "Shahada",
                        "Shahadah"
                    ],
                    "id": "ple_sst_sectA_or_1"
                }
            },
            {
                "either": {
                    "question": "Name the mountain where Moses received the Ten Commandments",
                    "type": "text",
                    "answer": [
                        "Mountain Sinai"
                    ],
                    "id": "ple_sst_sectA_either_2"
                },
                "or": {
                    "question": "Name the mountain where Prophet Muhammad received the first revelation of the Quran",
                    "type": "text",
                    "answer": [
                        "Mountain Hira"
                    ],
                    "id": "ple_sst_sectA_or_2"
                }
            },
            {
                "either": {
                    "question": "How did Abraham show his faith in God?",
                    "type": "multipleChoice",
                    "answer": [
                        "He accepted to sacrifice his son Isaac to God"
                    ],
                    "options": [
                        "He listened to God",
                        "He accepted to sacrifice his son Isaac to God"
                    ],
                    "id": "ple_sst_sectA_either_3"
                },
                "or": {
                    "question": "How did prophet Ibrahim show his faith to Allah?",
                    "type": "multipleChoice",
                    "answer": [
                        "He accepted to sacrifice his son Ismail to Allah"
                    ],
                    "options": [
                        "He listened to Allah",
                        "He accepted to sacrifice his son Ismail to Allah"
                    ],
                    "id": "ple_sst_sectA_or_3"
                }
            },
            {
                "either": {
                    "question": "Give any one reason why Christians pray to God",
                    "type": "multipleChoice",
                    "answer": [
                        "To ask for forgiveness"
                    ],
                    "options": [
                        "To go to heaven",
                        "To ask for forgiveness"
                    ],
                    "id": "ple_sst_sectA_either_4"
                },
                "or": {
                    "question": "Give one reason why Muslims pray to Allah",
                    "type": "multipleChoice",
                    "answer": [
                        "To strengthen their faith "
                    ],
                    "options": [
                        "To go to Jannah",
                        "To strengthen their faith "
                    ],
                    "id": "ple_sst_sectA_or_4"
                }
            },
            {
                "either": {
                    "question": "Give one reason why Joseph was sold to the Midianite traders (Israelities) by his brothers",
                    "type": "multipleChoice",
                    "answer": [
                        "His brothers were jealous of him"
                    ],
                    "options": [
                        "His brothers wanted to get some money",
                        "His brothers were jealous of him"
                    ],
                    "id": "ple_sst_sectA_either_5"
                },
                "or": {
                    "question": "Give one reason why Yusuf was sold to the Midianite traders (Israelities) by his brothers",
                    "type": "multipleChoice",
                    "answer": [
                        "His brothers were jealous of him"
                    ],
                    "options": [
                        "His brothers wanted to get some money",
                        "His brothers were jealous of him"
                    ],
                    "id": "ple_sst_sectA_or_5"
                }
            },
            {
                "either": {
                    "question": "Select any one pratice that can enable a christian to live a fruitful life",
                    "type": "multipleChoice",
                    "answer": [
                        "Reading the Holy Bible"
                    ],
                    "options": [
                        "Visiting Jerusalem",
                        "Reading the Holy Quran",
                        "Reading the Holy Bible"
                    ],
                    "id": "ple_sst_sectA_either_6"
                },
                "or": {
                    "question": "Select any one pratice that can enable a muslim to live a fruitful life",
                    "type": "multipleChoice",
                    "answer": [
                        "Reading the Holy Quran"
                    ],
                    "options": [
                        "Visiting Mecca",
                        "Reading the Holy Quran",
                        "Reading the Holy Bible"
                    ],
                    "id": "ple_sst_sectA_or_6"
                }
            },
            {
                "either": {
                    "question": "State any one way in which a christian child can care for fellow pupils",
                    "type": "multipleChoice",
                    "answer": [
                        "By sharing with them"
                    ],
                    "options": [
                        "By reporting them",
                        "By sharing with them"
                    ],
                    "id": "ple_sst_sectA_either_7"
                },
                "or": {
                    "question": "State any one way in which a muslim child can care for fellow pupils",
                    "type": "multipleChoice",
                    "answer": [
                        "By sharing with them"
                    ],
                    "options": [
                        "By reporting them",
                        "By sharing with them"
                    ],
                    "id": "ple_sst_sectA_or_7"
                }
            },
            {
                "either": {
                    "question": "State any one role of angels in Christianity",
                    "type": "multipleChoice",
                    "answer": [
                        "They protect Christians"
                    ],
                    "options": [
                        "They blow the trumpet on the judgement day",
                        "They protect Christians"
                    ],
                    "id": "ple_sst_sectA_either_8"
                },
                "or": {
                    "question": "State any one role of angels in Islam",
                    "type": "multipleChoice",
                    "answer": [
                        "They record deeds of every muslim"
                    ],
                    "options": [
                        "They protect muslims",
                        "They record deeds of every muslim"
                    ],
                    "id": "ple_sst_sectA_or_8"
                }
            },
            {
                "either": {
                    "question": "State any one way in which christians can participate in the development of their communities",
                    "type": "multipleChoice",
                    "answer": [
                        "By constructing schools"
                    ],
                    "options": [
                        "By participating in elections",
                        "By constructing schools"
                    ],
                    "id": "ple_sst_sectA_either_9"
                },
                "or": {
                    "question": "State any one way in which muslims can participate in the development of their communities",
                    "type": "multipleChoice",
                    "answer": [
                        "By constructing hospitals"
                    ],
                    "options": [
                        "By participating in elections",
                        "By constructing hospitals"
                    ],
                    "id": "ple_sst_sectA_or_9"
                }
            },
            {
                "either": {
                    "question": "Mention any one way in which a christian can improve the lives of people suffering in their community",
                    "type": "multipleChoice",
                    "answer": [
                        "By constructing orphanages"
                    ],
                    "options": [
                        "By going to church",
                        "By constructing orphanages"
                    ],
                    "id": "ple_sst_sectA_either_10"
                },
                "or": {
                    "question": "Mention any one way in which a christian can improve the lives of people suffering in their community",
                    "type": "multipleChoice",
                    "answer": [
                        "By constructing orphanages"
                    ],
                    "options": [
                        "By going to the mosque",
                        "By constructing hospitals"
                    ],
                    "id": "ple_sst_sectA_or_10"
                }
            },
            {
                "either": {
                    "question": "Name the brother of Simon Peter who was called by Jesus to follow him",
                    "type": "text",
                    "answer": [
                        "Andrew"
                    ],
                    "id": "ple_sst_sectA_either_11"
                },
                "or": {
                    "question": "Name the person who was the uncle of Prophet Muhammad (P.B.U.H)",
                    "type": "text",
                    "answer": [
                        "Abu Talib",
                        "Abu Jahal"
                    ],
                    "id": "ple_sst_sectA_or_11"
                }
            },
            {
                "either": {
                    "question": "In what way did God punish the human race because of their disobedience during Noah's time?",
                    "type": "multipleChoice",
                    "answer": [
                        "He sent a flood"
                    ],
                    "options": [
                        "He killed all first born sons",
                        "He sent a flood"
                    ],
                    "id": "ple_sst_sectA_either_12"
                },
                "or": {
                    "question": "In what way did Allah punish the human race because of their disobedience during Prophet Nuhu's time?",
                    "type": "multipleChoice",
                    "answer": [
                        "He sent a flood"
                    ],
                    "options": [
                        "He killed all first born sons",
                        "He sent a flood"
                    ],
                    "id": "ple_sst_sectA_or_12"
                }
            },
            {
                "either": {
                    "question": "Mention any one reason why a christian should not live a lazy life.",
                    "type": "multipleChoice",
                    "answer": [
                        "To be able to access their basic needs"
                    ],
                    "options": [
                        "Because the Bible is against it",
                        "To be able to access their basic needs"
                    ],
                    "id": "ple_sst_sectA_either_13"
                },
                "or": {
                    "question": "Mention any one reason why a muslim should not live a lazy life.",
                    "type": "multipleChoice",
                    "answer": [
                        "To be able to access their basic needs"
                    ],
                    "options": [
                        "Because the Quran is against it",
                        "To be able to access their basic needs"
                    ],
                    "id": "ple_sst_sectA_or_13"
                }
            },
            {
                "either": {
                    "question": "What term is used by christians to mean the act of asking for forgiveness after wrong doing?",
                    "type": "text",
                    "answer": [
                        "Repentance"
                    ],
                    "id": "ple_sst_sectA_either_14"
                },
                "or": {
                    "question": "What term is used by muslims to mean the act of asking for forgiveness after wrong doing?",
                    "type": "text",
                    "answer": [
                        "Repentance"
                    ],
                    "id": "ple_sst_sectA_or_14"
                }
            },
            {
                "either": {
                    "question": "Give one benefit of helping the needy",
                    "type": "multipleChoice",
                    "answer": [
                        "It enables a Christian to get blessings from God"
                    ],
                    "options": [
                        "It enables Christian to go to heaven after death",
                        "It enables a Christian to get blessings from God"
                    ],
                    "id": "ple_sst_sectA_either_15"
                },
                "or": {
                    "question": "Give one benefit of giving zakat",
                    "type": "multipleChoice",
                    "answer": [
                        "It enables a Muslim to get blessings from Allah"
                    ],
                    "options": [
                        "It enables a muslim go to the mosque for prayer",
                        "It enables a Muslim to get blessings from Allah"
                    ],
                    "id": "ple_sst_sectA_or_15"
                }
            },
            {
                "either": {
                    "question": "How did God test Abraham's faith?",
                    "type": "multipleChoice",
                    "answer": [
                        "God asked Abraham to sacrifice his only son Isaac to Him"
                    ],
                    "options": [
                        "God asked Abraham to build the ark",
                        "God asked Abraham to sacrifice his only son Isaac to Him"
                    ],
                    "id": "ple_sst_sectA_either_16"
                },
                "or": {
                    "question": "How did Allah test Ibrahim's faith?",
                    "type": "multipleChoice",
                    "answer": [
                        "God asked Abraham to sacrifice his only son Isaac to Him"
                    ],
                    "options": [
                        "Allah asked Prophet Ibrahim to build the ark",
                        "Allah asked Prophet Ibrahim to sacrifice his son,Ismail to Him"
                    ],
                    "id": "ple_sst_sectA_or_16"
                }
            },
            {
                "either": {
                    "question": "Why should a christian couple go for HIV testing before marriage?",
                    "type": "multipleChoice",
                    "answer": [
                        "To produce healthy children"
                    ],
                    "options": [
                        "Because the Bible requires them to",
                        "To produce healthy children"
                    ],
                    "id": "ple_sst_sectA_either_17"
                },
                "or": {
                    "question": "Why should a muslim couple go for HIV testing before marriage?",
                    "type": "multipleChoice",
                    "answer": [
                        "To produce healthy children"
                    ],
                    "options": [
                        "Because the Quran requires them to",
                        "To produce healthy children"
                    ],
                    "id": "ple_sst_sectA_or_17"
                }
            },
            {
                "either": {
                    "question": "Write down one bad habit that a christian may show while others are praying",
                    "type": "text",
                    "answer": [
                        "Playing",
                        "Eating",
                        "Sleeping",
                        "Farting"
                    ],
                    "id": "ple_sst_sectA_either_18"
                },
                "or": {
                    "question": "Write down one bad habit that a muslim may show while others are praying",
                    "type": "text",
                    "answer": [
                        "Playing",
                        "Eating",
                        "Sleeping",
                        "Farting"
                    ],
                    "id": "ple_sst_sectA_or_18"
                }
            },
            {
                "either": {
                    "question": "Who was the mother of Ismael according to the Holy Bible",
                    "type": "text",
                    "answer": [
                        "Hagar"
                    ],
                    "id": "ple_sst_sectA_either_19"
                },
                "or": {
                    "question": "Who was the mother of Ismail according to the Holy Quran",
                    "type": "text",
                    "answer": [
                        "Hajarah"
                    ],
                    "id": "ple_sst_sectA_or_19"
                }
            },
            {
                "either": {
                    "question": "Name any one symbol of the Holy Spirit on Pentecost day.",
                    "type": "text",
                    "answer": [
                        "Strong wind",
                        "Flames of fire",
                        "Tongues of fire"
                    ],
                    "id": "ple_sst_sectA_either_20"
                },
                "or": {
                    "question": "Name any one symbol of Islam on a mosque.",
                    "type": "text",
                    "answer": [
                        "Crescent",
                        "Moon",
                        "Star"
                    ],
                    "id": "ple_sst_sectA_or_20"
                }
            },
            {
                "either": {
                    "question": "Name the person who led the Israelites out of Egypt.",
                    "type": "text",
                    "answer": [
                        "Moses"
                    ],
                    "id": "ple_sst_sectA_either_21"
                },
                "or": {
                    "question": "Name the prophet who led the Banu Israelites out of Egypt.",
                    "type": "text",
                    "answer": [
                        "Prophet Musa"
                    ],
                    "id": "ple_sst_sectA_or_21"
                }
            },
            {
                "either": {
                    "question": "How should you as a Christian treat those who mistreat you?",
                    "type": "multipleChoice",
                    "answer": [
                        "A christian should love those who mistreat him/her"
                    ],
                    "options": [
                        "A christian should not pray for those who mistreat him",
                        "A christian should love those who mistreat him/her"
                    ],
                    "id": "ple_sst_sectA_either_22"
                },
                "or": {
                    "question": "How should you as a Muslim treat those who mistreat you?",
                    "type": "multipleChoice",
                    "answer": [
                        "A Muslim should love those who mistreat him/her"
                    ],
                    "options": [
                        "A Muslim should not pray for those who mistreat him",
                        "A Muslim should love those who mistreat him/her"
                    ],
                    "id": "ple_sst_sectA_or_22"
                }
            },
            {
                "either": {
                    "question": "Name any one book of the law in the Bible.",
                    "type": "text",
                    "answer": [
                        "Genesis",
                        "Exodus",
                        "Leviticus",
                        "Numbers",
                        "Deuteronomy"
                    ],
                    "id": "ple_sst_sectA_either_23"
                },
                "or": {
                    "question": "Name the Holy Book in Islam that was revealed to Prophet Musa.",
                    "type": "text",
                    "answer": [
                        "Tauret ",
                        "Taurat"
                    ],
                    "id": "ple_sst_sectA_or_23"
                }
            },
            {
                "either": {
                    "question": "Name the mountain where Moses received the Ten commandments.",
                    "type": "text",
                    "answer": [
                        "Mount Sinai",
                        "Mountain Sinai"
                    ],
                    "id": "ple_sst_sectA_either_24"
                },
                "or": {
                    "question": "Name the mountain where Prophet Muhammad P.B.U.H received the first revelation.",
                    "type": "text",
                    "answer": [
                        "Mount Noor ",
                        "Mountain Noor"
                    ],
                    "id": "ple_sst_sectA_or_24"
                }
            },
            {
                "either": {
                    "question": "Who was the wife of Adam?",
                    "type": "text",
                    "answer": [
                        "Eve"
                    ],
                    "id": "ple_sst_sectA_either_25"
                },
                "or": {
                    "question": "Who was the wife of Adam?",
                    "type": "text",
                    "answer": [
                        "Hawa"
                    ],
                    "id": "ple_sst_sectA_or_25"
                }
            },
            {
                "either": {
                    "question": "Name the fasting period for Christians",
                    "type": "text",
                    "answer": [
                        "Lent"
                    ],
                    "id": "ple_sst_sectA_either_26"
                },
                "or": {
                    "question": "Name the fasting period for Muslims",
                    "type": "text",
                    "answer": [
                        "Ramadhan"
                    ],
                    "id": "ple_sst_sectA_or_26"
                }
            },
            {
                "either": {
                    "question": "Why is the Bible called a Holy book?",
                    "type": "multipleChoice",
                    "answer": [
                        "Its called a holy book because it carries God's word"
                    ],
                    "options": [
                        "Its called a holy book because it carries God's word",
                        "Its called a holy book because it read by christians"
                    ],
                    "id": "ple_sst_sectA_either_27"
                },
                "or": {
                    "question": "Why is the Qur'an called a Holy book?",
                    "type": "multipleChoice",
                    "answer": [
                        "Its called a holy book because it carries Allah's word"
                    ],
                    "options": [
                        "Its called a holy book because it carries Allah's word",
                        "Its called a holy book because it read by muslims"
                    ],
                    "id": "ple_sst_sectA_or_27"
                }
            },
            {
                "either": {
                    "question": "Name the Angel who brought the Good News about the birth of Jesus Christ.",
                    "type": "text",
                    "answer": [
                        "Angel Gabriel"
                    ],
                    "id": "ple_sst_sectA_either_28"
                },
                "or": {
                    "question": "Name the Angel who brought the Good News about the birth of Propher Isa",
                    "type": "text",
                    "answer": [
                        "Angel Jibril"
                    ],
                    "id": "ple_sst_sectA_or_28"
                }
            },
            {
                "either": {
                    "question": "Name the servant of God who suffered from serious illness but remained faithful.",
                    "type": "text",
                    "answer": [
                        "Job"
                    ],
                    "id": "ple_sst_sectA_either_29"
                },
                "or": {
                    "question": "Mention the Prophet of Allah who suffered from serious illnesses but remained faithful",
                    "type": "text",
                    "answer": [
                        "Prophet Ayub",
                        "Prophet Ayyub"
                    ],
                    "id": "ple_sst_sectA_or_29"
                }
            }
        ],
        "category": 36,
        "$id": "document_35"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Why was Kabaka Daudi Chwa unable to sign the 1900 Buganda agreement?",
                "type": "multipleChoice",
                "answer": [
                    "Because he was too young"
                ],
                "options": [
                    "Because he was not trusted by the British",
                    "Because he was too young"
                ],
                "sub_questions": [
                    {
                        "question": "Name the official who signed the 1900 Buganda agreement on behalf of the British.",
                        "type": "text",
                        "answer": [
                            "Sir Harry Johnston"
                        ]
                    },
                    {
                        "question": "Write down two terms of the 1900 Buganda agreement.",
                        "type": "check_box",
                        "answer": [
                            "Land",
                            "Taxation"
                        ],
                        "options": [
                            "Land",
                            "Slave trade",
                            "Labour",
                            "Taxation"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B41_1"
            },
            {
                "question": "Study the sketch map of East Africa below and use it to answer the questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2023_41.png",
                "sub_questions": [
                    {
                        "question": "State one ecomomic activity carried out by the people in the shaded area K.",
                        "type": "text",
                        "answer": [
                            "Pastoralism",
                            "Fishing",
                            "Tourism",
                            "Crop growing"
                        ]
                    },
                    {
                        "question": "How was the formation of Lake S different from that of Lake M?",
                        "type": "multipleChoice",
                        "answer": [
                            "Lake S was formed by down warping while Lake M was formed by faulting."
                        ],
                        "options": [
                            "Lake M was formed by down warping while Lake S was formed by faulting",
                            "Lake S was formed by down warping while Lake M was formed by faulting"
                        ]
                    },
                    {
                        "question": "In which one way the town T and island Q important to the Arabs?",
                        "type": "multipleChoice",
                        "answer": [
                            "Both were slave markets"
                        ],
                        "options": [
                            "Both were slave markets",
                            "Both were trade routes for the Arabs."
                        ]
                    },
                    {
                        "question": "Name the explorer whom Henry Morton Stanley met at the place marked R.",
                        "type": "text",
                        "answer": [
                            "Doctor David Livingstone"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B41_2"
            },
            {
                "question": "Study the sketch map of Kenya below and use it to answer the questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2022_41.png",
                "sub_questions": [
                    {
                        "question": "Name the ports marked C and D",
                        "type": "check_box",
                        "answer": [
                            "Port Mombasa",
                            "Port Kisumu"
                        ],
                        "options": [
                            "Port of Dar es Salaam",
                            "Port Mombasa",
                            "Port Kisumu"
                        ]
                    },
                    {
                        "question": "Give any one reason why the railway line was constructed from town C to town D.",
                        "type": "multipleChoice",
                        "answer": [
                            "To ease transport",
                            "To stop slave trade",
                            "To ease administration"
                        ],
                        "options": [
                            "To ease administration",
                            "To stop slave trade",
                            "To transport slaves",
                            "To ease transport"
                        ]
                    },
                    {
                        "question": "Mention one problem faced by the builders of the railway in the area marked K.",
                        "type": "multipleChoice",
                        "answer": [
                            "Attacks from man eaters",
                            "Attacks from fierce lions"
                        ],
                        "options": [
                            "Mosquito bites which caused malaria",
                            "Attacks from man eaters",
                            "Attacks from fierce lions"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B41_3"
            },
            {
                "question": "Select any two member states of the inter-Governmental Authority on Development (IGAD) apart from Kenya and Uganda.",
                "type": "check_box",
                "answer": [
                    "Djibouti",
                    "Eritrea",
                    "Ethiopia",
                    "Sudan"
                ],
                "options": [
                    "South Africa",
                    "Djibouti",
                    "Ethiopia",
                    "Sudan",
                    "Eritrea",
                    "Democratic Republic of Congo"
                ],
                "sub_questions": [
                    {
                        "question": "Give any two reasons why IGAD was formed.",
                        "type": "check_box",
                        "answer": [
                            "It was formed for conflict resolution and peacekeeping",
                            "It was formed for security and counter terrorism"
                        ],
                        "options": [
                            "It was formed to create a military alliance",
                            "It was formed for conflict resolution and peacekeeping",
                            "It was formed to promote regional justice",
                            "It was formed for security and counter terrorism"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B41_4"
            },
            {
                "question": "What do we call the movement of people from villages to towns for settlement?",
                "type": "text",
                "answer": [
                    "Rural urban migration"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two reasons why people migrate from villages to towns.",
                        "type": "check_box",
                        "answer": [
                            "To look for employment opportunities",
                            "To access improved standards of Living"
                        ],
                        "options": [
                            "To access a cheaper cost of living",
                            "To look for employment opportunities",
                            "To access improved standards of Living",
                            "The need to practice farming"
                        ]
                    },
                    {
                        "question": "Mention any one challenge faced by people who live in towns.",
                        "type": "multipleChoice",
                        "answer": [
                            "Increased cost of living",
                            "Pollution"
                        ],
                        "options": [
                            "Lack of employment opportunities",
                            "Increased cost of living",
                            "Pollution"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B41_5"
            },
            {
                "question": "Name the body that is responsible for organizing population census in Uganda.",
                "type": "text",
                "answer": [
                    "Uganda Bureau of Statistics",
                    "UBOS"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two reasons why people migrate from villages to towns.",
                        "type": "check_box",
                        "answer": [
                            "They migrate to look for better employment opportunities",
                            "They migrate to look for better education",
                            "They migrate to look for better healthcare"
                        ],
                        "options": [
                            "They migrate to look for better employment opportunities",
                            "They migrate to look for cheaper cost of living",
                            "They migrate to look for better agricultural opportunities",
                            "They migrate to look for better healthcare",
                            "They migrate to look for better education"
                        ]
                    },
                    {
                        "question": "Select any one challenges faced by the enumerators during census.",
                        "type": "multipleChoice",
                        "answer": [
                            "Language barrier",
                            "Resistance and non-cooperation"
                        ],
                        "options": [
                            "Resistance and non-cooperation",
                            "High cost of living",
                            "Language barrier"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B41_6"
            },
            {
                "question": "The diagram below is of the Uganda Coat of Arms. Use it to answer the questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2017_41.png",
                "sub_questions": [
                    {
                        "question": "Name the features marked B and D.",
                        "type": "check_box",
                        "answer": [
                            "Drum",
                            "Kob"
                        ],
                        "options": [
                            "Shield",
                            "Sun",
                            "Drum",
                            "Kob"
                        ]
                    },
                    {
                        "question": "What does the feature marked A represent?",
                        "type": "multipleChoice",
                        "answer": [
                            "It represents Uganda's bright future"
                        ],
                        "options": [
                            "It represents the willingness of the Ugandans to defend their country",
                            "It symbolizes grace, beauty, and the abundant wildlife in Uganda",
                            "It represents Uganda's bright future"
                        ]
                    },
                    {
                        "question": "Why was the feature C chosen as the national emblem?",
                        "type": "multipleChoice",
                        "answer": [
                            "It was chosen because of its beauty to represent Uganda's beauty"
                        ],
                        "options": [
                            "It was chosen to represent Uganda's natural vegetation",
                            "It was chosen because of its beauty to represent Uganda's beauty"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B41_7"
            },
            {
                "question": "Study the sketch map of East Africa below and use it to answer the questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2016_41.png",
                "sub_questions": [
                    {
                        "question": "Name the sea ports marked Y and W",
                        "type": "check_box",
                        "answer": [
                            "Port Mombasa",
                            "Port of Dar es Salaam"
                        ],
                        "options": [
                            "Port Kisumu",
                            "Port Mombasa",
                            "Port Mwanza",
                            "Port of Dar es Salaam"
                        ]
                    },
                    {
                        "question": "Why was the railway built from Port Y to country B?",
                        "type": "multipleChoice",
                        "answer": [
                            "It was built to transport goods easily"
                        ],
                        "options": [
                            "It was built to transport goods easily",
                            "It was built to transport slaves"
                        ]
                    },
                    {
                        "question": "Why is water transport difficult on the lake marked X?",
                        "type": "multipleChoice",
                        "answer": [
                            "It is difficult because it is shallow and swampy"
                        ],
                        "options": [
                            "It is difficult because of rapids and waterfalls",
                            "It is difficult because it is shallow and swampy"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B41_8"
            },
            {
                "question": "Name the two European explorers who made two journeys to East Africa.",
                "type": "check_box",
                "answer": [
                    "John Hanning Speke",
                    "Henry Morton Stanely"
                ],
                "options": [
                    "David Livingstone",
                    "John Hanning Speke",
                    "Henry Morton Stanely",
                    "Sir Samuel Baker"
                ],
                "sub_questions": [
                    {
                        "question": "Mention any two ways in which the work of the early explorers contributed to the development of Africa",
                        "type": "check_box",
                        "answer": [
                            "It led to the stopping of slave trade through articles by Dr. David Livingstone",
                            "They made Africa to be known to the rest of the world",
                            "They opened the way to the interior of Africa"
                        ],
                        "options": [
                            "They discovered Timbuktu",
                            "It led to the stopping of slave trade through articles by Dr. David Livingstone",
                            "They made Africa to be known to the rest of the world",
                            "They promoted slave trade",
                            "They opened the way to the interior of Africa"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B41_9"
            },
            {
                "question": "State any two traditional methods of catching fish in Uganda.",
                "type": "check_box",
                "answer": [
                    "Use of Baskets",
                    "Use of Hooks",
                    "Use of sticks or rods"
                ],
                "options": [
                    "Use of gill nets",
                    "Use of Baskets",
                    "Use of Hooks",
                    "Use of sticks or rods",
                    "Use of fishing boats"
                ],
                "sub_questions": [
                    {
                        "question": "Give any two reasons why modern methods of catching fish are commonly used in Lake Victoria",
                        "type": "check_box",
                        "answer": [
                            "Because Lake Victoria is wide and deep",
                            "Because of the availability of modern means of transport"
                        ],
                        "options": [
                            "Because Lake Victoria is wide and deep",
                            "Because of the availability of modern means of transport",
                            "Because there is no ready market for fish caught"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B41_10"
            },
            {
                "question": "Study the sketch map of Uganda below and answer the questions that follow:",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2013_41.png",
                "sub_questions": [
                    {
                        "question": "Name the ethnic group that followed the route marked by the arrows",
                        "type": "text",
                        "answer": [
                            "Bantu",
                            "Western Bantu",
                            "Inter custrine Bantu"
                        ]
                    },
                    {
                        "question": "Give any three reasons why the ethnic group in (a) above left their original home land",
                        "type": "check_box",
                        "answer": [
                            "They were escaping from diseases",
                            "They left duee to famine in their original home land",
                            "They left to look for fertile areas"
                        ],
                        "options": [
                            "They were escaping from diseases",
                            "They migrated to look for pasture and water for their cattle",
                            "They left due to famine in their original home land",
                            "They left to look for fertile areas",
                            "They left to escape cattle rustlers"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B41_11"
            },
            {
                "question": "The diagram below show the population distribution of two different area in a country. Use them to answer the question that follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2012_41.png",
                "sub_questions": [
                    {
                        "question": "What is the population distribution of area A?",
                        "type": "text",
                        "answer": [
                            "Sparsely populated ",
                            "Low population",
                            "Under populated"
                        ]
                    },
                    {
                        "question": "What is the population distribution of area B?",
                        "type": "text",
                        "answer": [
                            "Densely populated",
                            "High population",
                            "Over populated"
                        ]
                    },
                    {
                        "question": "Mention any one disadvantage of the population distribution of area B to country.",
                        "type": "multipleChoice",
                        "answer": [
                            "Shortage of land",
                            "High crime rate"
                        ],
                        "options": [
                            "Shortage of land",
                            "Low cost of living",
                            "High crime rate",
                            "Shortage of labour"
                        ]
                    },
                    {
                        "question": "Give any one advantage of the people living in area A over those living in area B.",
                        "type": "multipleChoice",
                        "answer": [
                            "People in area A have more land than those in area B",
                            "People in area A have chance of getting jobs compared to those in area B"
                        ],
                        "options": [
                            "People in area B have chance of getting jobs compared to those in area A",
                            "People in area A have more land than those in area B",
                            "People in area A have chance of getting jobs compared to those in area B",
                            "People in area B have more land than those in area A"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B41_12"
            },
            {
                "question": "Study the sketch map below and use it to answer the question follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2011_41.png",
                "sub_questions": [
                    {
                        "question": "Select any two social service found in this area.",
                        "type": "check_box",
                        "answer": [
                            "Transport service",
                            "Education service"
                        ],
                        "options": [
                            "Transport service",
                            "Medical service",
                            "Education service"
                        ]
                    },
                    {
                        "question": "Give any one reason why many people would settle in this area.",
                        "type": "multipleChoice",
                        "answer": [
                            "Availability of social services",
                            "Availability of a market"
                        ],
                        "options": [
                            "Availability of social services",
                            "Availability of a market",
                            "Availability of fertile land"
                        ]
                    },
                    {
                        "question": "What direction is the post office from the factory?",
                        "type": "text",
                        "answer": [
                            "North"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B41_13"
            },
            {
                "question": "State any two reasons for keeping domestic animals.",
                "type": "check_box",
                "answer": [
                    "They are a source of food",
                    "They are a source of income"
                ],
                "options": [
                    "They are a source of food",
                    "They are a source of income",
                    "They are kept for security"
                ],
                "sub_questions": [
                    {
                        "question": "Give any two reasons why some homes do not keep domestic animals",
                        "type": "check_box",
                        "answer": [
                            "Shortage of enough land",
                            "Due to cultural or religious beliefs"
                        ],
                        "options": [
                            "Shortage of enough land",
                            "Availability of social services",
                            "Due to cultural or religious beliefs"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B41_14"
            },
            {
                "question": "Study the table below showing the climate of place A and answer question that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2009_41.png",
                "sub_questions": [
                    {
                        "question": "In which month of the year does place A receive the highest amount of rainfall.",
                        "type": "text",
                        "answer": [
                            "June"
                        ]
                    },
                    {
                        "question": "Give any one reason why crop can be grown at any time of the year at place A",
                        "type": "multipleChoice",
                        "answer": [
                            "Because place A receives rainfall throughout the year",
                            "The temperatures throughout the year are moderate and conducive for plant growth."
                        ]
                    },
                    {
                        "question": "Mention one cash crop that can grow well under this type of climate",
                        "type": "text",
                        "answer": [
                            "Coffee",
                            "Wheat"
                        ]
                    },
                    {
                        "question": "Calculate the temperature range of place A",
                        "type": "multipleChoice",
                        "answer": [
                            "4<sup>o</sup>C"
                        ],
                        "explanation": [
                            "Range = Highest temperature - lowest temperature<br>= 25<sup>o</sup>C- 21<sup>o</sup>C<br>= 4<sup>o</sup>C"
                        ],
                        "options": [
                            "4<sup>o</sup>C",
                            "5<sup>o</sup>C",
                            "75<sup>o</sup>C"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B41_15"
            },
            {
                "question": "Name one missionary who contributed to the economic development of Uganda.",
                "type": "text",
                "answer": [
                    "Kenneth Borup",
                    "Doctor Albert Cook",
                    "Father John Mary Waliggo"
                ],
                "sub_questions": [
                    {
                        "question": "Select any three ways in which the missionaries contributed to the economic development of Uganda?",
                        "type": "check_box",
                        "answer": [
                            "They introduced research in medicine and fought against diseases such as sleeping sickness",
                            "Missionaries built hospital for instance Mengo, Rubaga and Nsambya hospitals",
                            "The missionaries skilled Ugandans wiith various skills"
                        ],
                        "options": [
                            "They introduced research in medicine and fought against diseases such as sleeping sickness",
                            "They abolished slave trade",
                            "Missionaries built hospital for instance Mengo, Rubaga and Nsambya hospitals",
                            "The missionaries skilled Ugandans with various skills like capentry"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B41_16"
            },
            {
                "question": "Use the map of East Africa given below to answer the questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2007_41.png",
                "sub_questions": [
                    {
                        "question": "Name the country marked the letter A",
                        "type": "text",
                        "answer": [
                            "Ethiopia"
                        ]
                    },
                    {
                        "question": "Name the mountain marked with the letter D.",
                        "type": "text",
                        "answer": [
                            "Mt. Kilimanjaro",
                            "Mountain Kilimanjaro",
                            "Kilimanjaro"
                        ]
                    },
                    {
                        "question": "Name the shaded Ocean marked with letter X.",
                        "type": "text",
                        "answer": [
                            "Indian Ocean"
                        ]
                    },
                    {
                        "question": "Name the country south of the country marked the letter A.",
                        "type": "text",
                        "answer": [
                            "Kenya"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B41_17"
            },
            {
                "question": "Study the map of Uganda given below and use it to answer the questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2006_41.png",
                "sub_questions": [
                    {
                        "question": "Name the town marked with the letter P.",
                        "type": "text",
                        "answer": [
                            "Jinja"
                        ]
                    },
                    {
                        "question": "Name the lake marked with letter B.",
                        "type": "text",
                        "answer": [
                            "Lake Albert"
                        ]
                    },
                    {
                        "question": "Name the country south-west of Uganda.",
                        "type": "text",
                        "answer": [
                            "Rwanda"
                        ]
                    },
                    {
                        "question": "Give the main occupation of the people who live in the shaded area marked with the letter N.",
                        "type": "text",
                        "answer": [
                            "Normadic pastroralism",
                            "Pastroralism"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B41_18"
            },
            {
                "question": "Use the map of Uganda given below to answer the questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2005_41.png",
                "sub_questions": [
                    {
                        "question": "Name the water body marked with letter A.",
                        "type": "text",
                        "answer": [
                            "Kazinga channel"
                        ]
                    },
                    {
                        "question": "Name the town marked with letter C.",
                        "type": "text",
                        "answer": [
                            "Arua"
                        ]
                    },
                    {
                        "question": "Why is the distance from town B to town C shorter by plane than by bus?",
                        "type": "multipleChoice",
                        "answer": [
                            "It is shorter by plane because a plane would fly directly in a straight line yet a bus cannot because of obstacles like lakes and forests."
                        ],
                        "options": [
                            "It is shorter because a plane is faster than a bus",
                            "It is shorter by plane because a plane would fly directly in a straight line yet a bus cannot because of obstacles like lakes and forests"
                        ]
                    },
                    {
                        "question": "What direction is town C from town D?",
                        "type": "text",
                        "answer": [
                            "North west direction",
                            "North west"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B41_19"
            }
        ],
        "category": 41,
        "$id": "document_36"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Select where the Akosombo dam is found",
                "type": "mutipleChoice",
                "answer": [
                    "River Volta"
                ],
                "options": [
                    "River Niger",
                    "River Volta",
                    "River Zambezi",
                    "River Congo"
                ],
                "sub_questions": [
                    {
                        "question": "Select where the Kariba dam is found",
                        "type": "multipleChoice",
                        "answer": [
                            "River Zambezi"
                        ],
                        "options": [
                            "River Niger",
                            "River Volta",
                            "River Zambezi",
                            "River Congo"
                        ]
                    },
                    {
                        "question": "Select where the Kainji dam is found",
                        "type": "multipleChoice",
                        "answer": [
                            "River Niger"
                        ],
                        "options": [
                            "River Niger",
                            "River Volta",
                            "River Zambezi",
                            "River Congo"
                        ]
                    },
                    {
                        "question": "Select where the Inga dam is found",
                        "type": "multipleChoice",
                        "answer": [
                            "River Congo"
                        ],
                        "options": [
                            "River Niger",
                            "River Volta",
                            "River Zambezi",
                            "River Congo"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B42_1"
            },
            {
                "question": "Name one symbol of authority used by the Speaker of Parliament of Uganda during parliamentary sessions",
                "type": "text",
                "answer": [
                    "The mace",
                    "Mace",
                    "The wig",
                    "Wig",
                    "A copy of the Uganda National constitution"
                ],
                "sub_questions": [
                    {
                        "question": "State one role played by clerk to parliament in parliamet",
                        "type": "multipleChoice",
                        "answer": [
                            "To record parliamentary proceedings",
                            "To keep records of parliamentary sessions"
                        ],
                        "options": [
                            "To ensure security in the parliament",
                            "To record parliamentary proceedings",
                            "To keep records of parliamentary sessions",
                            "To carry the mace of the speaker"
                        ]
                    },
                    {
                        "question": "State one role played by sergeant-at-arms in parliamet",
                        "type": "multipleChoice",
                        "answer": [
                            "To ensure security in the parliament",
                            "To carry the mace of the speaker"
                        ],
                        "options": [
                            "To ensure security in the parliament",
                            "To record parliamentary proceedings",
                            "To keep records of parliamentary sessions",
                            "To carry the mace of the speaker"
                        ]
                    },
                    {
                        "question": "Give the difference between a bill and a law",
                        "type": "multipleChoice",
                        "answer": [
                            "A bill is a proposed law in the parliament while a law is an act signed by the president"
                        ],
                        "options": [
                            "A bill is an act signed by the president while a law is a proposed law in the parliament",
                            "A bill is a proposed law in the parliament while a law is an act signed by the president"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B42_2"
            },
            {
                "question": "Select any two raw materials the Europeans received from Americas during the Trans-Atlantic trade.",
                "type": "check_box",
                "answer": [
                    "Sugar",
                    "Tobacco",
                    "Coffee"
                ],
                "optios": [
                    "Sugar",
                    "Slaves",
                    "Tobacco",
                    "Coffee",
                    "Gold"
                ],
                "sub_questions": [
                    {
                        "question": "State any two postive effects of the Trans-Atlantic trade on the people of Africa",
                        "type": "check_box",
                        "answer": [
                            "New trade items were introduced in Africa",
                            "It led to developed of towns"
                        ],
                        "options": [
                            "It led to economic underdevelopment",
                            "New trade items were introduced in Africa",
                            "It led to loss of life through slave trade",
                            "It led to developed of towns"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B42_3"
            },
            {
                "question": "Study the sketch map of Africa below and answer the questions that follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2020_42.png",
                "sub_questions": [
                    {
                        "question": "Select the countries marked A and D",
                        "type": "check_box",
                        "answer": [
                            "Libya",
                            "South Africa"
                        ],
                        "options": [
                            "Zimbabwe",
                            "Libya",
                            "Egypt",
                            "South Africa"
                        ]
                    },
                    {
                        "question": "What advantage does country marked C have over country B in terms of location?",
                        "type": "multipleChoice",
                        "answer": [
                            "Country C has a sea port unlike country B"
                        ],
                        "options": [
                            "Country C is bigger than country B",
                            "Country C has a sea port unlike country B"
                        ]
                    },
                    {
                        "question": "Which climatic region of Africa is found in the shaded area marked E?",
                        "type": "text",
                        "answer": [
                            "Mediterranean climatic region",
                            "Mediterranean"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B42_4"
            },
            {
                "question": "Study the sketch map of East Africa below and answer the questions that follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2019_42.png",
                "sub_questions": [
                    {
                        "question": "Name the ethinic group that settled in the area marked T.",
                        "type": "text",
                        "answer": [
                            "Cushites",
                            "Hamites"
                        ]
                    },
                    {
                        "question": "Mention any one of the archeological sites in the area marked S",
                        "type": "text",
                        "answer": [
                            "Lake Eyasi stone age site",
                            "Apis rock stone age site",
                            "Engaruka stone age site",
                            "Olduvai gorge stone age site"
                        ]
                    },
                    {
                        "question": "Select any two reasons why colonialists were interested in finding out the source of River marked Q above.",
                        "type": "check_box",
                        "answer": [
                            "To find out whether it was navigable",
                            "To strengthen their position in Egypt"
                        ],
                        "options": [
                            "To find out whether it was navigable",
                            "To find out where it ends",
                            "To strengthen their position in Egypt"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B42_5"
            },
            {
                "question": "Mention the system of Administration that was used by the French in West Africa.",
                "type": "text",
                "answer": [
                    "Indirect rule"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two ways in which the system named in (a) above was implemented.",
                        "type": "check_box",
                        "answer": [
                            "They used the kings and chiefs to administer their rule",
                            "Through missionary work to spread western culture and values"
                        ],
                        "options": [
                            "They maintained a significant military presence in their colonies",
                            "They used the kings and chiefs to administer their rule",
                            "They centralised administration in West Africa",
                            "Through missionary work to spread western culture and values"
                        ]
                    },
                    {
                        "question": "State any one negative effect of the above named system.",
                        "type": "multipleChoice",
                        "answer": [
                            "The divide and rule tactics led to ethnic and tribal divisions",
                            "The imposition of European education and values led to the erosion of African culture"
                        ],
                        "options": [
                            "The divide and rule tactics led to ethnic and tribal divisions",
                            "The imposition of European education and values led to the erosion of African culture",
                            "It led to military repression"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B42_6"
            },
            {
                "question": "State any two rights that children should enjoy.",
                "type": "check_box",
                "answer": [
                    "Right to Education",
                    "Right to Health"
                ],
                "options": [
                    "Right to Education",
                    "Right to marriage",
                    "Right to Health",
                    "Right to vote"
                ],
                "sub_questions": [
                    {
                        "question": "Give any two responsibilities that children must observe",
                        "type": "check_box",
                        "answer": [
                            "Respect for others",
                            "Respect for property"
                        ],
                        "options": [
                            "Respect for others",
                            "Legal Responsibilities",
                            "Respect for property",
                            "Civic Engagement"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B42_7"
            },
            {
                "question": "What is barter trade?",
                "type": "multipleChoice",
                "answer": [
                    "Barter trade is a system of exchange where goods and services are directly exchanged for other goods and services"
                ],
                "options": [
                    "",
                    "Barter trade is a system of exchange where goods and services are directly exchanged for other goods and services",
                    "Barter trade is a system of exchange where goods and services are directly exchanged for money"
                ],
                "sub_questions": [
                    {
                        "question": "Select one factor that made barter trade possible before colonial rule.",
                        "type": "multipleChoice",
                        "answer": [
                            "Absence of a common currency"
                        ],
                        "options": [
                            "Absence of a common currency",
                            "The goods being portable"
                        ]
                    },
                    {
                        "question": "State any two effects of local trade among different societies before the coming of colonial rule.",
                        "type": "check_box",
                        "answer": [
                            "Low population",
                            "Goods had identical quality"
                        ],
                        "options": [
                            "Low population",
                            "Reduced transaction Costs",
                            "Goods had identical quality",
                            "Easy divisibility of goods"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B42_8"
            },
            {
                "question": "What title is given to the highest civil servant in a ministry?",
                "type": "text",
                "answer": [
                    "Permanent secretary"
                ],
                "sub_questions": [
                    {
                        "question": "State the way in which one can become a chairperson LCV in any district of Uganda",
                        "type": "multipleChoice",
                        "answer": [
                            "Through elections"
                        ],
                        "options": [
                            "Through appointments",
                            "Through elections",
                            "Through succession"
                        ]
                    },
                    {
                        "question": "Select any two ways in which leaders can improve on the welfare of the people in their communities",
                        "type": "check_box",
                        "answer": [
                            "They introduce developmental projects",
                            "They look for funds outside the country"
                        ],
                        "options": [
                            "By giving them money",
                            "They can introduce developmental projects",
                            "They can look for funds outside the country",
                            "They can build for them houses"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B42_9"
            },
            {
                "question": "Name the continent from which Arab traders came.",
                "type": "text",
                "answer": [
                    "Asia"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two reasons why the early Arab traders settled at the coast of East Africa.",
                        "type": "check_box",
                        "answer": [
                            "East Africa's favorable climate",
                            "They wanted to carry out trade at the coast of East Africa"
                        ],
                        "options": [
                            "East Africa's favorable climate",
                            "They wanted to explore the East African coast",
                            "They wanted to carry out trade at the coast of East Africa",
                            "They came to spread christianity"
                        ]
                    },
                    {
                        "question": "State any one way in which the Arabs benefitted from their stay at the coast of East Africa.",
                        "type": "multipleChoice",
                        "answer": [
                            "They converted people to Islam",
                            "They got market for their goods"
                        ],
                        "options": [
                            "They gained colonial territories",
                            "They converted people to Islam",
                            "They got market for their goods"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B42_10"
            },
            {
                "question": "The diagram below shows a school budget. Use it to answer the questions that follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2013_42.png",
                "sub_questions": [
                    {
                        "question": "Give the type of Budget shown in the diagram above",
                        "type": "text",
                        "answer": [
                            "Deficit budget",
                            "Loss budget"
                        ]
                    },
                    {
                        "question": "Give any two problems a school may face when  it has a budget shown above.",
                        "type": "check_box",
                        "answer": [
                            "It leads to debts",
                            "It leads to shortages in school"
                        ],
                        "options": [
                            "It leads to debts",
                            "It leads to shortages in school",
                            "It leads to late closure of the school term",
                            "It leads to unnecessary spending"
                        ]
                    },
                    {
                        "question": "State any one reason a school should have a budget.",
                        "type": "multipleChoice",
                        "answer": [
                            "To identify priorities for proper planning"
                        ],
                        "options": [
                            "To identify priorities for proper planning",
                            "To avoid budget constraints"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B42_11"
            },
            {
                "question": "Select any two economic activities carried out in Kalangala District",
                "type": "check_box",
                "answer": [
                    "Fishing",
                    "Tourism"
                ],
                "options": [
                    "Gold mining",
                    "Fishing",
                    "Tourism"
                ],
                "sub_questions": [
                    {
                        "question": "Select any one problem that has slowed down the development of Kalangala District",
                        "type": "multipleChoice",
                        "answer": [
                            "Poor tansport system",
                            "Low levels of education"
                        ],
                        "options": [
                            "Poor tansport system",
                            "Low levels of education",
                            "Lack of market for the goods from the district"
                        ]
                    },
                    {
                        "question": "Select any one way in which the problems of Kalangala District can be solved",
                        "type": "multipleChoice",
                        "answer": [
                            "By sensitizing parents and children about values of education",
                            "By providing more boats to boast transport"
                        ],
                        "options": [
                            "By providing more boats to boast transport",
                            "By sensitizing parents and children about values of education",
                            "By looking for market for the goods"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B42_12"
            },
            {
                "question": "Select any two plantation crops grow in Uganda",
                "type": "check_box",
                "answer": [
                    "Tea",
                    "Sugarcane"
                ],
                "options": [
                    "Tea",
                    "Beans",
                    "Maize",
                    "Sugarcane"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two advantages of plantation farming",
                        "type": "multipleChoice",
                        "answer": [
                            "It generates high yields",
                            "It offers employment to the people"
                        ],
                        "options": [
                            "It is cheaper and easier to practice",
                            "It generates high yields",
                            "It offers employment to the people",
                            "It can be carried out on small acreage"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B42_13"
            },
            {
                "question": "Into which Sea does River Nile flow?",
                "type": "text",
                "answer": [
                    "Mediterranean Sea"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two advantages of River Nile to the people who live along it",
                        "type": "check_box",
                        "answer": [
                            "It provides a fishing ground",
                            "It acts as a source of water for irrigation",
                            "It helps in the generation of hydro electricity"
                        ],
                        "options": [
                            "It provides the raw material in salt mining",
                            "It provides a fishing ground",
                            "It acts as a source of water for irrigation",
                            "It provides every transport to the people",
                            "It helps in the generation of hydro electricity"
                        ]
                    },
                    {
                        "question": "Select any one problem that people who live along River Nile experience",
                        "type": "multipleChoice",
                        "answer": [
                            "Water borne diseases"
                        ],
                        "options": [
                            "Water borne diseases",
                            "Salty water"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B42_14"
            },
            {
                "question": "Select any two problems that are caused by floods",
                "type": "check_box",
                "answer": [
                    "Destruction of property and crops",
                    "Destruction of infrastructures like roads"
                ],
                "options": [
                    "Destruction of property and crops",
                    "They lead to drought",
                    "Destruction of infrastructures like roads",
                    "They lead to air pollution"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two ways in which the government can help the people affected by floods",
                        "type": "check_box",
                        "answer": [
                            "It can provide them with food",
                            "It can provide them with shelter"
                        ],
                        "options": [
                            "By providing them with face masks",
                            "It can provide them with food",
                            "It can provide them with shelter"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B42_15"
            },
            {
                "question": "Select any four factors that have made the Republic of South Africa a leading industrial country in Africa.",
                "type": "check_box",
                "answer": [
                    "The presence of so many minerals in South Africa",
                    "The politically stability of South Africa",
                    "South Africa's good transport and communication network",
                    "The capital contributed by early settlers"
                ],
                "options": [
                    "The capital contributed by early settlers",
                    "The unity of the people living in South Africa",
                    "The presence of so many minerals in South Africa",
                    "The politically stability of South Africa",
                    "The early gaining of independence by South Africa",
                    "South Africa's good transport and communication network",
                    "South Africa being the biggest country in Africa"
                ],
                "id": "ple_sst_sect_B42_16"
            },
            {
                "question": "Select the two parts of the budget",
                "type": "check_box",
                "answer": [
                    "Income",
                    "Expenditure"
                ],
                "options": [
                    "Balanced budget",
                    "Defict Budget",
                    "Income",
                    "Expenditure"
                ],
                "sub_questions": [
                    {
                        "question": "If you were given sh 20,000 as pocket money, Select any two reasons why you need to budget before you spend the money",
                        "type": "check_box",
                        "answer": [
                            "So that I don't spend more that what I don't have",
                            "To guide my expenditure"
                        ],
                        "options": [
                            "To guide my expenditure",
                            "So that I don't spend more that what I don't have",
                            "To avoid budget constraints"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B42_17"
            },
            {
                "question": "Select any two two basic human needs",
                "type": "check_box",
                "answer": [
                    "Food",
                    "Clothing"
                ],
                "options": [
                    "Money",
                    "Clothing",
                    "Land",
                    "Food"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two reasons why it is difficult for people to satisfy their needs",
                        "type": "check_box",
                        "answer": [
                            "The scarcity enough money due to umemployment",
                            "The change in taste over time"
                        ],
                        "options": [
                            "Some people don't want to satisfy their needs",
                            "The scarcity enough money due to umemployment",
                            "The change in taste over time"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B42_18"
            },
            {
                "question": "Which of the following images represent a map of a cup",
                "type": "multipleChoice",
                "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2005_42.png",
                "options": [
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2005_42a.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2005_42b.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2005_42.png"
                ],
                "sub_questions": [
                    {
                        "question": "Which of the following images represent a bottle ",
                        "type": "multipleChoice",
                        "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2005_42c.png",
                        "options": [
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2005_42a.png",
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2005_42c.png"
                        ]
                    },
                    {
                        "question": "What is a map?",
                        "type": "multipleChoice",
                        "answer": [
                            "A map is a representation of an object as seen from above"
                        ],
                        "options": [
                            "A map is a representation of an object as seen from above",
                            "A map is a representation of an object as seen from above"
                        ]
                    },
                    {
                        "question": "What is a picture?",
                        "type": "multipleChoice",
                        "answer": [
                            "A picture is a representation of an object as seen from above"
                        ],
                        "options": [
                            "A map is a representation of an object as seen from above",
                            "A picture is a representation of an object as seen from above"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B42_19"
            }
        ],
        "category": 42,
        "$id": "document_37"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Which ministry in Uganda is responsible for tourism?",
                "type": "text",
                "answer": [
                    "Ministry of Tourism Wildlife and Antiquities"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two importance of the tourism industry to Uganda",
                        "type": "check_box",
                        "answer": [
                            "It is a source of government revenue",
                            "It creates employment opportunities to people"
                        ],
                        "options": [
                            "It is a source of government revenue",
                            "It helps to provide health services to Ugandans",
                            "It helps to fight corruption",
                            "It creates employment opportunities to people"
                        ]
                    },
                    {
                        "question": "What can the government of Uganda do to attract more tourists into the country?",
                        "type": "multipleChoice",
                        "answer": [
                            "By advertising Uganda's tourist attractions on international media"
                        ],
                        "options": [
                            "By fighting corruption in Uganda",
                            "By advertising Uganda's tourist attractions on international media"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B43_1"
            },
            {
                "question": "Select any two ways in which decentralisation of power to local leaders benefit the people of Uganda",
                "type": "check_box",
                "answer": [
                    "It eases administration",
                    "It brings services closer to people"
                ],
                "options": [
                    "It promotes a sense of national unity",
                    "It eases administration",
                    "It brings services closer to people",
                    "It helps in efficient decision-making processes"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two challenges being faced by new districts in Uganda",
                        "type": "check_box",
                        "answer": [
                            "Shortage of funds",
                            "Corrupt officials"
                        ],
                        "options": [
                            "Shortage of funds",
                            "Corrupt officials",
                            "Services are far away from the people"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B43_2"
            },
            {
                "question": "Name any one common natural disaster in Uganda.",
                "type": "text",
                "answer": [
                    "Drought",
                    "Landslides",
                    "Floods"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two human activities that cause disasters in Uganda.",
                        "type": "check_box",
                        "answer": [
                            "Deforestation",
                            "Swamp drainage"
                        ],
                        "options": [
                            "Deforestation",
                            "High population",
                            "Swamp drainage",
                            "Illegal fishing"
                        ]
                    },
                    {
                        "question": "Select any one way of controlling disasters in mountaineous areas",
                        "type": "multipleChoice",
                        "answer": [
                            "By planting trees on mountain slopes"
                        ],
                        "options": [
                            "By practicing proper fishing methods",
                            "By using birth control",
                            "By planting trees on mountain slopes"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B43_3"
            },
            {
                "question": "Select any two reasons why the bank of Uganda is important to the government.",
                "type": "check_box",
                "answer": [
                    "It prints new currency",
                    "It regulates commercial banks"
                ],
                "options": [
                    "It provides laons to Ugandans",
                    "It prints new currency",
                    "It regulates commercial banks",
                    "It prepares the national budget"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two ways in which a person benefits from keeping money in a bank",
                        "type": "check_box",
                        "answer": [
                            "A person is able to access loans",
                            "It ensures safety of the person's money"
                        ],
                        "options": [
                            "A person is able to access loans",
                            "It helps them to spend more money",
                            "It ensures safety of the person's money",
                            "It makes people rich."
                        ]
                    }
                ],
                "id": "ple_sst_sect_B43_4"
            },
            {
                "question": "In which country did the Maji-Maji rebellion take place?",
                "type": "text",
                "answer": [
                    "Tanzania",
                    "Tanganyika"
                ],
                "sub_questions": [
                    {
                        "question": "Select two causes of Maji-Maji rebellion",
                        "type": "check_box",
                        "answer": [
                            "Heavy taxation",
                            "Forced labour"
                        ],
                        "options": [
                            "Displacement of people from their land",
                            "The desire for independence",
                            "Heavy taxation",
                            "Forced labour"
                        ]
                    },
                    {
                        "question": "Mention any one negative effect of the Maji-Maji rebellion on the Africans.",
                        "type": "multipleChoice",
                        "answer": [
                            "It led to the loss of lives"
                        ],
                        "options": [
                            "It led to independence of Africans",
                            "It led to the loss of lives"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B43_5"
            },
            {
                "question": "Select any two minerals mined in South Africa.",
                "type": "check_box",
                "answer": [
                    "Gold",
                    "Diamond"
                ],
                "options": [
                    "Oil",
                    "Gold",
                    "Diamond",
                    "Lead",
                    "Zinc"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two economic benefits of the mining industry to South Africa.",
                        "type": "check_box",
                        "answer": [
                            "It is a source of income and employment",
                            "It is a source of government revenue through exports"
                        ],
                        "options": [
                            "It helped South Africa to attain independence",
                            "It is a source of income and employment",
                            "It helps to reduce pollution in South Africa",
                            "It is a source of government revenue through exports"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B43_6"
            },
            {
                "question": "Select any two ways in which human activities can negatively affect the climate of an area.",
                "type": "check_box",
                "answer": [
                    "Human activities like deforestation lead to drought",
                    "Human activities like swamp drainage lead to desertification"
                ],
                "options": [
                    "Human activities lead to slow economic growth",
                    "Human activities like deforestation lead to drought",
                    "Human activities like swamp drainage lead to desertification",
                    "Human activities lead to high population"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two reasons why farmers should know the weather patterns in their area",
                        "type": "check_box",
                        "answer": [
                            "To know when to plant seeds",
                            "To know when to harvest crops"
                        ],
                        "options": [
                            "To know when to plant seeds",
                            "To know when to harvest crops",
                            "To know where to settle"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B43_7"
            },
            {
                "question": "The pie chart below shows a country's earning. Use it to answer the questions that follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2016_43.png",
                "sub_questions": [
                    {
                        "question": "Which is the major export earner of this country?",
                        "type": "text",
                        "answer": [
                            "Agriculture"
                        ]
                    },
                    {
                        "question": "Name the second biggest export earner of this country",
                        "type": "text",
                        "answer": [
                            "Minerals"
                        ]
                    },
                    {
                        "question": "If the total earning of the country is US dollars 240 million, find how much money was earned from tourism",
                        "type": "multipleChoice",
                        "answer": [
                            "$50 million"
                        ],
                        "explanation": "The degree for tourism is 75<sup>0</sup><br>= 75<sup>0</sup>/360<sup>0</sup> X $ 240 million<br>= 75/3 X 2 million<br>= $ 25 X 2 million<br>= $50 million",
                        "options": [
                            "$50 million",
                            "$40 million",
                            "$150 million"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B43_8"
            },
            {
                "question": "Study the sketch map of East Africa below and answer the questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2015_43.png",
                "sub_questions": [
                    {
                        "question": "Name the mountain peak marked with letter A",
                        "type": "text",
                        "answer": [
                            "Kilimanjaro",
                            "Kibo"
                        ]
                    },
                    {
                        "question": "Why is the shaded area marked with letter N not suitable for plantation farming?",
                        "type": "multipleChoice",
                        "answer": [
                            "Because it is a semi-arid desert"
                        ],
                        "options": [
                            "Because it is a semi-arid desert",
                            "Beacuse it has infertile grounds"
                        ]
                    },
                    {
                        "question": "Give one advantage country marked with letter Z has over Rwandan when carrying out international trade.",
                        "type": "multipleChoice",
                        "answer": [
                            "Country Z pays less tax on imports than Rwanda"
                        ],
                        "options": [
                            "Country Z is bigger than Rwanda",
                            "Country Z pays less tax on imports than Rwanda"
                        ]
                    },
                    {
                        "question": "Give any one reason why settlement is not allowed in the area marked S.",
                        "type": "multipleChoice",
                        "answer": [
                            "Because it is a game park"
                        ],
                        "options": [
                            "Because there is a lake",
                            "Because it is a game park"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B43_9"
            },
            {
                "question": "Select any two reasons why the government carries out population census.",
                "type": "check_box",
                "answer": [
                    "To know the number of people in a country",
                    "To establish educational level of citizens"
                ],
                "options": [
                    "To distribute land to the people",
                    "To know the number of people in a country",
                    "To establish educational level of citizens"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two reasons why the slopes of mountain Elgon have a high population.",
                        "type": "check_box",
                        "answer": [
                            "Because of the presence of volcanic fertile soils",
                            "Because the slopes of mountain Elgon receives reliable rainfall"
                        ],
                        "options": [
                            "Because of the presence of volcanic fertile soils",
                            "Because the slopes of mountain Elgon receives reliable rainfall",
                            "Because its safe to live on the slopes of mountain Elgon"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B43_10"
            },
            {
                "question": "Select the use of each element on a map",
                "sub_questions": [
                    {
                        "question": "Title",
                        "type": "multipleChoice",
                        "answer": [
                            "To understand what the map is all about"
                        ],
                        "options": [
                            "To calculate actual distance between place on a map",
                            "To interpret symbols on a map",
                            "To understand what the map is all about",
                            "To find direction between place on a map"
                        ]
                    },
                    {
                        "question": "Scale",
                        "type": "multipleChoice",
                        "answer": [
                            "To calculate actual distance between place on a map"
                        ],
                        "options": [
                            "To calculate actual distance between place on a map",
                            "To interpret symbols on a map",
                            "To understand what the map is all about",
                            "To find direction between place on a map"
                        ]
                    },
                    {
                        "question": "Key",
                        "type": "multipleChoice",
                        "answer": [
                            "To interpret symbols on a map"
                        ],
                        "options": [
                            "To calculate actual distance between place on a map",
                            "To interpret symbols on a map",
                            "To understand what the map is all about",
                            "To find direction between place on a map"
                        ]
                    },
                    {
                        "question": "Compass",
                        "type": "multipleChoice",
                        "answer": [
                            "To find direction between place on a map"
                        ],
                        "options": [
                            "To calculate actual distance between place on a map",
                            "To interpret symbols on a map",
                            "To understand what the map is all about",
                            "To find direction between place on a map"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B43_11"
            },
            {
                "question": "Give one safe method of keeping money.",
                "type": "text",
                "answer": [
                    "By use of a bank",
                    "Use of mobile money",
                    "Use of SACCOs"
                ],
                "sub_questions": [
                    {
                        "question": "Select any three reasons why Ugandan are encouraged to save money today",
                        "type": "check_box",
                        "answer": [
                            "To use it in future",
                            "To avoid wastage",
                            "To access loans"
                        ],
                        "options": [
                            "To pay taxes",
                            "To use it in future",
                            "To help the government",
                            "To be rich",
                            "To avoid wastage",
                            "To access loans"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B43_12"
            },
            {
                "question": "Select any two reasons why a family needs to make a budget.",
                "type": "check_box",
                "answer": [
                    "To identify sources of income",
                    "To avoid over spending"
                ],
                "options": [
                    "To be rich",
                    "To identify sources of income",
                    "To avoid over spending",
                    "To help the government"
                ],
                "sub_questions": [
                    {
                        "question": "What type of budget can bring development in a family?",
                        "type": "text",
                        "answer": [
                            "Surplus budget"
                        ]
                    },
                    {
                        "question": "In which one way can the budget you have mentioned above bring development in the family?",
                        "type": "multipleChoice",
                        "answer": [
                            "It  helps the family to save for the future"
                        ],
                        "options": [
                            "It  helps the family to save for the future",
                            "It helps the family to pay more taxes"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B43_13"
            },
            {
                "question": "Select any two causes of famine in Africa.",
                "type": "check_box",
                "answer": [
                    "Infertile soils",
                    "Poverty"
                ],
                "options": [
                    "Infertile soils",
                    "Thick vegetation",
                    "Poverty",
                    "Lack of land to grow food"
                ],
                "sub_questions": [
                    {
                        "question": "Select two ways in which illiteracy may lead to poor farming practice",
                        "type": "check_box",
                        "answer": [
                            "It leads to poor farming methods",
                            "It leads to poor attitude towards work"
                        ],
                        "options": [
                            "It leads to exploitation",
                            "It leads to over working",
                            "It leads to poor farming methods",
                            "It leads to poor attitude towards work"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B43_14"
            },
            {
                "question": "Select any two reasons why good roads are important in the development of an industry.",
                "type": "multipleChoice",
                "answer": [
                    "They ease transportation of finished goods to the market",
                    "They lower cost of production of goods"
                ],
                "options": [
                    "They ease transportation of finished goods to the market",
                    "They help to collect more taxes",
                    "They promote good work attitudes",
                    "They lower cost of production of goods"
                ],
                "sub_questions": [
                    {
                        "question": "Give the importance of labour in the development of an industry",
                        "type": "multipleChoice",
                        "answer": [
                            "It helps to operate machines"
                        ],
                        "options": [
                            "It helps to operate machines",
                            "It helps to purchase land and machinery"
                        ]
                    },
                    {
                        "question": "Give the importance of capital in the development of an industry",
                        "type": "multipleChoice",
                        "answer": [
                            "It helps to purchase land and machinery"
                        ],
                        "options": [
                            "It helps to operate machines",
                            "It helps to purchase land and machinery"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B43_15"
            },
            {
                "question": "Use the map below to answer the question that follow:",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2008_43.png",
                "sub_questions": [
                    {
                        "question": "Name the country marked A",
                        "type": "text",
                        "answer": [
                            "Ethiopia"
                        ]
                    },
                    {
                        "question": "Give one cause of low population in the area marked S",
                        "type": "multipleChoice",
                        "answer": [
                            "The existence of tsetse flies that spread sleeping sickness to people and nagana to cattle"
                        ],
                        "options": [
                            "The area is semi arid and infertile",
                            "The existence of tsetse flies that spread sleeping sickness to people and nagana to cattle"
                        ]
                    },
                    {
                        "question": "State one economic activity that is carried out in the lake marked X.",
                        "type": "text",
                        "answer": [
                            "Fishing"
                        ]
                    },
                    {
                        "question": "Give one similarity in  the formation of lake X and W",
                        "type": "multipleChoice",
                        "answer": [
                            "Both were formed by faulting"
                        ],
                        "options": [
                            "Both were formed by down warping",
                            "Both were formed by faulting"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B43_16"
            },
            {
                "question": "Select any two reasons why Europeans colonized Africa.",
                "type": "check_box",
                "answer": [
                    "To get market for their surplus goods",
                    "To get raw materials for their industries"
                ],
                "options": [
                    "To help the Africans to develop",
                    "To get market for their surplus goods",
                    "To get new settlements",
                    "To get raw materials for their industries"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two reasons why Africans disliked the colonial rulers.",
                        "type": "check_box",
                        "answer": [
                            "The colonial rulers were so harsh to them",
                            "There was force labour and over taxation of African"
                        ],
                        "options": [
                            "The Africans disliked christianity",
                            "The colonial rulers were so harsh to them",
                            "There was forced labour and over taxation of Africans"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B43_17"
            },
            {
                "question": "State any two reasons why roads transport is the most common type of transport used in East Africa.",
                "type": "check_box",
                "answer": [
                    "Road transport is cheap",
                    "Road transport reaches remote areas easily"
                ],
                "options": [
                    "Road transport is cheap",
                    "Road transport is fast",
                    "Road transport reaches every where",
                    "Road transport reaches remote areas easily"
                ],
                "sub_questions": [
                    {
                        "question": "Give any one advantage of air transport over road transport.",
                        "type": "multipleChoice",
                        "answer": [
                            "Air transport is the fastesr means of transport"
                        ],
                        "options": [
                            "Air transport reaches remote areas",
                            "Air transport is the fastesr means of transport"
                        ]
                    },
                    {
                        "question": "State any one disadvantage of air transport over other types of transport.",
                        "type": "multipleChoice",
                        "answer": [
                            "Air transport is the most expensive means of transport"
                        ],
                        "options": [
                            "Air transport is the most expensive means of transport",
                            "Air transport is not suitable for long journeys"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B43_18"
            },
            {
                "question": "Uganda's population has the highest growth rate in East Africa.",
                "sub_questions": [
                    {
                        "question": "Select any two causes of the high population growth",
                        "type": "check_box",
                        "answer": [
                            "High birth rate",
                            "Early marriages"
                        ],
                        "options": [
                            "Limited resources",
                            "High birth rate",
                            "Early marriages",
                            "Environmental Degradation"
                        ]
                    },
                    {
                        "question": "Suggest any two economic advantages that Uganda has because of a high population.",
                        "type": "check_box",
                        "answer": [
                            "Provides cheap and enough labour force for the economy",
                            "A large population boosts national security"
                        ],
                        "options": [
                            "It helps to reduce crime rates",
                            "Provides cheap and enough labour force for the economy",
                            "It reduces traffic and congestion",
                            "A large population boosts national security"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B43_19"
            }
        ],
        "category": 43,
        "$id": "document_38"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Select any two pastoral communities in East Africa",
                "type": "check_box",
                "answer": [
                    "Karimojong",
                    "Turkana"
                ],
                "options": [
                    "Karimojong",
                    "Turkana",
                    "Banyankole",
                    "Langi"
                ],
                "sub_questions": [
                    {
                        "question": "State any two problems faced by pastoral communities in East Africa",
                        "type": "check_box",
                        "answer": [
                            "Shortage of water and pasture for their animals",
                            "To pay salaries to teachers"
                        ],
                        "options": [
                            "To pay civil servants",
                            "To construct schools",
                            "To pay salaries to teachers",
                            "To build roads"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B44_1"
            },
            {
                "question": "What is the difference between between donations and loans as sources of government income?",
                "type": "multipleChoice",
                "answer": [
                    "A donation is free foreign aid given to the country while a loan is borrowed money a country pays back with interest"
                ],
                "options": [
                    "A laon is free foreign aid given to the country while a donation is borrowed money a country pays back with interest",
                    "A donation is free foreign aid given to the country while a loan is borrowed money a country pays back with interest"
                ],
                "sub_questions": [
                    {
                        "question": "Select two reasons why the government of Uganda allocates money to Ministry of Education and Sports every financial year.",
                        "type": "check_box",
                        "answer": [
                            "To construct schools",
                            "To pay salaries to teachers"
                        ],
                        "options": [
                            "To pay civil servants",
                            "To construct schools",
                            "To pay salaries to teachers",
                            "To build roads"
                        ]
                    },
                    {
                        "question": "Write one role of an adult citizen in funding the national budget",
                        "type": "multipleChoice",
                        "answer": [
                            "To pay taxes promptly"
                        ],
                        "options": [
                            "To maintain the law and order",
                            "To pay taxes promptly"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B44_2"
            },
            {
                "question": "Select any two reasons why the Banyoro staged the  Nyangire Rebellion of 1907",
                "type": "check_box",
                "answer": [
                    "The Banyoro never wanted to be ruled by the Buganda Chiefs",
                    "Bitterness due to the defeat and exile of Omukama Kabalega"
                ],
                "options": [
                    "The Banyoro wanted to have their independence",
                    "They didn't want the religion that was being introduced",
                    "The Banyoro never wanted to be ruled by the Buganda Chiefs",
                    "Bitterness due to the defeat and exile of Omukama Kabalega"
                ],
                "sub_questions": [
                    {
                        "question": "State any two postive effects of the Mau Mau uprising on the African communities in Kenya.",
                        "type": "check_box",
                        "answer": [
                            "The Africans regained their land",
                            "Africans got back their independence"
                        ],
                        "options": [
                            "The Africans regained their land",
                            "Africans got back their independence",
                            "Africans lost their lives",
                            "Many Africans were arrested"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B44_3"
            },
            {
                "question": "Name the colonial power that ruled Tanganyika after the first world war",
                "type": "text",
                "answer": [
                    "Britain"
                ],
                "sub_questions": [
                    {
                        "question": "Give any one way in which industrial growth in Europe contributed to the colonization of Africa",
                        "type": "multipleChoice",
                        "answer": [
                            "It led to the coming of colonialists to get market for their goods"
                        ],
                        "options": [
                            "It led to the coming of colonialists to spread christianity",
                            "It led to the coming of colonialists to get market for their goods"
                        ]
                    },
                    {
                        "question": "Select any two challenges Europeans faced in the process of colonizing Africa",
                        "type": "check_box",
                        "answer": [
                            "Harsh weather conditions",
                            "Resistance from the natives"
                        ],
                        "options": [
                            "They faced the problem of high taxes",
                            "Harsh weather conditions",
                            "Resistance from the natives"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B44_4"
            },
            {
                "question": "What importance do the following live stock have?",
                "sub_questions": [
                    {
                        "question": "Diary cattle",
                        "type": "multipleChoice",
                        "answer": [
                            "Kept for production of milk"
                        ],
                        "options": [
                            "Kept for eggs",
                            "Source of wool",
                            "Keeping cattle for beef",
                            "Kept for production of milk"
                        ]
                    },
                    {
                        "question": "Ranching",
                        "type": "multipleChoice",
                        "answer": [
                            "Keeping cattle for beef"
                        ],
                        "options": [
                            "Kept for eggs",
                            "Source of wool",
                            "Keeping cattle for beef",
                            "Kept for production of milk"
                        ]
                    },
                    {
                        "question": "Poultry",
                        "type": "multipleChoice",
                        "answer": [
                            "Kept for eggs"
                        ],
                        "options": [
                            "Kept for eggs",
                            "Source of wool",
                            "Keeping cattle for beef",
                            "Kept for production of milk"
                        ]
                    },
                    {
                        "question": "Merino sheep",
                        "type": "multipleChoice",
                        "answer": [
                            "Source of wool"
                        ],
                        "options": [
                            "Kept for eggs",
                            "Source of wool",
                            "Keeping cattle for beef",
                            "Kept for production of milk"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B44_5"
            },
            {
                "question": "Name the Kingdom that replaced the Chwezi dynasty of Bunyoro-Kitara",
                "type": "text",
                "answer": [
                    "Bunyoro Kingdom"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two economic contributions of the Chwezi to Uganda",
                        "type": "check_box",
                        "answer": [
                            "They started salt mining at Lake Katwe",
                            "They introduced bark cloth making"
                        ],
                        "options": [
                            "They started salt mining at Lake Katwe",
                            "They introduced the centralized system of governance",
                            "They introduced modern farming methods",
                            "They introduced bark cloth making"
                        ]
                    },
                    {
                        "question": "Give any one reason why the Chwezi Empire collapsed",
                        "type": "multipleChoice",
                        "answer": [
                            "The death of Bihogo their beloved cow"
                        ],
                        "options": [
                            "Other kingdoms fought the Chwezi empire",
                            "The death of Bihogo their beloved cow"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B44_6"
            },
            {
                "question": "Apart from Tanzania, name any one other East African country that was colonized by the Germans.",
                "type": "text",
                "answer": [
                    "Rwanda",
                    "Burundi"
                ],
                "sub_questions": [
                    {
                        "question": "Why did Germany lose her colonies in East Africa after the First World War?",
                        "type": "multipleChoice",
                        "answer": [
                            "It was a punishment given to Germany for having caused the first world war"
                        ],
                        "options": [
                            "They lost their colonies because they were weak",
                            "It was a punishment given to Germany for having caused the first world war"
                        ]
                    },
                    {
                        "question": "Select any two ways in which the people of Tanzania reacted to the German rule.",
                        "type": "check_box",
                        "answer": [
                            "By staging rebellions",
                            "By staging strikes"
                        ],
                        "options": [
                            "Through non violent resistance",
                            "By staging rebellions",
                            "Through legal challenges",
                            "By staging strikes"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B44_7"
            },
            {
                "question": "Study the sketch map of Uganda below and use it to answer the questions that follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2016_44.png",
                "sub_questions": [
                    {
                        "question": "Name the river marked X",
                        "type": "text",
                        "answer": [
                            "River Katonga"
                        ],
                        "options": [
                            "River Katonga",
                            "Kazinga channel",
                            "River nile"
                        ]
                    },
                    {
                        "question": "How was the mountain marked Y formed?",
                        "type": "multipleChoice",
                        "answer": [
                            "By volcanicity"
                        ],
                        "options": [
                            "By faulting",
                            "By folding",
                            "By volcanicity"
                        ]
                    },
                    {
                        "question": "Give the major cash crop grown in the same area marked S.",
                        "type": "text",
                        "answer": [
                            "Tobacco"
                        ]
                    },
                    {
                        "question": "Why does robusta coffee grow well in the area marked V?",
                        "type": "text",
                        "answer": [
                            "Due to the presence of fertile soil"
                        ],
                        "options": [
                            "The area receives tropical rainfall",
                            "Due to the presence of fertile soil"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B44_8"
            },
            {
                "question": "Select the elements of wind that are measured at weather station.",
                "type": "check_box",
                "answer": [
                    "Speed of wind",
                    "The direction of wind"
                ],
                "options": [
                    "Speed of wind",
                    "The direction of wind",
                    "Ocean Conditions",
                    "Air Quality"
                ],
                "sub_questions": [
                    {
                        "question": "Why are the North-East and South-Eastern monsoon winds called trade winds?",
                        "type": "multipleChoice",
                        "answer": [
                            "They were used to sail the boats/dhows of early traders to and from the coast of East Africa"
                        ],
                        "options": [
                            "Because they came from the North-East and South-East",
                            "They were used to sail the boats/dhows of early traders to and from the coast of East Africa"
                        ]
                    },
                    {
                        "question": "Give one reason why the North-East trade winds that blow towards the horn of Africa do not bring rainfall.",
                        "type": "multipleChoice",
                        "answer": [
                            "They do not carry moisture"
                        ],
                        "options": [
                            "They do not carry moisture",
                            "Because they come from a semi-desert"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B44_9"
            },
            {
                "question": "Select any two ways through which people show their culture",
                "type": "check_box",
                "answer": [
                    "Through dressing",
                    "Through music, dance, and drama"
                ],
                "options": [
                    "Through dressing",
                    "Through voting",
                    "Through education",
                    "Through music, dance, and drama"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two reasons why people should promote culture in their area.",
                        "type": "check_box",
                        "answer": [
                            "To promote morals",
                            "For identity and sense of belonging"
                        ],
                        "options": [
                            "To reduce illiteracy rates",
                            "To promote morals",
                            "To exercise voting rights",
                            "For identity and sense of belonging"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B44_10"
            },
            {
                "question": "Select any two uses of mobile phone",
                "type": "check_box",
                "answer": [
                    "They store information",
                    "They are used for communication"
                ],
                "options": [
                    "They are used for security",
                    "They store information",
                    "They are used for communication"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two disadvantages of mobile phones",
                        "type": "check_box",
                        "answer": [
                            "They can cause accidents while crossing roads",
                            "Children can waste a lot of time in using phones"
                        ],
                        "options": [
                            "They limit socialization",
                            "They can cause accidents while crossing roads",
                            "Children can waste a lot of time in using phones",
                            "They help in transportation"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B44_11"
            },
            {
                "question": "Mention any one way the colonialists used to show that they had control over an area.",
                "type": "multipleChoice",
                "answer": [
                    "They introduced laws to govern the area"
                ],
                "options": [
                    "They helped the poor",
                    "They introduced laws to govern the area"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two reasons that made African native to resist payment of taxes",
                        "type": "check_box",
                        "answer": [
                            "Africans were not benefiting from the taxes collected",
                            "Africans didn't like the harsh methods of collecting tax"
                        ],
                        "options": [
                            "Africans were not benefiting from the taxes collected",
                            "Africans didn't like the harsh methods of collecting tax",
                            "Africans wanted to leave their countries"
                        ]
                    },
                    {
                        "question": "Select any one reason why the colonialists did not want the native of Kenya to grow cash crops",
                        "type": "multipleChoice",
                        "answer": [
                            "They didn't want competition for market with the natives"
                        ],
                        "options": [
                            "They didn't want competition for market with the natives",
                            "The land in Kenya was infertile"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B44_12"
            },
            {
                "question": "Where in Africa do we find the temperate grassland?",
                "type": "text",
                "answer": [
                    "Natal province",
                    "Lesotho",
                    "Botswana",
                    "Swaziland",
                    "Ethiopian highlands"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two main economic activities of the temperate grassland",
                        "type": "check_box",
                        "answer": [
                            "Crop farming",
                            "Live stock rearing"
                        ],
                        "options": [
                            "Crop farming",
                            "Live stock rearing",
                            "Fishing",
                            "Industrialization"
                        ]
                    },
                    {
                        "question": "What method is used for growing sugarcane in the area mentioned in (a) above",
                        "type": "text",
                        "answer": [
                            "Irrigation"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B44_13"
            },
            {
                "question": "Why was Kabaka Mwanga afraid for foreigner coming to Buganda through the East?",
                "type": "multipleChoice",
                "answer": [
                    "He thought they would overthrow him"
                ],
                "options": [
                    "He didn't want their gifts",
                    "He thought they would overthrow him"
                ],
                "sub_questions": [
                    {
                        "question": "Where did Bishop Hannington meet his death on his way to Buganda?",
                        "type": "text",
                        "answer": [
                            "Busoga",
                            "Mayuge",
                            "Kyanda",
                            "Luba's Fort",
                            "Bunya"
                        ]
                    },
                    {
                        "question": "State any one way in which the imperial British East Africa company (IBEACO) helped missionaries to work in Uganda.",
                        "type": "multipleChoice",
                        "answer": [
                            "They protected the missionaries"
                        ],
                        "options": [
                            "They helped them to promote christianity",
                            "They protected the missionaries"
                        ]
                    },
                    {
                        "question": "Give any one way the missionaries helped in the development of Uganda.",
                        "type": "multipleChoice",
                        "answer": [
                            "They built hospitals"
                        ],
                        "options": [
                            "They built hospitals",
                            "They fought slave trade"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B44_14"
            },
            {
                "question": "Select any two reasons why people migrate from one place to another.",
                "type": "check_box",
                "answer": [
                    "To escape wars",
                    "To escape natural calamities such as landslides"
                ],
                "options": [
                    "To escape wars",
                    "To avoid paying taxes",
                    "To escape natural calamities such as landslides"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two problems caused by low population density in some rural parts.",
                        "type": "check_box",
                        "answer": [
                            "Lack of enough labour force",
                            "Low development levels"
                        ],
                        "options": [
                            "Lack of enough labour force",
                            "Congestion and traffic during rush hours",
                            "Slum development",
                            "Low development levels"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B44_15"
            },
            {
                "question": "Give one way in which lake Tana is important to be the Blue Nile.",
                "type": "multipleChoice",
                "answer": [
                    "Lake Tana is the source of Blue Nile"
                ],
                "options": [
                    "The Blue Nile flows into of lake Tana",
                    "Lake Tana is the source of Blue Nile"
                ],
                "sub_questions": [
                    {
                        "question": "Explain the meaning of tributary of a river",
                        "type": "multipleChoice",
                        "answer": [
                            "A tributary is a smaller river which flows into the main river"
                        ],
                        "options": [
                            "A tributary is a smaller river which flows into the main river",
                            "A tributary is the end point of the river where it pours into a lake, sea or ocean.",
                            "A tributary of a river is a small river that flows out of a bigger river"
                        ]
                    },
                    {
                        "question": "Explain the meaning of mouth of a river",
                        "type": "multipleChoice",
                        "answer": [
                            "A tributary of a river is a small river that flows out of a bigger river"
                        ],
                        "options": [
                            "Mouth of the river is a smaller river which flows into the main river",
                            "Mouth of the river is the end point of the river where it pours into a lake, sea or ocean.",
                            "Mouth of the river is a small river that flows out of a bigger river"
                        ]
                    },
                    {
                        "question": "Explain the meaning of distributary of a river",
                        "type": "multipleChoice",
                        "answer": [
                            "Mouth of the river is the end point of the river where it pours into a lake, sea or ocean."
                        ],
                        "options": [
                            "A tributary is a smaller river which flows into the main river",
                            "A tributary is the end point of the river where it pours into a lake, sea or ocean.",
                            "A tributary of a river is a small river that flows out of a bigger river"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B44_16"
            },
            {
                "question": "Apart from Libya, Gabon and Egypt, select any two other oil producing countries in Africa",
                "type": "check_box",
                "answer": [
                    "Nigeria",
                    "Angola"
                ],
                "options": [
                    "Malawi",
                    "Nigeria",
                    "Lesotho",
                    "Angola"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two ways which oil producing countries in Africa have benefited",
                        "type": "check_box",
                        "answer": [
                            "Oil producing has created employment",
                            "Oil producing earns foreign exchange"
                        ],
                        "options": [
                            "Oil producing helps countries to work less",
                            "Oil producing has created employment",
                            "Oil producing helps countries to pay less taxes",
                            "Oil producing earns foreign exchange"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B44_17"
            },
            {
                "question": "State any one cash crop introduced in Uganda by colonialists",
                "type": "text",
                "answer": [
                    "Coffee",
                    "Cotton",
                    "Tobacco"
                ],
                "sub_questions": [
                    {
                        "question": "Select any three reasons why cash crops were introduced in Uganda by colonialists",
                        "type": "check_box",
                        "answer": [
                            "Cash crops could stimulate economic development of Uganda which was a protectorate",
                            "The crop formed raw material for industries in colonial countries",
                            "Cash crops were sources of revenues in form of taxes."
                        ],
                        "options": [
                            "They used cash crops to control Ugandans by keeping them in the fields",
                            "Cash crops could stimulate economic development of Uganda which was a protectorate",
                            "The crop formed raw material for industries in colonial countries",
                            "They were introduced because the Ugandans asked them for the crops",
                            "Cash crops were sources of revenues in form of taxes."
                        ]
                    }
                ],
                "id": "ple_sst_sect_B44_18"
            },
            {
                "question": "The first two lines of the song below are missing<br> United free for liberty,<br> Together we'll always stand.",
                "sub_questions": [
                    {
                        "question": "What are the missing lines",
                        "type": "check_box",
                        "answer": [
                            "Oh Uganda! May God uphold the",
                            "We lay our future in thy hands"
                        ],
                        "options": [
                            "Oh Uganda! the land of freedom",
                            "Our love and labour we give",
                            "Oh Uganda! May God uphold the",
                            "We lay our future in thy hands"
                        ]
                    },
                    {
                        "question": "Select any two important things that can be learnt from this song.",
                        "type": "check_box",
                        "answer": [
                            "Promotion of unity to attain peace",
                            "The need for prayers in order to become prosperous"
                        ],
                        "options": [
                            "Promotion of unity to attain peace",
                            "The promotion of family values",
                            "The need for prayers in order to become prosperous",
                            "Promotion of elections in Uganda"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B44_19"
            }
        ],
        "category": 44,
        "$id": "document_39"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Mention the national symbol which is similar to a school badge.",
                "type": "text",
                "answer": [
                    "National coat of arms"
                ],
                "id": "ple_sst_sect_A5_1"
            },
            {
                "question": "Why are the shores of Lake Victoria densely populated?",
                "type": "multipleChoice",
                "answer": [
                    "They have fertile soils which support crop growing"
                ],
                "options": [
                    "Because they are located in the central region",
                    "Because they have fertile soils which support crop growing"
                ],
                "id": "ple_sst_sect_A5_2"
            },
            {
                "question": "Give any one negative effect of the collapse of the East African Community (EAC) in 1977 on the development of East African countries",
                "type": "multipleChoice",
                "answer": [
                    "It led to decline in trade among the East African countries"
                ],
                "options": [
                    "It led to civil wars in East Africa",
                    "It led to decline in trade among the East African countries"
                ],
                "id": "ple_sst_sect_A5_3"
            },
            {
                "question": "Name the direction of point d in the diagram below",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2022_5.png",
                "type": "text",
                "answer": [
                    "Northwest",
                    "NW"
                ],
                "id": "ple_sst_sect_A5_4"
            },
            {
                "question": "Name any one type of grassland that covers a large portion of Africa",
                "type": "text",
                "answer": [
                    "Savannah grasslands",
                    "Savannah"
                ],
                "id": "ple_sst_sect_A5_5"
            },
            {
                "question": "Give any one negative effect of the collapse of the East African Community (EAC) in 1977 on the development of East African countries",
                "type": "multipleChoice",
                "answer": [
                    "It led to decline in trade among the East African countries"
                ],
                "options": [
                    "It led to civil wars in East Africa",
                    "It led to decline in trade among the East African countries"
                ],
                "id": "ple_sst_sect_A5_6"
            },
            {
                "question": "Name the mineral that is used for making electric wires.",
                "type": "text",
                "answer": [
                    "Copper"
                ],
                "id": "ple_sst_sect_A5_7"
            },
            {
                "question": "Which element gives detailed information about a map?",
                "type": "text",
                "answer": [
                    "Key",
                    "The key"
                ],
                "id": "ple_sst_sect_A5_8"
            },
            {
                "question": "Mention any one challenge faced by the National Forestry Authority as it carries out its work.",
                "type": "text",
                "answer": [
                    "Deforestation"
                ],
                "id": "ple_sst_sect_A5_9"
            },
            {
                "question": "Select one reason why it is important to care for forests.",
                "type": "multipleChoice",
                "answer": [
                    "Forests help in rainfall formation"
                ],
                "options": [
                    "Forests help in rainfall formation",
                    "Forests provide food and shelter to people"
                ],
                "id": "ple_sst_sect_A5_10"
            },
            {
                "question": "What is the direction of Uganda from Burundi?",
                "type": "text",
                "answer": [
                    "Northern East",
                    "Northern Eastern"
                ],
                "id": "ple_sst_sect_A5_11"
            },
            {
                "question": "Apart from providing water for irrigation, which other way is River Nile important to the Sudanese? ",
                "type": "multipleChoice",
                "answer": [
                    "It provides them with water for domestic use"
                ],
                "options": [
                    "They use it for salt mining",
                    "It provides them with water for domestic use"
                ],
                "id": "ple_sst_sect_A5_12"
            },
            {
                "question": "Which type of transport is most suitable for carrying flowers from Uganda to the United Kingdom?",
                "type": "text",
                "answer": [
                    "Air transport",
                    "Air"
                ],
                "id": "ple_sst_sect_A5_13"
            },
            {
                "question": "Why should children be encouraged to plant trees at school?",
                "type": "text",
                "answer": [
                    "Trees provides shade",
                    "Trees control soil erosion",
                    "Trees act as windbreakers"
                ],
                "id": "ple_sst_sect_A5_14"
            },
            {
                "question": "What was the kingdom of the Bachwezi known as?",
                "type": "text",
                "answer": [
                    "Bunyoro Kitara"
                ],
                "id": "ple_sst_sect_A5_15"
            },
            {
                "question": "Give one example of a crater lake in Uganda.",
                "type": "text",
                "answer": [
                    "Lake katwe",
                    "Lake Nyamumuka",
                    "Lake Munyanyanga",
                    "Lake Muhavura",
                    "Lake Kataboganda",
                    "Lake Nakasura"
                ],
                "id": "ple_sst_sect_A5_16"
            },
            {
                "question": "What does the road sign below mean?",
                "type": "text",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2009_5.png",
                "answer": [
                    "Children crossing",
                    "School ahead"
                ],
                "id": "ple_sst_sect_A5_17"
            },
            {
                "question": "What is the similarity between the motto of Uganda and the first verse of the national anthem?",
                "type": "text",
                "answer": [
                    "Both have the word God",
                    "God"
                ],
                "id": "ple_sst_sect_A5_18"
            },
            {
                "question": "In which way was the formation of Mt.Elgon different from that of Mt.Rwenzori?",
                "type": "multipleChoice",
                "answer": [
                    "Mt. Elgon was formed by volcanic action while Mt. Rwenzori was formed by faulting"
                ],
                "options": [
                    "Mt. Elgon was formed by faulting while Mt. Rwenzori was formed by faulting volcanic action",
                    "Mt. Elgon was formed by volcanic action while Mt. Rwenzori was formed by faulting"
                ],
                "id": "ple_sst_sect_A5_19"
            },
            {
                "question": "State any one way in which Jomo Kenyatta contributed to the struggle for the independence of Kenya.",
                "type": "multipleChoice",
                "answer": [
                    "He formed the Kenya African National Union which led the country to independence"
                ],
                "options": [
                    "He led the Mau Mau rebellion",
                    "He led the Maji Maji rebellion",
                    "He formed the Kenya African National Union which led the country to independence"
                ],
                "id": "ple_sst_sect_A5_20"
            },
            {
                "question": "Why did Kabaka Mwanga order the killing of the Uganda martyrs?",
                "type": "multipleChoice",
                "answer": [
                    "They refused to denounce their religion as Christians as contrary to his will"
                ],
                "options": [
                    "They refused to follow his orders",
                    "They refused to denounce their religion as Christians as contrary to his will"
                ],
                "id": "ple_sst_sect_A5_21"
            }
        ],
        "category": 5,
        "$id": "document_4"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "What is a bill?",
                "type": "multipleChoice",
                "answer": [
                    "A bill is a proposed law in the parliament"
                ],
                "options": [
                    "A bill is a law signed by the president",
                    "A bill is a proposed law in the parliament"
                ],
                "sub_questions": [
                    {
                        "question": "When does a bill become a law?",
                        "type": "multipleChoice",
                        "answer": [
                            "After being approved by the president"
                        ],
                        "options": [
                            "After being passed by the parliament",
                            "After being approved by the president"
                        ]
                    },
                    {
                        "question": "Select any two special groups of people represented in Uganda's parliament",
                        "type": "check_box",
                        "answer": [
                            "The army",
                            "Workers"
                        ],
                        "options": [
                            "The army",
                            "Foreigners",
                            "Workers",
                            "The police"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B45_1"
            },
            {
                "question": "Select any two factors which delayed independence of African countries",
                "type": "check_box",
                "answer": [
                    "Disunity among African leaders",
                    "Some Africans collaborated with the colonialists"
                ],
                "options": [
                    "Disunity among African leaders",
                    "Some Africans collaborated with the colonialists"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two ways in which the first and second world wars helped to speed up independence of African countries",
                        "type": "check_box",
                        "answer": [
                            "Africans learnt that the whites could be defeated",
                            "It increased desire for independence"
                        ],
                        "options": [
                            "Becasue they were promised independence to fight the world wars",
                            "Africans learnt that the whites could be defeated",
                            "It increased desire for independence",
                            "Because Africans had won the world wars"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B45_2"
            },
            {
                "question": "Use the sketch map of Uganda below to answer the questions that follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2022_45.png",
                "sub_questions": [
                    {
                        "question": "Name the natural forest found in the area marked P",
                        "type": "text",
                        "answer": [
                            "Budongo forest"
                        ]
                    },
                    {
                        "question": "Name the river that flows out of lake M",
                        "type": "text",
                        "answer": [
                            "River nile"
                        ]
                    },
                    {
                        "question": "Select any two reasons why the area around Lake marked M is suitable for farming",
                        "type": "check_box",
                        "answer": [
                            "The area receives reliable rainfall",
                            "The area has fertile soil"
                        ],
                        "options": [
                            "The area has a high population",
                            "The area receives reliable rainfall",
                            "The area has alot of free land",
                            "The area has fertile soil"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B45_3"
            },
            {
                "question": "The diagram below shows the organ and titles of each organ of a democratic government. Use it to answer the questions that follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2020_45.png",
                "sub_questions": [
                    {
                        "question": "Name the organ of government marked C",
                        "type": "text",
                        "answer": [
                            "Judiciary"
                        ]
                    },
                    {
                        "question": "Mention the title of the head marked B",
                        "type": "text",
                        "answer": [
                            "President",
                            "Head of state"
                        ]
                    },
                    {
                        "question": "Mention the title of the head marked E",
                        "type": "text",
                        "answer": [
                            "Speaker",
                            "Speaker of Parliament"
                        ]
                    },
                    {
                        "question": "Apart from the head of the organ, give any one other example of people who work under C",
                        "type": "text",
                        "answer": [
                            "Magistrates",
                            "Judges",
                            "Lawyers",
                            "Attorneys"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B45_4"
            },
            {
                "question": "Study the sketch map of Africa below and then answer the questions that follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2019_45.png",
                "sub_questions": [
                    {
                        "question": "What process led to the formation of the mountain marked X?",
                        "type": "text",
                        "answer": [
                            "Folding"
                        ]
                    },
                    {
                        "question": "Describe the climate of the shaded area marked P",
                        "type": "multipleChoice",
                        "answer": [
                            "It receives heavy rainfall throughout the year"
                        ],
                        "options": [
                            "It receives little rainfall throughtout the year",
                            "It receives heavy rainfall throughout the year"
                        ]
                    },
                    {
                        "question": "Select any two factors which influence the vegetation in the area marked W",
                        "type": "check_box",
                        "answer": [
                            "Rainfall distribution",
                            "Distance from the equator"
                        ],
                        "options": [
                            "Rainfall distribution",
                            "Political activity",
                            "Distance from the equator"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B45_5"
            },
            {
                "question": "Study the sketch map of East Africa below and answer the questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2018_45.png",
                "sub_questions": [
                    {
                        "question": "Name the river marked L",
                        "type": "text",
                        "answer": [
                            "River Achwa"
                        ]
                    },
                    {
                        "question": "Where was the homeland of the ethnic group that entered East Africa using route Y?",
                        "type": "text",
                        "answer": [
                            "Cameroon Highlands"
                        ]
                    },
                    {
                        "question": "Mention the traditional cash crop grown on Island marked Z",
                        "type": "text",
                        "answer": [
                            "Cloves"
                        ]
                    },
                    {
                        "question": "How has the railway line marked X contributed to the economic to the economic development of Zambia?",
                        "type": "multipleChoice",
                        "answer": [
                            "It has linked Zambia to the coast"
                        ],
                        "options": [
                            "It has made Zambia have a sea port",
                            "It has linked Zambia to the coast"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B45_6"
            },
            {
                "question": "Name any one place at school where waste materials can be disposed.",
                "type": "text",
                "answer": [
                    "Dustbin",
                    "Rubbish pit",
                    "Toilet",
                    "Pit latrine"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two problems caused by poor waste management in schools",
                        "type": "check_box",
                        "answer": [
                            "Water and soil pollution",
                            "Blocking of water channels"
                        ],
                        "options": [
                            "Poor transport",
                            "Water and soil pollution",
                            "Blocking of water channels",
                            "Corruption"
                        ]
                    },
                    {
                        "question": "Why is recycling important in waste management?",
                        "type": "multipleChoice",
                        "answer": [
                            "It conserves the environment"
                        ],
                        "options": [
                            "It reduces deforestration",
                            "It conserves the environment"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B45_7"
            },
            {
                "question": "Name the policy practiced in South Africa which separated the blacks from the whites.",
                "type": "text",
                "answer": [
                    "Apartheid policy",
                    "Apartheid"
                ],
                "sub_questions": [
                    {
                        "question": "Name the first black president of South Africa who fought against the above mentioned policy.",
                        "type": "text",
                        "answer": [
                            "Nelson Mandela",
                            "Nelson Rolihlahla Mandela"
                        ]
                    },
                    {
                        "question": "Select any two ways in which the policy mentioned above negatively affected the blacks in South Africa",
                        "type": "check_box",
                        "answer": [
                            "Africans were not allowed to study in the same schools with the whites",
                            "Africans were not allowed to participate in politics"
                        ],
                        "options": [
                            "Africans were not allowed to work",
                            "Africans were not allowed to study in the same schools with the whites",
                            "Africans were not allowed to get health care",
                            "Africans were not allowed to participate in politics"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B45_8"
            },
            {
                "question": "Name the organization that was replaced by the African Union",
                "type": "text",
                "answer": [
                    "Organization of African Unity",
                    "OAU"
                ],
                "sub_questions": [
                    {
                        "question": "Mention one country in Africa which is not a member of the African Union",
                        "type": "text",
                        "answer": [
                            "Morocco"
                        ]
                    },
                    {
                        "question": "Select any major organs of the African Union",
                        "type": "check_box",
                        "answer": [
                            "Pan African Parliament",
                            "Executive council of the Union"
                        ],
                        "options": [
                            "Pan African Parliament",
                            "Executive council of the Union",
                            "Anti corruption court",
                            "Executive head of state"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B45_9"
            },
            {
                "question": "What title is given to the person heading Bank of Uganda?",
                "type": "text",
                "answer": [
                    "Governor"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two reasons why Bank of Uganda is important to the government.",
                        "type": "check_box",
                        "answer": [
                            "It prints and issues money",
                            "It regulates commercial banks"
                        ],
                        "options": [
                            "It prints and issues money",
                            "It gives loans to Ugandans",
                            "It collects taxes",
                            "It regulates commercial banks"
                        ]
                    },
                    {
                        "question": "Give any one way in which a person benefits from keeping money in a Bank.",
                        "type": "multipleChoice",
                        "answer": [
                            "It can help to keep ones money secure"
                        ],
                        "options": [
                            "It helps one to have more spending power",
                            "It can help to keep ones money secure"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B45_10"
            },
            {
                "question": "Who heads the commonwealth of Nation?",
                "type": "text",
                "answer": [
                    "The king of England",
                    "His Majesty King Charles III"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two functions of the commonwealth of Nation",
                        "type": "check_box",
                        "answer": [
                            "Organizes common wealth games",
                            "It funds development projects"
                        ],
                        "options": [
                            "It elects the prime minister of Britain",
                            "Organizes common wealth games",
                            "It runs the common wealth governments",
                            "It funds development projects"
                        ]
                    },
                    {
                        "question": "Give any one way in which Uganda benefit from participating I the Commonwealth of Nations games",
                        "type": "multipleChoice",
                        "answer": [
                            "Ugandans' talents are displayed"
                        ],
                        "options": [
                            "Ugandan raw materials are sold",
                            "Ugandans' talents are displayed"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B45_11"
            },
            {
                "question": "Why was early man known as the stone age man?",
                "type": "multipleChoice",
                "answer": [
                    "Because he used tools made out of stone"
                ],
                "options": [
                    "Because he lived long ago",
                    "Because he used tools made out of stone"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two methods stone age men used to kill the animals for food.",
                        "type": "check_box",
                        "answer": [
                            "Use of bolas",
                            "Use traps"
                        ],
                        "options": [
                            "Use of guns",
                            "Use of bolas",
                            "Use traps",
                            "Use of decoys"
                        ]
                    },
                    {
                        "question": "How did the discovery of iron change man's way of life?",
                        "type": "multipleChoice",
                        "answer": [
                            "Got better tools for hunting"
                        ],
                        "options": [
                            "He got better governance skills",
                            "Got better tools for hunting"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B45_12"
            },
            {
                "question": "Select any two reason why informal education is good in the community.",
                "type": "check_box",
                "answers": [
                    "It promotes unity",
                    "It helps to know the origin of their tribes"
                ],
                "options": [
                    "It helps to stop corruption",
                    "It promotes unity",
                    "It improves the communication networks",
                    "It helps to know the origin of their tribes"
                ],
                "sub_questions": [
                    {
                        "question": "In which two ways do parents help their children to learn about their culture?",
                        "type": "check_box",
                        "answer": [
                            "Through story telling",
                            "By allowing  children to attend cultural ceremonies"
                        ],
                        "options": [
                            "Through taking them to visit game parks",
                            "Through story telling",
                            "By allowing  children to attend cultural ceremonies",
                            "By providing them with the basic needs"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B45_13"
            },
            {
                "question": "Apart from Uganda and Kenya, select two other countries which are member of the East African community",
                "type": "check_box",
                "answer": [
                    "Tanzania",
                    "Burundi"
                ],
                "options": [
                    "Tanzania",
                    "Kenya",
                    "Burundi",
                    "Uganda"
                ],
                "sub_questions": [
                    {
                        "question": "Give any twp reasons why Uganda should live peacefully with her neighboring countries",
                        "type": "check_box",
                        "answer": [
                            "It helps to promote trade with Uganda's  neighboring countries",
                            "It helps to have free movement of people, goods and service"
                        ],
                        "options": [
                            "It helps to fight corruption",
                            "It helps to promote trade with Uganda's  neighboring countries",
                            "It helps to have free movement of people, goods and service",
                            "It helps to prevent electoral violence in Uganda"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B45_14"
            },
            {
                "question": "Select any two modern cattle keeping practices done today",
                "type": "check_box",
                "answer": [
                    "Paddocking",
                    "Zero grazing"
                ],
                "options": [
                    "Paddocking",
                    "Pastrolism",
                    "Zero grazing",
                    "Community grazing"
                ],
                "sub_questions": [
                    {
                        "question": "Which of the methods mentioned above is practiced mainly in central Kenya",
                        "type": "text",
                        "answer": [
                            "Paddocking"
                        ]
                    },
                    {
                        "question": "How can artificial insemination help a farmer in Uganda?",
                        "type": "multipleChoice",
                        "answer": [
                            "It reduces chances of inbreeding amongst related cattle which reduce genetic diseases"
                        ],
                        "options": [
                            "Its cheap to carry out",
                            "It reduces chances of inbreeding amongst related cattle",
                            "It doesn't require skilled labour"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B45_15"
            },
            {
                "question": "Select the use of each of the following weather instruments",
                "sub_questions": [
                    {
                        "question": "Barometer",
                        "type": "multipleChoice",
                        "answer": [
                            "It measures air pressure"
                        ],
                        "options": [
                            "It measures humidity",
                            "It measures speed of wind",
                            "It keeps and protects the maximum and minimum thermometer",
                            "It measures air pressure"
                        ]
                    },
                    {
                        "question": "Stevenson screen",
                        "type": "multipleChoice",
                        "answer": [
                            "It keeps and protects the maximum and minimum thermometer"
                        ],
                        "options": [
                            "It measures humidity",
                            "It measures speed of wind",
                            "It keeps and protects the maximum and minimum thermometer",
                            "It measures air pressure"
                        ]
                    },
                    {
                        "question": "Hygrometer",
                        "type": "multipleChoice",
                        "answer": [
                            "It measures humidity"
                        ],
                        "options": [
                            "It measures humidity",
                            "It measures speed of wind",
                            "It keeps and protects the maximum and minimum thermometer",
                            "It measures air pressure"
                        ]
                    },
                    {
                        "question": "Anemometer",
                        "type": "multipleChoice",
                        "answer": [
                            "It measures speed of wind"
                        ],
                        "options": [
                            "It measures humidity",
                            "It measures speed of wind",
                            "It keeps and protects the maximum and minimum thermometer",
                            "It measures air pressure"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B45_16"
            },
            {
                "question": "Select any one two reasons why the Portuguese came to settle in Mombasa",
                "type": "check_box",
                "answer": [
                    "They wanted to trade with India",
                    "They wanted to spread  christianity"
                ],
                "options": [
                    "They wanted to colonise East Africa",
                    "They wanted to trade with India",
                    "They wanted to get raw materials",
                    "They wanted to spread  christianity"
                ],
                "sub_questions": [
                    {
                        "question": "Why did the Portuguese build fort Jesus?",
                        "type": "multipleChoice",
                        "answer": [
                            "Fort Jesus was built to as a military base for protection against Arab Attacks"
                        ],
                        "options": [
                            "It was built to act as a church since they were spreading christianity",
                            "Fort Jesus was built to as a military base for protection against Arab Attacks"
                        ]
                    },
                    {
                        "question": "How does fort Jesus contribute to the economy of Kenya at present?",
                        "type": "multipleChoice",
                        "answer": [
                            "It acts as a tourist attraction"
                        ],
                        "options": [
                            "It acts as a coastal market",
                            "It acts as a tourist attraction"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B45_17"
            },
            {
                "question": "A factory producing posho (maize milling factory has been built in a place. Suggest any two ways in which people living near that place will benefit from the factory.",
                "type": "check_box",
                "answer": [
                    "The people will get food from milling maize",
                    "Some people will get employment from the milling factory"
                ],
                "options": [
                    "The people will get education",
                    "The people will get food from milling maize",
                    "Some people will get employment from the milling factory",
                    "Some people will get taxes"
                ],
                "sub_questions": [
                    {
                        "question": "Suggest any two ways in which the factory will benefit from people living near that place",
                        "type": "check_box",
                        "answer": [
                            "The factory will have a market for posho from local population",
                            "The people will provide labour to the factory"
                        ],
                        "options": [
                            "The factory will have a market for posho from local population",
                            "The people will pay less taxes",
                            "The factory will get free labour from the people",
                            "The people will provide labour to the factory"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B45_18"
            },
            {
                "question": "Select any two reasons why Africans fought for independence",
                "type": "check_box",
                "answer": [
                    "Africans were tired of harsh treatment and forced labour",
                    "Africans wanted to regain economic freedom"
                ],
                "options": [
                    "Africans were tired of harsh treatment and forced labour",
                    "Africans wanted to export their finished goods",
                    "Africans wanted to regain economic freedom",
                    "Africans did not want to fight in the first world war"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two problems that Africans experienced during the fight for independence",
                        "type": "check_box",
                        "answer": [
                            "Africans suffered from the loss of life and property.",
                            "Africans faced revenue loss",
                            "Africans faced oppressive laws"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B45_19"
            }
        ],
        "category": 45,
        "$id": "document_40"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Apart from Russia, select any other two permanent member states on the United Nations Security Council",
                "type": "check_box",
                "answer": [
                    "United States of America (USA)",
                    "France"
                ],
                "options": [
                    "United States of America (USA)",
                    "Italy",
                    "Israel",
                    "France"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two challenges faced by the United Nations",
                        "type": "check_box",
                        "answer": [
                            "Shortage of funds to run its programmes effectively",
                            "Differences in political systems among the member states"
                        ],
                        "options": [
                            "Corruption among member states",
                            "Shortage of funds to run its programmes effectively",
                            "Wars among member states",
                            "Differences in political systems among the member states"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B46_1"
            },
            {
                "question": "Give any two reasons why Germany colonised Tanganyika",
                "type": "multipleChoice",
                "answer": [
                    "They wanted to get raw materials for their home industries"
                ],
                "options": [
                    "They wanted to stop slave trade in Tanganyika",
                    "They wanted to get raw materials for their home industries"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two postive effects of German East Africa Company (GEACO) on the development of East Africa",
                        "type": "multipleChoice",
                        "answer": [
                            "It promoted trade in Tanganyika"
                        ],
                        "options": [
                            "It stopped slave trade in Tanganyika",
                            "It promoted trade in Tanganyika"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B46_2"
            },
            {
                "question": "Apart from farming, give any one other example of a primary industry in Uganda",
                "type": "text",
                "answer": [
                    "Mining",
                    "Lumbering",
                    "Fishing"
                ],
                "sub_questions": [
                    {
                        "question": "State any one way in which cash crop growing increases industrial development in East Africa",
                        "type": "multipleChoice",
                        "answer": [
                            "It is a source of raw materials to agro-based industries"
                        ],
                        "options": [
                            "It is source of raw materials for export",
                            "It is a source of raw materials to agro-based industries"
                        ]
                    },
                    {
                        "question": "Select any two reasons for the slow industrial development in some African countries.",
                        "type": "check_box",
                        "answer": [
                            "Shortage of capital of funds",
                            "Shortage of market for processed goods"
                        ],
                        "options": [
                            "Lack of enough land to set up industries",
                            "Shortage of capital of funds",
                            "Shortage of unskilled labour",
                            "Shortage of market for processed goods"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B46_3"
            },
            {
                "question": "John exchanged a cock for a bunch of matooke. What system of trade did John practice?",
                "type": "text",
                "answer": [
                    "Barter trade"
                ],
                "sub_questions": [
                    {
                        "question": "State any one reason why early traders used the system in (a) above.",
                        "type": "multipleChoice",
                        "answer": [
                            "The absence of money as a medium of exchange"
                        ],
                        "options": [
                            "It was a cheaper medium of exchange",
                            "The absence of money as a medium of exchange"
                        ]
                    },
                    {
                        "question": "State any one problem caused by the system in (a) above",
                        "type": "multipleChoice",
                        "answer": [
                            "It was difficult to transport bulky goods over long distances"
                        ],
                        "options": [
                            "It led to lower revenue for the authorities",
                            "It was difficult to transport bulky goods over long distances"
                        ]
                    },
                    {
                        "question": "Which system of trade is practiced by buying and selling of goods using money",
                        "type": "text",
                        "answer": [
                            "Monetary trade"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B46_4"
            },
            {
                "question": "Select any two local materials from the environment which are used to make crafts",
                "type": "check_box",
                "answer": [
                    "Papyrus",
                    "Sisal"
                ],
                "options": [
                    "Papyrus",
                    "Bauxite",
                    "Sisal",
                    "Limestone"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two examples of crafts made from local materials",
                        "type": "check_box",
                        "answer": [
                            "Mats",
                            "Brooms"
                        ],
                        "options": [
                            "Cement",
                            "Mats",
                            "Salt",
                            "Brooms"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B46_5"
            },
            {
                "question": "Select any two roles of security organs in Uganda.",
                "type": "check_box",
                "answer": [
                    "To protect people and their property",
                    "To arrest lawbreakers and wrong doers"
                ],
                "options": [
                    "To conduct elections",
                    "To protect people and their property",
                    "To arrest lawbreakers and wrong doers",
                    "To fight corruption"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two challenges faced by the security organs while performing their duties.",
                        "type": "check_box",
                        "answer": [
                            "Shortage of manpower",
                            "Poor relationship with the general public"
                        ],
                        "options": [
                            "High taxes",
                            "Shortage of manpower",
                            "High literacy levels",
                            "Poor relationship with the general public"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B46_6"
            }
        ],
        "category": 46,
        "$id": "document_41"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Select any two sources of history",
                "type": "check_box",
                "answer": [
                    "Archaeology",
                    "Oral traditions"
                ],
                "options": [
                    "Oceans",
                    "Archaeology",
                    "Oral traditions",
                    "The arctic"
                ],
                "sub_questions": [
                    {
                        "question": "Give one way in which Olduvai gorge is economically important to Tanzania?",
                        "type": "multipleChoice",
                        "answer": [
                            "It attracts tourists who bring revenue to the government of Tanzania"
                        ],
                        "options": [
                            "It is the place where slave trade ended so it a tourist attraction",
                            "It attracts tourists who bring revenue to the government of Tanzania"
                        ]
                    },
                    {
                        "question": "Name the Stone Age Site located in Karamoja sub-region",
                        "type": "text",
                        "answer": [
                            "Magosi Stone Age Site"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B47_1"
            },
            {
                "question": "Below is a sketch map of an area. Use it to answer the questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2023_47.png",
                "sub_questions": [
                    {
                        "question": "Identify any one economic activity carried out in the area",
                        "type": "text",
                        "answer": [
                            "Crop growing",
                            "Dairy farming",
                            "Lumbering",
                            "Trade",
                            "Transport",
                            "Industrialisation"
                        ]
                    },
                    {
                        "question": "Give any one social service provided in the area",
                        "type": "text",
                        "answer": [
                            "Education",
                            "Health",
                            "Transport"
                        ]
                    },
                    {
                        "question": "Select any one way in which people benefit from the area marked F",
                        "type": "multipleChoice",
                        "answer": [
                            "People get jobs"
                        ],
                        "options": [
                            "People get timber",
                            "People get jobs"
                        ]
                    },
                    {
                        "question": "Select any one way in which people can care for the resources in the area.",
                        "type": "multipleChoice",
                        "answer": [
                            "Through proper disposal of wastes"
                        ],
                        "options": [
                            "By voting for the leaders they want",
                            "Through proper disposal of wastes"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B47_2"
            },
            {
                "question": "Below is a sketch map of an area. Use it to answer the questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2022_47.png",
                "sub_questions": [
                    {
                        "question": "Name the month of the year that received the highest of rainfall",
                        "type": "text",
                        "answer": [
                            "July"
                        ]
                    },
                    {
                        "question": "Identify the type of climate shown in the table",
                        "type": "text",
                        "answer": [
                            "Desert climate"
                        ]
                    },
                    {
                        "question": "What is the temperature range of the climate shown in the table?",
                        "type": "multipleChoice",
                        "answer": [
                            "7<sup>o</sup>c"
                        ],
                        "explanation": "Range = Highest - Lowest<br> = 44<sup>o</sup>c - 37<sup>o</sup>c<br> = 7<sup>o</sup>c",
                        "options": [
                            "8<sup>o</sup>c",
                            "6<sup>o</sup>c",
                            "7<sup>o</sup>c"
                        ]
                    },
                    {
                        "question": "Mention one factor that influences the type of climate shown in the table",
                        "type": "text",
                        "answer": [
                            "Cold ocean current",
                            "Dry winds",
                            "Offshore winds"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B47_3"
            },
            {
                "question": "Name any one member country of the East African Community (EAC) which was not a founder member in 1967.",
                "type": "text",
                "anser": [
                    "South Sudan",
                    "Rwanda",
                    "Burundi",
                    "Somalia",
                    "D.R.C",
                    "Democratic Republic of Congo"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two reasons why the East African Community was formed",
                        "type": "check_box",
                        "answer": [
                            "To promote trade among member states",
                            "To promote security among member states"
                        ],
                        "options": [
                            "To fight for independence",
                            "To promote trade among member states",
                            "To promote security among member states",
                            "To end slave trade"
                        ]
                    },
                    {
                        "question": "Mention any one way in which the people of East Africa have benefited from the EAC today",
                        "type": "multipleChoice",
                        "answer": [
                            "The EAC has provided market for the goods"
                        ],
                        "options": [
                            "The people in the EAC have won wars",
                            "The EAC has provided market for the goods"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B47_4"
            },
            {
                "question": "Select two elements of climate that influence human settlement",
                "type": "check_box",
                "answer": [
                    "Rainfall",
                    "Temperature"
                ],
                "options": [
                    "Rainfall",
                    "Temperature",
                    "Tropical clmate",
                    "Mediterranean climate"
                ],
                "sub_questions": [
                    {
                        "question": "State one effect of the revolution of the earth",
                        "type": "multipleChoice",
                        "answer": [
                            "It causes changes in seasons"
                        ],
                        "options": [
                            "It causes ocean currents",
                            "It causes changes in seasons"
                        ]
                    },
                    {
                        "question": "What term is used when the sun is overhead the equator?",
                        "type": "text",
                        "answer": [
                            "Equinox"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B47_5"
            },
            {
                "question": "Study the sketch map below and answer the questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2018_47.png",
                "sub_questions": [
                    {
                        "question": "Name feature marked with letter A",
                        "type": "text",
                        "answer": [
                            "Niger delta"
                        ]
                    },
                    {
                        "question": "Name feature marked with letter B",
                        "type": "text",
                        "answer": [
                            "River Benue"
                        ]
                    },
                    {
                        "question": "Name any one other river in Africa that ends the same way as the river marked C above",
                        "type": "text",
                        "answer": [
                            "River Nile",
                            "River Zambezi",
                            "River Rufiji",
                            "River Tana"
                        ]
                    },
                    {
                        "question": "How is the part marked A economically important to Nigeria",
                        "type": "multipleChoice",
                        "answer": [
                            "It is a source of water for irrigation"
                        ],
                        "options": [
                            "It provides the raw material for salt mining",
                            "It is a source of water for irrigation"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B47_6"
            }
        ],
        "category": 47,
        "$id": "document_42"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Study the sketch map of East Africa below and then answer the questions that follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/1st_paper.png",
                "sub_questions": [
                    {
                        "question": "Name the game park marked S",
                        "type": "multipleChoice",
                        "answer": [
                            "Tsavo National park"
                        ],
                        "options": [
                            "Tsavo National park",
                            "Serengeti National park",
                            "Nairobi national park"
                        ]
                    },
                    {
                        "question": "Name the game park marked V",
                        "type": "multipleChoice",
                        "answer": [
                            "Serengeti National park"
                        ],
                        "options": [
                            "Tsavo National park",
                            "Serengeti National park",
                            "Nairobi national park"
                        ]
                    },
                    {
                        "question": "Why is the peak of mountain marked X snowcapped throughout the year?",
                        "type": "multipleChoice",
                        "answer": [
                            "Because its peak crosses the snowline"
                        ],
                        "options": [
                            "Because its peak crosses the snowline",
                            "Because its on the boarder of two countries"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B48_1"
            },
            {
                "question": "Select any two reasons why you would visit any one tourist attraction site in Uganda",
                "type": "check_box",
                "answer": [
                    "For study purposes or education and research",
                    "To see wildlife"
                ],
                "options": [
                    "To earn foreign exchange",
                    "For study purposes or education and research",
                    "To see wildlife",
                    "To pay taxes"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two contributions of tourism industry to the development of East Africa.",
                        "type": "check_box",
                        "answer": [
                            "It provides employment to people",
                            "It is a source of government revenue"
                        ],
                        "options": [
                            "It promotes safe farming methods",
                            "It provides employment to people",
                            "It provides health services to people",
                            "It is a source of government revenue"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B48_2"
            },
            {
                "question": "On which type of goods is customs tax charged?",
                "type": "text",
                "answer": [
                    "Imports",
                    "Imported goods"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two reasons why some people in Uganda are not willing to pay taxes.",
                        "type": "check_box",
                        "answer": [
                            "Due to ignorance about the importance of paying taxes",
                            "Due to the high taxes charged on goods"
                        ],
                        "options": [
                            "Due to ignorance about the importance of paying taxes",
                            "Because of poor transport networks",
                            "Due to the high taxes charged on goods",
                            "Due to under development"
                        ]
                    },
                    {
                        "question": "Why does the government set up revenue check points on roads?",
                        "type": "multipleChoice",
                        "answer": [
                            "To control smuggling"
                        ],
                        "options": [
                            "To collect taxes",
                            "To control smuggling"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B48_3"
            },
            {
                "question": "Select any two ways in which people acquire land in Uganda",
                "type": "check_box",
                "answer": [
                    "By inheriting land",
                    "By buying land"
                ],
                "options": [
                    "The government gives out land",
                    "By inheriting land",
                    "By buying land",
                    "By taking land from the weak"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two causes of land conflicts among people in Uganda today",
                        "type": "check_box",
                        "answer": [
                            "Over population",
                            "Greed"
                        ],
                        "options": [
                            "High government taxes",
                            "Over population",
                            "Greed",
                            "High illiteracy rates"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B48_4"
            },
            {
                "question": "What are Agro-based industries?",
                "type": "multipleChoice",
                "answer": [
                    "Agro-based industries are industries that depend on agricultural produce as their raw materials"
                ],
                "options": [
                    "Agro-based industries are industries that are found in Africa",
                    "Agro-based industries are industries that depend on agricultural produce as their raw materials"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two ways in which electricity is important in the establishment of an industry",
                        "type": "check_box",
                        "answer": [
                            "Electricity is used to run machines in industries",
                            "It is used to promote security of an industry"
                        ],
                        "options": [
                            "Electricity helps to light homes",
                            "Electricity is used to run machines in industries",
                            "Electricity helps to run appliances in a home",
                            "It is used to promote security of an industry"
                        ]
                    },
                    {
                        "question": "State any one way in which industries have promoted economic development in Africa",
                        "type": "multipleChoice",
                        "answer": [
                            "They are sources of government revenue"
                        ],
                        "options": [
                            "They help to reduce illiteracy rates in Africa",
                            "They are sources of government revenue"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B48_5"
            },
            {
                "question": "Select any two materials the Early man used for marking his tools.",
                "type": "check_box",
                "answer": [
                    "Stones",
                    "Iron"
                ],
                "options": [
                    "Stones",
                    "Iron",
                    "Steel",
                    "Clay"
                ],
                "sub_questions": [
                    {
                        "question": "How did the Early man use bolas to live a better life?",
                        "type": "multipleChoice",
                        "answer": [
                            "He used it to entangle the legs of first running animals"
                        ],
                        "options": [
                            "He used it for fishing",
                            "He used it to entangle the legs of first running animals"
                        ]
                    },
                    {
                        "question": "Give any one reason why the discovery of fire was important to the early man.",
                        "type": "multipleChoice",
                        "answer": [
                            "Fire was used for protection"
                        ],
                        "options": [
                            "He used fire to develop weapons",
                            "Fire was used for protection"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B48_6"
            }
        ],
        "category": 48,
        "$id": "document_43"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "How did Alexander Mackay contribute to the education sector in Uganda?",
                "type": "multipleChoice",
                "answer": [
                    "He introduced the first printing press in Uganda which was used to print reading books"
                ],
                "options": [
                    "He built Kings college Buddo in Uganda contributing to the education sector",
                    "He introduced the first printing press in Uganda which was used to print reading books"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two challenges the early Christian missionaries faced while in East Africa",
                        "type": "check_box",
                        "answer": [
                            "Difficulty in communication with the local people",
                            "Attacks from tropical diseases"
                        ],
                        "options": [
                            "Difficulty in communication with the local people",
                            "They faced a problem of poor network",
                            "Attacks from tropical diseases",
                            "They faced a problem of corruption"
                        ]
                    },
                    {
                        "question": "Write down one way how the early Christian missionaries led to the development of East African societies",
                        "type": "multipleChoice",
                        "answer": [
                            "They built hospitals and schools"
                        ],
                        "options": [
                            "They fought slave trade in East Africa",
                            "They faced a problem of poor network"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B49_1"
            },
            {
                "question": "Select any two factors that have made South Africa the most industrialised country in Africa",
                "type": "check_box",
                "answer": [
                    "High levels of technology",
                    "Presence of skilled labour"
                ],
                "options": [
                    "High levels of technology",
                    "The apartheid policy",
                    "Presence of skilled labour",
                    "The large coast of South Africa"
                ],
                "sub_questions": [
                    {
                        "question": "In which two ways are South African's industries helpful to the growth of other sectors of her economy?",
                        "type": "check_box",
                        "answer": [
                            "Industries provide processed goods to other sectors",
                            "Industries create market for produce from other sectors"
                        ],
                        "options": [
                            "Industries provide processed goods to other sectors",
                            "Industries help to reduce the taxes in other sectors",
                            "Industries help to reduce criminal rates",
                            "Industries create market for produce from other sectors"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B49_2"
            },
            {
                "question": "Name the place in modern Tanzania through which the early explorers entered East Africa.",
                "type": "text",
                "answer": [
                    "Bagamoyo"
                ],
                "sub_questions": [
                    {
                        "question": "In which two ways was the Sultan of Zanzibar helpful to early explorers to East Africa.",
                        "type": "check_box",
                        "answer": [
                            "He gave them permission to explore",
                            "He gave them guides"
                        ],
                        "options": [
                            "He gave them explorers slaves",
                            "He provided security to the explorers",
                            "He gave them permission to explore",
                            "He gave them guides"
                        ]
                    },
                    {
                        "question": "How did some wrong information about Africa make the work of the explorers difficult?",
                        "type": "multipleChoice",
                        "answer": [
                            "It delayed their movement into the interior"
                        ],
                        "options": [
                            "It led to sickness among the explorers",
                            "It delayed their movement into the interior"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B49_3"
            },
            {
                "question": "Study the sketch map of Uganda below and then answer the questions that follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2020_49.png",
                "sub_questions": [
                    {
                        "question": "Name the national game park found in the area marked A",
                        "type": "text",
                        "answer": [
                            "Queen Elizabeth National Park",
                            "Queen Elizabeth"
                        ]
                    },
                    {
                        "question": "Why is the lake marked Q swampy with floating vegetation?",
                        "type": "multipleChoice",
                        "answer": [
                            "Because it is shallow"
                        ],
                        "options": [
                            "Because it is a fresh water lake",
                            "Because it is shallow"
                        ]
                    },
                    {
                        "question": "Name the river marked S",
                        "type": "text",
                        "answer": [
                            "River Kafu"
                        ]
                    },
                    {
                        "question": "Why does the region marked Z experience semi-arid climate?",
                        "type": "multipleChoice",
                        "answer": [
                            "Region Z receives dry Northeast trade winds"
                        ],
                        "options": [
                            "Because region Z is in the nothern part ",
                            "Because region Z receives dry Northeast trade winds"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B49_4"
            },
            {
                "question": "What do we call a place where the remains of early man are kept for study and tourism",
                "type": "text",
                "answer": [
                    "Museum"
                ],
                "sub_questions": [
                    {
                        "question": "How did the Bachwezi promote the Agriculture in Uganda?",
                        "type": "multipleChoice",
                        "answer": [
                            "They introduced long horned cattle"
                        ],
                        "options": [
                            "They started salt mining at Lake Katwe",
                            "They introduced long horned cattle"
                        ]
                    },
                    {
                        "question": "How did the Bachwezi promote the Mining in Uganda?",
                        "type": "multipleChoice",
                        "answer": [
                            "They started salt mining at Lake Katwe"
                        ],
                        "options": [
                            "They started salt mining at Lake Katwe",
                            "They introduced long horned cattle"
                        ]
                    },
                    {
                        "question": "Select any one reason why the Bachwezi dug ditches around Bigobyamugenyi",
                        "type": "multipleChoice",
                        "answer": [
                            "For protection"
                        ],
                        "options": [
                            "To practice agriculture",
                            "For protection"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B49_5"
            },
            {
                "question": "What is a national election?",
                "type": "multipleChoice",
                "answer": [
                    "A national election is a democratic way of choosing leaders in a country"
                ],
                "options": [
                    "A national election is the process of counting people in an area",
                    "A national election is a democratic way of choosing leaders in a country"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two bad practices done by people during the national elections.",
                        "type": "check_box",
                        "answer": [
                            "Ballot stuffing",
                            "Ballot stuffing"
                        ],
                        "options": [
                            "Corruption",
                            "Ballot stuffing",
                            "Ballot stuffing",
                            "Theft"
                        ]
                    },
                    {
                        "question": "In which one way is a voters’ register important during elections?",
                        "type": "multipleChoice",
                        "answer": [
                            "It eases the identification of voters"
                        ],
                        "options": [
                            "It eases the identification of voters",
                            "It helps to know the people in an area"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B49_6"
            }
        ],
        "category": 49,
        "$id": "document_44"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Why is Stevenson screen designed with louvres?",
                "type": "multipleChoice",
                "answer": [
                    "To allow free air circulation"
                ],
                "options": [
                    "To make its appearance better",
                    "To allow free air circulation"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two weather instruments kept in a Stevenson screen",
                        "type": "check_box",
                        "answer": [
                            "Barometer",
                            "Hygrometer"
                        ],
                        "options": [
                            "Barometer",
                            "Ranfall gauge",
                            "Hygrometer",
                            "Wind sock"
                        ]
                    },
                    {
                        "question": "What is meant by weather forecasting?",
                        "type": "multipleChoice",
                        "answer": [
                            "Weather forecasting is the telling of the expected weather conditions of an area"
                        ],
                        "options": [
                            "Weather forecasting is predicting whether it may rain or not in an area",
                            "Weather forecasting is the telling of the expected weather conditions of an area"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B50_1"
            },
            {
                "question": "In which two ways have regional groupings promoted trade in Africa?",
                "type": "check_box",
                "answer": [
                    "By creating a wider market for goods",
                    "By reducing trade barriers in Africa"
                ],
                "options": [
                    "By creating a wider market for goods",
                    "By fighting corruption in Africa",
                    "By reducing trade barriers in Africa",
                    "By providing Africans with basic needs"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two benefits Uganda can get by participating in peace keeping missions in Africa",
                        "type": "check_box",
                        "answer": [
                            "Uganda gets market for her goods",
                            "It helps Uganda to fight corruption",
                            "It helps Ugandans to get basic needs",
                            "It promotes international relations or unity"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B50_2"
            },
            {
                "question": "Give the source of solar energy",
                "type": "text",
                "answer": [
                    "The sun",
                    "Sun"
                ],
                "sub_questions": [
                    {
                        "question": "Give the source of wind energy",
                        "type": "text",
                        "answer": [
                            "The wind",
                            "Wind"
                        ]
                    },
                    {
                        "question": "Select any two benefits of extending hydro-electric power (HEP) to all parts of Uganda.",
                        "type": "check_box",
                        "answer": [
                            "It promotes industrialization",
                            "It controls rural-urban migration"
                        ],
                        "options": [
                            "It promotes industrialization",
                            "It provides education services",
                            "It controls rural-urban migration",
                            "It helps to fight corruption"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B50_3"
            },
            {
                "question": "The diagram below shows a formation of a physical feature. Use it to answer the questions that follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2020_50.png",
                "sub_questions": [
                    {
                        "question": "Name the process shown with arrows on the diagram.",
                        "type": "text",
                        "answer": [
                            "Faulting"
                        ]
                    },
                    {
                        "question": "Which physical feature is formed in the area marked B",
                        "type": "text",
                        "answer": [
                            "Rift valley"
                        ]
                    },
                    {
                        "question": "What term is used to mean the slopes marked D on the diagram above?",
                        "type": "text",
                        "answer": [
                            "Escarpment"
                        ]
                    },
                    {
                        "question": "State any one reason why area marked C is cooler than the area marked B on the diagram",
                        "type": "multipleChoice",
                        "answer": [
                            "Area C is on a higher altitude than area B"
                        ],
                        "options": [
                            "Area C is at a lower altitude than area B",
                            "Area C is on a higher altitude than area B"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B50_4"
            },
            {
                "question": "Name the trading company that constructed the Uganda Railway",
                "type": "text",
                "answer": [
                    "Imperial British East Africa Company",
                    "IBEACO"
                ],
                "sub_questions": [
                    {
                        "question": "Mention the problem faced by the builders of the Uganda Railway at Nandi land",
                        "type": "multipleChoice",
                        "answer": [
                            "They faced attacks from hostile tribes"
                        ],
                        "options": [
                            "They faced attacks from fierce lions",
                            "They faced attacks from hostile tribes"
                        ]
                    },
                    {
                        "question": "Mention the problem faced by the builders of the Uganda Railway at Tsavo national game park",
                        "type": "multipleChoice",
                        "answer": [
                            "They faced attacks from fierce lions"
                        ],
                        "options": [
                            "They faced attacks from fierce lions",
                            "They faced attacks from hostile tribes"
                        ]
                    },
                    {
                        "question": "How did the building of the Uganda Railway help to stop slave trade in East Africa",
                        "type": "multipleChoice",
                        "answer": [
                            "Trains were used to transport goods instead of slaves"
                        ],
                        "options": [
                            "Trains were used to improve infrastructure in East Africa",
                            "Trains were used to transport goods instead of slaves"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B50_5"
            },
            {
                "question": "tudy the sketch map of Uganda below and answer the questions that follow:",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2018_50.png",
                "sub_questions": [
                    {
                        "question": "Name the National Game Park found at place marked P",
                        "type": "text",
                        "answer": [
                            "Lake Mburo National Park"
                        ]
                    },
                    {
                        "question": "State any one reason why there are no crocodiles in the National Game Park marked S.",
                        "type": "multipleChoice",
                        "answer": [
                            "Due to the absence of a permanent waterbody"
                        ],
                        "options": [
                            "Because the area is a desert",
                            "Due to the absence of a permanent waterbody"
                        ]
                    },
                    {
                        "question": "Select any two economic benefits of national game parks in Uganda",
                        "type": "check_box",
                        "answer": [
                            "They promote tourism",
                            "They are source of employment"
                        ],
                        "options": [
                            "They promote tourism",
                            "They are source of employment",
                            "They help to promote human rights",
                            "They help to fight corruption"
                        ]
                    }
                ],
                "id": "ple_sst_sect_B50_6"
            }
        ],
        "category": 50,
        "$id": "document_45"
    },
    {
        "instructions": "For each of the questions 51 to 55, answer EITHER the Christian OR Islamic question but not both. No marks will be awarded to a candidate who attempts both alternatives in a particular number.",
        "questions": [
            {
                "either": {
                    "question": "What type of religion existed in Africa before the coming of Christianity?",
                    "type": "text",
                    "answer": [
                        "African Traditional Religion"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select any two ways in which believers of the religion in (a) above showed that there was life after death",
                            "type": "check_box",
                            "answer": [
                                "They named their children after their dead relatives",
                                "They sacrificed animals to appease the spirits of their dead relatives"
                            ],
                            "options": [
                                "They named their children after their dead relatives",
                                "They prayed for the dead",
                                "They sacrificed animals to appease the spirits of their dead relatives"
                            ]
                        },
                        {
                            "question": "State any one reason why some Christians fear death",
                            "type": "check_box",
                            "answer": [
                                "They don't want to lose their wealth"
                            ],
                            "options": [
                                "They are not sure of life after death",
                                "They don't want to lose their wealth"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_either_1"
                },
                "or": {
                    "question": "What type of religion existed in Africa before the coming of Islam?",
                    "type": "text",
                    "answer": [
                        "African Traditional Religion"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select any two ways in which believers of the religion in (a) above showed that there was life after death",
                            "type": "check_box",
                            "answer": [
                                "They named their children after their dead relatives",
                                "They sacrificed animals to appease the spirits of their dead relatives"
                            ],
                            "options": [
                                "They named their children after their dead relatives",
                                "They prayed for the dead",
                                "They sacrificed animals to appease the spirits of their dead relatives"
                            ]
                        },
                        {
                            "question": "State any one reason why some Christians fear death",
                            "type": "check_box",
                            "answer": [
                                "They don't want to lose their wealth"
                            ],
                            "options": [
                                "They are not sure of life after death",
                                "They don't want to lose their wealth"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_or_1"
                }
            },
            {
                "either": {
                    "question": "What helped Jesus Christ to be successful when he was on earth?",
                    "type": "multipleChoice",
                    "answer": [
                        "His kindness and generosity brought him many followers"
                    ],
                    "options": [
                        "His kindness and generosity brought him many followers",
                        "Him being the son of God"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select any three factors that lead people to a successful life today",
                            "type": "check_box",
                            "answer": [
                                "The fear of God",
                                "High levels of education",
                                "Creativity"
                            ],
                            "options": [
                                "Having a family",
                                "The fear of God",
                                "Having children",
                                "High levels of education",
                                "Creativity"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_either_2"
                },
                "or": {
                    "question": "What helped Prophet Muhammad (PBUH) to be successful when he was on earth",
                    "type": "multipleChoice",
                    "answer": [
                        "Allah's guidance and protection"
                    ],
                    "options": [
                        "Allah's guidance and protection",
                        "Because he was Allah's servant"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select any three factors that lead people to a successful life today",
                            "type": "check_box",
                            "answer": [
                                "The fear of Allah",
                                "High levels of education",
                                "Creativity"
                            ],
                            "options": [
                                "Having a family",
                                "The fear of Allah",
                                "Having children",
                                "High levels of education",
                                "Creativity"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_or_2"
                }
            },
            {
                "either": {
                    "question": "What did Simon of Cyrene do?",
                    "type": "multipleChoice",
                    "answer": [
                        "Helped Jesus Christ to carry the cross"
                    ],
                    "options": [
                        "He buried the body of Jesus Christ",
                        "He helped Jesus Christ to carry the cross",
                        "He killed his brother",
                        "He betrayed Jesus Christ"
                    ],
                    "sub_questions": [
                        {
                            "question": "What did Joseph of Arimathea do?",
                            "type": "check_box",
                            "answer": [
                                "He buried the body of Jesus Christ"
                            ],
                            "options": [
                                "He buried the body of Jesus Christ",
                                "He helped Jesus Christ to carry the cross",
                                "He killed his brother",
                                "He betrayed Jesus Christ"
                            ]
                        },
                        {
                            "question": "What did Cain do?",
                            "type": "check_box",
                            "answer": [
                                "He killed his brother"
                            ],
                            "options": [
                                "He buried the body of Jesus Christ",
                                "He helped Jesus Christ to carry the cross",
                                "He killed his brother",
                                "He betrayed Jesus Christ"
                            ]
                        },
                        {
                            "question": "What did Judas Iscariot do?",
                            "type": "check_box",
                            "answer": [
                                "He betrayed Jesus Christ"
                            ],
                            "options": [
                                "He buried the body of Jesus Christ",
                                "He helped Jesus Christ to carry the cross",
                                "He killed his brother",
                                "He betrayed Jesus Christ"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_either_3"
                },
                "or": {
                    "question": "What did Abubakr do?",
                    "type": "multipleChoice",
                    "answer": [
                        "Was the first Caliph in Islam"
                    ],
                    "options": [
                        "Was the mother of Prophet Muhammad",
                        "The book given to Prophet Musa",
                        "Was the wife of Prophet Muhammad",
                        "Was the first Caliph in Islam"
                    ],
                    "sub_questions": [
                        {
                            "question": "Who was Amina?",
                            "type": "check_box",
                            "answer": [
                                "Was the mother of Prophet Muhammad"
                            ],
                            "options": [
                                "Was the mother of Prophet Muhammad",
                                "The book given to Prophet Musa",
                                "Was the wife of Prophet Muhammad",
                                "Was the first Caliph in Islam"
                            ]
                        },
                        {
                            "question": "Whats the taurat?",
                            "type": "check_box",
                            "answer": [
                                "The book given to Prophet Musa"
                            ],
                            "options": [
                                "Was the mother of Prophet Muhammad",
                                "The book given to Prophet Musa",
                                "Was the wife of Prophet Muhammad",
                                "Was the first Caliph in Islam"
                            ]
                        },
                        {
                            "question": "Who was Aisha?",
                            "type": "check_box",
                            "answer": [
                                "Was the wife of Prophet Muhammad"
                            ],
                            "options": [
                                "Was the mother of Prophet Muhammad",
                                "The book given to Prophet Musa",
                                "Was the wife of Prophet Muhammad",
                                "Was the first Caliph in Islam"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_or_3"
                }
            },
            {
                "either": {
                    "question": "What is marriage in Christianity?",
                    "type": "multipleChoice",
                    "answer": [
                        "Marriage is the legal love union between a man and a woman as husband and wife"
                    ],
                    "options": [
                        "Marriage is the legal love union between a man and a woman as husband and wife",
                        "Marriage is a christian sacrament or ritual that involves the symbolic consumption of bread and wine"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select any two reasons why Christians marry",
                            "type": "check_box",
                            "answer": [
                                "To earn respect from the society",
                                "To procreate"
                            ],
                            "options": [
                                "To earn respect from the society",
                                "Because they are required to marry",
                                "To procreate",
                                "To gain wealth"
                            ]
                        },
                        {
                            "question": "Give one quality of a good marriage partner",
                            "type": "multipleChoice",
                            "answer": [
                                "Honest"
                            ],
                            "options": [
                                "Educated",
                                "Honest"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_either_4"
                },
                "or": {
                    "question": "What is Nikah in Islam?",
                    "type": "multipleChoice",
                    "answer": [
                        "Nikah is an agreement between a man and a woman to live as husband and wife"
                    ],
                    "options": [
                        "Nikah is an agreement between a man and a woman to live as husband and wife",
                        "Nikah is an agreement for a man or woman to make the pilgrimage to mecca"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select any two reasons why Muslims marry",
                            "type": "check_box",
                            "answer": [
                                "To earn respect from the society",
                                "To procreate"
                            ],
                            "options": [
                                "To earn respect from the society",
                                "Because they are required to marry",
                                "To procreate",
                                "To gain wealth"
                            ]
                        },
                        {
                            "question": "Give one quality of a good marriage partner",
                            "type": "multipleChoice",
                            "answer": [
                                "Honest"
                            ],
                            "options": [
                                "Educated",
                                "Honest"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_or_4"
                }
            },
            {
                "either": {
                    "question": "Who is a martyr according to christian teaching?",
                    "type": "multipleChoice",
                    "answer": [
                        "A martyr is a person who dies for his or her faith in God"
                    ],
                    "options": [
                        "A martyr is a person who believes in his religion",
                        "A martyr is a person who dies for his or her faith in God"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select any two benefits of the benefits of the death of Jesus Christ to christian",
                            "type": "check_box",
                            "answer": [
                                "It promotes God's love for Christians",
                                "It brought salvation to Christians"
                            ],
                            "options": [
                                "It helped Christians to be rich",
                                "It promotes God's love for Christians",
                                "It brought salvation to Christians",
                                "It helped christians to go to church"
                            ]
                        },
                        {
                            "question": "What lesson can christians learn from the life of Mbaga Tuzinde",
                            "type": "multipleChoice",
                            "answer": [
                                "They can learn to sacrifice for the sake of Jesus Christ"
                            ],
                            "options": [
                                "They can learn to go to church",
                                "They can learn to sacrifice for the sake of Jesus Christ"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_either_5"
                },
                "or": {
                    "question": "Who is a martyr according to islamic teaching?",
                    "type": "multipleChoice",
                    "answer": [
                        "A martyr is a person who dies for his or her faith in Allah"
                    ],
                    "options": [
                        "A martyr is a person who believes in his religion",
                        "A martyr is a person who dies for his or her faith in Allah"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select any two postive results of the battles between muslims and the meccans",
                            "type": "check_box",
                            "answer": [
                                "Muslims were able to get new converts",
                                "Muslims grew stronger in faith"
                            ],
                            "options": [
                                "Muslims were able to get new converts",
                                "It helped muslims to fight corruption",
                                "Muslims grew stronger in faith",
                                "Muslims got recognised in the world"
                            ]
                        },
                        {
                            "question": "What lesson can a muslim learn from the wars fought by prophet Muhammed (P.B.U.H) in madina?",
                            "type": "multipleChoice",
                            "answer": [
                                "To have strong faith in Allah"
                            ],
                            "options": [
                                "A muslim can learn to have strong faith in Allah",
                                "A muslim can learn to go to the mosque for prayer"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_or_5"
                }
            },
            {
                "either": {
                    "question": "Select any two reasons why children should obey their parents",
                    "type": "check_box",
                    "answer": [
                        "To get blessings from God",
                        "To give respect to their parents"
                    ],
                    "options": [
                        "To get blessings from God",
                        "To be able to go to church",
                        "To give respect to their parents",
                        "To get basic needs"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select any two ways in which christians can avoid evil practices in their communities",
                            "type": "check_box",
                            "answer": [
                                "By joining the church choir",
                                "By reading the Holy Bible"
                            ],
                            "options": [
                                "By going to school",
                                "By joining the church choir",
                                "By reading the Holy Bible",
                                "By fighting corruption"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_either_6"
                },
                "or": {
                    "question": "Select any two reasons why children should obey their parents",
                    "type": "check_box",
                    "answer": [
                        "To get blessings from Allah",
                        "To give respect to their parents"
                    ],
                    "options": [
                        "To get blessings from Allah",
                        "To be able to go to mosque",
                        "To give respect to their parents",
                        "To get basic needs"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select any two ways in which muslims can avoid evil practices in their communities",
                            "type": "check_box",
                            "answer": [
                                "To get blessings from Allah",
                                "By reading the Quran"
                            ],
                            "options": [
                                "By going to school",
                                "To get blessings from Allah",
                                "By reading the Quran",
                                "By fighting corruption"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_or_6"
                }
            },
            {
                "either": {
                    "question": "\"I am nobody. How can I go to the King and bring the Israelities out of Egypt?\" (Exodus 3:11)",
                    "sub_questions": [
                        {
                            "question": "What made Moses to say the above words?",
                            "type": "multipleChoice",
                            "answer": [
                                "He was afraid of the pharaoh"
                            ],
                            "options": [
                                "He wanted to live the Israelities",
                                "He was afraid of the pharaoh"
                            ]
                        },
                        {
                            "question": "Select any two miracles performed in order to convice Pharaoh to release the children of Israel",
                            "type": "check_box",
                            "answer": [
                                "He turned his rod into a big snake",
                                "His hand became diseased"
                            ],
                            "options": [
                                "He turned his rod into a big snake",
                                "His hand became diseased",
                                "He parted the red sea for Israelities to pass",
                                "He healed the blind"
                            ]
                        },
                        {
                            "question": "Give any one lesson you learn from the above quotation.",
                            "type": "multipleChoice",
                            "answer": [
                                "I learn to trust God"
                            ],
                            "options": [
                                "I learn to be honest",
                                "I learn to trust God"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_either_7"
                },
                "or": {
                    "question": "\"Proper it is for me that I say nothing concerning Allah but the truth. Indeed I have come unto you from your Lord with a clear proof. So let the children of Israel depart along with me\". (Surat Al-Araf 7:105)",
                    "sub_questions": [
                        {
                            "question": "What made Prophet Musa to say the above words?",
                            "type": "multipleChoice",
                            "answer": [
                                "He was afraid of the Firaun"
                            ],
                            "options": [
                                "He was afraid of the Firaun",
                                "He wanted to live the Israelities"
                            ]
                        },
                        {
                            "question": "Select any two miracles prophet Musa performed in order to convice Firaun to release Ban Israel",
                            "type": "check_box",
                            "answer": [
                                "He turned his rod into a big snake",
                                "His hand became diseased"
                            ],
                            "options": [
                                "He turned his rod into a big snake",
                                "His hand became diseased",
                                "He parted the red sea for Israelities to pass",
                                "He healed the blind"
                            ]
                        },
                        {
                            "question": "Give any one lesson you learn from the above quotation",
                            "type": "multipleChoice",
                            "answer": [
                                "I learn to trust Allah"
                            ],
                            "options": [
                                "I learn to be honest",
                                "I learn to trust Allah"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_or_7"
                }
            },
            {
                "either": {
                    "question": "Select any two ways in which the Uganda Joint Christian Council (UJCC) has promoted social welfare of people.",
                    "type": "check_box",
                    "answer": [
                        "It has promoted morals through guidance and counselling",
                        "It has promoted unity among people"
                    ],
                    "options": [
                        "It has promoted morals through guidance and counselling",
                        "It has helped to organise free and fair elections",
                        "It has helped to fight corruption",
                        "It has promoted unity among people"
                    ],
                    "sub_questions": [
                        {
                            "question": "In which two ways can the Inter-Religious Council (IRC) promote unity in Uganda?",
                            "type": "check_box",
                            "answer": [
                                "By mediating peace talks",
                                "By guiding and counselling people"
                            ],
                            "options": [
                                "By organising elections",
                                "By mediating peace talks",
                                "By guiding and counselling people",
                                "By fighting corruption"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_either_8"
                },
                "or": {
                    "question": "Select any two ways in which the Uganda Muslim Supreme Council (UMSC) has promoted social welfare of people.",
                    "type": "check_box",
                    "answer": [
                        "It has promoted morals through guidance and counselling",
                        "It has promoted unity among people"
                    ],
                    "options": [
                        "It has promoted morals through guidance and counselling",
                        "It has helped to organise free and fair elections",
                        "It has helped to fight corruption",
                        "It has promoted unity among people"
                    ],
                    "sub_questions": [
                        {
                            "question": "In which two ways can the Inter-Religious Council (IRC) promote unity in Uganda?",
                            "type": "check_box",
                            "answer": [
                                "By mediating peace talks",
                                "By guiding and counselling people"
                            ],
                            "options": [
                                "By organising elections",
                                "By mediating peace talks",
                                "By guiding and counselling people",
                                "By fighting corruption"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_or_8"
                }
            },
            {
                "either": {
                    "question": "Give the meaning of baptism as a sacrament in christianity",
                    "type": "multipleChoice",
                    "answer": [
                        "It is the sacrament that introduces a person into Christianity"
                    ],
                    "options": [
                        "It is the sacrament in which Christians receive the body and blood of Jesus Christ",
                        "It is the sacrament that introduces a person into Christianity"
                    ],
                    "sub_questions": [
                        {
                            "question": "Give the meaning of holy communion as a sacrament in christianity",
                            "type": "multipleChoice",
                            "answer": [
                                "It is the sacrament in which Christians receive the body and blood of Jesus Christ"
                            ],
                            "options": [
                                "It is the sacrament in which Christians receive the body and blood of Jesus Christ",
                                "It is the sacrament that introduces a person into Christianity"
                            ]
                        },
                        {
                            "question": "Select two conditions that can enable a christian to take part in Holy communion",
                            "type": "check_box",
                            "answer": [
                                "One must have repented before receiving it",
                                "One must be baptized"
                            ],
                            "options": [
                                "One must be a christian who goes to church",
                                "One must have repented before receiving it",
                                "One must be baptized",
                                "One has to be a honest christian"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_either_9"
                },
                "or": {
                    "question": "Give the meaning of Zakah as a pillar of Islam",
                    "type": "multipleChoice",
                    "answer": [
                        "Zakah refers to the money and other items given by Muslims to the mosque to help the needy"
                    ],
                    "options": [
                        "Zakah refers to the journey made by a Muslim to Mecca",
                        "Zakah refers to the money and other items given by Muslims to the mosque to help the needy"
                    ],
                    "sub_questions": [
                        {
                            "question": "Give the meaning of Hijjah as a pillar of Islam",
                            "type": "multipleChoice",
                            "answer": [
                                "Hijjah refers to the journey made by a Muslim to Mecca"
                            ],
                            "options": [
                                "Hijjah refers to the journey made by a Muslim to Mecca",
                                "Hijjah refers to the money and other items given by Muslims to the mosque to help the needy"
                            ]
                        },
                        {
                            "question": "Select two conditions that enable a muslim to observe Hijjah",
                            "type": "check_box",
                            "answer": [
                                "One must have niyyah or intention",
                                "One must leave enough money for one's family"
                            ],
                            "options": [
                                "One has to be a muslim",
                                "One must have niyyah or intention",
                                "One must be honest",
                                "One must leave enough money for one's family"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_or_9"
                }
            },
            {
                "either": {
                    "question": "Select any two types of books in the holy bible.",
                    "type": "check_box",
                    "answer": [
                        "Gospel books",
                        "Epistles or Letters"
                    ],
                    "options": [
                        "Gospel books",
                        "Epistles or Letters",
                        "Mathew",
                        "John"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select any one way in which christians give respect to the holy bible",
                            "type": "multipleChoice",
                            "answer": [
                                "By keeping it in a clean place"
                            ],
                            "options": [
                                "By reading it",
                                "By keeping it in a clean place"
                            ]
                        },
                        {
                            "question": "Why do christains use the holy bible when preaching",
                            "type": "multipleChoice",
                            "answer": [
                                "Because it contains the word of God"
                            ],
                            "options": [
                                "Because it contains the word of God",
                                "Because it is a holy book"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_either_10"
                },
                "or": {
                    "question": "Name the holy book that was revealed to Prophet Issa",
                    "type": "text",
                    "answer": [
                        "Injil",
                        "Injir"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select any two ways in which muslims give respect to the holy Quran",
                            "type": "check_box",
                            "answer": [
                                "By keeping it in a clean place",
                                "By performing ablution before reading it"
                            ],
                            "options": [
                                "By performing ablution before reading it",
                                "By always carrying it",
                                "By reading it",
                                "By keeping it in a clean place"
                            ]
                        },
                        {
                            "question": "Why do muslim leaders use the holy Quran when preaching?",
                            "type": "multipleChoice",
                            "answer": [
                                "Because it contains the word of Allah"
                            ],
                            "options": [
                                "Because it contains the word of Allah",
                                "Because it is a holy book"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_or_10"
                }
            },
            {
                "either": {
                    "question": "Apart from love, select any other two parts of the fruits of the holy spirit",
                    "type": "check_box",
                    "answer": [
                        "Joy",
                        "Peace"
                    ],
                    "options": [
                        "Honest",
                        "Joy",
                        "Peace",
                        "Righteousness"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select any other two ways in which the holy spirit is important in the life of a christian",
                            "type": "check_box",
                            "answer": [
                                "He guides christians",
                                "He protects christians"
                            ],
                            "options": [
                                "He guides christians",
                                "He gives wealth to christians",
                                "He helps christians go to church",
                                "He protects christians"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_either_11"
                },
                "or": {
                    "question": "Select any two ways in which observing the pillars of Islam can change the life of a muslim",
                    "type": "check_box",
                    "answer": [
                        "They strengthen a Muslim's faith",
                        "They guide a muslim"
                    ],
                    "options": [
                        "They help a muslim go to the mosque",
                        "They strengthen a Muslim's faith",
                        "They guide a muslim",
                        "They help muslims to be rich"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select any two ways in which articles of Islamic faith are important to muslims",
                            "type": "check_box",
                            "answer": [
                                "They strengthen the faith of a muslim",
                                "They draw muslims closer to Allah"
                            ],
                            "options": [
                                "They strengthen the faith of a muslim",
                                "They help muslims to be rich",
                                "They draw muslims closer to Allah",
                                "They help a muslim go to the mosque"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_or_11"
                }
            },
            {
                "either": {
                    "question": "State any one traditional way of reconciliation",
                    "type": "multipleChoice",
                    "answer": [
                        "Through arbitration"
                    ],
                    "options": [
                        "Through going to church",
                        "Through arbitration"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select any two ways in which christians carry out reconciliation",
                            "type": "check_box",
                            "answer": [
                                "Through arbitration",
                                "Through exchanging gifts"
                            ],
                            "options": [
                                "Through going to church",
                                "Through arbitration",
                                "Through exchanging gifts",
                                "Through fighting corruption"
                            ]
                        },
                        {
                            "question": "Why is reconciliation very important in our communities?",
                            "type": "multipleChoice",
                            "answer": [
                                "It promotes peace"
                            ],
                            "options": [
                                "It helps to fight corruption",
                                "It promotes peace"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_either_12"
                },
                "or": {
                    "question": "State any one traditional way of reconciliation",
                    "type": "multipleChoice",
                    "answer": [
                        "Through arbitration"
                    ],
                    "options": [
                        "Through going to church",
                        "Through arbitration"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select any two ways in which muslims carry out reconciliation",
                            "type": "check_box",
                            "answer": [
                                "Through arbitration",
                                "Through exchanging gifts"
                            ],
                            "options": [
                                "Through going to church",
                                "Through arbitration",
                                "Through exchanging gifts",
                                "Through fighting corruption"
                            ]
                        },
                        {
                            "question": "Why is reconciliation very important in our communities?",
                            "type": "multipleChoice",
                            "answer": [
                                "It promotes peace"
                            ],
                            "options": [
                                "It helps to fight corruption",
                                "It promotes peace"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_or_12"
                }
            },
            {
                "either": {
                    "question": "Mention any one cause of suffering in our society",
                    "type": "text",
                    "answer": [
                        "Death of parents",
                        "Ignorance",
                        "Disobedience",
                        "Accidents",
                        "Diseases",
                        "Witchcraft",
                        "Poor family background"
                    ],
                    "sub_questions": [
                        {
                            "question": "State any one way in which Jesus suffered during his life on earth",
                            "type": "multipleChoice",
                            "answer": [
                                "He was made to carry a heavy cross"
                            ],
                            "options": [
                                "He was poisoned",
                                "He was made to carry a heavy cross"
                            ]
                        },
                        {
                            "question": "Select any two things a christian can do in times of suffering",
                            "type": "check_box",
                            "answer": [
                                "By praying to God",
                                "By reading the Holy Bible"
                            ],
                            "options": [
                                "By praying to God",
                                "By going to work",
                                "By reading the Holy Bible",
                                "By fighting corruption"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_either_13"
                },
                "or": {
                    "question": "Mention any one cause of suffering in our society",
                    "type": "text",
                    "answer": [
                        "Death of parents",
                        "Ignorance",
                        "Disobedience",
                        "Accidents",
                        "Diseases",
                        "Witchcraft",
                        "Poor family background"
                    ],
                    "sub_questions": [
                        {
                            "question": "State any one way in which Prophet Muhammad (P.B.U.H) suffered during his life on earth",
                            "type": "multipleChoice",
                            "answer": [
                                "He was poisoned"
                            ],
                            "options": [
                                "He was poisoned",
                                "He was made to carry a heavy cross"
                            ]
                        },
                        {
                            "question": "Select any two things a muslim can do in times of suffering",
                            "type": "check_box",
                            "answer": [
                                "By praying to Allah",
                                "By reciting the Holy Quran"
                            ],
                            "options": [
                                "By praying to Allah",
                                "By going to work",
                                "By reciting the Holy Quran",
                                "By fighting corruption"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_or_13"
                }
            },
            {
                "either": {
                    "question": "Select any two qualities of a good friend",
                    "type": "check_box",
                    "answer": [
                        "Loving",
                        "Caring"
                    ],
                    "options": [
                        "Educated",
                        "Loving",
                        "Caring",
                        "Wealthy"
                    ],
                    "sub_questions": [
                        {
                            "question": "Give any one reason why a christian child should have good friends",
                            "type": "multipleChoice",
                            "answer": [
                                "To receive good advice"
                            ],
                            "options": [
                                "To be rich",
                                "To receive good advice"
                            ]
                        },
                        {
                            "question": "State any one way of keeping good friends",
                            "type": "multipleChoice",
                            "answer": [
                                "By praying for them"
                            ],
                            "options": [
                                "By praying for them",
                                "By giving them money"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_either_14"
                },
                "or": {
                    "question": "Select any two qualities of a good friend",
                    "type": "check_box",
                    "answer": [
                        "Loving",
                        "Caring"
                    ],
                    "options": [
                        "Educated",
                        "Loving",
                        "Caring",
                        "Wealthy"
                    ],
                    "sub_questions": [
                        {
                            "question": "Give any one reason why a muslim child should have good friends",
                            "type": "multipleChoice",
                            "answer": [
                                "To receive good advice"
                            ],
                            "options": [
                                "To be rich",
                                "To receive good advice"
                            ]
                        },
                        {
                            "question": "State any one way of keeping good friends",
                            "type": "multipleChoice",
                            "answer": [
                                "By praying for them"
                            ],
                            "options": [
                                "By praying for them",
                                "By giving them money"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_or_14"
                }
            },
            {
                "either": {
                    "question": "Mention any one book which was written by Moses",
                    "type": "text",
                    "answer": [
                        "Genesis",
                        "Exodus",
                        "Leviticus",
                        "Numbers",
                        "Deuteronomy"
                    ],
                    "sub_questions": [
                        {
                            "question": "State any one way in which the Bible is different from other books",
                            "type": "multipleChoice",
                            "answer": [
                                "The Holy Bible was written by people inspired with Holy Spirit unlike other books"
                            ],
                            "options": [
                                "The bible is different because it is a holy book",
                                "The Holy Bible was written by people inspired with Holy Spirit unlike other books"
                            ]
                        },
                        {
                            "question": "Select any two ways in which the Bible is important to christians.",
                            "type": "check_box",
                            "answer": [
                                "It guides Christians",
                                "It strengthens the faith of christians"
                            ],
                            "options": [
                                "It is a holy book",
                                "It guides Christians",
                                "It was written by God",
                                "It strengthens the faith of christians"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_either_15"
                },
                "or": {
                    "question": "Mention any one holy book in Islam which was received by prophet Musa",
                    "type": "text",
                    "answer": [
                        "Tauret",
                        "Taurat"
                    ],
                    "sub_questions": [
                        {
                            "question": "State any one way in which the Quran is different from other books",
                            "type": "multipleChoice",
                            "answer": [
                                "The Quran is used during prayers unlike other books"
                            ],
                            "options": [
                                "The Quran is different because it is a holy book",
                                "The Quran is used during prayers unlike other books"
                            ]
                        },
                        {
                            "question": "Select any two ways in which the Quran is important to muslims.",
                            "type": "check_box",
                            "answer": [
                                "It guides muslims",
                                "It strengthens the faith of muslims"
                            ],
                            "options": [
                                "It is a holy book",
                                "It guides muslims",
                                "It was written by Allah",
                                "It strengthens the faith of muslims"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_or_15"
                }
            },
            {
                "either": {
                    "question": "Select any two groups of church leaders who conduct marriages",
                    "type": "check_box",
                    "answer": [
                        "Priests",
                        "Reverends"
                    ],
                    "options": [
                        "Nuns",
                        "Priests",
                        "Reverends",
                        "Catechist"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select any two ways in which christan marriages have promoted morals among christians",
                            "type": "check_box",
                            "answer": [
                                "They have encouraged christian couples to remain faithful to each other",
                                "They have encouraged christians to care for each other"
                            ],
                            "options": [
                                "They have encouraged christians to fight corruption",
                                "They have encouraged christian couples to remain faithful to each other",
                                "They have encouraged christians to care for each other",
                                "They have encouraged christians to get married"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_either_16"
                },
                "or": {
                    "question": "Select any two groups of muslim leaders who conduct marriages",
                    "type": "check_box",
                    "answer": [
                        "Sheikh",
                        "Mufti"
                    ],
                    "options": [
                        "Mufti",
                        "Priests",
                        "Reverends",
                        "Sheikh"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select any two ways in which marriages have promoted morals among muslims",
                            "type": "check_box",
                            "answer": [
                                "They have encouraged them to remain faithful to each other",
                                "They have encouraged Muslims to take care of each other"
                            ],
                            "options": [
                                "They have encouraged Muslims to fight corruption",
                                "They have encouraged them to remain faithful to each other",
                                "They have encouraged Muslims to take care of each other",
                                "They have encouraged Muslims to get married"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_or_16"
                }
            },
            {
                "either": {
                    "question": "Select any two ways in which christians prepare themselves for christmas day",
                    "type": "check_box",
                    "answer": [
                        "By decorating their houses",
                        "By attending prayers"
                    ],
                    "options": [
                        "By healing the sick",
                        "By decorating their houses",
                        "By going to the mosque",
                        "By attending prayers"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select any two ways in which christians share joy of christmas with other people",
                            "type": "check_box",
                            "answer": [
                                "By inviting other people to eat with them",
                                "By exchanging gifts with others"
                            ],
                            "options": [
                                "By going to church",
                                "By decorating their houses",
                                "By inviting other people to eat with them",
                                "By exchanging gifts with others"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_either_17"
                },
                "or": {
                    "question": "Select any two ways in which muslims prepare themselves for Idd-el-fitr",
                    "type": "check_box",
                    "answer": [
                        "By attending Idd prayers",
                        "By preparing food for their families"
                    ],
                    "options": [
                        "By healing the sick",
                        "By preparing food for their families",
                        "By going to the church",
                        "By attending Idd prayers"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select any two ways in which muslims share joy of Idd-el-fitr with other people",
                            "type": "check_box",
                            "answer": [
                                "By inviting other people to eat with them",
                                "By exchanging gifts with others"
                            ],
                            "options": [
                                "By going to mosque",
                                "By decorating their houses",
                                "By inviting other people to eat with them",
                                "By exchanging gifts with others"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_or_17"
                }
            },
            {
                "either": {
                    "question": "Select any two roles that were played by prophets in christianity",
                    "type": "check_box",
                    "answer": [
                        "They baptized people",
                        "They performed miracles"
                    ],
                    "options": [
                        "They fought the unfaithful",
                        "They baptized people",
                        "They performed miracles",
                        "They gave punishments to law breakers"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select two ways in which prophets changed people's lives",
                            "type": "check_box",
                            "answer": [
                                "They guided people",
                                "They strengthened people's faith in God"
                            ],
                            "options": [
                                "They kept criminals away from society",
                                "They guided people",
                                "They were the people's leaders",
                                "They strengthened people's faith in God"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_either_18"
                },
                "or": {
                    "question": "Select any two roles that were played by prophets in islam",
                    "type": "check_box",
                    "answer": [
                        "They people to convert to Islam",
                        "They performed miracles"
                    ],
                    "options": [
                        "They fought the unfaithful",
                        "They people to convert to Islam",
                        "They performed miracles",
                        "They gave punishments to law breakers"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select two ways in which prophets changed people's lives",
                            "type": "check_box",
                            "answer": [
                                "They guided people",
                                "They strengthened people's faith in Allah"
                            ],
                            "options": [
                                "They kept criminals away from society",
                                "They guided people",
                                "They were the people's leaders",
                                "They strengthened people's faith in Allah"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_or_18"
                }
            },
            {
                "either": {
                    "question": "Where will sinners go on the day of Judgement",
                    "type": "text",
                    "answer": [
                        "Hell"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select any three ways in which christians should prepare for the day of Judgement",
                            "type": "check_box",
                            "answer": [
                                "By repenting their sins",
                                "By forgiving others",
                                "By getting baptized"
                            ],
                            "options": [
                                "By going to church",
                                "By repenting their sins",
                                "By going to school",
                                "By forgiving others",
                                "By going to work",
                                "By getting baptized"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_either_19"
                },
                "or": {
                    "question": "Where will sinners go on the day of Judgement",
                    "type": "text",
                    "answer": [
                        "Jannah"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select any three ways in which muslims should prepare for the day of Judgement",
                            "type": "check_box",
                            "answer": [
                                "By repenting their sins",
                                "By forgiving others",
                                "By getting baptized"
                            ],
                            "options": [
                                "By going to the mosque",
                                "By repenting their sins",
                                "By going to school",
                                "By forgiving others",
                                "By going to work",
                                "By observing the five pillars of Islam"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_or_19"
                }
            },
            {
                "either": {
                    "question": "Name the miracle Jesus performed while in a boat with his disciples",
                    "type": "multipleChoice",
                    "answer": [
                        "Jesus Christ calmed the storm"
                    ],
                    "options": [
                        "Jesus Christ calmed the storm",
                        "Jesus Christ walked on water"
                    ],
                    "sub_questions": [
                        {
                            "question": "Give one reason why Jesus performed miracles in His teaching.",
                            "type": "multipleChoice",
                            "answer": [
                                "To show that He was God's son"
                            ],
                            "options": [
                                "To go to heaven",
                                "To show that He was God's son"
                            ]
                        },
                        {
                            "question": "Select two ways in which Jesus' followers still benefit from the miracles He performed",
                            "type": "check_box",
                            "answer": [
                                "Their faith in God is strengthened",
                                "They get hope"
                            ],
                            "options": [
                                "Their faith in God is strengthened",
                                "They go to church",
                                "They get hope",
                                "They read the bible"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_either_20"
                },
                "or": {
                    "question": "Name the miracles Prophet Muhammad P.B.U.H performed",
                    "type": "multipleChoice",
                    "answer": [
                        "He split the moon into two parts"
                    ],
                    "options": [
                        "He walked on water",
                        "He split the moon into two parts"
                    ],
                    "sub_questions": [
                        {
                            "question": "Give one reason why Prophet Muhammad's followers still benefit from the miracles he performed miracles in His teaching.",
                            "type": "multipleChoice",
                            "answer": [
                                "To show that he was a true messenger of Allah"
                            ],
                            "options": [
                                "To go to heaven",
                                "To show that he was a true messenger of Allah"
                            ]
                        },
                        {
                            "question": "Select two ways in which Prophet Muhammad's followers still benefit from the miracles He performed",
                            "type": "check_box",
                            "answer": [
                                "Their faith in Allah is strengthened",
                                "They get hope"
                            ],
                            "options": [
                                "Their faith in Allah is strengthened",
                                "They go to mosque",
                                "They get hope",
                                "They read the Quran"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_or_20"
                }
            },
            {
                "either": {
                    "question": "Name the day that marks the beginning of fasting in Christianity",
                    "type": "text",
                    "answer": [
                        "Ash Wednesday"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select any two reasons why fasting is a good practice in Christianity",
                            "type": "check_box",
                            "answer": [
                                "It strengthens the faith of Christians",
                                "It enables Christians to get blessings from God"
                            ],
                            "options": [
                                "It makes Christians go to church",
                                "It strengthens the faith of Christians",
                                "It makes Christians read the bible",
                                "It enables Christians to get blessings from God"
                            ]
                        },
                        {
                            "question": "Give any one thing a Christian can do during fasting to get blessings from God",
                            "type": "multipleChoice",
                            "answer": [
                                "Helping the needy"
                            ],
                            "options": [
                                "Going to church",
                                "Helping the needy"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_either_21"
                },
                "or": {
                    "question": "What marks the beginning of fasting in Islam?",
                    "type": "multipleChoice",
                    "answer": [
                        "Sighting of the new moon"
                    ],
                    "options": [
                        "The beginning of the new month",
                        "Sighting of the new moon"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select any two reasons why fasting is a good practice in Islam",
                            "type": "check_box",
                            "answer": [
                                "It strengthens the faith of Muslims",
                                "It enables Muslims to receive rewards from Allah"
                            ],
                            "options": [
                                "It strengthens the faith of Muslims",
                                "It enables Muslims to receive rewards from Allah",
                                "It enables Muslims go to Jannah",
                                "It enables Muslims to read the Quran"
                            ]
                        },
                        {
                            "question": "Give any one thing a Muslim can do during fasting to get blessings from Allah",
                            "type": "multipleChoice",
                            "answer": [
                                "Helping the needy"
                            ],
                            "options": [
                                "Going to the mosque",
                                "Helping the needy"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_or_21"
                }
            },
            {
                "either": {
                    "question": "When do most Christians celebrate the birth of Jesus Christ?",
                    "type": "text",
                    "answer": [
                        "On Christmas day",
                        "On 25th December every year"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select any three things to show that Jesus Christ is the son of God",
                            "type": "check_box",
                            "answer": [
                                "He performed miracles",
                                "The Holy Spirit came down on earth during his baptism",
                                "Angel Gabriel brought the good news about His birth to Mary"
                            ],
                            "options": [
                                "He performed miracles",
                                "He split the moon into two parts",
                                "The Holy Spirit came down on earth during his baptism",
                                "He performed the Miraj",
                                "He performed the Hegira",
                                "Angel Gabriel brought the good news about His birth to Mary"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_either_22"
                },
                "or": {
                    "question": "When do most Muslims celebrate the birth of Prophet Muhammad P.B.U.H?",
                    "type": "text",
                    "answer": [
                        "12th Rabil Awwal",
                        "On 12th Rabil Awwal"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select any three things to show that Prophet Mohammad P.B.U.H was a true messenger of Allah",
                            "type": "check_box",
                            "answer": [
                                "He split the moon into two parts",
                                "He performed the Miraj",
                                "He performed the Hegira"
                            ],
                            "options": [
                                "He performed miracles",
                                "He split the moon into two parts",
                                "The Holy Spirit came down on earth during his baptism",
                                "He performed the Miraj",
                                "He performed the Hegira",
                                "Angel Gabriel brought the good news about His birth to Mary"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_or_22"
                }
            },
            {
                "either": {
                    "question": "\"Don't hurt the boy or do anything to him,\" he said. \"Now I know that you honour and obey God, because you have not kept back your only son from him.\" (Gen 22:12)",
                    "sub_questions": [
                        {
                            "question": "Who spoke the above words?",
                            "type": "text",
                            "answer": [
                                "An angel of God",
                                "An angel"
                            ]
                        },
                        {
                            "question": "Who was being talked to in the message?",
                            "type": "text",
                            "answer": [
                                "Abraham"
                            ]
                        },
                        {
                            "question": "Mention the son who was to be sacrificed",
                            "type": "text",
                            "answer": [
                                "Isaac"
                            ]
                        },
                        {
                            "question": "Give any one lesson you as a Christian learn from the message above",
                            "type": "multipleChoice",
                            "answer": [
                                "I learn to obey God"
                            ],
                            "options": [
                                "I learn to go to church",
                                "I learn to obey God"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_either_23"
                },
                "or": {
                    "question": "\"I am offering you in sacrifice to Allah..... O my father! Do that commanded to you by Allah.... \" (37:102)",
                    "sub_questions": [
                        {
                            "question": "Who spoke the above words?",
                            "type": "text",
                            "answer": [
                                "Prophet Ibrahim"
                            ]
                        },
                        {
                            "question": "Mention the son who was to be sacrificed",
                            "type": "text",
                            "answer": [
                                "Ismail"
                            ]
                        },
                        {
                            "question": "Select any two lessons you as a Muslim learn from the above quotation",
                            "type": "check_box",
                            "answer": [
                                "I learn to obey God",
                                "I learn to be faithful to Allah"
                            ],
                            "options": [
                                "I learn to go to church",
                                "I learn to obey God",
                                "I learn to be faithful to Allah",
                                "I learn to respect my elders"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_or_23"
                }
            },
            {
                "either": {
                    "question": "Name the Kabaka of Buganda who welcomed the first Christian missionaries to Uganda",
                    "type": "text",
                    "answer": [
                        "Kabaka Muteesa I"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select any two contributions of Christianity to the development of Uganda",
                            "type": "check_box",
                            "answer": [
                                "It has led to the building of hospitals",
                                "It has led to the building of schools"
                            ],
                            "options": [
                                "It has led to the fighting of corruption",
                                "It has led to the building of hospitals",
                                "It has led to the building of roads",
                                "It has led to the building of schools"
                            ]
                        },
                        {
                            "question": "Give any one similarity between the Christian missionaries and the first Arab traders in Uganda.",
                            "type": "multipleChoice",
                            "answer": [
                                "Both spread a foreign religion"
                            ],
                            "options": [
                                "Both built roads",
                                "Both spread a foreign religion"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_either_24"
                },
                "or": {
                    "question": "Name the Kabaka of Buganda who welcomed the first Arab traders to Uganda",
                    "type": "text",
                    "answer": [
                        "Kabaka Ssuuna II"
                    ],
                    "sub_questions": [
                        {
                            "question": "Select any two contributions of Islam to the development of Uganda",
                            "type": "check_box",
                            "answer": [
                                "It has led to the building of hospitals",
                                "It has led to the building of schools"
                            ],
                            "options": [
                                "It has led to the fighting of corruption",
                                "It has led to the building of hospitals",
                                "It has led to the building of roads",
                                "It has led to the building of schools"
                            ]
                        },
                        {
                            "question": "Give any one similarity between the first Arab traders and Christian missionaries in Uganda",
                            "type": "multipleChoice",
                            "answer": [
                                "Both spread a foreign religion"
                            ],
                            "options": [
                                "Both built roads",
                                "Both spread a foreign religion"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_or_24"
                }
            },
            {
                "either": {
                    "question": "Name any one symbol in Christianity",
                    "type": "text",
                    "answer": [
                        "Rosary",
                        "Cross",
                        "Holy Bible"
                    ],
                    "sub_questions": [
                        {
                            "question": "State any one way symbols are important to Christians",
                            "type": "multipleChoice",
                            "answer": [
                                "They strengthen the faith of Christians"
                            ],
                            "options": [
                                "They strengthen the faith of Christians",
                                "They help christians go to church"
                            ]
                        },
                        {
                            "question": "Select any two symbols of Christianity",
                            "type": "check_box",
                            "answer": [
                                "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2018_51.png",
                                "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2018_51D.png"
                            ],
                            "options": [
                                "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2018_51.png",
                                "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2018_51C.png",
                                "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2018_51D.png",
                                "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2018_51F.png"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_either_25"
                },
                "or": {
                    "question": "Name any one symbol in Islam",
                    "type": "text",
                    "answer": [
                        "Tasbih",
                        "Holy Quran",
                        "Star",
                        "Moon",
                        "Crescent"
                    ],
                    "sub_questions": [
                        {
                            "question": "State any one way symbols are important to Muslims",
                            "type": "multipleChoice",
                            "answer": [
                                "They are used during prayers"
                            ],
                            "options": [
                                "They are used during prayers",
                                "They help christians go to church"
                            ]
                        },
                        {
                            "question": "Select any two symbols of Islam",
                            "type": "check_box",
                            "answer": [
                                "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2018_51C.png",
                                "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2018_51F.png"
                            ],
                            "options": [
                                "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2018_51.png",
                                "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2018_51C.png",
                                "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2018_51D.png",
                                "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2018_51F.png"
                            ]
                        }
                    ],
                    "id": "ple_sst_sectB_or_25"
                }
            }
        ],
        "category": 51,
        "$id": "document_46"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "How did Dr. Albert Cook socially contribute to the life of people of Uganda?",
                "type": "multipleChoice",
                "answer": [
                    "He built Mengo hospital"
                ],
                "options": [
                    "He built Nsambya hospital",
                    "He built Mengo hospital"
                ],
                "id": "ple_sst_sect_A6_1"
            },
            {
                "question": "Name the game park in Uganda famous for ostriches",
                "type": "text",
                "answer": [
                    "Kidepo valley national park",
                    "Kidepo national park"
                ],
                "id": "ple_sst_sect_A6_2"
            },
            {
                "question": "Mention any one reason why school children should be taught practical skills",
                "type": "multipleChoice",
                "answer": [
                    "To reduce the rate of unemployment"
                ],
                "options": [
                    "To fight corruption",
                    "To reduce the rate of unemployment"
                ],
                "id": "ple_sst_sect_A6_3"
            },
            {
                "question": "State any one way in which a community can help the police to keep law and order",
                "type": "multipleChoice",
                "answer": [
                    "By reporting lawbreakers"
                ],
                "options": [
                    "By imprisoning wrong doers",
                    "By reporting lawbreakers"
                ],
                "id": "ple_sst_sect_A6_4"
            },
            {
                "question": "Why should children greet visitors at home?",
                "type": "multipleChoice",
                "answer": [
                    "To promote morals"
                ],
                "options": [
                    "To reduce illiteracy rates",
                    "To promote morals"
                ],
                "id": "ple_sst_sect_A6_5"
            },
            {
                "question": "Why is food one of the basic human needs?",
                "type": "multipleChoice",
                "answer": [
                    "Food is required by humans for growth",
                    "health andwell being"
                ],
                "options": [
                    "Food helps to cure diseases",
                    "Food is required by humans for growth",
                    "health andwell being"
                ],
                "id": "ple_sst_sect_A6_6"
            },
            {
                "question": "Give any one reason why a radio is important to a community.",
                "type": "multipleChoice",
                "answer": [
                    "A radio is used to broadcast important information to the community"
                ],
                "options": [
                    "A radio is used to show current affairs to the community",
                    "A radio is used to broadcast important information to the community"
                ],
                "id": "ple_sst_sect_A6_7"
            },
            {
                "question": "What is the main role of a speaker in parliament?",
                "type": "multipleChoice",
                "answer": [
                    "A speaker presides over parliamentary sessions"
                ],
                "options": [
                    "A speaker stores parliamentary records",
                    "A speaker presides over parliamentary sessions"
                ],
                "id": "ple_sst_sect_A6_8"
            },
            {
                "question": "Why is a hut not called a permanent house?",
                "type": "multipleChoice",
                "answer": [
                    "A hut is not durable"
                ],
                "options": [
                    "A hut is not durable",
                    "A hut lasts longer"
                ],
                "id": "ple_sst_sect_A6_9"
            },
            {
                "question": "Mention any one activity that the Bachwezi are remembered for.",
                "type": "text",
                "answer": [
                    "Animal rearing",
                    "Rearing cattle",
                    "Cattle rearing",
                    "Rearing animals"
                ],
                "id": "ple_sst_sect_A6_10"
            },
            {
                "question": "Mention any one way in which forests help to reduce the effect of bad weather.",
                "type": "multipleChoice",
                "answer": [
                    "Forests reduce the rate of soil erosion by wind"
                ],
                "options": [
                    "Forests help to reduce wildfires",
                    "Forests reduce the rate of soil erosion by wind"
                ],
                "id": "ple_sst_sect_A6_11"
            },
            {
                "question": "Apart from windy and rainy conditions, give any one other weather condition.",
                "type": "text",
                "answer": [
                    "Cloudy",
                    "Sunny",
                    "Foggy",
                    "Humid",
                    "Misty"
                ],
                "id": "ple_sst_sect_A6_12"
            },
            {
                "question": "Mention any one human activity which causes the number of wild animals to reduce in national game parks.",
                "type": "text",
                "answer": [
                    "Poaching",
                    "Deforestation",
                    "Bush burning"
                ],
                "id": "ple_sst_sect_A6_13"
            },
            {
                "question": "Name the element which shows a map reader what the map is about.",
                "type": "text",
                "answer": [
                    "Title",
                    "Heading"
                ],
                "id": "ple_sst_sect_A6_14"
            },
            {
                "question": "Name one tribe in Uganda that belongs to the Nilo-Hamites.",
                "type": "text",
                "answer": [
                    "Iteso",
                    "Karamajong",
                    "Kumam",
                    "Jie",
                    "Dodoth",
                    "Kakwa",
                    "Pokot",
                    "Tepeth",
                    "Pian",
                    "Bokora",
                    "Upe",
                    "Labwor."
                ],
                "id": "ple_sst_sect_A6_15"
            },
            {
                "question": "Give one reason why farmers are advised to plant crops at the beginning of a rainy season.",
                "type": "multipleChoice",
                "answer": [
                    "To save crops from destruction of the sunshine"
                ],
                "options": [
                    "For easy planting as the ground is hard enough",
                    "To save crops from destruction of the sunshine"
                ],
                "id": "ple_sst_sect_A6_16"
            },
            {
                "question": "Why is Carl Peters important in the history of East Africa?",
                "type": "multipleChoice",
                "answer": [
                    "Carl Peters was sent from Europe to look for Emin Pasha"
                ],
                "options": [
                    "Carl Peters discoverd the source of the Nile",
                    "Carl Peters was the first governor of Uganda",
                    "Carl Peters was sent from Europe to look for Emin Pasha"
                ],
                "id": "ple_sst_sect_A6_17"
            },
            {
                "question": "Select one way in which Game parks are important in East Africa",
                "type": "multipleChoice",
                "answer": [
                    "Game parks attract tourist earning foreign exchange"
                ],
                "options": [
                    "Game parks attract tourist earning foreign exchange",
                    "Game parks provide shelter to citizens of East Africa"
                ],
                "id": "ple_sst_sect_A6_18"
            },
            {
                "question": "What do the shield and spear stand for on the Coat of Arms of Uganda?",
                "type": "multipleChoice",
                "answer": [
                    "They stand for Uganda's commitment to defense, security and protection of its independence"
                ],
                "options": [
                    "The shield and the spear stand for Uganda is moving forward",
                    "The shield and the spear stand for brotherhood among Ugandans",
                    "They stand for Uganda's commitment to defense, security and protection of its independence"
                ],
                "id": "ple_sst_sect_A6_19"
            }
        ],
        "category": 6,
        "$id": "document_5"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Name the river which separates Tanzania from Uganda",
                "type": "text",
                "answer": [
                    "River Kagera"
                ],
                "id": "ple_sst_sect_A7_1"
            },
            {
                "question": "State any one importance of the East African Community to the member states",
                "type": "multipleChoice",
                "answer": [
                    "It has promoted cooperation and unity among member states"
                ],
                "options": [
                    "It has helped to fight corruption",
                    "It has promoted cooperation and unity among member states"
                ],
                "id": "ple_sst_sect_A7_2"
            },
            {
                "question": "What is the meaning of recycling as used in environmental protection?",
                "type": "multipleChoice",
                "answer": [
                    "Recycling is the process of changing waste materials into new products"
                ],
                "options": [
                    "Recycling is the process of proper disposal of waste materials",
                    "Recycling is the process of changing waste materials into new products"
                ],
                "id": "ple_sst_sect_A7_3"
            },
            {
                "question": "Mention any one in which school children can misuse their right to education",
                "type": "multipleChoice",
                "answer": [
                    "By dodging lessons"
                ],
                "options": [
                    "By being corrupt",
                    "By dodging lessons"
                ],
                "id": "ple_sst_sect_A7_4"
            },
            {
                "question": "How did the people in Kenya struggle to get independence",
                "type": "multipleChoice",
                "answer": [
                    "By forming political parties"
                ],
                "options": [
                    "By staging the Maji Maji rebellion",
                    "By forming political parties"
                ],
                "id": "ple_sst_sect_A7_5"
            },
            {
                "question": "Name the water body that is found at the western coast of Africa.",
                "type": "text",
                "answer": [
                    "Atlantic Ocean"
                ],
                "id": "ple_sst_sect_A7_6"
            },
            {
                "question": "How did the independence of Eritrea affect Ethiopia?",
                "type": "multipleChoice",
                "answer": [
                    "Ethiopia became landlocked"
                ],
                "options": [
                    "Eritrea became landlocked",
                    "Ethiopia became landlocked",
                    "Eritrea lost part of its land"
                ],
                "id": "ple_sst_sect_A7_7"
            },
            {
                "question": "Give one example of volcanic mountain found in South Africa.",
                "type": "text",
                "answer": [
                    "Salpeterkop",
                    "Marion Island",
                    "Prince Edward Island",
                    "Planesberg"
                ],
                "id": "ple_sst_sect_A7_8"
            },
            {
                "question": "Give one way in which children can help to keep security at home.",
                "type": "multipleChoice",
                "answer": [
                    "Reporting wrong doers and strangers"
                ],
                "options": [
                    "Fetching water",
                    "Reporting wrong doers/strangers",
                    "Washing utensils"
                ],
                "id": "ple_sst_sect_A7_9"
            },
            {
                "question": "Mention the best season for harvesting cotton.",
                "type": "text",
                "answer": [
                    "Dry season",
                    "Sunny season",
                    "Hot season"
                ],
                "id": "ple_sst_sect_A7_10"
            },
            {
                "question": "What is important about the place called Magosi in Karamoja sub-region?",
                "type": "text",
                "answer": [
                    "It is a stone age site or historical site",
                    "It is a place where early man lived",
                    "It is a tourist attraction site",
                    "It is an archaeological site",
                    "Its is a source of income and employment"
                ],
                "id": "ple_sst_sect_A7_11"
            },
            {
                "question": "Give the quickest means in which government sends information to its citizen.",
                "type": "text",
                "answer": [
                    "Radios",
                    "Television",
                    "Newspapers",
                    "Internet",
                    "Telephone",
                    "E-mails"
                ],
                "id": "ple_sst_sect_A7_12"
            },
            {
                "question": "Mention any one problem the government of Uganda is trying to solve by introducing Universal Primary Education.",
                "type": "text",
                "answer": [
                    "Illiteracy",
                    "Ignorance"
                ],
                "id": "ple_sst_sect_A7_13"
            },
            {
                "question": "In which country in East Africa were the Akidas and Jumbe used by colonialists to rule?",
                "type": "text",
                "answer": [
                    "Tanzania",
                    "Tanganyika"
                ],
                "id": "ple_sst_sect_A7_14"
            },
            {
                "question": "Name the winds that bring rain to East Africa.",
                "type": "text",
                "answer": [
                    "South-Eastern winds"
                ],
                "id": "ple_sst_sect_A7_15"
            },
            {
                "question": "Give one reason why there are many different types of natural vegetation in Africa.",
                "type": "multipleChoice",
                "answer": [
                    "The different distribution of rainfall in different areas"
                ],
                "options": [
                    "The different distribution of rainfall in different areas",
                    "The different colonial rulers who ruled in Africa"
                ],
                "id": "ple_sst_sect_A7_16"
            },
            {
                "question": "Why does Kalanga district receive convectional rainfall?",
                "type": "multipleChoice",
                "answer": [
                    "Kalangala receives convection rainfall because it is within Lake Victoria"
                ],
                "options": [
                    "Kalangala receives convection rainfall because it is near the equator",
                    "Kalangala receives convection rainfall because it is within Lake Victoria"
                ],
                "id": "ple_sst_sect_A7_17"
            },
            {
                "question": "Suggest any one disadvantage which a country with a low population faces.",
                "type": "multipleChoice",
                "answer": [
                    "A country with low population will have shortage of labour force"
                ],
                "options": [
                    "A country with low population will have a high rate of unemployment",
                    "A country with low population will have a high crime rates",
                    "A country with low population will have shortage of labour force"
                ],
                "id": "ple_sst_sect_A7_18"
            },
            {
                "question": "Soil erosion is a problem in mountaineous areas. Select any one way a farmer can solve this problem.",
                "type": "multipleChoice",
                "answer": [
                    "By constructing terraces around the steep slopes",
                    "By planting trees"
                ],
                "options": [
                    "By constructing terraces around the steep slopes",
                    "By planting trees",
                    "By planting more plants on the slopes"
                ],
                "id": "ple_sst_sect_A7_19"
            }
        ],
        "category": 7,
        "$id": "document_6"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Why is road construction difficult in Kigezi sub region?",
                "type": "multipleChoice",
                "answer": [
                    "Kigezi sub region is mountaineous in nature"
                ],
                "options": [
                    "Kigezi sub region is swampy",
                    "Kigezi sub region is mountaineous in nature"
                ],
                "id": "ple_sst_sect_A8_1"
            },
            {
                "question": "Write down any one effect of the Great Trek",
                "type": "multipleChoice",
                "answer": [
                    "It led to discovery of new minerals in the interior of South Africa"
                ],
                "options": [
                    "It led to discovery of new minerals in the interior of South Africa",
                    "It led to the formation of South Africa"
                ],
                "id": "ple_sst_sect_A8_2"
            },
            {
                "question": "Give one reason why a birthday party is called a social activity.",
                "type": "multipleChoice",
                "answer": [
                    "It unites people"
                ],
                "options": [
                    "It helps to fight violence",
                    "It unites people"
                ],
                "id": "ple_sst_sect_A8_3"
            },
            {
                "question": "Give the meaning of the term population density",
                "type": "multipleChoice",
                "answer": [
                    "It is the number of people living in an area per square kilometer"
                ],
                "options": [
                    "It a large number of people living in an area",
                    "It is the number of people living in an area per square kilometer"
                ],
                "id": "ple_sst_sect_A8_4"
            },
            {
                "question": "How can a market help people to meet their needs",
                "type": "multipleChoice",
                "answer": [
                    "It is a source of income to people"
                ],
                "options": [
                    "It is a source of income to people",
                    "It provides health services to people"
                ],
                "id": "ple_sst_sect_A8_5"
            },
            {
                "question": "Name the source of the raw material for making clay pots.",
                "type": "text",
                "answer": [
                    "Clay"
                ],
                "id": "ple_sst_sect_A8_6"
            },
            {
                "question": "What role did Dr. Kwame Nkrumah play towards promoting Pan-Africanism?",
                "type": "multipleChoice",
                "answer": [
                    "Dr. Kwame Nkrumah led Ghana to independece"
                ],
                "options": [
                    "Dr. Kwame Nkrumah led Ghana to independece",
                    "Dr. Kwame Nkrumah led Nigeria to independece",
                    "Dr. Kwame Nkrumah led Zimbabwe to independece"
                ],
                "id": "ple_sst_sect_A8_7"
            },
            {
                "question": "Apart from using elections, state any one other way of getting leaders in a community.",
                "type": "multipleChoice",
                "answer": [
                    "Through hereditary or traditional system of leadership"
                ],
                "options": [
                    "Through military coups",
                    "Through hereditary or traditional system of leadership"
                ],
                "id": "ple_sst_sect_A8_8"
            },
            {
                "question": "What title is given to the political head of a sub-county in Uganda?",
                "type": "text",
                "answer": [
                    "Sub-county chief",
                    "Sub county chief",
                    "Chief",
                    "Subcounty chief"
                ],
                "id": "ple_sst_sect_A8_9"
            },
            {
                "question": "Which element of weather is measured using a barometer?",
                "type": "text",
                "answer": [
                    "Air pressure",
                    "Atmospheric pressure"
                ],
                "id": "ple_sst_sect_A8_10"
            },
            {
                "question": "Give any one example of a mineral that improves soil fertility",
                "type": "text",
                "answer": [
                    "Potassium nitrate",
                    "Potassium sulphate",
                    "Potassium"
                ],
                "id": "ple_sst_sect_A8_11"
            },
            {
                "question": "Mention the Muganda agent used by the British to extend their rule to Eastern Uganda.",
                "type": "text",
                "answer": [
                    "Semei Kakungulu",
                    "Kakungulu",
                    "Semei Lwakirenzi"
                ],
                "id": "ple_sst_sect_A8_12"
            },
            {
                "question": "Select one way in which African countries can improve on agricultural production.",
                "type": "multipleChoice",
                "answer": [
                    "Use of modern methods of farming",
                    "Encourage people to carry out large scale farming"
                ],
                "options": [
                    "Encourage people to carry out large scale farming",
                    "By increasing the taxes on agricultural products",
                    "Use of modern methods of farming"
                ],
                "id": "ple_sst_sect_A8_13"
            },
            {
                "question": "Name any one feature on a river that helps in generation of Hydro-Electric power.",
                "type": "text",
                "answer": [
                    "Waterfall",
                    "Cataracts",
                    "Falls",
                    "Rapids",
                    "Gorge"
                ],
                "id": "ple_sst_sect_A8_14"
            },
            {
                "question": "Select any one way in which the partition of East Africa aaffected some tribes.",
                "type": "multipleChoice",
                "answer": [
                    "People lost land",
                    "Culture was lost",
                    "Tribes were separated"
                ],
                "options": [
                    "The tribes became rich",
                    "People lost land",
                    "Culture was lost",
                    "People got more connected",
                    "Tribes were separated"
                ],
                "id": "ple_sst_sect_A8_15"
            },
            {
                "question": "Which mineral is mined in the Osukuru hills in Tororo?",
                "type": "text",
                "answer": [
                    "Limestone"
                ],
                "id": "ple_sst_sect_A8_16"
            },
            {
                "question": "Select any one way of improving the labour force in Africa.",
                "type": "multipleChoice",
                "answer": [
                    "Through education and training of practical skills",
                    "Introducing adult education to fight illiteracy"
                ],
                "options": [
                    "Introduce family planning in homes",
                    "Through education and training of practical skills",
                    "Introducing adult education to fight illiteracy"
                ],
                "id": "ple_sst_sect_A8_17"
            },
            {
                "question": "In which way was the coming of Captain F.D.Lugard of an advantage to the Tooro kingdom?",
                "type": "multipleChoice",
                "answer": [
                    "F.D Lugard built a Fort in Tooro  Kingdom"
                ],
                "options": [
                    "F.D Lugard promoted tourism in Tooro Kingdom",
                    "F.D Lugard built a Fort in Tooro Kingdom"
                ],
                "id": "ple_sst_sect_A8_18"
            },
            {
                "question": "Which lake is shared by all the three East African countries?",
                "type": "text",
                "answer": [
                    "Lake Victoria"
                ],
                "id": "ple_sst_sect_A8_19"
            },
            {
                "question": "Why is savanna vegetation suitable for game parks in Africa?",
                "type": "text",
                "answer": [
                    "Savanna vegetation provides feeds for wild animals",
                    "It provides feeds for wild animals"
                ],
                "id": "ple_sst_sect_A8_20"
            }
        ],
        "category": 8,
        "$id": "document_7"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Give any one reason why people make plans of what to buy before shopping.",
                "type": "multipleChoice",
                "answer": [
                    "To cater for priorities"
                ],
                "options": [
                    "To buy better things",
                    "To cater for priorities"
                ],
                "id": "ple_sst_sect_A9_1"
            },
            {
                "question": "Which important discovery of early man marked the end of Stone Age period?",
                "type": "text",
                "answer": [
                    "Discovery of iron",
                    "Iron smelting"
                ],
                "id": "ple_sst_sect_A9_2"
            },
            {
                "question": "Name the type of mountains formed as a result of the process shown below.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2023_9.png",
                "type": "text",
                "answer": [
                    "Fold mountains"
                ],
                "id": "ple_sst_sect_A9_3"
            },
            {
                "question": "What does the map symbol below represent?",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2022_9.png",
                "type": "text",
                "answer": [
                    "A dam",
                    "Dam"
                ],
                "id": "ple_sst_sect_A9_4"
            },
            {
                "question": "Why is Arusha an important town to the East African countries",
                "type": "multipleChoice",
                "answer": [
                    "Arusha has the headquarters of the East African Community"
                ],
                "options": [
                    "Arusha is the capital city of Tanzania",
                    "Arusha has the headquarters of the East African Community"
                ],
                "id": "ple_sst_sect_A9_5"
            },
            {
                "question": "Give any one advantage of locking doors and windows at night.",
                "type": "text",
                "answer": [
                    "It protects the house from intruders",
                    "It protects the family members from outside weather"
                ],
                "id": "ple_sst_sect_A9_6"
            },
            {
                "question": "Mention any one advantage of a nuclear family over an extended family.",
                "type": "multipleChoice",
                "answer": [
                    "Nuclear family ensures better financial stability"
                ],
                "options": [
                    "It provides enough labour within the  family",
                    "Nuclear family ensures better financial stability"
                ],
                "id": "ple_sst_sect_A9_7"
            },
            {
                "question": "Name the water passage that connects the mediterranean sea to the red sea.",
                "type": "text",
                "answer": [
                    "Suez canal"
                ],
                "id": "ple_sst_sect_A9_8"
            },
            {
                "question": "What is the use of a compass to a sailor?",
                "type": "multipleChoice",
                "answer": [
                    "It helps the sailor to know the direction"
                ],
                "options": [
                    "It helps the sailor to know their position",
                    "It helps the sailor to know the direction"
                ],
                "id": "ple_sst_sect_A9_9"
            },
            {
                "question": "Mention any one port in East Africa that handles Uganda's imports and exports.",
                "type": "text",
                "answer": [
                    "Mombasa",
                    "Dar-es-Salaam",
                    "Port Jinja",
                    "Kisumu",
                    "Musoma",
                    "Bukoba"
                ],
                "id": "ple_sst_sect_A9_10"
            },
            {
                "question": "State any one way in which prisons are important to a country.",
                "type": "text",
                "answer": [
                    "They keep prisoners in safe custody",
                    "They provide skills to prisoners",
                    "They help to reform prisoners"
                ],
                "id": "ple_sst_sect_A9_11"
            },
            {
                "question": "Name the chief who is remembered for his resistance to colonial rule in Nothern Uganda.",
                "type": "text",
                "answer": [
                    "Chief Awich",
                    "Chief Awich of payira",
                    "Rwot Awich"
                ],
                "id": "ple_sst_sect_A9_12"
            },
            {
                "question": "Apart from constructing schools, state any one way missionaries contributed in improving the life of the people of Africa.",
                "type": "text",
                "answer": [
                    "They built hospitals",
                    "They built roads",
                    "Built roads",
                    "Built hospitals",
                    "Introduced modern medicine",
                    "Taught Christianity"
                ],
                "id": "ple_sst_sect_A9_13"
            },
            {
                "question": "How did Alexander Mackay promote education in Uganda?",
                "type": "multipleChoice",
                "answer": [
                    "He introduction of vocational and technical education"
                ],
                "options": [
                    "He introduction of vocational and technical education",
                    "He built schools in Uganda"
                ],
                "id": "ple_sst_sect_A9_14"
            },
            {
                "question": "State one reason why some people cannot get proper health services in your community.",
                "type": "text",
                "answer": [
                    "Poverty",
                    "Unemployment",
                    "Poor transport network",
                    "Remoteness of some places",
                    "Inadequate",
                    "Illiteracy drugs"
                ],
                "id": "ple_sst_sect_A9_15"
            },
            {
                "question": "Which people used dhows to come to East Africa?",
                "type": "text",
                "answer": [
                    "Arabs"
                ],
                "id": "ple_sst_sect_A9_16"
            },
            {
                "question": "Give one reason why landslides are not common in many parts of central and nothern Uganda.",
                "type": "multipleChoice",
                "answer": [
                    "Because the central and northern regions of Uganda are not hilly"
                ],
                "options": [
                    "Because the central and northern receive low amounts of rainfall",
                    "Because the central and northern regions of Uganda are not hilly"
                ],
                "id": "ple_sst_sect_A9_17"
            },
            {
                "question": "In which way is the work of the judiciary different from that of the legislature?",
                "type": "multipleChoice",
                "answer": [
                    "The judiciary interpretes and applys laws whereas legislature makes laws"
                ],
                "options": [
                    "The legislature interpretes and applys laws whereas judiciary makes laws",
                    "The judiciary interpretes and applys laws whereas legislature makes laws"
                ],
                "id": "ple_sst_sect_A9_18"
            },
            {
                "question": "What was the work of the Legislative Council (LEGCO) in colonial Uganda?",
                "type": "multipleChoice",
                "answer": [
                    "The work of legislature council was to make laws of the colonial government"
                ],
                "options": [
                    "The work of legislature council was to punish laws breakers",
                    "The work of legislature council was to make laws"
                ],
                "id": "ple_sst_sect_A9_19"
            },
            {
                "question": "Give any one volcanic mountain in Uganda",
                "type": "text",
                "answer": [
                    "Mountain elgon",
                    "Mountain moroto",
                    "Mountain muhavura"
                ],
                "id": "ple_sst_sect_A9_20"
            }
        ],
        "category": 9,
        "$id": "document_8"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "How did Johann Ludwig Krapf make it easy for the coastal people to understand the Bible?",
                "type": "multipleChoice",
                "answer": [
                    "He built the first mission station in East Africa at Rabai Mpya"
                ],
                "options": [
                    "He built fort portal in Kabarole to fight enemies",
                    "He built the first mission station in East Africa at Rabai Mpya"
                ],
                "id": "ple_sst_sect_A10_1"
            },
            {
                "question": "Give a reason why Libya's crude oil is on high demand in Europe",
                "type": "multipleChoice",
                "answer": [
                    "Libya's oil has less impurities"
                ],
                "options": [
                    "Libya's oil is cheap",
                    "Libya's oil has less impurities"
                ],
                "id": "ple_sst_sect_A10_2"
            },
            {
                "question": "Give any one way in which billboards promote trade in the community",
                "type": "multipleChoice",
                "answer": [
                    "They are used for advertising goods"
                ],
                "options": [
                    "They are used for advertising goods",
                    "They beautify the community"
                ],
                "id": "ple_sst_sect_A10_3"
            },
            {
                "question": "Mention any one role played by Kwame Nkrumah during the Pan African Conference of 1958.",
                "type": "multipleChoice",
                "answer": [
                    "He chaired the 1958 Accra conference"
                ],
                "options": [
                    "He led Ghana to independence",
                    "He chaired the 1958 Accra conference"
                ],
                "id": "ple_sst_sect_A10_4"
            },
            {
                "question": "Give any one reason why the United Nations Organisation sends peace keeping missions to war torn areas",
                "type": "multipleChoice",
                "answer": [
                    "To promote the respect of human rights"
                ],
                "options": [
                    "To fight for the right cause",
                    "To promote the respect of human rights"
                ],
                "id": "ple_sst_sect_A10_5"
            },
            {
                "question": "Why is New york city important in the daily running of the United Nations' activities?",
                "type": "multipleChoice",
                "answer": [
                    "Because the United Nations headquaters are in New york city"
                ],
                "options": [
                    "Because the New york city is in theUnited States",
                    "Because the United Nations headquaters are in New york city"
                ],
                "id": "ple_sst_sect_A10_6"
            },
            {
                "question": "How do citizens of Uganda show their respect when singing the national anthem?",
                "type": "text",
                "answer": [
                    "By standing",
                    "By removing hats",
                    "By placing the right hand on the heart"
                ],
                "id": "ple_sst_sect_A10_7"
            },
            {
                "question": "The diagram below shows a wind vane. Select the image in which letter K shows the direction in which the wind is blowing",
                "type": "multipleChoice",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2017_10.png",
                "answer": [
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2017_10A.png"
                ],
                "options": [
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2017_10A.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2017_10B.png"
                ],
                "id": "ple_sst_sect_A10_8"
            },
            {
                "question": "Mention any one way in which children benefit from United Nations Children's Fund (UNICEF)",
                "type": "multipleChoice",
                "answer": [
                    "It helps children to access basic healthcare services"
                ],
                "options": [
                    "It adopts children",
                    "It enforces laws to protect children",
                    "It helps children to access basic healthcare services"
                ],
                "id": "ple_sst_sect_A10_9"
            },
            {
                "question": "Name any one tribe in Uganda that belongs to the plain Nilotes.",
                "type": "text",
                "answer": [
                    "Iteso",
                    "Kakwa",
                    "Langi",
                    "Kumam",
                    "Karimajongo"
                ],
                "id": "ple_sst_sect_A10_10"
            },
            {
                "question": "What name is given to a group of people living together?",
                "type": "text",
                "answer": [
                    "Family",
                    "Community",
                    "Society"
                ],
                "id": "ple_sst_sect_A10_11"
            },
            {
                "question": "Give any one benefit which a family can get by living near a health center.",
                "type": "multipleChoice",
                "answer": [
                    "It has easy access to drugs and medicine"
                ],
                "options": [
                    "It has easy access to drugs and medicine",
                    "It has easy access to education"
                ],
                "id": "ple_sst_sect_A10_12"
            },
            {
                "question": "Name the country in the Horn of Africa which was not colonised.",
                "type": "text",
                "answer": [
                    "Ethiopia"
                ],
                "id": "ple_sst_sect_A10_13"
            },
            {
                "question": "Select any one reason why the people of Uganda wanted to be represented in the Legislative Council (LEGCO)",
                "type": "multipleChoice",
                "answer": [
                    "To participate in law making",
                    "To promote and protect their rights"
                ],
                "options": [
                    "To get independence",
                    "To participate in law making",
                    "To fight the colonialists",
                    "To promote and protect their rights"
                ],
                "id": "ple_sst_sect_A10_14"
            },
            {
                "question": "Give one way in which communication has become easy in Uganda.",
                "type": "multipleChoice",
                "answer": [
                    "Mobile phones have been introduced",
                    "Internet has been introduced"
                ],
                "options": [
                    "The creation of new ministries",
                    "Mobile phones have been introduced",
                    "The emphasis on gender equality",
                    "Internet has been introduced"
                ],
                "id": "ple_sst_sect_A10_15"
            },
            {
                "question": "How many countries does Uganda share its borders with?",
                "type": "text",
                "answer": [
                    "Five countries",
                    "Five"
                ],
                "id": "ple_sst_sect_A10_16"
            },
            {
                "question": "Name an international economic organisation that includes Nigeria, Algeria and Libya",
                "type": "multipleChoice",
                "answer": [
                    "Organisation of Petroleum Exporting Countries (OPEC)"
                ],
                "options": [
                    "Economic Community of West African States (ECOWAS)",
                    "African Union (AU)",
                    "Organisation of Petroleum Exporting Countries (OPEC)"
                ],
                "id": "ple_sst_sect_A10_17"
            },
            {
                "question": "State any one way in which the coming of Asians to East Africa helped in the economic development of East Africa",
                "type": "multipleChoice",
                "answer": [
                    "The Asians introduced new items for instance beads",
                    "clothes and guns."
                ],
                "options": [
                    "The Asians introduced religion in EastAfrica",
                    "The Asians introduced new items for instance beads, clothes and guns."
                ],
                "id": "ple_sst_sect_A10_18"
            },
            {
                "question": "Select any one way in which the government gets its income",
                "type": "multipleChoice",
                "answer": [
                    "Through taxation",
                    "Through fines and penalties",
                    "Through foreign Exchange"
                ],
                "options": [
                    "Through selling goods and services",
                    "Through taxation",
                    "Through fines and penalties",
                    "Through hiring out services to the public",
                    "Through foreign Exchange"
                ],
                "id": "ple_sst_sect_A10_19"
            },
            {
                "question": "In which way did the people of East Africa express their need for independence during the colonial rule?",
                "type": "multipleChoice",
                "answer": [
                    "They formed political parties and rebellions agitating for independence"
                ],
                "options": [
                    "They chased away the colonialists",
                    "They formed political parties and rebellions agitating for independence"
                ],
                "id": "ple_sst_sect_A10_20"
            }
        ],
        "category": 10,
        "$id": "document_9"
    }
]

const math_ple = [
    {
        "category": 1,
        "questions": [
            {
                "question": "Work out: 534 - 123",
                "type": "text",
                "answer": "411",
                "mark": 2,
                "id": "ple_math_sect_A1_1"
            },
            {
                "question": "Work out: 36 ÷ 3",
                "type": "text",
                "answer": "12",
                "mark": 2,
                "id": "ple_math_sect_A1_2"
            },
            {
                "question": "Work out: 32 x 3",
                "type": "text",
                "answer": "96",
                "mark": 2,
                "id": "ple_math_sect_A1_3"
            },
            {
                "question": "Work out: 23 + 42",
                "type": "text",
                "answer": "65",
                "mark": 2,
                "id": "ple_math_sect_A1_4"
            },
            {
                "question": "Work out: 124 - 45",
                "type": "text",
                "answer": "79",
                "mark": 2,
                "id": "ple_math_sect_A1_5"
            },
            {
                "question": "Work out: 14 + 53",
                "type": "text",
                "answer": "67",
                "mark": 2,
                "id": "ple_math_sect_A1_6"
            },
            {
                "question": "Work out : 22 x 4",
                "type": "text",
                "answer": "88",
                "mark": 2,
                "id": "ple_math_sect_A1_7"
            },
            {
                "question": "Work out: 87 - 65",
                "type": "text",
                "answer": "22",
                "mark": 2,
                "id": "ple_math_sect_A1_8"
            },
            {
                "question": "Subtract: 472 - 361",
                "type": "text",
                "answer": "111",
                "mark": 2,
                "id": "ple_math_sect_A1_9"
            },
            {
                "question": "Work out: 237 + 120",
                "type": "text",
                "answer": "357",
                "mark": 2,
                "id": "ple_math_sect_A1_10"
            }
        ],
        "$id": "document_0"
    },
    {
        "category": 2,
        "questions": [
            {
                "question": "Write XCVII IN Hindu Arabic numerals",
                "type": "text",
                "answer": "97",
                "mark": 2,
                "id": "ple_math_sect_A2_1"
            },
            {
                "question": "Write in figures: Nine thousand, thirty six",
                "type": "text",
                "answer": "9036",
                "mark": 2,
                "id": "ple_math_sect_A2_2"
            },
            {
                "question": "Write 650,019 in words",
                "type": "multipleChoice",
                "answer": "Six hundred fifty thousand nineteen",
                "options": [
                    "Six hundred fifty thousand nine",
                    "Six thousand five hundred nineteen",
                    "Six hundred fifty thousand nineteen"
                ],
                "mark": 2,
                "id": "ple_math_sect_A2_3"
            },
            {
                "question": "Simplify: 3a + a - 2a",
                "type": "multipleChoice",
                "answer": "2a",
                "explanation": "4a - 2a<br> = 2a",
                "options": [
                    "4a",
                    "2",
                    "2a"
                ],
                "mark": 2,
                "id": "ple_math_sect_A2_4"
            },
            {
                "question": "Write in figures: Eighty thousand, ten.",
                "type": "text",
                "answer": "80010",
                "mark": 2,
                "id": "ple_math_sect_A2_5"
            },
            {
                "question": "Write 99,040 in words",
                "type": "multipleChoice",
                "answer": "Ninety nine thousand forty",
                "options": [
                    "Nine hundred nine thousand four",
                    "Ninety nine thousand four",
                    "Ninety nine thousand forty"
                ],
                "mark": 2,
                "id": "ple_math_sect_A2_6"
            },
            {
                "question": "What number has been expanded below?<br> 20,000 + 600 + 8",
                "type": "text",
                "answer": "20608",
                "mark": 2,
                "id": "ple_math_sect_A2_7"
            },
            {
                "question": "Write in words: 55,001",
                "type": "multipleChoice",
                "answer": "Fifty five thousand one",
                "options": [
                    "Fifty five thousand one",
                    "Fifty five hundred thousand one",
                    "Fifty thousand five hundred"
                ],
                "mark": 2,
                "id": "ple_math_sect_A2_8"
            },
            {
                "question": "Write 93,044 in words",
                "type": "multipleChoice",
                "answer": "Ninety three thousand forty four",
                "options": [
                    "Nine thousand four",
                    "Ninety three thousand",
                    "Ninety three thousand forty four"
                ],
                "mark": 2,
                "id": "ple_math_sect_A2_9"
            },
            {
                "question": "Write in 5,092 words",
                "type": "multipleChoice",
                "answer": "Five thousand ninety two",
                "options": [
                    "Five thousand ninety",
                    "Five hundred ninety two",
                    "Five thousand ninety two"
                ],
                "mark": 2,
                "id": "ple_math_sect_A2_10"
            }
        ],
        "$id": "document_1"
    },
    {
        "category": 11,
        "questions": [
            {
                "question": "Find the next number in the sequence 58, 33, 17, 8, _____",
                "type": "multipleChoice",
                "answer": "4",
                "options": [
                    "7",
                    "6",
                    "4"
                ],
                "mark": 2,
                "id": "ple_math_sect_A11_1"
            },
            {
                "question": "Work out: (49 x 39) + (61 x 49)",
                "type": "multipleChoice",
                "answer": "4900",
                "explanation": "(49 x 39) + (61 x 49)<br> 49(39 + 61)<br> 49(100)<br> 49 x 100<br> 4900",
                "options": [
                    "198",
                    "4900",
                    "400"
                ],
                "mark": 2,
                "id": "ple_math_sect_A11_2"
            },
            {
                "question": "Work out: 1<sup>1</sup>/<sub>2</sub> - 2/3",
                "type": "multipleChoice",
                "answer": "5/6",
                "explanation": "Convert the mixed fraction to improper fraction<br> 3/2 - 2/3<br> Get the LCM<br> (9 - 4)/6<br> = 5/6",
                "options": [
                    "1/2",
                    "1/6",
                    "5/6"
                ],
                "mark": 2,
                "id": "ple_math_sect_A11_3"
            },
            {
                "question": "The area of a square flower garden is 196 m<sup>2</sup>. find the length of each side.",
                "type": "multipleChoice",
                "answer": "14 m",
                "explanation": "Area of a square = Side x Side<br> = S<sup>2</sup><br> 196 = S<sup>2</sup><br> √196 = √S<sup>2</sup><br> 14 = S<br> ∴ S = 14",
                "options": [
                    "14 m",
                    "49 m",
                    "98 m"
                ],
                "mark": 2,
                "id": "ple_math_sect_A11_4"
            },
            {
                "question": "In a car park there are 192 cars. The probability that a car picked at random from the park is made in Japan is 5/8. How many cars are not made in Japan?",
                "type": "multipleChoice",
                "answer": "72 cars",
                "explanation": "Number of cars made in Japan = 5/8 x 192<br> = 5 x 24<br> = 120 cars<br> Number of cars not made in Japan = Total number of cars - Number of cars made in Japan<br> = 192 - 120<br> = 72 cars",
                "options": [
                    "120 cars",
                    "78 cars",
                    "72 cars"
                ],
                "mark": 2,
                "id": "ple_math_sect_A11_5"
            },
            {
                "question": "Given that n = 3 and r =  -2, evaluate (2n+2r)/r",
                "type": "multipleChoice",
                "answer": "-1",
                "explanation": "(2 x 3 + 2 x -2)/-2<br> = (6 + -4)/2<br> = -2/2<br> = -1",
                "options": [
                    "-1",
                    "1",
                    "1/2"
                ],
                "mark": 2,
                "id": "ple_math_sect_A11_6"
            },
            {
                "question": "What morning time is shown on the clock face below?",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2013/11.png",
                "type": "multipleChoice",
                "answer": "8:25 a.m",
                "options": [
                    "2:25 a.m",
                    "8:25 a.m",
                    "8:45 a.m"
                ],
                "mark": 2,
                "id": "ple_math_sect_A11_7"
            },
            {
                "question": "Express 36400 in scientific notation",
                "type": "multipleChoice",
                "answer": "3.64 x 10<sup>4</sup>",
                "options": [
                    "3.64 x 10<sup>3</sup>",
                    "3.6 x 10<sup>2</sup>",
                    "3.64 x 10<sup>4</sup>"
                ],
                "mark": 2,
                "id": "ple_math_sect_A11_8"
            },
            {
                "question": "Express 0.1212… as a common fraction in its simplified form",
                "type": "multipleChoice",
                "answer": "4/33",
                "explanation": "Let the fraction be k<br> k = 0.1212 ......(i)<br> 100 x k = 0.1212 .... x 100<br> 100k = 12.1212 ....(ii)<br> Subtract equation (i) from equation (ii)<br> (100k = 12.1212.....) - (k =  0. 1212.....)<br> 99k = 12<br> 99k/99 = 12/99<br> k = 4/33",
                "options": [
                    "5/33",
                    "6/33",
                    "4/33"
                ],
                "mark": 2,
                "id": "ple_math_sect_A11_9"
            }
        ],
        "$id": "document_10"
    },
    {
        "category": 12,
        "questions": [
            {
                "question": "Calculate the speed of a motorist who covered a distance of 210 kilometres in 2.5 hours",
                "type": "multipleChoice",
                "answer": "84 km/hr",
                "explanation": "Speed = Distance/Time<br> Speed = 210/2.5<br> Speed = 84 kilometres/hour",
                "options": [
                    "84 km/hr",
                    "212.5 km/hr",
                    "525 km/hr"
                ],
                "mark": 2,
                "id": "ple_math_sect_A12_1"
            },
            {
                "question": "Round off 796 to the nearest tens.",
                "type": "text",
                "answer": "800",
                "explanation": "Since 6 is greater than or equal to 5, you round up the tens digit<br>. Therefore, the tens digit becomes 0, and the hundreds digit remains the same. The final result is 800.",
                "options": [
                    "795",
                    "800",
                    "795"
                ],
                "mark": 2,
                "id": "ple_math_sect_A12_2"
            },
            {
                "question": "Find the value of the digit in the ten thousand place in the number 850634.",
                "type": "text",
                "answer": "5",
                "mark": 2,
                "id": "ple_math_sect_A12_3"
            },
            {
                "question": "Convert 12½% to a fraction in its lowest term",
                "type": "multipleChoice",
                "answer": "1/8",
                "explanation": "12½/100<br> convert 12½ to an improper fraction<br> (25/2)/100<br> 25/2 ÷ 100<br> 25/2 ÷ 100/1<br> 25/2 x 1/100<br> 1/2 x 1/4<br> = 1/8",
                "options": [
                    "1/4",
                    "1/2",
                    "1/8"
                ],
                "mark": 2,
                "id": "ple_math_sect_A12_4"
            },
            {
                "question": "How many packets of 200 grammes can be got from 2.6 kilogrammes of salt?",
                "type": "multipleChoice",
                "answer": "13 packets",
                "explanation": "First convert 2.6 kg to grammes<br> 1 kg = 1000 g<br> 2.6 kg = (2.6 x 1000)g<br> = 2600 g<br> Number of packets = 2600/200<br> = 26/2<br> = 13 packets",
                "options": [
                    "13 packets",
                    "12 packets",
                    "75 packets"
                ],
                "mark": 2,
                "id": "ple_math_sect_A12_5"
            },
            {
                "question": "The graph below shows the temperature of a patient in a hospital taken from 7:00 a.m. to 12:00 noon in a day. What times of the day was the temperature of the patient the same?",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2014/12.png",
                "type": "multipleChoice",
                "answer": "10:00 a.m and 12:00 noon",
                "options": [
                    "10:00 a.m and 12:00 noon",
                    "10:00 a.m and 11:00 a.m",
                    "7:00 a.m and 110:00 a.m"
                ],
                "mark": 2,
                "id": "ple_math_sect_A12_6"
            },
            {
                "question": "Simplify : <sup>+</sup>4 - <sup>+</sup>6",
                "type": "multipleChoice",
                "answer": "-10",
                "explanation": "+4 - (+6)<br> = -10",
                "options": [
                    "-2",
                    "-10",
                    "10"
                ],
                "mark": 2,
                "id": "ple_math_sect_A12_7"
            },
            {
                "question": "Write 0.08 as a fraction in its simplest form",
                "type": "multipleChoice",
                "answer": "2/25",
                "explanation": "0.08 = 8/100<br> = 2/25",
                "options": [
                    "2/25",
                    "1/5",
                    "1/4"
                ],
                "mark": 2,
                "id": "ple_math_sect_A12_8"
            },
            {
                "question": "Given that a = <sup>-</sup>2 and b = 3. Find the value of a<sup>2</sup> - ab",
                "type": "multipleChoice",
                "answer": "10",
                "explanation": "a<sup>2</sup> ─ ab<br> (axa) - (axb)<br> (<sup>-</sup>2x<sup>-</sup>2) - (<sup>-</sup>2x3)<br> = 4 - (<sup>-</sup>6)<br> = 4 + 6<br> = 10",
                "options": [
                    "10",
                    "8",
                    "5"
                ],
                "mark": 2,
                "id": "ple_math_sect_A12_9"
            },
            {
                "question": "The ratio of the interior angle to the exterior angle of a regular polygon is 3:2. Find the size of the exterior angle.",
                "type": "multipleChoice",
                "answer": "Exterior angle = 72°",
                "explanation": "Interior angle = 3k<br> Exterior angle = 2k<br> Total = 180<br> Value of k;<br> 3k + 2k = 180<br> 5k = 180<br> k = 36<br> Exterior angle = 2 x k<br> = 2 x 36<br> = 72°",
                "options": [
                    "Exterior angle = 80°",
                    "Exterior angle = 70°",
                    "Exterior angle = 72°"
                ],
                "mark": 2,
                "id": "ple_math_sect_A12_10"
            }
        ],
        "$id": "document_11"
    },
    {
        "category": 13,
        "questions": [
            {
                "question": "Change 8<sub>ten</sub> to binary system.",
                "type": "multipleChoice",
                "answer": "1000",
                "explanation": "Divide 8 by 2. The quotient is 4 and the remainder is 0<br> Divide 4 by 2. The quotient is 2 and the remainder is 0<br> Divide 2 by 2. The quotient is 1 and the remainder is 0<br> Write down the remainders in reverse order as 0001<br> Therefore, 8 in binary is 1000",
                "options": [
                    "100",
                    "1000",
                    "10000"
                ],
                "mark": 2,
                "id": "ple_math_sect_A13_1"
            },
            {
                "question": "Which of the following diagrams show <sub>-</sub>5+<sub>+</sub>2 on the number line?",
                "type": "multipleChoice",
                "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2018/13.png",
                "options": [
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2018/13_1.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2018/13_2.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2018/13.png"
                ],
                "mark": 2,
                "id": "ple_math_sect_A13_2"
            },
            {
                "question": "A box contains 20 pens, 10 are blue, 7 are red and the rest black. A pen is picked at random from the box, find the probability that it is a black pen.",
                "type": "multipleChoice",
                "answer": "Probability = 3/20",
                "explanation": "Let the number of black pens be x<br> Number of black pens = Total number of pens - (Blue pens + Red pens)<br> = 20 - (10 + 7)<br> = 20 - 17<br> = 3 black pens<br> Probability = (Number of black pens)/(Total number of pens)<br> = 3/20",
                "options": [
                    "Probability = 1/10",
                    "Probability = 7/20",
                    "Probability = 3/20"
                ],
                "mark": 2,
                "id": "ple_math_sect_A13_3"
            },
            {
                "question": "The prime factors of 12 and 90 are given below;<br> 12 = 2<sup>2</sup> x 3<br> 90 = 2 x 3<sup>2</sup> x 5<br> Use the given prime factors above to find the Lowest Common Multiple (LCM) of 12 and 90",
                "type": "multipleChoice",
                "answer": "LCM = 180",
                "explanation": "2<sup>2</sup> x 3<sup>2</sup> x 5<br> = 4 x 9 x 5<br> = 180",
                "options": [
                    "LCM = 30",
                    "LCM = 180",
                    "LCM = 360"
                ],
                "mark": 2,
                "id": "ple_math_sect_A13_4"
            },
            {
                "question": "Given that a = <sup>-</sup>2, b = 3 and c = 4, find the value of b(a<sup>2</sup> + c)",
                "type": "multipleChoice",
                "answer": "24",
                "explanation": "3((<sup>-</sup>2)<sup>2</sup> + 4)<br> = 3(4 + 4)<br> = 3(8)<br> = 3 x 8<br> = 24",
                "options": [
                    "24",
                    "0",
                    "20"
                ],
                "mark": 2,
                "id": "ple_math_sect_A13_5"
            },
            {
                "question": "Today is Monday, and the workers on the farm are paid their salary. What day of the week will the workers' next pay be, 30 days from today?",
                "type": "multipleChoice",
                "answer": "Monday",
                "explanation": "Monday + 30 days = 30 days<br> Since every week has 7 days<br> 30 = (7 x 4) + 2",
                "options": [
                    "Tuesday",
                    "Friday",
                    "Monday"
                ],
                "mark": 2,
                "id": "ple_math_sect_A13_6"
            },
            {
                "question": "In a class, the ratio of girls to boys is 3:2. If there are 18 girls, how many pupils are in the class?",
                "type": "multipleChoice",
                "answer": "30 pupils",
                "explanation": "Total ratio = 3 + 2 = 5<br> Let each ratio be n<br> 3n = 18<br> 3n/3 = 18/3<br> n = 6<br> = 5 x 6<br> = 30 pupils",
                "options": [
                    "20 pupils",
                    "10 pupils",
                    "30 pupils"
                ],
                "mark": 2,
                "id": "ple_math_sect_A13_7"
            },
            {
                "question": "The perimeter of the rectangle below is 36m. Find its width if the length is 12 m.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2012/13.png",
                "type": "multipleChoice",
                "answer": "Width = 6 m",
                "explanation": "Perimeter =  (2L + 2W)<br> 36 = (2 x 12 + 2W)<br> 36 - 24 = 24- 24 + 2w<br> 12 = 2W<br> 6 = W<br> Therefore Width = 6",
                "options": [
                    "Width = 12 m",
                    "Width = 8 m",
                    "Width = 6 m"
                ],
                "mark": 2,
                "id": "ple_math_sect_A13_8"
            },
            {
                "question": "The cost of 2 dozen of exercise books is sh. 30,000. Find the cost of six similar exercise books",
                "type": "multipleChoice",
                "answer": "sh.7500",
                "explanation": "2 dozens = (12 x 2)<br> = 24 books<br> 24 books = sh.30,000<br> 1 book = 30,000/24<br> = sh.1250<br> 6 books = 1250 x 6<br> = sh.7500",
                "options": [
                    "sh.7000",
                    "sh.7500",
                    "sh.8000"
                ],
                "mark": 2,
                "id": "ple_math_sect_A13_9"
            },
            {
                "question": "Given that the bearing of A from B is 210° , find the bearing of B from A.",
                "type": "multipleChoice",
                "answer": "Bearing of B from A is 30°",
                "explanation": "Bearing of B from A = 210° - 180°<br> = 30°",
                "options": [
                    "Bearing of B from A is 40°",
                    "Bearing of B from A is 50°",
                    "Bearing of B from A is 30°"
                ],
                "mark": 2,
                "id": "ple_math_sect_A13_10"
            }
        ],
        "$id": "document_12"
    },
    {
        "category": 14,
        "questions": [
            {
                "question": "Find the smallest number that can be divided by 8 or 12 and leaves 5 as the remainder",
                "type": "multipleChoice",
                "answer": "29",
                "explanation": "Find the LCM of 8 and 12<br> 8 = 2<sup>3</sup><br> 12 = 2<sup>2</sup> x 3<br> LCM of 8 and 12 = 2<sup>3</sup> x 3<br> LCM = 24<br> Smallest number = LCM + remainder<br> Smallest number = 24 + 5<br> = 29",
                "options": [
                    "24",
                    "25",
                    "29"
                ],
                "mark": 2,
                "id": "ple_math_sect_A14_1"
            },
            {
                "question": "Martha drove from town A to town B at a speed of 72km per hour. Town A is 90km away from town B. Calculate the time she took to reach town B.",
                "type": "multipleChoice",
                "answer": "Time taken is 1 hour and 15 minutes",
                "explanation": "Time taken = Distance/Speed<br> = 90/72<br> = 15/12<br> Convert to minutes<br> 15/12 x 60<br> = 75 minutes<br> = 1 hour and 15 minutes",
                "options": [
                    "Time taken is 2 hours",
                    "Time taken is 1 hour",
                    "Time taken is 1 hour and 15 minutes"
                ],
                "mark": 2,
                "id": "ple_math_sect_A14_2"
            },
            {
                "question": "The diagram below shows the positions of two towns L and M. Work out the bearing of town L from town M",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2017/14.png",
                "type": "multipleChoice",
                "answer": "Bearing = 283<sup>o</sup>",
                "explanation": "Since the bearing is calculated from the North clockwise<br> (90 + 90 + 90) + (90 - 77) (corresponding angles)<br> = 270 + 13<br>  = 283<sup>o</sup>",
                "options": [
                    "Bearing = 347<sup>o</sup>",
                    "Bearing = 77<sup>o</sup>",
                    "Bearing = 283<sup>o</sup>"
                ],
                "mark": 2,
                "id": "ple_math_sect_A14_3"
            },
            {
                "question": "A wire of length 161 metres was shared by some boys. The average length of wire each boy got was 23 metres. Find the number of boys who shared the wire.",
                "type": "multipleChoice",
                "answer": "7 boys",
                "explanation": "Number of boys = (wire length)/(average length each boy got)<br> = 161/23<br> = 7",
                "options": [
                    "9 boys",
                    "8 boys",
                    "7 boys"
                ],
                "mark": 2,
                "id": "ple_math_sect_A14_4"
            },
            {
                "question": "Work out 1101<sub>two</sub> + 111<sub>two</sub>",
                "type": "multipleChoice",
                "answer": "10100<sub>two</sub>",
                "options": [
                    "1010<sub>two</sub>",
                    "10110<sub>two</sub>",
                    "10100<sub>two</sub>"
                ],
                "mark": 2,
                "id": "ple_math_sect_A14_5"
            },
            {
                "question": "Write the number whose scientific notation is 9.85 x 10<sup>3</sup>",
                "type": "multipleChoice",
                "answer": "9850",
                "explanation": "9.85 x (10 x 10 x 10)<br> = 9.85 x 1000<br> = 9850",
                "options": [
                    "9850",
                    "985",
                    "98500"
                ],
                "mark": 2,
                "id": "ple_math_sect_A14_6"
            },
            {
                "question": "Work out: 268 x 25",
                "type": "text",
                "answer": "6700",
                "mark": 2,
                "id": "ple_math_sect_A14_7"
            },
            {
                "question": "Express 2500 metres as kilometres",
                "type": "multipleChoice",
                "answer": "2.5 km",
                "explanation": "1000m = 1 km<br> 2500m = 2500/1000<br> = 25/10<br> = 2.5 km",
                "options": [
                    "3.5 km",
                    "2.5 km",
                    "4 km"
                ],
                "mark": 2,
                "id": "ple_math_sect_A14_8"
            },
            {
                "question": "Find the value of k in the figure",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/paper_2/14.png",
                "type": "multipleChoice",
                "answer": "k = 70°",
                "explanation": "2k - 30° = k + 40° (vertically opposite angles)<br> 2k - 30° + 30° = k + 40° + 30°<br> 2k - k = 70°<br> k = 70°",
                "options": [
                    "k = 75°",
                    "k = 70°",
                    "k = 80°"
                ],
                "mark": 2,
                "id": "ple_math_sect_A14_9"
            }
        ],
        "$id": "document_13"
    },
    {
        "category": 15,
        "questions": [
            {
                "question": "A teacher deposited shs 72,000 in a bank. After one year, the teacher earned a simple interest of shs 3,600. Calculate the simple interest rate of the bank.",
                "type": "multipleChoice",
                "answer": "Rate = 5",
                "explanation": "Simple Interest = (Principal x Rate x Time)/100<br> Rate = (Simple Interest x 100)/Principal x Time<br> Rate = (3600 x 100)/(72000 x 1)<br> Rate = 5",
                "options": [
                    "Rate = 15",
                    "Rate = 10",
                    "Rate = 5"
                ],
                "mark": 2,
                "id": "ple_math_sect_A15_1"
            },
            {
                "question": "The following heights of six children were recorded at a health centre: 53cm, 64cm, 59cm, 51cm, 63cm and 61cm. Find the median height of the children.",
                "type": "multipleChoice",
                "answer": "Median height = 60cm",
                "explanation": "Arrange the heights in ascending and descending order<br> 51cm, 53cm, 59cm, 61cm, 63cm, 64cm<br> Since we have two values in the middle<br> Add and divide them by 2<br> Median = (59 + 61)/2<br> = 60",
                "options": [
                    "Median height = 51cm",
                    "Median height = 60cm",
                    "Median height = 58.5cm"
                ],
                "mark": 2,
                "id": "ple_math_sect_A15_2"
            },
            {
                "question": "Find the length of the arc DK in the diagram below. (Use π = 22/7)",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/15.png",
                "type": "multipleChoice",
                "answer": "99 cm",
                "explanation": "From circumference of a circle;<br> C = 2πr<br> Since we finding the length of the arc<br> Length = 2πr/2<br> = πr<br> = 22/7 x 63/2<br> = 99",
                "options": [
                    "99 cm",
                    "126 cm",
                    "31.5 cm"
                ],
                "mark": 2,
                "id": "ple_math_sect_A15_3"
            },
            {
                "question": "Find the size of angle y in the figure below",
                "type": "multipleChoice",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2015/15.png",
                "answer": "y = 65<sup>o</sup>",
                "explanation": "y + y + 50 = 180<br> 2y + 50 = 180<br> 2y + 50 - 50 = 180 - 50<br> 2y = 130<br> y = 65",
                "options": [
                    "y = 50<sup>o</sup>",
                    "y = 80<sup>o</sup>",
                    "y = 65<sup>o</sup>"
                ],
                "mark": 2,
                "id": "ple_math_sect_A15_4"
            },
            {
                "question": "A cyclist covers 70 km in 2½ hours. How long will he take to cover 21 km at the same speed?",
                "type": "multipleChoice",
                "answer": "Time taken = 45 minutes",
                "explanation": "Speed = Distance/Time<br> = 70/2.5<br> = 28km/hr<br> Time with same speed = Distance/Speed<br> = 21/28<br> = 3/4<br> Convert 3/4 hours to minutes<br> = 3/4 x 60<br> = 45 minutes",
                "options": [
                    "Time taken = 1 hour and 45 minutes",
                    "Time taken = 45 minutes",
                    "Time taken = 35 minutes"
                ],
                "mark": 2,
                "id": "ple_math_sect_A15_5"
            },
            {
                "question": "Work out: 2 - 6(mod 7)",
                "type": "multipleChoice",
                "answer": "3(mod 7)",
                "explanation": "2 - 6 = __(mod 7)<br> (2+7) - 6 = __(mod 7)<br> 9 - 6 = 3(mod 7)<br> = 3(mod 7)",
                "options": [
                    "-4(mod 7)",
                    "4(mod 7)",
                    "3(mod 7)"
                ],
                "mark": 2,
                "id": "ple_math_sect_A15_6"
            },
            {
                "question": "Given that k = 2 and p = -3, find the valve 3k + 2p",
                "type": "multipleChoice",
                "answer": "0",
                "explanation": "3k + 2p<br> (3x2) + (2x-3)<br> = 6 + -6<br> = 0",
                "options": [
                    "-12",
                    "0",
                    "6"
                ],
                "mark": 2,
                "id": "ple_math_sect_A15_7"
            },
            {
                "question": "Find the highest number of boys that can exactly share 12 or 18 mangoes leaving no remainder",
                "type": "multipleChoice",
                "answer": "6 boys",
                "explanation": "3k + 2p<br> (3x2) + (2x-3)<br> = 6 + -6<br> = 0",
                "options": [
                    "4 boys",
                    "8 boys",
                    "6 boys"
                ],
                "mark": 2,
                "id": "ple_math_sect_A15_8"
            },
            {
                "question": "Write 2605 in standard form.",
                "type": "multipleChoice",
                "answer": "2.605 x 10<sup>3</sup>",
                "options": [
                    "2.605 x 10<sup>2</sup>",
                    "26.0 x 10<sup>3</sup>",
                    "2.605 x 10<sup>3</sup>"
                ],
                "mark": 2,
                "id": "ple_math_sect_A15_9"
            }
        ],
        "$id": "document_14"
    },
    {
        "category": 16,
        "questions": [
            {
                "question": "Study the coordinate graph below and use it to answer the questions that follow:",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/Symbol%207.png",
                "sub_questions": [
                    {
                        "question": "Write the coordinates of point G",
                        "type": "multipleChoice",
                        "answer": "(3,2)",
                        "options": [
                            "(2,3)",
                            "(3,-2)",
                            "(3,2)"
                        ],
                        "mark": 1
                    },
                    {
                        "question": "Select the image that shows point N(-3,0) on the coordinate graph",
                        "type": "multipleChoice",
                        "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/Symbol%208.png",
                        "options": [
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/16.png",
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/Symbol%208.png"
                        ],
                        "mark": 1
                    }
                ],
                "id": "ple_math_sect_A16_1"
            },
            {
                "question": "Given that 1 US dollar ($) costs Uganda Shillings (Ug.sh) 3,672 and 1 Kenya shilling (Ksh) costs Ug.sh 36, find the cost of 1 US dollar in Kenya shillings.",
                "type": "multipleChoice",
                "answer": "1 US dollar = 102 Kenya shillings",
                "explanation": "1 US dollar ($) = 3,672 Ug.sh<br> 1 (Ksh) = 36 (Ug.sh)<br> Cost of 1 US dollar in Ksh = (Cost of 1 US dollar in Ug.sh)/(Exchange rate for Ksh to Ug.sh)<br> = 3672/36<br> = 102",
                "options": [
                    "1 US dollar = 36 Kenya shillings",
                    "1 US dollar = 132,192 Kenya shillings",
                    "1 US dollar = 102 Kenya shillings"
                ],
                "mark": 2,
                "id": "ple_math_sect_A16_2"
            },
            {
                "question": "Given that a = 3 and b= -2, find the value of a<sup>2</sup> - b<sup>3</sup>",
                "type": "multipleChoice",
                "answer": "17",
                "explanation": "3<sup>2</sup> - (-2)<sup>2</sup><br> 9 - (-8)<br> 9 + 8<br> = 17",
                "options": [
                    "1",
                    "17",
                    "5"
                ],
                "mark": 2,
                "id": "ple_math_sect_A16_3"
            },
            {
                "question": "Apio bought 30 books at sh 3,000 per dozen. How much money did she spend?",
                "type": "multipleChoice",
                "answer": "sh 7,500",
                "explanation": "We need to first find the number of dozens Apio bought<br> Since a dozen contains 12 books<br> Number of dozens = 30/12<br> = 15/6<br> = 5/2 dozens<br> Amount spent = Number of dozens x Cost of dozen<br> = 5/2 x 3000<br> = 5 x 1500<br> = 7500",
                "options": [
                    "sh 90,000",
                    "sh 7,500",
                    "sh 5,000"
                ],
                "mark": 2,
                "id": "ple_math_sect_A16_4"
            },
            {
                "question": "The venn diagram below shows the prime factors of 12 and 18. Find the Lowest Common Multiple of 12 and 18.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2015/16.png",
                "type": "multipleChoice",
                "answer": "LCM = 36",
                "explanation": "LCM = 2x2x3x3<br> = 4x3x3<br> = 12x3<br> = 36",
                "options": [
                    "LCM = 6",
                    "LCM = 18",
                    "LCM = 36"
                ],
                "mark": 2,
                "id": "ple_math_sect_A16_5"
            },
            {
                "question": "Find the bearing of point Q from point P in the diagram below.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2014/16.png",
                "type": "multipleChoice",
                "answer": "Bearing = 245<sup>o</sup>",
                "explanation": "180 + (90 - 25)<br> = 180 + 65<br> = 245",
                "options": [
                    "Bearing = 75<sup>o</sup>",
                    "Bearing = 25<sup>o</sup>",
                    "Bearing = 245<sup>o</sup>"
                ],
                "mark": 2,
                "id": "ple_math_sect_A16_6"
            },
            {
                "question": "Given that a = 1/3 and b = 1/9. Find the value of a/b",
                "type": "multipleChoice",
                "answer": "3",
                "explanation": "a/b = a ÷ b<br> = 1/3 ÷ 1/9<br> = 1/3 x 9/1<br> = 9/3<br> = 3",
                "options": [
                    "1/3",
                    "3",
                    "1/2"
                ],
                "mark": 2,
                "id": "ple_math_sect_A16_7"
            },
            {
                "question": "A gatekeeper's salary was increased from sh 50,000 to sh 60,000. Find the percentage increase",
                "type": "multipleChoice",
                "answer": "Percentage increase = 20%",
                "explanation": "Increase =  New salary - Old salary<br> = 60000 - 50000<br> = 10000<br> Percentage increase = Increase/Old salary x 100<br> = 10000/50000 x 100<br> = 20%",
                "options": [
                    "Percentage increase = 30%",
                    "Percentage increase = 40%",
                    "Percentage increase = 20%"
                ],
                "mark": 2,
                "id": "ple_math_sect_A16_8"
            },
            {
                "question": "In the figure below, find the value of r in degrees",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/paper_1/16.png",
                "type": "multipleChoice",
                "answer": "r = 55°",
                "explanation": "x + 50° = 180° (co-interior angles)<br> x + 50° - 50° = 180° - 50°<br> x = 130°<br> 2r + 130° + 120° = 360° (Angle at a point)<br> 2r + 250° = 360°<br> 2r + 250° - 250° = 360° - 250°<br> 2r = 110<br> 2r/2 = 110/2<br> r = 55°",
                "options": [
                    "r = 65°",
                    "r = 60°",
                    "r = 55°"
                ],
                "mark": 2,
                "id": "ple_math_sect_A16_9"
            },
            {
                "question": "The price of a shirt is sh. 45,000. After a discount, a customer paid sh.39,000. What was the discount?",
                "type": "multipleChoice",
                "answer": "Discount = 6,000",
                "explanation": "Discount = Old price - New price<br> = 45,000 - 39,000<br> = 6,000",
                "options": [
                    "Discount = 5,000",
                    "Discount = 6,000",
                    "Discount = 7,000"
                ],
                "mark": 2,
                "id": "ple_math_sect_A16_10"
            }
        ],
        "$id": "document_15"
    },
    {
        "category": 17,
        "questions": [
            {
                "question": "A train left station K at 11 38 hours and reached station M at 14 27 hours. How long did the train take to travel from K to M?",
                "type": "multipleChoice",
                "answer": "2 hours and 49 minutes",
                "explanation": "Time taken = Arrival time - Departure time<br> = 14:27 - 11:38<br> Subtract the minutes first<br> 27 - 38 = -11 (carry over 1)<br> Now, subtract the hours<br> (14 - 1) - 11 = 2",
                "options": [
                    "2 hours and 11 minutes",
                    "1 hour and 49 minutes",
                    "2 hours and 49 minutes"
                ],
                "mark": 2,
                "id": "ple_math_sect_A17_1"
            },
            {
                "question": "Find the value of p in degrees in the diagram below.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2018/17.png",
                "type": "multipleChoice",
                "answer": "p = 30",
                "explanation": "Exterior angles of a triangle add up to 360<sup>o</sup><br> 5p + 3p + 4p = 360<br> 12p = 360<br> p = 360/12<br> = 30",
                "options": [
                    "p = 15",
                    "p = 30",
                    "p = 180"
                ],
                "mark": 2,
                "id": "ple_math_sect_A17_2"
            },
            {
                "question": "Sixty six poles are fixed in a straight line along one side of a road. The poles are fixed at intervals of 10 meters. Calculate the length of the road.",
                "type": "multipleChoice",
                "answer": "Length = 660 meters",
                "explanation": "Total Length = Distance between poles * Number of poles<br> = 10 m x 66<br> = 660 m",
                "options": [
                    "Length = 76 meters",
                    "Length = 660 meters",
                    "Length = 650 meters"
                ],
                "mark": 2,
                "id": "ple_math_sect_A17_3"
            },
            {
                "question": "A motorist travels 64 km in 40 minutes. Find the speed of the motorist in kilometers per hour.",
                "type": "multipleChoice",
                "answer": "Speed = 96 km/hr",
                "explanation": "Since the time is in minutes we need to convert it to hours<br> 1 minute = 1/60 hours<br> 40 minutes = (1/60 x 40) hours<br> = 4/6<br> = 2/3<br> Speed = Distance/Time<br> = 64/(2/3)<br> = 64 ÷ 2/3<br> = 64 x 3/2 = 96",
                "options": [
                    "Speed = 2560 km/hr",
                    "Speed = 90 km/hr",
                    "Speed = 96 km/hr"
                ],
                "mark": 2,
                "id": "ple_math_sect_A17_4"
            },
            {
                "question": "Find the median of the numbers 8, 10 ,4 ,1 ,6 and 9",
                "type": "multipleChoice",
                "answer": "Median = 7",
                "explanation": "Arrange the number in ascending or descending order<br> 1, 4, 6, 8, 9, 10<br> Median = (6+8)/2<br> = 7",
                "options": [
                    "Median = 8",
                    "Median = 6.33",
                    "Median = 7"
                ],
                "mark": 2,
                "id": "ple_math_sect_A17_5"
            },
            {
                "question": "Solve 2<sup>3n</sup> ÷ 2<sup>n</sup> = 2<sup>4</sup>",
                "type": "multipleChoice",
                "answer": "n = 2",
                "explanation": "2<sup>(3n-n)</sup> = 2<sup>4</sup><br> 3n - n = 4<br> 2n = 4<br> n = 2",
                "options": [
                    "n = 4",
                    "n = 1",
                    "n = 2"
                ],
                "mark": 2,
                "id": "ple_math_sect_A17_6"
            },
            {
                "question": "The bar graph below shows the number of eggs laid by chicken in Opio's farm from Monday to Friday. How many eggs were laid on Wednesday and Friday",
                "type": "multipleChoice",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2013/17.png",
                "answer": "28 eggs were laid on Wednesday and 42 eggs were laid on Friday",
                "options": [
                    "30 eggs were laid on Wednesday and 45 eggs were laid on Friday",
                    "28 eggs were laid on Wednesday and 42 eggs were laid on Friday",
                    "42 eggs were laid on Wednesday and 28 eggs were laid on Friday"
                ],
                "mark": 2,
                "id": "ple_math_sect_A17_7"
            },
            {
                "question": "The table below shows the goals scored by some teams in a netball competition. How many teams scored less than 20 goals?",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2012/17.png",
                "type": "multipleChoice",
                "answer": "12 teams",
                "explanation": "Less than 20 goals =  3 + 4 + 5<br> = 12 teams",
                "options": [
                    "13 teams",
                    "12 teams",
                    "16 teams"
                ],
                "mark": 2,
                "id": "ple_math_sect_A17_8"
            },
            {
                "question": "A parent went to the bank and paid school fees. If she paid 30 notes of fifty thousand shillings each numbered consecutively from ZA7359981, find the last note.",
                "type": "multipleChoice",
                "answer": "ZA73600010",
                "explanation": "Notes = 30 - 1<br> = 29<br> Last note = ZA73599981 + 29<br> = ZA73600010",
                "options": [
                    "ZA73600000",
                    "ZA73600010",
                    "ZA7360000"
                ],
                "mark": 2,
                "id": "ple_math_sect_A17_9"
            },
            {
                "question": "The interior angle sum of a regular polygon is 540°. How many sides has the polygon?",
                "type": "multipleChoice",
                "answer": "5 sides",
                "explanation": "180°(n - 2) = (Interior angle sum)<br> 180°(n - 2) = 540°<br> 180n - 360 = 540<br> 180n - 360 + 360 = 540 + 360<br> 180n = 900<br> n = 5",
                "options": [
                    "5 sides",
                    "6 sides",
                    "7 sides"
                ],
                "mark": 2,
                "id": "ple_math_sect_A17_10"
            }
        ],
        "$id": "document_16"
    },
    {
        "category": 18,
        "questions": [
            {
                "question": "Find the solution set for k + 2 < 6.",
                "type": "multipleChoice",
                "answer": "k < 4",
                "explanation": "k + 2 < 6<br> k + 2 -2 < 6 -2<br> k < 6 -2<br> k < 4",
                "options": [
                    "k < 8",
                    "k < 4",
                    "k = 4"
                ],
                "mark": 2,
                "id": "ple_math_sect_A18_1"
            },
            {
                "question": "The taxi fare from Kampala to Mukono was raised by 16<sup>2</sup>/<sub>3</sub>% The old farewash 3,000. Find the new taxi fare",
                "type": "multipleChoice",
                "answer": "New Fare = 3,500",
                "explanation": "New Fare = Old Fare + (Percentage increase/100 x Old fare)<br> Percentage increase = 16<sup>2</sup>/<sub>3</sub>/100<br> = 50/3/100<br> = 1/6<br> = 3000 + (1/6 x 3000)<br> = 3000 + 500<br> = 3500",
                "options": [
                    "New Fare = 4,000",
                    "New Fare = 3,800",
                    "New Fare = 3,500"
                ],
                "mark": 2,
                "id": "ple_math_sect_A18_2"
            },
            {
                "question": "A house can be built by 3 men in 20 days. How many men working at the same rate can build the same house in 12 days?",
                "type": "multipleChoice",
                "answer": "5 men",
                "explanation": "3 men = 20 days<br> Total man days = (3 x 20)days<br> = 60 days<br> Total man days = Number of men x Number of days<br> 60 = Number of men x 12<br> Number of men = 60/12<br> = 5 men",
                "options": [
                    "5 men",
                    "6 men",
                    "4 men"
                ],
                "mark": 2,
                "id": "ple_math_sect_A18_3"
            },
            {
                "question": "The area of the shaded part of the cuboid below is 12 cm<sup>2</sup>. Calculate the volume of the cuboid.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/18.png",
                "type": "multipleChoice",
                "answer": "Volume = 84cm³",
                "explanation": "Base area = 12cm²<br> Volume = Base area x height<br> = 12 x 7<br> = 84cm³",
                "options": [
                    "Volume = 80cm³",
                    "Volume = 84cm³",
                    "Volume = 36cm³"
                ],
                "mark": 2,
                "id": "ple_math_sect_A18_4"
            },
            {
                "question": "Gidudu has goats and sheep in the ratio of 3:2. If he has 24 goats, how many sheep does he have?",
                "type": "multipleChoice",
                "answer": "16 sheep",
                "explanation": "Let the total number of animals be x<br> Total parts = 3+2 = 5<br> 3x/5 = 24<br> Multiply both sides by 5<br> 3x = 24x5<br> x = (24x5)/3<br> x = 40 animals<br> Number of sheep = Total number of animals - Number of goats<br> = 40 - 24<br> = 16 sheep",
                "options": [
                    "16 sheep",
                    "10 sheep",
                    "24 sheep"
                ],
                "mark": 2,
                "id": "ple_math_sect_A18_5"
            },
            {
                "question": "The time on the 12-hour clock is : a quarter to 4 O'clock in the afternoon. Express this time in the 24-hour clock.",
                "type": "multipleChoice",
                "answer": "15:45 hours",
                "options": [
                    "15:00 hours",
                    "15:45 hours",
                    "15:15 hours"
                ],
                "mark": 2,
                "id": "ple_math_sect_A18_6"
            },
            {
                "question": "The Lowest Common Multiple (LCM) of two numbers is 72 and their Greatest Common Factor (GCF) is 6. If one of the numbers is 24, find the second number.",
                "type": "multipleChoice",
                "answer": "First number = 18",
                "explanation": "First number = (LCM x GCF)/Given number<br> = (72 x 6)/24<br> = 18",
                "options": [
                    "First number = 18",
                    "First number = 16",
                    "First number = 20"
                ],
                "mark": 2,
                "id": "ple_math_sect_A18_7"
            },
            {
                "question": "Find the square root of 3<sup>1</sup>/<sup>16</sup>",
                "type": "multipleChoice",
                "answer": "Square root = 1¾",
                "explanation": "Change to improper fraction<br> = 49/16<br> = √(49/16)<br> = √49/√16<br> = 7/4<br> = 1¾",
                "options": [
                    "Square root = 1½",
                    "Square root = 3/4",
                    "Square root = 1¾"
                ],
                "mark": 2,
                "id": "ple_math_sect_A18_8"
            },
            {
                "question": "In the Venn diagram below, describe the unshaded part.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/paper_1/18.png",
                "type": "multipleChoice",
                "answer": "(A∩B)<sup|</up>",
                "options": [
                    "(AuB)<sup|</up>",
                    "(A∩B)<sup|</up>",
                    "(A<sup|</up>∩B)"
                ],
                "mark": 2,
                "id": "ple_math_sect_A18_9"
            },
            {
                "question": "A dice is tossed once. What is the probability that a composite number appears on top?",
                "type": "multipleChoice",
                "answer": "Probability = 1/3",
                "explanation": "Sample space = {1,2,3,4,5,6}<br> n(Sample space) = 6<br> Events = {4,6}<br> n(Events) = 2<br> Probability = n(Events)/n(Sample space)<br> = 2/6<br> = 1/3",
                "options": [
                    "Probability = 2/6",
                    "Probability = 1/2",
                    "Probability = 1/3"
                ],
                "mark": 2,
                "id": "ple_math_sect_A18_10"
            }
        ],
        "$id": "document_17"
    },
    {
        "category": 19,
        "questions": [
            {
                "question": "A shopkeeper bought 19 plates at sh 34,200. At what price must the shopkeeper sell each plate in order to raise a profit of sh 3,800?",
                "type": "multipleChoice",
                "answer": "2,000",
                "explanation": "Selling Price per Plate = (Total Cost + Profit)/ Number of plates<br> = (34200+3800)/19<br> = 38000/19<br> = 2000",
                "options": [
                    "1,500",
                    "2,000",
                    "2,500"
                ],
                "mark": 2,
                "id": "ple_math_sect_A19_1"
            },
            {
                "question": "Solve the inequality: 3 - 2m < 15",
                "type": "multipleChoice",
                "answer": "m > -6",
                "explanation": "3 - 2m < 15<br> 3 - 3 -2m < 15 - 3<br> -2m < 12<br> Divide both sides by -2. When you divide by a negative number, the inequality sign changes<br> -2m/-2 > 12/-2<br> m > -6",
                "options": [
                    "m < -6",
                    "m > -6",
                    "m > 6"
                ],
                "mark": 2,
                "id": "ple_math_sect_A19_2"
            },
            {
                "question": "The graph below shows the number of pupils present in a class of 40 pupils in a certain week. Find the number of pupils who were absent on Tuesday.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2017/19.png",
                "type": "multipleChoice",
                "answer": "15 pupils",
                "explanation": "Number of pupils absent = Total number of pupils - Number of pupils present<br> = 40 - 25<br> = 15 pupils",
                "options": [
                    "20 pupils",
                    "10 pupils",
                    "15 pupils"
                ],
                "mark": 2,
                "id": "ple_math_sect_A19_3"
            },
            {
                "question": "Which of the following show 135<sup>o</sup> constructed accurately",
                "type": "multipleChoice",
                "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/19.png",
                "options": [
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/19_1.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/19_2.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/19.png"
                ],
                "mark": 2,
                "id": "ple_math_sect_A19_4"
            },
            {
                "question": "A bucket was ¾ full of water. When 4 litres were removed, it became ½ full of water. What is the capacity of the bucket?",
                "type": "multipleChoice",
                "answer": "Capacity = 16 litres",
                "explanation": "Fraction of water = 3/4<br> Removed fraction = 3/4 - 1/2<br> LCM = 4<br> = ((1x3)-(2x1))/4<br> = (3-2)/4<br> = 1/4<br> Full bucket = Let the full bucket capacity be k<br> 1/4 of k = 4<br> 1/4 x k = 4<br> k = 4 x 4<br> = 16 litres",
                "options": [
                    "Capacity = 20 litres",
                    "Capacity = 16 litres",
                    "Capacity = 15 litres"
                ],
                "mark": 2,
                "id": "ple_math_sect_A19_5"
            },
            {
                "question": "The pie chart below shows how a farmer uses her land. Calculate the size of her land if she uses 72 acres for cultivation",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2014/19.png",
                "type": "multipleChoice",
                "answer": "216 acres",
                "explanation": "Let the size of the size of her land be x<br> 120x/360 = 72<br> x = (72 x 360)/120<br> = 72 x 3<br> = 216",
                "options": [
                    "206 acres",
                    "200 acres",
                    "216 acres"
                ],
                "mark": 2,
                "id": "ple_math_sect_A19_6"
            },
            {
                "question": "Trees were planted along a straight road 305 meters long. If the trees were planted 5 metres apart, how many trees were planted along the road?",
                "type": "multipleChoice",
                "answer": "62 trees",
                "explanation": "Number of trees in a straight road = ( Distance ÷ interval)+ 1<br> = (305 ÷ 5) + 1<br> = 61 + 1<br> = 62 trees",
                "options": [
                    "62 trees",
                    "61 trees",
                    "60 trees"
                ],
                "mark": 2,
                "id": "ple_math_sect_A19_7"
            },
            {
                "question": "The number of subsets in set A is 16. How many elements are in set A?",
                "type": "multipleChoice",
                "answer": "4 elements",
                "explanation": "Number of subsets = 2<sup>n</sup> (where n, is the number of elements in a set)<br> 2<sup>n</sup> = 16<br> 2<sup>n</sup> = 2<sup>4</sup><br> n = 4",
                "options": [
                    "8 elements",
                    "16 elements",
                    "4 elements"
                ],
                "mark": 2,
                "id": "ple_math_sect_A19_8"
            },
            {
                "question": "A trader borrowed sh.2,000,000 from a money lender who charges an interest of 20% every after 3 months. How much interest did the trader pay at the end of 3 months?",
                "type": "multipleChoice",
                "answer": "sh.400,000",
                "explanation": "Time = 3/3<br> = 1 interval of 3 months<br> Simple interest = PxRxT<br> = 2,000,000 x 20/100 x 1<br> = 20,000 x 2<br> = 400,000",
                "options": [
                    "sh.500,000",
                    "sh.400,000",
                    "sh.450,000"
                ],
                "mark": 2,
                "id": "ple_math_sect_A19_9"
            },
            {
                "question": "Tom was facing North East. If he turned clockwise through an angle of 225°, what is his new direction?",
                "type": "multipleChoice",
                "answer": "West",
                "explanation": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/paper_2/19.png",
                "options": [
                    "West",
                    "East",
                    "South"
                ],
                "mark": 2,
                "id": "ple_math_sect_A19_10"
            }
        ],
        "$id": "document_18"
    },
    {
        "category": 20,
        "questions": [
            {
                "question": "Mawa built a circular hut of circumference 66 metres using poles. The poles were fixed at intervals of 1.5 metres. Calculate the number of poles he used.",
                "type": "multipleChoice",
                "answer": "44 poles",
                "explanation": "Circumference = 2πr<br> 66 = 2πr<br> r = 66/2π<br> Number of poles = C/Interval<br> = 66/1.5<br> = 44 poles",
                "options": [
                    "40 poles",
                    "30 poles",
                    "44 poles"
                ],
                "mark": 2,
                "id": "ple_math_sect_A20_1"
            },
            {
                "question": "Bottles of 300 millilitres (ml) were used to fill a nine litre bucket with water. Find the number of full 300ml bottles that were used.",
                "type": "multipleChoice",
                "answer": "30 bottles",
                "explanation": "1 litre = 1000ml<br> So a 9 litre bucket = (9 x 1000)ml<br> = 9000ml<br> Number of bottles = (Total volume)/(Volume of one bottle)<br> = 9000ml/300ml<br> = 30",
                "options": [
                    "30 bottles",
                    "20 bottles",
                    "40 bottles"
                ],
                "mark": 2,
                "id": "ple_math_sect_A20_2"
            },
            {
                "question": "Find the least number of sweets when divided among 8 boys or 6 girls equally, leaves 2 sweets as the remainder.",
                "type": "multipleChoice",
                "answer": "26 sweets",
                "explanation": "Let's first find the LCM of 8 and 6<br> Prime factorization of 8: 2 x 2 x 2<br> Prime factorization of 6: 2 x 3<br> LCM = 2 x 2 x 2 x 3<br> = 24<br> Least number of sweets that leaves a remainder of 2 = 24 + 6<br> = 26 sweets",
                "options": [
                    "24 sweets",
                    "26 sweets",
                    "22 sweets"
                ],
                "mark": 2,
                "id": "ple_math_sect_A20_3"
            },
            {
                "question": "Hakim is 3 times as old as Lucky. Their total age is 52 years. How old is Lucky?",
                "type": "multipleChoice",
                "answer": "13 years old",
                "explanation": "Let Hakim's age be H and Lucky's age be L<br> ∴ H = 3L<br> Since H + L = 52<br> 3L + L = 52<br> 4L = 52<br> 4L/4 = 52/4<br> L = 13",
                "options": [
                    "13 years old",
                    "12 years old",
                    "39 years old"
                ],
                "mark": 2,
                "id": "ple_math_sect_A20_4"
            },
            {
                "question": "In a poultry farm, eggs are packed into boxes which hold 144 eggs. How many boxes of the same size are needed to pack 1,008 eggs?",
                "type": "multipleChoice",
                "answer": "7 boxes",
                "explanation": "Number of boxes = Total number of eggs/Number of eggs per box<br> = 1008/144<br> = 7 boxes",
                "options": [
                    "7 boxes",
                    "5 boxes",
                    "6 boxes"
                ],
                "mark": 2,
                "id": "ple_math_sect_A20_5"
            },
            {
                "question": "A man got a loan of shs 120,000 from a savings and credit co-operative society at a simple interest rate of 8% per annunm. He paid an interest of sh7,200 on the loan. How long was the loan?",
                "type": "multipleChoice",
                "answer": "9 months",
                "explanation": "Interest = Principal x Rate x Time<br> 7200 = 120000 x 8/100 x T<br> 7200 = 120000 x 0.08T<br> 7200 = 9600T<br> T = 7200/9600<br> = 3/4<br> Convert to months<br> = 3/4 x 12<br> = 9",
                "options": [
                    "90 months",
                    "9 months",
                    "9 years"
                ],
                "mark": 2,
                "id": "ple_math_sect_A20_6"
            },
            {
                "question": "Medi has 30 kg to be parked in ¾ kg packets. How many packets will he get?",
                "type": "multipleChoice",
                "answer": "40 packets",
                "explanation": "The number of packets = 30 ÷ 3/4<br> = 30 x 4/3<br> = 120/3<br> = 40",
                "options": [
                    "30 packets",
                    "40 packets",
                    "50 packets"
                ],
                "mark": 2,
                "id": "ple_math_sect_A20_7"
            },
            {
                "question": "A bus covered a distance of 280 km in 3 hours and 30 minutes. What was its average speed?",
                "type": "multipleChoice",
                "answer": "Speed = 80 km/hr",
                "explanation": "Speed = Distance/Time<br> = 280/3½<br> = 280/7/2<br> = 280 ÷ 7/2<br> 280 x 2/7<br> = 80 km/hr",
                "options": [
                    "Speed = 70 km/hr",
                    "Speed = 60 km/hr",
                    "Speed = 80 km/hr"
                ],
                "mark": 2,
                "id": "ple_math_sect_A20_8"
            },
            {
                "question": "Express 00 40 hrs in 12 hour clock system.",
                "type": "multipleChoice",
                "answer": "12:40 a.m",
                "options": [
                    "12:40 a.m",
                    "12:40 p.m",
                    "6:40 a.m"
                ],
                "mark": 2,
                "id": "ple_math_sect_A20_9"
            },
            {
                "question": "A car travelled at a speed of 80km/hr for 2½ hours. What distance did the car cover?",
                "type": "multipleChoice",
                "answer": "Distance = 200 km",
                "explanation": "Distance = speed x time<br> = 80 x 5/2<br> = 40 x 5<br> = 200 km",
                "options": [
                    "Distance = 100 km",
                    "Distance = 200 km",
                    "Distance = 80 km"
                ],
                "mark": 2,
                "id": "ple_math_sect_A20_10"
            }
        ],
        "$id": "document_19"
    },
    {
        "category": 3,
        "questions": [
            {
                "question": "Simplify: 3p+p-2p",
                "type": "multipleChoice",
                "answer": "2p",
                "explanation": "3p+p-2p<br> =4p-2p<br> =2p",
                "options": [
                    "4p",
                    "2p",
                    "p"
                ],
                "mark": 2,
                "id": "ple_math_sect_A3_1"
            },
            {
                "question": "Given that P = {a, b, c, d, e, f, g} and Q = {b, a, f, e, h} find n(P u Q).",
                "type": "multipleChoice",
                "answer": "n(P u Q) = 8",
                "explanation": "First we get (P u Q)<br> (P u Q) = {a,b,c,d,e,f,g,h}<br> ∴ n(P u Q) = 8",
                "options": [
                    "n(P u Q) = 4",
                    "n(P u Q) = 7",
                    "n(P u Q) = 8"
                ],
                "mark": 2,
                "id": "ple_math_sect_A3_2"
            },
            {
                "question": "Work out: 2 - 5 (finite 7)",
                "type": "multipleChoice",
                "answer": "-33",
                "explanation": "2 - 5 x (7)<br> 2 - 35<br> = -33",
                "options": [
                    "-33",
                    "-3",
                    "-21"
                ],
                "mark": 2,
                "id": "ple_math_sect_A3_3"
            },
            {
                "question": "Work out 5/9 ÷ 2/3",
                "type": "multipleChoice",
                "answer": "5/6",
                "explanation": "5/9 x 3/2<br> = 5/3 x 1/2<br> = 5/6",
                "options": [
                    "5/6",
                    "1",
                    "5/9"
                ],
                "mark": 2,
                "id": "ple_math_sect_A3_4"
            },
            {
                "question": "Simplify: 18x - 5(3x + 7)",
                "type": "multipleChoice",
                "answer": "3x - 35",
                "explanation": "Using BODMAS<br> 18x - 15x - 35<br> 3x - 35",
                "options": [
                    "3x + 35",
                    "3x - 35",
                    "3x + 12"
                ],
                "mark": 2,
                "id": "ple_math_sect_A3_5"
            },
            {
                "question": "Given that k = {1,2,3,4,5} and M = {2,4,6,8}, find n(K u M)",
                "type": "multipleChoice",
                "answer": "n(K u M) = 7",
                "explanation": "First we get (K u M)<br> (K u M) = {1,2,3,4,5,6,8}<br> ∴ n(P u Q) = 7",
                "options": [
                    "n(K u M) = 8",
                    "n(K u M) = 7",
                    "n(K u M) = 6"
                ],
                "mark": 2,
                "id": "ple_math_sect_A3_6"
            },
            {
                "question": "Write XCIX in Hindu-Arabic numerals",
                "type": "multipleChoice",
                "answer": "99",
                "explanation": "XCIX = XC + IX<br> = 90 + 9<br> = 99",
                "options": [
                    "99",
                    "90",
                    "95"
                ],
                "mark": 2,
                "id": "ple_math_sect_A3_7"
            },
            {
                "question": "Simplify:  <sup>-</sup>6 - <sup>-</sup>4",
                "type": "multipleChoice",
                "answer": "<sup>-</sup>2",
                "explanation": "<sup>-</sup>6 - (<sup>-</sup>4)<br> = <sup>-</sup>6 + 4<br> = <sup>-</sup>2",
                "options": [
                    "<sup>-</sup>2",
                    "2",
                    "<sup>-</sup>3"
                ],
                "mark": 2,
                "id": "ple_math_sect_A3_8"
            },
            {
                "question": "Work out: 6÷5 = (finite 7)",
                "type": "multipleChoice",
                "answer": "4 (finite 7)",
                "explanation": "(6 + 7) ÷ 5 = (finite 7)<br> (13 + 7) ÷ 5 = (finite 7)<br> 20 ÷ 5 = 4 (finite 7)",
                "options": [
                    "1 (finite 7)",
                    "4 (finite 7)",
                    "3 (finite 7)"
                ],
                "mark": 2,
                "id": "ple_math_sect_A3_9"
            }
        ],
        "$id": "document_2"
    },
    {
        "category": 21,
        "questions": [
            {
                "question": "The diagrams below represent the subtraction of two numbers on an abacus. Study the diagrams and use them to answer the questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/Symbol 10.png",
                "sub_questions": [
                    {
                        "question": "Write down the numbers represented in the subtraction",
                        "type": "multipleChoice",
                        "answer": "304 - 43",
                        "options": [
                            "314 - 43",
                            "304 - 23",
                            "304 - 43"
                        ],
                        "mark": 2,
                        "id": "ple_math_sect_B21_1_sub_0"
                    },
                    {
                        "question": "Which diagram represents your answer above on the third abacus?",
                        "type": "multipleChoice",
                        "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/21.png",
                        "options": [
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/21A.png",
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/21.png",
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/21B.png"
                        ],
                        "mark": 3,
                        "id": "ple_math_sect_B21_1_sub_1"
                    }
                ],
                "id": "ple_math_sect_B21_1"
            },
            {
                "question": "At a party, guests were served with soda (S) and mineral water (W) as shown in the Venn diagram below. Study and use the Venn diagram to answer the questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2018/21.png",
                "sub_questions": [
                    {
                        "question": "If 32 guests were served with soda, find the value of r.",
                        "type": "multipleChoice",
                        "answer": "r = 4",
                        "explanation": "3r + 7 + 13 = 32<br> 3r + 20 = 32<br> 3r + 20 - 20 = 32 - 20<br> 3r = 12<br> 3r/3 = 12/3<br> r = 4",
                        "options": [
                            "r = 3",
                            "r = 4",
                            "r = 5"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "Find the total number of guests who attended the party.",
                        "type": "multipleChoice",
                        "answer": "50 guests",
                        "explanation": "3r + 7 + 13 + r + 6 + 2r<br> Since r = 4<br> (3x4) + 20 + 4 + 6 + (2x4)<br> 12 + 30 + 8<br> = 50",
                        "options": [
                            "48 guests",
                            "42 guests",
                            "50 guests"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "Find the probability that a guest picked at random did not take any drink.",
                        "type": "multipleChoice",
                        "answer": "Probability  = 4/25",
                        "explanation": "Probability = (Guests who did not take any drink)/(Guests who attended the party)<br> = (2r)/50<br> = (2x4)/50<br> = 8/50<br> = 4/25",
                        "options": [
                            "4/25",
                            "13/50",
                            "1/8"
                        ],
                        "mark": 1
                    }
                ],
                "id": "ple_math_sect_B21_2"
            },
            {
                "question": "Work out: 334<sub>five</sub> + 123<sub>five</sub>",
                "type": "multipleChoice",
                "answer": "1011<sub>five</sub>",
                "options": [
                    "1111<sub>five</sub>",
                    "10011<sub>five</sub>",
                    "1011<sub>five</sub>"
                ],
                "mark": 2,
                "sub_questions": [
                    {
                        "question": "Given that 34<sub>t</sub> = 112<sub>four</sub>, find the value of t",
                        "type": "multipleChoice",
                        "answer": "t = 6",
                        "explanation": "34t<sub>m</sub> = 112<sub>four</sub><br> 3t¹ + 4t⁰ = 1x4² + 1x4¹ + 2x4⁰<br> (3 x t) + (4 x 1) = (1x4x4) +(1 x 4) + (2x1)<br> 3t + 4 = 16 + 4 + 2<br> 3t + 4 = 22<br> 3t + 4 - 4 = 22 - 4<br> 3t/3 = 18/3<br> t = 6",
                        "options": [
                            "t = 5",
                            "t = 4",
                            "t = 6"
                        ],
                        "mark": 3
                    }
                ],
                "id": "ple_math_sect_B21_3"
            },
            {
                "question": "In a class of 41 pupils, 30 play football (F), t play Netball (N) and 5 play both football and Netball. 3 pupils do not play any of the two games.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/21.png",
                "sub_questions": [
                    {
                        "question": "Which of the following shows the complete venn diagram",
                        "type": "multipleChoice",
                        "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/21C.png",
                        "options": [
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/21C.png",
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/21W.png",
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/21W1.png"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "Find the value t",
                        "type": "multipleChoice",
                        "answer": "t = 13",
                        "explanation": "25 + 5 + t - 5 + 3 = 41<br> 25 + 5 - 5 + 3 + t = 41<br> 28 + t = 41<br> 28 - 28 + t = 41 - 28<br> t = 13",
                        "options": [
                            "t = 12",
                            "t = 10",
                            "t = 13"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B21_4"
            },
            {
                "question": "In a class, 31 pupils play tennis (T) and (d+5) play volley ball (V) only. d pupils play both games while 3 play neither of the games.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2015/21.png",
                "sub_questions": [
                    {
                        "question": "Which of the following show the correct complete venn diagram",
                        "type": "multipleChoice",
                        "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2015/21C.png",
                        "options": [
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2015/21W.png",
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2015/21C.png",
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2015/21W1.png"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "If 27 pupils play volleyball altogether, find the value of d",
                        "type": "multipleChoice",
                        "answer": "d = 11",
                        "explanation": "d + d + 5 = 27<br> 2d + 5 = 27<br> 2d + 5 - 5 = 27 - 5<br> 2d = 22<br> d = 11",
                        "options": [
                            "d = 12",
                            "d = 14",
                            "d = 15"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B21_5"
            },
            {
                "question": "Which of the following represent 4,502 on the abacus",
                "type": "multipleChoice",
                "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2014/21_2.png",
                "options": [
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2014/21_1.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2014/21.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2014/21_2.png"
                ],
                "mark": 1,
                "sub_questions": [
                    {
                        "question": "Find the sum of the values of 3 and 7 in the numbers 3,678",
                        "type": "multipleChoice",
                        "answer": "3070",
                        "explanation": "3000 + 70<br> = 3070",
                        "options": [
                            "37",
                            "3070",
                            "73"
                        ],
                        "mark": 3
                    }
                ],
                "id": "ple_math_sect_B21_6"
            },
            {
                "question": "Musamali bought the items in the table below from a shop",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2013/21.png",
                "sub_questions": [
                    {
                        "question": "Which of the following tables show the complete and correct table",
                        "type": "multipleChoice",
                        "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2013/21C.png",
                        "explanation": "Soap = 6600/2200 = 3 bars<br> Bread = 3400/2 = 1,700<br> Salt = (5 x 800)/2<br> = 5 x 400<br> = 2,000<br> Total = 6600 + 3400 + 2000<br> = 12,000",
                        "options": [
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2013/21C.png",
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2013/21W.png",
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2013/21W1.png"
                        ],
                        "mark": 4
                    },
                    {
                        "question": "If Musamali paid Sh 10,800, what percentage discount was he given?",
                        "type": "multipleChoice",
                        "answer": "Discount = 10%",
                        "explanation": "Discount = old price - new price<br> = 12,000 - 10,800<br> = 1,200<br> Percentage discount = (Discount/Total expenditure) x 100<br> = 1,200/12,000 x 100<br> = 1/10 x 100<br> = 10%",
                        "options": [
                            "Discount = 9%",
                            "Discount = 19%",
                            "Discount = 10%"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B21_7"
            },
            {
                "question": "In a class of 60 pupils, 30 like English (E), y like mathematics (M) only, 10 like both subjects and 5 do not like any of the two subjects.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2012/21.png",
                "sub_questions": [
                    {
                        "question": "Which of the following diagrams represent a complete venn diagram",
                        "type": "multipleChoice",
                        "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2012/21C.png",
                        "options": [
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2012/21C.png",
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2012/21_1.png",
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2012/21_2.png"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "Find the valve of y",
                        "type": "multipleChoice",
                        "answer": "y = 25",
                        "explanation": "y + 10 + 20 + 5 = 60<br> y + 35 = 60<br> y + 35 - 35 = 60 - 35<br> y = 25",
                        "options": [
                            "y = 25",
                            "y = 15",
                            "y = 20"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "How many pupils like Mathematics altogether?",
                        "type": "multipleChoice",
                        "answer": "35 pupils",
                        "explanation": "Pupils that like mathematics = y + 10<br> = 25 + 10<br> = 35",
                        "options": [
                            "25 pupils",
                            "30 pupils",
                            "35 pupils"
                        ],
                        "mark": 1
                    }
                ],
                "id": "ple_math_sect_B21_8"
            },
            {
                "question": "The Venn diagram below shows the number of pupils who like meat (M) and beans (B). Use it to answer questions that follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/paper_1/21.png",
                "sub_questions": [
                    {
                        "question": "If 34 pupils like either meat or beans, find the value of y.",
                        "type": "multipleChoice",
                        "answer": "y = 9",
                        "explanation": "n(MuB) = 34<br> y + 4 + 2y + y - 2 = 34<br> y + 2y + y + 4 - 2 = 34<br> 4y - 2 = 34<br> 4y - 2 + 2 = 34 + 2<br> 4y = 36<br> y = 9",
                        "options": [
                            "y = 6",
                            "y = 8",
                            "y = 9"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "Find the probability of picking a pupil who likes beans only.",
                        "type": "multipleChoice",
                        "answer": "Probability = 7/42",
                        "explanation": "Beans only = y - 2<br> = 9 - 2<br> = 7<br> Total = y + 4 + (2xy) + y - 2 + y - 5<br> = 9 + 4 +(9 x 2) + 9 - 2 + 9 - 5<br> = 13 + 18 + 7 + 4<br> = 42<br> Probability = 7/42",
                        "options": [
                            "Probability = 8/42",
                            "Probability = 1/3",
                            "Probability = 7/42"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B21_9"
            },
            {
                "question": "The Venn diagram below shows the number of pupils who like Hockey (H) and Table tennis (T). Use it to answer questions that follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/paper_2/21.png",
                "sub_questions": [
                    {
                        "question": "If 20 pupils don't  like Table tennis, how many pupils like both games?",
                        "type": "multipleChoice",
                        "answer": "9 pupils",
                        "explanation": "3x + 5 + 2x = 20<br> 3x + 2x + 5 = 20<br> 5x + 5 - 5 = 20 - 5<br> 5x = 15<br> x = 3<br> Both games = 3 x 3<br> = 9 pupils",
                        "options": [
                            "5 pupils",
                            "7 pupils",
                            "9 pupils"
                        ],
                        "mark": 3
                    },
                    {
                        "question": "Find the probability of choosing a pupil at random who likes only one game",
                        "type": "multipleChoice",
                        "answer": "Probability = 17/32",
                        "explanation": "(3x +5) + (2x - 3)<br> (3x3) + 5 + (2x3 - 3)<br> (9 + 5) + (6 - 3)<br> = 14 + 3<br> = 17 + 9 + (2 + 3)<br> = 26 + 6<br> = 32<br> = 17/32",
                        "options": [
                            "Probability = 7/32",
                            "Probability = 15/32",
                            "Probability = 17/32"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B21_10"
            }
        ],
        "$id": "document_20"
    },
    {
        "category": 22,
        "questions": [
            {
                "question": "A class of 68 pupils was served matooke (M) and rice (R). 30 pupils ate matooke and 2h ate rice. 7 pupils ate both matooke and rice while 9 pupils did not eat either of the foods.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/22.png",
                "sub_questions": [
                    {
                        "question": "Which of the following diagrams show the complete venn diagram",
                        "type": "multipleChoice",
                        "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/22A.png",
                        "options": [
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/22B.png",
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/22A.png"
                        ],
                        "mark": 2,
                        "id": "ple_math_sect_B22_1_sub_0"
                    },
                    {
                        "question": "How many pupils ate rice only?",
                        "type": "multipleChoice",
                        "answer": "29 pupils",
                        "explanation": "First solve for h;<br> 23 + 7 + 2h - 7 + 9 = 68<br> 2h + 32 = 68<br> 2h + 32 - 32 = 68 - 32<br> 2h = 36<br> h = 36/2<br> h = 18<br> Pupils who ate only rice = 2h - 7<br> = 2 x 18 - 7<br> = 29 pupils",
                        "options": [
                            "25 pupils",
                            "29 pupils",
                            "20 pupils"
                        ],
                        "mark": 3,
                        "id": "ple_math_sect_B22_1_sub_1"
                    }
                ],
                "id": "ple_math_sect_B22_1"
            },
            {
                "question": "Express 4/15 as a recurring decimal",
                "type": "multipleChoice",
                "answer": "0.266...",
                "explanation": "Perform long division<br> You will find that you get 6 as a recurring answer",
                "options": [
                    "0.25",
                    "0.26",
                    "0.266..."
                ],
                "mark": 2,
                "sub_questions": [
                    {
                        "question": "Simplify: 4/5 x 3/7 ÷ 9/14 + 2<sup>7</sup>/<sub>15</sub>",
                        "type": "multipleChoice",
                        "answer": "3",
                        "explanation": "Change the mixed fraction to improper fraction<br> 4/5 x 3/7 ÷ 9/14 + 37/15<br> Using BODMAS<br> = 4/5 x 3/7 x 14/9 + 37/15<br> = 8/15 + 37/15<br> = 45/15<br> = 3",
                        "options": [
                            "3",
                            "4",
                            "5/7"
                        ],
                        "mark": 3
                    }
                ],
                "id": "ple_math_sect_B22_2"
            },
            {
                "question": "Akot went to the market and bought the following items:<br> 3 liters of milk at sh2,400 per liter<br> 250g of salt at sh2,000 per kg.<br> 18 oranges at sh1,500 for every 6 oranges",
                "sub_questions": [
                    {
                        "question": "Calculate the total cost of the items",
                        "type": "multipleChoice",
                        "answer": "Total cost = shs 12,200",
                        "explanation": "Cost of milk = 3 x 2400<br> =  shs 7,200<br> Cost of salt;<br> We first convert the 250 grammes to kilogrammes<br> 1 gram = (1/1000) kilogrammes<br> 250 g = (250 x 1/1000) kg<br> = 1/4 kg<br> Cost of salt = 1/4 x 2000<br> =  shs 500<br> Cost of oranges: <br> We first need to find the cost of 1 orange<br> 6 oranges = 1500<br> 1 orange = 1500/6<br> = shs 250<br> 18 oranges = 250 x 18<br> = shs 4,500<br> Total cost = 7,200 + 500 + 4,500<br> = 12,200",
                        "options": [
                            "Total cost = shs 511,700",
                            "Total cost = shs 12,200",
                            "Total cost = shs 27,000"
                        ],
                        "mark": 4
                    },
                    {
                        "question": "Akot paid sh 12,000 for the items. What discount was she given?",
                        "type": "multipleChoice",
                        "answer": "Discount = sh 200",
                        "explanation": "Discount = Total cost - Amount Paid<br> = 12,200 - 12,000<br> = 200",
                        "options": [
                            "Discount = sh 499,700",
                            "Discount = sh 200",
                            "Discount = sh 15,000"
                        ],
                        "mark": 1
                    }
                ],
                "id": "ple_math_sect_B22_3"
            },
            {
                "question": "Write 955 in Roman numerals.",
                "type": "text",
                "answer": "CMLV",
                "explanation": "CM represents 900 (1000 - 100)<br> LV represents 55 (50 + 5)",
                "mark": 1,
                "sub_questions": [
                    {
                        "question": "Find the product of the value of 2 and the value of 8 in the number 4820",
                        "type": "multipleChoice",
                        "answer": "Product = 16000",
                        "explanation": "8 hundreds = 8 x 100 = 800<br> 2 tens = 2 x 10 = 20<br> Product = 800 x 20<br> = 16000",
                        "options": [
                            "Product = 16000",
                            "Product = 16",
                            "Product = 160"
                        ],
                        "mark": 4
                    }
                ],
                "id": "ple_math_sect_B22_4"
            },
            {
                "question": "What number has been expanded below?<br> (6x10<sup>3</sup>) + (2x10<sup>1</sup>) + (7x10<sup>0</sup>) + (3x10<sup>-2</sup>).",
                "type": "multipleChoice",
                "answer": "6027.03",
                "explanation": "= 6000 + 20 + 7 + 0.03<br> = 6027.03",
                "options": [
                    "6027.03",
                    "6127.03",
                    "6027.3"
                ],
                "mark": 3,
                "sub_questions": [
                    {
                        "question": "Work out: (8.5x14) + (8.5x16)",
                        "type": "multipleChoice",
                        "answer": "255",
                        "explanation": "= 8.5(14 + 16)<br> = 8.5 x 30<br> = 255",
                        "options": [
                            "250",
                            "255.5",
                            "255"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B22_5"
            },
            {
                "question": "A birthday party attended by 76 guests, 47 were served with beef (B) and 18 were served with both beef and chicken (C). y guests were served with chicken only while (y-5) were not served with any of the two dishes.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2014/22.png",
                "sub_questions": [
                    {
                        "question": "Which of the following show a complete and correct venn diagram",
                        "type": "multipleChoice",
                        "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2014/22_3.png",
                        "options": [
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2014/22_1.png",
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2014/22_3.png",
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2014/22_2.png"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "Find the value of y",
                        "type": "multipleChoice",
                        "answer": "y = 17",
                        "explanation": "y + 18 + 29 + y - 5 = 76<br> y + y + 18 + 29 - 5 = 76<br> 2y + 42 = 76<br> 2y + 42 - 42 = 76 - 42<br> 2y = 34<br> y = 17",
                        "options": [
                            "y = 14",
                            "y = 12",
                            "y = 17"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "Find the number of guests who were served with chicken",
                        "type": "multipleChoice",
                        "answer": "35 guests",
                        "explanation": "Number of guests served with chicken;<br> = 18 + y<br> = 18 + 17<br> = 35",
                        "options": [
                            "32 guests",
                            "30 guests",
                            "35 guests"
                        ],
                        "mark": 1
                    }
                ],
                "id": "ple_math_sect_B22_6"
            },
            {
                "question": "Express 0.406 in standard form",
                "type": "multipleChoice",
                "answer": "4.06 x 10<sup>-1</sup>",
                "options": [
                    "40.6 x 10<sup>-1</sup>",
                    "4.06 x 10<sup>-1</sup>",
                    "4.06 x 10"
                ],
                "mark": 2,
                "sub_questions": [
                    {
                        "question": "Write 72 as a product of its prime factors",
                        "type": "multipleChoice",
                        "answer": "Prime factors of 72 = 2<sup>3</sup> x3<sup>2</sup>",
                        "explanation": "72 ÷ 2 = 36<br> 36 ÷ 2 = 18<br> 18 ÷ 2 = 9<br> 9 ÷ 3 = 3<br> 3 ÷ 3 = 1<br> = 2 x 2 x 2 x 3 x 3<br> = 2<sup>3</sup> x3<sup>2</sup>",
                        "options": [
                            "Prime factors of 72 = 2<sup>2</sup> x3<sup>2</sup>",
                            "Prime factors of 72 = 2<sup>3</sup> x3<sup>3</sup>",
                            "Prime factors of 72 = 2<sup>3</sup> x3<sup>2</sup>"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B22_7"
            },
            {
                "question": "Which of the following shows the accurate diagram of a triangle ABC where line AB = 6.4 cm, angle CAB = 60<sup>o</sup> and angle ABC = 75<sup>o</sup>",
                "type": "multipleChoice",
                "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2012/22.png",
                "options": [
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2012/22_1.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2012/22.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2012/22_2.png"
                ],
                "mark": 6,
                "id": "ple_math_sect_B22_8"
            },
            {
                "question": "Represent 24<sub>five</sub> on the abacus",
                "type": "multipleChoice",
                "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/paper_1/22C.png",
                "explanation": "24<sub>five</sub> ------- change to base ten<br> (2x5<sup>1</sup>) + (4x5<sup>0</sup>)<br> (2x5) + (4x1)<br> 10 + 4<br> = 14<sub>ten</sub>",
                "options": [
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/paper_1/22W.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/paper_1/22W1.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/paper_1/22C.png"
                ],
                "mark": 3,
                "sub_questions": [
                    {
                        "question": "Find the value of the unknown base marked k in 32<sub>k</sub> = 26<sub>seven</sub>",
                        "type": "multipleChoice",
                        "answer": "k = 6",
                        "explanation": "32<sub>k</sub> = 26<sub>seven</sub><br> (3xk<sup>1</sup>) + (2xk<sup>0</sup>) = (2x7<sup>1</sup>)+(6x7<sup>0</sup>)<br> (3xk) + (2x1) = (2x7) + (6x1)<br> 3k + 2 = 14 + 6<br> 3k + 2 = 20<br> 3k + 2 - 2 = 20 - 2<br> 3k = 18<br> k = 6",
                        "options": [
                            "k = 6",
                            "k = 7",
                            "k = 8"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B22_9"
            },
            {
                "question": "Subtract 1101<sub>two</sub> - 11<sub>two</sub>",
                "type": "multipleChoice",
                "answer": "1010<sub>two</sub>",
                "options": [
                    "1000<sub>two</sub>",
                    "1010<sub>two</sub>",
                    "1011<sub>two</sub>"
                ],
                "mark": 2,
                "sub_questions": [
                    {
                        "question": "Given that p<sup>2</sup> + p<sup>2</sup> = 32. Find the value of p",
                        "type": "multipleChoice",
                        "answer": "p = 4",
                        "explanation": "p<sup>2</sup> + p<sup>2</sup> = 32<br> 2p<sup>2</sup> = 32<br> p<sup>2</sup> = 16<br> √(p<sup>2</sup>) = √16<br> p = 4",
                        "options": [
                            "p = 2",
                            "p = 3",
                            "p = 4"
                        ],
                        "mark": 3
                    }
                ],
                "id": "ple_math_sect_B22_10"
            }
        ],
        "$id": "document_21"
    },
    {
        "category": 23,
        "questions": [
            {
                "question": "Work out: (2.4 x 0.5)/0.12",
                "type": "multipleChoice",
                "answer": "10",
                "explanation": "(2.4 x 0.5)/0.12<br> = (24/10 x 5/10)/12/100<br> = (120/100)/12/100<br> = 120/100 ÷ 12/100<br> = 120/100 x 100/12<br> = 120/12<br> = 10",
                "options": [
                    "10",
                    "20",
                    "30"
                ],
                "mark": 3,
                "sub_questions": [
                    {
                        "question": "Simplify: 0.37 - 1.03 + 2.6",
                        "type": "multipleChoice",
                        "answer": "1.94",
                        "explanation": "Using BODMAS<br> = 0.37 + 2.6 - 1.03<br> = 2.97 - 1.03<br> = 1.94",
                        "options": [
                            "2.97",
                            "1.94",
                            "-3.26"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B23_1"
            },
            {
                "question": "What is the place value of 2 and 1 in 201<sub>three</sub>?",
                "type": "multipleChoice",
                "answer": "Threes and ones",
                "options": [
                    "Threes and ones",
                    "Three and ones",
                    "Three and threes"
                ],
                "mark": 2,
                "sub_questions": [
                    {
                        "question": "Work out: 42<sub>five</sub> x 21<sub>five</sub>",
                        "type": "multipleChoice",
                        "answer": "1432<sub>five</sub>",
                        "options": [
                            "1422<sub>five</sub>",
                            "1442<sub>five</sub>",
                            "1432<sub>five</sub>"
                        ],
                        "mark": 3
                    }
                ],
                "id": "ple_math_sect_B23_2"
            },
            {
                "question": "In a class, 32 pupils play football (F) only, g play both volleyball (V) and football, (2 g - 10) play volley ball but not football while (g -2) play neither of the two games.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2017/23.png",
                "sub_questions": [
                    {
                        "question": "Which of the following venn diagrams show the correct venn diagram",
                        "type": "multipleChoice",
                        "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2017/23W1.png",
                        "options": [
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2017/23W.png",
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2017/23W1.png",
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2017/23C.png"
                        ],
                        "mark": 2,
                        "id": "ple_math_sect_B23_3_sub_0"
                    },
                    {
                        "question": "Given that 62 pupils play one game only, find the value of g",
                        "type": "multipleChoice",
                        "answer": "g = 36",
                        "explanation": "2g - 10 = 62<br> 2g - 10 + 10 = 62 + 10<br> 2g = 72<br> 2g/2 = 72/2<br> g = 36",
                        "options": [
                            "g = 60",
                            "g = 36",
                            "g = 32"
                        ],
                        "mark": 2,
                        "id": "ple_math_sect_B23_3_sub_1"
                    },
                    {
                        "question": "Calculate the number of pupils in the class.",
                        "type": "multipleChoice",
                        "answer": "164 pupils",
                        "explanation": "(2g - 10) + g + 32 + (g - 2)<br> (2 x 36 - 10) + 36 + 32 + (32 - 2)<br> 62 + 36 + 32 + 34<br> = 164",
                        "options": [
                            "162 pupils",
                            "160 pupils",
                            "164 pupils"
                        ],
                        "mark": 2,
                        "id": "ple_math_sect_B23_3_sub_2"
                    }
                ],
                "id": "ple_math_sect_B23_3"
            },
            {
                "question": "Simplify: (0.12 x 5.4)/(0.03 x 0.6)",
                "type": "multipleChoice",
                "answer": "36",
                "explanation": "(12/100 x 54/10) ÷ (3/100 x 6/10)<br> = ((12 x 54)/1000) ÷ ((3 x 6)/1000)<br> = ((12 x 54)/1000) x (1000/(3 x 6))<br> = (12 x 54)/(3 x 6)<br> = 4 x 9<br> = 36",
                "options": [
                    "36",
                    "2/9",
                    "3/10"
                ],
                "mark": 3,
                "sub_questions": [
                    {
                        "question": "Express the recurring decimal 0.5454… as a common fraction.",
                        "type": "multipleChoice",
                        "answer": "6/11",
                        "explanation": "0.5454 = (54 - 0) ÷ (100 - 1)<br> = 54/99<br> = 6/11",
                        "options": [
                            "2/11",
                            "5/11",
                            "6/11"
                        ],
                        "mark": 3
                    }
                ],
                "id": "ple_math_sect_B23_4"
            },
            {
                "question": "The table below shows the rate at which different currencies were sold and bought in a commercial bank during the month of September. Use it to answer the questions that follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2015/23.png",
                "sub_questions": [
                    {
                        "question": "How many Euros did Musa get for Ug. Sh. 603,000?",
                        "type": "multipleChoice",
                        "answer": "150 Euros",
                        "explanation": "Since Musa was buying Euros<br> = 603,000/4,020<br> = 150",
                        "options": [
                            "150 Euros",
                            "150.75 Euros",
                            "167.5 Euros"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "Amina came from Rwanda with 109,500 Rwandese francs and exchanged them for US dollars. How many US dollars did she get from the bank?",
                        "type": "multipleChoice",
                        "answer": "120 US dollars",
                        "explanation": "Changing Rwandan francs to Ugandan shillings and then to US dollars<br> Local currency = foreign currency x buying rate<br> 1 Rwandan franc = Ugshs 4<br> 10,9500 RF = Ug sh 4 x 109,500<br> = Ugshs 438,000<br> Foreign currency = local currency/selling rate<br> = 438,000/3,650<br> = 120 US dollars",
                        "options": [
                            "240 US dollars",
                            "120 US dollars",
                            "200 US dollars"
                        ],
                        "mark": 3
                    }
                ],
                "id": "ple_math_sect_B23_5"
            },
            {
                "question": "Apio bought the following items from a market.<br> 2kg of rice at sh 3,200 per kg<br> 1½ kg of meat at sh 8,000 per kg<br> 500 g of salt at sh 1,400 per kg<br> A sacket of cooking oil at sh 1,750.<br> How much money did she spend altogether?",
                "type": "multipleChoice",
                "answer": "She spent sh 20,850",
                "explanation": "Rice = 3200 x 2 = 6,400<br> Meat = 1½ x 8000<br> = 3/2 x 8000<br> = 12,000<br> Salt, we first convert grammes to kilogrammes<br> 1g = 1/1000kg<br> 500g = (500 x 1/1000)kg<br> = 1/2kg<br> = 1/2 x 1400<br> = 700<br> Cooking oil = 1750<br> Total spent = 6,400 + 12,000 + 700 + 1750<br> = 20,850",
                "options": [
                    "She spent sh 20,000",
                    "She spent sh 15,850",
                    "She spent sh 20,850"
                ],
                "mark": 5,
                "id": "ple_math_sect_B23_6"
            },
            {
                "question": "In a village of 49 farmers, 20 grow millet)M), 25 grow beans and y grow both millet and beans. 3y farmers grow neither of the two food crops.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2013/23.png",
                "sub_questions": [
                    {
                        "question": "Which of the following show a complete venn diagram according to the given information",
                        "type": "multipleChoice",
                        "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2013/23_3.png",
                        "options": [
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2013/23_2.png",
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2013/23_1.png",
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2013/23_3.png"
                        ],
                        "mark": 3
                    },
                    {
                        "question": "Find the value of y",
                        "type": "multipleChoice",
                        "answer": "y = 2",
                        "explanation": "20 - y + y + 25 - y + 3y = 49<br> 3y - y + 20 + 25 = 49<br> 2y + 45 = 49<br> 2y + 45 - 45 = 49 - 45<br> 2y = 4<br> y = 2",
                        "options": [
                            "y = 2",
                            "y = 3",
                            "y = 4"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "How many farmers grow neither millet nor beans?",
                        "type": "multipleChoice",
                        "answer": "6 farmers",
                        "explanation": "Neither millet nor beans = 3 x y<br> = 3 x 2<br> = 6",
                        "options": [
                            "4 farmers",
                            "8 farmers",
                            "6 farmers"
                        ],
                        "mark": 1
                    }
                ],
                "id": "ple_math_sect_B23_7"
            },
            {
                "question": "Asiimwe bought the following items from a shop.<br> 3 bars of soap at shs 1,200 per bar<br> 1½ kg of sugar at shs 3,000 per kg<br> ½ kg of salt at shs 1,000 per kg",
                "sub_questions": [
                    {
                        "question": "What was his total expenditure?",
                        "type": "multipleChoice",
                        "answer": "Total expenditure = 8,600",
                        "explanation": "Soap = 1,200 x 3 = 3,600<br> Sugar = 1½ x 3000<br> = 3/2 x 3000<br> = 4,500<br> Salt = ½ x 1000 = 500<br> Total expenditure = 3,600 + 4,500 + 500<br> = 8,600",
                        "options": [
                            "Total expenditure = 8,000",
                            "Total expenditure = 6,600",
                            "Total expenditure = 8,600"
                        ],
                        "mark": 4
                    },
                    {
                        "question": "If he had shs 10,000, how much money did he remain with?",
                        "type": "multipleChoice",
                        "answer": "shs 1,400",
                        "explanation": "Change = shs 10,000 - shs 8,600<br> = shs 1,400",
                        "options": [
                            "shs 2,000",
                            "shs 3,400",
                            "shs 1,400"
                        ],
                        "mark": 1
                    }
                ],
                "id": "ple_math_sect_B23_8"
            },
            {
                "question": "Study Wasswa`s shopping bill table below and answer the questions that follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/paper_1/23Q.png",
                "sub_questions": [
                    {
                        "question": "Which of the following tables show the complete correct table",
                        "type": "multipleChoice",
                        "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/paper_1/23C.png",
                        "explanation": "Rice: 4500 x 3 = 13,500<br> Milk: 1500/3000 = 1/2 litres<br> Apples: 10500/7 = 1500<br> Total Expenditure = 13,500 + 10,500 + 1,500 = 25,500",
                        "options": [
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/paper_1/23C.png",
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/paper_1/23W.png"
                        ],
                        "mark": 3
                    },
                    {
                        "question": "Wasswa was given a discount of sh. 500 on all items. If a shopkeeper was given a fifty-thousand-shilling note, find Wasswa`s change",
                        "type": "multipleChoice",
                        "answer": "Change = 28,000",
                        "explanation": "22,500 - 500 = 22,000<br> 50,000 - 22,000<br> = 28,000",
                        "options": [
                            "Change = 22,000",
                            "Change = 25,000",
                            "Change = 28,000"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B23_9"
            },
            {
                "question": "Find the perimeter of a square garden whose area is 81 m<sup>2</sup>",
                "type": "multipleChoice",
                "answer": "Perimeter = 36 m",
                "explanation": "s x s  = Area<br> s<sup>2</sup> = Area<br> s<sup>2</sup> = 81<br> √(s<sup>2</sup>) = √81<br> s = 9m<br> Perimeter = 9 + 9 + 9 + 9<br> = 36 m",
                "options": [
                    "Perimeter = 26 m",
                    "Perimeter = 36 m",
                    "Perimeter = 30 m"
                ],
                "mark": 4,
                "id": "ple_math_sect_B23_10"
            }
        ],
        "$id": "document_22"
    },
    {
        "category": 24,
        "questions": [
            {
                "question": "In the figure below, PQ is parallel to RS and AB is perpendicular to AR. Study the figure and answer the question that follows.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/2019_24.png",
                "sub_questions": [
                    {
                        "question": "Find the size of angle c.",
                        "type": "multipleChoice",
                        "answer": "c = 29°",
                        "explanation": "Angle ARS<br> ∠ARS + 90° + 55° = 180°( interior angle sum of a triangle)<br> ∠ARS + 145° = 180°<br> ∠ARS + 145°- 145° = 180° - 145°<br> ∠ARS = 35°<br> c + 35° + 116° = 180° (co-interior angles)<br> c + 151° = 180°<br> c + 151°- 151° = 180°- 151°<br> c = 29°",
                        "options": [
                            "c = 39°",
                            "c = 30°",
                            "c = 29°"
                        ],
                        "id": "ple_math_sect_B24_1_sub_0"
                    }
                ],
                "id": "ple_math_sect_B24_1"
            },
            {
                "question": "The sum of the lengths of all the edges of the prism below is 96cm.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2018/24.png",
                "sub_questions": [
                    {
                        "question": "Find the length of edge L",
                        "type": "multipleChoice",
                        "answer": "L = 6 cm",
                        "explanation": "2 x (10 + 8 + L) = 96<br> 2 x (18 + L) = 96<br> 36 + 2L = 96<br> 36 - 36 + 2L = 96 - 36<br> 2L = 60<br> L = 60/2<br> = L = 30",
                        "options": [
                            "L = 6 cm",
                            "L = 10 cm",
                            "L = 90 cm"
                        ],
                        "mark": 3
                    },
                    {
                        "question": "Calculate the volume of the prism",
                        "type": "multipleChoice",
                        "answer": "480 cm<sup>3</sup>",
                        "explanation": "Volume=Length x Width x Height<br> = 10cm x 8cm x 30cm<br> = 2400cm<sup>3</sup>",
                        "options": [
                            "240 cm<sup>3</sup>",
                            "480 cm<sup>3</sup>",
                            "240 cm<sup>3</sup>"
                        ]
                    }
                ],
                "id": "ple_math_sect_B24_2"
            },
            {
                "question": "A school bus taking pupils to a Game Park covered 75% of its journey in 1½ hour. The bus travelled at a steady speed of 80 kilometers per hour. Find how far the school is from the Game Park.",
                "type": "multipleChoice",
                "answer": "160 km",
                "explanation": "Let total time be t<br> 75% of t = 1½<br> 75/100 x t = 3/2<br> 75/100t x 100 = 3/2 x 100<br> 75t = 150<br> t = 2 hours<br> Distance = Speed x Time<br> = 80 x 2<br> = 160 km",
                "options": [
                    "150 km",
                    "100 km",
                    "160 km"
                ],
                "mark": 4,
                "id": "ple_math_sect_B24_3"
            },
            {
                "question": "The exchange rates in a bank are as follows;<br> 1 US dollar ($) = Ug.Sh 3,400<br> 1 British pound Sterling (£) = Ug sh 4,600<br> 1 Kenya shilling (K.sh)= Ug. Sh 35.",
                "sub_questions": [
                    {
                        "question": "Convert Ug. Sh 1,840,000 to British pound sterling.",
                        "type": "multipleChoice",
                        "answer": "Ug. Sh 1,840,000 = 400 British pound Sterling",
                        "explanation": "Since 1 British pound Sterling (£) = Ug sh 4,600<br> Ug. Sh 1,840,000 = 1,840,000/4,600<br> = 18400/46<br> = 9200/23<br> = 400",
                        "options": [
                            "Ug. Sh 1,840,000 = 541.2 British pound Sterling",
                            "Ug. Sh 1,840,000 = 400 British pound Sterling",
                            "Ug. Sh 1,840,000 = 450 British pound Sterling"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "If a set of chairs costs $700, find the equivalent cost of the chairs in Kenya shillings.",
                        "type": "multipleChoice",
                        "answer": "Cost = K.sh 68,000",
                        "explanation": "First convert $700 to Ug. sh<br> = 700 x 3.400<br> = Ug.sh 2,380,000<br> Now convert Ug. sh to K. sh<br> = 2,380,000/36<br> = 68,000",
                        "options": [
                            "Cost = K.sh 2,380,000",
                            "Cost = K.sh 68,000",
                            "Cost = K.sh 68,0000"
                        ],
                        "mark": 3
                    }
                ],
                "id": "ple_math_sect_B24_4"
            },
            {
                "question": "Betty filled container A below with drinking water. She served visitors with the water using cups of size B shown in the diagram. Find the total number of full cups of water she served the visitors (Use π = 22/7)",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2015/24.png",
                "type": "multipleChoice",
                "answer": "80 cups",
                "explanation": "Number of cups = Volume of container A/Volume of container B<br> Volume of A = πr<sub>2</sub>h<br> = 22/7 x (28/2)<sub>2</sub> x 50<br> = 22/7 x 14 x 14 x 50<br> = 30800 cm<sub>3</sub><br> Volume of B = 22/7 x (7/2)<sub>2</sub> x 10<br> = 22/7 x 7/2 x 7/2 x 10<br> = 11 x 7 x 5<br> = 385 cm<sub>3</sub><br> Number of cups = 30800/385<br> = 80 cups",
                "options": [
                    "80 cups",
                    "100 cups",
                    "20 cups"
                ],
                "mark": 6,
                "id": "ple_math_sect_B24_5"
            },
            {
                "question": "The diagram below shows a rhombus PQRS. The diagonals PR = 24 cm and QS = 10 cm.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2014/24.png",
                "sub_questions": [
                    {
                        "question": "Calculate the area of the rhombus.",
                        "type": "multipleChoice",
                        "answer": "Area = 120 cm<sup>2</sup>",
                        "explanation": "Area = 1/2 x PR x QS<br> = 1/2 x 24 x 10<br> = 1/2 x 240<br> = 120",
                        "options": [
                            "Area = 120 cm<sup>2</sup>",
                            "Area = 240 cm<sup>2</sup>",
                            "Area = 34 cm<sup>2</sup>"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "Find the perimeter of the rhombus",
                        "type": "multipleChoice",
                        "answer": "Perimeter = 52 cm",
                        "explanation": "Perimeter = 4 x side<br> Side = √[(24/2)<sup>2</sup> + (10/2)<sup>2</sup>]<br> = √(12<sup>2</sup> + 5<sup>2</sup>)<br> = √(144 + 25)<br> = √169<br> = 13<br> Therefore Perimeter = 4 x 13<br> = 52 cm",
                        "options": [
                            "Perimeter = 34 cm",
                            "Perimeter = 52 cm",
                            "Perimeter = 26 cm"
                        ],
                        "mark": 4
                    }
                ],
                "id": "ple_math_sect_B24_6"
            },
            {
                "question": "Pupils did a test and scored marks as shown in the table below.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2013/24.png",
                "sub_questions": [
                    {
                        "question": "How many pupils did the test?",
                        "type": "multipleChoice",
                        "answer": "15 pupils",
                        "explanation": "Number of pupils = 2 + 6 + 3 + 4<br> = 15 pupils",
                        "options": [
                            "150 pupils",
                            "15 pupils",
                            "100 pupils"
                        ],
                        "mark": 1
                    },
                    {
                        "question": "Find the value of k if the mean mark was 61.",
                        "type": "multipleChoice",
                        "answer": "k = 60",
                        "explanation": "sum ÷ number = mean<br> ((50x2) + (kx6) + (45 x 3) + (80 x 4)) ÷ 15 = 61<br> (6k + 100 + 135 + 320) ÷ 15 = 61<br> 15(6k + 555) ÷ 15 = 61 x 15<br> 6k + 555 = 915<br> 6k + 555 - 555 = 915 - 555<br> 6k = 360<br> 6k/6 = 360/6<br> k = 60",
                        "options": [
                            "k = 100",
                            "k = 50",
                            "k = 60"
                        ],
                        "mark": 3
                    },
                    {
                        "question": "What was the range of the marks?",
                        "type": "multipleChoice",
                        "answer": "Range = 35",
                        "explanation": "Range = Highest - lowest<br> = 80 - 45<br> = 35",
                        "options": [
                            "Range = 35",
                            "Range = 30",
                            "Range = 25"
                        ],
                        "mark": 1
                    }
                ],
                "id": "ple_math_sect_B24_7"
            },
            {
                "question": "A cylindrical tin of radius 7 cm contains 3080 cm<sup>3</sup> of cooking oil.",
                "sub_questions": [
                    {
                        "question": "Joan used 2156 cm<sup>3</sup> of the cooking oil. What is the height of the cooking oil remaining in the tin? (Take π = 22/7)",
                        "type": "multipleChoice",
                        "answer": "Height = 6 cm",
                        "explanation": "Volume = Base area x height (h)<br> Volume of remaining cooking oil = 924 cm³<br> Base Area x height = volume<br> πr<sup>2</sup>h = volume<br> 22/7 x 7 x 7 x h = 194<br> 154h = 924<br> 154h/154 = 924/154<br> h = 6",
                        "options": [
                            "Height = 16 cm",
                            "Height = 8 cm",
                            "Height = 6 cm"
                        ],
                        "mark": 3
                    },
                    {
                        "question": "Joan poured the remaining cooking oil into a rectangular tin with base area 77 cm<sup>2</sup>. What was the height of the oil in the tin?",
                        "type": "multipleChoice",
                        "answer": "Height = 12 cm",
                        "explanation": "Base Area x height = volume<br> (77 x h)/77 = 924/77<br> h = 12 cm",
                        "options": [
                            "Height = 6 cm",
                            "Height = 18 cm",
                            "Height = 12 cm"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B24_8"
            },
            {
                "question": "The table below shows Ammon's shopping bill. Use it to answer the following questions",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/paper_2/24Q.png",
                "sub_questions": [
                    {
                        "question": "Which of the following tables show a complete correct table?",
                        "type": "multipleChoice",
                        "explanation": "Beans amount: 3 x 5,000 = 15,000<br> Flour: 12,800/3200 = 4 kg<br> Salt: 600g = 600/1000<br> = 3/5<br> = 900 ÷ 3/5<br> = 900 x 5/3<br> = 300 x 5<br> = 1,500<br> Total = 12,800 + 15,000 + 900<br> = 28,700",
                        "answer": [
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/paper_2/24W1.png",
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/paper_2/24C.png",
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/paper_2/24W.png"
                        ],
                        "mark": 4
                    },
                    {
                        "question": "If a man was given a discount of 10%, how much did he pay?",
                        "type": "multipleChoice",
                        "answer": "He paid 25,830",
                        "explanation": " New% = 100% - 10%<br> = 90%<br> = 90/100 x 28,700<br> = 25,830",
                        "options": [
                            "He paid 13,680",
                            "He paid 15,830",
                            "He paid 25,830"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B24_9"
            }
        ],
        "$id": "document_23"
    },
    {
        "category": 25,
        "questions": [
            {
                "question": "The diagram below represents a container which a shopkeeper uses to sell cooking oil.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/Symbol 13.png",
                "sub_questions": [
                    {
                        "question": "On a certain day, the shopkeeper sold 15.4 litres of cooking oil. How many such containers of cooking oil were sold that day? (Use π = 22/7)",
                        "type": "multipleChoice",
                        "answer": "20 containers",
                        "explanation": "Get the volume of the container;<br> Volume = π x radius<sup>2</sup> x height<br> = 22/7 x (7/2)<sup>2</sup> x 20<br> = 22/7 x 7/2 x 7/2 x 20<br> = 770 cm<sup>3</sup><br> Convert litres to cm<sup>3</sup><br> 1 litre = 1000 cm<sup>3</sup><br> 15.4 litres = (15.4 x 1000)cm<sup>3</sup><br> = 15400cm<sup>3</sup><br> Number of containers = (Volume of litres sold in cm<sup>3</sup>)/(Volume of the container)<br> = 15400/770 = 20 containers",
                        "options": [
                            "70 containers",
                            "20 containers",
                            "50 containers"
                        ],
                        "mark": 5
                    }
                ],
                "id": "ple_math_sect_B25_1"
            },
            {
                "question": "Below is Mukasa's shopping table. Select the complete correct table from the given options",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2018/25.png",
                "type": "multipleChoice",
                "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2018/25C.png",
                "explanation": "Unit cost of sugar = 14400/3<br> = 4800<br>Rice quantity = 5000/2500<br> = 2 kg<br> Milk total; We first need to convert the ml to litres<br> 1 ml = (1/1000)litres<br> 250ml = (1/1000 x 250)litres<br> = 25/100 litres<br> 1/4 litres<br> ∴ Milk total = 3000 x 1/4 = 750 shs<br> Total for Biscuits = Total Expenditure - (Other expenditures)<br> = 29,650 - (14,400 + 2,500 + 750)<br> = 12,000<br> Unit cost for biscuits = 12000/2<br> = 6,000",
                "options": [
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2018/25W.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2018/25W1.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2018/25C.png"
                ],
                "mark": 5,
                "id": "ple_math_sect_B25_2"
            },
            {
                "question": "Solve the equation: 3n/5 + 6 = 2 + n",
                "type": "multipleChoice",
                "answer": "n = 10",
                "explanation": "3/5n - n + 6 - 6 = 2 - 6 + n - n<br> 3/5n - n = 2 - 6<br> (3n - 5n)/5 = -4<br> -2n/5 = -4<br> Multiply both sides by 5<br> -2n/5 x 5 = -4 x 5<br> -2n = -20<br> -2n/-2 = -20/-2<br> n = 10",
                "options": [
                    "n = 5",
                    "n = 15",
                    "n = 10"
                ],
                "mark": 3,
                "sub_questions": [
                    {
                        "question": "Solve the inequality: 9 - 2k > k + 3",
                        "type": "multipleChoice",
                        "answer": "k < 2",
                        "explanation": "9 - 2k > k + 3<br> 9 - 9 - 2k - k > k - k + 3 - 9<br> -3k > -6<br> -3k/-3 < -6/-3<br> k < 2",
                        "options": [
                            "k > 2",
                            "k < 2",
                            "k < -2"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B25_3"
            },
            {
                "question": "Study the figure below and use it to answer the questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/25.png",
                "sub_questions": [
                    {
                        "question": "Find the value of g",
                        "type": "multipleChoice",
                        "answer": "g = 28",
                        "explanation": "3g + 6 + g + 30 + 2g - 24 = 180<br> 3g + g + 2g - 24 + 6 + 30 = 180<br> 6g - 24 + 36 = 180<br> 6g + 12 = 180<br> 6g + 12 - 12 = 180 - 12<br> 6g = 168<br> 6g/6 = 168/6<br> g = 28",
                        "options": [
                            "g = 28",
                            "g = 24",
                            "g = 30"
                        ],
                        "mark": 3,
                        "id": "ple_math_sect_B25_4_sub_0"
                    },
                    {
                        "question": "Calculate the size of the angle RST",
                        "type": "multipleChoice",
                        "answer": "Angle RST = 58<sup>o</sup>",
                        "explanation": "Since angle RST = (g+30)<br> = 28 + 30<br> = 58",
                        "options": [
                            "Angle RST = 58<sup>o</sup>",
                            "Angle RST = 54<sup>o</sup>",
                            "Angle RST = 60<sup>o</sup>"
                        ],
                        "mark": 1,
                        "id": "ple_math_sect_B25_4_sub_1"
                    }
                ],
                "id": "ple_math_sect_B25_4"
            },
            {
                "question": "A fruit seller sold the following number of mangoes in six days.<br> 60, 35, 28, 42, and 35",
                "sub_questions": [
                    {
                        "question": "What is the modal number of mangoes sold?",
                        "type": "multipleChoice",
                        "answer": "Modal number = 35",
                        "explanation": "Since 35 appears twice, its the modal number",
                        "options": [
                            "Modal number = 28",
                            "Modal number = 35",
                            "Modal number = 60"
                        ],
                        "mark": 1
                    },
                    {
                        "question": "Work out the mean number of mangoes sold.",
                        "type": "multipleChoice",
                        "answer": "Mean = 40",
                        "explanation": "Mean = (60 + 35 + 28 + 42 + 35)/5<br> = 200/5<br> = 40",
                        "options": [
                            "Mean = 40",
                            "Mean = 30",
                            "Mean = 20"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "By the end of the seventh day, the mean number of mangoes sold was 44. How many mangoes were sold on the seventh day?",
                        "type": "multipleChoice",
                        "answer": "68 mangoes",
                        "explanation": "Total mangoes sold in 6 days = 60 + 35 + 28 + 42 + 35<br> = 240<br> Total mangoes sold by the end of the seventh day = 7 x 44<br> = 308<br> Number of mangoes sold on the seventh day = Total mangoes sold by the end of the seventh day - Total mangoes sold in 6 days<br> = 308 - 240<br> = 68",
                        "options": [
                            "60 mangoes",
                            "65 mangoes",
                            "68 mangoes"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B25_5"
            },
            {
                "question": "Work out: (3.09 + 3.6)/(0.06 x 0.5)",
                "type": "multipleChoice",
                "answer": "223",
                "explanation": "(3.09 + 3.6)/(6/100 x 5/10)<br> = 6.69 ÷ 30/1000<br> = 6.69 ÷ 3/100<br> = 669/100 ÷ 3/100<br> = 669/100 x 100/3<br> = 223",
                "options": [
                    "223",
                    "2230",
                    "22300"
                ],
                "mark": 3,
                "sub_questions": [
                    {
                        "question": "Simplify: 3⅓ ÷ 2½ x 2⅖",
                        "type": "multipleChoice",
                        "answer": "3⅕",
                        "explanation": "Using BODMAS<br> (10/3 ÷ 5/2) x 12/5<br> = (10/3 x 2/5) x 12/5<br> = 4/3 x 12/5<br> = (4 x 4)/ 5<br> = 16/5<br> = 3⅕",
                        "options": [
                            "3⅕",
                            "3½",
                            "3⅐"
                        ],
                        "mark": 3
                    }
                ],
                "id": "ple_math_sect_B25_6"
            },
            {
                "question": "Solve the inequality: 9 ≤ -3(y-1)",
                "type": "multipleChoice",
                "answer": "y ≤ -2",
                "explanation": "9 ≤ -3y + 3<br> 9 - 3 ≤ -3y + 3 - 3<br> 6 ≤ -3y<br> 6/-3 ≤ -3/-3y<br> -2 ≥ y (Change sign when dividing with a negative coefficient)<br> Therefore y ≤ -2",
                "options": [
                    "y ≥ -2",
                    "y ≤ -2",
                    "y ≤ 2"
                ],
                "mark": 3,
                "sub_questions": [
                    {
                        "question": "State the first two values of the solution set for the inequality",
                        "type": "multipleChoice",
                        "answer": "First two values of y = { -2, -3}",
                        "options": [
                            "First two values of y = { -3, -4}",
                            "First two values of y = { -1, -2}",
                            "First two values of y = { -2, -3}"
                        ],
                        "mark": 1
                    }
                ],
                "id": "ple_math_sect_B25_7"
            },
            {
                "question": "Solve: 14p + 4 = 11",
                "type": "multipleChoice",
                "answer": "p = 1/2",
                "explanation": "14p + 4 = 11<br> 14p + 4 - 4 = 11 - 4<br> 14p = 7<br> 14p/14 = 7/14<br> p = 1/2",
                "options": [
                    "p = 2",
                    "p = 1/4",
                    "p = 1/2"
                ],
                "mark": 2,
                "sub_questions": [
                    {
                        "question": "Solve the inequality: 3x + 4 > x + 8",
                        "type": "multipleChoice",
                        "answer": "x > 2",
                        "explanation": "3x + 4 > x + 8<br> 3x - x > 8 - 4<br> 2x > 4<br> x > 2",
                        "options": [
                            "x > 2",
                            "x > -2",
                            "x < 2"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B25_8"
            },
            {
                "question": "The table below shows marks scored by a certain group of P.7 pupils in a Mathematics test. Use it to answer questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/paper_1/25.png",
                "sub_questions": [
                    {
                        "question": "Find the total number of pupils who scored above 80",
                        "type": "multipleChoice",
                        "answer": "Total number = 9",
                        "explanation": "Above 80 = 4 + 9<br> = 9",
                        "options": [
                            "Total number = 6",
                            "Total number = 9",
                            "Total number = 11"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "Work out the median mark of pupils who scored below 75",
                        "type": "multipleChoice",
                        "answer": "Median mark = 52",
                        "explanation": "Arrange marks below 75 in ascending and descending order<br> 74, 56, 56, 50,50, 50<br> Median = (56+50)/2<br> = 106/2<br> = 53",
                        "options": [
                            "Median mark = 50",
                            "Median mark = 60",
                            "Median mark = 52"
                        ],
                        "mark": 3
                    }
                ],
                "id": "ple_math_sect_B25_9"
            },
            {
                "question": "Use the Venn diagram below to answer questions that follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/paper_2/25.png",
                "sub_questions": [
                    {
                        "question": "Find the value of M<sub>1</sub>",
                        "type": "multipleChoice",
                        "answer": "M<sub>1</sub> = 5",
                        "explanation": "(2 x 2) x 3xm = 60<br> 12m = 60<br> 12m/12 = 60/12<br> m = 5",
                        "options": [
                            "M<sub>1</sub> = 4",
                            "M<sub>1</sub> = 5",
                            "M<sub>1</sub> = 6"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "Find the value of Q",
                        "type": "multipleChoice",
                        "answer": "Q = 126",
                        "explanation": "Q = (2 X 3) X ( 3 X 7)<br> = 6 x 21<br> = 126",
                        "options": [
                            "Q = 90",
                            "Q = 126",
                            "Q = 120"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "Find the GCF of F60 and FQ",
                        "type": "multipleChoice",
                        "answer": "GCF = 6",
                        "explanation": "GCF = product of intersection<br> = 2x3<br> = 6",
                        "options": [
                            "GCF = 3",
                            "GCF = 5",
                            "GCF = 6"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B25_10"
            }
        ],
        "$id": "document_24"
    },
    {
        "category": 26,
        "questions": [
            {
                "question": "Kizza went to a market with sh. 30,000. She bought the items shown in the table below. After paying for all the items, she remained with sh 9,250. Which of the following tables show the complete table?",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/26.png",
                "type": "multipleChoice",
                "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/26A.png",
                "explanation": "",
                "options": [
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/26B.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/26C.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/26A.png"
                ],
                "mark": 6,
                "id": "ple_math_sect_B26_1"
            },
            {
                "question": "In the diagram below, line DH is parallel to FE. Angle ACB = 75<sup>o</sup> and angle CBE = 135<sup>o</sup>. Angle FAD is twice angle DAC. Study the diagram and use it to answer the questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2017/26.png",
                "sub_questions": [
                    {
                        "question": "Calculate the size of angle DAC",
                        "type": "multipleChoice",
                        "answer": "Angle DAC = 40°",
                        "explanation": "Let the angle DAC be a<br> Angle FAD = 2a<br> 2a + a = 75 + 45<br> 3a = 120<br> a = 40°",
                        "options": [
                            "Angle DAC = 50°",
                            "Angle DAC = 60°",
                            "Angle DAC = 40°"
                        ],
                        "mark": 3,
                        "sub_questions": [
                            {
                                "question": "Find the size of angle ADC",
                                "type": "multipleChoice",
                                "answer": "Angle ADC = 80°",
                                "explanation": "Angle ADC = 2a°(alternetive angle)<br> = 2 x 40<br> Angle ADC = 80°",
                                "options": [
                                    "Angle ADC = 60°",
                                    "Angle ADC = 80°",
                                    "Angle ADC = 70°"
                                ],
                                "mark": 2
                            }
                        ],
                        "id": "ple_math_sect_B26_2_sub_0"
                    }
                ],
                "id": "ple_math_sect_B26_2"
            },
            {
                "question": "The figure below represents a rectangular floor which is covered by square tiles of area 400 cm<sup>2</sup> each. Use it to answer the questions that follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/26.png",
                "sub_questions": [
                    {
                        "question": "Find the area of the rectangular floor",
                        "type": "multipleChoice",
                        "answer": "Area = 7200 cm<sup>2</sup>",
                        "explanation": "Since the number of sqaures = 18<br> Area of rectangular floor = Area of one square x Number of sqaures<br> = 400 x 18<br> = 7200",
                        "options": [
                            "Area = 8000 cm<sup>2</sup>",
                            "Area = 7000 cm<sup>2</sup>",
                            "Area = 7200 cm<sup>2</sup>"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "Calculate the perimeter of the rectangular floor",
                        "type": "multipleChoice",
                        "answer": "Perimeter = 360 cm",
                        "explanation": "Perimeter = 2(L + W)<br> The length of each side of the squares;<br> From Area = S<sup>2</sup><br> 400 = S<sup>2</sup><br> √400 = √S<sup>2</sup><br> S = 20 cm<br> Length = (6 x 20) = 120 cm<br> Width = (3 x 20) = 60 cm<br> ∴ P = 2(L + W)<br> = 2(120 + 60)<br> = 2 x 180<br> = 360",
                        "options": [
                            "Perimeter = 360 cm",
                            "Perimeter = 14,400 cm",
                            "Perimeter = 72000 cm"
                        ],
                        "mark": 4
                    }
                ],
                "id": "ple_math_sect_B26_3"
            },
            {
                "question": "In the figure below, line AB is parallel to CD. Angle CTV = 44<sup>o</sup> and angle TQR =56<sup>o</sup>. Study and use it to answer the questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2015/26.png",
                "sub_questions": [
                    {
                        "question": "Find the size of angle k",
                        "type": "multipleChoice",
                        "answer": "k = 80°",
                        "explanation": "k + 56 + 44 = 180 (Straight line angles)<br> k + 100 = 180<br> k + 100 - 100 = 180 - 100<br> k = 80°",
                        "options": [
                            "k = 70°",
                            "k = 55°",
                            "k = 80°"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "Find the size of angle g",
                        "type": "multipleChoice",
                        "answer": "g = 136°",
                        "explanation": "g + 44 = 180 (Co-interior angles add up to 180)<br> g + 44 - 44 = 180 - 44<br> g = 136°",
                        "options": [
                            "g = 130°",
                            "g = 126°",
                            "g = 136°"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B26_4"
            },
            {
                "question": "Which of the following diagrams show an accurate parallelogram ABCD such that line AB = 7 cm, BC = 5 cm and angle ABC = 120<sup>o</sup>, with a perpendicular from D to meet AB at M.",
                "type": "multipleChoice",
                "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2014/26_2.png",
                "options": [
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2014/26.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2014/26_1.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2014/26_2.png"
                ],
                "mark": 6,
                "id": "ple_math_sect_B26_5"
            },
            {
                "question": "A watch loses 5 seconds every hour. How many minutes will it lose in two days?",
                "type": "multipleChoice",
                "answer": "4 minutes",
                "explanation": "1 day =  24hrs<br> 2days = (24 x 2) hours<br> = 48 hours<br> In 1 hour the clock loses 5 second<br> In 1 day (24hours) it loses 5 x 24<br> =  120 seconds<br>  In 2 day (48hours) it loses  5 x 48<br> = 240 seconds<br> But 60 seconds = 1 minute<br> 240 seconds = 240/60 minutes<br> = 4 minutes",
                "options": [
                    "40 minutes",
                    "14 minutes",
                    "4 minutes"
                ],
                "mark": 2,
                "sub_questions": [
                    {
                        "question": "Express 5m/sec in km/hr",
                        "type": "multipleChoice",
                        "answer": "18 km/hr",
                        "explanation": "5m/sec = km/hr<br> 1000m = 1km<br> 5m = 5/1000 km<br> 3600 seconds = 1hr<br> 1 second = 1/3600 hours<br> Speed = Distance ÷ Time<br> = 5/1000 ÷ 1/3600<br> = 5/1000 x 3600/1<br> = 5/10 x 36/1<br> = 36/2<br> = 18km/hr",
                        "options": [
                            "15 km/hr",
                            "18 km/hr",
                            "20 km/hr"
                        ],
                        "mark": 3
                    }
                ],
                "id": "ple_math_sect_B26_6"
            },
            {
                "question": "The interior angle of a regular polygon is 36<sup>o</sup> more than its exterior angle. What is the size of each exterior angle?",
                "type": "multipleChoice",
                "answer": "Exterior angle = 72<sup>o</sup>",
                "explanation": "Let the exterior angle be x<br> The anterior angle = x + 36<br>  x + x + 36  = 180<br> 2x + 36 - 36 = 180 - 36<br> 2x = 144<br> x = 72",
                "options": [
                    "Exterior angle = 36<sup>o</sup>",
                    "Exterior angle = 80<sup>o</sup>",
                    "Exterior angle = 72<sup>o</sup>"
                ],
                "mark": 2,
                "sub_questions": [
                    {
                        "question": "In the figure below DE is parallel to FG, angle ODE = 127<sup>o</sup> and angle OFG = 136<sup>o</sup>. Calculate the size of angle x.",
                        "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2012/26.png",
                        "type": "multipleChoice",
                        "answer": "x = 97<sup>o</sup>",
                        "explanation": "x + 127 + 136 = 360 (Angles at a point)<br> x + 263 = 360<br> x + 263 - 263 = 360 - 263<br> x = 97<sup>o</sup>",
                        "options": [
                            "x = 94<sup>o</sup>",
                            "x = 95<sup>o</sup>",
                            "x = 97<sup>o</sup>"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B26_7"
            },
            {
                "question": "The number of pupils in a certain school increased by 120 pupils in the ratio of 5/6 : 1/2. Find the new number of pupils in the school.",
                "type": "multipleChoice",
                "answer": "300 pupils",
                "explanation": "Ratio = 5/6 ÷ 1/2<br> = 5/6 x 2/1<br> = 5/3<br> = 5:3<br> Let each ratio be n<br> Value of n: 2n = 120<br> n = 60<br> 5 x n<br> = 5 x 60<br> = 300 pupils",
                "options": [
                    "90 pupils",
                    "150 pupils",
                    "300 pupils"
                ],
                "mark": 5,
                "id": "ple_math_sect_B26_8"
            },
            {
                "question": "In a library there are 108 books. 4/9 of the books are Maths, 1/6 of the remainder are English and the rest are science books. Find the fraction of the science books.",
                "type": "multipleChoice",
                "answer": "Science fraction = 25/54",
                "explanation": "Math = 4/9<br> Remainder = 9/9 - 4/9<br> = 5/9<br> English = 1/6 x 5/9<br> = 5/54<br> Science fraction = 5/9 - 5/54<br> ((6x5) - (5x1)/54)<br> (30 -5)/54<br> = 25/54",
                "options": [
                    "Science fraction = 19/54",
                    "Science fraction = 1/9",
                    "Science fraction = 25/54"
                ],
                "mark": 3,
                "sub_questions": [
                    {
                        "question": "Express the number of Maths books as a ratio of the total number of books",
                        "type": "multipleChoice",
                        "answer": "4:9",
                        "explanation": "Math books = 4/9 x 108<br> = 4 x 12<br> = 48 books<br> Math:total<br> 48:108<br> = 48/108<br> = 4/9<br> = 4:9",
                        "options": [
                            "5:9",
                            "1:3",
                            "4:9"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B26_9"
            }
        ],
        "$id": "document_25"
    },
    {
        "category": 27,
        "questions": [
            {
                "question": "A bus that left town A at 11:30 a.m. moving at a speed of 60 km/hr reached town B at 1:30 p.m. The bus stayed at town B for 40 minutes. It then continued to town C and covered a distance of 96 kilometres at a speed of 64 km/hr.",
                "sub_questions": [
                    {
                        "question": "Calculate the total distance covered by the bus from town A to town C.",
                        "type": "multipleChoice",
                        "answer": "216 kilometres",
                        "explanation": "Time from town A to B is 2 hours<br> Speed = 60km/hr<br> Distance from town A to B = Speed x Time<br> = 60 x 2<br> = 120km/hr<br> Total distance = Distance from town A to B + Distance from town B to C<br> = 120km + 96km<br> = 216km",
                        "options": [
                            "200 kilometres",
                            "216 kilometres",
                            "120 kilometres"
                        ],
                        "mark": 3
                    },
                    {
                        "question": "At what time did the bus reach town C?",
                        "type": "multipleChoice",
                        "answer": "1:40 p.m",
                        "explanation": "Time from B to C<br> = Distance/Speed<br> = 96/64 hours<br> Total Time = Time from A to B+Time at B+Time from B to C<br> = 2 hours + 40/60 hours + 96/64 hours<br> = 2 hours + 2/3 hours + 3/2 hours<br> = 13/6 hours<br> Arrival time at town C = 11:30a.m + 13/6 hours<br> Convert 13/6 hours to minutes<br> = 13/6 x 60 minutes<br> = 130 minutes<br> = 1:40p.m",
                        "options": [
                            "1:30 p.m",
                            "1:40 p.m",
                            "11:30 a.m"
                        ],
                        "mark": 3
                    }
                ],
                "id": "ple_math_sect_B27_1"
            },
            {
                "question": "The average weight of four boys is 56kg. When two other boys join the group, the average weight becomes 52kg. The sixth boy is 8kg heavier than the fifth boy. Find the weight of the sixth boy.",
                "type": "multipleChoice",
                "answer": "48 kg",
                "explanation": "Average weight of the four boys;<br> (a+b+c+d)/4 = 56<br> Multiply both sides by 4<br> a+b+c+d = 224'<br> Average weight of the six boys;<br> (a+b+c+d+e+f)/6 = 52<br> Multiply both sides by 6<br> a+b+c+d+e+f = 312<br> Now we subtract the average weight of the 4 boys from the average weight of the 6 boys to get the weight of the 5th and 6th boys<br> (a+b+c+d+e+f = 312) - (a+b+c+d = 224)<br> We cancel out whats common on both sides<br> e + f = 88<br> Since the 6th boy is 8 kg heavier than the 5th boy<br> 6th boy's weight = e + 8'<br> e + e + 8 = 88<br> 2e + 8 = 88<br> 2e = 80<br> e = 40kg<br> ∴ Weight of the 6th boy = e + 8<br> = 40 + 8<br> = 48kg",
                "options": [
                    "40 kg",
                    "50 kg",
                    "48kg"
                ],
                "mark": 6,
                "id": "ple_math_sect_B27_2"
            },
            {
                "question": "Arafat deposited money in a bank which offers a simple interest rate of 2½% per year. After 9 months, his account had an amount of sh 163,000. Calculate the money Arafat deposited in the bank.",
                "type": "multipleChoice",
                "answer": "Amount = 160,000",
                "explanation": "Amount deposited = Principal + Simple interest<br> Simple interest = Principal x Rate x Time<br> 163,000 = P + P x R x T<br> 163,000 = P + P x 2.5/100 x 9/12<br> 163,000 = P(1 + 0.025 x 0.75)<br> 163,000 = 1.01875P<br> P = 160,000",
                "options": [
                    "Amount = 150,000",
                    "Amount = 160,000",
                    "Amount = 166,000"
                ],
                "mark": 5,
                "id": "ple_math_sect_B27_3"
            },
            {
                "question": "A taxi driver left town A for town B at 10:30am. Driving at a speed of 80 kilometers per hour. The driver reached town B at 2:00 p.m",
                "sub_questions": [
                    {
                        "question": "Calculate the time taken by the driver to reach town B",
                        "type": "multipleChoice",
                        "answer": "Time taken = 3 hours and 30 minutes",
                        "explanation": "Time interval = Arrival time - Departure time<br> = 2:00p.m - 10:30a.m<br> Convert the time to 24 hour format<br> = 14:00 - 10:30<br> = (14-10) hours + (0-30) minutes<br> = 4hours - 30minutes<br> Convert 30 minutes to hours<br> = 4 - 30/60<br> = 4 - 1/2<br> = 3.5<br> = 3 hours and 30 minutes",
                        "options": [
                            "Time taken = 3 hours and 30 minutes",
                            "Time taken = 4 hours and 30 minutes",
                            "Time taken = 2 hours and 30 minutes"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "Find the distance between town A and town B",
                        "type": "multipleChoice",
                        "answer": "Distance = 280 km/hr",
                        "explanation": "Distance = Speed x Time<br> = 80 x 3.5<br> = 280",
                        "options": [
                            "Distance = 360 km/hr",
                            "Distance = 200 km/hr",
                            "Distance = 280 km/hr"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B27_4"
            },
            {
                "question": "The table below shows how a motor cyclist travelled from town R through town Q and S to town P. Study it to answer the questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2015/27.png",
                "sub_questions": [
                    {
                        "question": "How long did the motor cyclist stay at town S?",
                        "type": "multipleChoice",
                        "answer": "35 minutes",
                        "explanation": "Time taken at S;<br> = 11:10 a.m - 10:35 a.m<br> = 35 minutes.",
                        "options": [
                            "45 minutes",
                            "40 minutes",
                            "35 minutes"
                        ],
                        "mark": 1
                    },
                    {
                        "question": "Find the time the motor cyclist took to travel from town R to town P.",
                        "type": "multipleChoice",
                        "answer": "4 hours and 30 minutes",
                        "explanation": "12:00 hrs + 1 00<br> = 13 30 hrs<br> 13 30 - 9 00<br> = 4 hours and 30 minutes",
                        "options": [
                            "4 hours and 30 minutes",
                            "5 hours and 30 minutes",
                            "5 hours"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "If the distance from town R to P is 180 km, calculate the average speed of the motor cyclist for the whole journey.",
                        "type": "multipleChoice",
                        "answer": "40 km/hr",
                        "explanation": "Average speed = Total distance/Total time<br> = 180 ÷ 4½<br> = 180 ÷ 9/2<br> = 180 x 2/9<br> = 40 km/hr",
                        "options": [
                            "45 km/hr",
                            "50 km/hr",
                            "40 km/hr"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B27_5"
            },
            {
                "question": "The time table shows how a pupil spent his time one Saturday. Use it to answer the questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2014/27.png",
                "sub_questions": [
                    {
                        "question": "How long did he take playing?",
                        "type": "multipleChoice",
                        "answer": "1 hour and 30 minutes",
                        "explanation": "4:30 pm - 3:00 pm<br> Hours: (4 - 3) = 1 hour<br> Minutes: (30 - 00) = 30 minutes<br> = 1 hour and 30 minutes",
                        "options": [
                            "30 minutes",
                            "1 hour",
                            "1 hour and 30 minutes"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "If he dug his maize garden at a rate of 2 rows in every 30 minutes, find the number of rows he dug that day.",
                        "type": "multipleChoice",
                        "answer": "14 rows",
                        "explanation": "Time taken digging = 10:30 a.m - 7:00 a.m<br> = 3 hours and 30 minutes<br> = 3.5 hours<br> Convert to minutes<br> = 3.5 x 60<br> = 210 minutes<br> Number of rows = 210/30 x 2<br> = 14 rows",
                        "options": [
                            "9 rows",
                            "20 rows",
                            "14 rows"
                        ],
                        "mark": 3
                    }
                ],
                "id": "ple_math_sect_B27_6"
            },
            {
                "question": "The pictograph below represents the number of patients who were admitted in a hospital on a certain day. Study and use it to answer the questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2013/27.png",
                "sub_questions": [
                    {
                        "question": "How many patients were admitted in the hospital on that day?",
                        "type": "multipleChoice",
                        "answer": "120 patients",
                        "explanation": "Total number of pictures = 3 + 4 + 3½ + 1½<br> 1 picture = 10 patients<br> 12 pictures = 10 x 12<br> = 120 patients",
                        "options": [
                            "12 patients",
                            "120 patients",
                            "100 patients"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "Find the ratio of the patients in the women's ward to those in the children's ward in its simplest form.",
                        "type": "multipleChoice",
                        "answer": "Ratio = 7:8",
                        "explanation": "Women's ward = 3½<br> = 7/2 x 10<br> = 7 x 5<br> = 35 patients<br> Children's ward = 4 x 10<br> = 40 patients<br> Women ward : Children<br> 35 : 40<br> Divide each by 5<br> = 7 : 8",
                        "options": [
                            "Ratio = 8:7",
                            "Ratio = 5:8",
                            "Ratio = 7:8"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "Express the number of patients in the men's ward as a percentage of the total number of patients.",
                        "type": "multipleChoice",
                        "answer": "25%",
                        "explanation": "Men's ward = 3 x 10<br> = 30<br> Percentage = (30/120 x 100)%<br> = 25%",
                        "options": [
                            "33.33%",
                            "30%",
                            "25%"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B27_7"
            },
            {
                "question": "In Jumbo primary school, 1/4 of the pupils in P7 like science, 2/3 of the remainder like mathematics. The rest of the pupils like English. If those who like English are 33, find the total number of pupil in P.7",
                "type": "multipleChoice",
                "answer": "132 Pupils",
                "explanation": "Students that like science = 1/4<br> Remainder = 1 - 1/4<br> = 4/4 - 1/4<br> = 3/4<br> Mathematics = 2/3 of 3/4<br> = 1.2<br> Mathematics and Science = 1/2 + 1/4<br> = (2 + 1)/4<br> = 3/4<br> Rest = 1 - 3/4<br> = (4 - 3)/4<br> = 1/4<br> Let the total number pupils be Q<br> 1/4 of Q = 33<br> 1/4 x Q = 33<br> Multiply through by 4<br> 4 x 1/4Q = 33 x 4<br> Q = 132",
                "options": [
                    "130 Pupils",
                    "120 Pupils",
                    "132 Pupils"
                ],
                "mark": 5,
                "id": "ple_math_sect_B27_8"
            },
            {
                "question": "In the figure below, A is the centre of the circle of circumference 88 metres. Find the area of the circle that is not shaded (Use π = 22/7)",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/paper_1/27.png",
                "type": "multipleChoice",
                "answer": "Area of unshaded part = 420 m<sup>2</sup>",
                "explanation": "Circumference = 2πr<br> 2πr = 88<br> 2 x 22/7 x r = 88<br> 44r = 88 x 7<br> r = 2 x 7<br> = 14 m<br> Area of triangle = 1/2 x b x h<br> = 1/2 x 28 x 14<br> = 14 x 14<br> = 196 m<sup>2</sup><br> Area of circle = πr<sup>2</sup><br> = 22/7 x 14 x 14<br> = 44 x 14<br> = 616 m<sup>2</sup><br> Area of unshaded = Area of circle - Area of triangle<br> = 616 m<sup>2</sup> - 196 m<sup>2</sup><br> = 420 m<sup>2</sup>",
                "options": [
                    "Area of unshaded part = 616 m<sup>2</sup>",
                    "Area of unshaded part = 420 m<sup>2</sup>",
                    "Area of unshaded part = 196 m<sup>2</sup>"
                ],
                "mark": 6,
                "id": "ple_math_sect_B27_9"
            }
        ],
        "$id": "document_26"
    },
    {
        "category": 28,
        "questions": [
            {
                "question": "The table below shows the marks obtained by some pupils in a test. If the mean mark of the pupils was 55, find the value of m.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/28.png",
                "type": "multipleChoice",
                "answer": "m = 50",
                "explanation": "Mean = Sum/Number<br> ((40x2)+(mx6)+(60x3)+(10x3))/(2+6+3+3) = 55<br> (80+6m+180+210)/14 = 55<br> (6m + 470)/14 = 55<br> 14 x (6m + 470)/14 = 55 x 14<br>  6m + 470 = 770<br> 6m + 470 - 470 = 770 - 470<br> 6m = 300<br> m = 50",
                "options": [
                    "m = 77",
                    "m = 50",
                    "m = 60"
                ],
                "mark": 4,
                "id": "ple_math_sect_B28_1"
            },
            {
                "question": "The interior angle of a regular polygon is 108<sup>o</sup> more than the exterior angle. How many sides has the polygon?",
                "type": "multipleChoice",
                "answer": "10 sides",
                "explanation": "Let the exterior angle be y<br> Exterior = y<br> Interior = y + 108°<br>  y + y + 108° = 180° (Interior and exterior angles add up to 180°)<br> 2y + 108° = 180°<br> 2y + 108°- 108° = 180° - 108°<br> 2y = 72°<br> y = 36°<br> Number of sides = sum of exterior angles/one exterior angle<br> = 360/60<br> = 10 sides",
                "options": [
                    "8 sides",
                    "10 sides",
                    "5 sides"
                ],
                "mark": 3,
                "sub_questions": [
                    {
                        "question": "In the figure below, find the size of angle z.",
                        "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2018/28.png",
                        "type": "multipleChoice",
                        "answer": "z = 65<sup>o</sup>",
                        "explanation": "z + 60 + 55 = 180<br> z + 115 = 180<br> z + 115 - 115 = 180 - 115<br> z = 65",
                        "options": [
                            "z = 65<sup>o</sup>",
                            "z = 60<sup>o</sup>",
                            "z = 55<sup>o</sup>"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B28_2"
            },
            {
                "question": "Which of the following diagrams show a quadrilateral ABCD where line AB = 7cm, angle ABC = BAD = 60<sup>o</sup> and AD = BC = 3.5cm",
                "type": "multipleChoice",
                "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2017/27.png",
                "options": [
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2017/27_1.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2017/27.png"
                ],
                "mark": 6,
                "id": "ple_math_sect_B28_3"
            },
            {
                "question": "Hajati bought 120 shares from a village SACCO at a simple interest rate of 30% per year. Each share costs sh 3,000.",
                "sub_questions": [
                    {
                        "question": "Find her total interest after 3½ years",
                        "type": "multipleChoice",
                        "answer": "Total interest = 378,000",
                        "explanation": "Interest = Principal x Rate x Time<br> = (3000 x 120) x 30/100 x 3½<br> = 360000 x 30/100 x 3.5<br> = 3600 x 30 x 35/10<br> = 360 x 30 x 35<br> = 378,000",
                        "options": [
                            "Total interest = 3,150",
                            "Total interest = 378,000",
                            "Total interest = 37,800"
                        ],
                        "mark": 3
                    },
                    {
                        "question": "Calculate the total amount of money Hajat has in the SACCO after the 3½ years.",
                        "type": "multipleChoice",
                        "answer": "Sh 738,000",
                        "explanation": "Total Amount = P + SI<br> = 360,000 + 378,000<br> = 738,000",
                        "options": [
                            "Sh 73,800",
                            "Sh 738,000",
                            "Sh 648,000"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B28_4"
            },
            {
                "question": "Madada sold his radio to Aguti at 63,000 making a loss of 10%. Aguti later sold the radio to chebet at a profit of 15%.",
                "sub_questions": [
                    {
                        "question": "Calculate the amount of money Madada paid for the radio.",
                        "type": "multipleChoice",
                        "answer": "Madada paid 70,000",
                        "explanation": "Madada to aguti selling price = shs 63,000<br> new % = 100% - 10%<br> = 90% of c.p<br> Let cost price be k<br> 90/100 of k = 63,000<br> 9/10k = 63,000<br> 9/10k x 10 = 63,000 x 10<br> 9k = 630,000<br> k = 70,000",
                        "options": [
                            "Madada paid 80,400",
                            "Madada paid 80,000",
                            "Madada paid 70,000"
                        ],
                        "mark": 3,
                        "id": "ple_math_sect_B28_5_sub_0"
                    },
                    {
                        "question": "For how much money did Aguti sell the radio?",
                        "type": "multipleChoice",
                        "answer": "Selling price = 72,450",
                        "explanation": "Aguti to chebet cost price = sh. 63,000<br> New% = 100%+ 15%<br> = 115% of cost price<br> Selling price = 115/100 x 63000<br> = 115 x 630<br> = shs 72,450",
                        "options": [
                            "Selling price = 72,450",
                            "Selling price = 82,450",
                            "Selling price = 80,450"
                        ],
                        "mark": 2,
                        "id": "ple_math_sect_B28_5_sub_1"
                    }
                ],
                "id": "ple_math_sect_B28_5"
            },
            {
                "question": "The exchange rate of Kenya shillings (Ksh) to Uganda shillings (Ug.sh) and the United states dollars (US$) to Uganda shillings are shown below<br> 1 Ksh = Ug.sh 30<br> US $1 = Ug.shs 2,580",
                "sub_questions": [
                    {
                        "question": "How many United States dollars will one get from 21,500 kenya shillings?",
                        "type": "multipleChoice",
                        "answer": "US$ 250",
                        "explanation": "Convert Ksh 21,500 to Ug.sh<br> Ksh 21,500 = (21,500 x 30)<br> = Ug.sh 645,000<br> Convert Ug.sh 645,000 to US$<br> = 645,000/2,580<br> = 64,500/258<br> = US$ 250",
                        "options": [
                            "US$ 2500",
                            "US$ 25",
                            "US$ 250"
                        ],
                        "mark": 3
                    },
                    {
                        "question": "If the cost of a new bicycle is 90 United States dollars, how much would this be in Uganda shillings?",
                        "type": "multipleChoice",
                        "answer": "Ug.sh 232,200",
                        "explanation": "US$ 90 = (90 x 2,580)<br> = Ug.sh 232,200",
                        "options": [
                            "Ug.sh 28.67",
                            "Ug.sh 232,200",
                            "Ug.sh 232,000"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B28_6"
            },
            {
                "question": "A tank was 2/3 full of water. When ¼ of the water in the tank was drawn, 2,500 litres remained. Find the capacity of the tank when full.",
                "type": "multipleChoice",
                "answer": "5000 litres",
                "explanation": "Fraction in tank = 2/3<br> Fraction drown = 1/4 of 2/3<br> (1 x 2)/(4 x 3)<br> = 1/6<br> Remaining fraction = 2/3 - 1/6<br> LCM = 6<br> ((2 x 2)x(1 x 1))/6<br> = (4 - 1)/6<br> = 1/2<br> Let capacity of full tank be g<br> 1/2 of g = 2500<br> 1/2g = 2500<br> g = 2 x 2500<br> = 5000 litres",
                "options": [
                    "6000 litres",
                    "5000 litres",
                    "10000 litres"
                ],
                "mark": 4,
                "id": "ple_math_sect_B28_7"
            },
            {
                "question": "Change13<sub>ten</sub> to base two",
                "type": "multipleChoice",
                "answer": "13<sub>ten</sub> = 1101<sub>two</sub>",
                "explanation": "13 ÷ 2 = 6 remainder 1<br> 6 ÷ 2 = 3 remainder 0<br> 3 ÷ 2 = 1 remainder 1<br> We get the remainders;<br> = 1101<sub>two</sub>",
                "options": [
                    "13<sub>ten</sub> = 110<sub>two</sub>",
                    "13<sub>ten</sub> = 1101<sub>two</sub>",
                    "13<sub>ten</sub> = 1111<sub>two</sub>"
                ],
                "mark": 2,
                "sub_questions": [
                    {
                        "question": "Find the number which has been expanded below<br> (5 x 10<sup>5</sup>) + (4 x10<sup>3</sup>) + (9 x 10<sup>0</sup>)",
                        "type": "multipleChoice",
                        "answer": "504,009",
                        "explanation": "(5 x 100000 + 4 x 1000 + 9)<br> = 504,009",
                        "options": [
                            "504,000",
                            "502,009",
                            "504,009"
                        ],
                        "mark": 3
                    }
                ],
                "id": "ple_math_sect_B28_8"
            },
            {
                "question": "Abdul paid sh. 12,600 for a shirt whose marked price was sh. 14,000.",
                "sub_questions": [
                    {
                        "question": "Calculate the discount he was offered",
                        "type": "multipleChoice",
                        "answer": "Discount = 1,400",
                        "explanation": "Discount = New price - old price<br> = 141,000 - 12,600<br> = 1,400",
                        "options": [
                            "Discount = 1,000",
                            "Discount = 2,400",
                            "Discount = 1,400"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "If Abdul was also offered the same percentage discount on a pair of trousers whose marked price was sh. 20,000, how much did he pay for the pair of trousers?",
                        "type": "multipleChoice",
                        "answer": "New price = 18,000",
                        "explanation": "Percentage discount = Discount/Marked price x 100<br> = 1400/14000 x 100<br> = 10%<br> 100% - 10%<br> = 90%<br> 90/100 x 20,000<br> = 18,000",
                        "options": [
                            "New price = 12,000",
                            "New price = 18,000",
                            "New price = 20,000"
                        ],
                        "mark": 4
                    }
                ],
                "id": "ple_math_sect_B28_9"
            },
            {
                "question": "Mugisha is twice as old as Mugume. In 10 years time, the difference in their age will be 18 years. How old is Mugume?",
                "type": "multipleChoice",
                "answer": "18 years old",
                "explanation": "Mugume's age now: k<br> Mugume's age in 10 years: k + 10<br> Mugisha's age now: 2k<br> Mugisha's age in 10 years: 2k+10<br> Mugisha - Mugume = 18<br> (2k+10) - (k+10) = 18<br> 2k+10-k-10 = 18<br> k = 18",
                "options": [
                    "20 years old",
                    "16 years old",
                    "18 years old"
                ],
                "mark": 2,
                "sub_questions": [
                    {
                        "question": "Find their total age",
                        "type": "multipleChoice",
                        "answer": "Total age = 54 years",
                        "explanation": "Mugisha = 2 x k<br> = 2x18<br> = 36 years<br> Total = 36+18<br> = 54 years",
                        "options": [
                            "Total age = 50 years",
                            "Total age = 44 years",
                            "Total age = 54 years"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B28_10"
            }
        ],
        "$id": "document_27"
    },
    {
        "category": 29,
        "questions": [
            {
                "question": "The number of goats, cows and sheep on a certain farm are in the ratio of 4:3:5 respectively. There are 40 more sheep than goats on the farm. Find the number of each type of animal on the farm.",
                "type": "multipleChoice",
                "answer": "Number of goats = 160, Number of cows = 120, Number of sheep = 200",
                "explanation": "Use a common multiplier x<br> ∴ Goats = 4x, Cows = 3x and Sheep = 5x<br> Since there are 40 more sheep than goats<br> 5x = 4x + 40<br> Solve for x<br> 5x - 4x = 4x - 4x + 40<br> x = 40<br> ∴ Number of goats = 4x = 4 x 40 = 160<br> Number of cows  = 3x = 3 x 40 = 120<br> Number of sheep = 5x = 5 x 40 = 200",
                "options": [
                    "Number of goats = 120, Number of cows = 200, Number of sheep = 160",
                    "Number of goats = 200, Number of cows = 160, Number of sheep = 120",
                    "Number of goats = 160, Number of cows = 120, Number of sheep = 200"
                ],
                "mark": 5,
                "id": "ple_math_sect_B29_1"
            },
            {
                "question": "Joyce, Peter and Hannah shared pencils in the ratio 3:5:7 respectively.",
                "sub_questions": [
                    {
                        "question": "If Hannah got 12 more pencils than Joyce, how many pencils did they share altogether?",
                        "type": "multipleChoice",
                        "answer": "45 pencils",
                        "explanation": "Lets use a common multiplier x<br> ∴ The ratios are 3x:5x:7x<br> Since Hannah got 12 more pencils than Joyce<br> 7x = 3x + 12<br> 7x - 3x = 3x - 3x + 12<br> 4x = 12<br> 4x/4 = 12/4<br> x = 3<br> Now that we have the value of x, we can find the number of pencils each person got<br> Joyce: 3x = 3 x 3 = 9 pencils<br> Peter: 5x = 5 x 3 = 15 pencils<br> Hannah: 7x = 7 x 3 = 21 pencils<br> Total number of pencils;<br> = 9 + 15 + 21<br> = 45",
                        "options": [
                            "45 pencils",
                            "40 pencils",
                            "35 pencils"
                        ],
                        "mark": 4,
                        "sub_questions": [
                            {
                                "question": "Find the number of pencils Peter got.",
                                "type": "multipleChoice",
                                "answer": "15 pencils",
                                "options": [
                                    "9 pencils",
                                    "15 pencils",
                                    "21 pencils"
                                ],
                                "mark": 2
                            }
                        ]
                    }
                ],
                "id": "ple_math_sect_B29_2"
            },
            {
                "question": "The total mass of tins of honey in a box is 3.25kg. The mass of each tin is 250g. Find the number of tins in the box.",
                "type": "multipleChoice",
                "answer": "13 tins",
                "explanation": "Total mass = 3.25kg<br> Mass of each tin = 250g<br> Convert mass of each tin to kg<br> Since 1 g = 1/1000 kg<br> = (250 x 1/1000)kg<br> = 0.25kg<br> Number of tins  = 3.25/0.25<br> = 13 tins",
                "options": [
                    "12 tins",
                    "10 tins",
                    "13 tins"
                ],
                "mark": 4,
                "id": "ple_math_sect_B29_3"
            },
            {
                "question": "The pie chart below shows how pupils of Mpaata Primary School are distributed in various clubs in the school. Use it to answer the questions that follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/29.png",
                "sub_questions": [
                    {
                        "question": "There are 216 pupils in the debating club. Find the total number of pupils in the school",
                        "type": "multipleChoice",
                        "answer": "1080 pupils",
                        "explanation": "p + 2p + 90° + 54° = 360°<br> 3p + 144° = 360°<br> 3p + 144° - 144° = 360° - 144°<br> 3p = 216<br> p = 72°<br> 72° = 216 pupils<br> 1° = 216/72<br> 1° = 3<br> 360° = 360 x 3<br> = 1080 pupils",
                        "options": [
                            "180 pupils",
                            "1080 pupils",
                            "108 pupils"
                        ],
                        "mark": 4
                    },
                    {
                        "question": "Express the number of pupils in the debating club as a percentage of the whole school.",
                        "type": "multipleChoice",
                        "answer": "20%",
                        "explanation": "Percentage for debating = (72/360 x 100)%<br> = (2 x 10)%<br> = 20%",
                        "options": [
                            "20%",
                            "30%",
                            "40%"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B29_4"
            },
            {
                "question": "Study the figure below and use it to answer the questions that follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2015/29.png",
                "sub_questions": [
                    {
                        "question": "Calculate the area of the figure",
                        "type": "multipleChoice",
                        "answer": "Area = 69 cm<sup>2</sup>",
                        "explanation": "Area of triangle = 1/2 x b x h<br> = 1/2 x 3 x 4<br> = 6 cm<sup>2</sup><br> Area of rectangle = L x W<br> = 7 x 9<br> = 63 cm<sup>2</sup><br> Total area = 63 + 6<br> = 69 cm<sup>2</sup>",
                        "options": [
                            "Area = 64 cm<sup>2</sup>",
                            "Area = 69 cm<sup>2</sup>",
                            "Area = 70 cm<sup>2</sup>"
                        ],
                        "mark": 3
                    },
                    {
                        "question": "Work out the perimeter of the figure",
                        "type": "multipleChoice",
                        "answer": "Perimeter = 37cm",
                        "explanation": "Hypotenuse = 3<sup>2</sup> + 4<sup>2</sup><br> = 9 + 16<br> = 25 cm<br> Perimeter = 10 + 9 + 7 + 5 + 5<br> = 37 cm",
                        "options": [
                            "Perimeter = 32cm",
                            "Perimeter = 37cm",
                            "Perimeter = 35cm"
                        ],
                        "mark": 3
                    }
                ],
                "id": "ple_math_sect_B29_5"
            },
            {
                "question": "At a kampala bus park, buses travelling to Arua and Mbarara leave after every 40 minutes and 50 minutes respectively. The first buses to the two towns leave together at 6:00 am. At what time will buses to the two towns leave Kampala together again?",
                "type": "multipleChoice",
                "answer": "9:20 a.m",
                "explanation": "LCM of 40 and 50<br> = 2 x 2 x 2 x 5 x 5<br> = 200 minutes<br> They will leave together after 200 minutes or 3hrs 20mins<br> 200 minutes = 200/60<br> = 3⅓ hours<br> = 3hrs and 20minutes<br> = 6:00 a.m  + 3:20<br> = 9:20 a.m",
                "options": [
                    "10:20 a.m",
                    "8:20 a.m",
                    "9:20 a.m"
                ],
                "mark": 4,
                "id": "ple_math_sect_B29_6"
            },
            {
                "question": "Opoka rides a distance of 2.97 km from his home to school on a bicycle. The wheel of the bicycle has a diameter of 63 cm.",
                "sub_questions": [
                    {
                        "question": "How many revolutions does the wheel make to cover the distance? (Take π = 22/7}",
                        "type": "multipleChoice",
                        "answer": "1500 revolutions",
                        "explanation": "Change km to cm<br> 2.97km to cm = 2.97 x 100000<br> Distance = 297000 cm<br> Circumference of the wheel = πd<br> C = 22/7 x 63<br> = 22 x 9<br> = 198 cm<br> Number of revolutions = distance/circumference<br> = 297000/198<br> = 1500 revolutions",
                        "options": [
                            "1500 revolutions",
                            "1000 revolutions",
                            "2000 revolutions"
                        ],
                        "mark": 3
                    },
                    {
                        "question": "If Opoka makes 50 revolutions in one minute, how long does he take to reach the school?",
                        "type": "multipleChoice",
                        "answer": "30 minutes",
                        "explanation": "50 revolutions = 1 minute<br> 1 rev = 1/50 minutes<br> 1500 revolutions = 1/50 x 1500<br> = 30 minutes",
                        "options": [
                            "25 minutes",
                            "40 minutes",
                            "30 minutes"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B29_7"
            },
            {
                "question": "Carefully study the diagram below and use it to answer the questions that follow. Line AB = OC and AO = OD = BC.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2012/29.png",
                "sub_questions": [
                    {
                        "question": "Find the length of arc AD. (Take π = 22/7) ",
                        "type": "multipleChoice",
                        "answer": "AD = 11 cm",
                        "explanation": "Diameter = 7 + 7<br> D = 14cm<br> Length AD = 1/4πD<br> = 1/4 x 22/7 x 14<br> = 11 cm",
                        "options": [
                            "AD = 9 cm",
                            "AD = 10 cm",
                            "AD = 11 cm"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "Work out perimeter of ABCDA",
                        "type": "multipleChoice",
                        "answer": "Perimeter = 61 cm",
                        "explanation": "Perimeter = (11 + 18 + 25 + 7)<br> = 61",
                        "options": [
                            "Perimeter = 64 cm",
                            "Perimeter = 61 cm",
                            "Perimeter = 60 cm"
                        ],
                        "mark": 3
                    }
                ],
                "id": "ple_math_sect_B29_8"
            },
            {
                "question": "The diagrams below represent two different containers which hold the same amount of water when completely full. Container A measures 40cm by 10cm by 30cm",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/paper_1/29.png",
                "sub_questions": [
                    {
                        "question": "Calculate the number of litres of water that fill container A",
                        "type": "multipleChoice",
                        "answer": "12 litres",
                        "explanation": "Volume = L x w x h<br> = (40x10) x 30<br> = 400 x 30<br>  = 12000 cm<sup>3</sup><br> Capacity = Volume/1000<br> = 12000/1000<br> = 12 litres",
                        "options": [
                            "8 litres",
                            "10 litres",
                            "12 litres"
                        ],
                        "mark": 3
                    },
                    {
                        "question": "Calculate the height (h) of container B if it has a base area of 200 cm<sup>2</sup>",
                        "type": "multipleChoice",
                        "answer": "Height = 60 cm",
                        "explanation": "Base area x height = volume<br> 200 x h = 12000<br> 200h = 12000<br> = 60 cm",
                        "options": [
                            "Height = 50 cm",
                            "Height = 60 cm",
                            "Height = 100 cm"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B29_9"
            }
        ],
        "$id": "document_28"
    },
    {
        "category": 30,
        "questions": [
            {
                "question": "In the parallelogram ABCD below, lines DX and DY are perpendicular to AB and BC respectively. Line AB = 16cm, BC = 12cm and DX = 6cm.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/30.png",
                "sub_questions": [
                    {
                        "question": "Calculate the area of the parallelogram.",
                        "type": "multipleChoice",
                        "answer": "Area = 96cm<sup>2</sup>",
                        "explanation": "Area = Base x Height<br> = AB x DX<br> = 16cm x 6cm<br> = 96cm<sup>2</sup>",
                        "options": [
                            "Area = 192cm<sup>2</sup>",
                            "Area = 96cm<sup>2</sup>",
                            "Area = 72cm<sup>2</sup>"
                        ],
                        "mark": 2,
                        "id": "ple_math_sect_B30_1_sub_0"
                    },
                    {
                        "question": "Find the length of DY",
                        "type": "multipleChoice",
                        "answer": "DY = 8cm",
                        "explanation": "B x h = B x h (Find DY by comparing areas using different heights)<br> 12 x h = 16 x 6<br> 12h = 96<br> h = 8 cm<br> Therefore DY = 8cm",
                        "options": [
                            "DY = 10cm",
                            "DY = 8cm",
                            "DY = 6cm"
                        ],
                        "mark": 3,
                        "id": "ple_math_sect_B30_1_sub_1"
                    }
                ],
                "id": "ple_math_sect_B30_1"
            },
            {
                "question": "Kizito is 38 years old and his sister is 24 years old.",
                "sub_questions": [
                    {
                        "question": "How many years ago was Kizito three times as old as his sister?",
                        "type": "multipleChoice",
                        "answer": "17 years ago",
                        "explanation": "∴ x years ago Kizito was 38 - x and his sister 24 - x<br> Since x years ago Kizito was three times as old as his sister<br> 38 - x = 3(24 - x)<br> 38 - x = 72 - 3x<br> 38 - 38 - x + 3x = 72 - 38 - 3x + 3x<br> - x + 3x = 72 - 34<br> 2x = 34<br> 2x/2 = 34/2<br> x = 17",
                        "options": [
                            "19 years ago",
                            "17 years ago",
                            "20 years ago"
                        ],
                        "mark": 4
                    },
                    {
                        "question": "How old was Kizito's sister then?",
                        "type": "multipleChoice",
                        "answer": "7 years old",
                        "explanation": "From the above explanation<br> 24 - x<br> = 24 - 17<br> = 7",
                        "options": [
                            "24 years old",
                            "7 years old",
                            "19 years old"
                        ]
                    }
                ],
                "id": "ple_math_sect_B30_2"
            },
            {
                "question": "The diagram below shows a square BCDE enclosed in a circle with centre O and radius 14cm. Parts of the circle are shaded as shown. Study the diagram and use it to answer the questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2017/30.png",
                "sub_questions": [
                    {
                        "question": "Calculate the area of the circle. (Use π = 22/7)",
                        "type": "multipleChoice",
                        "answer": "Area = 616 cm<sup>2</sup>",
                        "explanation": "Area = π x (radius)cm<sup>2</sup><br> = 22/7 x 14cm<sup>2</sup><br> = 22/7 x 14 x 14<br> = 22 x 2 x 14<br> = 616 cm<sup>2</sup>",
                        "options": [
                            "Area = 88 cm<sup>2</sup>",
                            "Area = 44 cm<sup>2</sup>",
                            "Area = 616 cm<sup>2</sup>"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "Find the area of the shaded part.",
                        "type": "multipleChoice",
                        "answer": "Area of shaded part = 224 cm<sup>2</sup>",
                        "explanation": "Area of the shaded part  = Area of circle - Area of square area  of square<br> Area of square;<br> d<sub>1</sub> = 14 + 14<br> d<sub>1</sub> = 28 cm<br> d<sub>2</sub> = 28 cm<br> = 1/2 x d<sub>1</sub> x d<sub>2</sub><br> = 1/2 x 28 x 28<br> = 14 x 28<br> = 392 cm<sub>2</sub><br> Shaded area = 616 cm<sub>2</sub> - 392 cm<sub>2</sub><br> = 224 cm<sub>2</sub>",
                        "options": [
                            "Area of shaded part = 124 cm<sup>2</sup>",
                            "Area of shaded part = 224 cm<sup>2</sup>",
                            "Area of shaded part = 120 cm<sup>2</sup>"
                        ],
                        "mark": 4
                    }
                ],
                "id": "ple_math_sect_B30_3"
            },
            {
                "question": "A cylindrical tank of diameter 70cm contains water to a height of 100 cm. Find in litres the amount of water the tank contains (Use π = 22/7)",
                "type": "multipleChoice",
                "answer": "Volume = 11 litres",
                "explanation": "V = πr<sup>2</sup>h<br> = 22/7 x 70/2 x 100<br> = 11 x 10 x 100<br> = 11000 cm<sup>3</sup><br> Convert cm<sup>3</sup> to litres<br> 1 cm<sup>3</sup> = (1/1000) litres<br> 11000 cm<sup>3</sup> = (1/1000 x 11000)<br> = 11 litres",
                "options": [
                    "Volume = 11000 litres",
                    "Volume = 110 litres",
                    "Volume = 11 litres"
                ],
                "mark": 4,
                "id": "ple_math_sect_B30_4"
            },
            {
                "question": "Two taps F and E are connected to the water tank. Tap F can fill the tank in 2 hours while E can empty it in 3 hours. One day when the tank was 1/3 full of water, the taps were opened at the same time. How long did it take to fill the tank?",
                "type": "multipleChoice",
                "answer": "4 hours",
                "explanation": "In one hour;<br> Tap F fills = ½ of the tank<br> Tap E empties = ⅓ of the tank<br> Both taps open;<br> 1/2 - 1/3<br> LCM = 6<br> (3-2)/6<br> Whole tank = 1 ÷ 1/6<br> = 6<br> 6 hours to fill the tank<br> = 1/3 x 6<br> = 2 hours<br> Remaining time to fill the tank = (6-2) hour<br> = 4 hours",
                "options": [
                    "2 hours",
                    "6 hours",
                    "4 hours"
                ],
                "mark": 5,
                "id": "ple_math_sect_B30_5"
            },
            {
                "question": "The mean of the numbers 7, 9, 5, x+2 and 6 is 8. Find the value of x.",
                "type": "multipleChoice",
                "answer": "x = 11",
                "explanation": "Mean = (7 + 9 + 5 + x+2 + 6)/5<br> 8 = (7 + 9 + 5 + x+2 + 6)/5<br> (29 + x)/5 = 8<br> 29 + x = 40<br> 29 - 29 + x = 40 - 29<br> x = 11",
                "options": [
                    "x = 9",
                    "x = 10",
                    "x = 11"
                ],
                "mark": 3,
                "sub_questions": [
                    {
                        "question": "In a bag there are 15 pens. Out of these 4 are red and the rest blue. What is the probability that a pen picked at random from the bag is blue?",
                        "type": "multipleChoice",
                        "answer": "Probability = 11/15",
                        "explanation": "Blue pens = 15 - 4<br> = 11 pens<br> Probability = Number of blue pens/Total number of pens<br> = 11/15",
                        "options": [
                            "Probability = 4/15",
                            "Probability = 11/15",
                            "Probability = 1/2"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B30_6"
            },
            {
                "question": "In the figure below, BCD is a straight line. Line BX bisects angle ABC. Line AB is parallel to line XC. Angle BCE = 50<sup>o</sup> and angle BAC = 70<sup>o</sup>.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2013/30.png",
                "sub_questions": [
                    {
                        "question": "Find the size of the angle CEX",
                        "type": "multipleChoice",
                        "answer": "Angle CEX  = 80<sup>o</sup>",
                        "explanation": "Considering triangle ABC<br> a + a + 50 + 70 = 180 (angle sum of a triangle<br> 2a + 120 = 180<br> 2a = 60<br> a = 30<br> Using triangle BAE<br> a + ∠BEA + 70 = 180<br> 30 + ∠BEA + 70 = 180<br> ∠BEA = 80<sup>o</sup><br> But ∠BEA = ∠CEX<br> Therefore ∠CEX = 80<sup>o</sup>)",
                        "options": [
                            "Angle CEX  = 30<sup>o</sup>",
                            "Angle CEX  = 40<sup>o</sup>",
                            "Angle CEX  = 80<sup>o</sup>"
                        ],
                        "mark": 4
                    },
                    {
                        "question": "Find the size of the angle DCX",
                        "type": "multipleChoice",
                        "answer": "Angle DCX = 60<sup>o</sup>",
                        "explanation": " Angel XCA = angle BAC = 70<sup>o</sup> (alternative angles)<br> Angle BCA + angle ACX + angle DCX = 180<sup>o</sup> (angle sum on a straight line)<br> 50 + 70 + ∠DCX = 180<br> ∠DCX = 60<sup>o</sup>",
                        "options": [
                            "Angle DCX = 30<sup>o</sup>",
                            "Angle DCX = 60<sup>o</sup>",
                            "Angle DCX = 75<sup>o</sup>"
                        ],
                        "mark": 1
                    }
                ],
                "id": "ple_math_sect_B30_7"
            },
            {
                "question": "Which of the following images show the correct graph with points A(-2, +3); B(+5, +3); C(-2, -1) and D(+1, -1) with the points joined A to B, B to D, D to C and C to A.",
                "type": "multipleChoice",
                "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2012/30C.png",
                "options": [
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2012/30a_1.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2012/30W.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2012/30C.png"
                ],
                "mark": 5,
                "sub_questions": [
                    {
                        "question": "Name the quadrilateral formed after joining the points",
                        "type": "text",
                        "answer": "Trapezium",
                        "mark": 1
                    }
                ],
                "id": "ple_math_sect_B30_8"
            },
            {
                "question": "A motorist drove a car for 240 minutes covering a distance of 360 km. Calculate the motorist`s average speed in metres per second",
                "type": "multipleChoice",
                "answer": "Speed = 25 m/sec",
                "explanation": "Time = 240/60<br> = 4 hours<br> Distance:<br> 1 km = 1000 m<br> 360 km = 360 x 1000<br> = 360,000 m<br> Time: 1 hour = 3600 seconds<br> 4 hrs = 3600 x 4<br> = 14400 secs<br> Speed = Distance/Time<br> = 360000/14400<br> = 25m/sec",
                "options": [
                    "Speed = 35 m/sec",
                    "Speed = 30 m/sec",
                    "Speed = 25 m/sec"
                ],
                "mark": 4,
                "id": "ple_math_sect_B30_9"
            },
            {
                "question": "A teacher gave a test of 20 questions. He awarded 4 marks for every correct question and deducted a mark for every wrong question. If a pupil got 15 correct questions, What mark did the pupil get?",
                "type": "multipleChoice",
                "answer": "55 marks",
                "explanation": "Correct = 15<br> Wrong = (20-15)<br> = 5 questions<br> Mark = correct - wrong<br>  = (15x4) - (5x1)<br> = 60 - 5<br> = 55",
                "options": [
                    "35 marks",
                    "55 marks",
                    "45 marks"
                ],
                "mark": 2,
                "sub_questions": [
                    {
                        "question": "If the pupil got 64 marks, how many questions did he get correct?",
                        "type": "multipleChoice",
                        "answer": "14 questions",
                        "explanation": "Correct = k<br> Wrong = 20 - k<br> correct(5xk) = 5k<br> wrong 1(20-k) =(20-k)<br> correct - wrong = marks<br> 5k - (20-k) = 64<br> 5k - 20 + k = 64<br> 5k + k - 20 = 64<br> 6k - 20 + 20 = 64 + 20<br> 6k = 84<br> k = 14",
                        "options": [
                            "12 questions",
                            "14 questions",
                            "18 questions"
                        ],
                        "mark": 3
                    }
                ],
                "id": "ple_math_sect_B30_10"
            }
        ],
        "$id": "document_29"
    },
    {
        "category": 4,
        "questions": [
            {
                "question": "In the venn diagram, shade the region (S u T)'",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/Symbol%201.png",
                "type": "multipleChoice",
                "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/2.png",
                "options": [
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/3.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/2.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/4.png"
                ],
                "mark": 2,
                "id": "ple_math_sect_A4_1"
            },
            {
                "question": "A teacher counted pupils without school uniforms in a class and tallied them as follows: How many pupils were without uniform?",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2018/4.png",
                "type": "text",
                "answer": [
                    "24 pupils",
                    "24"
                ],
                "mark": 2,
                "id": "ple_math_sect_A4_2"
            },
            {
                "question": "Find the next number in the sequence: -11, -8, -5, -2, ....",
                "type": "multipleChoice",
                "answer": "1",
                "explanation": "3 is added to the next number in the sequence",
                "options": [
                    "1",
                    "-5",
                    "-1"
                ],
                "mark": 2,
                "id": "ple_math_sect_A4_3"
            },
            {
                "question": "Use the venn diagram below to find n(P∩Q)",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/4.png",
                "type": "multipleChoice",
                "answer": "n(P∩Q) = 2",
                "options": [
                    "n(P∩Q) = {a,b}",
                    "n(P∩Q) = 2",
                    "n(P∩Q) = 3"
                ],
                "mark": 2,
                "id": "ple_math_sect_A4_4"
            },
            {
                "question": "Given that K = {g,m,v,z}, find the numbers of subsets in set K.",
                "type": "multipleChoice",
                "answer": "4 subsets",
                "explanation": "Subsets of K are;<br> {g}, {m}, {v} and {z}<br> which are 4 in total",
                "options": [
                    "9 subsets",
                    "6 subsets",
                    "4 subsets"
                ],
                "mark": 2,
                "id": "ple_math_sect_A4_5"
            },
            {
                "question": "Work out : 1/6 x 3/4",
                "type": "multipleChoice",
                "answer": "1/8",
                "explanation": "= 1/2 x 1/4<br> = 1/8",
                "options": [
                    "1/24",
                    "1/8",
                    "1/4"
                ],
                "mark": 2,
                "id": "ple_math_sect_A4_6"
            },
            {
                "question": "Given that set P = {1, 2, 3, 5, 7, 9 } and set Q = {2, 3, 5, 7} find n(P∩Q)",
                "type": "multipleChoice",
                "answer": "n(P n Q) = 4",
                "explanation": "P ∩ Q = {2, 3, 5, 7}<br> n (P n Q) = 4",
                "options": [
                    "n(P n Q) = 3",
                    "n(P n Q) = 6",
                    "n(P n Q) = 4"
                ],
                "mark": 2,
                "id": "ple_math_sect_A4_7"
            },
            {
                "question": "Solve 2/5m = 4",
                "type": "multipleChoice",
                "answer": "m = 10",
                "explanation": "2/5m = 4<br> Multiply by 5 on both sides<br> = 5 x 2/5m = 4 x 5<br> 2m = 20<br> m = 10",
                "options": [
                    "m = 6",
                    "m = 10",
                    "m = 8"
                ],
                "mark": 2,
                "id": "ple_math_sect_A4_8"
            },
            {
                "question": "Which of the following numbers is divisible by 6 in the list below<br> 3616, 1722, 5814, 6316",
                "type": "multipleChoice",
                "answer": "1722 and 5814 are divisible by 6",
                "explanation": "3616 = 3+6+1+6 = 16 Not divisible by 6<br> 1722 = 1+7+2+2=12 Divisible by 6<br> 5814 = 5+8+1+4=18 Divisible by 6<br> 6316 = 6+3+1+6=16 Not divisible by 6",
                "options": [
                    "1722 and 5814 are divisible by 6",
                    "6316 and 5814 are divisible by 6",
                    "1722 and 3616 are divisible by 6"
                ],
                "mark": 2,
                "id": "ple_math_sect_A4_9"
            },
            {
                "question": "Find the lowest common multiple (LCM) of 12 and 18",
                "type": "multipleChoice",
                "answer": "LCM = 36",
                "explanation": "L C M = (2 x 2) x 3 x 3<br>  = 4 X 9<br> = 36",
                "options": [
                    "LCM = 30",
                    "LCM = 36",
                    "LCM = 18"
                ],
                "mark": 2,
                "id": "ple_math_sect_A4_10"
            }
        ],
        "$id": "document_3"
    },
    {
        "category": 31,
        "questions": [
            {
                "question": "In a market, the cost of a pawpaw is sh 800 more than the cost of a mango. A mango costs two thirds of the cost of a pineapple. The total cost of the three fruits is sh 4,300. Calculate the cost of a pineapple.",
                "type": "multipleChoice",
                "answer": "Cost of a pineapple is sh 1,500",
                "explanation": "Let cost of a pineapple be k<br> Pineapple = k<br> Mango = 2/3k<br> Pawpaw = 2/3k + 800<br> k + 2/3k + 2/3k + 800 = 4300<br> k + 2/3k + 2/3k + 800 - 800 = 4300 - 800<br> k + 2/3k + 2/3k = 3500<br> Multiply through by 3<br> 3k + 2k + 2k = 10500<br> 7k = 10500<br> k = 1500",
                "options": [
                    "Cost of a pineapple is sh 2,500",
                    "Cost of a pineapple is sh 1,500",
                    "Cost of a pineapple is sh 1,000"
                ],
                "mark": 4,
                "id": "ple_math_sect_B31_1"
            },
            {
                "question": "The figure below shows a rectangular sheet of metal. The sheet is curved to form the wall of a cylindrical tank whose height is 100cm.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2018/31.png",
                "sub_questions": [
                    {
                        "question": "Find the diameter of the tank formed. (Use π = 22/7)",
                        "type": "multipleChoice",
                        "answer": "Diameter = 70cm",
                        "explanation": "Circumference = 2 x π x radius<br> Since the rectangle was folded to form the cylinder, Length = Circumference<br> From C = 2πr<br> 220 = 2 x 22/7 x r<br> 220 = 44/7 x r<br> 220 x 7 = 44/7 x r x 7<br> 1540 = 44r<br> 1540/44 = 44r/44<br> 35 = r<br> r = 35<br> Diameter = radius x 2<br> = 35 x 2<br> = 70",
                        "options": [
                            "Diameter = 35cm",
                            "Diameter = 70cm",
                            "Diameter = 110cm"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "Calculate the area of the sheet needed to cover the base of the tank.",
                        "type": "multipleChoice",
                        "answer": "Area = 3850 cm<sup>2</sup>",
                        "explanation": "Area of sheet needed to cover = Area of circle that can fit on the cylinder<br> = π x radius<sup>2</sup><br> = 22/7 x (70/2)<sup>2</sup><br> = 22/7 x 35<sup>2</sup><br> 22/7 x 1225<br> = 3850 cm<sup>2</sup>",
                        "options": [
                            "Area = 2250 cm<sup>2</sup>",
                            "Area = 3800 cm<sup>2</sup>",
                            "Area = 3850 cm<sup>2</sup>"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "Calculate the capacity of the tank.",
                        "type": "multipleChoice",
                        "answer": "Capacity = 385000cm<sup>3</sup>",
                        "explanation": "Capacity = Volume<br> Volume = π x radiuscm<sup>2</sup> x height<br> = π x (70/2)<sup>2</sup> x 100<br> = 22/7 x 35 x 35 x 100<br> = 385000",
                        "options": [
                            "Capacity = 38500cm<sup>3</sup>",
                            "Capacity = 385000cm<sup>3</sup>",
                            "Capacity = 3850000cm<sup>3</sup>"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B31_2"
            },
            {
                "question": "In a class, 1/5 of the girls are boarders while 1/3 of the boys are day scholars. The percentage of the girls in the class in 60%. The class has 10 boys who are day scholars.",
                "sub_questions": [
                    {
                        "question": "How many pupils are in the class?",
                        "type": "multipleChoice",
                        "answer": "75 pupils",
                        "explanation": "Girls = 60% = 60/100<br> = 3/5<br> Boys = 5/5 - 3/5<br> = 2/5<br> Boys day scholars = 1/3 of 2/5<br> = 1/3 x 2/5<br> = 2/15<br> Let the total number of pupils be n<br> 2/15 x n = 10<br> 15 x 2/15n = 10 x 15<br> 2n = 150<br> n = 75 pupils",
                        "options": [
                            "70 pupils",
                            "65 pupils",
                            "75 pupils"
                        ],
                        "mark": 3
                    },
                    {
                        "question": "Find the number of girls who are borders",
                        "type": "multipleChoice",
                        "answer": "9 girls",
                        "explanation": "Number of girls = 3/5 x 75<br> = 3 x 15<br> = 45 girls<br> Girls in boarding = 1/5 x 45<br> = 9 girls",
                        "options": [
                            "10 girls",
                            "9 girls",
                            "8 girls"
                        ],
                        "mark": 2
                    }
                ],
                "id": "ple_math_sect_B31_3"
            },
            {
                "question": "Given that m = 3k and k = 5, find the value of 2k + 6m",
                "type": "multipleChoice",
                "answer": "100",
                "explanation": "2k + 6m<br> 2 x 5 + 6 x 3k<br> 10 + 6 x 3 x 5<br> 10 + 90<br> = 100",
                "options": [
                    "10",
                    "100",
                    "90"
                ],
                "mark": 3,
                "sub_questions": [
                    {
                        "question": "Write the solution set for the inequality: 6 < x < 10",
                        "type": "multipleChoice",
                        "answer": "x = {7, 8, 9}",
                        "explanation": "x are integers between 6 and 10",
                        "options": [
                            "x = {6, 8, 9}",
                            "x = {7, 8, 9}",
                            "x = {7, 8, 10}"
                        ],
                        "mark": 1
                    }
                ],
                "id": "ple_math_sect_B31_4"
            },
            {
                "question": "A geometry set costs half as much as a book. A book costs 600 more than a fountain pen. If the total cost of the three items is sh 6,900, find the cost of the geometry set.",
                "type": "multipleChoice",
                "answer": "shs 1,500",
                "explanation": "Let the cost of fountain pen be y<br> Book = y + 600<br> Set = 1/2(y + 600)<br> y + y + 600 + 1/2(y + 600) = 6900<br> 2y + 600 + (y+600)/2 = 6900<br> Multiply through by 2<br> 4y + 1200 + y + 600 = 13800<br> 4y + y + 600 + 1200 = 13800<br> 5y + 1800 - 1800 = 13800 - 1800<br> 5y = 12000<br> y = 2400<br> Cost of geometry set<br> = 1/2(y+600)<br> = 1/2(2400 + 600)<br> = 1/2 x 3000<br> = shs 1500",
                "options": [
                    "shs 2,000",
                    "shs 3,000",
                    "shs 1,500"
                ],
                "mark": 4,
                "id": "ple_math_sect_B31_5"
            },
            {
                "question": "Nanziri has two children, a son and a daughter. If the son is half her age, the daughter a third her age and the total age of the two children is 30 years.",
                "sub_questions": [
                    {
                        "question": "Find Nanziri's age",
                        "type": "multipleChoice",
                        "answer": "36 years old",
                        "explanation": "Let Nanziri's age be x<br> Son = ½x<br> Daughter = ⅓x<br> Total age = 30 years<br> ½x + ⅓x = 30<br> x/2 + x/3 = 30<br> (3x + 2x)/6 = 30<br> 5x/6 = 30<br> 5x = (30x6)<br> 5x = 180<br> x = 36",
                        "options": [
                            "30 years old",
                            "25 years old",
                            "36 years old"
                        ],
                        "mark": 3,
                        "id": "ple_math_sect_B31_6_sub_0"
                    },
                    {
                        "question": "How old is the daughter?",
                        "type": "multipleChoice",
                        "answer": "12 years old",
                        "explanation": "Daughter's age  = 1/3 x 36<br> = 12 years old",
                        "options": [
                            "10 years old",
                            "18 years old",
                            "12 years old"
                        ],
                        "mark": 1,
                        "id": "ple_math_sect_B31_6_sub_1"
                    }
                ],
                "id": "ple_math_sect_B31_6"
            },
            {
                "question": "The figure below is a cuboid. Study and use it to answer the questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2013/31.png",
                "sub_questions": [
                    {
                        "question": "Find the value of y",
                        "type": "multipleChoice",
                        "answer": "y = 5",
                        "explanation": "Length = Length<br> 2y - 3 = y + 2<br> 2y - 3 + 3 = y + 2 + 3<br> 2y = y + 5<br> 2y - y = y - y + 5<br> y = 5",
                        "options": [
                            "y = 6",
                            "y = 5",
                            "y = 4"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "Find the volume of the cuboid",
                        "type": "multipleChoice",
                        "answer": "Volume = 105 cm<sup>3</sup>",
                        "explanation": "Length = (y+2)cm<br> = 5+2<br> = 7 cm<br> Width = (y-2)cm<br> = y-2<br> = 5-2<br> = 3 cm<br> Height<br> y cm = 5 cm<br> Volume = L x W x H<br> = (7 x 3) x 5<br> = 21 x 5<br> = 105 cm<sup>3</sup>",
                        "options": [
                            "Volume = 100 cm<sup>3</sup>",
                            "Volume = 110 cm<sup>3</sup>",
                            "Volume = 105 cm<sup>3</sup>"
                        ],
                        "mark": 3
                    }
                ],
                "id": "ple_math_sect_B31_7"
            },
            {
                "question": "A man's salary was increased by 30% at shs 312,000 per month.",
                "sub_questions": [
                    {
                        "question": "What was the man's monthly salary before the increment?",
                        "type": "multipleChoice",
                        "answer": "240,000",
                        "explanation": "Let the previous salary be y<br> New percentage = 100% + 30%'<br> = 130 %<br> 130/100 x y = 31200<br> Mutiply both sides by 100<br> 100 x 130/100y = 31200 x 100<br> 130y/130 = 3120000/130<br> y = 240,000",
                        "options": [
                            "240,000",
                            "360,000",
                            "200,000"
                        ],
                        "mark": 2
                    },
                    {
                        "question": "If 5% of his new salary is subtracted as tax, what is his final salary?",
                        "type": "multipleChoice",
                        "answer": "New salary = 296,400",
                        "explanation": "New   percentage = 100% - 5%<br> = 95%<br> New salary = 95/100 x 312000<br> = 95 x 3120<br> = 296,400",
                        "options": [
                            "240,000",
                            "296,400",
                            "248,000"
                        ],
                        "mark": 3
                    }
                ],
                "id": "ple_math_sect_B31_8"
            },
            {
                "question": "The cost of a book in a shop is (3d) shillings. The cost of a book is twice as much as a pen. If the total cost of two items is sh. 3,600. Calculate the cost of a pen",
                "type": "multipleChoice",
                "answer": "Cost of pen = 1,200",
                "explanation": "Book: 3d<br> Pen: 3d/2<br> Total: sh. 3,600<br> 3d + 3d/2 = 3,600<br> 2 x 3d + 3d/2 x 2 = 3,600 x 2<br> 6d + 3d = 7200<br> 9d = 7200<br> d = 800<br> Cost of pen = 3d/2<br> = (3 x 800)/2<br> = 2400/2<br> = 1200",
                "options": [
                    "Cost of pen = 1,000",
                    "Cost of pen = 500",
                    "Cost of pen = 1,200"
                ],
                "mark": 4,
                "id": "ple_math_sect_B31_9"
            },
            {
                "question": "At a forex Bureau, one dollar cost Ug. Sh. 3,800 and 1Ksh. = Ug. 35. How much in Uganda shillings will a tourist who has US dollars 500 get?",
                "type": "multipleChoice",
                "answer": "Ugsh. 1,900,000",
                "explanation": "1 dollar = Ugsh. 3800<br> 500 dollars = 3800 x 500<br> = Ugsh. 1,900,000",
                "options": [
                    "Ugsh. 1,000,000",
                    "Ugsh. 1,900,000",
                    "Ugsh. 3,800,000"
                ],
                "mark": 2,
                "sub_questions": [
                    {
                        "question": "If a businessman had Ksh. 17,500, how many US dollars will he get at the same exchange rate?",
                        "type": "multipleChoice",
                        "answer": "115.1316 dollars",
                        "explanation": "1Ksh. = Ugsh. 25<br> Ksh. 17500 = Ugsh. 25x17500<br> = Ugsh. 437,500<br> Ugsh.3800 = 1 dollar<br> = 437,500/3,800<br> = 115.1316 dollars",
                        "options": [
                            "53 dollars",
                            "115.1316 dollars",
                            "115 dollars"
                        ],
                        "mark": 3
                    }
                ],
                "id": "ple_math_sect_B31_10"
            }
        ],
        "$id": "document_30"
    },
    {
        "category": 32,
        "questions": [
            {
                "question": "Using the equation y = 2x - 1, which of the following tables is the correct and complete of the table below:",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/paper_2/32Q.png",
                "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/paper_2/32C.png",
                "options": [
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/paper_2/32W.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/paper_2/32C.png"
                ],
                "mark": 5,
                "id": "ple_math_sect_B32_10"
            }
        ],
        "$id": "document_31"
    },
    {
        "category": 5,
        "questions": [
            {
                "question": "Round off 53.86 to the nearest tenth",
                "type": "multipleChoice",
                "answer": "53.9",
                "options": [
                    "53.0",
                    "53.9",
                    "53.8"
                ],
                "mark": 2,
                "id": "ple_math_sect_A5_1"
            },
            {
                "question": "The clock face shows time in the afternoon. What's the time shown in 24-hour clock.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2018/5.png",
                "type": "multipleChoice",
                "answer": "15:50 hours",
                "explanation": "4:50p.m + 12hours<br> = 16:50 or 16 hours 50 minutes",
                "options": [
                    "04:50 hours",
                    "15:50 hours",
                    "12:50 hours"
                ],
                "mark": 2,
                "id": "ple_math_sect_A5_2"
            },
            {
                "question": "Solve the equation: 7n + 2 = 23",
                "type": "multipleChoice",
                "answer": "n = 3",
                "explanation": "7n + 2 = 23<br> 7n + 2 - 2 = 23 - 2<br> 7n = 21<br> 7n/7 = 21/7<br> n = 3",
                "options": [
                    "n = 7",
                    "n = 4",
                    "n = 3"
                ],
                "mark": 2,
                "id": "ple_math_sect_A5_3"
            },
            {
                "question": "Without dividing, which of the numbers 140 and 5070 is divisible by 3?",
                "type": "text",
                "answer": "5070",
                "explanation": "We add the sum of the numbers and the sum that is divisible by 3 is the number that can be divided by 3",
                "mark": 2,
                "id": "ple_math_sect_A5_4"
            },
            {
                "question": "Which of the following diagrams represent <sup>-</sup>7 - <sup>-</sup>3 on a number line",
                "type": "multipleChoice",
                "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2015/5.png",
                "options": [
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2015/5_1.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2015/5_2.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2015/5.png"
                ],
                "mark": 2,
                "id": "ple_math_sect_A5_5"
            },
            {
                "question": "Simplify: 5ab - 2xy - ab + 7y",
                "type": "multipleChoice",
                "answer": "4ab - y(2x - 7)",
                "explanation": "5ab - ab - 2xy + 7y<br> 4ab - y(2x - 7)",
                "options": [
                    "4ab - y(2x - 7)",
                    "4ab - y(2x + 7)",
                    "4ab + y(2x - 7)"
                ],
                "mark": 2,
                "id": "ple_math_sect_A5_6"
            },
            {
                "question": "Round off 13.962 to the nearest thousands",
                "type": "multipleChoice",
                "answer": "13.000",
                "explanation": "12000 + 1000<br> = 13,000",
                "options": [
                    "12,999",
                    "13.000",
                    "13,852"
                ],
                "mark": 2,
                "id": "ple_math_sect_A5_7"
            },
            {
                "question": "Given that set Q = (all prime numbers less than 10), find n(Q)",
                "type": "multipleChoice",
                "answer": "n(Q) = 4",
                "explanation": "Set Q = (2, 3, 5, 7)<br> n(Q) = 4",
                "options": [
                    "n(Q) = 9",
                    "n(Q) = 5",
                    "n(Q) = 4"
                ],
                "mark": 2,
                "id": "ple_math_sect_A5_8"
            },
            {
                "question": "Find the radius of a circular ring whose diameter is 28 cm",
                "type": "multipleChoice",
                "answer": "Radius = 14 cm",
                "explanation": "R = D/2<br> = 28/2<br> = 14 cm",
                "options": [
                    "Radius = 28 cm",
                    "Radius = 7 cm",
                    "Radius = 14 cm"
                ],
                "mark": 2,
                "id": "ple_math_sect_A5_9"
            },
            {
                "question": "Work out: 2/3 ÷ 2/9",
                "type": "multipleChoice",
                "answer": "3",
                "explanation": "2/3 x 9/3<br> = 18/9<br> = 14 cm",
                "options": [
                    "1/3",
                    "3",
                    "4"
                ],
                "mark": 2,
                "id": "ple_math_sect_A5_10"
            }
        ],
        "$id": "document_4"
    },
    {
        "category": 6,
        "questions": [
            {
                "question": "Which image shows the perpendicular from point C onto the segment AB below",
                "type": "multipleChoice",
                "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/Symbol 3.png",
                "options": [
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/Symbol%205.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/Symbol 4.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/Symbol 3.png"
                ],
                "mark": 2,
                "id": "ple_math_sect_A6_1"
            },
            {
                "question": "Simplify: 5k - 2(3 - k)",
                "type": "multipleChoice",
                "answer": "7k - 6",
                "explanation": "Use BODMAS<br> Open the brackets<br> 5k - 6 + 2k<br> Collect the like terms<br> 5k + 2k - 6<br> 7k - 6",
                "options": [
                    "3k - 6",
                    "7k - 6",
                    "7k + 6"
                ],
                "mark": 2,
                "id": "ple_math_sect_A6_2"
            },
            {
                "question": "Given that set N = {c, t, p}, list all the subsets in N",
                "type": "multipleChoice",
                "answer": "{c},{t},{p},{c,t},{c,p},{t,p}",
                "options": [
                    "{c},{t},{p}",
                    "{c},{t},{p},{c,t},{c,p},{t,p},{c,t,p}",
                    "{c},{t},{p},{c,t},{c,p},{t,p}"
                ],
                "mark": 2,
                "id": "ple_math_sect_A6_3"
            },
            {
                "question": "Work out 110<sub>two</sub> x 11<sub>two</sub>",
                "type": "multipleChoice",
                "answer": "10010<sub>two</sub>",
                "options": [
                    "1210<sub>two</sub>",
                    "10010<sub>two</sub>",
                    "1010<sub>two</sub>"
                ],
                "mark": 2,
                "id": "ple_math_sect_A6_4"
            },
            {
                "question": "Find the sum of the 5<sup>th</sup> and 8<sup>th</sup> prime numbers",
                "type": "multipleChoice",
                "answer": "Sum = 30",
                "explanation": "The 5<sup>th</sup> is 11 and the 8<sup>th</sup> is 19<br> So 11 + 19 = 30",
                "options": [
                    "Sum = 34",
                    "Sum = 30",
                    "Sum = 26"
                ],
                "mark": 2,
                "id": "ple_math_sect_A6_5"
            },
            {
                "question": "Find the next number in the sequence: 49, 47, 44, 39, .....",
                "type": "multipleChoice",
                "answer": "32",
                "explanation": "By subtracting prime numbers from the given sequence<br> 49 - 2 = 47<br> 47 - 3 = 44<br> 44 - 5 = 39<br> 39 - 7 = 32",
                "options": [
                    "32",
                    "31",
                    "37"
                ],
                "mark": 2,
                "id": "ple_math_sect_A6_6"
            },
            {
                "question": "Find the value of X in the diagram below.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2013/6.png",
                "type": "multipleChoice",
                "answer": "X = 18°",
                "explanation": "3x + 90°+ 2x = 180° (angle sum of straight line)<br> 5x + 90° = 180°<br> 5x + 90 - 90 = 180 - 90<br> 5x = 90<br> x = 18°",
                "options": [
                    "X = 18°",
                    "X = 15°",
                    "X = 10°"
                ],
                "mark": 2,
                "id": "ple_math_sect_A6_7"
            },
            {
                "question": "Work out: 3/4 ÷ 1½",
                "type": "multipleChoice",
                "answer": "1/2",
                "explanation": "3/4 ÷ 3/2<br> = 3/4 x 2/3<br> = 1/2",
                "options": [
                    "1/3",
                    "1/2",
                    "1/4"
                ],
                "mark": 2,
                "id": "ple_math_sect_A6_8"
            },
            {
                "question": "Simplify: 5<sup>4</sup> ÷ 5<sup>2</sup>",
                "type": "multipleChoice",
                "answer": "25",
                "explanation": "5<sup>4-2</sup><br> = 5<sup>2</sup><br> = 5 x 5<br> = 25",
                "options": [
                    "20",
                    "25",
                    "30"
                ],
                "mark": 2,
                "id": "ple_math_sect_A6_9"
            }
        ],
        "$id": "document_5"
    },
    {
        "category": 7,
        "questions": [
            {
                "question": "Change 250 grammes into kilogrammes",
                "type": "multipleChoice",
                "answer": "0.25 kilogrammes",
                "explanation": "1 gram = 1/1000 kilogrammes<br> 250 grammes = (250 x 1/1000) kilogrammes<br> = 0.25 kilogrammes",
                "options": [
                    "0.025 kilogrammes",
                    "0.25 kilogrammes",
                    "250000 kilogrammes"
                ],
                "mark": 2,
                "id": "ple_math_sect_A7_1"
            },
            {
                "question": "A car uses 7 litres of petrol to cover 28 kilometres. How many litres of petrol can it use to cover 64 kilometres?",
                "type": "multipleChoice",
                "answer": "16 litres",
                "explanation": "28 kilometres = 7 litres<br> 1 km = 7/28 litres<br> = 1/4 litres<br> 64 km = (64 x 1/4) litres<br> = 16 litres",
                "options": [
                    "448 litres",
                    "256 litres",
                    "16 litres"
                ],
                "mark": 2,
                "id": "ple_math_sect_A7_2"
            },
            {
                "question": "Find the number which has been expanded below: (3x10<sup>2</sup>) + (5x10<sup>-1</sup>)",
                "type": "multipleChoice",
                "answer": "300.5",
                "explanation": "(3 x 10 x 10) + (5 x 1/10)<br> = 300 + 0.5<br> = 300.5",
                "options": [
                    "30.5",
                    "300.5",
                    "300.2"
                ],
                "mark": 2,
                "id": "ple_math_sect_A7_3"
            },
            {
                "question": "A die is tossed once. What is the probability that a number less than 5 will appear on top?",
                "type": "multipleChoice",
                "answer": "Probability = 2/3",
                "explanation": "Probability = Number of favorable outcomes/Total number of possible outcomes<br> = 4/6<br> Since both the numerator and denominator are divisible by 2<br> = (4÷2)/(6÷2)<br> = 2/3",
                "options": [
                    "Probability = 5/6",
                    "Probability = 2/3",
                    "Probability = 1/2"
                ],
                "mark": 2,
                "id": "ple_math_sect_A7_4"
            },
            {
                "question": "Work out 14/15 ÷ 2/5",
                "type": "multipleChoice",
                "answer": "2⅓",
                "explanation": "14/15 ÷ 2/5<br> = 14/15 x 5/2<br> = 7/3<br> = 2⅓",
                "options": [
                    "2⅓",
                    "2½",
                    "2⅔"
                ],
                "mark": 2,
                "id": "ple_math_sect_A7_5"
            },
            {
                "question": "A pupil bought a dozen of exercise books for Shs. 6,000. He later sold each book at Shs. 700. Calculate the profit.",
                "type": "multipleChoice",
                "answer": "Shs. 2,400",
                "explanation": "Buying price = Shs. 6,000<br> Selling price;<br> 1 dozen = 12 books<br> 12 books = 12 x 700<br> = Shs. 8400<br> Profit = selling price - buying price<br> = 8400 - 6000<br> = Shs. 2,400",
                "options": [
                    "Shs. 2,400",
                    "Shs. 4,800",
                    "Shs. 3,600"
                ],
                "mark": 2,
                "id": "ple_math_sect_A7_6"
            },
            {
                "question": "In the diagram below, find the valves of K",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2012/7.png",
                "type": "multipleChoice",
                "answer": "k = 80<sup>o</sup>",
                "explanation": "50<sup>o</sup> + k + 50<sup>o</sup> = 180<sup>o</sup> (angle sum of a straight line)<br> k + 100 = 180<br> k + 100 - 100 = 180 - 100<br> k = 80<sup>o</sup>",
                "options": [
                    "k = 50<sup>o</sup>",
                    "k = 90<sup>o</sup>",
                    "k = 80<sup>o</sup>"
                ],
                "mark": 2,
                "id": "ple_math_sect_A7_7"
            },
            {
                "question": "Simplify: 5k - 7k + 8k",
                "type": "multipleChoice",
                "answer": "6k",
                "explanation": "(5k + 8k) - 7k<br> 13k - 7k<br> = 6k",
                "options": [
                    "8k",
                    "6",
                    "6k"
                ],
                "mark": 2,
                "id": "ple_math_sect_A7_8"
            },
            {
                "question": "Subtract: 4p + 3 from 7p - 5",
                "type": "multipleChoice",
                "answer": "3p - 8",
                "explanation": "(7p - 5) - (4p +3)<br> = 7p - 5 - 4p - 3<br> = 7p - 4p - 5 - 3<br> = 3p - 8",
                "options": [
                    "-3p + 18",
                    "3p - 8",
                    "2p - 4"
                ],
                "mark": 2,
                "id": "ple_math_sect_A7_9"
            }
        ],
        "$id": "document_6"
    },
    {
        "category": 8,
        "questions": [
            {
                "question": "Okia bought 4 packets of washing powder each weighing 750 grams. Find the weight of the washing powder Okia bought in kilograms.",
                "type": "multipleChoice",
                "answer": "3 Kilograms",
                "explanation": "1 packet = 750 grams<br> 4 packets = (4 x 750) grams<br> = 3000 grams<br> 1 gram = 1/1000 kg<br> 3000 grams = 3000 x 1/1000 <br> = 3000/1000<br> = 3 kg",
                "options": [
                    "3 Kilograms",
                    "2 Kilograms",
                    "3000 grams"
                ],
                "mark": 2,
                "id": "ple_math_sect_A8_1"
            },
            {
                "question": "The profit on a shirt sold at sh7,900 was sh2,100. Calculate the cost price of the shirt.",
                "type": "multipleChoice",
                "answer": "Cost price = 5,800",
                "explanation": "Profit = Selling price - Cost price<br> 2100 = 7900 - CP<br> 2100 - 2100 + CP = 7900 - 2100 - CP + CP<br> CP = 5800",
                "options": [
                    "Cost price = 6,000",
                    "Cost price = 5,100",
                    "Cost price = 5,800"
                ],
                "mark": 2,
                "id": "ple_math_sect_A8_2"
            },
            {
                "question": "Write the integers represented by letters a and b on the line below",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/8.png",
                "sub_questions": [
                    {
                        "question": "a",
                        "type": "text",
                        "answer": "4"
                    },
                    {
                        "question": "b",
                        "type": "text",
                        "answer": "-5"
                    }
                ],
                "mark": 2,
                "id": "ple_math_sect_A8_3"
            },
            {
                "question": "A birthday party started at 4:30 p.m and lasted 2¾ hours . At what time did the party end?",
                "type": "multipleChoice",
                "answer": "7:15 p.m",
                "explanation": "We need to add 2¾ hours to 4:30 p.m<br> First we convert ¾ hours to minutes<br> 3/4 x 60 = 45 minutes<br> 4:30 p.m + 2:45<br> = 7:15 p.m",
                "options": [
                    "6:15 p.m",
                    "7:15 p.m",
                    "7:00 p.m"
                ],
                "mark": 2,
                "id": "ple_math_sect_A8_4"
            },
            {
                "question": "A lady bought a dress at 55,000. She later sold it and made a loss of sh 15,000. At what price did she sell the dress?",
                "type": "multipleChoice",
                "answer": "40,000",
                "explanation": "Purchase Price = 55,000 shillings<br> Loss = 15,000 shillings<br> Selling Price = Purchase Price - Loss<br> = 55,000 - 15,000<br> = 40,000",
                "options": [
                    "60,000",
                    "35,000",
                    "40,000"
                ],
                "mark": 2,
                "id": "ple_math_sect_A8_5"
            },
            {
                "question": "Simplify 4t - 2k + 5k - t",
                "type": "multipleChoice",
                "answer": "3t + 3k",
                "explanation": "4t - 2k + 5k - t<br> = 4t - t + 5k - 2k<br> = 3t + 3k",
                "options": [
                    "4t + 3k",
                    "3t + 2k",
                    "3t + 3k"
                ],
                "mark": 2,
                "id": "ple_math_sect_A8_6"
            },
            {
                "question": "Find the valve of 2<sup>4</sup> + 3<sup>0</sup>",
                "type": "multipleChoice",
                "answer": "17",
                "explanation": "(2 x 2 x 2 x 2) + 1<br> = (4 x 4) + 1<br> = 16 + 1<br> = 17",
                "options": [
                    "16",
                    "8",
                    "17"
                ],
                "mark": 2,
                "id": "ple_math_sect_A8_7"
            },
            {
                "question": "A retailer packed 2/3 kg of tea leaves into 1/9 kg packets, how many packets did she obtain?",
                "type": "multipleChoice",
                "answer": "6 packets",
                "explanation": "2/3 ÷ 1/9<br> = 2/3 x 9/1<br> = 2 x 3<br> = 6",
                "options": [
                    "6 packets",
                    "2 packets",
                    "3 packets"
                ],
                "mark": 2,
                "id": "ple_math_sect_A8_8"
            },
            {
                "question": "Find the median of 80, 55, 90, 55, 60 and 60",
                "type": "multipleChoice",
                "answer": "Median = 60",
                "explanation": "Arrange in ascending or descending order<br> 55, 55, 60, 60, 80, 90<br> Find the median number<br> = (60+60)/2<br> = 60",
                "options": [
                    "Median = 55",
                    "Median = 90",
                    "Median = 60"
                ],
                "mark": 2,
                "id": "ple_math_sect_A8_9"
            }
        ],
        "$id": "document_7"
    },
    {
        "category": 9,
        "questions": [
            {
                "question": "Solve: 3 + m = 2(finite 5)",
                "type": "multipleChoice",
                "answer": "m = 7",
                "explanation": "3 + m = 2 x 5<br> 3 + m = 10<br> m = 10 - 3<br> m = 7",
                "options": [
                    "m = 5",
                    "m = 7",
                    "m = -1"
                ],
                "mark": 2,
                "id": "ple_math_sect_A9_1"
            },
            {
                "question": "Change 10 square meters into square centimeters",
                "type": "multipleChoice",
                "answer": "10 m<sup>2</sup> = 100,000 cm<sup>2</sup>",
                "explanation": "1 m = 100 cm<br> 1 m<sup>2</sup> = 10000 cm<sup>2</sup><br> 10 m<sup>2</sup> = (10 x 10000) cm<sup>2</sup><br> = 100000 cm<sup>2</sup>",
                "options": [
                    "10 m<sup>2</sup> = 100,000 cm<sup>2</sup>",
                    "10 m<sup>2</sup> = 1,000 cm<sup>2</sup>",
                    "10 m<sup>2</sup> = 100 cm<sup>2</sup>"
                ],
                "mark": 2,
                "id": "ple_math_sect_A9_2"
            },
            {
                "question": "Which of the following images show Twenty five minutes to eleven on the clock face",
                "type": "multipleChoice",
                "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/9.png",
                "options": [
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/9_1.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/9_2.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/9.png"
                ],
                "mark": 2,
                "id": "ple_math_sect_A9_3"
            },
            {
                "question": "Which of the following shows all the lines of folding symmetry in the figure below",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2015/9Q.png",
                "type": "multipleChoice",
                "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2015/9.png",
                "options": [
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2015/9_2.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2015/9_1.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2015/9.png"
                ],
                "mark": 2,
                "id": "ple_math_sect_A9_4"
            },
            {
                "question": "The mass of a packet of coffee is 1/8kg. what is this mass in grams?",
                "type": "multipleChoice",
                "answer": "125 grams",
                "explanation": "1 kg = 1000 g<br> 1/8 kg = (1/8 x 1000)g<br> = 1000/8<br> = 500/4<br> = 250/2<br> = 125",
                "options": [
                    "0.125 grams",
                    "12.5 grams",
                    "125 grams"
                ],
                "mark": 2,
                "id": "ple_math_sect_A9_5"
            },
            {
                "question": "Divide 6363 by 7",
                "type": "text",
                "answer": "909",
                "mark": 2,
                "id": "ple_math_sect_A9_6"
            },
            {
                "question": "A debate which took 1¼ hours ended at 4:10p.m. What time did it start?",
                "type": "multipleChoice",
                "answer": "2:55 p.m",
                "explanation": "4:10 - 1:15<br> = 2:55",
                "options": [
                    "1:55 p.m",
                    "12:55 p.m",
                    "2:55 p.m"
                ],
                "mark": 2,
                "id": "ple_math_sect_A9_7"
            },
            {
                "question": "An examination ended at 11:10 a.m. If it lasted for 2 and 1/2 hours, at what time did it start?",
                "type": "multipleChoice",
                "answer": "8:40 a.m",
                "explanation": "Duration = 2 hrs 30 min<br> Starting time = Ending time - Duration<br> = 11:10 am - 2 30<br> = 8:40 a.m",
                "options": [
                    "1:40 a.m",
                    "8:40 a.m",
                    "9:40 a.m"
                ],
                "mark": 2,
                "id": "ple_math_sect_A9_8"
            },
            {
                "question": "Work out: 2 + 4 = ____(finite 7)",
                "type": "multipleChoice",
                "answer": "6 (finite 7)",
                "options": [
                    "6 (finite 7)",
                    "8 (finite 7)",
                    "7 (finite 7)"
                ],
                "mark": 2,
                "id": "ple_math_sect_A9_9"
            }
        ],
        "$id": "document_8"
    },
    {
        "category": 10,
        "questions": [
            {
                "question": "In the diagram below, find the value of b in degrees",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/Symbol 6.png",
                "type": "multipleChoice",
                "answer": "b = 50<sup>o</sup>",
                "explanation": "b + 20 + 110 = 180<br> b + 130 = 180<br> b + 130 - 130 = 180 - 130<br> b = 50",
                "options": [
                    "b = 90<sup>o</sup>",
                    "b = 50<sup>o</sup>",
                    "b = 160<sup>o</sup>"
                ],
                "mark": 2,
                "id": "ple_math_sect_A10_1"
            },
            {
                "question": "Find the next number in the sequence: 1, 2, 10, 37, .....",
                "type": "text",
                "answer": "101",
                "explanation": "The difference between 2 and 1 is 1<br> The difference between 10 and 2 is 8<br> The difference between 37 and 10 is 27<br> which is gotten from 1<sub>3</sub>, 2<sub>3</sub>, 3<sub>3</sub><br> So the next difference is 4<sub>3</sub> = 64<br> 37 + 64 = 101",
                "mark": 2,
                "id": "ple_math_sect_A10_2"
            },
            {
                "question": "Write 9:30 am in the 24-hour clock",
                "type": "text",
                "answer": "09:30 hours",
                "explanation": "When the time is in a.m, it remains the same but only changes the a.m to hours",
                "mark": 2,
                "id": "ple_math_sect_A10_3"
            },
            {
                "question": "In the triangle below, find the value of d in degrees",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/10.png",
                "type": "multipleChoice",
                "answer": "d = 36<sup>o</sup>",
                "explanation": "Since its an equilateral triangle, the base angles are equal<br> ∴ 2d + 2d + d = 180 (Angle sum of a triangle)<br> 5d = 180<br> 5d/5 = 180/5<br> d = 36",
                "options": [
                    "d = 45<sup>o</sup>",
                    "d = 60<sup>o</sup>",
                    "d = 36<sup>o</sup>"
                ],
                "mark": 2,
                "id": "ple_math_sect_A10_4"
            },
            {
                "question": "A trader sold a pair of shoes at sh 32,800 making a profit of sh 1,200. What was the cost price of the pair of shoes?",
                "type": "multipleChoice",
                "answer": "Cost price = 31,600",
                "explnation": "Cost price = Selling price - Profit<br> = 32,800 - 1,200<br> = 31,600",
                "options": [
                    "Cost price = 30,600",
                    "Cost price = 31,000",
                    "Cost price = 31,600"
                ],
                "mark": 2,
                "id": "ple_math_sect_A10_5"
            },
            {
                "question": "Work out: 412<sub>five</sub> - 13<sub>five</sub>",
                "type": "multipleChoice",
                "answer": "344<sub>five</sub>",
                "options": [
                    "399<sub>five</sub>",
                    "304<sub>five</sub>",
                    "344<sub>five</sub>"
                ],
                "mark": 2,
                "id": "ple_math_sect_A10_6"
            },
            {
                "question": "Work out: 2/3 + 1/4",
                "type": "multipleChoice",
                "answer": "11/12",
                "explanation": "LCM of 3 and 4 = 12<br>  = ((4 x 2) + (3 x 1))/12<br> = (8 + 3)/12<br> = 11/12",
                "options": [
                    "11/12",
                    "1/2",
                    "1/12"
                ],
                "mark": 2,
                "id": "ple_math_sect_A10_7"
            },
            {
                "question": "Find the Greatest common factor (GCF) of 18 and 24",
                "type": "multipleChoice",
                "answer": "GCF = 6",
                "explanation": "Factors of 18 = {1, 2, 3, 6, 9, 18}<br> Factors of 24 = {1, 2, 4, 6, 8, 12, 24}<br> GCF = 6",
                "options": [
                    "GCF = 8",
                    "GCF = 12",
                    "GCF = 6"
                ],
                "mark": 2,
                "id": "ple_math_sect_A10_8"
            }
        ],
        "$id": "document_9"
    }
]

const sci_ple = [
    {
        "category": 1,
        "questions": [
            {
                "question": "Name the source of solar energy",
                "type": "text",
                "answer": "The Sun"
            },
            {
                "question": "State any one sign of a compound fracture.",
                "type": "text",
                "answer": "The broken bone pierces through the skin and can be seen on the outside"
            },
            {
                "question": "State the injury caused by steam to a human body",
                "type": "text",
                "answer": "Scald"
            },
            {
                "question": "Name the gas used in fire extinguishers.",
                "type": "text",
                "answer": "Carbondioxide"
            },
            {
                "question": "Which type of immunity do babies get from their mothers?",
                "type": "check_box",
                "answer": "",
                "Options": [
                    "",
                    "",
                    ""
                ]
            },
            {
                "question": "Which type of soil has rough and large particle?",
                "type": "text",
                "answer": "Carbondioxide"
            },
            {
                "question": "Give any one example of a swimming bird",
                "type": "text",
                "answer": [
                    "Duck",
                    "Goose"
                ]
            },
            {
                "question": "Give any one example of a root tuber",
                "type": "check_box",
                "answer": [
                    "Cassava"
                ],
                "Options": [
                    "Apples",
                    "sugarcane",
                    "mango"
                ]
            },
            {
                "question": "Name the disease which is spread by a female anopheles mosquito",
                "type": "check_box",
                "answer": [
                    "Malaria"
                ],
                "Options": [
                    "Malaria",
                    "Aids",
                    "fever"
                ]
            },
            {
                "question": "Give one item used for cleaning hands after visiting a latrine or toilet.",
                "type": "check_box",
                "answer": [
                    "Water"
                ],
                "Options": [
                    "Water",
                    "sugar",
                    "iron sheets",
                    "air"
                ]
            },
            {
                "question": "Name one domestic used for protection in a home.",
                "type": "text",
                "answer": [
                    "Dog "
                ]
            },
            {
                "question": "Name  one body  organ  in man  that  makes  use  of light  energy  from  the sun.",
                "type": "text",
                "answer": [
                    "Eye"
                ]
            },
            {
                "question": "Which reptile  change  the colour  of its body  as its moves  from one place to another ?",
                "type": "text",
                "answer": [
                    "Chameleon"
                ]
            },
            {
                "question": "What good health habit should be practiced after visiting a latrine or toilet?",
                "type": "check_box",
                "answer": [
                    "Wash hands with soap and water",
                    "Wash hands with water only",
                    "washing clothes",
                    "brushing your teeth"
                ]
            }
        ]
    },
    {
        "category": 2,
        "questions": [
            {
                "question": "which vector spreads relapsing fever to people?",
                "type": "text",
                "answer": "Ticks"
            },
            {
                "question": "What happens when a new queen is born in a hive?",
                "type": "text",
                "answer": [
                    "The worker bees may kill the old queen",
                    "queen cell construction",
                    "Royal jelly feeding"
                ]
            },
            {
                "question": "What type of electricity is lightening?",
                "type": "text",
                "answer": "Static electricity"
            },
            {
                "question": "State the eye disease which is spread by houseflies.",
                "type": "text",
                "answer": "Trachoma"
            },
            {
                "question": "Name the blood vessel which takes blood from the heart to the lungs.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "In which state of matter does heat travel slowest",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Name the animal product used in leather industry.",
                "type": "text",
                "answer": "Skin"
            },
            {
                "question": "State one disadvantage of keeping finger nails long.",
                "type": "text",
                "answer": "They keep germs"
            },
            {
                "question": "Which form of energy changes ice to water?",
                "type": "check_box",
                "answer": [
                    "Heat energy"
                ],
                "options": [
                    "Heat energy",
                    "sound energy",
                    "light energy"
                ]
            },
            {
                "question": "State one way in which rain may be a disadvantage to a crop.",
                "type": "check_box",
                "answer": [
                    "May lead to flooding"
                ],
                "options": [
                    "May lead to flooding",
                    "promotes crop growth",
                    "delays farmers work"
                ]
            },
            {
                "question": "Give the function of a micropyle to a germinating seed.",
                "type": "check_box",
                "answer": [
                    "Allow entry of water and air into the seed during germination"
                ],
                "options": [
                    "Allow entry of water and air into the seed during germination",
                    "Allow entry of sunlight into the seed during germination",
                    "Allow entry of sunlight into the cotyledon during germination",
                    "Allow entry of oxygen into the seed during photosynthesis"
                ]
            },
            {
                "question": "Name one cold- blooded vertebrate that has a body shell.",
                "type": "check_box",
                "answer": [
                    "Tortoise"
                ],
                "options": [
                    "Tortoise",
                    "jelly fish",
                    "dolphin",
                    "whale"
                ]
            },
            {
                "question": "Give any one way in which bush burning leads to soil erosion",
                "type": "check_box",
                "answer": [
                    "It removes vegetation cover and expose soil to agents of soil erosion such as wind, running water."
                ],
                "options": [
                    "It removes vegetation cover and expose soil to agents of soil erosion such as wind, running water.",
                    "It removes vegetation cover and expose soil to agents of soil erosion such as sunshine, moonlight",
                    "Bush burning causes soil erosion",
                    "fire destroys the soil"
                ]
            },
            {
                "question": "Give any one way in which animals are important during the process of photosynthesis.",
                "type": "check_box",
                "answer": [
                    "Animal produce carbon dioxide that is a raw material in photosynthesis"
                ],
                "options": [
                    "Animal produce carbon dioxide that is a raw material in photosynthesis",
                    "Animals produce oxygen that is a raw material in photosynthesis",
                    "they provide sunlight",
                    "they provide water"
                ]
            }
        ]
    },
    {
        "category": 3,
        "questions": [
            {
                "question": "State any one use of a razor blade in promoting personal hygiene",
                "type": "text",
                "answer": "Trimming fingernails"
            },
            {
                "question": "Which human body organ balances the amount of water needed in the body?",
                "type": "text",
                "answer": "The Kidney"
            },
            {
                "question": "Give any one way in which termites benefit from soil",
                "type": "text",
                "answer": [
                    "Get shelter",
                    "They obtain food material from soil"
                ]
            },
            {
                "question": "Which part of a fish performs a similar function as the human ear?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Name the energy resource obtained from animal dung and urine.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "To which class of levers does a wheel barrow belong?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Sate any one property of matter",
                "type": "text",
                "answer": [
                    "It occupies space",
                    "It has weight "
                ]
            },
            {
                "question": "What is the importance of including calcium in chicken feed?",
                "type": "check_box",
                "answer": [
                    "to lay eggs with hard shells",
                    "to strengthen bones "
                ]
            },
            {
                "question": "Give a reason why drugs should be kept away from children",
                "type": "check_box",
                "answer": [
                    "To prevent drug poisoning"
                ],
                "options": [
                    "To prevent drug poisoning",
                    "To prevent food poisoning",
                    "To promote a balanced diet"
                ]
            },
            {
                "question": "The diagram below is   of a simple circuit.Use it to answer questions and 4 and 5  ",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2010_3.jpg",
                "sub_questions": [
                    {
                        "question": "How does electricity flow in a circuit?",
                        "type": "check_box",
                        "answer": [
                            "from postive to negative"
                        ],
                        "options": [
                            "from postive to negative",
                            "from negative to positive",
                            "from negative to negative",
                            "from positive to positive"
                        ]
                    },
                    {
                        "question": "What general name is given to animals with a backbone?",
                        "type": "text",
                        "answer": [
                            "Vertebrate "
                        ]
                    },
                    {
                        "question": "Mention any one way in which a biological change is similar to a chemical change",
                        "type": "check_box",
                        "answer": [
                            "It is irreversible"
                        ],
                        "options": [
                            "It is irreversible",
                            "It is reversible",
                            "Involves heat energy",
                            "Involves sunlight"
                        ]
                    }
                ]
            },
            {
                "question": "How are bats different from hens in their way of reproduction?",
                "type": "check_box",
                "answer": [
                    "Bats give birth to young ones whereas hens lay eggs."
                ],
                "options": [
                    "Bats give birth to young ones whereas hens lay eggs.",
                    "Bats lay eggs  whereas hens give birth to live young ones.",
                    "Bats sit on eggs after laying them whereas hens breast feed "
                ]
            },
            {
                "question": "What form of energy does a falling stone have before it reaches the ground?",
                "type": "text",
                "answer": [
                    "kinetic energy"
                ]
            }
        ]
    },
    {
        "category": 4,
        "questions": [
            {
                "question": "Give any one example of Biological change in plants",
                "type": "multipleChoice",
                "answer": "",
                "options": [
                    "photosynthesis",
                    "transpiration"
                ]
            },
            {
                "question": "Give any one disorder of the human digestive system.",
                "type": "multipleChoice",
                "answer": "",
                "options": [
                    "constipation",
                    "intestinal obstruction"
                ]
            },
            {
                "question": "State one effect of gonorrhea on female reproductive system",
                "type": "text",
                "answer": [
                    "Increased vaginal discharge.",
                    "Vaginal bleeding between periods, such as after vaginal intercourse",
                    "Blockade of fallopian tubes"
                ]
            },
            {
                "question": "Name the male reproductive cell in flowers.",
                "type": "text",
                "answer": "Anthers"
            },
            {
                "question": "Mention any one factor that affects the rate of evaporation.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Apart from labor, give one other requirement for starting a livestock farm",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Name the part of the human eye that works like the film in a camera.",
                "type": "text",
                "answer": "Retina"
            },
            {
                "question": "What is the use of a wedge as a simple machine?",
                "type": "text",
                "answer": "Cutting"
            },
            {
                "question": "Name the class of simple machines to which   an axe belongs.",
                "type": "text",
                "answer": "Wedge"
            },
            {
                "question": "Name the type of preservation where slow burning fire is used to dry food",
                "type": "text",
                "answer": [
                    "Smoking"
                ]
            },
            {
                "question": "How can a passion fruit plant benefit from growing near a mango tree?",
                "type": "text",
                "answer": [
                    "Obtain support"
                ]
            },
            {
                "question": "How does immunization promote health in a child?",
                "type": "check_box",
                "answer": [
                    "Protect the child from killer diseases such as tetanus, TB."
                ],
                "options": [
                    "Protect the child from killer diseases such as tetanus, TB.",
                    "Protect the child from killer diseases such as flue",
                    "Protect the child from defficiency diseases like tetanus",
                    "Protect the child from contagius diseases"
                ]
            },
            {
                "question": "State ant one reason why some plants climb others.",
                "type": "check_box",
                "answer": [
                    "To obtain support to reach light."
                ],
                "options": [
                    "To obtain support to reach light.",
                    "To obtain support ",
                    "to obtain nutrients",
                    "to be conspicuous"
                ]
            }
        ]
    },
    {
        "category": 5,
        "questions": [
            {
                "question": "The diagram below is of a crow bar. Study and use it to answer the following questions",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2018_5.jpg",
                "sub_questions": [
                    {
                        "question": "which of the two positions x and y requires less effort to overcome the load w?",
                        "type": "text",
                        "answer": "Y"
                    },
                    {
                        "question": "Indicate on the diagram with an arrow E, the direction of the effort used to overcome the load w",
                        "type": "text",
                        "answer": ""
                    }
                ]
            },
            {
                "question": "What is the importance of gap filling in crop growing?",
                "type": "text",
                "answer": "To maintain optimum plant population"
            },
            {
                "question": "Give any one way in which wood fuel can be conserved.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "State the difference in units used to measure weight and mass",
                "type": "text",
                "answer": ""
            },
            {
                "question": "What kind of accident requires the use of splints in giving First Aid?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "In which way is the message in the sign post below helpful to school children?",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2013_5.jpg",
                "type": "text",
                "answer": [
                    "to avoid sexually transmitted diseases",
                    "to avoid early pregnancy",
                    "to avoid sexual harassment"
                ]
            },
            {
                "question": "Name the type of lens used to correct long sightedness",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Write down any one example of a plant fibre.",
                "type": "check_box",
                "answer": "Sisal",
                "options": [
                    "Sisal",
                    "mango",
                    "sugarcane"
                ]
            },
            {
                "question": "The diagram below is of a human eye.Use it to answer question 6 and 7",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2011_6.jpg",
                "sub_questions": [
                    {
                        "question": "Name the part marked with letter X ",
                        "type": "text",
                        "answer": [
                            "Pupil"
                        ]
                    },
                    {
                        "question": "Give the use of the part marked with letter Z. ",
                        "type": "check_box",
                        "answer": [
                            "Part Z regulates the amount of light entering the eye"
                        ],
                        "options": [
                            "Part Z regulates the amount of light entering the eye",
                            "Part Z increase the amount of light entering the eye"
                        ]
                    },
                    {
                        "question": "Apart from light, which other form   of energy is produced at A?",
                        "type": "text",
                        "answer": [
                            "Heat"
                        ]
                    }
                ]
            },
            {
                "question": "Give one pest which destroys the roots of root crops in gardens",
                "type": "check_box",
                "answer": [
                    "squirrels"
                ],
                "options": [
                    "squirrels",
                    "spiders",
                    "snakes",
                    "snails"
                ]
            },
            {
                "question": "Write down the vector that spreads elephantiasis.",
                "type": "text",
                "answer": [
                    "Culex mosquito"
                ],
                "options": [
                    "Culex mosquito",
                    "Anopheles mosquito",
                    "tiger mosquito",
                    "male anopheles mosquito"
                ]
            },
            {
                "question": "Give any one property of air shown in the diagram below ",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2010_5.jpg",
                "type": "check_box",
                "answer": [
                    "Air occupies space"
                ],
                "options": [
                    "Air occupies space",
                    "Air has weight",
                    "air exerts pressure",
                    "air has mass"
                ]
            },
            {
                "question": "Give any one common use of sand at home.",
                "type": "check_box",
                "answer": [
                    "Sand is used for building"
                ],
                "options": [
                    "Sand is used for building",
                    "Sand is used for cooking",
                    "Sand is used for  washing",
                    "Sand is used for bathing"
                ]
            }
        ]
    },
    {
        "category": 6,
        "questions": [
            {
                "question": "The diagram below is of a human skull and a neck vertebra. Use it to answer the following questions",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2019_6.jpg",
                "sub_questions": [
                    {
                        "question": "Name the movable joint at <b>K</b>",
                        "type": "text",
                        "answer": ""
                    },
                    {
                        "question": "Which delicate Organ is protected by  <b>S<b>",
                        "type": "text",
                        "answer": ""
                    }
                ]
            },
            {
                "question": "The diagram below shows reflection of light. Study and use it to answer questions 6 and 7",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2017_6.jpg",
                "sub_questions": [
                    {
                        "question": "What type of reflection is shown above?",
                        "type": "text",
                        "answer": "Diffuse reflection"
                    },
                    {
                        "question": "Why are the rays reflected as shown in the diagram?",
                        "type": "text",
                        "answer": "Because the reflecting surface is rough or uneven"
                    }
                ]
            },
            {
                "question": "The diagram below is of a weather instrument. Use it to answer the question that follows.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2016_6.jpg",
                "sub_questions": [
                    {
                        "question": "What is the use of this instrument at a Weather Station?",
                        "type": "text",
                        "answer": ""
                    }
                ]
            },
            {
                "question": "the diagram below shows a simple machine. Use it to answer questions 6 and 7.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2015_6.jpg",
                "sub_questions": [
                    {
                        "question": "To which class of levers does the machine above belong?",
                        "type": "text",
                        "answer": ""
                    },
                    {
                        "question": "Use an arrow with letter P to show the fulcrum on the diagram.",
                        "type": "text",
                        "answer": ""
                    }
                ]
            },
            {
                "question": "Give any one exotic breed of sheep kept for wool production",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Give any way in which a single fixed pulley is used in a school",
                "type": "check_box",
                "answer": [
                    "For digging pit latrine"
                ],
                "Options": [
                    "",
                    ""
                ]
            },
            {
                "question": "The diagram below shows a person with an injury .Use it to answer question 6",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2013_6.png",
                "sub_questions": [
                    {
                        "question": "How useful is the structure marked M to the injured person?",
                        "type": "check_box",
                        "answer": [
                            "Keep the injured arm in one position"
                        ],
                        "Options": []
                    }
                ]
            },
            {
                "question": "What is the importance of dipping cattle?",
                "type": "check_box",
                "answer": [
                    "Kills ectoparasite"
                ],
                "options": [
                    "Kills ectoparasite",
                    "to keep cattle clean",
                    "to regulate their temperature"
                ]
            },
            {
                "question": "What state of matter is smoke?",
                "type": "text",
                "answer": [
                    "Gaseous state"
                ]
            },
            {
                "question": "To which down   group of animals does the one shown in the diagram below belong?",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2010_6.jpg",
                "answer": [
                    "mammals"
                ]
            },
            {
                "question": "Give the difference between evaporation and condensation ",
                "type": "check_box",
                "answer": [
                    "Evaporation is a change of a liquid to vapor/gas while condensation is the change if vapor to a liquid"
                ],
                "options": [
                    "Evaporation is a change of a liquid to vapor/gas while condensation is the change if vapor to a liquid",
                    "Evaporation is a change of a solid to vapor/gas while condensation is the change if vapor to a liquid",
                    "Evaporation is a change of a liquid to a solid while condensation is the change if vapor to a liquid",
                    "Evaporation is a change of a liquid to vapor/gas while condensation is the change of metal to a liquid"
                ]
            },
            {
                "question": "The diagram below shows a glass with water and a straight ruler in it.Use it to answer question 6 ",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2006_5.jpg",
                "sub_questions": [
                    {
                        "question": "What causes the ruler to appear bent?",
                        "type": "check_box",
                        "answer": [
                            "The ruler appears bent due to refraction of light"
                        ],
                        "options": [
                            "The ruler appears bent due to refraction of light",
                            "The ruler appears bent due to reflection of light",
                            "The ruler appears bent due to interpolation of light"
                        ]
                    }
                ]
            }
        ]
    },
    {
        "category": 7,
        "questions": [
            {
                "question": "why do houseflies lay their eggs in decaying matter",
                "type": "text",
                "answer": "So that their larvae can feed as soon as they hatch"
            },
            {
                "question": "Which method of heat transfer enables a person to iron clothes using a flat iron?",
                "type": "text",
                "answer": "Conduction"
            },
            {
                "question": "Give any one way in which litter is important in a poultry house.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Which sexually transmitted disease can cause blindness in babies?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Name the body organ which helps a person to know whether there is enough sugar in tea or not",
                "type": "text",
                "answer": "Tongue"
            },
            {
                "question": "Apart from mosses, give one other example of a spore bearing non-flowering plant",
                "type": "text",
                "answer": "Fern"
            },
            {
                "question": "Write down one bad behavior which is a result of drug abuse.",
                "type": "check_box",
                "answer": [
                    "Defilement"
                ],
                "options": [
                    "Defilement",
                    "",
                    "",
                    ""
                ]
            },
            {
                "question": "Give a reason why a tsetse fly is dangerous to cattle.",
                "type": "check_box",
                "answer": [
                    "It spread nagana to cattle and sleeping sickness to man",
                    "It spread sleeping sickness to cattle and nagana to man",
                    "It spread malaria to cattle and sleeping sickness to man"
                ]
            },
            {
                "question": "Give any one use of wearing sun glasses.",
                "type": "check_box",
                "answer": [
                    "Prevent direct sunrays that may damage the eye"
                ],
                "options": [
                    "Prevent direct sunrays that may damage the eye",
                    "Prevent indirect sunrays that may damage the eye",
                    "Prevent direct sunrays that may damage the skull",
                    "Prevent direct sunrays that may damage the skin"
                ]
            },
            {
                "question": "Why is an earth-worm called an invertebrate?",
                "type": "check_box",
                "answer": [
                    "It does not posses a vertebral column or back bone"
                ],
                "options": [
                    "It does not posses a vertebral column or back bone",
                    "It  possesses a vertebral column or back bone",
                    "it has a long body",
                    "It lives in soil"
                ]
            },
            {
                "question": "What First Aid would be given to Jamada who has burnt his fingers with a hot metal?",
                "type": "check_box",
                "answer": [
                    "Place the fingers in cold water"
                ],
                "options": [
                    "Place the fingers in cold water",
                    "Place the fingers in hot water",
                    "Place the fingers in steam",
                    "use a plaster"
                ]
            }
        ]
    },
    {
        "category": 8,
        "questions": [
            {
                "question": "Why is a fuse made of a thin wire?",
                "type": "text",
                "answer": "So that it can easily melt or blow when excess current flows through"
            },
            {
                "question": "State any one way in which alcoholism affects a family",
                "type": "text",
                "answer": [
                    "School dropout due to alcoholic failing to pay fee or attend classes",
                    "Self-neglect of the alcoholic",
                    "Increased accident of an alcoholic",
                    "Marital conflicts and abusive language",
                    "Fighting and violence- alcoholics have a tendency of fighting and being violent.",
                    "Robbery"
                ]
            },
            {
                "question": "Why should cold water be poured on the part of the body which has been scalded or burnt?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Apart from the bright coloured petals, state other thing that attracts insects to flowers.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Name the human body organ that uses light for it to function",
                "type": "text",
                "answer": "The eye"
            },
            {
                "question": "Give any one advantage of pruning tress",
                "type": "text",
                "answer": [
                    "eases weeding",
                    "reduce competition for light",
                    "Reduces spread of diseases ",
                    "for better yield "
                ]
            },
            {
                "question": "Give any one advantage of pruning tress",
                "type": "text",
                "answer": [
                    "Whale",
                    "dolphin",
                    "hippopotamus "
                ]
            },
            {
                "question": "Name any one mammal that lives in water",
                "type": "text",
                "answer": [
                    "Whale",
                    "dolphin",
                    "hippopotamus "
                ]
            },
            {
                "question": "State one types of accidents whose First Aid is to put the affected part of the body in cold water",
                "type": "check_box",
                "answer": "Burns",
                "options": [
                    "Burns",
                    "fracture",
                    "boils"
                ]
            },
            {
                "question": "Name one type of seed dispersal.",
                "type": "check_box",
                "answer": [
                    "Animal dispersal"
                ],
                "options": [
                    "Animal dispersal",
                    "tree dispersal",
                    "dispersal by fire"
                ]
            },
            {
                "question": "Why is a maize grain grouped under monocotyledonous seeds?",
                "type": "check_box",
                "answer": [
                    "Has one cotyledon"
                ],
                "options": [
                    "Has one cotyledon",
                    "Has two cotyledons",
                    "Has no cotyledon",
                    "Has three cotyledon"
                ]
            },
            {
                "question": "Give any one function of the human skeleton",
                "type": "check_box",
                "answer": [
                    "Provide shape of organism"
                ],
                "options": [
                    "Provide shape of organism",
                    "Provide food nutrients",
                    "Manufacture white blood cells",
                    "responsible for immunity"
                ]
            },
            {
                "question": "Give any one practice that can help to control the spreads of cholera in a school.",
                "type": "check_box",
                "answer": [
                    "Proper disposal of wastes"
                ],
                "options": [
                    "Proper disposal of wastes",
                    "immunization against cholera",
                    "Eating cold food",
                    "washing hands without soap"
                ]
            }
        ]
    },
    {
        "category": 9,
        "questions": [
            {
                "question": "what type of objects do not form shadows",
                "type": "text",
                "answer": "Transparent Objects"
            },
            {
                "question": "where are seeds of corniferous",
                "type": "text",
                "answer": "Transparent Objects"
            },
            {
                "question": "State any one reason why animals make sound.",
                "type": "text",
                "answer": [
                    "To sound warning",
                    "To entice mates",
                    "To express pain",
                    "To express happiness",
                    "To sense obstacles during motion e.g. bats",
                    "To protect territories"
                ]
            },
            {
                "question": "Apart from decomposition, in which other way is soil formed?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "State any one way in which drought affects food production in the community.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "State one way in which echo can be reduced in a hall",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Which method of heat transfer helps farmers to dry their harvested crops?",
                "type": "text",
                "answer": "Radiation"
            },
            {
                "question": "Give a reason why people keep cats in their homes.",
                "type": "text",
                "answer": "Kept as pet"
            },
            {
                "question": "Why should children be given food which is rich in proteins?",
                "type": "check_box",
                "answer": "To build their body cells",
                "options": [
                    "To build their body cells",
                    "To avoid constipation",
                    "they are too young"
                ]
            },
            {
                "question": ". Sunbirds visit flowers to get nectar, how do plants benefit from these birds?",
                "type": "check_box",
                "answer": [
                    "Plants get pollinated"
                ],
                "options": [
                    "Plants get pollinated",
                    "plants get nutrients",
                    "plants get sunlight",
                    "plants get oxygen "
                ]
            },
            {
                "question": "How is reproduction in beans different from that in ferns?",
                "type": "check_box",
                "answer": [
                    "Beans reproduce by seed whereas fern reproduce by spores"
                ],
                "options": [
                    "Beans reproduce by seed whereas fern reproduce by spores",
                    "Beans reproduce by spores whereas fern reproduce by seeds",
                    "Beans reproduce by flowers whereas fern reproduce by seeds",
                    "Beans reproduce by seed whereas fern reproduce by air"
                ]
            },
            {
                "question": "Write activity that can help to reduce snake bites in homes.",
                "type": "check_box",
                "answer": [
                    "Slashing the bushes around a home and rearing cat to eat snakes"
                ],
                "options": [
                    "Slashing the bushes around a home and rearing cat to eat snakes",
                    "Fencing the home",
                    "sweeping the compound",
                    "planting trees"
                ]
            },
            {
                "question": "What is the importance of a web to a spider?",
                "type": "check_box",
                "answer": [
                    "For movement from one place to another"
                ],
                "options": [
                    "For movement from one place to another",
                    "For design",
                    "For trapping big animals",
                    "spiders eat webs"
                ]
            },
            {
                "question": "Give any one reason why people plant trees around their houses.",
                "type": "check_box",
                "answer": [
                    "Trees act as wind breakers"
                ],
                "options": [
                    "Trees act as wind breakers",
                    "trees  attract lightning",
                    "attract carterpillars",
                    "attract snakes"
                ]
            }
        ]
    },
    {
        "category": 10,
        "questions": [
            {
                "question": "why does water drain faster in sand soil than in clay soil",
                "type": "text",
                "answer": "The space between sand particles is large for water to flow through it. Hence sandy soil drains faster than other types of soil."
            },
            {
                "question": "How is the function of anthers in flowers similar to that of testes in humans?",
                "type": "text",
                "answer": "Both produce sperm cells necessary for fertilisation during reproduction"
            },
            {
                "question": "Name the process through which water turns into ice.",
                "type": "text",
                "answer": "Freezing"
            },
            {
                "question": "What is the importance of smoke during the harvesting of honey?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "State the difference between the feeding of a tapeworm and a hookworm.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "The diagram below shows a piece of metal dipped in a jug containing hot water. Use it to answer the questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2014_10.jpg",
                "sub_questions": [
                    {
                        "question": "How does part A become hot yet it is not in hot water?",
                        "type": "text",
                        "answer": "By conduction"
                    },
                    {
                        "question": "Give one way in which the cause of a scald is similar to that of a burn.",
                        "type": "check_box",
                        "answer": [
                            "Both injuries are caused by heat energy"
                        ],
                        "options": [
                            "Both injuries are caused by heat energy",
                            "Both injuries are caused by falls",
                            "Both injuries are caused by car accidents",
                            "Both injuries are caused by sharp objects"
                        ]
                    }
                ]
            },
            {
                "question": "How does a fish benefit from its slippery body?",
                "type": "text",
                "answer": "Easily escape from the grip of its enemy"
            },
            {
                "question": "Why does a farmer cut off leaves of a banana sucker before planting it?",
                "type": "text",
                "answer": "To reduce the rate of transpiration"
            },
            {
                "question": "Give one way in which plants depend on soil.",
                "type": "check_box",
                "answer": "Plants get water from the soil",
                "options": [
                    "Plants get water from the soil",
                    "Plants get sunlightfrom the soil"
                ]
            },
            {
                "question": "The diagram below is of an Irish potato tuber.Use it to answer the question that follows.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2010_10.jpg",
                "sub_questions": [
                    {
                        "question": "What is the use of the part marked S?",
                        "type": "check_box",
                        "answer": [
                            "develops into a new shoot"
                        ],
                        "options": [
                            "develops into a new shoot",
                            "develops into a fruit",
                            "develops into a new root",
                            "develops into leaves"
                        ]
                    }
                ]
            },
            {
                "question": "How does fish help in controlling the spread of malaria?",
                "type": "check_box",
                "answer": [
                    "Feed on mosquito larvae "
                ],
                "options": [
                    "Feed on mosquito larvae ",
                    "produce mosquito larvae ",
                    "Feed on mosquitoes",
                    "Feed on mosquito eggs"
                ]
            },
            {
                "question": "Give any one way in which the spread of scabies can be controlled.",
                "type": "check_box",
                "answer": [
                    "Preventing contact of a heathy person with a person with scabies"
                ],
                "options": [
                    "Preventing contact of a heathy person with a person with scabies",
                    "sharing cloths, towels and basins with an infected person since it shows care for the sick",
                    "Avoid bathing till it cures",
                    "do not change clothes to avoid contaminating other clothes"
                ]
            },
            {
                "question": "Mention a disease which makes a person to pass out watery stool with blood",
                "type": "text",
                "answer": [
                    "Dysentery"
                ]
            },
            {
                "question": "Apart from the skin and lungs name any one other execratory organ.",
                "type": "text",
                "answer": [
                    "Kidney "
                ]
            },
            {
                "question": "Give any one way in which soil is important to a plant.",
                "type": "check_box",
                "answer": [
                    "Soil provide anchorage"
                ],
                "options": [
                    "Soil provide anchorage",
                    "Soil provide sunglight",
                    "Soil provides carbondioxide",
                    "Soil provides oxygen"
                ]
            }
        ]
    },
    {
        "category": 11,
        "questions": [
            {
                "question": "How is the eardrum useful in the process of hearing?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "How do farmers control the breeding of poor quality animals on their farms?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "In which one way does toothpaste promote oral health?",
                "type": "text",
                "answer": [
                    "Strength tooth by providing calcium and fluoride",
                    "Ensure effective cleaning of teeth by removing plaque/bacteria from the teeth"
                ]
            },
            {
                "question": "State the class of lever in which the effort is between the fulcrum and the load.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Why is water obtained by distillation method not good for drinking?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Give any one disadvantage of harvesting honey using fire",
                "type": "text",
                "answer": [
                    "Kills bees",
                    "Buns the hive",
                    "Make honey dirty",
                    "Melt honey comb"
                ]
            },
            {
                "question": "In which way does the use of biogas conserve the environment?",
                "type": "text",
                "answer": [
                    "Reduces cutting down trees that absorb carbon dioxide",
                    "Reduced use of petroleum products"
                ]
            },
            {
                "question": "Apart from pasteurizing, give any one way milk can be prevented from going bad",
                "type": "text",
                "answer": [
                    "Boiling",
                    "Refrigeration"
                ]
            },
            {
                "question": "Give one way in which the umbilical cord is important to the developing focus in the uterus",
                "type": "check_box",
                "answer": [
                    "It is a passage of digested food to the foetus"
                ],
                "options": [
                    "It is a passage of digested food to the foetus",
                    "It is long ",
                    "It has muscles on its walls",
                    "it is U-shaped"
                ]
            },
            {
                "question": "Name the best soil for making pots.",
                "type": "text",
                "answer": [
                    "Clay soil"
                ]
            },
            {
                "question": "Why is immunization of children carried out free of charge in Uganda?",
                "type": "text",
                "answer": [
                    "to enable all children be protected from the killer diseases "
                ]
            },
            {
                "question": "Mention any one way in which communicable diseases are spread.",
                "type": "check_box",
                "answer": [
                    "Shaking hands"
                ],
                "options": [
                    "Shaking hands",
                    "Being in isolation",
                    "using hand sanitizers",
                    "using soap"
                ]
            },
            {
                "question": "State the difference between volume and mass",
                "type": "check_box",
                "answer": [
                    "Volume is the space occupied by matter while mass is a quantity of matter in a substance contains"
                ],
                "options": [
                    "Volume is the space occupied by matter while mass is a quantity of matter in a substance contains",
                    "Volume is the quantity of matter a substance contains while mass is the space occupied by an object",
                    "volume is measured in kg while mass is measured in cm<sup>3</sup>",
                    "volume is measured in km while mass is measured in cm<sup>3</sup"
                ]
            },
            {
                "question": "Give any one way in which the breathing of a housefly is different from that of a rat.",
                "type": "check_box",
                "answer": [
                    "Insects breathes through spiracles whereas rat breath through lungs."
                ],
                "options": [
                    "A housefly breathes through spiracles whereas rat breath through lungs.",
                    "A housefly breathes through  lungs whereas rat breath through spiracles."
                ]
            }
        ]
    },
    {
        "category": 12,
        "questions": [
            {
                "question": "write down any one activity done to seedlings during hardening off?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "By what process is alcohol made from fruit juice?",
                "type": "text",
                "answer": "fermentation"
            },
            {
                "question": "State any one physical change that takes place during the process of distillation of alcohol.",
                "type": "text",
                "answer": "Evaporation"
            },
            {
                "question": "Give a reason why male anopheles mosquitoes do not transmit malaria parasites to humans.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "State the sense organ for feeling in the human body.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "How is BCG vaccine different from the polio vaccine in the way they are given to children.",
                "type": "text",
                "answer": "BCG vaccine is administered through injection while polio vaccine is administered orally"
            },
            {
                "question": "The diagram below shows a garden tool. Use it to answer questions 12 and 13",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2013_11.jpg",
                "sub_questions": [
                    {
                        "question": "Name the garden tool above",
                        "type": "text",
                        "answer": "Rake"
                    }
                ]
            },
            {
                "question": "Why is it important for a husband and his wife to be treated together if they have got gonorrhea?",
                "type": "check_box",
                "answer": "To prevent reinfection",
                "Options": [
                    "To promote hygiene",
                    "To improve self-care"
                ]
            },
            {
                "question": "Name any one characteristic of seeds which are dispersed by wind",
                "type": "check_box",
                "answer": "They are light",
                "options": [
                    "They are light",
                    "They are heavy"
                ]
            },
            {
                "question": "Write down one block in a food path.",
                "type": "check_box",
                "answer": [
                    "Pests"
                ],
                "options": [
                    "Pests",
                    "poor sanitation",
                    "deficiency diseases",
                    "immunizable diseases"
                ]
            },
            {
                "question": "Write one reason why people working in dirty areas should wear gumboots.",
                "type": "check_box",
                "answer": [
                    "To prevent cuts to the legs"
                ],
                "options": [
                    "To prevent cuts to the legs",
                    "To prevent snake bites",
                    "To promote PHC",
                    "to easily step on sharp objects"
                ]
            },
            {
                "question": "Whenever peter opens or closes his door, the hinges makes noise. What can he do to stop the noise when he is opening?",
                "type": "check_box",
                "answer": [
                    "Oiling the hinge to reduce friction"
                ],
                "options": [
                    "Oiling the hinge to reduce friction",
                    "Oiling the hinge to increase friction",
                    "Oiling the hinge to create friction",
                    "Removing the hinge totally"
                ]
            },
            {
                "question": "Give any one body characteristic which is common to a spider and a tick ",
                "type": "check_box",
                "answer": [
                    "Both have four pairs of limbs"
                ],
                "options": [
                    "Both have four pairs of limbs",
                    "Both have two pairs of limbs",
                    "Both are animals",
                    "Both three main body parts"
                ]
            },
            {
                "question": "Give any one way in which soil is important to a plant.",
                "type": "check_box",
                "answer": [
                    "Soil provide anchorage"
                ],
                "options": [
                    "Soil provide anchorage",
                    "Soil provide sunlight",
                    "Soil provides rain",
                    "Soil provides carbondioxide"
                ]
            }
        ]
    },
    {
        "category": 13,
        "questions": [
            {
                "question": "state any one characteristic of incisor teeth which makes them suitable for their function?",
                "type": "text",
                "answer": [
                    "they have a sharp, chiesel like shape with sharp edges to tear food",
                    " they have a single root which gives them stability"
                ]
            },
            {
                "question": "Give any one way in which school children can promote immunization in their community.",
                "type": "text",
                "answer": [
                    "",
                    ""
                ]
            },
            {
                "question": "What is the importance of the rings of cartilages of the trachea in the respiratory system?",
                "type": "text",
                "answer": "Maintain the trachea open when pressure falls"
            },
            {
                "question": "How can foot and mouth disease be controlled in cattle?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Which property of magnets enables magnetic compass to work?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Give one reason why washing of hands after visiting a latrine is a good practice.",
                "type": "text",
                "answer": "Removes germs from the hands"
            },
            {
                "question": "How is the garden tool above important to farmers?.",
                "type": "text",
                "answer": [
                    "For collecting rubbish ",
                    "For levelling soil in a nursery bed"
                ]
            },
            {
                "question": "Why do farmers carry out de –beaking in poultry farms?",
                "type": "text",
                "answer": [
                    "Debeaking is cutting of poultry to prevent cannibalism and egg eating"
                ],
                "Options": [
                    "",
                    ""
                ]
            },
            {
                "question": "The diagram below shows a fracture. Use it to answer question 13.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2011_13.jpg",
                "sub_questions": [
                    {
                        "question": "What type of fracture is shown in the diagram above?",
                        "type": " check-box",
                        "answer": [
                            "Simple fracture."
                        ],
                        "options": [
                            "Simple fracture.",
                            "compound fracture.",
                            "reflex fracture.",
                            "divided fractur"
                        ]
                    }
                ]
            },
            {
                "question": "Give one way in which health education is important in controlling over population.",
                "type": "check_box",
                "answer": [
                    "it encourages family planning"
                ],
                "options": [
                    "it encourages family planning",
                    "it controls the fertility in women",
                    "",
                    ""
                ]
            },
            {
                "question": "Why should young girls avoid getting pregnant?",
                "type": "check_box",
                "answer": [
                    "It may terminate their education to care for their siblings"
                ],
                "options": [
                    "It may terminate their education to care for their child",
                    "",
                    "",
                    ""
                ]
            },
            {
                "question": "Name one structure in a human which connects bone to bone.",
                "type": "text",
                "answer": [
                    "Ligament"
                ]
            },
            {
                "question": "What is the main cause of fainting?",
                "type": "text",
                "answer": [
                    "Shortage of blood supply to the brain"
                ]
            },
            {
                "question": "Why is a wet cloth put on the body of a person with fever?",
                "type": "check_box",
                "answer": [
                    "To reduce temperature"
                ],
                "options": [
                    "To reduce temperature",
                    "To increase temperature",
                    "To reduce sweat",
                    "wet cloths remove temperature"
                ]
            }
        ]
    },
    {
        "category": 14,
        "questions": [
            {
                "question": "How can the breeding of houseflies be controlled in our compounds",
                "type": " text",
                "answer": [
                    "Proper disposal of rubbish",
                    "keeping toilets clean"
                ]
            },
            {
                "question": "What First Aid would you give to a child who has taken paraffin?",
                "type": " text",
                "answer": ""
            },
            {
                "question": "Why does a nail sink in water?",
                "type": " text",
                "answer": "It is denser than water"
            },
            {
                "question": "State any one way in which the spread of scabies can be prevented.",
                "type": " text",
                "answer": ""
            },
            {
                "question": "Which process in plants has a similar function as sweating in humans?",
                "type": " text",
                "answer": "Transpiration"
            },
            {
                "question": "Which deficiency disease is caused by lack of vitamin d in children `s diet? ",
                "type": " text",
                "answer": "Rickets"
            },
            {
                "question": "State any one habit which helps to promote oral health. ",
                "type": " text",
                "answer": "Brushing teeth;"
            },
            {
                "question": "State the compass direction in which a freely suspended magnet will rest.",
                "type": "check_box",
                "answer": "North-south direction.",
                "options": [
                    "North-south direction.",
                    "southern direction.",
                    "western direction",
                    "north-eastern direction"
                ]
            },
            {
                "question": " How can a farmer make use of cow dung produced by his animals?",
                "type": "check_box",
                "answer": [
                    "Turns cow dung into manure"
                ],
                "options": [
                    "Turns cow dung into manure",
                    "Turns cow dung into a finished product",
                    "Turns cow dung into waste",
                    "Turns cow dung into artificial fertilizer"
                ]
            },
            {
                "question": "What type of farming should a farmer use in order to harvest both crops and wood?",
                "type": "text",
                "answer": [
                    "Agroforestry"
                ]
            },
            {
                "question": "",
                "type": "check_box",
                "answer": [
                    "Both petrol and food are oxidized to produce energy"
                ],
                "options": [
                    "Both petrol and food are oxidized to produce energy",
                    "Both petrol and food are de-oxidized to produce energy",
                    "Both petrol and food are oxidized to produce heat",
                    "Both petrol and food are liquified to produce energy"
                ]
            },
            {
                "question": "Suggest any one way in which human beings use energy light the sun.",
                "type": "check_box",
                "answer": [
                    "To see"
                ],
                "options": [
                    "To see",
                    "To smell",
                    "To hear",
                    "To walk"
                ]
            },
            {
                "question": "The diagram is of a blood cell. Use it to answer questions 14 and 15",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2006_14.jpg",
                "sub_questions": [
                    {
                        "question": "What type of blood cell is shown in the diagram?",
                        "type": "text",
                        "answer": [
                            "White blood cell"
                        ]
                    },
                    {
                        "question": "Give a reason for your answer in question 14 above",
                        "type": "check_box",
                        "answer": [
                            "Has an irregular shape and nucleus"
                        ],
                        "options": [
                            "Has an irregular shape and nucleus",
                            "Has a regular shape and nucleus",
                            "it is white in color",
                            "it is found in the blood"
                        ]
                    }
                ]
            }
        ]
    },
    {
        "category": 15,
        "questions": [
            {
                "question": "which deficiency disease is caused by lack of vitamin B1 in a diet?",
                "type": "text",
                "answer": "BeriBeri"
            },
            {
                "question": "State the importance of observing good posture to the human body",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Give any one danger of wetland drainage to water animals.",
                "type": "text",
                "answer": [
                    "Water animals lose habitats when wetlands are drained",
                    "animals die due to lack of water for breathing when wetlands are drained"
                ]
            },
            {
                "question": "The diagram below is of a human arm. Study and use it to answer questions 15 and 16.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2016_15.jpg",
                "sub_questions": [
                    {
                        "question": "Name muscle P.",
                        "type": " text",
                        "answer": "Bicep"
                    },
                    {
                        "question": "What happens to muscle Q when the arm is raised?",
                        "type": " text",
                        "answer": "It Relaxes"
                    }
                ]
            },
            {
                "question": "Why do some plants shed their leaves during dry season?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Apart from taking wrong dose, give one other way in which drugs are misused.",
                "type": "text",
                "answer": [
                    "Taking drugs when not sick",
                    "Taking drugs for wrong disease",
                    "Taking drug at wrong time",
                    "Sharing drug with another person"
                ]
            },
            {
                "question": "Why is the water obtained through filtration not necessarily safe for drinking?",
                "type": "text",
                "answer": "It contain germs"
            },
            {
                "question": "State one use of soil to earthworms.",
                "type": "text",
                "answer": "Soil is a home for earthworm"
            },
            {
                "question": "What is the use of gills in a mushroom?",
                "type": "check_box",
                "answer": "Gills of a mushroom produce spores",
                "options": [
                    "Gills of a mushroom produce spores",
                    "produce food",
                    "produce starch",
                    "Gills of a mushroom produce water"
                ]
            },
            {
                "question": "State one way in which hens are important in prevention of deficiency diseases in man.",
                "type": "check_box",
                "answer": [
                    "source of proteins"
                ],
                "options": [
                    "source of proteins",
                    "source of vitamins",
                    "source of carbohydrates",
                    "source of starch"
                ]
            },
            {
                "question": "The diagram below shows a method of making water clean.Use it to answer the question that follows.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2010_15.jpg",
                "sub_questions": [
                    {
                        "question": "Name the method used above: ",
                        "type": "filtration",
                        "answer": ""
                    },
                    {
                        "question": "How are ferns, mosses and liverworts similar?",
                        "type": "check_box",
                        "answer": [
                            "They are non-flowering plants"
                        ],
                        "options": [
                            "They are non-flowering plants ",
                            "They are flowering plants ",
                            "They reproduce by seeds ",
                            "propagated by stems"
                        ]
                    }
                ]
            },
            {
                "question": "Give   any one way in which sweating is important in a human body",
                "type": "check_box",
                "answer": [
                    "To cool the body"
                ],
                "options": [
                    "To cool the body",
                    "To warm the body",
                    "for body growth",
                    "for reproduction"
                ]
            }
        ]
    },
    {
        "category": 16,
        "questions": [
            {
                "question": "the diagram below shows the head of a tapeworm. study it and answer the following questions",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2019_16.jpg",
                "sub_questions": [
                    {
                        "question": "Name the part marked A",
                        "type": " text",
                        "answer": "sucker"
                    },
                    {
                        "question": "How is part B useful to the tapeworm?",
                        "type": " text",
                        "answer": "the hooks anchor the tapeworm firmly to the intestinal walls of the host"
                    }
                ]
            },
            {
                "question": "the diagram below shows a fruit crop. use it to answer the following questions",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2018_16.jpg ",
                "sub_questions": [
                    {
                        "question": "How is the above crop propagated?",
                        "type": "text",
                        "answer": "using the crown"
                    },
                    {
                        "question": "give one other crop which is propagated in the same way as the crop shown above",
                        "type": "text",
                        "answer": [
                            "Bananas",
                            "Sweet Potatoes",
                            "Garlic",
                            "Ginger"
                        ]
                    }
                ]
            },
            {
                "question": "Sate any one principle of primary Health care.",
                "type": "text",
                "answer": [
                    "personal hygiene",
                    "sanitation",
                    "good nutrition",
                    "maternal and child health",
                    "prevention and control of endemic diseases.",
                    "health education"
                ]
            },
            {
                "question": "Mention one permanent method of birth control in males.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Which condition in babies is shown by sunken spot on the head?",
                "type": "text",
                "answer": "Dehydration"
            },
            {
                "question": "What plant system does the plumule make when it grows?",
                "type": "text",
                "answer": "Into a shoot system"
            },
            {
                "question": "The diagram below shows a crop rotation cycle",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2012_15.jpg",
                "sub_questions": [
                    {
                        "question": "What is the importance of beans in this cycle to the rest of the crops?",
                        "type": " check-box",
                        "answer": "Beans contain bacteria in root nodules that fix nitrogen to the soil",
                        "Options": [
                            "",
                            ""
                        ]
                    }
                ]
            },
            {
                "question": "Give one way in which P7 pupils can promote immunization in their families",
                "type": "check_box",
                "answer": "By taking siblings for immunization/",
                "options": [
                    "By taking siblings for immunization",
                    "By immunizing people at home",
                    "By reporting them to the nearest police station"
                ]
            },
            {
                "question": "Arrange the following organisms in the correct order of the food chain from 1st to last",
                "sub_questions": [
                    {
                        "question": "Caterpillar",
                        "type": "MultipleChoice",
                        "answer": [
                            "3r<sup>d</sup>"
                        ],
                        "Options": [
                            "2n<sup>d</sup>",
                            "2n<sup>d</sup>",
                            "1s<sup>t</sup>",
                            "4t<sup>h</sup>"
                        ]
                    },
                    {
                        "question": "dog",
                        "type": "MultipleChoice",
                        "answer": [
                            "2n<sup>d</sup>"
                        ],
                        "Options": [
                            "34t<sup>h</sup>",
                            "2n<sup>d</sup>",
                            "1s<sup>t</sup>",
                            "4t<sup>h</sup>"
                        ]
                    },
                    {
                        "question": "leaves",
                        "type": "MultipleChoice",
                        "answer": [
                            "2n<sup>d</sup>"
                        ],
                        "Options": [
                            "1s<sup>t</sup>",
                            "2n<sup>d</sup>",
                            "1s<sup>t</sup>",
                            "4t<sup>h</sup>"
                        ]
                    },
                    {
                        "question": "bird",
                        "type": "MultipleChoice",
                        "answer": [
                            "3r<sup>d</sup>"
                        ],
                        "Options": [
                            "3r<sup>d</sup>",
                            "2n<sup>d</sup>",
                            "1s<sup>t</sup>",
                            "4t<sup>h</sup>"
                        ]
                    }
                ]
            },
            {
                "question": "Why do objects thrown in the air fall back on the ground?",
                "type": "text",
                "answer": [
                    "Due to the force of gravity"
                ]
            },
            {
                "question": "State any one health reason why the building of industries should be limited in an area",
                "type": "check_box",
                "answer": [
                    "Reduce noise in residential area that may affect the ear drum"
                ],
                "options": [
                    "Reduce noise in residential area that may affect the ear drum",
                    "increase noise in residential area that may affect the ear drum",
                    "Reduce noise in residential area that may affect sight"
                ]
            },
            {
                "question": "The diagram below a foetus in the womb. Use it to answer questions 16 and 17",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2010_16.jpg",
                "sub_questions": [
                    {
                        "question": "Name the part marked with letter A",
                        "type": "text",
                        "answer": "Placenta"
                    },
                    {
                        "question": "State any one way in which the part marked with letter B is useful to the foetus ",
                        "type": " check-box",
                        "answer": [
                            "Conduct nutrients and oxygen to the fetus"
                        ],
                        "options": [
                            "Conduct nutrients and oxygen to the fetus",
                            "Absorb shock",
                            "bring in carbon dioxide to  the fetus"
                        ]
                    }
                ]
            },
            {
                "question": " Give any one reason why plants are grouped among living things.",
                "type": "check_box",
                "answer": [
                    "They grow"
                ],
                "options": [
                    "They grow",
                    "they dont feed",
                    "they only excrete",
                    "they dont respond to stimulus"
                ]
            }
        ]
    },
    {
        "category": 17,
        "questions": [
            {
                "question": "Why are laying boxes put in dark corners of a poultry house?",
                "type": " text",
                "answer": "To provide chickens a safe location where they can lay their eggs in peace"
            },
            {
                "question": "Which intestinal worm enters the human body through bare feet?",
                "type": " text",
                "answer": "hook worm"
            },
            {
                "question": "Why does a fish die soon after it has been removed from water?",
                "type": " text",
                "answer": "hook worm"
            },
            {
                "question": "Give one way in which plants benefit from animals for photosynthesis.",
                "type": " text",
                "answer": "Get carbon dioxide"
            },
            {
                "question": "How does leaching reduce soil fertility?",
                "type": " text",
                "answer": "Causes nutrients to sink deeper in the soil where they cannot be reached by root"
            },
            {
                "question": "Which communicable disease in human affects both the skins and the skeletal system?",
                "type": " text",
                "answer": "Leprosy"
            },
            {
                "question": ".  Apart from pregnant mothers, young and disabled people, give any one other group of people who need special care in a family.",
                "type": "check_box",
                "answer": [
                    "The elderly"
                ],
                "options": [
                    "The elderly",
                    "Adults",
                    " students",
                    "parents"
                ]
            },
            {
                "question": "Why is a person with hook worms likely to suffer from anemia?",
                "type": "text",
                "answer": [
                    "Hookworm suck blood"
                ]
            },
            {
                "question": "Why should communities fence open spring wells?",
                "type": "check_box",
                "answer": [
                    "To prevent contamination by animals"
                ],
                "options": [
                    "To prevent contamination by animals",
                    "To protect them from thieves",
                    "To make fetching of water easy"
                ]
            },
            {
                "question": "Why should you chew food before swallowing it?",
                "type": "check_box",
                "answer": [
                    "To break food into small particles that increases surface area for enzyme activity and to ease swallowing."
                ],
                "options": [
                    "To break food into small particles that increases surface area for enzyme activity and to ease swallowing.",
                    "To break food into bigger particles that increases surface area for enzyme activity and to ease swallowing.",
                    "To break food into small particles that reduces surface area for enzyme activity and to ease swallowing.",
                    "To break food into small particles that increases surface area for enzyme activity and to harden swallowing."
                ]
            },
            {
                "question": "Give any one example of a leguminous crop.",
                "type": "check_box",
                "answer": [
                    "Soya peas"
                ],
                "options": [
                    "Soya peas",
                    "maize",
                    "mangoes",
                    "apple"
                ]
            }
        ]
    },
    {
        "category": 18,
        "questions": [
            {
                "question": "state one characteristic of essential drugs?",
                "type": " text",
                "answer": ""
            },
            {
                "question": "Give any one way in which human beings can prevent tapeworm infection.",
                "type": " text",
                "answer": [
                    "Washing food fruits before eating",
                    "Boiling meat to readiness to kill tapeworm eggs"
                ]
            },
            {
                "question": "Apart from causing diseases, give one other way in which fungi are harmful to people?",
                "type": " text",
                "answer": []
            },
            {
                "question": "How do plants make use of the gas given out by animals during respiration??",
                "type": " text",
                "answer": "used in the process of phtosynthesis"
            },
            {
                "question": "Give the use of a drenching gun to a cattle farmer.",
                "type": " text",
                "answer": ""
            },
            {
                "question": "How is sun-drying helpful in the preservation of food crops?",
                "type": " text",
                "answer": "Reduce water content and dry them"
            },
            {
                "question": "Why is it advisable to leave the pit of a VIP latrine open?",
                "type": " text",
                "answer": [
                    "To allow fresh air drives out bad smell",
                    "To allow flies to enter"
                ]
            },
            {
                "question": "Apart from carbohydrates, name one other class of food that gives the body energy",
                "type": " check-box",
                "answer": [
                    "Fats",
                    "oils",
                    "lipids"
                ],
                "options": []
            },
            {
                "question": "In which way is a fuse similar to s switch in a simple electric circuit?",
                "type": "check_box",
                "answer": [
                    "Bot a fuse and a switch break the circuit."
                ],
                "options": [
                    "Both a fuse and a switch break the circuit.",
                    "Both a fuse and a switch open the circuit.",
                    "Bot a fuse and a switch start the circuit.",
                    "Bot a fuse and a switch belong to the circuit.",
                    "Bot a fuse and a switch are similar."
                ]
            },
            {
                "question": "The diagram below is a weather instrument.Study it and answer question 18 ",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2010_17.jpg",
                "sub_questions": [
                    {
                        "question": "What is the use   of the instrument in a weather station?",
                        "type": "check_box",
                        "answer": [
                            "To measure amount of rain fall received"
                        ],
                        "options": [
                            "To measure amount of rain fall received",
                            "To measure amount of sunshine",
                            "To measure amount of air pressure",
                            "To measure wind speed"
                        ]
                    }
                ]
            },
            {
                "question": "State one ways of preventing athlete`s foot disease.",
                "type": "check_box",
                "answer": [
                    "Avoid sharing shoes"
                ],
                "options": [
                    "Avoid sharing shoes",
                    "Avoid sharing combs",
                    "Avoid sharing towels",
                    "Avoid sharing soap"
                ]
            },
            {
                "question": "How is a chameleon important to human beings?",
                "type": "check_box",
                "answer": [
                    "Feeds on vectors"
                ],
                "options": [
                    "Feeds on vectors",
                    "acts as food",
                    "source of protein",
                    "Feeds on parasites"
                ]
            },
            {
                "question": "Which part of human eye works like a film in camera?",
                "type": "text",
                "answer": [
                    "Retina"
                ]
            },
            {
                "question": "How does a snail protect itself from its enemies?",
                "type": "text",
                "answer": [
                    "By hiding in its shell"
                ]
            }
        ]
    },
    {
        "category": 19,
        "questions": [
            {
                "question": "Give any one way in which you can re-use plastic objects at home?",
                "type": " text",
                "answer": ""
            },
            {
                "question": "How does smoking control the effect of bacteria in food preservation?",
                "type": " text",
                "answer": "Smoking food removes moisture from the surface of food thus eliminating the necessary conditions for bacteria to multiply"
            },
            {
                "question": "How is reflected sound helpful to sailors?",
                "type": " text",
                "answer": [
                    "To detect obstacles in its surrounding",
                    "to detect depth of water."
                ]
            },
            {
                "question": "State any one use of plane mirrors to people",
                "type": " text",
                "answer": ""
            },
            {
                "question": "Give one reason why pregnant women are advised to feed on rich proteins.",
                "type": " text",
                "answer": ""
            },
            {
                "question": "Why is water grouped under renewable resource?",
                "type": " text",
                "answer": [
                    "It cannot be exhausted",
                    "Can be replaced when used"
                ]
            },
            {
                "question": "Name the cattle disease whose sign is a swollen and painful udder.",
                "type": " text",
                "answer": [
                    "Mastitis"
                ]
            },
            {
                "question": "In which way is the sun important in the formation of rain?",
                "type": " text",
                "answer": [
                    "Evaporation"
                ],
                "options": [
                    "Evaporation",
                    "transpiration"
                ]
            },
            {
                "question": "Why should a nurse shake the clinical thermometer before it is used on another patient?",
                "type": "check_box",
                "answer": "to take mercury back to the bulb",
                "options": [
                    "to take mercury back to the bulb",
                    "to take mercury out of the bulb",
                    "to make the mercury stick on the walls",
                    "to increase traction"
                ]
            },
            {
                "question": "Give a reason for keeping Ebola victims away from other people.",
                "type": "text",
                "answer": [
                    "To prevent easy spread"
                ]
            },
            {
                "question": "Why is an ostrich unable to fly?",
                "type": "check_box",
                "answer": [
                    "it has small weak wings"
                ],
                "options": [
                    "it has small weak wings",
                    "it has large wings",
                    "It has no wings",
                    "It has no legs"
                ]
            },
            {
                "question": "Suggest any one use of a tourniquet.",
                "type": "check_box",
                "answer": [
                    "It is tied tightly around the arm to reduce bleeding from a cut or injury"
                ],
                "options": [
                    "It is tied tightly around the arm of arm to reduce bleeding from a cut or injury",
                    "It is tied loosely around the arm to reduce bleeding from a cut or injury",
                    "It is tied tightly around the arm  to incrase bleeding from a cut or injury",
                    "It is loosely tied around the arm to increase bleeding from a cut or injury"
                ]
            },
            {
                "question": " Give any one activity that can be done at home to control the spread of malaria.",
                "type": "check_box",
                "answer": [
                    "Sleeping in insecticide treated nets"
                ],
                "options": [
                    "Sleeping in insecticide treated nets",
                    "Sleeping in  nets",
                    "Maintaining bushes around the house"
                ]
            },
            {
                "question": " Give any one disadvantage of keeping poultry on a free-range system.",
                "type": "check_box",
                "answer": [
                    "Birds can easily catch diseases"
                ],
                "options": [
                    "Birds can easily catch diseases",
                    "Birds develop vices",
                    "Birds can suffocate",
                    "Birds easily die"
                ]
            }
        ]
    },
    {
        "category": 20,
        "questions": [
            {
                "question": "How do carnivorous animals get energy from plants?",
                "type": " text",
                "answer": "Carnivorous animals eat herbivorous animals that eat plants therefore the nutrients are passed on to the canivorous animals"
            },
            {
                "question": "How are arteries able to withstand pressure of blood pumped from the heart?",
                "type": " text",
                "answer": "Arteries are elastic and have thick walls to withstand high pressure"
            },
            {
                "question": "Give one way in which molluscs are different from other invertebrates.",
                "type": " text",
                "answer": [
                    "Have soft bodies",
                    "May have shells to protect their bodies"
                ]
            },
            {
                "question": "Apart from helping in pollination, give one other way in which wind is useful to plants. ",
                "type": " text",
                "answer": ""
            },
            {
                "question": "The diagram below shows parts of a joint. Study and use it to answer questions 20 and 21.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2015_20.jpg",
                "sub_questions": [
                    {
                        "question": "Name the part marked with letter X",
                        "type": "text",
                        "answer": ""
                    },
                    {
                        "question": "Using letter W, label the part that reduces friction in the joint apart from the synovial fluid.",
                        "type": "text",
                        "answer": ""
                    }
                ]
            },
            {
                "question": "Which mineral helps in formation of read blood cells in the human body? ",
                "type": " text",
                "answer": "Iron"
            },
            {
                "question": "In which part of the female reproductive system does fertilization take place?",
                "type": " text",
                "answer": "Oviduct or fallopian tubules"
            },
            {
                "question": "State any one method used to make dirty water clean",
                "type": " text",
                "answer": [
                    "Filtration",
                    "Distillation",
                    "Decanting"
                ]
            },
            {
                "question": "State one way in which a farmer can control crop disease without using chemicals",
                "type": "check_box",
                "answer": [
                    "Practicing crop rotation"
                ],
                "options": [
                    "Practicing crop rotation",
                    "Practicing mulching",
                    "Practicing deforestation",
                    "Practicing re- afforestation"
                ]
            },
            {
                "question": "The diagram below part of the human digestive system.Use it to answer question 20 ",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2010_20.jpg",
                "sub_questions": [
                    {
                        "question": "Name the class of food which is digested in the part marked A.",
                        "type": "text",
                        "answer": "Protein "
                    }
                ]
            },
            {
                "question": "Apart from trachoma, name one other eye disease.",
                "type": "check_box",
                "answer": [
                    "Night blindness"
                ],
                "options": [
                    "Night blindness",
                    "malaria",
                    "headache",
                    "goitre"
                ]
            },
            {
                "question": "State any one way in which soil is important to insects",
                "type": "text",
                "answer": [
                    "It is food for some insects"
                ],
                "options": [
                    "It is food for some insects",
                    "It is food for all insects",
                    "It is food for some animals",
                    "It is food for all insects"
                ]
            },
            {
                "question": "In which way is a person who HIV positive different from the one who has developed AIDS?",
                "type": "check_box",
                "answer": [
                    "A person with HIV show no sign of the disease while a student with AIDS has signs like cough, skin rush, fever, loss of weight."
                ],
                "options": [
                    "A person with HIV show no sign of the disease while a student with AIDS has signs like cough, skin rush, fever, loss of weight.",
                    "A person with HIV shows signs of the disease like cough, skin rush, fever, loss of weight.while a student with AIDS has no signs"
                ]
            },
            {
                "question": "Give any one reason why air is considered to be matter",
                "type": "check_box",
                "answer": [
                    "It has weight"
                ],
                "options": [
                    "It has weight",
                    "it is a vacuum",
                    "it is invisble",
                    "it has rare gases"
                ]
            }
        ]
    },
    {
        "category": 21,
        "questions": [
            {
                "question": "state one charactersistic of non-renewable resources?",
                "type": " text",
                "answer": [
                    "not easily refilled",
                    "depleted overtime",
                    "they are not environment friendly"
                ]
            },
            {
                "question": "State any one way in which people use sand soil.",
                "type": " text",
                "answer": "For Building"
            },
            {
                "question": "Give any one mineral deficiency disease in humans.",
                "type": " text",
                "answer": [
                    "Anemia due to lack of iron",
                    "Goiter due to lack of iodine",
                    "Osteoporosis due to deficiency of calcium"
                ]
            },
            {
                "question": "Which part of a maize grain has a similar function as the cotyledon of a bean seed?",
                "type": " text",
                "answer": ""
            },
            {
                "question": "Apart from melting, state any one other physical change that takes place in the presence of heat.",
                "type": " text",
                "answer": "evaporation or sublimation"
            },
            {
                "question": "Why is the left side of the heart able to pump blood to most body parts?",
                "type": " text",
                "answer": "It is thick and muscular to generate enough pressure"
            },
            {
                "question": "How does a sweater help to keep a person warm during cold days?",
                "type": " text",
                "answer": "A sweater prevents heat loss"
            },
            {
                "question": "Name the turning point of a machine.",
                "type": "check_box",
                "answer": [
                    "Pivot"
                ],
                "options": [
                    "Pivot",
                    "wedge",
                    "axle",
                    "lever"
                ]
            },
            {
                "question": "Write down one way in which a primary seven pupil can help in the control of cholera in a community.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2010_21.jpg",
                "sub_questions": [
                    {
                        "question": "Write down one way in which a primary seven pupil can help in the control of cholera in a community.",
                        "type": "",
                        "answer": [
                            "proper use of latrine"
                        ],
                        "options": [
                            "proper use of latrine",
                            "proper use of the dining hall",
                            "proper use of the classroom",
                            "proper use of  textbooks in the library"
                        ]
                    }
                ]
            },
            {
                "question": "What system is responsible for the removal of waste materials from our bodies?",
                "type": "text",
                "answer": [
                    "Excretory system"
                ]
            },
            {
                "question": "What causes kwashiorkor in children?",
                "type": "text",
                "answer": [
                    "Deficiency of protein"
                ]
            },
            {
                "question": "Give any one reason why people like the brick makers, need to feed on more carbohydrates",
                "type": "text",
                "answer": [
                    "To provide energy"
                ]
            },
            {
                "question": "Give any one reason why people like the brick makers, need to feed on more carbohydrates",
                "type": "text",
                "answer": [
                    "To provide energy"
                ]
            }
        ]
    },
    {
        "category": 22,
        "questions": [
            {
                "question": "state any one difference in function between an artery and a vein",
                "type": " text",
                "answer": "An artery carries oxygenated blood away from the heart to the body whereas a vein carries deoxygenated blood from the body to the heart"
            },
            {
                "question": "Give any one disadvantage of overcrowding chicken in their house.",
                "type": " text",
                "answer": [
                    "Increased risk of disease spread",
                    "increased risk of suffocation"
                ]
            },
            {
                "question": "Name the type of bees, which develops from unfertilized eggs.",
                "type": " text",
                "answer": "Drones or honey bee males"
            },
            {
                "question": "State any one characteristic which shows that a bat is a mammal",
                "type": " text",
                "answer": "Undergoes Internal Fertilisation"
            },
            {
                "question": "State the method of controlling soil erosion where farmers plough land and across a slope.",
                "type": " text",
                "answer": ""
            },
            {
                "question": "Name any one disease of rabbits that affects poultry birds.",
                "type": " text",
                "answer": [
                    "Coccidiosis",
                    "Pneumonia"
                ]
            },
            {
                "question": "State any one way in which trees are able to reduce air pollution",
                "type": " text",
                "answer": [
                    "absorb carbon dioxide",
                    "Trap dust"
                ]
            },
            {
                "question": "The diagram is a head of a bird .use it to answer question 22",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2012_21.jpg",
                "sub_questions": [
                    {
                        "question": [
                            "What does a bird with this type off beak eat?"
                        ],
                        "type": " text",
                        "answer": "Hard seeds"
                    }
                ]
            },
            {
                "question": "Why are ventilators usually put on a higher level than doors and windows in a house?",
                "type": "check_box",
                "answer": [
                    "To let out warm rising air"
                ],
                "options": [
                    "To let out warm rising air",
                    "To bring in cold air",
                    "To act as Air conditioners",
                    "To let in warm air"
                ]
            },
            {
                "question": "Give one way in which the preservation of millet and fish differs.",
                "type": "check_box",
                "answer": [
                    "fish may be preserved by smoking unlike millet."
                ],
                "options": [
                    "fish may be preserved by smoking unlike millet",
                    "fish cannot preserved by smoking unlike millet",
                    "millet may be preserved by refrigeration unlike fish",
                    "millet may be preserved by slating unlike fish"
                ]
            },
            {
                "question": "",
                "type": "check_box",
                "answer": [
                    "Accumulate stagnant water into which mosquitoes’ breed"
                ],
                "options": [
                    "Accumulate stagnant water into which mosquitoes’ breed",
                    "Accumulate stagnant water into which snakes’ breed",
                    "Accumulate stagnant water into which tsetse flies’ breed",
                    "Accumulate stagnant water into which bees’ breed"
                ]
            },
            {
                "question": "State any one result of two naked wires carrying electric current getting in touch with one another.",
                "type": "text",
                "answer": [
                    "Short circuit"
                ]
            },
            {
                "question": "Give any one way in which a transparent object differs from an opaque object",
                "type": "check_box",
                "answer": [
                    "Transparent objects allow light to pass through fully whereas opaque objects do not"
                ],
                "options": [
                    "Transparent objects allow light to pass through fully whereas opaque objects do not",
                    "Transparent objects does not allow light to pass through fully whereas opaque objects do ",
                    "One can see through opaque objects unlike transparent objects"
                ]
            },
            {
                "question": "Which part of air is necessary for burning to take place?",
                "type": "text",
                "answer": [
                    "Oxygen"
                ]
            }
        ]
    },
    {
        "category": 23,
        "questions": [
            {
                "question": "Give any one way in which having regular physical excercise is important to the human body",
                "type": " text",
                "answer": "It improves blood circulation throught the body"
            },
            {
                "question": "Which part of a maize grain absorbs and supplies food to the embryo?",
                "type": " text",
                "answer": "Endosperm"
            },
            {
                "question": "State any one way in which the spread of germs by houseflies can be controlled.",
                "type": " text",
                "answer": [
                    "Use of insecticides",
                    "Cover food",
                    "Eat hot food",
                    "Keep home clean",
                    "Fly screens should be installed over ventilation covers and drain to prevent house flies from entering"
                ]
            },
            {
                "question": "Which blood cells carry oxygen in the body?",
                "type": " text",
                "answer": "Red Blood Cells"
            },
            {
                "question": "Which element of Primary Health Care helps to prevent tooth decay?",
                "type": " text",
                "answer": ""
            },
            {
                "question": "Name the method of making a magnet shown in the diagram below",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2014_23.jpg",
                "type": " text",
                "answer": ""
            },
            {
                "question": "Which element of Primary Health Care helps to prevent tooth decay?",
                "type": " text",
                "answer": ""
            },
            {
                "question": "Which of the following lenses Corrects short sightedness",
                "type": "MultipleChoice",
                "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2013_23v.png",
                "Options": [
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2013_23C.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2013_23v.png"
                ]
            },
            {
                "question": "Give any one affect of poor disposal of human wastes in the environment.",
                "type": " text",
                "answer": "Spreads diseases like cholera"
            },
            {
                "question": "How do conifers differ from other non- flowering plants?",
                "type": "check_box",
                "answer": [
                    "Conifers reproduce by means of seeds while other non-flowering plants reproduce by means of spores."
                ],
                "options": [
                    "Conifers reproduce by means of seeds while other non-flowering plants reproduce by means of spores.",
                    "Conifers reproduce by means of spores while other non-flowering plants reproduce by means of seeds.",
                    "Conifers  do not have flowers.",
                    "Conifers have flowers."
                ]
            },
            {
                "question": "State one way of controlling Nagana in an area.",
                "type": "check_box",
                "answer": [
                    "Clearing bushes"
                ],
                "options": [
                    "Clearing bushes",
                    "using scare crows",
                    "using mouse traps",
                    "growing more trees in the area"
                ]
            },
            {
                "question": "Name one material used to guard against electric shocks.",
                "type": "check_box",
                "answer": [
                    "Gloves"
                ],
                "options": [
                    "Gloves",
                    "metal",
                    "water",
                    "oil"
                ]
            },
            {
                "question": "Give the function of the epiglottis in the respiratory system.",
                "type": "check_box",
                "answer": [
                    "It opens to allow air into the trachea and closes during swallowing to prevent food entering the trachea."
                ],
                "options": [
                    "It opens to allow air into the trachea and closes during swallowing to prevent food entering the trachea.",
                    "It closes to allow air into the trachea and opens during swallowing to prevent food entering the stomach.",
                    "It closes to allow air into the trachea and opens during swallowing to prevent food entering the trachea.",
                    "It opens to allow air into the trachea and closes during swallowing to prevent food entering the trachea."
                ]
            },
            {
                "question": "Why is photosynthesis important to plants?",
                "type": "check_box",
                "answer": [
                    "Manufacture food for the plants"
                ],
                "options": [
                    "manufacture carbondioixide for plants",
                    "manufacture oxygen for plants",
                    "helps in plant growth",
                    "Manufacture food for the plants"
                ]
            },
            {
                "question": "Give the meaning of drug abuse",
                "type": "check_box",
                "answer": [
                    "Drug abuse is the habitual taking of addictive or illegal drugs"
                ],
                "options": [
                    "Drug abuse is the habitual taking of addictive or illegal drugs",
                    "Drug abuse is the irregular taking of addictive or illegal drugs",
                    "Drug abuse is the habitual taking of prescribed drugs",
                    "Drug abuse is the habitual taking of alcohol"
                ]
            }
        ]
    },
    {
        "category": 24,
        "questions": [
            {
                "question": "How is a coconut seed adapted to water dispersal?",
                "type": " text",
                "answer": "Coconuts have a fibrous outer coating that allows them to float"
            },
            {
                "question": "What is the importance of the black lining inside a solardrier?",
                "type": " text",
                "answer": ""
            },
            {
                "question": "The diagram below shows a germinating seed. Study and use it to answer questions below.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2017_24.jpg",
                "sub_questions": [
                    {
                        "question": "What type of germination is shown in the diagram above?",
                        "type": "text",
                        "answer": "Epigeal germination"
                    }
                ]
            },
            {
                "question": "Give any one reason why breastfeeding is encouraged for at least two years.",
                "type": " text",
                "answer": ""
            },
            {
                "question": "State the role of enzymes during food gestation.",
                "type": " text",
                "answer": ""
            },
            {
                "question": "In which method of making water clean, do you first allow the impurities to settle at the bottom of the container?",
                "type": " text",
                "answer": "Decanting"
            },
            {
                "question": "How is a safety pin in a first–Aid kit important?",
                "type": " text",
                "answer": "Safety pin removes foreign particle from the body and to fasten bandages"
            },
            {
                "question": ".  Apart from the egg stage, at what other stage of development is a housefly not active?",
                "type": " text",
                "answer": "Pupa"
            },
            {
                "question": "What is the importance of a chimney in a kitchen?",
                "type": "check_box",
                "answer": [
                    "to take out smoke from the kitchen"
                ],
                "options": [
                    "to take out smoke from the kitchen",
                    "to bring in fresh air",
                    "makes cooking easy",
                    "prevent soot from forming around  saucepans"
                ]
            },
            {
                "question": "Give one example of a plant with prop roots.",
                "type": "check_box",
                "answer": [
                    "Maize plant"
                ],
                "options": [
                    "Maize plant",
                    "mango plant",
                    "Apple plant",
                    "tomato plant"
                ]
            },
            {
                "question": "Give one activity the community can do to reduce road accidents",
                "type": "check_box",
                "answer": [
                    "Putting humps on roads"
                ],
                "options": [
                    "Putting humps on roads",
                    "performing regular stops",
                    "Putting roadblocks on roads",
                    "increasing the speed limit"
                ]
            },
            {
                "question": "Write down the type of venation shown by the diagram below ",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2010_24.jpg",
                "answer": [
                    "Network venation"
                ]
            },
            {
                "question": "Give any one reason why human beings breathe in.",
                "type": "check_box",
                "answer": [
                    "To get oxygen for the body"
                ],
                "options": [
                    "To get oxygen for the body",
                    "To get Carbondioxide for the body",
                    "To get food for the body"
                ]
            },
            {
                "question": "State ant one way of making a magnet",
                "type": "check_box",
                "answer": [
                    "By using electrical method"
                ],
                "options": [
                    "By using electrical method",
                    "By using tripple or double stroke method",
                    "By using static  method",
                    "By breaking other magnets into two"
                ]
            }
        ]
    },
    {
        "category": 25,
        "questions": [
            {
                "question": "The diagram below shows electric wires under a certain weathe condition. Study it and answer questions 25 and 26",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2019_25.jpg",
                "sub_questions": [
                    {
                        "question": "In which kind of weather condition do the electric wires appear as shown above ?",
                        "type": "text",
                        "answer": ""
                    },
                    {
                        "question": "why do the electric wires appear as shwon above?",
                        "type": "text",
                        "answer": ""
                    }
                ]
            },
            {
                "question": "Mention any one factor considered when sorting clothes for washing.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "To which group of flowering plants does the germinating seed belong?",
                "type": "text",
                "answer": "Dicotyledonous plant"
            },
            {
                "question": "Name any one scale in which temperature is measured.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Apart from cameras and microscopes, name any one other optical instrument that uses a lens.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Why does a coin thrown in a basin full of water appear to be nearer to the surface?",
                "type": "text",
                "answer": "Due to refraction"
            },
            {
                "question": "In which type of change are the properties of the substance formed permanently different from those of the original one?",
                "type": "text",
                "answer": "Chemical change"
            },
            {
                "question": "Why does a clay-made charcoal stove use less charcoal than a metallic one?",
                "type": "check_box",
                "answer": [
                    "Clay stove retain more heat than metallic ones",
                    "Clay stove conserve heat for a long time"
                ],
                "Options": [
                    ""
                ]
            },
            {
                "question": "Apart from getting food, give any one other way in which birds benefit from plants.",
                "type": "check_box",
                "answer": [
                    "birds get shelter from plants"
                ],
                "options": [
                    "birds get shelter from plants",
                    "birds destroy plants",
                    "plants have strong stems to support birds",
                    "birds get sunshine from plants"
                ]
            },
            {
                "question": "Name the type of family where there are parents and their biological children only. Turn over. ",
                "type": "text",
                "answer": [
                    "Nuclear family "
                ]
            },
            {
                "question": "Name one activity carried out by farmers to remove unwanted plants from their gardens.",
                "type": "text",
                "answer": [
                    "Weeding "
                ]
            },
            {
                "question": "State the organisms that can be controlled by use of antibodies",
                "type": "text",
                "answer": [
                    "Germs"
                ]
            },
            {
                "question": "What is an enzyme?",
                "type": "check_box",
                "answer": [
                    "An enzyme is a substance that speeds up a biological reaction"
                ],
                "options": [
                    "An enzyme is a substance that speeds up a biological reaction",
                    "An enzyme is a substance that slows down a biological reaction",
                    "An enzyme is a substance that creates a biological reaction",
                    "An enzyme is a substance that speeds up a chemical reaction"
                ]
            },
            {
                "question": "Which part of the human body does Trachoma attack?",
                "type": "text",
                "answer": [
                    "Eyes"
                ]
            }
        ]
    },
    {
        "category": 26,
        "questions": [
            {
                "question": "Apart from removing dirt, why should the udder and teats be washed with warm water before milking?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "State any one way in which saliva is useful in the process of digestion.",
                "type": "text",
                "answer": [
                    "Softens food to ease swallowing",
                    "Contains amylase enzyme that digests starch"
                ]
            },
            {
                "question": "The diagram below shows two bar magnets placed close to each other. Use arrows to show what happens to the `two bar magnets.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2016_26.jpg"
            },
            {
                "question": "Give way in which mosses are similar to mushrooms in the way they reproduce.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "How is reproduction in whale different from that of fish",
                "type": "text",
                "answer": [
                    "Whales produce younger one alive while fish lay eggs.",
                    "Whale undergo internal fertilization while fish undergo external fertilization"
                ]
            },
            {
                "question": "How is the function of stomata in a leaf similar to that of gills in a fish?",
                "type": "text",
                "answer": "They are used for gaseous exchange"
            },
            {
                "question": "Apart from production of eggs (ova), mention one other use of the ovaries in the human body",
                "type": "check_box",
                "answer": "Ovary produce hormones such as estrogen and progesterone",
                "options": [
                    "",
                    ""
                ]
            },
            {
                "question": "What types of skeleton does a cockroach have?",
                "type": "check_box",
                "answer": [
                    "Exoskeleton"
                ],
                "options": [
                    "Exoskeleton",
                    "Endoskeleton",
                    "static skeleton",
                    "Internal skeleton"
                ]
            },
            {
                "question": "Write down one function of joint in a human body",
                "type": "text",
                "answer": [
                    "Allow movement in the body"
                ]
            },
            {
                "question": "Give one way   in which claws are important to birds.",
                "type": "check_box",
                "answer": [
                    "Hunting and griping prey"
                ],
                "options": [
                    "Hunting and griping prey",
                    "flight",
                    "floating in air",
                    "floating on water"
                ]
            },
            {
                "question": "Write down any one piece of advice you can give to a friend in order promote their personal hygiene.",
                "type": "text",
                "answer": [
                    "Bathe regularly"
                ],
                "options": [
                    "Bathe regularly",
                    "Bathe irregularly",
                    "Eat food",
                    "take medicine"
                ]
            },
            {
                "question": "Give any one reason why plants and animals are classified as living things.",
                "type": "check_box",
                "answer": [
                    "They feed"
                ],
                "options": [
                    "They feed",
                    "They sleep",
                    "they have skeletons",
                    "they belong to the same kingdom"
                ]
            },
            {
                "question": "Suggest any one way in which the use of solar energy helps in the protection of the environment.",
                "type": "check_box",
                "answer": [
                    "It reduces the cutting down trees"
                ],
                "options": [
                    "It reduces the cutting down trees",
                    "It promotes the cutting down trees",
                    "It promotes the growth of  trees",
                    "it builds the ozone layer hence protecting the earth from direct sunrays"
                ]
            }
        ]
    },
    {
        "category": 27,
        "questions": [
            {
                "question": "why is alcohol used in a maximum and minimum thermometer?",
                "type": "text",
                "answer": [
                    "It has a low freezing point",
                    "Uniform expansion",
                    "it has a wide temperature range"
                ]
            },
            {
                "question": "How does prolonged breastfeeding help in child spacing?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Name the vaccine which is given to prevent three childhood immunizable diseases.",
                "type": "text",
                "answer": "MMR for mumps, measles and mumps"
            },
            {
                "question": "Name the human body system to which the lungs, kidneys and the skin belong.",
                "type": "text",
                "answer": "The Excretory System"
            },
            {
                "question": "Why is tepid sponging an important First Aid to a person with high fever?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Why is it important for government to collect information on human health?",
                "type": "text",
                "answer": [
                    "To plan health units",
                    "To budget for medicine"
                ]
            },
            {
                "question": "Which components of soil is used by plants to make their food?",
                "type": "text",
                "answer": "Water is a raw material for photosynthesis"
            },
            {
                "question": "State one way in which a child gets natural immunity",
                "type": "check_box",
                "answer": [
                    "By suffering from disease and recovering from it",
                    "From breast milk"
                ],
                "options": [
                    "",
                    ""
                ]
            },
            {
                "question": "What is the importance of water during seed germination?",
                "type": "check_box",
                "answer": [
                    "Water soften the testa and cotyledon"
                ],
                "options": [
                    "Water soften the testa and cotyledon",
                    "Water hardens the testa and cotyledon",
                    "Water promotes fertilization in plants",
                    "Water soften the testa and plumule"
                ]
            },
            {
                "question": "You are a health prefect in your school. Give one activity you would do during   a health parade",
                "type": "check_box",
                "answer": [
                    "checking finger nails"
                ],
                "options": [
                    "checking finger nails",
                    "checking notes",
                    "Music dance and drama",
                    "communicating school programs"
                ]
            },
            {
                "question": "To which group of simple machines does a ladder leaning against a well belong?",
                "type": "text",
                "answer": [
                    "Inclined plane"
                ]
            },
            {
                "question": "Why is a microscope important to doctor?",
                "type": "text",
                "answer": [
                    "To observe small organisms such as germs"
                ]
            },
            {
                "question": " State any one way in which health parades are important in primary schools.",
                "type": "check_box",
                "answer": [
                    "Educate the pupils about their body"
                ],
                "options": [
                    "Educate the pupils about their body",
                    "Help pupils pass exams",
                    "Help pupils do homework",
                    "Help pupils with counselling"
                ]
            },
            {
                "question": "Why is a Ventilated Improved Pit latrine left without a cover?",
                "type": "check_box",
                "answer": [
                    "To allow free flow of air through the system"
                ],
                "options": [
                    "To allow free flow of air through the system",
                    "To let out the bad smell",
                    "For easy use",
                    "To prevent free flow of air through the system"
                ]
            }
        ]
    },
    {
        "category": 28,
        "questions": [
            {
                "question": "how does mouth-to-mouth breathing help a victim of near drowning?",
                "type": "text",
                "answer": [
                    "It Restores the victim's oxygen supply",
                    "It maintains Air Circulation to the lungs"
                ]
            },
            {
                "question": "What type of change is molting in insects?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Give one reason why less effort is applied to move a load using first class levers.",
                "type": "text",
                "answer": "Less effort is used when applied far from the fulcrum than the load"
            },
            {
                "question": "Give one way in which bacteria in pit latrines are useful.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Why are sun birds able to suck nector from the bottom of flowers?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "State any one piece of advice you would give to a person who suffers from indigestion.",
                "type": "text",
                "answer": [
                    "To chew food properly",
                    "Eat food rich in roughages ",
                    "Eat a lot of fruits and vegetables",
                    ""
                ]
            },
            {
                "question": "Which structures enable the trachea to remain open all the time?",
                "type": "text",
                "answer": "Cartilaginous rings"
            },
            {
                "question": "State the injury that results from tearing of muscles.",
                "type": "text",
                "answer": " strain"
            },
            {
                "question": "How does a human body cool itself on a hot day?",
                "type": "check_box",
                "answer": [
                    "By sweating"
                ],
                "options": [
                    "By sweating",
                    "By shivering",
                    "By sweating",
                    "By vomiting"
                ]
            },
            {
                "question": "How is wind useful to human beings?",
                "type": "check_box",
                "answer": [
                    ""
                ],
                "options": [
                    "For drying clothes",
                    "For cooking food",
                    "For washing clothes",
                    "source of vitamin D"
                ]
            },
            {
                "question": "Why is it a bad practice to store cooked food in room where domestic animals are kept?",
                "type": "check_box",
                "answer": [
                    "Food may get contaminated with faeces",
                    "food may go bad",
                    "Animals dont like food"
                ]
            },
            {
                "question": "How does boiling of water help in the control of typhoid",
                "type": "text",
                "answer": [
                    "It kills typhoid germs"
                ]
            },
            {
                "question": "State the function of the amniotic fluid during pregnancy",
                "type": "check_box",
                "answer": [
                    "Absorbs shock"
                ],
                "options": [
                    "increases friction for the baby",
                    "Absorbs shock",
                    "kills germs"
                ]
            },
            {
                "question": "Apart from painting, give any one other way in which metal can be prevented from rusting.",
                "type": "check_box",
                "answer": [
                    "By oiling"
                ],
                "options": [
                    "By oiling",
                    "melting",
                    "freezing",
                    "condensation"
                ]
            },
            {
                "question": "How do some people become passive smokers?",
                "type": "check_box",
                "answer": [
                    "By breathing air that contain cigarette smoke from a person smoking"
                ],
                "options": [
                    "By breathing air that contains cigarette smoke from a person smoking",
                    "By inhaling air",
                    "By breathing air that contains oxygen from a person smoking",
                    "By smoking a cigarette"
                ]
            }
        ]
    },
    {
        "category": 29,
        "questions": [
            {
                "question": "State the importance of the hairs in the nose during breathing?",
                "type": "text",
                "answer": [
                    "The hairs in the nose act as a filter that prevent dust and other foreign bodies from entering through the nose"
                ]
            },
            {
                "question": "How is a lunar eclipse formed?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "The diagram below shows a bar magnet with iron fillings around it. Study and use it to answer the question that follows",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2017_29.jpg",
                "sub_questions": [
                    {
                        "question": "Which property of magnets is shown in the diagram above?",
                        "type": "text",
                        "answer": "Magnets have magnetic field around them"
                    },
                    {
                        "question": "Name one disease that affects the stomach because of alcoholism.",
                        "type": "text",
                        "answer": ""
                    }
                ]
            },
            {
                "question": "Give an example of a mollusk which is a vector to humans.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "The diagram below show how electricity is produced Use it to answer the question that follows: ",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2014_29.jpg",
                "sub_questions": [
                    {
                        "question": "What source of energy is used to produce the electricity?",
                        "type": "text",
                        "answer": ""
                    }
                ]
            },
            {
                "question": "Four bar magnets marked 1, 2, 3 and 4 were placed next to each other as shown below. .",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2013_29.jpg",
                "sub_questions": [
                    {
                        "question": "Name the pole of magnet 4 marked with letter x",
                        "type": "text",
                        "answer": "North pole"
                    }
                ]
            },
            {
                "question": "Which gas is used by food manufacturers for preserving drinks like sodas?",
                "type": "text",
                "answer": "Carbon dioxide"
            },
            {
                "question": "How is the fertilization of the eggs of amphibians different from those reptiles?",
                "type": "check_box",
                "answer": [
                    "Eggs of amphibians are fertilized externally while eggs of reptile are fertilized internally."
                ],
                "options": [
                    "Eggs of amphibians are fertilized externally while eggs of reptile are fertilized internally.",
                    "Eggs of amphibians are fertilized internally while eggs of reptile are fertilized externally.",
                    "Eggs of amphibians are actually not fertilised while eggs of reptile are fertilized internally.",
                    "Eggs of amphibians take one month to get fertilized while eggs of reptile take two months"
                ]
            },
            {
                "question": "Why is mercury used in a thermometer instead of water?",
                "type": "check_box",
                "answer": [
                    "expands uniformly"
                ],
                "options": [
                    "expands uniformly",
                    "expands non-uniformly",
                    "wets the glass",
                    "does not expand"
                ]
            },
            {
                "question": "Write one method of rearing cattle used in towns.",
                "type": "check_box",
                "answer": [
                    "Paddock system"
                ],
                "options": [
                    "Paddock system",
                    "Free range system"
                ]
            },
            {
                "question": "Which type of soil does not allow water to go through it easily?",
                "type": "check_box",
                "answer": [
                    "Clay soil"
                ],
                "options": [
                    "Clay soil",
                    "sand soil",
                    "loam soil",
                    "quick sand"
                ]
            },
            {
                "question": "which kind of roots provide extra support to the plant",
                "type": "text",
                "answer": [
                    "prop roots"
                ],
                "sub_questions": [
                    {
                        "question": [
                            "Name any one crop with a root system similar to the one  above"
                        ],
                        "type": " check-box",
                        "answer": [
                            "Rice"
                        ],
                        "options": [
                            "Rice",
                            "Mango trees",
                            "guava",
                            "paw-paw"
                        ]
                    }
                ]
            }
        ]
    },
    {
        "category": 30,
        "questions": [
            {
                "question": "which immunisable disease affects the liver?",
                "type": "text",
                "answer": "Hepatitis B"
            },
            {
                "question": "What determines the sinking or floating of an object in water?",
                "type": "text",
                "answer": "Hepatitis B"
            },
            {
                "question": "State any one sign of disease on the plant leaves.",
                "type": "text",
                "answer": [
                    "Chlorosis (yellowing of leaves)",
                    "Leaf rust (common in corn)",
                    "Leaf spot (septoria brown spot)",
                    "Leaf wilt",
                    "Leaf death"
                ]
            },
            {
                "question": "Apart from the polio vaccine, name any other vaccine administered to children at 6 weeks of age.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Name the human body system which eliminates wastes.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Why does paraffin settle on top when mixed with water?",
                "type": "text",
                "answer": "Paraffin is less dense than water or water is denser than paraffin"
            },
            {
                "question": "Apart from brushing, how else can dental carries be prevented?",
                "type": "check_box",
                "answer": [
                    "By rising mouth after eating",
                    "By going for regular medical check up",
                    "By avoiding sugary food"
                ],
                "Options": [
                    "",
                    ""
                ]
            },
            {
                "question": "The diagram below is of a common animal. Use it to answer question 30",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2012_30.jpg",
                "type": "text",
                "answer": "Bilharzia or schistosomiasis"
            },
            {
                "question": "How can the pitch of a string of a musical instrument be changed?",
                "type": "check_box",
                "answer": [
                    "By tightening the strings"
                ],
                "options": [
                    "By tightening the strings",
                    "By loosening the strings",
                    "By increasing the surface area",
                    "By reducing the surface area"
                ]
            },
            {
                "question": "State one natural source of sound energy.",
                "type": "check_box",
                "answer": [
                    "Thunder"
                ],
                "options": [
                    "Thunder",
                    "wind mills",
                    "generators",
                    "vehicles"
                ]
            },
            {
                "question": "State one similarity in structure between a snail and earthworm",
                "type": "check_box",
                "answer": [
                    "Both lack a backbone"
                ],
                "options": [
                    "Both lack a backbone",
                    "Both have backbones",
                    "both have endo skeletons",
                    "vboth have exo-skeleton"
                ]
            },
            {
                "question": "Give any one cause of cause of malnutrition in adults.",
                "type": "check_box",
                "answer": [
                    "Poverty"
                ],
                "options": [
                    "Poverty",
                    "Eating alot of food",
                    "Eating alot of fruits",
                    "driniking too much water"
                ]
            },
            {
                "question": "Why is a sling used when giving First Aid to a person with a broken hand?",
                "type": "check_box",
                "answer": [
                    "To minimize movement of the broken hand"
                ],
                "options": [
                    "To minimize movement of the broken hand",
                    "To maximise movement of the broken hand",
                    "it is a permament treatment for the broken hand"
                ]
            }
        ]
    },
    {
        "category": 31,
        "questions": [
            {
                "question": "Apart from Light Sussex, name any one other exotic breed of chicken kept for meat",
                "type": "text",
                "answer": "Cornish Cross"
            },
            {
                "question": "How does covering of food during cooking help to conserve wood fuel?",
                "type": "text",
                "answer": "Cornish Cross"
            },
            {
                "question": "Name the organ, which regulates the human body temperature.",
                "type": "text",
                "answer": "Skin"
            },
            {
                "question": "In which part of the human alimentary canal is water absorbed?",
                "type": "text",
                "answer": "Skin"
            },
            {
                "question": "Give any one way in which a VIP latrine is different from an ordinary pit latrine.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Give any way in which bacteria are useful to crop farmers?",
                "type": "text",
                "answer": [
                    "They fix nitrogen",
                    "Convert plant remains to human",
                    "Decompose organic matter to form manure"
                ]
            },
            {
                "question": "State any one way in which friction in moving parts of a machine can be reduced.",
                "type": "check_box",
                "answer": [
                    "By oiling",
                    "By greasing",
                    "by using roller",
                    "By using ball bearings"
                ]
            },
            {
                "question": "Why are the legs of a person who has fainted raised higher than the head as a way   of giving first aid?",
                "type": "check_box",
                "answer": [
                    "Raising legs allows more blood supply to the brain"
                ],
                "Options": [
                    "",
                    ""
                ]
            },
            {
                "question": "Mary shouted in a big house and she head the same sound repeated. Name the repeated sound she heard.",
                "type": "check_box",
                "answer": [
                    "Echo"
                ],
                "options": [
                    "Echo",
                    "reflection",
                    "frequency",
                    "Amplitude"
                ]
            },
            {
                "question": " Write down the function of testis in a human reproductive system.",
                "type": "check_box",
                "answer": [
                    "Produce sperm"
                ],
                "options": [
                    "Produce sperm",
                    "Produce ova",
                    "Produce starch and salts",
                    "Produce  water"
                ]
            },
            {
                "question": "Apart from rock particles and living organisms, name other part of soil.",
                "type": "check_box",
                "answer": [
                    "Water"
                ],
                "options": [
                    "Water ",
                    "hills",
                    "valleys",
                    "mountains"
                ]
            },
            {
                "question": "Which type of electricity is produced by generators?",
                "type": "text",
                "answer": [
                    "Thermal electricity "
                ]
            },
            {
                "question": "State any one practice on a school garden that will help to improve soil fertility.",
                "type": "check_box",
                "answer": [
                    "Mulching"
                ],
                "options": [
                    "Mulching",
                    "terracing",
                    "contour ploughing",
                    "soil erosion"
                ]
            },
            {
                "question": "What causes an echo?",
                "type": "check_box",
                "answer": [
                    "Reflection of sound waves"
                ],
                "options": [
                    "Reflection of sound waves",
                    "deflection of sound waves",
                    "Refraction of sound waves",
                    "Reflection of water waves"
                ]
            }
        ]
    },
    {
        "category": 32,
        "questions": [
            {
                "question": "which rabbit disease causes wounds in their ears?",
                "type": "text",
                "answer": "Ear Canker"
            },
            {
                "question": "State any one characteristic of images formed on the retina.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "State any one way in which animals can be used as energy resource.",
                "type": "text",
                "answer": "Excreta are used to make biogas"
            },
            {
                "question": "What is the danger of having too much wax in the human ear?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Name the root crop which is attacked by the mosaic disease.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Mention the group of birds to which a crested crane belongs",
                "type": "text",
                "answer": "Wadding birds"
            },
            {
                "question": "Give   any one way in which yeast is useful to people",
                "type": "check_box",
                "answer": [
                    "for baking bread",
                    "for brewing alcohols",
                    "it is a source of vitamin Be"
                ],
                "Options": [
                    "",
                    ""
                ]
            },
            {
                "question": "What name is given to a place where seedlings are grown before transplanting?",
                "type": "text",
                "answer": "Nursery bed"
            },
            {
                "question": "Give one disadvantage of using   herbicides (chemicals) to kill weeds in crop gardens.",
                "type": "check_box",
                "answer": [
                    "They kill soil organisms"
                ],
                "options": [
                    "They kill soil organisms",
                    "They kill plants",
                    "They improve soil fertility",
                    "cut off nutrient supply"
                ]
            },
            {
                "question": "Give the difference between river blindness and night blindness",
                "type": "check_box",
                "answer": [
                    "River blindness is caused by Onchocera volvulus while night blindness is caused by lack of vitamin A."
                ],
                "options": [
                    "River blindness is caused by Onchocera volvulus while night blindness is caused by lack of vitamin A.",
                    "River blindness is caused by  lack of vitamin A while night blindness is caused by Onchocera volvulus",
                    "River blindness is caused by Onchocera volvulus while night blindness is caused by lack of vitamin D.",
                    "River blindness is caused by Onchocera volvulus while night blindness is caused by lack of vitamin B12"
                ]
            },
            {
                "question": "What happens when an opaque object is placed in as path of light?",
                "type": "text",
                "answer": [
                    "A shadow is formed"
                ]
            },
            {
                "question": "How is TASO (The Aids Support Organization) useful to HIV/AIDS victims?",
                "type": "check_box",
                "answer": [
                    "Provide counselling and guidance"
                ],
                "options": [
                    "Provide counselling and guidance",
                    "Provide paid treatment to HIV and AIDs victims",
                    "Provide free treatment to accident victims",
                    "Provide food rich in nutrients free refugees"
                ]
            },
            {
                "question": "Why is a tick called a parasite?",
                "type": "check_box",
                "answer": [
                    "Feed on its host"
                ],
                "options": [
                    "Feed on its host",
                    "host feeds on it",
                    "Feed on insects",
                    "feeds on blood"
                ]
            },
            {
                "question": "Why does a stone sink when it is put in a bucket full of water?",
                "type": "check_box",
                "answer": [
                    "A stone is denser than water or water is less dense than the stone"
                ],
                "options": [
                    "A stone is denser than water or water is less dense than the stone",
                    " water is  more  dense than the stone",
                    "A stone has the same density as water",
                    "A stone is lighter than water"
                ]
            }
        ]
    },
    {
        "category": 33,
        "questions": [
            {
                "question": "Give any one way in which the electric current produced by a dynamo can be increased?",
                "type": "text",
                "answer": "By increasing the speed of rotation of the dynamo's rotor"
            },
            {
                "question": "Why are eagles referred to as birds of prey?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Which method of harvesting trees allows new shoots to grow for the stump?",
                "type": "text",
                "answer": "Coppicing"
            },
            {
                "question": "What form of energy is produced by dry cells?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Give any one reason why sheep farmers carry out docking.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "How is the function of the pulmonary vein different from that of other veins?",
                "type": "text",
                "answer": "Pulmonary vein carries oxygenated blood whereas other carry deoxygenated blood."
            },
            {
                "question": "Apart from   making food, mention one other use of leaves to plants.",
                "type": "check_box",
                "answer": [
                    "For gaseous exchange",
                    "For vegetative reproduction"
                ],
                "options": [
                    "",
                    ""
                ]
            },
            {
                "question": "Which part of the human ear equalises pressure in and outside the ear?",
                "type": "text",
                "answer": "Eustachian tube"
            },
            {
                "question": "State an example of a crop which is a legume.",
                "type": "check_box",
                "answer": [
                    "Beans"
                ],
                "options": [
                    "Beans",
                    "mangoes",
                    "onions",
                    "cassava"
                ]
            },
            {
                "question": "How is a thermos flask helpful in a home?",
                "type": "check_box",
                "answer": [
                    "By keeping hot things, hot and cold things cold"
                ],
                "options": [
                    "By keeping hot things, hot and cold things cold",
                    "By keeping hot things, cold and cold things hot",
                    "By cooling hot things, and heating  cold things",
                    ""
                ]
            },
            {
                "question": "Name the kind of energy possessed by water at 1000 C.",
                "type": "text",
                "answer": [
                    "Kinetic energy"
                ],
                "options": [
                    "Kinetic energy",
                    "potential energy",
                    "mechanical energy",
                    "chemical energy"
                ]
            },
            {
                "question": "Name the kind of energy possessed by water at 1000 C.",
                "type": "text",
                "answer": [
                    "kinetic energy"
                ]
            },
            {
                "question": "What process makes dew to disappear during hot days?",
                "type": "text",
                "answer": [
                    "evaporation"
                ]
            },
            {
                "question": "State the difference between the function of a pulmonary vein and that of a pulmonary artery in blood circulation.",
                "type": "check_box",
                "answer": [
                    "The pulmonary vein carries oxygenated blood from the lungs to the heart while the pulmonary artery carries deoxygenated blood from the heart to the lungs."
                ],
                "options": [
                    "The pulmonary vein carries oxygenated blood from the lungs to the heart while the pulmonary artery carries deoxygenated blood from the heart to the lungs.",
                    "The pulmonary vein carries de-oxygenated blood from the lungs to the heart while the pulmonary artery carries deoxygenated blood from the heart to the lungs.",
                    "The pulmonary vein carries deoxygenated blood from the lungs to the heart while the pulmonary artery carries oxygenated blood from the lungs to the heart."
                ]
            },
            {
                "question": "Give any one way through which worms enter our bodies",
                "type": "check_box",
                "answer": [
                    "By eating half cooked meat"
                ],
                "options": [
                    "By eating half cooked meat",
                    "By eating  meat",
                    "By moving in dirty places with shoes",
                    "By eating fruits"
                ]
            }
        ]
    },
    {
        "category": 34,
        "questions": [
            {
                "question": "How does an Object less dense than water behave in water?",
                "type": "text",
                "answer": "it will float when placed in water"
            },
            {
                "question": "The diagram below shows bags of maize flour in a store. answer the question that follows.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2018_34.jpg",
                "sub_questions": [
                    {
                        "question": "Why are the bags of maize flour put on such a raised stand?",
                        "type": "",
                        "answer": ""
                    }
                ]
            },
            {
                "question": "Give anyone way of controlling the bad smell in an ordinary pit latrine.",
                "type": "text",
                "answer": "Smoking"
            },
            {
                "question": "Give any one good health habit that can improve the working of the lungs",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Apart from a flat iron, give one other appliance used at home that changes electric energy to heat energy.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Apart from eating a balanced diet, give any one other way of controlling common diseases at home.",
                "type": "text",
                "answer": [
                    "warm environment",
                    "moist environment",
                    "protein rich environment"
                ]
            },
            {
                "question": "How is the function of the pulmonary vein different from that of other veins?",
                "type": "text",
                "answer": "Pulmonary vein carries oxygenated blood whereas other carry deoxygenated blood."
            },
            {
                "question": "State any one characteristic of the images formed by plane mirrors",
                "type": "tcheck-box",
                "answer": [
                    "Same size as the object",
                    "Laterally inverted",
                    "Equal distance from the mirror as the object"
                ],
                "Options": [
                    "",
                    ""
                ]
            },
            {
                "question": "Name one other disease caused by fungi in human s apart from ring worm.",
                "type": "check_box",
                "answer": [
                    "Candida",
                    "oral thrust",
                    "Dhobis itch"
                ],
                "Options": [
                    "",
                    ""
                ]
            },
            {
                "question": "Give any one way in which the human liver can be kept in proper working condition.",
                "type": "check_box",
                "answer": [
                    "Eating a balanced diet"
                ],
                "options": [
                    "Eating a balanced diet",
                    "drinking alot of alcohol",
                    "taking little water",
                    "Eating alot of salty foods"
                ]
            },
            {
                "question": "A part from absorption of water and mineral salts one other use of roots to a cassava plants.",
                "type": "check_box",
                "answer": [
                    "Storage of food"
                ],
                "options": [
                    "Storage of food",
                    "Storage of sunlight",
                    "Storage of reproductive cells",
                    "Storage of chlorophyll"
                ]
            },
            {
                "question": "Give one factor to be considered when choosing a site for a school garden.",
                "type": "check_box",
                "answer": [
                    "Land"
                ],
                "options": [
                    "Land",
                    "school name",
                    "school motto",
                    "head of the school"
                ]
            },
            {
                "question": "Give any one use of reptiles to man",
                "type": "check_box",
                "answer": [
                    "Provide skin for making drums"
                ],
                "options": [
                    "Provide skin for making drums",
                    "Provide skin for making houses",
                    "They are source of medicine such as anti-malarials",
                    "Source of entertainment"
                ]
            },
            {
                "question": ".Give any one form of energy that is produced during the process of splitting firewood with an axe.",
                "type": "check_box",
                "answer": [
                    "Sound energy"
                ],
                "options": [
                    "Sound energy",
                    "light energy",
                    "mechanical energy",
                    "physical energy"
                ]
            },
            {
                "question": "Mention the instrument used to measure the temperature of a human body",
                "type": "text",
                "answer": [
                    "Clinical thermometer "
                ]
            }
        ]
    },
    {
        "category": 35,
        "questions": [
            {
                "question": "state any one condition which is favourable for bacteria to breed?",
                "type": "text",
                "answer": [
                    "warm environment",
                    "moist environment",
                    "protein rich environment"
                ]
            },
            {
                "question": "How is water as a renewable resource replaced in the environment?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "The diagram below shows an object in a glass of water. Study and use it to answer the questions that follows.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2017_35.jpg",
                "sub_questions": [
                    {
                        "question": "Why does the object seem to appear at point A yet it is at point B?",
                        "type": "",
                        "answer": "Due to fraction of light"
                    }
                ]
            },
            {
                "question": "Apart from eating a balanced diet, give any one other way of controlling common diseases at home.",
                "type": "text",
                "answer": [
                    "warm environment",
                    "moist environment",
                    "protein rich environment"
                ]
            },
            {
                "question": "The diagram below shows a well tuned musical instrument. The strings P,Q,R,S and T are of uniform thickness.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2015_35.jpg",
                "sub_questions": [
                    {
                        "question": "Why does string T produce sound of the highest pitch?",
                        "type": "",
                        "answer": ""
                    }
                ]
            },
            {
                "question": "Which property of air enables you to drink soda using a straw",
                "type": "text",
                "answer": "Air exerts pressure"
            },
            {
                "question": "Why do the people installing electricity wear rubber gloves?",
                "type": "text",
                "answer": "To avoid electric shock"
            },
            {
                "question": "What class of food is digested in the stomach? ",
                "type": "text",
                "answer": "Proteins"
            },
            {
                "question": "State any one way in which termites depend on soil.",
                "type": "check_box",
                "answer": [
                    "Termite live in the soil (soil is a habitat of termites) they get shelter from soil"
                ],
                "options": [
                    "Termites live in the soil (soil is a habitat of termites) they get shelter from soil",
                    "Termites get sunshine from the soil",
                    "Soil has water which supports their growth",
                    "Soil has air which supports breathing"
                ]
            },
            {
                "question": "State one effects of heat on a metal ",
                "type": "check_box",
                "answer": [
                    "makes a metal to expand"
                ],
                "options": [
                    "makes a metal to expand",
                    "makes a metal to solidify",
                    "makes a metal to contract",
                    "makes a metal to shrink"
                ]
            },
            {
                "question": "The diagram below shows a stage of development of an animal.Use it to answer the question that follows.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2010_35.jpg",
                "sub_questions": [
                    {
                        "question": "Name one animal that develop from this stage",
                        "type": "check_box",
                        "answer": [
                            "Frog "
                        ],
                        "options": [
                            "Frog",
                            "snake",
                            "carterpillar",
                            "rat"
                        ]
                    }
                ]
            },
            {
                "question": "How are green plants important in the water cycle?",
                "type": "check_box",
                "answer": [
                    "Produce water vapour through transpiration, the vapour condense to form rain"
                ],
                "options": [
                    "Produce water vapour through transpiration, the vapour condense to form rain",
                    "Produce water vapour through photosynthesis, the vapour condense to form rain",
                    "Produce water vapour through transpiration, the vapour condense to form mountains",
                    "Produce water vapour through transpiration, the vapour condense to form rivers"
                ]
            },
            {
                "question": "Give any one way in which plants benefits from animals.",
                "type": "text",
                "answer": [
                    "Plant get carbon dioxide"
                ]
            },
            {
                "question": "What is the process by which seeds grow into seeding?",
                "type": "check_box",
                "answer": [
                    "Seed germination"
                ],
                "options": [
                    "Seed germination",
                    "Seed dispersal",
                    "seed grafting",
                    "seed growth"
                ]
            }
        ]
    },
    {
        "category": 36,
        "questions": [
            {
                "question": "Name any one root tuber crop which is propagated by use of seeds ?",
                "type": "text",
                "answer": "carrots"
            },
            {
                "question": "Give any one way in which proper disposal of wastes is important in our environment.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "State any one sign of snuffle disease in rabbits.",
                "type": "text",
                "answer": [
                    "Fever",
                    "mucus coming from the nostrils",
                    "Runny eyes.",
                    "breathing problems",
                    "possibly some wheezing",
                    "coughing and sneezing"
                ]
            },
            {
                "question": "Apart from eating a balanced diet, give any one other way of controlling common diseases at home.",
                "type": "text",
                "answer": [
                    "warm environment",
                    "moist environment",
                    "protein rich environment"
                ]
            },
            {
                "question": "The diagram below shows a method of plant propagation. Use it to answer the question that follows.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2016_36.jpg",
                "sub_questions": [
                    {
                        "question": "Name the method of plant propagation shown in the diagram above.",
                        "type": "",
                        "answer": ""
                    }
                ]
            },
            {
                "question": "State any one way in which smoking affects the human respiratory system.",
                "type": "text",
                "answer": "carrots"
            },
            {
                "question": "Why do plane mirrors produce regular reflection of light?",
                "type": "text",
                "answer": "Has a smooth polished surface"
            },
            {
                "question": "The diagram below is of a mosquito larva .use it to answer questions 36 and 37",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2013_36.jpg",
                "sub_questions": [
                    {
                        "question": "Name the mosquito which develops from   such a larva",
                        "type": "text",
                        "answer": "Culex mosquito "
                    },
                    {
                        "question": " What disease does the mosquito you have named above transmits to human beings?",
                        "type": "check_box",
                        "answer": [
                            "Elephantiasis",
                            "yellow fever"
                        ],
                        "Options": [
                            "",
                            ""
                        ]
                    }
                ]
            },
            {
                "question": "Give any one advantage of castrating farm animals",
                "type": "check_box",
                "answer": "prevent poor bread",
                "options": [
                    "",
                    ""
                ]
            },
            {
                "question": "A man lives with, two sons and a nephew. What type of family is this?",
                "type": "check_box",
                "answer": [
                    "Extended family"
                ],
                "options": [
                    "Extended family",
                    "nuclear family",
                    "subsistence family",
                    " His family"
                ]
            },
            {
                "question": "How does mulching help to keep moisture in the soil?",
                "type": "text",
                "answer": [
                    "Reduces evaporation"
                ]
            },
            {
                "question": "State one common problem which adolescents face",
                "type": "check_box",
                "answer": [
                    "Inability to control their feelings"
                ],
                "options": [
                    "Inability to control their feelings",
                    "low immunity",
                    "too much anger",
                    "slow growth"
                ]
            },
            {
                "question": ".  Mention any one natural resource found under the ground and is used as fuel",
                "type": "check_box",
                "answer": [
                    "Petroleum"
                ],
                "options": [
                    "Petroleum",
                    "gold",
                    "salt",
                    "iron"
                ]
            },
            {
                "question": "",
                "type": "check_box",
                "answer": [
                    "Poor disposal of wastes"
                ],
                "options": [
                    "Poor disposal of wastes",
                    "too many children on the school compound",
                    "few children on the school compound",
                    "soil heaps in various parts of the compound"
                ]
            },
            {
                "question": "State any one use of an ax in a home",
                "type": "check_box",
                "answer": [
                    "Spiting fire wood"
                ],
                "options": [
                    "Spiting fire wood",
                    "cutting food",
                    "for security",
                    "for washing"
                ]
            }
        ]
    },
    {
        "category": 37,
        "questions": [
            {
                "question": "which group of invertebrates has segmented bodies and jointed legs?",
                "type": "text",
                "answer": "Arthropods"
            },
            {
                "question": "What would you do to a guitar string to make it produce a high pitched sound?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Which property of air makes the lungs to expand when we breathe in?",
                "type": "text",
                "answer": "Air occupy space"
            },
            {
                "question": "Which organs are used by both fish and tadpoles for breathing?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Give the importance of plants in a food pain.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "What name is given to a meal containing all food values in their right amount?",
                "type": "text",
                "answer": "Balanced diet"
            },
            {
                "question": "Name the root provided below",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2012_38.png",
                "type": "text",
                "answer": "fibrous root"
            },
            {
                "question": "Name any by product of respiration.",
                "type": "check_box",
                "answer": [
                    "Carbon dioxide"
                ],
                "options": [
                    "Carbon dioxide",
                    "oxygen",
                    "water",
                    "salt"
                ]
            },
            {
                "question": "What determines the size of the image in a pinhole camera?",
                "type": "check_box",
                "answer": [
                    "distance of pin hole from the screen"
                ],
                "options": [
                    "distance of pin hole from the screen",
                    "distance of pin hole from the shutter",
                    "size of the pinhole",
                    "size of the screen"
                ]
            },
            {
                "question": "Why is breast feeding very important to a mother?",
                "type": "check_box",
                "answer": [
                    "It is a natural birth control method"
                ],
                "options": [
                    "Breast milk has no cost hence relieves the mother stress to buy milk ",
                    "it is expensive",
                    "Reduces breast size",
                    " improves the mother's health"
                ]
            },
            {
                "question": "Apart   from getting medical treatment, write any other piece advice you can give to a friend suffering from flu",
                "type": "check_box",
                "answer": [
                    "Take a lot of fluids"
                ],
                "options": [
                    "Take a lot of fluids",
                    "Take little  fluids",
                    "Eat alot of oily foods",
                    "sleep less"
                ]
            },
            {
                "question": " In which way is the function of kidneys similar to that of the skins in a human body?",
                "type": "check_box",
                "answer": [
                    "They are both organs of excretions"
                ],
                "options": [
                    "They are both organs of excretions",
                    "They are both organs of reproduction",
                    "both belong to the digestive system",
                    " both belong to the reproductive system"
                ]
            },
            {
                "question": "Give any one reason why white objects are seen easily even at a distance.",
                "type": "text",
                "answer": [
                    "They reflect light"
                ]
            }
        ]
    },
    {
        "category": 38,
        "questions": [
            {
                "question": "How does the PIASCY programme help school children to avoid being infected with HIV/AIDS?",
                "type": "text",
                "answer": "It provides school children with all information necessary to prevent the further spread of HIV/AIDS"
            },
            {
                "question": "Why do the lungs expand during breathing in?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Apart from curing diseases, give one other characteristic of essential drugs.",
                "type": "text",
                "answer": [
                    "Prevent diseases",
                    "Reduce pain",
                    "Provide dietary supplements"
                ]
            },
            {
                "question": "Give any one secondary sex characteristic which is common to both adolescent boys and girls.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Name the fungal-caused sexually transmitted disease, which spread through poor hygiene.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "State one way in which married couple can avoid HIV/AID among themselves",
                "type": "text",
                "answer": "Being faithful to each other"
            },
            {
                "question": "State one way in which married couple can avoid HIV/AID among themselves",
                "type": "text",
                "answer": "Irish potatoes are propagated by stem tuber while sweet potatoes by stem cutting"
            },
            {
                "question": "How are plastics and glass materials dangerous to soil?",
                "type": "check_box",
                "answer": "prevent water from entering the soil and can cause water logging",
                "options": [
                    "",
                    ""
                ]
            },
            {
                "question": "State the type of change that takes place when a baby grows to an adult.",
                "type": "check_box",
                "answer": [
                    "Biological change"
                ],
                "options": [
                    "Biological change",
                    "physical change",
                    "chemical change",
                    "permanent change"
                ]
            },
            {
                "question": "Give one way in which energy from animals can be used.",
                "type": "check_box",
                "answer": [
                    "Ploughing"
                ],
                "options": [
                    "Ploughing",
                    "mulching",
                    "building houses",
                    "crop rotation"
                ]
            },
            {
                "question": "How do plants reduce the amount of carbon dioxide in the atmosphere?",
                "type": "check_box",
                "answer": [
                    "They use it for photosynthesis"
                ],
                "options": [
                    "They use it for photosynthesis",
                    "They use it for transpiration",
                    "They use it for withering",
                    "They consume it"
                ]
            },
            {
                "question": "Name the state of matter in which sound travels fastest.",
                "type": "text",
                "answer": [
                    "Solid state"
                ]
            },
            {
                "question": "Apart from the brain, give any one other body organ which is greatly damaged by alcoholism.",
                "type": "text",
                "answer": [
                    "Liver"
                ]
            },
            {
                "question": "Give any one way in which people benefit from bees.",
                "type": "check_box",
                "answer": [
                    "They get honey"
                ],
                "options": [
                    "They get honey",
                    "They get milk",
                    "They get sugar",
                    "They get meat"
                ]
            }
        ]
    },
    {
        "category": 39,
        "questions": [
            {
                "question": "Give any one reason why farmers sort seeds before planting them?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Give any one way in which the spread of Hepatitis B disease can be controlled.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Why is biogas regarded as an environmentally friendly source of energy?",
                "type": "text",
                "answer": [
                    "Reduces deforestation",
                    "It is renewable",
                    "can help reduce methane and black carbon emissions"
                ]
            },
            {
                "question": "Name the fungi which normally grows on left over foods.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "State one reason why farmers stake tomato plants.",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Give one way in which the use of solar energy is good tour environment",
                "type": "text",
                "answer": "Does not pollute environment "
            },
            {
                "question": "Apart from running windmills, give one other way wind is used as an energy resource.",
                "type": "check_box",
                "answer": [
                    "For drying clothes",
                    "flying kites"
                ],
                "Options": [
                    "",
                    ""
                ]
            },
            {
                "question": "Apart from liquids, name one other state of mater through which heat is transferred by convection",
                "type": "text",
                "answer": "Gas"
            },
            {
                "question": "The diagram below is a section of a flower.Use it to answer question 39 and40 ",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2011_39.jpg",
                "sub_questions": [
                    {
                        "question": "Name the part marked A",
                        "type": "text",
                        "answer": [
                            "Sepal"
                        ]
                    },
                    {
                        "question": "which part of a flower produces male reproductive cells",
                        "type": " check-box",
                        "answer": [
                            "Anthers"
                        ],
                        "options": [
                            "Anthers",
                            "stigma",
                            "filament",
                            "sepal"
                        ]
                    }
                ]
            },
            {
                "question": "Write down the first Aid you would give to a person who has been bitten by a poisonous snake on the lower part of the leg.",
                "type": "check_box",
                "answer": [
                    "Tie slightly above the bitten part"
                ],
                "options": [
                    "Tie slightly above the bitten part",
                    "Tie on  the bitten part",
                    "Tie slightly above the bitten part"
                ]
            },
            {
                "question": "Name the part of a flower that holds the anther head",
                "type": "text",
                "answer": [
                    "filament"
                ]
            },
            {
                "question": "Name the part of a flower that holds the anther head.",
                "type": "text",
                "answer": [
                    "filament"
                ]
            },
            {
                "question": "Why is it easier for Otim to push a wheel barrow full of manure downhill than uphill?",
                "type": "check_box",
                "answer": [
                    "Downhill, Otim is assisted by the force of gravity"
                ],
                "options": [
                    "Downhill, Otim is assisted by the force of gravity",
                    "Downhill, Otim is not assisted by the force of gravity",
                    "Downhill, Otim is assisted by the force of friction",
                    "Downhill, Otim is assisted by the force of viscocity"
                ]
            },
            {
                "question": "How does heat from the sun reach the ground?",
                "type": "text",
                "answer": [
                    "By radiation"
                ]
            },
            {
                "question": "Which bone in the human skeleton protects the brain?",
                "type": "text",
                "answer": [
                    "The skull"
                ]
            }
        ]
    },
    {
        "category": 40,
        "questions": [
            {
                "question": "Apart from the skin, give any one other human body organ that excretes excess water",
                "type": "text",
                "answer": "The Kidneys"
            },
            {
                "question": "How are plants important in a food chain?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "State any one characteristic of seeds dispersed by wind.",
                "type": "text",
                "answer": [
                    "They are light",
                    "They have hairy extension",
                    "Have wing-like extension"
                ]
            },
            {
                "question": "What happens to light rays when they meet a convex lens?",
                "type": "text",
                "answer": ""
            },
            {
                "question": "Apart from the number of body parts, state one other difference between Arachnids and insects.",
                "type": "check_box",
                "answer": [
                    "Arachnids have four pairs of legs while insects have three pairs of legs",
                    "Arachnids have no antennae/feelers whereas insects have",
                    "Arachnids use lung-book for breathing while insects use trachea system"
                ],
                "options": [
                    "",
                    ""
                ]
            },
            {
                "question": "How do the silvery walls of a vacuum flask reduce heat loss?",
                "type": "check_box",
                "answer": [
                    "Reduce heat loss by radiation",
                    "Reflect heat back to the food"
                ],
                "options": [
                    "",
                    ""
                ]
            },
            {
                "question": "In which human body organ does gases exchange take place?",
                "type": "check_box",
                "answer": "lungs"
            },
            {
                "question": "Why does a coin sink when it is placed in a basin full of water?",
                "type": "check_box",
                "answer": [
                    "A coin is denser than water"
                ],
                "options": [
                    "A coin is denser than water",
                    "A coin is less dense than water",
                    "A coin is equally dense as the water",
                    "water is more dense than a coin"
                ]
            },
            {
                "question": "Give one reason why people who get badly burnt are given plenty of fluids",
                "type": "check_box",
                "answer": [
                    "To prevent dehydration"
                ],
                "options": [
                    "To prevent dehydration",
                    "To promote quick recovery",
                    "its a form of first aid"
                ]
            },
            {
                "question": "State an immunizeable disease which enters our bodies through cuts and wounds.",
                "type": "text",
                "answer": [
                    "Tetanus "
                ]
            },
            {
                "question": "Give any one way in which teeth are important in the digestion of food.",
                "type": "check_box",
                "answer": [
                    "The breakdown food to ease swallowing"
                ],
                "options": [
                    "The breakdown food to ease swallowing",
                    "The form  food to ease swallowing",
                    "they release enzymes that digest food",
                    "They breakdown food to reduce surface area for enzyme action"
                ]
            },
            {
                "question": "Give any one characteristic which is common in all the invertebrates.",
                "type": "check_box",
                "answer": [
                    "They possess a vertebral column"
                ],
                "options": [
                    "They possess a vertebral column",
                    "They have an external skeleton",
                    "They have a hydrostatic skeleton",
                    "They have no skeleton"
                ]
            }
        ]
    },
    {
        "category": 41,
        "questions": [
            {
                "question": "Name the component of air used for;",
                "sub_questions": [
                    {
                        "question": "respiration",
                        "type": "text",
                        "answer": "oxygen"
                    },
                    {
                        "question": "putting out fire",
                        "type": "text",
                        "answer": "carbon-dioxide"
                    }
                ]
            },
            {
                "sub_questions": [
                    {
                        "question": "Name the outermost layer of the human skin.",
                        "type": "text",
                        "answer": ""
                    },
                    {
                        "question": "State any one waste product removed by the skin.",
                        "type": "text",
                        "answer": ""
                    },
                    {
                        "question": "Apart from removing wastes, mention two other functions of the human skin.",
                        "type": "text",
                        "answer": ""
                    }
                ]
            },
            {
                "question": " Give any two signs that a baby is dehydrated",
                "type": "text",
                "answer": [
                    "Sunken eyes",
                    "Pale skin",
                    "a dry or sticky mouth."
                ],
                "sub_questions": [
                    {
                        "question": "State any two pieces of advice to give to a mother whose baby is dehydrated.",
                        "type": "text",
                        "answer": [
                            "Give oral rehydration salt solution",
                            "Give a lot of fruit fluids"
                        ]
                    }
                ]
            },
            {
                "question": " State any two ways in which vectors spread diseases to humans.",
                "type": "text",
                "answer": [
                    "",
                    "",
                    ""
                ],
                "sub_questions": [
                    {
                        "question": "Give any two ways of preventing vectors from spreading diseases to humans.",
                        "type": "text",
                        "answer": [
                            "",
                            ""
                        ]
                    }
                ]
            },
            {
                "question": "Give two examples of natural fertilizers",
                "type": "check_box",
                "answer": [
                    ""
                ],
                "options": [],
                "sub_questions": [
                    {
                        "question_text": "State two ways in which natural fertilizers are better than artificial fertilizers.",
                        "type": "check_box",
                        "answer": [
                            "",
                            ""
                        ],
                        "options": [
                            "",
                            "",
                            ""
                        ]
                    }
                ]
            },
            {
                "question": " name two poisonous substances found in tobacco",
                "type": "check_box",
                "answer": [
                    "Tar",
                    "Nicotine"
                ],
                "options": [
                    "marijuana",
                    "coffee"
                ],
                "sub_questions": [
                    {
                        "question_text": "State any two effects of cigarette smoking by pregnant women to their unborn babies",
                        "type": "check_box",
                        "answer": [
                            "leads to underweight",
                            "leads to still birth"
                        ],
                        "options": [
                            "",
                            "",
                            ""
                        ]
                    }
                ]
            },
            {
                "question": "The diagram below shows an insect. Use it to answer the question that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2013_41.jpg",
                "sub_questions": [
                    {
                        "question": "Name the parts marked C and D ",
                        "sub_questions": [
                            {
                                "question_text": "C",
                                "type": "MultipleChoice",
                                "answer": "Spiracle",
                                "Options": [
                                    "antenna",
                                    "legs",
                                    "Anthers"
                                ]
                            },
                            {
                                "question_text": "D",
                                "type": "MultipleChoice",
                                "answer": "antenna",
                                "Options": [
                                    "spiracles",
                                    "legs",
                                    "Anthers"
                                ]
                            }
                        ]
                    },
                    {
                        "question": "State the function of part marked D",
                        "type": "check_box",
                        "answer": [
                            "Used for feeling",
                            "Detect danger"
                        ],
                        "Options": [
                            "breathing",
                            "flight"
                        ]
                    },
                    {
                        "question": "Why would the insect above die when the whole of its abdomen is dipped in oil?",
                        "type": "check_box",
                        "answer": [
                            "Oil blocks the spiracles and cut of oxygen"
                        ],
                        "Options": [
                            "Oil is poisonous",
                            " The abdomen is too small"
                        ]
                    }
                ]
            },
            {
                "question": "Gives any two example of a physical change",
                "type": "check_box",
                "answer": [
                    "melting of ice",
                    "Evaporation"
                ],
                "Options": [],
                "sub_questions": [
                    {
                        "question": "State two characteristics of a chemical change",
                        "type": "check_box",
                        "answer": [
                            "Heat is given out",
                            "Form new substance"
                        ],
                        "Options": [
                            "",
                            ""
                        ]
                    },
                    {
                        "question": "Give one way in which primary health care (PHC) is important to a community",
                        "type": "check_box",
                        "answer": [
                            "it prevents childhood immunizable diseases"
                        ],
                        "Options": [
                            "it prevents childhood immunizable diseases",
                            "it increases population growth",
                            "it causes bad smell in the society",
                            "it is a source of income"
                        ],
                        "sub_questions": [
                            {
                                "question": "State the elements of primary Health Care which is promoted by each of the following activities:",
                                "sub": [
                                    {
                                        "question": "Sweeping the kitchen",
                                        "type": "multipleChoice",
                                        "answer": [
                                            "water and sanitation"
                                        ],
                                        "Options": [
                                            "water and sanitation",
                                            "immunization",
                                            "food and nutrition"
                                        ]
                                    },
                                    {
                                        "question": "Putting drops   of polio vaccine into the mouth of a child.",
                                        "type": "multipleChoice",
                                        "answer": [
                                            "immunization"
                                        ],
                                        "Options": [
                                            "water and sanitation",
                                            "immunization",
                                            "food and nutrition"
                                        ]
                                    },
                                    {
                                        "question": "Eating a balanced diet.",
                                        "type": "multipleChoice",
                                        "answer": [
                                            "food and nutrition"
                                        ],
                                        "Options": [
                                            "water and sanitation",
                                            "immunization",
                                            "food and nutrition"
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "question": "Name one natural and artificial source of light",
                        "sub_questions": [
                            {
                                "question": "Natural: ",
                                "type": "MultipleChoice",
                                "answer": [
                                    "sun, "
                                ],
                                "Options": [
                                    "sun",
                                    "moon",
                                    "light bulb",
                                    "generator"
                                ]
                            },
                            {
                                "question": "Natural: ",
                                "type": "MultipleChoice",
                                "answer": [
                                    "electric bulb"
                                ],
                                "Options": [
                                    "electric bulb",
                                    "moon",
                                    "fireflies",
                                    "generator"
                                ]
                            },
                            {
                                "question": "Why is the moon not regarded as a source of light?",
                                "type": "check_box",
                                "answer": [
                                    "The moon reflects light from the sun"
                                ],
                                "options": [
                                    "The moon reflects light from the sun",
                                    "The moon absorbs light from the sun",
                                    "The moon sun reflects light from the moon",
                                    "The sun absorbs light from the moon"
                                ]
                            },
                            {
                                "question": "Give one way in which reflection of light is important to man.",
                                "type": "check_box",
                                "answer": [
                                    "Enables man to see"
                                ],
                                "options": [
                                    "Enables man to see",
                                    "Enables man to hear",
                                    "Enables man to talk"
                                ]
                            }
                        ]
                    },
                    {
                        "question": "The following diagram shows two types of teeth in human beings. Use it to answer question that follow",
                        "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2010_41.jpg",
                        "sub_questions": [
                            {
                                "question": "Name the type of tooth marked A:",
                                "type": "text",
                                "answer": "Canine"
                            },
                            {
                                "question": "Name the type of tooth marked B:",
                                "type": "text",
                                "answer": "incisor"
                            },
                            {
                                "question": "Give the use of A in the diagram above:",
                                "type": "text",
                                "answer": "for tearing food"
                            },
                            {
                                "question": "Give the use of B in the diagram above:",
                                "type": "text",
                                "answer": "for cutting food"
                            },
                            {
                                "question": "Use the diagram below   to answer questions that follow.",
                                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2010_41.jpg",
                                "sub_questions": [
                                    {
                                        "question": "What name is given to the above equipment?",
                                        "type": "text",
                                        "answer": [
                                            "Fire extinguisher"
                                        ]
                                    },
                                    {
                                        "question": "Why is the above equipment always painted with bright colours?",
                                        "type": "text",
                                        "answer": [
                                            "To be seen easily"
                                        ]
                                    },
                                    {
                                        "question": "Why is such an equipment recommended to be in school?",
                                        "type": "text",
                                        "answer": [
                                            "To be used in case of fire outbreak"
                                        ]
                                    },
                                    {
                                        "question": "What gas is contained in the above equipment?",
                                        "type": "text",
                                        "answer": [
                                            "Carbon dioxide"
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "question": "The diagram below shows a human joint.Use   it to answer the questions that follow",
                        "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2007_41.jpg",
                        "sub_questions": [
                            {
                                "question": "Name the part   marked with letter Y ",
                                "type": "text",
                                "answer": "Ligament"
                            },
                            {
                                "question": "Name the part   marked with letter Z ",
                                "type": "text",
                                "answer": "cartilage"
                            },
                            {
                                "question": "Give any one function of the fluid found in the place marked with letter X",
                                "type": "check_box",
                                "answer": [
                                    "The synovial fluid reduces friction"
                                ],
                                "options": [
                                    "The synovial fluid reduces friction",
                                    "The synovial fluid increases friction",
                                    "The synovial fluid keeps the joint stable"
                                ]
                            },
                            {
                                "question": "What  type  of  joint  is shown  in the  diagram  above?",
                                "type": "text",
                                "answer": [
                                    "Hinge joint"
                                ]
                            }
                        ]
                    },
                    {
                        "question": "John fell off a bicycle and broke his thigh bone.Name the injury which john got.  ",
                        "type": "text",
                        "answer": [
                            "A fracture"
                        ],
                        "sub_questions": [
                            {
                                "question": "State any two ways in which john can be given First Aid",
                                "type": "check_box",
                                "answer": [
                                    "apply a splint at the broken area",
                                    "give a pain killer"
                                ],
                                "Options": [
                                    "apply a splint at the broken area",
                                    "Giving an injection",
                                    "give a pain killer",
                                    "using a plaster"
                                ]
                            },
                            {
                                "question": "What is the importance of giving first Aid to a person like John?",
                                "type": "check_box",
                                "answer": [
                                    "Reduce pain"
                                ],
                                "options": [
                                    "Reduce pain",
                                    "To avoid injury",
                                    "increase pain",
                                    "to stop headache"
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "category": 42,
                "questions": [
                    {
                        "question": "The diagram below shows the lifecycle of a vector. Study it and answer the questions that follow",
                        "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2019_42.jpg",
                        "sub_questions": [
                            {
                                "question": "Name the vector whose life cycle is shown above",
                                "type": "text",
                                "answer": ""
                            },
                            {
                                "question": "Name the stage marked B",
                                "type": "text",
                                "answer": "Eggs"
                            },
                            {
                                "question": "what does stage A feed on?",
                                "type": "text",
                                "answer": ""
                            },
                            {
                                "question": "how does the vector whose life cycle is shown above spread disease??",
                                "type": "text",
                                "answer": ""
                            }
                        ]
                    },
                    {
                        "question": "The diagram below shows a glass container into which water and cooking oil were poured. The two liquids settled as shown. Study and use it to answer the questions that follow",
                        "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2018_42.jpg",
                        "sub_questions": [
                            {
                                "question": "Which of the two liquids is represented by:Q R",
                                "type": "text",
                                "answer": ""
                            },
                            {
                                "question": "State any one method that can be used to separate the two liquids.",
                                "type": "text",
                                "answer": ""
                            },
                            {
                                "question": "Why has liquid Q settled on top of liquid R?",
                                "type": "text",
                                "answer": ""
                            }
                        ]
                    },
                    {
                        "question": "Use the living things listed below to complete the given food chain",
                        "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2017_42.png",
                        "sub_questions": [
                            {
                                "question": "Which one of the livings in the food chain above is a producer?",
                                "type": "text",
                                "answer": "grass"
                            },
                            {
                                "question": "What is the source of energy for the producer in the food chain above?",
                                "type": "text",
                                "answer": "sunlight"
                            }
                        ]
                    },
                    {
                        "question": "The Diagram Below is of the human Urinary system. Study it to answer the questions that follow",
                        "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2016_42.jpg",
                        "sub_questions": [
                            {
                                "question": "Name the part marked S ",
                                "type": "text",
                                "answer": ""
                            },
                            {
                                "question": "Name the part marked T ",
                                "type": "text",
                                "answer": ""
                            },
                            {
                                "question": "What is the function of part marked R?",
                                "type": "text",
                                "answer": ""
                            },
                            {
                                "question": "Give any one disease that affects the above system",
                                "type": "text",
                                "answer": ""
                            }
                        ]
                    },
                    {
                        "question": "The diagram below shows a flowering plant.study and use it to answer the questions that follow",
                        "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2015_42.jpg",
                        "sub_questions": [
                            {
                                "question": "To which group of flowering plants does the above plant belong?",
                                "type": "check_box",
                                "answer": "",
                                "options": [
                                    "",
                                    "",
                                    ""
                                ]
                            },
                            {
                                "question": "What type of root system does the plant have?",
                                "type": "check_box",
                                "answer": "",
                                "options": [
                                    "",
                                    "",
                                    ""
                                ]
                            },
                            {
                                "question": "State the importance of the type of root marked Y to the plant.",
                                "type": "text",
                                "answer": ""
                            },
                            {
                                "question": "In which one way is part W different from X in their reproductive function?",
                                "type": "check_box",
                                "answer": "",
                                "options": [
                                    "",
                                    "",
                                    ""
                                ]
                            }
                        ]
                    },
                    {
                        "question": "The diagram below Q, R and S show the stages of germination of a seed. Study and use it to answer the questions that follow.",
                        "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2014_42.jpg",
                        "sub_questions": [
                            {
                                "question": "Name the part marked X: ",
                                "type": "text",
                                "answer": "cotyledon"
                            },
                            {
                                "question": "How is the part marked X useful to the seedling at stage R.",
                                "type": "text",
                                "answer": "Provides food to the plant"
                            },
                            {
                                "question": "Why is the part marked X useless at stage S?",
                                "type": "text",
                                "answer": "The food in the cotyledon is used up"
                            },
                            {
                                "question": "What type of germination is shown by the seed",
                                "type": "text",
                                "answer": "Epigeal germination"
                            }
                        ]
                    },
                    {
                        "question": "Apart from the lack of conditions necessary for germination, give any two other factors that can make a seed fail to germinate",
                        "type": "check_box",
                        "answer": [
                            "immaturity of embryo",
                            "hard impervious cuticle"
                        ],
                        "options": [
                            "Very large seed size",
                            "too much sunlight"
                        ],
                        "sub_questions": [
                            {
                                "question_text": "Write down any two ways   farmers can control pests in the garden without using chemicals",
                                "type": "check_box",
                                "answer": [
                                    "use biological enemies of the pest",
                                    "by burning infected crops"
                                ],
                                "options": [
                                    "By Harvesting the crops",
                                    ""
                                ]
                            }
                        ]
                    },
                    {
                        "question": "The diagram below of a human skin. Use it to answer questions 42",
                        "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2012_42jpg",
                        "sub_questions": [
                            {
                                "question": "Name the layers A and B",
                                "sub": [
                                    {
                                        "question": "A",
                                        "type": "text",
                                        "answer": "Epidermis"
                                    },
                                    {
                                        "question": "B",
                                        "type": "text",
                                        "answer": "Dermis"
                                    }
                                ]
                            },
                            {
                                "question": "The diagram below is of a fish.Use it to answer the follow",
                                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2011_42.jpg",
                                "sub_questions": [
                                    {
                                        "question": "Name the part labeled R",
                                        "type": "text",
                                        "answer": [
                                            "lateral line"
                                        ]
                                    },
                                    {
                                        "question": "Give the function of the part labeled P ",
                                        "type": " check-box",
                                        "answer": [
                                            "for taking in food"
                                        ],
                                        "options": [
                                            "for taking in food",
                                            "it allows in water with dissolved oxygen",
                                            "for breathing",
                                            "It supports excretion"
                                        ]
                                    },
                                    {
                                        "question": "Give the function of the part labeled U ",
                                        "type": " check-box",
                                        "answer": [
                                            "it allows in water with dissolved oxygen"
                                        ],
                                        "options": [
                                            "for taking in food",
                                            "it allows in water with dissolved oxygen",
                                            "for breathing",
                                            "It supports excretion"
                                        ]
                                    },
                                    {
                                        "question": "What structure is covered by the part labeled Q?",
                                        "type": "text",
                                        "answer": [
                                            "gills. "
                                        ]
                                    }
                                ]
                            },
                            {
                                "question": "The diagram below shows how disease can be spread through the 4Fs.Use it to answer question 42. ",
                                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2010_42.jpg",
                                "sub_questions": [
                                    {
                                        "question": "Give the word which should be in the box marked X ",
                                        "type": "text",
                                        "answer": [
                                            "Finger"
                                        ]
                                    },
                                    {
                                        "question": "Name two activities which can be done to avoid the spread of diseases through food. ",
                                        "type": "check_box",
                                        "answer": [
                                            "boiling",
                                            "covering food"
                                        ],
                                        "options": [
                                            "boiling",
                                            "covering food",
                                            "storing food"
                                        ]
                                    },
                                    {
                                        "question": "What structure on the body of fly makes it spread diseases?",
                                        "type": "text",
                                        "answer": [
                                            "Hair on its body"
                                        ]
                                    }
                                ]
                            },
                            {
                                "question": "How does a guitar produce sound when its string is plucked?",
                                "type": "text",
                                "answer": [
                                    "By vibration"
                                ],
                                "sub_questions": [
                                    {
                                        "question": "How can the pitch of a guitar be changed?",
                                        "type": "check_box",
                                        "answer": [
                                            "by tightening the string"
                                        ],
                                        "Options": [
                                            "by tightening the string",
                                            "by increasing the length of the string",
                                            "By increasing the numbe rof strings",
                                            " by decreasing the number of strings"
                                        ]
                                    },
                                    {
                                        "question": "Name one way of storing sound.",
                                        "type": "check_box",
                                        "answer": [
                                            "Through tape recorder."
                                        ],
                                        "Options": [
                                            "Through tape recorder.",
                                            "using a casette radio",
                                            "By playing the sound on speakers",
                                            " by playing the sound on tv"
                                        ]
                                    },
                                    {
                                        "question": "Give a reason why sound cannot travel through a vacuum.",
                                        "type": "check_box",
                                        "answer": [
                                            "There is no matter to vibrate"
                                        ]
                                    }
                                ]
                            }
                        ],
                        "Options": [
                            "There is no matter to vibrate",
                            "vacuum has no sound",
                            "vacuum has no  air"
                        ]
                    }
                ]
            },
            {
                "question": "Apart from the skin, give any one example of a respiratory organ.",
                "type": "text",
                "answer": [
                    "Lungs"
                ],
                "sub_questions": [
                    {
                        "question": "List any two diseases which affect the respiratory organ in humans",
                        "type": "check_box",
                        "answer": [
                            "Lung cancer",
                            "Pneumonia",
                            "malaria",
                            "dental carries"
                        ],
                        "Options": [
                            "Lung cancer",
                            "Pneumonia",
                            "malaria",
                            "dental carries"
                        ]
                    },
                    {
                        "question": "Give any one practice by humans which may lead to a respiratory disease.",
                        "type": "text",
                        "answer": [
                            "Smoking"
                        ]
                    }
                ]
            },
            {
                "question": "An experiment was done by a P 7   class as shown in the diagram below. Use it to answer the question that follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2007_42.jpg",
                "sub_questions": [
                    {
                        "question": "When is such a method of finding volume used?",
                        "type": "text",
                        "answer": [
                            "To find the volume of irregular objects"
                        ]
                    },
                    {
                        "question": "If the object above has a density of 2 g/cm3. Find its mass.",
                        "type": "text",
                        "answer": [
                            "30g"
                        ],
                        "explanation": [
                            "volume = 15cc<br> Mass = density * volume <br> = 2*15 <br> =30g"
                        ],
                        "options": [
                            "30g",
                            "15g",
                            "12g",
                            "22g"
                        ]
                    }
                ]
            },
            {
                "question": "The diagram shows a human heart.Use to answer the questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2006_42.jpg",
                "sub_questions": [
                    {
                        "question": "What is the function of the part marked with letter Y?",
                        "type": "text",
                        "answer": "Prevent backflow of blood"
                    },
                    {
                        "question": "Give the difference between the blood in the regions marked with letters A and B ",
                        "type": "check_box",
                        "answer": [
                            "Blood in A is deoxygenated while that in B contains oxygenated blood"
                        ],
                        "options": [
                            "Blood in A is deoxygenated while that in B contains oxygenated blood",
                            "Blood in A is oxygenated while that in B contains deoxygenated blood",
                            "Blood in A is oxygenated while that in B contains oxygenated blood"
                        ]
                    }
                ]
            }
        ]
    },
    {
        "category": 43,
        "questions": [
            {
                "question": "write down any two causes of nose bleeding",
                "type": "text",
                "answer": [
                    "Injury to the blood vessels in the nose",
                    "Frequent blowing of the nose",
                    "Dry air or Nasal Irritation"
                ],
                "sub_questions": [
                    {
                        "question": "Why is a person whose nose is bleeding advised to;",
                        "sub_questions": [
                            {
                                "question": "lean back his/her head?",
                                "type": "text",
                                "answer": "Leaning back helps in the clotting of blood"
                            },
                            {
                                "question": "pinch the soft part of his/her nose?",
                                "type": "text",
                                "answer": "it helps apply pressure to the torn blood vessels"
                            }
                        ]
                    }
                ]
            },
            {
                "question": "In the table below, part A shows processes in human reproduction. Part B shows meaning of the processes in their wrong order. Select from the table the correct meaning of the processes and write in the space provided below",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2018_43.png",
                "sub_questions": [
                    {
                        "a": "Ovulation",
                        "type": "text",
                        "answer": "Loudness or Softness of sound"
                    },
                    {
                        "b": "Implantation",
                        "type": "text",
                        "answer": "Highness or lowness of sound"
                    },
                    {
                        "c": "Pregnancy",
                        "type": "text",
                        "answer": "Number of vibrations per second"
                    },
                    {
                        "d": "fertilization",
                        "type": "text",
                        "answer": "Reflected sound"
                    },
                    {
                        "question": "The diagram below shows circulation of blood in the body. Study and use it to answer the questions that follow.",
                        "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2017_43.jpg",
                        "sub_questions": [
                            {
                                "question": "Name the organ marked with letter X",
                                "type": "text",
                                "answer": "lungs"
                            },
                            {
                                "question": "Which blood vessel is marked with letter W?",
                                "type": "text",
                                "answer": "Plumonary vein"
                            },
                            {
                                "question": "State the similarity between blood vessel Y and blood vessel Z.",
                                "type": "text",
                                "answer": "Both carry deoxygenated blood"
                            },
                            {
                                "question": "Why does vessel W bring blood back to the heart?",
                                "type": "text",
                                "answer": "To get oxygenated"
                            }
                        ]
                    },
                    {
                        "question": "write the correct effect to the activity in the space provided",
                        "sub_questions": [
                            {
                                "question": "Irrigation",
                                "type": "MultipleChoice",
                                "answer": "Leads to death of crop posts",
                                "options": [
                                    "Preserves soil moisture",
                                    "Promotes convectional rainfall",
                                    "Allows growth of crops in all seasons"
                                ]
                            },
                            {
                                "question": "Afforestation",
                                "type": "MultipleChoice",
                                "answer": " Promotes convectional rainfall",
                                "options": [
                                    "Preserves soil moisture",
                                    "Leads to death of crop posts",
                                    "Allows growth of crops in all seasons"
                                ]
                            },
                            {
                                "question": "Mulching",
                                "type": "MultipleChoice",
                                "answer": "  Preserves soil moisture",
                                "options": [
                                    "Promotes convectional rainfall",
                                    "Leads to death of crop posts",
                                    "Allows growth of crops in all seasons"
                                ]
                            },
                            {
                                "question": "Crop Rotation",
                                "type": "MultipleChoice",
                                "answer": "  Allows growth of crops in all seasons",
                                "options": [
                                    "Promotes convectional rainfall",
                                    "Leads to death of crop posts",
                                    "Preserves soil moisture"
                                ]
                            }
                        ]
                    },
                    {
                        "question": "Give any two conditions in which women are vulnerable.",
                        "type": "check_box",
                        "answer": "",
                        "options": [
                            "",
                            "",
                            ""
                        ],
                        "sub_questions": [
                            {
                                "question": "State any two ways in which family members care for vulnerable women.",
                                "type": "check_box",
                                "answer": "",
                                "options": [
                                    "",
                                    "",
                                    ""
                                ]
                            }
                        ]
                    },
                    {
                        "question": "The table below shows joints and their position in the human body. Study and complete it correctly",
                        "sub_questions": [
                            {
                                "question": "Pivot Joint",
                                "type": "MultipleChoice",
                                "Anwswer": "",
                                "Options": [
                                    ""
                                ]
                            },
                            {
                                "question": "Shoulder",
                                "type": "MultipleChoice",
                                "Anwswer": "",
                                "Options": [
                                    ""
                                ]
                            },
                            {
                                "question": "Knee",
                                "type": "MultipleChoice",
                                "Anwswer": "",
                                "Options": [
                                    ""
                                ]
                            },
                            {
                                "question": "Suture Joint",
                                "type": "MultipleChoice",
                                "Anwswer": "",
                                "Options": [
                                    ""
                                ]
                            }
                        ]
                    },
                    {
                        "question": "Give any two advantages of modern beehive",
                        "type": "check_box",
                        "answer": [
                            "gives clean honey",
                            "does not kill bees when honey is harvested."
                        ],
                        "options": [
                            "Cheap to maintain",
                            "Easy to build"
                        ],
                        "sub_questions": [
                            {
                                "question": "write down two things a bee-keeper should consider before a salting up beehives.",
                                "type": "check_box",
                                "answer": "",
                                "options": [
                                    "source of water",
                                    "far from the road, school or market"
                                ]
                            }
                        ]
                    },
                    {
                        "question": "Name the two types of pollination in flowering plants ",
                        "type": "check_box",
                        "answer": [
                            "self-pollination",
                            "cross pollination"
                        ],
                        "Options": [],
                        "sub_questions": [
                            {
                                "question": "Write any two agents of pollination",
                                "type": "check_box",
                                "answer": [
                                    "Insects",
                                    "Wind"
                                ],
                                "Options": [
                                    "fire",
                                    "soil"
                                ]
                            }
                        ]
                    },
                    {
                        "question": "states two differences between birds and mammals",
                        "type": "check_box",
                        "answer": [
                            "birds lay eggs while mammals produce live young ones",
                            "birds have feathers on their bodies "
                        ],
                        "Options": [
                            "birds lay eggs while mammals produce live young ones",
                            "birds have feathers on their bodies",
                            "Birds use gills for breathing",
                            "Birds Dont have Backbones",
                            "mammals reproduce by laying eggs"
                        ],
                        "sub_questions": [
                            {
                                "question": "Give two similarities between birds and mammals",
                                "type": "check_box",
                                "answer": [
                                    "both use lungs for breathing.",
                                    "both birds and mammals are warm blooded animals"
                                ],
                                "Options": [
                                    "both birds and mammals are warm blooded animals",
                                    "both use lungs for breathing.",
                                    "Both move by flight",
                                    "both have two legs"
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "question": "The diagram below shows one of the methods of grazing goats in Uganda.Study it and use it to answer question ",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2010_43.jpg",
                "sub_questions": [
                    {
                        "question": "Name the method of grazing goats shown in the diagram.",
                        "type": "text",
                        "answer": [
                            "Tethering"
                        ]
                    },
                    {
                        "question": "Give two advantages of using the methods shown in the diagram.",
                        "type": "check_box",
                        "answer": [
                            "control over grazing",
                            "Prevents destruction of farmer’s crop"
                        ],
                        "options": [
                            "control over grazing",
                            "Prevents destruction of farmer’s crop",
                            "leads to over grazing"
                        ]
                    },
                    {
                        "question": "Write down one disadvantage of using this method.",
                        "type": "check_box",
                        "answer": [
                            "Animal can easily be stolen"
                        ],
                        "options": [
                            "Animal can easily be stolen",
                            "Animals cannot be easily stolen",
                            "Animals cannot be easily attacked by predators"
                        ]
                    }
                ]
            },
            {
                "question": "The diagram below is of a sugarcane plant.Use it to answer questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2010_43.jpg",
                "sub_questions": [
                    {
                        "question": "Name the part marked with letters M",
                        "type": "text",
                        "answer": [
                            "node"
                        ]
                    },
                    {
                        "question": "Name the part marked with letters L",
                        "type": "text",
                        "answer": [
                            "internode"
                        ]
                    },
                    {
                        "question": "What food value is got eating sugar cane?",
                        "type": "text",
                        "answer": [
                            "carbohydrates"
                        ]
                    },
                    {
                        "question": "How is such a sugarcane plant propagated? ",
                        "type": "text",
                        "answer": [
                            "By stem cutting"
                        ]
                    }
                ]
            },
            {
                "question": "Suggest two reasons why young farmers’ clubs are important in schools",
                "type": "check_box",
                "answer": [
                    "to update them new method of agriculture.",
                    "to organize pupils in order to deliver information for school activities"
                ],
                "Options": [
                    "to organize pupils in order to deliver information for school activities",
                    "to update them new method of agriculture.",
                    "to organize pupils in order to deliver information  to outsiders",
                    "to update them on the new school curriculum"
                ],
                "sub_questions": [
                    {
                        "question": "Give any two activities that can be done by young farmers` clubs in school",
                        "type": "check_box",
                        "answer": [
                            "learning better methods of farming",
                            "demonstration of farming on a school piece of land"
                        ],
                        "Options": [
                            "learning better methods of farming",
                            "demonstration of farming on a school piece of land",
                            "learning better methods of teaching",
                            "demonstration of farming in classrooms"
                        ]
                    }
                ]
            },
            {
                "question": "Give any two characteristics that are common to a frog and as crocodile apart from being vertebrates.",
                "type": "check_box",
                "answer": [
                    "they are both cold blooded animals",
                    "they are both aquatic animal"
                ],
                "Options": [
                    "they are both cold blooded animals",
                    "they are both aquatic animals",
                    "they are both land animals",
                    "they are both warm blooded animals"
                ],
                "sub_questions": [
                    {
                        "question": "How is fertilization of a frog different from that of a crocodile?",
                        "type": "check_box",
                        "answer": [
                            "Fertilization in frogs is external while that of crocodile is internal."
                        ],
                        "Options": [
                            "Fertilization in frogs is external while that of crocodile is internal.",
                            "Fertilization in frogs is internal while that of crocodile is external.",
                            "Fertilization in frogs is external while that of crocodile is external."
                        ]
                    },
                    {
                        "question": "Name any one other vertebrate that can be classified with a frog",
                        "type": "check_box",
                        "answer": [
                            "Toad"
                        ],
                        "options": [
                            "Toad",
                            "whale",
                            "snail",
                            "fish"
                        ]
                    }
                ]
            }
        ]
    },
    {
        "category": 44,
        "questions": [
            {
                "question": "In the table below, part A shows the terms used to describe sound and part B has the meanings of the terms.use the meanings in part B of the table to match the terms below",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2019_44.png",
                "sub_questions": [
                    {
                        "question": "volume",
                        "type": "text",
                        "answer": "Loudness or Softness of sound"
                    },
                    {
                        "question": "pitch",
                        "type": "text",
                        "answer": "Highness or lowness of sound"
                    },
                    {
                        "question": "Frequency",
                        "type": "text",
                        "answer": "Number of vibrations per second"
                    },
                    {
                        "question": "Echo",
                        "type": "text",
                        "answer": "Reflected sound"
                    }
                ]
            },
            {
                "sub_questions": [
                    {
                        "question": "Write down any two causes of weathering of rocks",
                        "type": "text",
                        "answer": ""
                    },
                    {
                        "question": "Apart from weathering, name the other process by which soil is formed.",
                        "type": "text",
                        "answer": ""
                    },
                    {
                        "question": "How do living organisms in soil help to improve soil fertility?",
                        "type": "text",
                        "answer": ""
                    }
                ]
            },
            {
                "question": "Write down any two causes of soil erosion",
                "type": "text",
                "answer": [
                    "wind",
                    "Rain",
                    "animals"
                ],
                "sub_questions": [
                    {
                        "question": "Which agent of soil erosion causes gullies?",
                        "type": "text",
                        "answer": "water"
                    },
                    {
                        "question": "How does soil erosion lead to soil infertility?",
                        "type": "text",
                        "answer": "It remove top fertile soil"
                    }
                ]
            },
            {
                "question": "Give any two examples of non-renewable resources in the environment",
                "type": "check_box",
                "answer": [
                    "",
                    ""
                ],
                "options": [
                    "",
                    ""
                ],
                "sub_questions": [
                    {
                        "question": "state any two ways of conserving non-renewable resources",
                        "type": "check_box",
                        "answer": [
                            "",
                            ""
                        ],
                        "options": [
                            "",
                            ""
                        ]
                    }
                ]
            },
            {
                "question": "Name the respiratory organs which are protected by the rib cage.",
                "type": "text",
                "answer": "",
                "sub_questions": [
                    {
                        "question": "What happens to the rib cage when breathing in:",
                        "type": "MultipleChoice",
                        " answer": "",
                        "Options": [
                            "",
                            "",
                            ""
                        ]
                    },
                    {
                        "question": "What happens to the rib cage when breathing out:",
                        "type": "MultipleChoice",
                        " answer": "",
                        "Options": [
                            "",
                            "",
                            ""
                        ]
                    },
                    {
                        "q1": "Give the use of oxygen we breathe in.",
                        "type": "check_box",
                        " answer": "",
                        "Options": [
                            "",
                            "",
                            ""
                        ]
                    }
                ]
            },
            {
                "question": "state any two agents of soil erosion",
                "type": "check_box",
                "answer": [
                    "wind",
                    "running water",
                    "Animals"
                ],
                "Options": [
                    "",
                    ""
                ],
                "sub_questions": [
                    {
                        "question": "Give two ways in which soil erosion can be controlled in hilly areas.",
                        "type": "check_box",
                        "answer": [
                            "by terracing",
                            "by contour ploughing",
                            "by strip cropping",
                            "By agroforestry",
                            "By planting trees"
                        ],
                        "Options": [
                            "",
                            "",
                            ""
                        ]
                    }
                ]
            },
            {
                "question": "The diagram below is part of the human digestive system. Use it to answer the questions  that follows",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2013_44.jpg",
                "sub_questions": [
                    {
                        "question": "Name the parts marked Y and W",
                        "sub": [
                            {
                                "question": "Y",
                                "type": "MultipleChoice",
                                "answer": "Oesophagus",
                                "Options": [
                                    "pancreas",
                                    "Long intestines"
                                ]
                            },
                            {
                                "question": "W",
                                "type": "MultipleChoice",
                                "answer": "pancreas",
                                "Options": [
                                    "oesophagus",
                                    "Long intestines"
                                ]
                            }
                        ]
                    },
                    {
                        "question": "State the functions of the parts marked Z",
                        "type": "check_box",
                        "answer": "store food",
                        "Options": [
                            "pounds food",
                            "produces enzymes for digestion of proteins"
                        ]
                    },
                    {
                        "question": "Give  any one disease  that affects  the parts  marked X",
                        "type": "check_box",
                        "answer": [
                            "Liver cancer ",
                            "cirrhosis "
                        ],
                        "Options": [
                            "dysentry",
                            "malaria"
                        ]
                    }
                ]
            },
            {
                "question": "Give any two disadvantages of keeping local breeds of poultry",
                "type": "check_box",
                "answer": [
                    "Take long to mature",
                    "Produce a few eggs"
                ],
                "Options": [
                    "mature quickly",
                    "produce alot of eggs"
                ],
                "sub_questions": [
                    {
                        "question": " Apart from getting money, give any two ways a farmer benefits from keeping poultry.",
                        "type": "check_box",
                        "answer": [
                            "Get meat",
                            "Get eggs"
                        ],
                        "Options": [
                            "Bad smell",
                            " Get milk"
                        ]
                    }
                ]
            },
            {
                "question": "The diagram below shows a germinating seed.Use it to answer the questions that follow ",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2011_44.jpg",
                "sub_questions": [
                    {
                        "question": "State the type of germination shown above.",
                        "type": "check_box",
                        "answer": [
                            "hypogeal germination"
                        ],
                        "options": [
                            "hypogeal germination",
                            "Epigeal germination"
                        ]
                    },
                    {
                        "question": "Where does the seed above store the food used during germination?",
                        "type": "check_box",
                        "answer": [
                            "In the endosperm"
                        ],
                        "options": [
                            "In the endosperm",
                            "Cotyledon"
                        ]
                    },
                    {
                        "question": "Give an example of a crop with the same types of germination.",
                        "type": "check_box",
                        "answer": [
                            "Maize"
                        ],
                        "options": [
                            "maize",
                            "mangoes",
                            " sugarcane",
                            "peas"
                        ]
                    },
                    {
                        "question": "Apart from moisture, state any one other condition necessary for seed germination",
                        "type": "check_box",
                        "answer": [
                            "Oxygen"
                        ],
                        "options": [
                            "Oxygen",
                            "carbondioxide",
                            "hydrogen",
                            "salt",
                            ""
                        ]
                    }
                ]
            },
            {
                "question": "Match the items in list A with their function in list B",
                "sub_questions": [
                    {
                        "question": "Red  blood cells",
                        "type": "MultipleChoice",
                        "answer": [
                            "carry oxygen around  the body"
                        ],
                        "Options": [
                            "Stop bleeding when the skin is cut",
                            "Carry  oxygen  around  the body",
                            "Carry  blood  away from the heart ",
                            "Prevent back  flow  of blood  in veins"
                        ]
                    },
                    {
                        "question": "Arteries",
                        "type": "MultipleChoice",
                        "answer": [
                            "carry blood away from the heart"
                        ],
                        "Options": [
                            "Stop bleeding when the skin is cut",
                            "Carry  oxygen  around  the body",
                            "Carry  blood  away from the heart ",
                            "Prevent back  flow  of blood  in veins"
                        ]
                    },
                    {
                        "question": "Valves",
                        "type": "MultipleChoice",
                        "answer": [
                            "prevent back flow of blood"
                        ],
                        "Options": [
                            "Stop bleeding when the skin is cut",
                            "Carry  oxygen  around  the body",
                            "Carry  blood  away from the heart ",
                            "Prevent back  flow  of blood  in veins"
                        ]
                    },
                    {
                        "question": "platelets",
                        "type": "MultipleChoice",
                        "answer": [
                            "stop bleeding when skin is cut"
                        ],
                        "Options": [
                            "Stop bleeding when the skin is cut",
                            "Carry  oxygen  around  the body",
                            "Carry  blood  away from the heart ",
                            "Prevent back  flow  of blood  in veins"
                        ]
                    }
                ]
            },
            {
                "question": "State one way in which a nursery bed is important to a farmer.",
                "type": "check_box",
                "answer": [
                    "It enables easy monitoring of seedlings"
                ],
                "Options": [
                    "It enables easy monitoring of seedlings",
                    "It enables easy monitoring of nursery children",
                    "it is where farmers grow crops"
                ],
                "sub_questions": [
                    {
                        "question": "Name two vegetable crop commonly grown in a nursery bed ",
                        "type": "check_box",
                        "answer": [
                            "carrots",
                            "tomatoes"
                        ],
                        "Options": [
                            "carrots",
                            "tomatoes",
                            "rice",
                            "maize"
                        ]
                    },
                    {
                        "question": "Give one way in which a farmer can care for crops in nursery bed.",
                        "type": "check_box",
                        "answer": [
                            "weeding"
                        ],
                        "Options": [
                            "weeding",
                            "crop rotation",
                            "bush burning",
                            "harvesting"
                        ]
                    }
                ]
            },
            {
                "question": "Name any two of the 5Rs in waste   management ",
                "type": "check_box",
                "answer": [
                    "Recycling",
                    "reuse"
                ],
                "Options": [
                    "Recycling",
                    "reuse",
                    "re-invent",
                    "replace"
                ],
                "sub_questions": [
                    {
                        "question": "Suggest any two effects of poor waste management in the environment",
                        "type": "check_box",
                        "answer": [
                            "it leads to spread of diseases to man",
                            "it leads to environmental degradation"
                        ],
                        "Options": [
                            "it leads to environmental degradation",
                            "it leads to spread of diseases to man",
                            "it leads to spread of diseases to plants",
                            "it promotes environmental conservation"
                        ]
                    }
                ]
            },
            {
                "question": "The diagram below shows a saucepan with some water on fire.Use it to answer the questions that follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2007_44.jpg",
                "sub_questions": [
                    {
                        "question": " How will heat from the fire move to reach the water at the bottom of the saucepan?",
                        "type": "text",
                        "answer": [
                            "Conduction"
                        ]
                    },
                    {
                        "question": " Which method will enable that heat all the water in the saucepan?",
                        "type": "text",
                        "answer": [
                            "Convection"
                        ]
                    },
                    {
                        "question": "What will happen to the water in the saucepan to show that it is boiling?",
                        "type": "check_box",
                        "answer": [
                            "Bumps up and down"
                        ],
                        "options": [
                            "Bumps up and down",
                            "freezes",
                            "causes the saucepan to melt"
                        ]
                    }
                ]
            }
        ]
    },
    {
        "category": 45,
        "questions": [
            {
                "sub_questions": [
                    {
                        "question": "write down any two reasons why toads are classified as amphibians",
                        "type": "text",
                        "answer": [
                            "",
                            ""
                        ]
                    },
                    {
                        "question": "In which two ways are fish different from amphibians?",
                        "type": "text",
                        "answer": [
                            "",
                            ""
                        ]
                    }
                ]
            },
            {
                "question": "Give the importance of each of the following components of oral rehydration solution (ORS) to a patient",
                "sub_questions": [
                    {
                        "question": "Sugar",
                        "type": "text",
                        "answer": ""
                    },
                    {
                        "question": "Water",
                        "type": "text",
                        "answer": ""
                    },
                    {
                        "question": "Apart from using ORS, give two other ways of replacing lost fluids in a patient.",
                        "type": "text",
                        "answer": [
                            "",
                            ""
                        ]
                    }
                ]
            },
            {
                "question": "The table below shows information collected from five homes during a health survey. Study it to answer the question that follow. ",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2017_45.png",
                "sub_questions": [
                    {
                        "question": "Which home had all the sanitation items?",
                        "type": "text",
                        "answer": "B"
                    },
                    {
                        "question": "Identify the sanitation item missing in most of homes surveyed.",
                        "type": "text",
                        "answer": "Latrine"
                    },
                    {
                        "question": "Name any one disease that is likely to attack homes A, C, D and E.",
                        "type": "text",
                        "answer": "Intestinal worms and dysentery."
                    },
                    {
                        "question": "Give any one other sanitation item which could be added in the health survey.",
                        "type": "text",
                        "answer": "Kitchen"
                    }
                ]
            },
            {
                "question": "To which group of plants do ferns and liverworts belong?",
                "type": "check_box",
                "answer": [
                    "",
                    ""
                ],
                "options": [
                    "",
                    ""
                ],
                "sub_questions": [
                    {
                        "question": "How is the reproduction of ferns similar to that of liverworts?",
                        "type": "check_box",
                        "answer": [
                            "",
                            ""
                        ],
                        "options": [
                            "",
                            ""
                        ]
                    },
                    {
                        "question": "Give two other plants that reproduce in the same way as ferns and liverworts.",
                        "type": "check_box",
                        "answer": [
                            "",
                            ""
                        ],
                        "options": [
                            "",
                            ""
                        ]
                    }
                ]
            },
            {
                "question": " what type of change takes place when an iron tool rusts?",
                "type": "text",
                "answer": "Chemical change",
                "sub_questions": [
                    {
                        "question": "Give two conditions necessary for rusting to take place on an iron tool",
                        "type": "check_box",
                        "answer": [
                            "oxygen (not air)",
                            "water"
                        ],
                        "Options": [
                            "",
                            ""
                        ]
                    },
                    {
                        "question": "state any one effect of rusting on iron tools",
                        "type": "check_box",
                        "answer": [
                            "weakens tools",
                            "wmake tools dull/ugly",
                            "make tool blunt"
                        ],
                        "Options": [
                            "",
                            ""
                        ]
                    }
                ]
            },
            {
                "question": " Fill in the corresponding signs and symptoms for each disease",
                "sub_questions": [
                    {
                        "question": "Polio",
                        "type": "MultipleChoice",
                        "answer": "paralyzed tissue",
                        "options": [
                            "swollen neck",
                            "stiffness of muscle",
                            "paralyzed tissues"
                        ]
                    },
                    {
                        "question": "Measles",
                        "type": "MultipleChoice",
                        "answer": "skin rush",
                        "options": [
                            "swollen neck",
                            "stiffness of muscle",
                            "paralyzed tissues"
                        ]
                    },
                    {
                        "question": "Tetanus",
                        "type": "MultipleChoice",
                        "answer": "stiffness of muscle",
                        "options": [
                            "swollen neck",
                            "paralyzed tissue",
                            "paralyzed tissues"
                        ]
                    },
                    {
                        "question": "Diptheria",
                        "type": "MultipleChoice",
                        "answer": "swollen neck",
                        "options": [
                            "stiffness of muscle",
                            "paralyzed tissue",
                            "paralyzed tissues"
                        ]
                    }
                ]
            },
            {
                "question": "Match each animal in A with the correct group to which it belong in B",
                "sub_questions": [
                    {
                        "question": "Chameleon",
                        "type": "MultipleChoice",
                        "answer": "reptile",
                        "Options": [
                            "amphibian",
                            "mammal",
                            "bird"
                        ]
                    },
                    {
                        "question": "Toad",
                        "type": "MultipleChoice",
                        "answer": "amphibian",
                        "Options": [
                            "reptile",
                            "mammal",
                            "bird"
                        ]
                    },
                    {
                        "question": "Bat ",
                        "type": "MultipleChoice",
                        "answer": "mammal",
                        "Options": [
                            "reptile",
                            "amphibian",
                            "bird"
                        ]
                    },
                    {
                        "question": "Ostrich",
                        "type": "MultipleChoice",
                        "answer": "bird",
                        "Options": [
                            "mammal",
                            "amphibian",
                            "bird"
                        ]
                    }
                ]
            },
            {
                "question": "state any two natural causes of environmental degradation",
                "type": "check_box",
                "answer": [
                    "Landslides",
                    "storms"
                ],
                "Options": [
                    "Landslides",
                    "storms",
                    "crop rotation",
                    "bush burning"
                ],
                "sub_questions": [
                    {
                        "question": "Give two human causes of environmental degradation ",
                        "type": "check_box",
                        "answer": [
                            "Bush burning",
                            "Pollution"
                        ],
                        "Options": [
                            "Bush burning",
                            "Pollution",
                            "Landslides",
                            "volcanic eruptions"
                        ]
                    }
                ]
            },
            {
                "question": "Give two benefits of planting trees in your school compound",
                "type": "check_box",
                "answer": [
                    "work as wind breaks",
                    "for timber"
                ],
                "Options": [
                    "for timber",
                    "work as wind breaks",
                    "for leisure",
                    "source of income"
                ],
                "sub_questions": [
                    {
                        "question": "What two things can you do to protect plants in your school compound?",
                        "type": "check_box",
                        "answer": [
                            "Fencing",
                            "watering"
                        ],
                        "Options": [
                            "Fencing",
                            "watering",
                            "cutting them down",
                            "burning trees"
                        ]
                    }
                ]
            },
            {
                "question": "Write  one  dangerous  substance found  in tobacco smoke.",
                "type": "check_box",
                "answer": [
                    "Nicotine"
                ],
                "Options": [
                    "Nicotine",
                    "oxygen",
                    "carbonmonoxide",
                    "carbondioixide"
                ],
                "sub_questions": [
                    {
                        "question": "Give the meaning of each of the following:",
                        "sub_questions": [
                            {
                                "question": "active smoking:",
                                "type": "MultipleChoice",
                                "answer": [
                                    "direct smoking of tobacco through cigarette "
                                ],
                                "Options": [
                                    "direct smoking of tobacco through cigarette",
                                    "breathing in air containing tobacco smoke by non-smoker."
                                ]
                            },
                            {
                                "question": "passive   smoking:",
                                "type": "MultipleChoice",
                                "answer": [
                                    "breathing in air containing tobacco smoke by non-smoker."
                                ],
                                "Options": [
                                    "direct smoking of tobacco through cigarette",
                                    "breathing in air containing tobacco smoke by non-smoker."
                                ]
                            },
                            {
                                "question": "What piece of advice can you give to smokers to help them to stop the habit?",
                                "type": "check_box",
                                "answer": [
                                    "tell about the dangers of smoking"
                                ],
                                "Options": [
                                    "tell about the dangers of smoking",
                                    "encourage them to smoke more",
                                    "report them to the police"
                                ]
                            }
                        ]
                    },
                    {
                        "question": "The diagram below is of an amoeba. Use it to answer questions that follow.",
                        "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2010_45.jpg",
                        "sub_questions": [
                            {
                                "question": "What kind of organism is an amoeba?",
                                "type": "text",
                                "answer": [
                                    "Unicellular organism"
                                ]
                            },
                            {
                                "question": "Name the structure indicated by letter O",
                                "type": "text",
                                "answer": [
                                    "nucleus"
                                ]
                            },
                            {
                                "question": "Name the structure indicated by letter P",
                                "type": "text",
                                "answer": [
                                    "cell membrane"
                                ]
                            },
                            {
                                "question": "How  is the amoeba able  to move  from  place to place?",
                                "type": "text",
                                "answer": [
                                    "By use of pseudopodia"
                                ]
                            }
                        ]
                    },
                    {
                        "question": "The diagram below is of a fish.Study it and answer the questions that follow ",
                        "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2007_45.jpg",
                        "sub_questions": [
                            {
                                "question": "Name the structure marked L",
                                "type": "type",
                                "answer": [
                                    "Ventral fin"
                                ]
                            },
                            {
                                "question": "What is the function of each of the structure marked D ",
                                "type": "type",
                                "answer": [
                                    "propels the fish"
                                ]
                            },
                            {
                                "question": "What is the function of each of the structure marked N",
                                "type": "type",
                                "answer": [
                                    "detects sound waves"
                                ]
                            },
                            {
                                "question": "State the function of the structure covered by the part marked O",
                                "type": "check_box",
                                "answer": [
                                    "The structure covered is the gills that are used for gaseous exchange"
                                ],
                                "options": [
                                    "The structure covered is the gills that are used for gaseous exchange",
                                    "The structure covered is the gills that are used for reproduction",
                                    "The structure covered is the gills that are used for excretion of faecal matter"
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "category": 46,
                "questions": [
                    {
                        "sub_questions": [
                            {
                                "question": "write down any two pieces of information which are collected in a health survey",
                                "type": "text",
                                "answer": [
                                    "",
                                    ""
                                ]
                            },
                            {
                                "question": "Give any two reasons why carrying out a health survey in an area is important",
                                "type": "text",
                                "answer": [
                                    "",
                                    ""
                                ]
                            }
                        ]
                    },
                    {
                        "question": "The diagram below shows a defect of the human eye. Study and use it to answer the questions that follow.",
                        "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2018_46.jpg",
                        "sub_questions": [
                            {
                                "question": "Name the eye defect shown above.",
                                "type": "text",
                                "answer": ""
                            },
                            {
                                "question": "State the two causes of the eye defects shown above.",
                                "type": "text",
                                "answer": [
                                    "",
                                    ""
                                ]
                            },
                            {
                                "question": "What type of lens is used to correct the above eye defect?",
                                "type": "text",
                                "answer": ""
                            }
                        ]
                    },
                    {
                        "question": "What causes fainting? ",
                        "type": "text",
                        "answer": "Low blood to flow to the brain",
                        "sub_questions": [
                            {
                                "question": "State any two conditions that can lead to fainting.",
                                "type": "text",
                                "answer": [
                                    "anxiety",
                                    "fear",
                                    "pain",
                                    "intense emotional stress",
                                    "hunger",
                                    "hunger",
                                    "sickness.",
                                    "use of alcohol or drugs"
                                ]
                            },
                            {
                                "question": "Why are the legs of a person who has fainted raised higher than the head when giving first Aid?",
                                "type": "text",
                                "answer": "To increase blood flow to the brain"
                            }
                        ]
                    },
                    {
                        "question": "Name the change of state which takes place when",
                        "sub_questions": [
                            {
                                "question": "Vapour changes to liquid",
                                "type": "MultipleChoice",
                                "answer": "",
                                "options": [
                                    "",
                                    "",
                                    ""
                                ]
                            },
                            {
                                "question": "A solid changes directly to gas",
                                "type": "MultipleChoice",
                                "answer": "",
                                "options": [
                                    "",
                                    "",
                                    ""
                                ]
                            },
                            {
                                "question": "Apart from causing change of state, give two other effects of heat on matter.",
                                "type": "check_box",
                                "answer": [
                                    "",
                                    ""
                                ],
                                "options": [
                                    "",
                                    ""
                                ]
                            }
                        ]
                    },
                    {
                        "question": " Give the function of each of the following parts of a beehive",
                        "sub_questions": [
                            {
                                "question": "The brood chamber",
                                "type": "MultipleChoice",
                                "answer": "",
                                "options": [
                                    "",
                                    "",
                                    ""
                                ]
                            },
                            {
                                "question": "The honey Chamber",
                                "type": "MultipleChoice",
                                "answer": "",
                                "options": [
                                    "",
                                    "",
                                    ""
                                ]
                            },
                            {
                                "question": "Apart from being used as food, give two other ways in which honey is useful to people.",
                                "type": "check_box",
                                "answer": "",
                                "options": [
                                    "",
                                    "",
                                    ""
                                ]
                            }
                        ]
                    },
                    {
                        "question": "Apart from egg eating, name any one other vice of poultry.",
                        "type": "check_box",
                        "answer": [
                            "Feather pecking",
                            "Cannibalism"
                        ],
                        "options": [
                            "",
                            "",
                            ""
                        ],
                        "sub_questions": [
                            {
                                "question": "Give any one cause of egg eating in poultry",
                                "type": "check_box",
                                "answer": [
                                    "boredom",
                                    "Clack of enough calcium in the diet",
                                    "Delay to pick eggs",
                                    "Delay to pick eggs",
                                    "lack of food"
                                ],
                                "options": [
                                    "",
                                    "",
                                    ""
                                ]
                            },
                            {
                                "question": "State any two ways of preventing the egg eating vice in poultry.",
                                "type": "check_box",
                                "answer": [
                                    "make laying boxes dark",
                                    "give enough food with calcium",
                                    "timely picking of eggs",
                                    "Delay to pick eggs",
                                    "provide enough space"
                                ],
                                "options": [
                                    "",
                                    "",
                                    ""
                                ]
                            }
                        ]
                    },
                    {
                        "question": "Give any one two ways which drug dependence can affect an individual",
                        "type": "check_box",
                        "answer": [
                            "Self-neglect",
                            "neglect of the job"
                        ],
                        "options": [
                            "Improves Health",
                            "Improves personal hygiene"
                        ],
                        "sub_questions": [
                            {
                                "question": "State two ways in which an individual can avoid drug dependence",
                                "type": "check_box",
                                "answer": [
                                    "by joining good pear groups",
                                    "by trusting god",
                                    "by going for guidance and counselling"
                                ],
                                "options": [
                                    "By changing dosage",
                                    "By taking more "
                                ]
                            }
                        ]
                    },
                    {
                        "question": "The diagram below shows how light passes through a glass block.Study and use it to answer question 46",
                        "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2012_46.jpg",
                        "sub_questions": [
                            {
                                "question": "Name the rays M and P",
                                "sub": [
                                    {
                                        "question": "M",
                                        "type": "text",
                                        "answer": "incident ray "
                                    },
                                    {
                                        "question": "P",
                                        "type": "text",
                                        "answer": "Refracted ray "
                                    }
                                ]
                            },
                            {
                                "question": "Name the angle marked P",
                                "type": "check_box",
                                "answer": "Angle of refraction",
                                "options": [
                                    "Angle of incidence",
                                    "Angle of reflection"
                                ]
                            },
                            {
                                "question": "Give a reason why ray P   bend as shown in the diagram",
                                "type": "check_box",
                                "answer": "Light is moving from less dense medium to a denser medium",
                                "options": [
                                    "Light is moving from more dense medium to a less dense medium",
                                    "Light is moving from more denser medium to an even denser medium"
                                ]
                            }
                        ]
                    },
                    {
                        "question": "Which part of the body is affected by each of the following diseases",
                        "sub_questions": [
                            {
                                "question": "Scabies",
                                "type": "multipleChoice",
                                "answer": [
                                    "skin"
                                ],
                                "Options": [
                                    "skin",
                                    "Eyes",
                                    "legs",
                                    "ears"
                                ]
                            },
                            {
                                "question": "Trachoma ",
                                "type": "multipleChoice",
                                "answer": [
                                    "Eyes"
                                ],
                                "Options": [
                                    "skin",
                                    "Eyes",
                                    "legs",
                                    "ears"
                                ]
                            }
                        ],
                        "Sub_question_2": [
                            {
                                "question": "Bilharzias",
                                "type": "multipleChoice",
                                "answer": [
                                    "water snails"
                                ],
                                "Options": [
                                    "water snails",
                                    "Rabid dogs",
                                    "mosquito",
                                    "rats"
                                ]
                            },
                            {
                                "question": " Rabies",
                                "type": "multipleChoice",
                                "answer": [
                                    "Eyes"
                                ],
                                "Options": [
                                    "water snails",
                                    "Rabid dogs",
                                    "mosquito",
                                    "rats"
                                ]
                            }
                        ]
                    },
                    {
                        "question": "Name the type of cattle kept for milk production.",
                        "type": "text",
                        "answer": [
                            "Diary cattle"
                        ],
                        "sub_questions": [
                            {
                                "question": "Give one example of exotic breeds of cattle which are the best for milk production",
                                "type": "check_box",
                                "answer": [
                                    "Jersey"
                                ],
                                "Options": [
                                    "Jersey",
                                    "Ankole",
                                    "East African short horn zebu"
                                ]
                            },
                            {
                                "question": "Write  down  two activities  a farmer  should  do  in order  to get  clean  milk  from a cow.",
                                "type": "check_box",
                                "answer": [
                                    "filtering milk",
                                    "test for mastitis"
                                ],
                                "Options": [
                                    "filtering milk",
                                    "test for mastitis",
                                    "avoid washing it",
                                    "keep it from impurities like preservatives"
                                ]
                            }
                        ]
                    },
                    {
                        "question": "The density of sand is 3 grams per cubic centimeter. Its mass is 270 grams. Find its volume.",
                        "sub_questions": [
                            {
                                "question": "",
                                "type": "MultipleChoice",
                                "answer": [
                                    "90cm<sup>3</sup>"
                                ],
                                "explanations": "Desnsity = mass/volume <br> implies that volume = mass/desnity <br> = 270/3 <br> = 90cm<sup>3</sup>",
                                "Options": [
                                    "90cm<sup>3</sup>",
                                    "90cm<sup>2</sup>",
                                    "80cm<sup>2</sup>",
                                    "70cm<sup>2</sup>"
                                ]
                            }
                        ]
                    },
                    {
                        "question": "The illustration below show different types of light rays. Use them to answer questions that follow:",
                        "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2010_46.jpg",
                        "sub_questions": [
                            {
                                "question": "Name the following rays of light Q",
                                "type": "text",
                                "answer": [
                                    "Parallel rays"
                                ]
                            },
                            {
                                "question": "Name the following rays of light S",
                                "type": "text",
                                "answer": [
                                    "Converging rays"
                                ]
                            },
                            {
                                "question": "What eye defect is corrected by a lens which makes rays move as shown in R?",
                                "type": "text",
                                "answer": [
                                    "Short sightedness"
                                ]
                            }
                        ]
                    },
                    {
                        "question": "The diagram below, show a seeding in a box.Study it and answer the question that follow.",
                        "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2007_46.jpg",
                        "sub_questions": [
                            {
                                "question": "What colour will the leaves of the seeding be if left in this box with H covered for a week?",
                                "type": "text",
                                "answer": [
                                    "yellow"
                                ]
                            },
                            {
                                "question": "Give a reason for your answer in (a) above.",
                                "type": "check_box",
                                "answer": [
                                    "Absence of light prevents formation of chlorophyll"
                                ],
                                "options": [
                                    "Absence of light prevents formation of chlorophyll",
                                    "Absence of light causes the  formation of chlorophyll",
                                    "Absence of light prevents formation of starch",
                                    "Absence of light prevents formation of fruits"
                                ]
                            },
                            {
                                "question": "Give any two things that are likely to  happen  to the seedling  if the  part marked with letter H  is left is left open.",
                                "type": "check_box",
                                "answer": [
                                    "the leaves will turn green"
                                ],
                                "options": [
                                    "the leaves will turn green",
                                    "the leaves will turn yellow",
                                    "the leaves will turn blue",
                                    "the leaves will turn red"
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "category": 47,
                "questions": [
                    {
                        "question": "The diagram below is of a developing foetus in a mother's womb. study it and answer the questions that follow",
                        "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2019_47.jpg",
                        "sub_questions": [
                            {
                                "question": "Name the substance marked N",
                                "type": "text",
                                "answer": ""
                            },
                            {
                                "question": "Give two uses of part marked K to the foetus",
                                "answer": [
                                    "",
                                    ""
                                ]
                            },
                            {
                                "question": "On the diagram above, label the placenta",
                                "type": "text",
                                "answer": ""
                            }
                        ]
                    },
                    {
                        "question": "Use the list of crops given to answer the questions that follow.Conifer, coffee, cassava, groundnuts ",
                        "sub_questions": [
                            {
                                "question": "Which crop on the list is propagated by use of stem cutting?",
                                "type": "text",
                                "answer": "Cassava"
                            },
                            {
                                "question": "Identify any one crop on the list which is An Annual Crop:",
                                "type": "text",
                                "answer": "Groundnuts"
                            },
                            {
                                "question": "Identify any one crop on the list which is A Perennial crop Crop:",
                                "type": "text",
                                "answer": "Coffee, conifer"
                            },
                            {
                                "question": "Give any one way in which a conifer is different from all the other crops on the list",
                                "type": "text",
                                "answer": "It bears cones and not flowers, produces naked seed"
                            },
                            {
                                "question": "The diagram below shows a type of eclipse. Study and use it to answer the questions that follow",
                                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2016_47.jpg",
                                "sub_questions": [
                                    {
                                        "question": "Name the type of eclipse shown in the diagram above",
                                        "type": "text",
                                        "answer": ""
                                    },
                                    {
                                        "question": "what happens to a person who would be in part x during the eclipse?",
                                        "type": "text",
                                        "answer": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "question": "What kind of food is made by green plants during photosynthesis?",
                        "type": "text",
                        "answer": "",
                        "sub_questions": [
                            {
                                "question": "State the one raw material used by green plants for photosynthesis.",
                                "type": "text",
                                "answer": ""
                            },
                            {
                                "question": "How is chlorophyll important during the process of photosynthesis?",
                                "type": "text",
                                "answer": ""
                            },
                            {
                                "question": "Give one reason why photosynthesis does not take place at night.",
                                "type": "text",
                                "answer": ""
                            }
                        ]
                    },
                    {
                        "question": "The diagram below shows a kerosene lamp. Study and use it to answer the questions that follow",
                        "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2014_47.jpg",
                        "sub_questions": [
                            {
                                "question": "Name the part marked with letter Z.",
                                "type": "text",
                                "answer": "Flame or fire"
                            },
                            {
                                "question": "Why is the part marked R made of glass?",
                                "type": "text",
                                "answer": "To allow light to pass through"
                            },
                            {
                                "question": "Give the importance of the parts marked X and Y when the lamp is in use",
                                "type": "check_box",
                                "answer": [
                                    "To let out carbon dioxide, heat and smoke",
                                    "To let in fresh air"
                                ],
                                "Options": [
                                    "",
                                    ""
                                ]
                            },
                            {
                                "question": "An irregular object was lowered into an overflow can A containing water. he water  it  displaced  was  collected  in container B  as shown below",
                                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2013_47.jpg",
                                "sub_questions": [
                                    {
                                        "question": "What is the volume of the irregular object?",
                                        "type": "MultipleChoice",
                                        "answer": "20cm<sup>3</sup>",
                                        "Explanation": ""
                                    },
                                    {
                                        "question": "Calculate the density of the irregular object if its mass is 60 g",
                                        "type": "MultipleChoice",
                                        "answer": "3gcm<sup>-3</sup>",
                                        "Explanation": "Density <br> = mass/volume<br> = 60/20<br> = 3gcm<sup>-3</sup>",
                                        "Options": [
                                            "20",
                                            "30"
                                        ]
                                    }
                                ]
                            },
                            {
                                "question": "Give one reason why dehydration is dangerous to humans",
                                "type": "check_box",
                                "answer": [
                                    "can lead to death",
                                    "it weakens the body"
                                ],
                                "Options": [
                                    "increases body nutrients",
                                    "strenthens the body"
                                ],
                                "sub_questions": [
                                    {
                                        "question": "In which way is oral rehydration solution (ORS) a good treatment for diarrhea?",
                                        "type": "check_box",
                                        "answer": [
                                            "Replaces lost water, and mineral"
                                        ],
                                        "Options": [
                                            "souce of protein to the body",
                                            "source of carbohydrates"
                                        ]
                                    },
                                    {
                                        "question": "Apart from taking ORS, how else can one prevent dehydration?",
                                        "type": "check_box",
                                        "answer": [
                                            "Take a plenty of water",
                                            "Eating juicy fruits like paw paws"
                                        ],
                                        "Options": [
                                            "Take little water",
                                            "Eating starchy foods"
                                        ]
                                    },
                                    {
                                        "question": "How can a person preparing ORS prevent it from contamination?",
                                        "type": "check_box",
                                        "answer": [
                                            "By using boiled water",
                                            "By using clean containers"
                                        ],
                                        "Options": [
                                            "By using dirty containers",
                                            "By using salty water"
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "question": "Mention  two   primary  sex changes that  happen in boys",
                        "type": "check_box",
                        "answer": [
                            "boys experience wet dreams.",
                            "testes produce sperms "
                        ],
                        "Options": [
                            "boys experience wet dreams",
                            "testes produce sperms",
                            "They grow breasts",
                            ""
                        ],
                        "sub_questions": [
                            {
                                "question": "Give two secondary sex changes which happen in both boys and girls",
                                "type": "check_box",
                                "answer": [
                                    "sweat glands become more active",
                                    "Growth of pubic hair"
                                ],
                                "Options": [
                                    "sweat glands become more active",
                                    "Growth of pubic hair",
                                    "",
                                    ""
                                ]
                            }
                        ]
                    },
                    {
                        "question": "State one primary health care activity children can do to help their mothers when cooking",
                        "type": "check_box",
                        "answer": [
                            "Sweeping kitchen",
                            "washing utensils",
                            "going for immunization",
                            "sweeping the compound"
                        ],
                        "Options": [],
                        "sub_questions": [
                            {
                                "question": "Write down three health care activities that can be provided to the elderly in our homes.",
                                "type": "check_box",
                                "answer": [
                                    "Bathing him/her",
                                    "cutting finger nail"
                                ],
                                "Options": [
                                    "Bathing him/her",
                                    "cutting finger nail",
                                    "telling stories",
                                    "giving them work to do"
                                ]
                            }
                        ]
                    },
                    {
                        "question": "What is First Aid?",
                        "type": "check_box",
                        "answer": [
                            "First Aid is the first help give to a casualty (accident victim) before being taken to a heathy center or hospital for treatment."
                        ],
                        "Options": [
                            "First Aid is the first help given to a casualty (accident victim) before being taken to a heathy center or hospital for treatment.",
                            "First Aid is the first held give to a casualty (accident victim) before being taken home",
                            "First Aid is the first held give to a casualty (accident victim) before being taken to an AIDS patient"
                        ],
                        "sub_questions": [
                            {
                                "question": "List any two things found in a first Aid box.",
                                "type": "check_box",
                                "answer": [
                                    "Bandage",
                                    "Plaster"
                                ],
                                "Options": [
                                    "Bandage",
                                    "Plaster",
                                    "Phone",
                                    "food"
                                ]
                            },
                            {
                                "question": "What is First Aid?",
                                "type": "check_box",
                                "answer": [
                                    "First Aid is the first help give to a casualty (accident victim) before being taken to a heathy center or hospital for treatment."
                                ],
                                "Options": [
                                    "First Aid is the first help given to a casualty (accident victim) before being taken to a heathy center or hospital for treatment.",
                                    "First Aid is the first held give to a casualty (accident victim) before being taken home",
                                    "First Aid is the first held give to a casualty (accident victim) before being taken to an AIDS patient"
                                ],
                                "sub_questions": [
                                    {
                                        "question": "State why every school should have a First Aid box",
                                        "type": "check_box",
                                        "answer": [
                                            "to save life in case of overbleeding"
                                        ],
                                        "Options": [
                                            "to save life in case of overbleeding",
                                            "to help students do their exams",
                                            "its important for the welfare of the school"
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "question": ". A poultry farmer has his chicken passing out blood –stained dropping and have drooping wings",
                        "sub_questions": [
                            {
                                "question": "What disease is likely to have attached the farmer`s birds?",
                                "type": "text",
                                "answer": [
                                    "Coccidiosis"
                                ]
                            },
                            {
                                "question": "Suggest two ways in which the neighbors to the farmer can prevent their birds from contracting the above disease",
                                "type": "text",
                                "answer": [
                                    "vaccination",
                                    "Keeping infected birds from healthy ones"
                                ],
                                "options": [
                                    "vaccination",
                                    "Keeping infected birds from healthy ones",
                                    "immunization",
                                    "Keeping infected birds and healthy ones in one room"
                                ]
                            },
                            {
                                "question": "Why should the farmer separate the birds with the above given conditions from the rest?",
                                "type": "check_box",
                                "answer": [
                                    "To prevent healthy birds from acquiring disease from the infected bird."
                                ],
                                "options": [
                                    "To prevent healthy birds from acquiring disease from the infected bird.",
                                    "To prevent the birds from mating",
                                    "To prevent the birds from suffocating",
                                    "to fight vices"
                                ]
                            }
                        ]
                    },
                    {
                        "question": "Give any one reason why a magnet cannot attract pieces of wood.",
                        "type": "check_box",
                        "answer": [
                            "Wood has no magnetic properties"
                        ],
                        "Options": [
                            "Wood has no magnetic properties",
                            "Wood has magnetic properties",
                            "Wood has some magnetic properties"
                        ],
                        "sub_questions": [
                            {
                                "question": "Give any three practices that can lead to the destruction of a magnet ",
                                "type": "check_box",
                                "answer": [
                                    "Heating",
                                    " hammering"
                                ],
                                "Options": [
                                    "Heating",
                                    " hammering",
                                    "Storing east -west direction",
                                    "storing north - west direction"
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "category": 48,
                "questions": [
                    {
                        "sub_questions": [
                            {
                                "question": "Give one example of an exotic breed of goats kept in Uganda",
                                "type": "text",
                                "answer": [
                                    "",
                                    ""
                                ]
                            },
                            {
                                "question": "Name one example of fibre got from goats",
                                "type": "text",
                                "answer": [
                                    "",
                                    ""
                                ]
                            },
                            {
                                "question": "state any two reasons why some farmers would prefer rearing goats to cattle",
                                "type": "text",
                                "answer": [
                                    "",
                                    ""
                                ]
                            },
                            {
                                "question": "",
                                "type": "check_box",
                                "answer": [
                                    "",
                                    ""
                                ],
                                "Options": [],
                                "sub_questions": [
                                    {
                                        "question": "",
                                        "type": "check_box",
                                        "answer": [
                                            "",
                                            ""
                                        ],
                                        "Options": []
                                    }
                                ]
                            },
                            {
                                "sub_questions": [
                                    {
                                        "question": "State any two ways in which animals depend on other animals",
                                        "type": "text",
                                        "answer": [
                                            "",
                                            ""
                                        ]
                                    },
                                    {
                                        "question": "Give any two ways in which plants depend on other plants",
                                        "type": "text",
                                        "answer": [
                                            "",
                                            ""
                                        ]
                                    }
                                ]
                            },
                            {
                                "question": "The diagram below shows a man using a simple machine to load a drum on to a truck. Study and use it to answer the questions that follow.",
                                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2017_48.jpg",
                                "sub_questions": [
                                    {
                                        "question": "What type of simple machine is the man using?",
                                        "type": "text",
                                        "answer": "Inclined plane"
                                    },
                                    {
                                        "question": "Give any examples of the type of simple machine you have named in (a) above.",
                                        "answer": [
                                            "ladder",
                                            "Stair",
                                            "slopping road"
                                        ]
                                    },
                                    {
                                        "question": "How can the man improve on this simple machine above in order to make his work easier?",
                                        "type": "text",
                                        "answer": "Using long wood to reduce the slope"
                                    }
                                ]
                            },
                            {
                                "question": "In which one way is each of the following elements of Primary Health Care (PHC) important to the community",
                                "sub_questions": [
                                    {
                                        "question": "Water and sanitation",
                                        "type": "MultipleChoice",
                                        "answer": "",
                                        "options": [
                                            "",
                                            "",
                                            ""
                                        ]
                                    },
                                    {
                                        "question": "maternal and child",
                                        "type": "MultipleChoice",
                                        "answer": "",
                                        "options": [
                                            "",
                                            "",
                                            ""
                                        ]
                                    },
                                    {
                                        "question": "Give any two health care services which are provide through maternal and child health",
                                        "type": "check_box",
                                        "answer": [
                                            "",
                                            ""
                                        ],
                                        "options": [
                                            "",
                                            ""
                                        ]
                                    }
                                ]
                            },
                            {
                                "question": "The diagram below shows firing bricks in a kiln.study and use it to answer the questions that follow..",
                                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2015_48.jpg",
                                "sub_questions": [
                                    {
                                        "question": "Name the source of heat energy used in firing the bricks.",
                                        "type": "text",
                                        "answer": ""
                                    },
                                    {
                                        "question": "Give one reason why the bricks are covered with mud before firing starts.",
                                        "Type": "check_box",
                                        "answer": [
                                            "ladder",
                                            "Stair",
                                            "slopping road"
                                        ],
                                        "Options": [
                                            "",
                                            "",
                                            ""
                                        ]
                                    },
                                    {
                                        "question": "What is the importance of the grass put on top of the kiln during firing?",
                                        "type": "text",
                                        "answer": ""
                                    },
                                    {
                                        "question": "State any one disadvantage of using the above method of firing bricks to the environment.",
                                        "Type": "check_box",
                                        "answer": [
                                            "ladder",
                                            "Stair",
                                            "slopping road"
                                        ],
                                        "Options": [
                                            "",
                                            "",
                                            ""
                                        ]
                                    }
                                ]
                            },
                            {
                                "question": "Name the group of crops that are harvested year after",
                                "type": "text",
                                "answer": "Perennial crops",
                                "sub_questions": [
                                    {
                                        "question": "give any two example of crops that belong to the group you have named in (a) above",
                                        "type": "check_box",
                                        "answer": [
                                            "coffee",
                                            "tea",
                                            "mangoes",
                                            "orange",
                                            "Banana",
                                            "Sisal"
                                        ],
                                        "options": [
                                            "",
                                            "",
                                            ""
                                        ]
                                    },
                                    {
                                        "question": "State one way in which the above crops are harvested.",
                                        "type": "check_box",
                                        "answer": [
                                            "By hand picking",
                                            "plucking",
                                            "Uprooting",
                                            "Cutting"
                                        ],
                                        "options": [
                                            "",
                                            "",
                                            ""
                                        ]
                                    }
                                ]
                            },
                            {
                                "question": "The table below shows lists of animals. Use it to answer the questionThat follow",
                                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2013_48.png",
                                "sub_questions": [
                                    {
                                        "question": "Which characteristic is common to all the animals in the table?",
                                        "answer": [
                                            "they have a backbone",
                                            "They have endo skeleton"
                                        ],
                                        "Options": [
                                            "they have four legs",
                                            "They live in water"
                                        ]
                                    },
                                    {
                                        "question": "How is the reproduction in the animals in list P similar to those in list Q?",
                                        "Type": "check_box",
                                        "answer": "They lay eggs",
                                        "Options": [
                                            "Internal fertilisation",
                                            "Give birth to live young ones"
                                        ]
                                    },
                                    {
                                        "question": "Which list has warm blooded animals?",
                                        "Type": "check_box",
                                        "answer": "List R",
                                        "Options": [
                                            "List P",
                                            "List Q"
                                        ]
                                    },
                                    {
                                        "question": "Why would you place a tortoise in list Q?",
                                        "Type": "check_box",
                                        "answer": "It is a reptile",
                                        "Options": [
                                            "It is an Amphibian",
                                            "It is a mammal"
                                        ]
                                    }
                                ]
                            },
                            {
                                "question": "Complete correctly by filling in the food, food nurients and related dificiecncy diseases",
                                "sub_questions": [
                                    {
                                        "question": "Fruit",
                                        "type": "MultipleChoice",
                                        "answer": "vitamin c",
                                        "Options": []
                                    }
                                ]
                            },
                            {
                                "question": "Apart from millets, wheat and sorghum, give any one other cereal food crop.",
                                "type": "check_box",
                                "answer": [
                                    "Maize"
                                ],
                                "Options": [
                                    "Maize",
                                    "mangoes",
                                    "sugarcane"
                                ],
                                "sub_questions": [
                                    {
                                        "question": "Mention any one common pest that that destroys cereals crops",
                                        "type": "check_box",
                                        "answer": [
                                            "weaverbirds"
                                        ],
                                        "Options": [
                                            "weaverbirds",
                                            "snakes",
                                            "frogs",
                                            "mosquitoes"
                                        ]
                                    },
                                    {
                                        "question": "Give any two reasons why cereal crops are dried before storing ",
                                        "type": "check_box",
                                        "answer": [
                                            "To reduce pest damage",
                                            "To prevent them from rotting"
                                        ],
                                        "Options": [
                                            "To reduce pest damage",
                                            "To prevent them from rotting",
                                            "To reduce weight",
                                            "To create more room in storage"
                                        ]
                                    }
                                ]
                            },
                            {
                                "question": "Name two scavengers in the environment",
                                "type": "check_box",
                                "answer": [
                                    "Vulture",
                                    "Marabou stork"
                                ],
                                "Options": [
                                    "Vulture",
                                    "Marabou stork"
                                ],
                                "sub_questions": [
                                    {
                                        "question": "In which way are scavengers useful in the environment?",
                                        "type": "text",
                                        "answer": [
                                            "Clean the environment"
                                        ]
                                    },
                                    {
                                        "question": "Give the difference between a predator and a scavenger.",
                                        "type": "check_box",
                                        "answer": [
                                            "Predator kills its prey while the scavengers feed on dead organism",
                                            "Predator feed on dead organism while the scavengers kills its prey",
                                            "predators feed on natural vegetation",
                                            "Scavengers feed on only plants while predators feed on dead animals"
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "question": "Equal amount of water were poured into  soil A and B  shown  in the  diagram  below:",
                        "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2010_48.jpg",
                        "sub_questions": [
                            {
                                "question": "From which soil did more water drop?",
                                "type": "text",
                                "answer": [
                                    "B"
                                ]
                            },
                            {
                                "question": "State why more water dropped from soil you have named in (a) above",
                                "type": "text",
                                "answer": [
                                    "Soil B has big particles than soil A"
                                ]
                            },
                            {
                                "question": "Name the type of soils in B",
                                "type": "text",
                                "answer": [
                                    "Sandy soil"
                                ]
                            },
                            {
                                "question": "Give one use of the type of soil in A",
                                "type": "check_box",
                                "answer": [
                                    "Brick making"
                                ],
                                "options": [
                                    "Brick making",
                                    "Farming",
                                    "road works"
                                ]
                            }
                        ]
                    },
                    {
                        "question": "The diagram below is of a food chain. Study it and answer the questions that follow.",
                        "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2010_48.jpg",
                        "sub_questions": [
                            {
                                "question": "Name the producer",
                                "type": "text",
                                "answer": [
                                    "Plant"
                                ]
                            },
                            {
                                "question": "Which living organism in the diagram is herbivorous?",
                                "type": "text",
                                "answer": [
                                    "Goat"
                                ]
                            },
                            {
                                "question": "Why is the organism in (b) above herbivorous?",
                                "type": "check_box",
                                "answer": [
                                    "Feeds on plants"
                                ],
                                "options": [
                                    "Feeds on plants",
                                    "Feeds on animals",
                                    "Feeds on insects",
                                    "Feeds on blood"
                                ]
                            },
                            {
                                "question": "Name one way in which plants benefit from the goats",
                                "type": "check_box",
                                "answer": [
                                    "Manure"
                                ],
                                "options": [
                                    "Manure",
                                    "plants feed on goats",
                                    "oxygen for photosynthesis",
                                    "Carbon dioxide for transpiration"
                                ]
                            }
                        ]
                    },
                    {
                        "question": "Bbosa is a poultry   farmer. He has 100 layers on his farm. Give any four reasons why he should keep farm records?",
                        "type": "check_box",
                        "answer": [
                            "to know how much food, they eat",
                            ") to know how much eggs they lay",
                            "to know amount of money he spends on food",
                            "to know how much money "
                        ],
                        "options": [
                            "to know amount of money he spends on food",
                            "to know how much eggs they lay",
                            "to know how much money ",
                            "to know how much food, they eat",
                            "to know how much money ",
                            "for fun",
                            "to which birds to eat and which not to eat"
                        ]
                    }
                ]
            }
        ]
    },
    {
        "category": 49,
        "questions": [
            {
                "question": "state any two ways in which weeds can lower the yield of crops",
                "type": "text",
                "answer": [
                    "",
                    ""
                ]
            },
            {
                "question": "state any one way in which the growth of weeds can be controlled in a garden",
                "type": "text",
                "answer": [
                    "",
                    ""
                ]
            },
            {
                "question": "Apart from controlling weeds, give any one other eay farmers can care for their crops",
                "type": "text",
                "answer": [
                    "",
                    ""
                ]
            },
            {
                "question": "The following is a list of animals that belong to different classes of vertebrates. Use it to answer the questions that follow.Monkey, Parrot, Crocodile Tilapia",
                "sub_questions": [
                    {
                        "question": "In which class of vertebrates does a crocodile belong?",
                        "type": "text",
                        "answer": ""
                    },
                    {
                        "question": "Name the class of vertebrate which is not represented in the above list",
                        "type": "text",
                        "answer": ""
                    },
                    {
                        "question": "Identify any one animal from the above list which is cold blooded.",
                        "type": "text",
                        "answer": ""
                    }
                ]
            },
            {
                "question": "Which breed of cattle is obtained by making a pure local breed and an exotic breed?",
                "type": "text",
                "answer": "Hybrid",
                "sub_questions": [
                    {
                        "question": "Give any two reasons why some farmers prefer keeping local breeds of cattle to exotic breeds.",
                        "type": "text",
                        "answer": [
                            "Resistant to diseases",
                            "can survive and produce milk on poor feed and fodder resources"
                        ]
                    },
                    {
                        "question": "State any one characteristic of dairy cattle",
                        "type": "text",
                        "answer": "Big udder"
                    },
                    {
                        "question": "Apart from controlling weeds, give any one other eay farmers can care for their crops",
                        "type": "text",
                        "answer": [
                            "",
                            ""
                        ]
                    }
                ]
            },
            {
                "question": "Name any two systems of keeping poultry that enables a farmer to keep large number of birds",
                "type": "check_box",
                "answer": [
                    "",
                    ""
                ],
                "options": [
                    "",
                    ""
                ],
                "sub_questions": [
                    {
                        "question": "State any two viral diseases that can spread easily in the aboe systems of poultry keeping",
                        "type": "check_box",
                        "answer": [
                            "",
                            ""
                        ],
                        "options": [
                            "",
                            ""
                        ]
                    }
                ]
            },
            {
                "question": "The table below shows parts of a human eye in A and that of a lens camera in B.For each of the parts of the human eye, write the part of the lens camera from B which performs a similar function",
                "sub_questions": [
                    {
                        "question_Text": "Iris",
                        "Type": "MultipleChoice",
                        "answer": "",
                        "Options": [
                            "",
                            ""
                        ]
                    },
                    {
                        "question_Text": "Pupil",
                        "Type": "MultipleChoice",
                        "answer": "",
                        "Options": [
                            "",
                            ""
                        ]
                    },
                    {
                        "question_Text": "Eye lid",
                        "Type": "MultipleChoice",
                        "answer": "",
                        "Options": [
                            "",
                            ""
                        ]
                    },
                    {
                        "question_Text": "Retina",
                        "Type": "MultipleChoice",
                        "answer": "",
                        "Options": [
                            "Moist Skin",
                            "Lungs",
                            "Gills",
                            "spiracles"
                        ]
                    }
                ]
            },
            {
                "question": "The table below shows a group of animals in A with their respiratory organ in B.",
                "sub_questions": [
                    {
                        "question_Text": "Tilapia",
                        "Type": "MultipleChoice",
                        "answer": "Gills",
                        "Options": [
                            "Moist Skin",
                            "Lungs",
                            "Gills",
                            "spiracles"
                        ]
                    },
                    {
                        "question_Text": "Mosquito",
                        "Type": "MultipleChoice",
                        "answer": "spiracle",
                        "Options": [
                            "Moist Skin",
                            "Lungs",
                            "Gills",
                            "spiracles"
                        ]
                    },
                    {
                        "question_Text": "Frog",
                        "Type": "MultipleChoice",
                        "answer": "mosit skin",
                        "Options": [
                            "Moist Skin",
                            "Lungs",
                            "Gills",
                            "spiracles"
                        ]
                    },
                    {
                        "question_Text": "Dog",
                        "Type": "MultipleChoice",
                        "answer": "lungs",
                        "Options": [
                            "Moist Skin",
                            "Lungs",
                            "Gills",
                            "spiracles"
                        ]
                    }
                ]
            },
            {
                "question": "Name any one organism that makes fresh meat go bad.",
                "type": "check_box",
                "answer": [
                    "Bacteria",
                    "Fungi"
                ],
                "options": [
                    "salmonella typhi",
                    "amoeba"
                ],
                "sub_questions": [
                    {
                        "question": "State any three ways of preventing fresh meat from going bad",
                        "type": "check_box",
                        "answer": [
                            "boiling",
                            "frying",
                            "refrigeration"
                        ],
                        "options": [
                            "pasting it",
                            "leaving it open"
                        ]
                    }
                ]
            },
            {
                "question": "Which type of blood vessels return blood to the heart?",
                "type": "text",
                "answer": [
                    "Veins"
                ],
                "Options": [
                    "Arteries",
                    "capillaries"
                ],
                "sub_questions": [
                    {
                        "question": "What is the function   of valves in the blood vessels during blood circulation?",
                        "type": "check_box",
                        "answer": [
                            "Allow blood to flow in one direction"
                        ],
                        "Options": [
                            "Allow blood to flow in one direction",
                            "Allow blood to flow in both directions"
                        ]
                    },
                    {
                        "question": "What type of blood is carried by most blood vessels with valves?",
                        "type": "check_box",
                        "answer": [
                            "Deoxygenated blood"
                        ],
                        "Options": [
                            "Deoxygenated blood",
                            "oxygenated blood"
                        ]
                    },
                    {
                        "question": "Give any one waste materials carried by blood",
                        "type": "check_box",
                        "answer": [
                            "Urea"
                        ],
                        "Options": [
                            "Uric acid",
                            "Carbon dioxide",
                            "water",
                            "Urea"
                        ]
                    }
                ]
            },
            {
                "question": "Apart from a worker bee, mention two other types of bees found in a bee hive.",
                "type": "check_box",
                "answer": [
                    "queen bee",
                    "Drone bee"
                ],
                "Options": [
                    "queen bee",
                    "Drone bee",
                    "wasps",
                    "ants"
                ],
                "sub_questions": [
                    {
                        "question": "Write down two materials collected by a worker bee from the environment",
                        "type": "check_box",
                        "answer": [
                            "Nectar ",
                            "propolis"
                        ],
                        "Options": [
                            "Nectar ",
                            "propolis",
                            "honey",
                            "sugar"
                        ]
                    }
                ]
            },
            {
                "question": "Which organ removes urine from the body?",
                "type": "text",
                "answer": [
                    "Kidney"
                ],
                "sub_questions": [
                    {
                        "question": "To which body system does the organ that removes urine from the body?",
                        "type": "text",
                        "answer": [
                            "Excretory system"
                        ]
                    },
                    {
                        "question": "Where does urine collect before it is removed from the body?",
                        "type": "text",
                        "answer": [
                            "Urinary bladder"
                        ]
                    },
                    {
                        "question": "Name one substance found in urine.",
                        "type": "text",
                        "answer": [
                            "Urea"
                        ],
                        "options": [
                            "Urea",
                            "sugar",
                            "blood",
                            "saliva"
                        ]
                    }
                ]
            },
            {
                "question": "Write two activities that help to promote good sanitation in a home",
                "type": "check_box",
                "answer": [
                    "proper disposal of rubbish",
                    "maintaining cleanness of a house"
                ],
                "Options": [
                    "proper disposal of rubbish",
                    "maintaining cleanness of a house",
                    "cooking food",
                    "poor disposal of rubbish"
                ],
                "sub_questions": [
                    {
                        "question": "Name two diseases that may attack a family due to poor sanitation.",
                        "type": "check_box",
                        "answer": [
                            "Cholera",
                            "Dysentery"
                        ],
                        "Options": [
                            "Cholera",
                            "Dysentery",
                            "marasmus",
                            "polio"
                        ]
                    }
                ]
            },
            {
                "question": "The diagram below is of a pulley system. Use it use to answer questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2010_49.jpg",
                "sub_questions": [
                    {
                        "question": "Name the types of pulley system shown above",
                        "type": "text",
                        "answer": [
                            "Block and tackle pulley system"
                        ]
                    },
                    {
                        "question": "what is effort",
                        "type": "check_box",
                        "answer": [
                            "The force applied to a machine to do mechanical work"
                        ],
                        "options": [
                            "The force applied to a machine to do mechanical work",
                            "The force of gravity applied to a machine to do mechanical work",
                            "The force of friction applied to a machine to do mechanical work",
                            "The force of viscocity applied to a machine to do mechanical work"
                        ]
                    },
                    {
                        "question": "If   the mechanical advantage of the machine is 2 and the load being carried by the machine is 50kg. Find the effort needed to raise that load.",
                        "type": "check_box",
                        "answer": [
                            "250N"
                        ],
                        "Explanation": [
                            "MA = <br> L/E <br> = 50/E <br> = 2 <br> E <br> = 50/2 <br>= 25kg <br> E = 25*10 <br> = 250N"
                        ],
                        "options": [
                            "250N",
                            "300N",
                            "200N",
                            "350N"
                        ]
                    }
                ]
            },
            {
                "question": "Why is it a bad practice to apply soil or cow dung on any burnt area of our skin?",
                "type": "check_box",
                "answer": [
                    "They may contain germs"
                ],
                "Options": [
                    "They may contain germs",
                    "They reduce the heat fron the burn",
                    "do not contain germs"
                ],
                "sub_questions": [
                    {
                        "question": "Suggest any two reasons why the injured part with a burn or scald is dipped into cold water",
                        "type": "check_box",
                        "answer": [
                            "To cool the burnt place",
                            "To minimize the effect of the burn"
                        ],
                        "Options": [
                            "To cool the burnt place",
                            "To minimize the effect of the burn",
                            "To maximize the effect of the burn",
                            "To warm the burnt place"
                        ]
                    },
                    {
                        "question": "Give any one way of avoiding burns while lifting hot objects.",
                        "type": "check_box",
                        "answer": [
                            "using pieces of paper"
                        ],
                        "Options": [
                            "using pieces of paper",
                            "using pieces of metal",
                            "using bare hands"
                        ]
                    }
                ]
            }
        ]
    },
    {
        "category": 50,
        "questions": [
            {
                "question": "The diagram below shows a simple machin. study it and answer the questions that follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2019_50.jpg",
                "sub_questions": [
                    {
                        "question": "To which type of simple machine does the machine above belong?",
                        "type": "text",
                        "answer": ""
                    },
                    {
                        "question": "state one use of the simple machine above",
                        "answer": [
                            "",
                            ""
                        ]
                    },
                    {
                        "question": "Name one force that makes work difficult when using the above machine",
                        "type": "text",
                        "answer": ""
                    },
                    {
                        "question": "Give one other example of a simple machine that belongs to the above type of simple machine",
                        "type": "text",
                        "answer": ""
                    }
                ]
            },
            {
                "question": "Give any one reason why drugs should be kept;",
                "sub_questions": [
                    {
                        "question": "away from mositure and sunlight",
                        "type": "text",
                        "answer": ""
                    },
                    {
                        "question": "out of reach of children",
                        "type": "text",
                        "answer": ""
                    },
                    {
                        "question": "out of reach of children",
                        "type": "text",
                        "answer": ""
                    },
                    {
                        "question": "State any two ways in which people in a family can misuse drugs",
                        "type": "text",
                        "answer": [
                            "",
                            ""
                        ]
                    }
                ]
            },
            {
                "question": "Name any two bones that form the joint at the knee of the human body.",
                "type": "text",
                "answer": [
                    "Femur",
                    "tibia"
                ],
                "sub_questions": [
                    {
                        "question": "What name is given to the joint at the knee?t",
                        "type": "text",
                        "answer": "Hinge joint"
                    },
                    {
                        "question": " In which one way is friction reduced at a joint?",
                        "type": "text",
                        "answer": ""
                    },
                    {
                        "question": "out of reach of children",
                        "type": "text",
                        "answer": [
                            "Smooth cartilage at bone ends",
                            "Presence of synovial fluids"
                        ]
                    },
                    {
                        "question": "State any two ways in which people in a family can misuse drugs",
                        "type": "text",
                        "answer": [
                            "",
                            ""
                        ]
                    }
                ]
            },
            {
                "question": "Give any one charcteristic which is common to all invertebrates",
                "type": "check_box",
                "answer": [
                    "",
                    ""
                ],
                "options": [
                    "",
                    ""
                ],
                "sub_questions": [
                    {
                        "question": " To which group of Invertebrates does each of the following animals belong?",
                        "sub": [
                            {
                                "question": "Snail",
                                "type": "MultipleChoice",
                                "Anwers": "",
                                "Options": [
                                    "",
                                    "",
                                    ""
                                ]
                            },
                            {
                                "question": "Grasshopper",
                                "type": "MultipleChoice",
                                "Anwers": "",
                                "Options": [
                                    "",
                                    "",
                                    ""
                                ]
                            }
                        ]
                    },
                    {
                        "question": "state one way in which snails may be dangerous to humans",
                        "type": "text",
                        "answer": ""
                    }
                ]
            },
            {
                "question": "Which communicable disease shows signs and symptoms similar to that of tuberculosis?",
                "Type": "text",
                "answer": "",
                "sub_questions": [
                    {
                        "question": "Name one cattle product through which tuberculosis can spread to human beings.",
                        "Type": "text",
                        "answer": ""
                    },
                    {
                        "question": "When is the vaccine against tuberculosis administered in babies?",
                        "Type": "text",
                        "answer": ""
                    },
                    {
                        "question": "Apart from immunization, give one other way in which the spread of tuberculosis can be controlled in humans.",
                        "Type": "text",
                        "answer": ""
                    }
                ]
            },
            {
                "question": " In the human reproductive system, state where each of the following processes take place:",
                "sub_questions": [
                    {
                        "question_Text": "Implantation",
                        "Type": "MultipleChoice",
                        "answer": "Uterus",
                        "Options": [
                            "uterus",
                            "testis",
                            "ovaries"
                        ]
                    },
                    {
                        "question_Text": "Production of Sperms",
                        "Type": "MultipleChoice",
                        "answer": "testes",
                        "Options": [
                            "uterus",
                            "ovaries"
                        ]
                    },
                    {
                        "question_Text": "Production of female hormones",
                        "Type": "MultipleChoice",
                        "answer": "ovaries",
                        "Options": [
                            "uterus",
                            "testes"
                        ]
                    },
                    {
                        "question_Text": "Dog",
                        "Type": "MultipleChoice",
                        "answer": "lungs",
                        "Options": [
                            "Moist Skin",
                            "Lungs",
                            "Gills",
                            "spiracles"
                        ]
                    },
                    {
                        "question_Text": "Give any one use of the placenta to the foetus during pregnancy",
                        "Type": "MultipleChoice",
                        "answer": "supplies food, oxygen, hormones"
                    }
                ]
            },
            {
                "question": "Name the human body organ where each of the following takes place:",
                "Sub_Question": [
                    {
                        "question": "Filtration of Blood",
                        "Type": "MultipleChoice",
                        "answer": "Kidney",
                        "Options": []
                    },
                    {
                        "question": "Blood gets oxygen while carbon dioxide is removed.",
                        "Type": "MultipleChoice",
                        "answer": "Lungs",
                        "Options": []
                    },
                    {
                        "question": "Give the use of the following components of blood in the body",
                        "sub": [
                            {
                                "question": "White blood cells",
                                "Type": "MultipleChoice",
                                "answer": "Fight germs",
                                "Options": []
                            },
                            {
                                "question": "Blood platelets",
                                "Type": "MultipleChoice",
                                "answer": "Cause blood clotting",
                                "Options": []
                            }
                        ]
                    }
                ]
            },
            {
                "question": "The diagram below shows one of the methods of preparing alcohol. Study and use it to answer questions 50.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2012_50.jpg",
                "sub_questions": [
                    {
                        "question": "Name the methods used in the diagram",
                        "type": "text",
                        "answer": "Distillation"
                    },
                    {
                        "question": "What do the arrows labeled J represent?",
                        "type": "text",
                        "answer": "Heat"
                    },
                    {
                        "question": "Why is the tube  passing through cold  water?",
                        "type": "check_box",
                        "answer": [
                            "To condense the alcohol vapour"
                        ],
                        "options": [
                            "To condense the alcohol vapour",
                            "To freeze the alcohol"
                        ]
                    },
                    {
                        "question": "What process forms M?",
                        "type": "text",
                        "answer": [
                            "Evaporation "
                        ]
                    },
                    {
                        "question": "What form of energy does a ball have before it is kicked?",
                        "type": "check_box",
                        "answer": [
                            "Potential energy"
                        ],
                        "Options": [
                            "Potential energy",
                            "heat energy",
                            "sound energy",
                            "kinetic energy"
                        ],
                        "sub_questions": [
                            {
                                "question": "What energy change takes place immediately a ball is kicked?",
                                "type": "check_box",
                                "answer": [
                                    "Potential energy to kinetic energy"
                                ],
                                "Options": [
                                    "Potential energy to kinetic energy",
                                    "kinetic energy to potential  energy"
                                ]
                            },
                            {
                                "question": "Give two forms of energy produced by the ball as the goal keeper catches it",
                                "type": "check_box",
                                "answer": [
                                    " sound energy",
                                    "heat energy"
                                ],
                                "Options": [
                                    "sound energy",
                                    "heat energy"
                                ]
                            }
                        ]
                    },
                    {
                        "question": "Name two water resources in Uganda that provide us with proteins.",
                        "type": "check_box",
                        "answer": [
                            " fish",
                            "hippopotamus"
                        ],
                        "Options": [
                            "hippopotamus",
                            "fish",
                            "water",
                            "water weeds"
                        ],
                        "sub_questions": [
                            {
                                "question": "Give two human practices that can lead to the destruction of such resources.",
                                "type": "check_box",
                                "answer": [
                                    "over fishing",
                                    "fishing young fish"
                                ],
                                "Options": [
                                    "over fishing",
                                    "fishing young fish",
                                    "bush burning",
                                    "defforestation"
                                ]
                            }
                        ]
                    },
                    {
                        "question": "The diagram below shows a human eye.Study it and use it to answer question that follow ",
                        "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2010_50.jpg",
                        "sub_questions": [
                            {
                                "question": "Name the part marked P :",
                                "type": "text",
                                "answer": "lens"
                            },
                            {
                                "question": "Name the part marked Q:",
                                "type": "text",
                                "answer": "cornea"
                            },
                            {
                                "question": "What is the function of the part marked R?",
                                "type": "check_box",
                                "answer": [
                                    "Controls amount of light entering the eye"
                                ],
                                "options": [
                                    "Controls amount of light entering the eye",
                                    "protects the eyes from foreign bodies",
                                    "increases amount of light entering the eye"
                                ]
                            },
                            {
                                "question": "Which letter on the diagram shows where images are formed?",
                                "type": "text",
                                "answer": "S"
                            }
                        ]
                    },
                    {
                        "question": "Give two reasons for placing utensils on a rack",
                        "type": "check_box",
                        "answer": [
                            "so that all utensil is dry",
                            "so that utensil remain clean"
                        ],
                        "Options": [
                            "so that all utensil is dry",
                            "so that utensil remain clean",
                            "for washing",
                            "to sort out dirty ones from clean ones"
                        ],
                        "sub_questions": [
                            {
                                "question": "Why should a rack be contracted with stands at least 1 meter above the ground?",
                                "type": "check_box",
                                "answer": [
                                    "To prevent utensil from getting contaminated from splashes"
                                ],
                                "Options": [
                                    "To prevent utensil from getting contaminated from splashes",
                                    "To allow them dry faster",
                                    "To easily pick them up",
                                    "to identify them easily"
                                ]
                            }
                        ]
                    },
                    {
                        "question": "The diagrams numbered 1 and 2 below show an experiment done by a P7 class.Diagram 1 shows burning candle in an open jar. In diagram 2, the jar was covered as shown.Use the diagrams to answer the question that follow",
                        "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2007_50.jpg",
                        "sub_questions": [
                            {
                                "question": " What would happen to the flame in diagram 2 if the cover was removed after a short time?",
                                "type": "text",
                                "answer": [
                                    "The candle will burn with a bigger flame"
                                ]
                            },
                            {
                                "question": " Give a reason for your answer in question (a) above.",
                                "type": "check_box",
                                "answer": [
                                    "The candle burns with a bigger flame due to presence of much oxygen",
                                    "The candle burns with a bigger flame due to presence of little oxygen",
                                    "The candle burns with a bigger flame due to presence of carbondioxide"
                                ],
                                "options": [
                                    "The candle burns with a bigger flame due to presence of much oxygen",
                                    "The candle burns with a bigger flame due to presence of little oxygen",
                                    "The candle burns with a bigger flame due to presence of carbondioxide",
                                    "The candle burns with a bigger flame due to presence of much carbonmonoxide"
                                ]
                            },
                            {
                                "question": " If  the cover had  been left  on the  jar in diagram 2  for a long time  what  would  have  happened  to the  flame",
                                "type": "text",
                                "answer": [
                                    "The flame will go off"
                                ]
                            },
                            {
                                "question": "Give a reason for your answer in question (c) above.",
                                "type": "text",
                                "answer": [
                                    "oxygen that supports burning is used up"
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "category": 51,
        "questions": [
            {
                "sub_questions": [
                    {
                        "question": "which digestive juice is produced in the mouth",
                        "type": "text",
                        "answer": ""
                    },
                    {
                        "question": "write down two any two things that happen to food in the mouth",
                        "type": "text",
                        "answer": [
                            "",
                            ""
                        ]
                    },
                    {
                        "question": "which digestive disorder is prevented by including roughage in our diet?",
                        "type": "text",
                        "answer": ""
                    }
                ]
            },
            {
                "sub_questions": [
                    {
                        "question": "What germ causes mastitis in cattle?",
                        "type": "text",
                        "answer": ""
                    },
                    {
                        "question": "Give any two signs of mastitis",
                        "type": "text",
                        "answer": [
                            "",
                            ""
                        ]
                    },
                    {
                        "question": "State any one way of controlling mastitis in cattle",
                        "type": "text",
                        "answer": [
                            "",
                            ""
                        ]
                    }
                ]
            },
            {
                "question": "The table below shows some common infection, organisms that cause it and the body part affected. Study and complete it correctly.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2017_51.png",
                "sub_questions": []
            },
            {
                "question": "The The diagram below shows a section through a humans tooth. Study and use it to answer the questions that follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2016_51.png",
                "sub_questions": [
                    {
                        "question": " Name the parts marked M and k",
                        "sub": [
                            {
                                "question": "M",
                                "type": "MultipleChoice",
                                "answer": "",
                                "options": [
                                    "",
                                    "",
                                    ""
                                ]
                            },
                            {
                                "question": "K",
                                "type": "MultipleChoice",
                                "answer": "",
                                "options": [
                                    "",
                                    "",
                                    ""
                                ]
                            }
                        ]
                    },
                    {
                        "question": "Which mineral salt helps to frm part M?",
                        "type": "text",
                        "answer": ""
                    },
                    {
                        "question": "Give the function of part labelled N",
                        "type": "text",
                        "answer": ""
                    }
                ]
            },
            {
                "question": "Give any two ways in which alcohol is used in hospitals",
                "type": "check_box",
                "answer": [
                    "",
                    ""
                ],
                "Options": [
                    "",
                    "",
                    ""
                ],
                "Sub_Questions": [
                    {
                        "question_Text": "State the two effects of alcoholism to an individual.",
                        "type": "check_box",
                        "answer": [
                            "",
                            ""
                        ],
                        "Options": [
                            "",
                            "",
                            ""
                        ]
                    }
                ]
            },
            {
                "question": "The diagram below shows a system of grazing cattle.Study and use it to answer the questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2014_51.png",
                "sub_questions": [
                    {
                        "question": " Name the system of grazing cattle shown in the diagram above.",
                        "sub_questions": [
                            {
                                "question": "Besides restricting the animals, give two other advantages of this system of grazing cattle..",
                                "type": "check_box",
                                "answer": [
                                    "Gives pasture time to grow",
                                    "Control soil erosion",
                                    "Control breeding of pest",
                                    "Give animal enough exercise",
                                    "Prevent overgrazing"
                                ]
                            },
                            {
                                "question": "State any one disadvantage of the system of grazing cattle shown in the diagram",
                                "type": "text",
                                "answer": "It is expensive"
                            }
                        ]
                    }
                ]
            },
            {
                "question": "Study the diagram of the f human ear below and use it to answer the questions that follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2013_51.png",
                "sub_questions": [
                    {
                        "question": " Name the parts marked M and N ",
                        "sub_questions": [
                            {
                                "question": "M",
                                "type": "MultipleChoice",
                                "answer": "pinna",
                                "Options": []
                            },
                            {
                                "question": "N",
                                "type": "MultipleChoice",
                                "answer": "eardrum",
                                "Options": []
                            },
                            {
                                "question": "Give the function of the part marked P",
                                "type": "MultipleChoice",
                                "answer": "Send impulses to the brain",
                                "Options": []
                            },
                            {
                                "question": "Which part of the ear is responsible for balancing the body",
                                "type": "check_box",
                                "answer": "Eustachian Tube",
                                "Options": []
                            }
                        ]
                    }
                ]
            },
            {
                "question": "Apart from ante –natal care, give two other ways of caring for pregnant women.",
                "type": "check_box",
                "answer": [
                    "Provide a balance diet",
                    "Show them love"
                ],
                "Options": [
                    "Provide a balance diet",
                    "Show them love",
                    "Give them enough rest",
                    "Give appropriate dressing"
                ],
                "sub_questions": [
                    {
                        "question": "In which one ways should pregnant women protect their unborn babies from malaria?",
                        "type": "check_box",
                        "answer": [
                            "Take antimalarial like Fansidar"
                        ],
                        "Options": [
                            "Sleep in mosquito treated nets",
                            "Use mosquito repellant"
                        ]
                    },
                    {
                        "question": "Why are pregnant women advised not to smoke?",
                        "type": "check_box",
                        "answer": [
                            "Avoid still birth"
                        ],
                        "Options": [
                            "To avoid premature death",
                            "Avoid lung cancer"
                        ]
                    }
                ]
            },
            {
                "question": "The diagram below is a section   of human kidney.Use it to answer the questions that follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2011_51.jpg",
                "sub_questions": [
                    {
                        "question": "Name the part marked with letter B ",
                        "type": "text",
                        "answer": [
                            "ureter"
                        ]
                    },
                    {
                        "question": "Name the part marked with letter C ",
                        "type": "text",
                        "answer": [
                            "Medulla"
                        ]
                    },
                    {
                        "question": "What happens to blood in the part marked with letter D",
                        "type": "check_box",
                        "answer": [
                            "it carries oxygenated blood to the kidney"
                        ],
                        "options": [
                            "it carries oxygenated blood to the kidney",
                            "it carries de-oxygenated blood to the kidney",
                            "it carries oxygenated blood from the kidney",
                            "it carries de-oxygenated blood from the kidney"
                        ]
                    }
                ]
            },
            {
                "question": "The diagram below shows a method of making a magnet.Study it and use it to answer question 51.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2010_51.jpg",
                "sub_questions": [
                    {
                        "question": "Name the method of making a magnet shown in the diagram",
                        "type": "text",
                        "answer": [
                            "Electrical method"
                        ]
                    },
                    {
                        "question": "What pole will the part marked A become?",
                        "type": "text",
                        "answer": [
                            "North pole"
                        ]
                    },
                    {
                        "question": "What would you do to the dry cells in order to increase the strength of the magnet?",
                        "type": "check_box",
                        "answer": [
                            "Increase number of battery cells"
                        ],
                        "options": [
                            "Increase number of battery cells",
                            "decrease number of battery cells",
                            "switch the position of the battery cells",
                            "maintain the number of battery cells"
                        ]
                    },
                    {
                        "question": "Apart from the method shown in the diagram, name any other method of making a magnet.",
                        "type": "check_box",
                        "answer": [
                            "Induction method"
                        ],
                        "options": [
                            "Induction method",
                            "conduction method",
                            "By buying one",
                            "breaking another magnet into two"
                        ]
                    }
                ]
            },
            {
                "question": "Macth the weather instrument and its use",
                "sub_questions": [
                    {
                        "question": "Wind vane",
                        "type": "MultipleChoice",
                        "answer": [
                            "direction of wind"
                        ],
                        "Options": [
                            "direction of wind",
                            "amount of air pressure",
                            "amount of rain",
                            "speed of wind"
                        ]
                    },
                    {
                        "question": "Barometer: ",
                        "type": "MultipleChoice",
                        "answer": [
                            "amount of air pressure"
                        ],
                        "Options": [
                            "direction of wind",
                            "amount of air pressure",
                            "amount of rain",
                            "speed of wind"
                        ]
                    },
                    {
                        "question": "Rain gauge: ",
                        "type": "MultipleChoice",
                        "answer": [
                            "amount of rain"
                        ],
                        "Options": [
                            "direction of wind",
                            "amount of air pressure",
                            "amount of rain",
                            "speed of wind"
                        ]
                    },
                    {
                        "question": "Anemometer: ",
                        "type": "MultipleChoice",
                        "answer": [
                            "speed of wind"
                        ],
                        "Options": [
                            "direction of wind",
                            "amount of air pressure",
                            "amount of rain",
                            "speed of wind"
                        ]
                    }
                ]
            },
            {
                "question": "Mention any one method of harvesting root crops",
                "type": "text",
                "answer": [
                    "uprooting"
                ],
                "sub_questions": [
                    {
                        "question": "Give any three ways in which weeding helps on the proper growth of root crops.",
                        "type": "check_box",
                        "answer": [
                            "to ensure that root crops have enough space",
                            "to ensure the root crop get enough sunlight",
                            "reduce competition of crops for nutrients with weed"
                        ],
                        "Options": [
                            "reduce competition of crops for nutrients with weeds",
                            "to ensure that root crops have enough space",
                            "reduce competition of crops for nutrients with weed",
                            "increase competition of crops for nutrients with weeds"
                        ]
                    }
                ]
            },
            {
                "question": "What is environmental degradation?",
                "type": "check_box",
                "answer": [
                    "Is the misuse of the environment to an extent that it cannot support life"
                ],
                "Options": [
                    "Is the misuse of the environment to an extent that it cannot support life",
                    "planting of trees in an area",
                    "refers to the activity of growing crops for commercial use",
                    "it is a type of farming where farmers grow crops for home consumptions"
                ],
                "sub_questions": [
                    {
                        "question": "Give any three natural causes of environment degradation.",
                        "type": "check_box",
                        "answer": [
                            "floods",
                            "drought",
                            "volcanic eruption"
                        ],
                        "Options": [
                            "floods",
                            "drought",
                            "volcanic eruption",
                            "industries",
                            "rainfall"
                        ]
                    }
                ]
            }
        ]
    },
    {
        "category": 52,
        "questions": [
            {
                "sub_questions": [
                    {
                        "question": "state any two factors that should be considered when starting a tree growing project",
                        "type": "text",
                        "answer": [
                            "",
                            ""
                        ]
                    },
                    {
                        "question": "Give any two benefits of a woodlot to a community",
                        "type": "text",
                        "answer": [
                            "",
                            ""
                        ]
                    },
                    {
                        "question": "which digestive disorder is prevented by including roughage in our diet?",
                        "type": "text",
                        "answer": ""
                    }
                ]
            },
            {
                "question": "Give any two Qualities of seeds that can germinate",
                "type": "text",
                "answer": [
                    "",
                    ""
                ],
                "sub_questions": [
                    {
                        "question": "State any two farm practices which help in controlling insect pests in a garden.",
                        "type": "text",
                        "answer": [
                            "",
                            ""
                        ]
                    }
                ]
            },
            {
                "question": "Apart from rainfall, give one other element of weather necessary for plant growth.",
                "type": "text",
                "answer": "sunlight",
                "sub_questions": [
                    {
                        "question": "In which two ways is rainfall important for plant growth?",
                        "type": "text",
                        "answer": [
                            "Provide water for photosynthesis",
                            "Cools the plant",
                            "Softens soil for penetration of roots"
                        ]
                    },
                    {
                        "question": "State any one disadvantage of too much rainfall to plants.",
                        "type": "text",
                        "answer": [
                            "Causes flooding reducing air in the soil",
                            "Cause trees to fall"
                        ]
                    }
                ]
            },
            {
                "question": "state the method that can be used to separate:",
                "sub_questions": [
                    {
                        "question_text": "fruit seeds in juice",
                        "type": "MultipleChoice",
                        "answer": "",
                        "options": [
                            "",
                            "",
                            ""
                        ]
                    },
                    {
                        "question_text": "millet seeds mixed with its husks",
                        "type": "MultipleChoice",
                        "answer": "",
                        "options": [
                            "",
                            "",
                            ""
                        ]
                    }
                ],
                "question": "Give the importance of the following steps in cleaning clothes at home:",
                "sub_question1": [
                    {
                        "question_text": "Sorting",
                        "type": "MultipleChoice",
                        "answer": "",
                        "options": [
                            "",
                            "",
                            ""
                        ]
                    },
                    {
                        "question_text": "soaking",
                        "type": "MultipleChoice",
                        "answer": "",
                        "options": [
                            "",
                            "",
                            ""
                        ]
                    }
                ]
            },
            {
                "question": "Apart from the respiratory gases and body wastes, name any other two materials carried in blood.",
                "type": "check_box",
                "answer": [
                    "",
                    ""
                ],
                "Options": [
                    "",
                    "",
                    ""
                ],
                "Sub_Questions": [
                    {
                        "question_Text": "Name the component of blood which transports oxygen in the human body..",
                        "type": "check_box",
                        "answer": [
                            "",
                            ""
                        ],
                        "Options": [
                            "",
                            "",
                            ""
                        ]
                    },
                    {
                        "question_Text": "Which disease causing germ attacks the white blood cells in humans?",
                        "type": "text",
                        "answer": ""
                    }
                ]
            },
            {
                "question": "Give two ways in which Primary Heathy care (PHC) is an important program in the community",
                "Type": "check_box",
                "answer": [
                    "Controls population growth",
                    "Helps prevent malnutrition in babies"
                ],
                "Options": [
                    "",
                    ""
                ],
                "sub_questions": [
                    {
                        "question": "state any two roles of school healthy committee.",
                        "Type": "check_box",
                        "answer": [
                            "organizes pupils to clean the environment",
                            "conducts health parades",
                            "identify and finds a solution to healthy problem"
                        ],
                        "Options": [
                            "",
                            ""
                        ]
                    }
                ]
            },
            {
                "question": "Name any two water–borne diseases",
                "type": "check_box",
                "answer": [
                    "Polio",
                    "cholera"
                ],
                "Options": [
                    "syphillis",
                    "HIV/AIDS"
                ],
                "Sub_Questions": [
                    {
                        "question_Text": "In which two ways can water–borne diseases be prevented in the community?",
                        "type": "check_box",
                        "answer": [
                            "by boiling water for drinking",
                            "by proper disposal of wastes"
                        ],
                        "Options": [
                            "",
                            "",
                            ""
                        ]
                    }
                ]
            },
            {
                "question": "Name any two water–borne diseases",
                "type": "check_box",
                "answer": [
                    "Polio"
                ]
            }
        ]
    },
    {
        "category": "52",
        "questions": [
            {
                "question": "Name the force that brings down all objects thrown up",
                "type": "text",
                "answer": [
                    "Gravity"
                ],
                "sub_questions": [
                    {
                        "question": "When a piece of  paper and as rubber dare  dropped from  the same  height, which one reaches the ground first?",
                        "type": "check_box",
                        "answer": [
                            "Rubber "
                        ]
                    },
                    {
                        "question": "Give a reason for your answer in(b) (i) above",
                        "type": "check_box",
                        "answer": [
                            "Rubber experiences less air resistance than paper"
                        ],
                        "options": [
                            "Rubber experiences less air resistance than paper",
                            "Rubber experiences more air resistance than paper",
                            "Rubber experiences equal air resistance than paper"
                        ]
                    },
                    {
                        "question": "Why do some objects float on water?",
                        "type": "check_box",
                        "answer": [
                            "They are less dense than water"
                        ],
                        "options": [
                            "They are less dense than water",
                            "They are more dense than water",
                            "water can carry objects"
                        ]
                    }
                ]
            },
            {
                "question": "Give any two reasons why it is important to wash hands before eating food.",
                "type": "check_box",
                "answer": [
                    "To remove germs",
                    "To prevent food contamination"
                ],
                "Options": [
                    "To prevent food contamination ",
                    "To remove germs",
                    "to get clean hands",
                    "To add taste to food"
                ],
                "sub_questions": [
                    {
                        "question": "Apart from washing, mention any two other activities one can do to maintain personal hygiene.",
                        "type": "check_box",
                        "answer": [
                            "bathing",
                            "scrubbing the feet"
                        ],
                        "Options": [
                            "bathing",
                            "scrubbing the feet",
                            "keeping long hair",
                            "drinking alcohol"
                        ]
                    }
                ]
            },
            {
                "question": "Name the germ that cause HIV/AIDS.",
                "type": "text",
                "answer": [
                    "HIV"
                ],
                "sub_questions": [
                    {
                        "question": "Apart from having sex with an infected person, write down two other ways in which one can get the germ that causes HIV/AIDS",
                        "type": "check_box",
                        "answer": [
                            "From mother to child at birth",
                            "sharing sharp objects"
                        ],
                        "Options": [
                            "From mother to child at birth",
                            "sharing sharp objects",
                            "sharing tooth brushes",
                            "sharing bathing buckets and soap"
                        ]
                    },
                    {
                        "question": "ABC is a way  of controlling  the spread  of the germ that causes HIV/AIDS .what   does A stand for?",
                        "type": "text",
                        "answer": [
                            "Abstinence from sex"
                        ]
                    }
                ]
            },
            {
                "question": "What name is given to the process that turns sugar to alcohol?",
                "type": "text",
                "answer": [
                    "Fermentation"
                ],
                "sub_questions": [
                    {
                        "question": "Apart from making alcohol, state two other ways the process you named in (a) can be useful to human beings.",
                        "type": "check_box",
                        "answer": [
                            "Manufacture of dairy products like yoghurt",
                            "Use for baking"
                        ],
                        "Options": [
                            "Used for baking",
                            "Manufacture of dairy products like yoghurt",
                            "used for frying",
                            "used for smoking"
                        ]
                    },
                    {
                        "question": "Name the fungus used in this process.",
                        "type": "check_box",
                        "answer": [
                            "Yeast"
                        ]
                    },
                    {
                        "question": "What name is given to the liquid part of blood?",
                        "type": "text",
                        "answer": [
                            "Blood pressure"
                        ],
                        "sub_questions": [
                            {
                                "question": "Name one component of the liquid part of blood",
                                "type": "check_box",
                                "answer": [
                                    "Water"
                                ],
                                "Options": [
                                    "Water",
                                    "iron",
                                    "iodine",
                                    "redblood cells"
                                ]
                            },
                            {
                                "question": "Give any two uses of the liquid part of part.",
                                "type": "check_box",
                                "answer": [
                                    "Transport hormones",
                                    "Transport nutrients in blood"
                                ],
                                "Options": [
                                    "Transport hormones",
                                    "Transport nutrients in blood",
                                    "fight germs",
                                    "for growth"
                                ]
                            }
                        ]
                    },
                    {
                        "question": "Give any two ways in which mulching is important to the soil",
                        "type": "check_box",
                        "answer": [
                            "maintains moisture in the soil",
                            "the mulch decomposes to add manure"
                        ],
                        "Options": [
                            "maintains moisture in the soil",
                            "the mulch decomposes to add manure",
                            "mulchin causes soil erosion",
                            "mulches habour pests which destroy soil"
                        ],
                        "sub_questions": [
                            {
                                "question": "Suggest any two materials that are commonly used for mulching gardens.",
                                "type": "check_box",
                                "answer": [
                                    "grass",
                                    "Banana leaves"
                                ],
                                "Options": [
                                    "grass",
                                    "Banana leaves",
                                    "stems",
                                    "roots"
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "category": 53,
        "questions": [
            {
                "question": "The diagram below is of a thermos flask. Study and use it to answer the questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2018_53.jpg",
                "sub_questions": [
                    {
                        "question": "Name the partslabeled Y and Z",
                        "type": "text",
                        "answer": [
                            "",
                            ""
                        ]
                    },
                    {
                        "question": "Give any one material that can be used for making part W",
                        "type": "text",
                        "answer": ""
                    },
                    {
                        "question": "Why is part X able to prevent heat loss by conduction and convention?",
                        "type": "text",
                        "answer": ""
                    }
                ]
            },
            {
                "question": "The diagrams below show animals in the arthropods group. Studt and use them to answer the questions that follow..",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2017_53.jpg",
                "sub_questions": [
                    {
                        "question": "Name the group of arthropods to which animals X belongs",
                        "type": "text",
                        "answer": ""
                    },
                    {
                        "question": "Name the group of arthropods to which animals Y belongs",
                        "type": "text",
                        "answer": ""
                    },
                    {
                        "question": "Give any one reason why animal Y does not belong to the same group as animal Z.",
                        "type": "text",
                        "answer": [
                            "Y has two body parts while Z has three main body parts",
                            "Y has four pairs of legs while Z has three pairs of legs",
                            "Y has no wings while Z has wings"
                        ]
                    },
                    {
                        "question": "How are animals X, Y and Z similar in the way they reproduce?",
                        "type": "text",
                        "answer": "They lay eggs"
                    }
                ]
            },
            {
                "question": "Write down any two examples of anti-social behaviour in our communities",
                "type": "check_box",
                "answer": [
                    ""
                ],
                "options": [],
                "sub_questions": [
                    {
                        "question_text": "State any two ways of controlling anti-social behaviour in our communities",
                        "type": "check_box",
                        "answer": [
                            "",
                            ""
                        ],
                        "options": [
                            "",
                            "",
                            ""
                        ]
                    }
                ]
            },
            {
                "question": "The diagram below shows a weather instrument.study and use it to answer the questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2015_53.jpg",
                "sub_questions": [
                    {
                        "question": "Name the weather instrument shown in the diagram above.",
                        "type": "text",
                        "answer": ""
                    },
                    {
                        "question": "What is the use of the instrument above at a weather station?",
                        "type": "check_box",
                        "answer": "",
                        "Options": [
                            "",
                            ""
                        ]
                    },
                    {
                        "question": "Give a reason why people are advised to:",
                        "sub_questions": [
                            {
                                "question": "put the instrument in an open ground when using it",
                                "type": "MultipleChoice",
                                "answer": "",
                                "Options": [
                                    "",
                                    ""
                                ]
                            },
                            {
                                "question": "raise the instrument 30 cm above the ground when using it",
                                "type": "MultipleChoice",
                                "answer": "",
                                "Options": [
                                    "",
                                    ""
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "question": "What name is given to a practice of growing crops and trees together on the same piece of land?",
                "type": "text",
                "answer": "Agroforestry",
                "sub_questions": [
                    {
                        "question": "State any two ways in which crops benefit from trees when they grow together.",
                        "type": "check_box",
                        "answer": [
                            "trees act as wind breaker",
                            "Trees provide shades"
                        ],
                        "Options": [
                            "",
                            ""
                        ]
                    },
                    {
                        "question": "Give any one proper method of harvesting trees in the practice named in (a) above",
                        "type": "text",
                        "answer": "Pollarding/ cropping/selective felling"
                    }
                ]
            },
            {
                "question": "The diagram m below shows part of the human skeleton. Use it to answer the questions that follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2013_53.jpg",
                "sub_questions": [
                    {
                        "question": "Name the part marked J",
                        "type": "check_box",
                        "answer": "Sternum",
                        "Options": []
                    },
                    {
                        "question": "State any two delicate body organ protected by the part of the skeleton showns",
                        "type": "text",
                        "answer": [
                            "heart",
                            "lungs"
                        ]
                    },
                    {
                        "question": "How is the part of the human skeleton shown above used during breathing out?",
                        "type": "check_box",
                        "answer": [
                            "Reduced the volume of the chest by moving inwards and down "
                        ],
                        "Options": ""
                    }
                ]
            },
            {
                "question": "State any two reasons why farmers thin their crops",
                "type": "check_box",
                "answer": [
                    "",
                    ""
                ],
                "Options": [],
                "sub_questions": [
                    {
                        "question": "Apart from thinning give any other ways farmers care for their crops",
                        "type": "check_box",
                        "answer": [
                            "watering"
                        ],
                        "Options": [
                            "crop rotation",
                            "watering",
                            "harvesting"
                        ]
                    }
                ]
            },
            {
                "question": "Name two natural resources in the environment that enable green plants to make their food",
                "type": "check_box",
                "answer": [
                    "carbon dioxide",
                    "sunlight"
                ],
                "Options": [
                    "sunlight",
                    "carbon dioxide",
                    "oxygen",
                    "leaves"
                ],
                "sub_questions": [
                    {
                        "question": "What is the name of the process in (a) above?",
                        "type": "text",
                        "answer": [
                            "photosynthesis"
                        ]
                    },
                    {
                        "question": "Which gas is given off during this process?",
                        "type": "text",
                        "answer": [
                            "oxygen"
                        ]
                    }
                ]
            },
            {
                "question": "Apart from killing people, give two other dangers of lighting ",
                "type": "check_box",
                "answer": [
                    "Cause injury",
                    "destroy properties"
                ],
                "Options": [
                    "Cause injury",
                    "destroy properties",
                    "causes soil erosion",
                    "causes defforestation"
                ],
                "sub_questions": [
                    {
                        "question": "write down two ways by which people can protect themselves from the dangers of lightening",
                        "type": "check_box",
                        "answer": [
                            "avoid standing under tall trees when raining",
                            "wearing rubber shoes"
                        ],
                        "Options": [
                            "avoid standing under tall trees when raining",
                            "wearing rubber shoes",
                            "Avoid using lightening conductors on bulidings",
                            "cut down trees that are too tall"
                        ]
                    }
                ]
            },
            {
                "question": "Name the process that takes place in the air sacs of a human lung",
                "type": "text",
                "answer": [
                    "Gaseous exchange"
                ],
                "sub_questions": [
                    {
                        "question": "What prevents the trachea (wind pipe) from closing during breathing?",
                        "type": "check_box",
                        "answer": [
                            "It is made of cartilage rings that do not collapse under low pressure"
                        ],
                        "Options": [
                            "It is made of cartilage rings that do not collapse under low pressure",
                            "It is made of cartilage rings that do not collapse under high pressure",
                            "It is made of cartilage rings that  collapse under high pressure"
                        ]
                    },
                    {
                        "question": "Give two products found in the air we breathe out.",
                        "type": "check_box",
                        "answer": [
                            "Carbon dioxide",
                            "water"
                        ],
                        "Options": [
                            "water",
                            "Carbon dioxide",
                            "salt",
                            "oxygen"
                        ]
                    },
                    {
                        "question": "Give the meaning of the following words as used in cattle –rearing",
                        "sub_questions": [
                            {
                                "question": "Insemination: ",
                                "type": "MultipleChoice",
                                "answer": [
                                    "inserting the semen of male cow into the vigina of a female cow for fertilization."
                                ],
                                "Options": [
                                    "this is a period of time when a cow is restless in desire to mate with a bull",
                                    "Activity carried out on a bull to make it unable to have sex and reproduce",
                                    " where a local and exotic breed mate to produce an intermediate offspring",
                                    "inserting the semen of male cow into the vigina of a female cow for fertilization."
                                ]
                            },
                            {
                                "question": "Crossbreeding: nation: ",
                                "type": "MultipleChoice",
                                "answer": [
                                    "inserting the semen of male cow into the vigina of a female cow for fertilization."
                                ],
                                "Options": [
                                    "this is a period of time when a cow is restless in desire to mate with a bull",
                                    "Activity carried out on a bull to make it unable to have sex and reproduce",
                                    " where a local and exotic breed mate to produce an intermediate offspring",
                                    "inserting the semen of male cow into the vigina of a female cow for fertilization."
                                ]
                            },
                            {
                                "question": "Castration: ",
                                "type": "MultipleChoice",
                                "answer": [
                                    "inserting the semen of male cow into the vigina of a female cow for fertilization."
                                ],
                                "Options": [
                                    "this is a period of time when a cow is restless in desire to mate with a bull",
                                    "Activity carried out on a bull to make it unable to have sex and reproduce",
                                    " where a local and exotic breed mate to produce an intermediate offspring",
                                    "inserting the semen of male cow into the vigina of a female cow for fertilization."
                                ]
                            },
                            {
                                "question": "On heat ",
                                "type": "MultipleChoice",
                                "answer": [
                                    "inserting the semen of male cow into the vigina of a female cow for fertilization."
                                ],
                                "Options": [
                                    "this is a period of time when a cow is restless in desire to mate with a bull",
                                    "Activity carried out on a bull to make it unable to have sex and reproduce",
                                    " where a local and exotic breed mate to produce an intermediate offspring",
                                    "inserting the semen of male cow into the vigina of a female cow for fertilization."
                                ]
                            }
                        ]
                    },
                    {
                        "question": "State any one function of each of the following parts in simple electric circuit:",
                        "sub_questions": [
                            {
                                "question": " Wire ",
                                "type": "MultipleChoice",
                                "answer": [
                                    "conducts electricity"
                                ],
                                "Options": [
                                    "conducts electricity",
                                    "source of current"
                                ]
                            },
                            {
                                "question": "Battery",
                                "type": "MultipleChoice",
                                "answer": [
                                    "source of current"
                                ],
                                "Options": [
                                    "conducts electricity",
                                    "source of current"
                                ]
                            },
                            {
                                "question": "Mention any two causes of a short circuit",
                                "type": "check_box",
                                "answer": [
                                    "high voltage",
                                    "contact of naked wires in a circuit."
                                ],
                                "options": [
                                    "high voltage",
                                    "contact of naked wires in a circuit.",
                                    "low voltage",
                                    "contact of closed wires in a circuit."
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "category": 54,
        "questions": [
            {
                "question": "The diagram below shows how light is split into seven colors using a glass prism. Study it and answer the questions that follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2019_54.jpg",
                "sub_questions": [
                    {
                        "question": "Name the colours x and z",
                        "type": "text",
                        "answer": [
                            "",
                            ""
                        ]
                    },
                    {
                        "question": "why do the light rays bend at different angles as they leave the glass prism?",
                        "type": "text",
                        "answer": ""
                    },
                    {
                        "question": "Name any two diseases that can break out due to poor sanitation in a school",
                        "type": "text",
                        "answer": [
                            "",
                            ""
                        ]
                    }
                ]
            },
            {
                "question": "State any two differences between the external parts of a hen and that of a cock.",
                "type": "text",
                "answer": [
                    "",
                    ""
                ],
                "sub_questions": [
                    {
                        "question": "Write down any one product got from poultry birds.",
                        "type": "text",
                        "answer": ""
                    },
                    {
                        "question": "Give any one material that can be used for making part W",
                        "type": "text",
                        "answer": ""
                    },
                    {
                        "question": "Give any one way in which farmers can improve on the quality of their local breeds of chicken",
                        "type": "text",
                        "answer": ""
                    },
                    {
                        "question": "Apart from raising the temperature of substances, give any two effects of heat on matter.",
                        "type": "text",
                        "answer": [
                            "Melts metal.",
                            "Burns them",
                            "Decompose matter",
                            "Causes evaporation",
                            "Cause expansion"
                        ],
                        "sub_questions": [
                            {
                                "question": "State any two ways in which heat transfer by conduction is important to people.",
                                "type": "text",
                                "answer": [
                                    "During ironing clothes",
                                    "thermostat"
                                ]
                            }
                        ]
                    },
                    {
                        "question": "Apart from using solfa notation, mention any two other ways of storing sound",
                        "type": "check_box",
                        "answer": [
                            ""
                        ],
                        "options": [],
                        "sub_questions": [
                            {
                                "question_text": "Name two instruments used to reproduce the sound stored by solfa notation",
                                "type": "check_box",
                                "answer": [
                                    "",
                                    ""
                                ],
                                "options": [
                                    "",
                                    "",
                                    ""
                                ]
                            }
                        ]
                    },
                    {
                        "question": "To which class of vertebrates do toads belong?",
                        "type": "check_box",
                        "answer": "",
                        "options": [
                            "",
                            "",
                            ""
                        ],
                        "sub_questions": [
                            {
                                "question": "Give one way in which the breathing of a toad is different from that of a tadpole.",
                                "type": "text",
                                "answer": ""
                            },
                            {
                                "question": "State any two ways in which the jelly round the eggs of toads is important.",
                                "type": "check_box",
                                "answer": "",
                                "Options": ""
                            }
                        ]
                    },
                    {
                        "question": "Study the diagram of an electric circuit below and use it to answer the questions that follow",
                        "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2016_54.jpg",
                        "sub_questions": [
                            {
                                "question": "Name the parts marked N",
                                "Type": "MultipleChoice",
                                "answer": "bulb",
                                "Options": [
                                    "",
                                    "",
                                    ""
                                ]
                            },
                            {
                                "question": "Name the parts marked K",
                                "Type": "MultipleChoice",
                                "answer": "bulb",
                                "Options": [
                                    "",
                                    "",
                                    ""
                                ]
                            },
                            {
                                "question": "State the energy change that takes place at L when M is closed",
                                "type": "text",
                                "answer": "Chemical energy is converted to electric energy."
                            },
                            {
                                "question": "Give any one form of energy produces at K when M is closed",
                                "type": "text",
                                "answer": "Light and heat"
                            }
                        ]
                    },
                    {
                        "question": "Write down  any two  activities  that  can  be  done  by  an individual  to  promote  personal  hygiene",
                        "type": "check_box",
                        "answer": [
                            "brushing teeth",
                            "bathing daily"
                        ],
                        "options": [
                            "",
                            "",
                            ""
                        ],
                        "sub_questions": [
                            {
                                "question": "State two ways community members can promote sanitation in their area.",
                                "type": "check_box",
                                "answer": [
                                    "protecting spring wells",
                                    "by proper disposal of wastes"
                                ],
                                "Options": []
                            }
                        ]
                    },
                    {
                        "question": "Name any two   products got from sheep",
                        "type": "check_box",
                        "answer": [
                            "wool",
                            "mutton"
                        ],
                        "Options": [
                            "mutton ",
                            "pork",
                            "wool",
                            "meat"
                        ],
                        "sub_questions": [
                            {
                                "question": "What does shearing refer to in sheep rearing?",
                                "type": "check_box",
                                "answer": [
                                    "shearing is the removal of wool from a sheep’s body using a shearing machine."
                                ],
                                "Options": [
                                    "shearing is the removal of wool from a sheep’s body using a shearing machine",
                                    "shearing is the removal of ticks from a sheep’s body using a shearing machine.",
                                    "shearing is the removal of lice from a sheep’s body using a shearing machine.",
                                    "shearing is the removal of nails from a sheep’s toes using a shearing machine",
                                    "shearing is the removal of horns from a sheep’s head using a shearing machine."
                                ]
                            },
                            {
                                "question": "How can a farmer control pneumonia in sheep?",
                                "type": "check_box",
                                "answer": [
                                    "Keep sheep in well ventilated pens"
                                ],
                                "Options": [
                                    "Keep sheep in well ventilated pens ",
                                    "Keep sheep under the sun",
                                    "Keep sheep in well ventilated kraals",
                                    "Keep sheep in dipping pools"
                                ]
                            }
                        ]
                    },
                    {
                        "question": "In an experiment, salt is mixed with water stirred until it dissolves to make a solution.What do the following act as in the experiment?",
                        "sub_questions": [
                            {
                                "question": "Salt",
                                "type": "MultipleChoice",
                                "answer": [
                                    "solute"
                                ],
                                "Options": [
                                    "solute",
                                    "solvents"
                                ]
                            },
                            {
                                "question": "Water ",
                                "type": "MultipleChoice",
                                "answer": [
                                    "solvents"
                                ],
                                "Options": [
                                    "solute",
                                    "solvents"
                                ]
                            },
                            {
                                "question": "Name any other substance that could   be used instead of salt.",
                                "type": "MultipleChoice",
                                "answer": [
                                    "Sugar",
                                    "glucose"
                                ],
                                "Options": [
                                    "Sugar",
                                    "glucose",
                                    "detergent",
                                    "flour"
                                ]
                            },
                            {
                                "question": "How can salt be removed from the solution?",
                                "type": "text",
                                "answer": [
                                    "By evaporation"
                                ],
                                "Options": [
                                    "Sugar",
                                    "glucose",
                                    "detergent",
                                    "flour"
                                ]
                            },
                            {
                                "question": "State the function of each of the following structure in the human skeleton.",
                                "sub_questions": [
                                    {
                                        "question": "Ligament:",
                                        "type": "MultipleChoice",
                                        "answer": [
                                            "joins a bone to a bone"
                                        ],
                                        "Options": [
                                            "joins a bone to a bone",
                                            "joins a bone to a muscle"
                                        ]
                                    },
                                    {
                                        "question": "Tendon: ",
                                        "type": "MultipleChoice",
                                        "answer": [
                                            "joins a bone to a muscle"
                                        ],
                                        "Options": [
                                            "joins a bone to a bone",
                                            "joins a bone to a muscle",
                                            ""
                                        ]
                                    },
                                    {
                                        "question": "Give one example of a long bone in a human skeleton",
                                        "type": "MultipleChoice",
                                        "answer": [
                                            "Femur"
                                        ],
                                        "Options": [
                                            "Femur",
                                            "skull",
                                            "tarsals",
                                            "meta-tarsals"
                                        ]
                                    },
                                    {
                                        "question": "Name one disease that affects a human skeleton.",
                                        "type": "MultipleChoice",
                                        "answer": [
                                            "Polio"
                                        ],
                                        "Options": [
                                            "Polio",
                                            "dysentery",
                                            "intestinal obstructio",
                                            "rush"
                                        ]
                                    }
                                ]
                            },
                            {
                                "question": "What is meant by the term silting?",
                                "type": "check_box",
                                "answer": [
                                    "is the collecting and deposition of soil, sand or mud at the sides or the bottom of the river"
                                ],
                                "Options": [
                                    "is the collecting and deposition of soil, sand or mud at the sides or the bottom of the river",
                                    "is the collecting and deposition of soil, sand or mud at the sides or the bottom of  a lake ",
                                    "is the collecting and deposition of soil, sand or mud at the sides or the bottom of a mountain",
                                    "is the collecting and deposition of soil, sand or mud at the sides or the bottom of the sea"
                                ],
                                "sub_questions": [
                                    {
                                        "question": "Apart from killing fish, how else does silting affect fish?",
                                        "type": "check_box",
                                        "answer": [
                                            "Destroy breeding space"
                                        ],
                                        "Options": [
                                            "Destroy breeding space",
                                            "causes extinction",
                                            "dirties the water so they cant see"
                                        ]
                                    },
                                    {
                                        "question": "List any two major causes of silting",
                                        "type": "check_box",
                                        "answer": [
                                            "soil erosion",
                                            "Fast flow of water in river"
                                        ],
                                        "options": [
                                            "Fast flow of water in river",
                                            "soil erosion",
                                            "too much sunshine",
                                            "too much drought"
                                        ]
                                    }
                                ]
                            },
                            {
                                "question": "The diagram below shows a machine used for lifting loads. use it to answer the questions that follow",
                                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2007_54.jpg",
                                "sub_questions": [
                                    {
                                        "question": "What distance is the load to be lifted?",
                                        "type": "check_box",
                                        "answer": [
                                            "4m high"
                                        ],
                                        "options": [
                                            "4m high",
                                            "6m high",
                                            "4m lower",
                                            "6m lower"
                                        ]
                                    },
                                    {
                                        "question": "Give the advantage of lifting the load alongside CA.",
                                        "type": "check_box",
                                        "answer": [
                                            "Less effort is used to make work easier"
                                        ],
                                        "options": [
                                            "Less effort is used to make work easier",
                                            "more effort is used to make work easier",
                                            "Less effort is used to make work harder",
                                            "more effort is used to make work harder"
                                        ]
                                    },
                                    {
                                        "question": "Find  the mechanical advantage  of the machine above",
                                        "type": "check_box",
                                        "answer": [
                                            "2"
                                        ],
                                        "explanation": [
                                            "MA = Load/Effort <br> = 80/40 <br> = 2"
                                        ],
                                        "options": [
                                            "2",
                                            "4",
                                            "2.4",
                                            "3.64"
                                        ]
                                    },
                                    {
                                        "question": "How can the machine be improved so that less effort is used to lift loads",
                                        "type": "check_box",
                                        "answer": [
                                            "By reducing the angle of inclination of the plane"
                                        ],
                                        "options": [
                                            "By reducing the angle of inclination of the plane",
                                            "By increasing the angle of inclination of the plane",
                                            "By reducing the angle of declination of the plane",
                                            "By increasing the angle of declination of the plane"
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "category": 55,
                        "questions": [
                            {
                                "question": "state any two signs  of poor sanitation  in a school",
                                "type": "text",
                                "answer": [
                                    "",
                                    ""
                                ]
                            },
                            {
                                "question": "Give any two diseases that can break out due to poor sanitation",
                                "type": "text",
                                "answer": [
                                    "",
                                    "",
                                    ""
                                ]
                            },
                            {
                                "question": "Give any two possible ways through which a baby can get HIV/AIDS from the mother.",
                                "type": "text",
                                "answer": [
                                    "",
                                    ""
                                ],
                                "sub_questions": [
                                    {
                                        "question": "State two ways in which an HIV positive pregnant woman can protect her unborn baby from getting HIV/AIDS",
                                        "type": "text",
                                        "answer": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "question": "The diagram below shows a simple method of making water clean.Study and use it to answer the questions that follow.",
                        "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2017_55.jpg",
                        "sub_questions": [
                            {
                                "question": "Name the method shown above",
                                "type": "text",
                                "answer": "Filtration"
                            },
                            {
                                "question": "Apart from the coarse stones, name any two other local materials which are placed in container T.",
                                "type": "text",
                                "answer": [
                                    "sand",
                                    "Charcoal powder"
                                ]
                            },
                            {
                                "question": "Why should the clean water obtained in container s be boiled before drinking?",
                                "type": "text",
                                "answer": "To kill germs"
                            }
                        ]
                    },
                    {
                        "question": "The diagram below shows the water cycle. Study and use it to answer the questions that follow.",
                        "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2016_55.jpg",
                        "sub_questions": [
                            {
                                "question": "Name the type of clouds represented by letter A.",
                                "type": "text",
                                "answer": "Filtration"
                            },
                            {
                                "question": "State the processes taking place at B and C",
                                "Type": "MultipleChoice",
                                "answer": "",
                                "Options": [
                                    "",
                                    "",
                                    ""
                                ]
                            },
                            {
                                "question": "What is the importance of the sun in the above diagram?",
                                "type": "text",
                                "answer": ""
                            }
                        ]
                    },
                    {
                        "question": "Apart from ticks, name any one other cattle pest.",
                        "type": "check_box",
                        "answer": "",
                        "Options": [
                            "",
                            "",
                            "",
                            ""
                        ],
                        "sub_questions": [
                            {
                                "question": "State any two diseases spread by ticks to cattle.",
                                "type": "check_box",
                                "answer": "",
                                "Options": [
                                    "",
                                    "",
                                    "",
                                    ""
                                ]
                            },
                            {
                                "question": "Give any one way in which the tick borne diseases can be controlled.",
                                "type": "check_box",
                                "answer": "",
                                "Options": [
                                    "",
                                    "",
                                    "",
                                    ""
                                ]
                            }
                        ]
                    },
                    {
                        "question": "The table below shows classification of plants.Study it carefully and use it to answer the questions that follow",
                        "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2014_55.png",
                        "sub_questions": [
                            {
                                "question": "Name the group of plants shown by the letter A:",
                                "Type": "MultipleChoice",
                                "answer": "nonflowering plants",
                                "Options": [
                                    "",
                                    "",
                                    ""
                                ]
                            },
                            {
                                "question": "Name the group of plants shown by the letter B:",
                                "Type": "MultipleChoice",
                                "answer": "nonflowering plants",
                                "Options": [
                                    "",
                                    "",
                                    ""
                                ]
                            },
                            {
                                "question": "Name the group of plants shown by the letter C:",
                                "Type": "MultipleChoice",
                                "answer": "nonflowering plants",
                                "Options": [
                                    "",
                                    "",
                                    ""
                                ]
                            },
                            {
                                "question": "Give any one plant that belongs to the box labelled D",
                                "Type": "check_box",
                                "answer": "Fern",
                                "Options": [
                                    "",
                                    "",
                                    ""
                                ]
                            }
                        ]
                    },
                    {
                        "question": ". Study the diagram of an electric bell below and use it to answer question 55",
                        "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2012_55.jpg",
                        "sub_questions": [
                            {
                                "question": "Name the part marked A and C",
                                "sub": [
                                    {
                                        "question": "A",
                                        "type": "text",
                                        "answer": [
                                            "Dry cell"
                                        ]
                                    },
                                    {
                                        "question": "C",
                                        "type": "text",
                                        "answer": [
                                            " hammer"
                                        ]
                                    }
                                ]
                            },
                            {
                                "question": "What will happen to E when B is closed ",
                                "type": "text",
                                "answer": [
                                    "Become magnetized"
                                ],
                                "options": [
                                    "Become magnetized",
                                    "Become de-magnetized",
                                    "Become polarised"
                                ]
                            },
                            {
                                "question": "How useful  is  part D on the electric bell?",
                                "type": "text",
                                "answer": [
                                    "Produce sound"
                                ],
                                "options": [
                                    "Produce sound",
                                    "reflect sound",
                                    "produce electricity"
                                ]
                            }
                        ]
                    },
                    {
                        "question": "What force makes a pencil to reduce in length as one writes?",
                        "type": "text",
                        "answer": [
                            "Friction"
                        ],
                        "sub_questions": [
                            {
                                "question": "Name any one form of energy produced by the force in (a) above as one writes",
                                "type": "text",
                                "answer": [
                                    "sound energy"
                                ]
                            },
                            {
                                "question": "Give  any  two  disadvantage  of the  you have named  above  to the  writer ",
                                "type": " check-box",
                                "answer": [
                                    "it produces unnecessary heat ",
                                    "it produces unnecessary noise"
                                ],
                                "options": [
                                    "it produces unnecessary noise",
                                    "it produces unnecessary heat",
                                    "it produces less noise",
                                    "it produces less heat"
                                ]
                            },
                            {
                                "question": "Why are vaccine   used in the prevention of certain diseases?",
                                "type": "text",
                                "answer": [
                                    "induce body immunity"
                                ],
                                "sub_questions": [
                                    {
                                        "question": "How is the measles vaccine given to a baby?",
                                        "type": "text",
                                        "answer": [
                                            "Through injection"
                                        ]
                                    },
                                    {
                                        "question": "A part from immunization, state two other ways in which a baby`s immunity can be improved.",
                                        "type": "check_box",
                                        "answer": [
                                            "by breast feeding",
                                            "eating balance diet"
                                        ],
                                        "options": [
                                            "eating balance diet",
                                            "by breast feeding",
                                            "physical excerise",
                                            "weaning"
                                        ]
                                    }
                                ]
                            },
                            {
                                "question": "Name the germ that causes gonorrhea",
                                "type": "text",
                                "answer": [
                                    "Gonococci"
                                ],
                                "sub_questions": [
                                    {
                                        "question": "Give one sign of gonorrhea infection in a newly born baby",
                                        "type": "text",
                                        "answer": [
                                            "blindness"
                                        ]
                                    },
                                    {
                                        "question": "How does gonorrhea infection increase the chances of a person getting HIV/AIDS?",
                                        "type": "check_box",
                                        "answer": [
                                            "It weakens the white blood cells making them unable to fight the virus that causes AIDs",
                                            "It strengthens the white blood cells making them unable to fight the virus that causes AIDs",
                                            "It weakens the red blood cells making them unable to fight the virus that causes AIDs",
                                            "It strengthens the red blood cells making them unable to fight the virus that causes AIDs"
                                        ]
                                    },
                                    {
                                        "question": "How can a person avoid getting gonorrhea?",
                                        "type": "check_box",
                                        "answer": [
                                            "Abstinence from sex "
                                        ],
                                        "options": [
                                            "Abstinence from sex ",
                                            "Having unprotected sex ",
                                            "Having many sexual partners",
                                            "Abstinence from alcohol"
                                        ]
                                    }
                                ]
                            },
                            {
                                "question": "The diagram below shows a life cycle of an insect. Use it to answer the question that follow",
                                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2010_55.jpg",
                                "sub_questions": [
                                    {
                                        "question": "Name stages marked M",
                                        "type": "text",
                                        "answer": [
                                            "Nymph"
                                        ]
                                    },
                                    {
                                        "question": "Name stages marked T",
                                        "type": "text",
                                        "answer": [
                                            "Adult"
                                        ]
                                    },
                                    {
                                        "question": "Name one insect that undergoes the above stages of development",
                                        "type": " check-box",
                                        "answer": [
                                            "Cockroach",
                                            "housefly",
                                            "mosquito"
                                        ]
                                    },
                                    {
                                        "question": "name the stage marked with letter P",
                                        "type": "text",
                                        "answer": [
                                            "Egg"
                                        ]
                                    }
                                ]
                            },
                            {
                                "question": "The diagram below is of a ventilated improved Pit (VIP) latrine.Use it to answer the question that follow.Use it to answer the question that follow.",
                                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/science/2007_55.jpg",
                                "sub_questions": [
                                    {
                                        "question": "Name the part marked m ",
                                        "type": "text",
                                        "answer": [
                                            "wire gauze"
                                        ]
                                    },
                                    {
                                        "question": "Name the part marked n",
                                        "type": "text",
                                        "answer": [
                                            "vent pipe"
                                        ]
                                    },
                                    {
                                        "question": "Give the function of the part marked M",
                                        "type": "check_box",
                                        "answer": [
                                            "Prevent flies from escaping from the latrine"
                                        ],
                                        "options": [
                                            "Prevent flies from escaping from the latrine",
                                            "Prevent flies from entering the latrine",
                                            "Prevent bad smell from  latrine"
                                        ]
                                    },
                                    {
                                        "question": "What  do the arrows in the diagram show? ",
                                        "type": "text",
                                        "answer": [
                                            "Direction of air through the latrine"
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    }
]

const eng_ple = [
    {
        "instructions": "In each of the questions 1 to 5, fill in the blank space with a suitable word.",
        "category": 1,
        "numberOfQuestions ": 5,
        "questions": [
            {
                "question": "_____ old are you, Mary?",
                "answer": "How",
                "mark": 1,
                "id": "pleenglishcategory1-5_1",
                "type": "text"
            },
            {
                "question": "The angry dog _____ the thief's leg as he tried to escape.",
                "answer": "bit",
                "mark": 1,
                "id": "pleenglishcategory1-5_2",
                "type": "text"
            },
            {
                "question": "A lion is _____ fierce than a leopard.",
                "answer": "more",
                "mark": 1,
                "id": "pleenglishcategory1-5_3",
                "type": "text"
            },
            {
                "question": "That is the journalist to _____ we gave our articles to publish.",
                "answer": "whom",
                "mark": 1,
                "id": "pleenglishcategory1-5_4",
                "type": "text"
            },
            {
                "question": "Our cat has been feeding all its newborn _____ on mice.",
                "answer": "kitten",
                "mark": 1,
                "id": "pleenglishcategory1-5_5",
                "type": "text"
            },
            {
                "question": "Uganda gained independence _____ 1962.",
                "answer": "in",
                "mark": 1,
                "id": "pleenglishcategory1-5_6",
                "type": "text"
            },
            {
                "question": "The skillful _____ has varnished the piece of furniture he made yesterday.",
                "answer": "carpenter",
                "mark": 1,
                "id": "pleenglishcategory1-5_7",
                "type": "text"
            },
            {
                "question": "_____ a narrow road to Akapu village is!",
                "answer": "What",
                "mark": 1,
                "id": "pleenglishcategory1-5_8",
                "type": "text"
            },
            {
                "question": "Bruno is very happy because all the eggs that his hen _____ have hatched.",
                "answer": "laid",
                "mark": 1,
                "id": "pleenglishcategory1-5_9",
                "type": "text"
            },
            {
                "question": "That is the girl _____ mother is our PE teacher.",
                "answer": "whose",
                "mark": 1,
                "id": "pleenglishcategory1-5_10",
                "type": "text"
            },
            {
                "question": "My sister sat _____ a tree after taking medicine.",
                "answer": "under",
                "mark": 1,
                "id": "pleenglishcategory1-5_11",
                "type": "text"
            },
            {
                "question": "Most people enjoy _____ to radio talk shows.",
                "answer": "listening",
                "mark": 1,
                "id": "pleenglishcategory1-5_12",
                "type": "text"
            },
            {
                "question": "_____ John and Mary are good at solving Mathematical problems.",
                "answer": "Both",
                "mark": 1,
                "id": "pleenglishcategory1-5_13",
                "type": "text"
            },
            {
                "question": "The _____ in our trading centre did not sell meat yesterday.",
                "answer": "butcher",
                "mark": 1,
                "id": "pleenglishcategory1-5_14",
                "type": "text"
            },
            {
                "question": "How _____ do you visit your grandparents?.",
                "answer": "often",
                "mark": 1,
                "id": "pleenglishcategory1-5_15",
                "type": "text"
            },
            {
                "question": "How _____ does a bottle of soda cost?",
                "answer": "much",
                "mark": 1,
                "id": "pleenglishcategory1-5_16",
                "type": "text"
            },
            {
                "question": "Lina was knocked _____ by a speeding car.",
                "answer": "over",
                "mark": 1,
                "id": "pleenglishcategory1-5_17",
                "type": "text"
            },
            {
                "question": "I would _____ be a carpenter than a tailor.",
                "answer": "rather",
                "mark": 1,
                "id": "pleenglishcategory1-5_18",
                "type": "text"
            },
            {
                "question": "He visited the _____ because he had a toothache.",
                "answer": "dentist",
                "mark": 1,
                "id": "pleenglishcategory1-5_19",
                "type": "text"
            },
            {
                "question": "_____ more books you read, the wiser you become.",
                "answer": "The",
                "mark": 1,
                "id": "pleenglishcategory1-5_20",
                "type": "text"
            },
            {
                "question": "We _____ all join senior one next year, shan't we?",
                "answer": "shall",
                "mark": 1,
                "id": "pleenglishcategory1-5_21",
                "type": "text"
            },
            {
                "question": "This is the place _____ I fell off the motorcycle.",
                "answer": "where",
                "mark": 1,
                "id": "pleenglishcategory1-5_22",
                "type": "text"
            },
            {
                "question": "Musa is the boy with _____ we went to sing traditional songs at the festival.",
                "answer": "whom",
                "mark": 1,
                "id": "pleenglishcategory1-5_23",
                "type": "text"
            },
            {
                "question": "_____ Opio buys new tyres for his car, he will not be arrested by traffic police officers.",
                "answer": "If",
                "mark": 1,
                "id": "pleenglishcategory1-5_24",
                "type": "text"
            },
            {
                "question": "The bitch has given birth to three _____.",
                "answer": "puppies",
                "mark": 1,
                "id": "pleenglishcategory1-5_25",
                "type": "text"
            },
            {
                "question": "Either Joan _____ Joel will feed the baby.",
                "answer": "or",
                "mark": 1,
                "id": "pleenglishcategory1-5_26",
                "type": "text"
            },
            {
                "question": "Besides using a hammer, the carpenter _____ uses a drill.",
                "answer": "also",
                "mark": 1,
                "id": "pleenglishcategory1-5_27",
                "type": "text"
            },
            {
                "question": "Sugar is normally measured _____ kilograms.",
                "answer": "in",
                "mark": 1,
                "id": "pleenglishcategory1-5_28",
                "type": "text"
            },
            {
                "question": "Number _____ comes after seven.",
                "answer": "eight",
                "mark": 1,
                "id": "pleenglishcategory1-5_29",
                "type": "text"
            },
            {
                "question": "What _____ you doing now?",
                "answer": "are",
                "mark": 1,
                "id": "pleenglishcategory1-5_30",
                "type": "text"
            },
            {
                "question": "Asio is my _____ because she is a sister to my father.",
                "answer": "aunt",
                "mark": 1,
                "id": "pleenglishcategory1-5_31",
                "type": "text"
            },
            {
                "question": "I last boarded a bus four months _____",
                "answer": "ago",
                "mark": 1,
                "id": "pleenglishcategory1-5_32",
                "type": "text"
            },
            {
                "question": "Our class teacher is such a kind man _____ we all love.",
                "answer": "who",
                "mark": 1,
                "id": "pleenglishcategory1-5_33",
                "type": "text"
            },
            {
                "question": "The dog _____ so loudly that everybody got scared.",
                "answer": "barked",
                "mark": 1,
                "id": "pleenglishcategory1-5_34",
                "type": "text"
            },
            {
                "question": "That lady has _____ standing near the sign post.",
                "answer": "been",
                "mark": 1,
                "id": "pleenglishcategory1-5_35",
                "type": "text"
            },
            {
                "question": "Lazy peeple _____ eating to working.",
                "answer": "prefer",
                "mark": 1,
                "id": "pleenglishcategory1-5_36",
                "type": "text"
            },
            {
                "question": "It is not good to shout _____your friends.",
                "answer": "at",
                "mark": 1,
                "id": "pleenglishcategory1-5_37",
                "type": "text"
            },
            {
                "question": "_____a hardworking girl Priscilla is!",
                "answer": "What",
                "mark": 1,
                "id": "pleenglishcategory1-5_38",
                "type": "text"
            },
            {
                "question": "The baby has malaria because she was _____ by mosquitoes.",
                "answer": "bitten",
                "mark": 1,
                "id": "pleenglishcategory1-5_39",
                "type": "text"
            },
            {
                "question": "Either a rope _____ a string is used to measure the length of a chalkboard.",
                "answer": "or",
                "mark": 1,
                "id": "pleenglishcategory1-5_40",
                "type": "text"
            },
            {
                "question": "Our headteacher doesnot want children who _____ against the wall.",
                "answer": "lean",
                "mark": 1,
                "id": "pleenglishcategory1-5_41",
                "type": "text"
            },
            {
                "question": "Maureen is related _____ our class teacher.",
                "answer": "to",
                "mark": 1,
                "id": "pleenglishcategory1-5_42",
                "type": "text"
            },
            {
                "question": "Waiswa wrote _____ article which appeared in the Newspaper lastweek.",
                "answer": "an",
                "mark": 1,
                "id": "pleenglishcategory1-5_43",
                "type": "text"
            },
            {
                "question": "To _____ should I address the issue of the children's rights?",
                "answer": "whom",
                "mark": 1,
                "id": "pleenglishcategory1-5_44",
                "type": "text"
            },
            {
                "question": "We _____ to her story attentively.",
                "answer": "listened",
                "mark": 1,
                "id": "pleenglishcategory1-5_45",
                "type": "text"
            },
            {
                "question": "If you follow instructions carefully, you cannot _____ mistakes.",
                "answer": "make",
                "mark": 1,
                "id": "pleenglishcategory1-5_46",
                "type": "text"
            },
            {
                "question": "When I had a painful _____ I went to a dentist for treatment.",
                "answer": "toothache",
                "mark": 1,
                "id": "pleenglishcategory1-5_47",
                "type": "text"
            },
            {
                "question": "Don't play with a razor blade because _____ can hurt you.",
                "answer": "it",
                "mark": 1,
                "id": "pleenglishcategory1-5_48",
                "type": "text"
            },
            {
                "question": "We should thank our teachers _____ teaching us well.",
                "answer": "for",
                "mark": 1,
                "id": "pleenglishcategory1-5_49",
                "type": "text"
            },
            {
                "question": "The carpenter will use a saw to _____ the wood into pieces.",
                "answer": "cut",
                "mark": 1,
                "id": "pleenglishcategory1-5_50",
                "type": "text"
            },
            {
                "question": "The man _____ defiled the little girl was imprisoned for life.",
                "answer": "who",
                "mark": 1,
                "id": "pleenglishcategory1-5_51",
                "type": "text"
            },
            {
                "question": "The letter \"e\" appears _____ in the word \"Wednesday\".",
                "answer": "twice",
                "mark": 1,
                "id": "pleenglishcategory1-5_52",
                "type": "text"
            },
            {
                "question": "What is clay used _____ ?",
                "answer": "for",
                "mark": 1,
                "id": "pleenglishcategory1-5_53",
                "type": "text"
            },
            {
                "question": "The barking of a _____ kept me awake last night.",
                "answer": "dog",
                "mark": 1,
                "id": "pleenglishcategory1-5_54",
                "type": "text"
            },
            {
                "question": "A teacher is as important _____ a doctor.",
                "answer": "as",
                "mark": 1,
                "id": "pleenglishcategory1-5_55",
                "type": "text"
            },
            {
                "question": "Mrs. Opolot _____ birth to twins yesterday.",
                "answer": "gave",
                "mark": 1,
                "id": "pleenglishcategory1-5_56",
                "type": "text"
            },
            {
                "question": "Our head teacher arrived at school late _____ of his bad foot.",
                "answer": "because",
                "mark": 1,
                "id": "pleenglishcategory1-5_57",
                "type": "text"
            },
            {
                "question": "Kato was accussed _____ stealing a pen.",
                "answer": "of",
                "mark": 1,
                "id": "pleenglishcategory1-5_58",
                "type": "text"
            },
            {
                "question": "Waiswa is _____ university student now.",
                "answer": "a",
                "mark": 1,
                "id": "pleenglishcategory1-5_59",
                "type": "text"
            },
            {
                "question": "_____ a wonderful day this is!",
                "answer": "What",
                "mark": 1,
                "id": "pleenglishcategory1-5_60",
                "type": "text"
            },
            {
                "question": "_____ a wonderful day this is!",
                "answer": "What",
                "mark": 1,
                "id": "pleenglishcategory1-5_61",
                "type": "text"
            },
            {
                "question": "_____ said prayers at the parade.",
                "answer": "He",
                "mark": 1,
                "id": "pleenglishcategory1-5_62",
                "type": "text"
            },
            {
                "question": "The boys _____ playing football when it started raining.",
                "answer": "were",
                "mark": 1,
                "id": "pleenglishcategory1-5_63",
                "type": "text"
            },
            {
                "question": "Brenda has been crying _____ two hours.",
                "answer": "for",
                "mark": 1,
                "id": "pleenglishcategory1-5_64",
                "type": "text"
            },
            {
                "question": "He is a famous leader about  _____ many books have been written.",
                "answer": "who",
                "mark": 1,
                "id": "pleenglishcategory1-5_65",
                "type": "text"
            },
            {
                "question": "If he had been careful, the accident _____ not have happened.",
                "answer": "would",
                "mark": 1,
                "id": "pleenglishcategory1-5_66",
                "type": "text"
            },
            {
                "question": "_____ she works hard, she will not pass the examination.",
                "answer": "Unless",
                "mark": 1,
                "id": "pleenglishcategory1-5_67",
                "type": "text"
            },
            {
                "question": "The driver lost his job because he _____ hardly read or write.",
                "answer": "could",
                "mark": 1,
                "id": "pleenglishcategory1-5_68",
                "type": "text"
            },
            {
                "question": "The athlete _____ won the gold medal died in a motor accident.",
                "answer": "who",
                "mark": 1,
                "id": "pleenglishcategory1-5_69",
                "type": "text"
            },
            {
                "question": "_____ you carry packed lunch, you will not find anything to eat on the way.",
                "answer": "Unless",
                "mark": 1,
                "id": "pleenglishcategory1-5_70",
                "type": "text"
            },
            {
                "question": "She travelled _____ train from Tororo to Mombasa.",
                "answer": "by",
                "mark": 1,
                "id": "pleenglishcategory1-5_71",
                "type": "text"
            },
            {
                "question": "If I went home late, I _____ find the gate locked.",
                "answer": "would",
                "mark": 1,
                "id": "pleenglishcategory1-5_72",
                "type": "text"
            },
            {
                "question": "I do not know _____ to go after P.7 ",
                "answer": "where",
                "mark": 1,
                "id": "pleenglishcategory1-5_73",
                "type": "text"
            },
            {
                "question": "The milk which was kept for the visitor was _____ by the cat.",
                "answer": "drunk",
                "mark": 1,
                "id": "pleenglishcategory1-5_74",
                "type": "text"
            },
            {
                "question": "She was smartly _____ for the party.",
                "answer": "dressed",
                "mark": 1,
                "id": "pleenglishcategory1-5_75",
                "type": "text"
            },
            {
                "question": "The local police was _____ to arrest the thief. ",
                "answer": "called",
                "mark": 1,
                "id": "pleenglishcategory1-5_76",
                "type": "text"
            },
            {
                "question": "Mum's _____ took place in her home village last Saturday.",
                "answer": "marriage",
                "mark": 1,
                "id": "pleenglishcategory1-5_77",
                "type": "text"
            },
            {
                "question": "Ochola is the _____ of the two boys.",
                "answer": "taller",
                "mark": 1,
                "id": "pleenglishcategory1-5_78",
                "type": "text"
            },
            {
                "question": "I was _____ to see a snake in my house.",
                "answer": "surprised",
                "mark": 1,
                "id": "pleenglishcategory1-5_79",
                "type": "text"
            },
            {
                "question": "I _____ not met my friend for two years.",
                "answer": "have",
                "mark": 1,
                "id": "pleenglishcategory1-5_80",
                "type": "text"
            },
            {
                "question": "_____ of these pens belongs to you?",
                "answer": "Which",
                "mark": 1,
                "id": "pleenglishcategory1-5_81",
                "type": "text"
            },
            {
                "question": "Mother loves her baby very much. _____ she?",
                "answer": "Doesn't",
                "mark": 1,
                "id": "pleenglishcategory1-5_82",
                "type": "text"
            },
            {
                "question": "He asked me _____ my name was.",
                "answer": "what",
                "mark": 1,
                "id": "pleenglishcategory1-5_83",
                "type": "text"
            },
            {
                "question": "Take care not to lean _____ that dirty wall.",
                "answer": "against",
                "mark": 1,
                "id": "pleenglishcategory1-5_84",
                "type": "text"
            },
            {
                "question": "He wanted to know _____ far Gulu was from Kampala.",
                "answer": "how",
                "mark": 1,
                "id": "pleenglishcategory1-5_85",
                "type": "text"
            },
            {
                "question": "She was charged _____ the responsibility of feeding the children.",
                "answer": "with",
                "mark": 1,
                "id": "pleenglishcategory1-5_86",
                "type": "text"
            },
            {
                "question": "My elder sister was _____ down by a car as she was crossing the road.",
                "answer": "knocked",
                "mark": 1,
                "id": "pleenglishcategory1-5_87",
                "type": "text"
            },
            {
                "question": "_____ should Ugandans do to lead a better life?",
                "answer": "What",
                "mark": 1,
                "id": "pleenglishcategory1-5_88",
                "type": "text"
            },
            {
                "question": "To _____ girl did you give the book?",
                "answer": "which",
                "mark": 1,
                "id": "pleenglishcategory1-5_89",
                "type": "text"
            }
        ]
    },
    {
        "instructions": "In each of the questions 6 to 15, use the correct form of the word given in the brackets to complete the sentence.",
        "category": 6,
        "numberOfQuestions ": 5,
        "questions": [
            {
                "question": "Aisha will buy _____ when she goes to Parombo market. (tomato)",
                "answer": "tomatoes",
                "mark": 1,
                "id": "pleenglishcategory6-15_1",
                "type": "text"
            },
            {
                "question": "The fishmonger _____ all his fish at a profit yesterday. (sell)",
                "answer": "sold",
                "mark": 1,
                "id": "pleenglishcategory6-15_2",
                "type": "text"
            },
            {
                "question": "The athlete was so _____ that he could not run any further. (thirst)",
                "answer": "thirsty",
                "mark": 1,
                "id": "pleenglishcategory6-15_3",
                "type": "text"
            },
            {
                "question": "Nakato is the _____ girl in our school. (strong)",
                "answer": "strongest",
                "mark": 1,
                "id": "pleenglishcategory6-15_4",
                "type": "text"
            },
            {
                "question": "Gene Victor was _____ his school uniform when the visitor arrived. (iron)",
                "answer": "ironing",
                "mark": 1,
                "id": "pleenglishcategory6-15_5",
                "type": "text"
            },
            {
                "question": "The accident occurred because of the _____ of the cyclist. (careless)",
                "answer": "carelessness",
                "mark": 1,
                "id": "pleenglishcategory6-15_6",
                "type": "text"
            },
            {
                "question": "Mother _____ breastfeeds the baby before she goes to work. (normal)",
                "answer": "normally",
                "mark": 1,
                "id": "pleenglishcategory6-15_7",
                "type": "text"
            },
            {
                "question": "My cousin is not a Kenyan but an _____. (Egypt)",
                "answer": "Egyptian",
                "mark": 1,
                "id": "pleenglishcategory6-15_8",
                "type": "text"
            },
            {
                "question": "The teacher was pleased with the old man's good _____ towards the school children. (behave)",
                "answer": "behaviour",
                "mark": 1,
                "id": "pleenglishcategory6-15_9",
                "type": "text"
            },
            {
                "question": "Our mock results were arranged in _____ order. (ascend)",
                "answer": "ascending",
                "mark": 1,
                "id": "pleenglishcategory6-15_10",
                "type": "text"
            },
            {
                "question": "Most urban areas are _____ populated. (dense)",
                "answer": "densely",
                "mark": 1,
                "id": "pleenglishcategory6-15_11",
                "type": "text"
            },
            {
                "question": "When the thief saw the policeman, he _____ in the bush. (hide)",
                "answer": "hid",
                "mark": 1,
                "id": "pleenglishcategory6-15_12",
                "type": "text"
            },
            {
                "question": "Andrina's little sister will do the homework by _____. (self)",
                "answer": "herself",
                "mark": 1,
                "id": "pleenglishcategory6-15_13",
                "type": "text"
            },
            {
                "question": "One should always ask for _____ if one makes a mistake. (forgive)",
                "answer": "forgiveness",
                "mark": 1,
                "id": "pleenglishcategory6-15_14",
                "type": "text"
            },
            {
                "question": "Who is _____ of the twins? (smart)",
                "answer": "smarter",
                "mark": 1,
                "id": "pleenglishcategory6-15_15",
                "type": "text"
            },
            {
                "question": "Reading a storybook is more _____ than writing an article. (enjoy)",
                "answer": "enjoyable",
                "mark": 1,
                "id": "pleenglishcategory6-15_16",
                "type": "text"
            },
            {
                "question": "Mr. Mugisha was given a prize for being the best _____ in the village. (farm)",
                "answer": "farmer",
                "mark": 1,
                "id": "pleenglishcategory6-15_17",
                "type": "text"
            },
            {
                "question": "Chelimo's father held  the baby _____ in his arms. (gentle)",
                "answer": "gently",
                "mark": 1,
                "id": "pleenglishcategory6-15_18",
                "type": "text"
            },
            {
                "question": "The class teacher punished the pupil for _____ her classmate during the meeting. (shame)",
                "answer": "shamming",
                "mark": 1,
                "id": "pleenglishcategory6-15_19",
                "type": "text"
            },
            {
                "question": "Our teachers do not like children who are _____. (quarrel)",
                "answer": "quarrelsome",
                "mark": 1,
                "id": "pleenglishcategory6-15_20",
                "type": "text"
            },
            {
                "question": "Alito Jonah handed in her homework neatly _____. (write)",
                "answer": "written",
                "mark": 1,
                "id": "pleenglishcategory6-15_21",
                "type": "text"
            },
            {
                "question": "I prefer _____ cakes to circular ones. (triangle)",
                "answer": "triangular",
                "mark": 1,
                "id": "pleenglishcategory6-15_22",
                "type": "text"
            },
            {
                "question": "Vivid Bank has a very hard working _____. (cash)",
                "answer": "cashier",
                "mark": 1,
                "id": "pleenglishcategory6-15_23",
                "type": "text"
            },
            {
                "question": "The hungry baby ate porridge _____. (hurry)",
                "answer": "hurriedly",
                "mark": 1,
                "id": "pleenglishcategory6-15_24",
                "type": "text"
            },
            {
                "question": "Banana fibres can be used for _____ firewood together. (tie)",
                "answer": "tying",
                "mark": 1,
                "id": "pleenglishcategory6-15_25",
                "type": "text"
            },
            {
                "question": "Many children lose _____ teeth because of eating a lot of sugary foods. (they)",
                "answer": "their",
                "mark": 1,
                "id": "pleenglishcategory6-15_26",
                "type": "text"
            },
            {
                "question": "Our librarian _____ me a very interesting story book last week. (lend)",
                "answer": "lent",
                "mark": 1,
                "id": "pleenglishcategory6-15_27",
                "type": "text"
            },
            {
                "question": "My classmates behaved very _____ on our sports day. (good)",
                "answer": "well",
                "mark": 1,
                "id": "pleenglishcategory6-15_28",
                "type": "text"
            },
            {
                "question": "Of the three girls who is the _____ ? (lazy)",
                "answer": "laziest",
                "mark": 1,
                "id": "pleenglishcategory6-15_29",
                "type": "text"
            },
            {
                "question": "Nakawombe Glaura has been humble since _____.  (child)",
                "answer": "childhood",
                "mark": 1,
                "id": "pleenglishcategory6-15_30",
                "type": "text"
            },
            {
                "question": "If Ayupo had _____ to kalangala, she would have eaten a lot of fish. (go)",
                "answer": "gone",
                "mark": 1,
                "id": "pleenglishcategory6-15_31",
                "type": "text"
            },
            {
                "question": "In Kampala, motorcycles move _____ than cars. (fast)",
                "answer": "faster",
                "mark": 1,
                "id": "pleenglishcategory6-15_32",
                "type": "text"
            },
            {
                "question": "Uncle Simon will celebrate his _____ birthday next week. (thirty)",
                "answer": "thirtieth",
                "mark": 1,
                "id": "pleenglishcategory6-15_33",
                "type": "text"
            },
            {
                "question": "We have completed second term _____ (success)",
                "answer": "successfully",
                "mark": 1,
                "id": "pleenglishcategory6-15_34",
                "type": "text"
            },
            {
                "question": "Godwin is the _____ boy in the class. (happy)",
                "answer": "happiest",
                "mark": 1,
                "id": "pleenglishcategory6-15_35",
                "type": "text"
            },
            {
                "question": "Lucy _____ to me that she ate my fruits. (lie)",
                "answer": "lied",
                "mark": 1,
                "id": "pleenglishcategory6-15_36",
                "type": "text"
            },
            {
                "question": "Children should pray for _____ everyday. (wise)",
                "answer": "wisdom",
                "mark": 1,
                "id": "pleenglishcategory6-15_37",
                "type": "text"
            },
            {
                "question": "Matovu will buy a _____ blanket for his brother. (wool)",
                "answer": "woolen",
                "mark": 1,
                "id": "pleenglishcategory6-15_38",
                "type": "text"
            },
            {
                "question": "I will give _____ story books to the Assistant Head boy after PLE. (mine)",
                "answer": "my",
                "mark": 1,
                "id": "pleenglishcategory6-15_39",
                "type": "text"
            },
            {
                "question": "The visitors were given a wonderful _____ at our home. (receive)",
                "answer": "reception",
                "mark": 1,
                "id": "pleenglishcategory6-15_40",
                "type": "text"
            },
            {
                "question": "It is dangerous to live in _____ areas. (swamp)",
                "answer": "swampy",
                "mark": 1,
                "id": "pleenglishcategory6-15_41",
                "type": "text"
            },
            {
                "question": "The guide warned us that there were many _____ snakes in the forest. (poison)",
                "answer": "poisonous",
                "mark": 1,
                "id": "pleenglishcategory6-15_42",
                "type": "text"
            },
            {
                "question": "I have already _____ some seats in the bus for my friends. (book)",
                "answer": "booked",
                "mark": 1,
                "id": "pleenglishcategory6-15_43",
                "type": "text"
            },
            {
                "question": "Our teachers have been so _____ to us (help)",
                "answer": "helpful",
                "mark": 1,
                "id": "pleenglishcategory6-15_44",
                "type": "text"
            },
            {
                "question": "It is _____ to complete studies than drop out of school (good)",
                "answer": "better",
                "mark": 1,
                "id": "pleenglishcategory6-15_45",
                "type": "text"
            },
            {
                "question": "The mother who _____ tortured her son has been arrested (mercy)",
                "answer": "mercilessly",
                "mark": 1,
                "id": "pleenglishcategory6-15_46",
                "type": "text"
            },
            {
                "question": "Alecho Shian took the _____ position in the spelling bee (nine)",
                "answer": "ninth",
                "mark": 1,
                "id": "pleenglishcategory6-15_47",
                "type": "text"
            },
            {
                "question": "The bride was _____ dressed in her bridal gown when she went to church (smart)",
                "answer": "smartly",
                "mark": 1,
                "id": "pleenglishcategory6-15_48",
                "type": "text"
            },
            {
                "question": "That baby _____ a lot whenever his mother is away (cry)",
                "answer": "cries",
                "mark": 1,
                "id": "pleenglishcategory6-15_49",
                "type": "text"
            },
            {
                "question": "I visited the zoo _____ during Easter holidays (one)",
                "answer": "once",
                "mark": 1,
                "id": "pleenglishcategory6-15_50",
                "type": "text"
            },
            {
                "question": "Luganda is _____ spoken in Uganda. (wide)",
                "answer": "widely",
                "mark": 1,
                "id": "pleenglishcategory6-15_51",
                "type": "text"
            },
            {
                "question": "My friend was _____ on the ear by a wasp. (sting)",
                "answer": "stung",
                "mark": 1,
                "id": "pleenglishcategory6-15_52",
                "type": "text"
            },
            {
                "question": "The plumber reported the _____ of his identity card to the police. (lose)",
                "answer": "loss",
                "mark": 1,
                "id": "pleenglishcategory6-15_53",
                "type": "text"
            },
            {
                "question": "There are _____ teachers than doctors in our country. (many)",
                "answer": "more",
                "mark": 1,
                "id": "pleenglishcategory6-15_54",
                "type": "text"
            },
            {
                "question": "He married a _____ wife (beauty)",
                "answer": "beautiful",
                "mark": 1,
                "id": "pleenglishcategory6-15_55",
                "type": "text"
            },
            {
                "question": "The man who repaired our computer is a _____ national (Kenya)",
                "answer": "Kenyan",
                "mark": 1,
                "id": "pleenglishcategory6-15_56",
                "type": "text"
            },
            {
                "question": "Abaho is _____ with the shopkeeper to reduce the price of a shirt (bargain)",
                "answer": "bargaining",
                "mark": 1,
                "id": "pleenglishcategory6-15_57",
                "type": "text"
            },
            {
                "question": "Disciplined children _____ perform well in class. (usual)",
                "answer": "usually",
                "mark": 1,
                "id": "pleenglishcategory6-15_58",
                "type": "text"
            },
            {
                "question": "I like my teacher of English because of his _____ during lessons. (explain)",
                "answer": "explaination",
                "mark": 1,
                "id": "pleenglishcategory6-15_59",
                "type": "text"
            },
            {
                "question": "Our school had a _____ sports day. (colour)",
                "answer": "colourful",
                "mark": 1,
                "id": "pleenglishcategory6-15_60",
                "type": "text"
            },
            {
                "question": "Everybody was amused by the skilfull _____ during the concert (dance)",
                "answer": "dancing",
                "mark": 1,
                "id": "pleenglishcategory6-15_61",
                "type": "text"
            },
            {
                "question": "The cat had _____ all the milk by the time Peter arrived. (drink)",
                "answer": "drunk",
                "mark": 1,
                "id": "pleenglishcategory6-15_62",
                "type": "text"
            },
            {
                "question": "Of the two boys, Tom is the _____ . (clever)",
                "answer": "cleverer",
                "mark": 1,
                "id": "pleenglishcategory6-15_63",
                "type": "text"
            },
            {
                "question": "The teacher on duty wanted to know our class _____ .(attend)",
                "answer": "attendance",
                "mark": 1,
                "id": "pleenglishcategory6-15_64",
                "type": "text"
            },
            {
                "question": "Michael told us an _____ story during lunch break. (interest)",
                "answer": "interesting",
                "mark": 1,
                "id": "pleenglishcategory6-15_65",
                "type": "text"
            },
            {
                "question": "The name of our school was printed _____ on the question paper envelop. (clear)",
                "answer": "clearly",
                "mark": 1,
                "id": "pleenglishcategory6-15_66",
                "type": "text"
            },
            {
                "question": "We cared for _____ when our mother was sick. (self)",
                "answer": "ourselves",
                "mark": 1,
                "id": "pleenglishcategory6-15_67",
                "type": "text"
            },
            {
                "question": "The boy who got the _____ mark was not promoted to the next class. (little)",
                "answer": "least",
                "mark": 1,
                "id": "pleenglishcategory6-15_68",
                "type": "text"
            },
            {
                "question": "The planted flowers will add _____ to the school compound. (beautiful)",
                "answer": "beauty",
                "mark": 1,
                "id": "pleenglishcategory6-15_69",
                "type": "text"
            },
            {
                "question": "Phionah was writing a _____ about the debating club. (compose)",
                "answer": "composition",
                "mark": 1,
                "id": "pleenglishcategory6-15_70",
                "type": "text"
            },
            {
                "question": "We shall be watching educative films _____ in a month. (two)",
                "answer": "twice",
                "mark": 1,
                "id": "pleenglishcategory6-15_71",
                "type": "text"
            },
            {
                "question": "The motorcycle _____ took off before they were caught. (rob)",
                "answer": "robbers",
                "mark": 1,
                "id": "pleenglishcategory6-15_72",
                "type": "text"
            },
            {
                "question": "The traffic police is _____ the lorry because it is overloaded. (stop)",
                "answer": "stopping",
                "mark": 1,
                "id": "pleenglishcategory6-15_73",
                "type": "text"
            },
            {
                "question": "John bought a _____ bed for himself. (wood)",
                "answer": "wooden",
                "mark": 1,
                "id": "pleenglishcategory6-15_74",
                "type": "text"
            },
            {
                "question": "The teacher _____ identical twins. (bear)",
                "answer": "bore",
                "mark": 1,
                "id": "pleenglishcategory6-15_75",
                "type": "text"
            },
            {
                "question": "It is _____ to send an e-mail than to post a letter. (easy)",
                "answer": "easier",
                "mark": 1,
                "id": "pleenglishcategory6-15_76",
                "type": "text"
            },
            {
                "question": "The villagers constructed the wall _____ .(willing)",
                "answer": "willingly",
                "mark": 1,
                "id": "pleenglishcategory6-15_77",
                "type": "text"
            },
            {
                "question": "All the girls will clean the classroom by _____ .(them)",
                "answer": "themselves",
                "mark": 1,
                "id": "pleenglishcategory6-15_78",
                "type": "text"
            },
            {
                "question": "The chief guest will be _____ by our head prefect. (welcome)",
                "answer": "welcomed",
                "mark": 1,
                "id": "pleenglishcategory6-15_79",
                "type": "text"
            },
            {
                "question": "December is usually the _____ month of the year.(hot)",
                "answer": "hottest",
                "mark": 1,
                "id": "pleenglishcategory6-15_80",
                "type": "text"
            },
            {
                "question": "Bahati asked for _____ to be absent from class. (permit)",
                "answer": "permission",
                "mark": 1,
                "id": "pleenglishcategory6-15_81",
                "type": "text"
            },
            {
                "question": "The rapist who defiled the young girl has been _____ .(imprison)",
                "answer": "imprisoned",
                "mark": 1,
                "id": "pleenglishcategory6-15_82",
                "type": "text"
            },
            {
                "question": "Children need _____ and love in order to grow well. (warm)",
                "answer": "warmth",
                "mark": 1,
                "id": "pleenglishcategory6-15_83",
                "type": "text"
            },
            {
                "question": "There are a lot of _____ crawling on his clothes. (louse)",
                "answer": "lice",
                "mark": 1,
                "id": "pleenglishcategory6-15_84",
                "type": "text"
            },
            {
                "question": "We still have a few _____ gold medalists. (Uganda)",
                "answer": "Ugandan",
                "mark": 1,
                "id": "pleenglishcategory6-15_85",
                "type": "text"
            },
            {
                "question": "There will be a _____ ceremony in our village tomorrow. (bury)",
                "answer": "burial",
                "mark": 1,
                "id": "pleenglishcategory6-15_86",
                "type": "text"
            },
            {
                "question": "The smart boy kept on _____ his clothes throughout the ceremony (dirty)",
                "answer": "dirtying",
                "mark": 1,
                "id": "pleenglishcategory6-15_87",
                "type": "text"
            },
            {
                "question": "Our teacher spoke _____ about children\u2019s education and responsibilities. (clear)",
                "answer": "clearly",
                "mark": 1,
                "id": "pleenglishcategory6-15_88",
                "type": "text"
            },
            {
                "question": "Some children are _____ that their stepmothers care for them well. (thank)",
                "answer": "thankful",
                "mark": 1,
                "id": "pleenglishcategory6-15_89",
                "type": "text"
            },
            {
                "question": "Most teachers arrange class registers _____ .(alphabet)",
                "answer": "alphabetically",
                "mark": 1,
                "id": "pleenglishcategory6-15_90",
                "type": "text"
            },
            {
                "question": "That cyclist is riding with a lot of _____ on that busy road(carefully)",
                "answer": "carefulness",
                "mark": 1,
                "id": "pleenglishcategory6-15_91",
                "type": "text"
            },
            {
                "question": "The triplets opened the door by _____ .(them)",
                "answer": "themselves",
                "mark": 1,
                "id": "pleenglishcategory6-15_92",
                "type": "text"
            },
            {
                "question": "The nurse will _____ all children who are below five years of age. (immunity)",
                "answer": "immunise",
                "mark": 1,
                "id": "pleenglishcategory6-15_93",
                "type": "text"
            },
            {
                "question": "Ainomugisha is _____ her teeth now. (brush)",
                "answer": "brushing",
                "mark": 1,
                "id": "pleenglishcategory6-15_94",
                "type": "text"
            },
            {
                "question": "Pupils who are _____ to their teachers usually succeeded in their studies. (obey)",
                "answer": "obedient",
                "mark": 1,
                "id": "pleenglishcategory6-15_95",
                "type": "text"
            },
            {
                "question": "Which book gives the correct _____ of each word? (pronounce)",
                "answer": "pronounciation",
                "mark": 1,
                "id": "pleenglishcategory6-15_96",
                "type": "text"
            },
            {
                "question": "The _____ of the twins was given a prize. (smart)",
                "answer": "smarter",
                "mark": 1,
                "id": "pleenglishcategory6-15_97",
                "type": "text"
            },
            {
                "question": "The bride _____ down to greet her husband. (kneel)",
                "answer": "knelt",
                "mark": 1,
                "id": "pleenglishcategory6-15_98",
                "type": "text"
            },
            {
                "question": "Charlotte has baked very _____ cakes today. (taste)",
                "answer": "tasteful",
                "mark": 1,
                "id": "pleenglishcategory6-15_99",
                "type": "text"
            },
            {
                "question": "There will be very many _____ at the party. (waiter)",
                "answer": "waiters",
                "mark": 1,
                "id": "pleenglishcategory6-15_100",
                "type": "text"
            },
            {
                "question": "Tom shouted _____ at the referee who denied their team a penalty kick. (angry)",
                "answer": "angrily",
                "mark": 1,
                "id": "pleenglishcategory6-15_101",
                "type": "text"
            },
            {
                "question": "The examination was _____ done. (good)",
                "answer": "well",
                "mark": 1,
                "id": "pleenglishcategory6-15_102",
                "type": "text"
            },
            {
                "question": "Katooko has baked only _____ cakes. (circle)",
                "answer": "circular",
                "mark": 1,
                "id": "pleenglishcategory6-15_103",
                "type": "text"
            },
            {
                "question": "What a _____ day it was! (rain)",
                "answer": "rainy",
                "mark": 1,
                "id": "pleenglishcategory6-15_104",
                "type": "text"
            },
            {
                "question": "How many _____ did Mr. Bukenya have in his farm? (sheep)",
                "answer": "sheep",
                "mark": 1,
                "id": "pleenglishcategory6-15_105",
                "type": "text"
            },
            {
                "question": "Nobody likes Peter because he does not tell the _____ .(true)",
                "answer": "truth",
                "mark": 1,
                "id": "pleenglishcategory6-15_106",
                "type": "text"
            },
            {
                "question": "Having _____ his letter, Joshua posted it to his father. (write)",
                "answer": "written",
                "mark": 1,
                "id": "pleenglishcategory6-15_107",
                "type": "text"
            },
            {
                "question": "I am the _____ born of my mother . (two)",
                "answer": "second",
                "mark": 1,
                "id": "pleenglishcategory6-15_108",
                "type": "text"
            },
            {
                "question": "At least one plane _____ over our school everyday.(fly)",
                "answer": "flies",
                "mark": 1,
                "id": "pleenglishcategory6-15_109",
                "type": "text"
            },
            {
                "question": "He was successful because of _____ hard work.(he)",
                "answer": "his",
                "mark": 1,
                "id": "pleenglishcategory6-15_110",
                "type": "text"
            },
            {
                "question": "Nyapendi _____ her school bag every Saturday.(wash)",
                "answer": "washes",
                "mark": 1,
                "id": "pleenglishcategory6-15_111",
                "type": "text"
            },
            {
                "question": "Most of the mourners were late for the _____ .(bury)",
                "answer": "burial",
                "mark": 1,
                "id": "pleenglishcategory6-15_112",
                "type": "text"
            },
            {
                "question": "Some _____ often fail to observe the traffic regulations. (cycle)",
                "answer": "cyclists",
                "mark": 1,
                "id": "pleenglishcategory6-15_113",
                "type": "text"
            },
            {
                "question": "Helping the needy in our community is _____ .(volunteer)",
                "answer": "voluntary",
                "mark": 1,
                "id": "pleenglishcategory6-15_114",
                "type": "text"
            },
            {
                "question": "My younger sister is _____ than my elder sister.(pretty)",
                "answer": "prettier",
                "mark": 1,
                "id": "pleenglishcategory6-15_115",
                "type": "text"
            },
            {
                "question": "Trees drop off their _____ in the dry season. (leaf)",
                "answer": "leaves",
                "mark": 1,
                "id": "pleenglishcategory6-15_116",
                "type": "text"
            },
            {
                "question": "The tourist _____ climbed the mountain up to the highest peak. (slow)",
                "answer": "slowly",
                "mark": 1,
                "id": "pleenglishcategory6-15_117",
                "type": "text"
            },
            {
                "question": "When the visitors arrived, Bruno was _____ a story book.(read)",
                "answer": "reading",
                "mark": 1,
                "id": "pleenglishcategory6-15_118",
                "type": "text"
            },
            {
                "question": "Akumu ran quite fast and she was the _____ in the race.(one)",
                "answer": "first",
                "mark": 1,
                "id": "pleenglishcategory6-15_119",
                "type": "text"
            },
            {
                "question": "The bride and bridegroom _____ each other after their vows. (hold)",
                "answer": "held",
                "mark": 1,
                "id": "pleenglishcategory6-15_120",
                "type": "text"
            },
            {
                "question": "The community member repaired the bridge _____ .(voluntary)",
                "answer": "voluntarily",
                "mark": 1,
                "id": "pleenglishcategory6-15_121",
                "type": "text"
            },
            {
                "question": "Candiru is liked by many people due to her _____ . (faithful)",
                "answer": "faithfulness",
                "mark": 1,
                "id": "pleenglishcategory6-15_122",
                "type": "text"
            },
            {
                "question": "Our teacher of English is a very _____ lady.(knowledge)",
                "answer": "knowledgeable",
                "mark": 1,
                "id": "pleenglishcategory6-15_123",
                "type": "text"
            },
            {
                "question": "We found the nurse _____ than we expected. (kind)",
                "answer": "kinder",
                "mark": 1,
                "id": "pleenglishcategory6-15_124",
                "type": "text"
            },
            {
                "question": "Asil was given a _____ blanket as a reward for hard work. (wool)",
                "answer": "woolen",
                "mark": 1,
                "id": "pleenglishcategory6-15_125",
                "type": "text"
            },
            {
                "question": "He scored high marks because his composition was _____ written. (correct)",
                "answer": "correctly",
                "mark": 1,
                "id": "pleenglishcategory6-15_126",
                "type": "text"
            },
            {
                "question": "The old man weaves his basket _____ .(skill)",
                "answer": "skillfully",
                "mark": 1,
                "id": "pleenglishcategory6-15_127",
                "type": "text"
            },
            {
                "question": "The dead body of our village chief was _____ To rest at 4.00 o\u2019clock yesterday. (lay)",
                "answer": "laid",
                "mark": 1,
                "id": "pleenglishcategory6-15_128",
                "type": "text"
            },
            {
                "question": "The doctor who treated you yesterday is my _____ .(guard)",
                "answer": "guardian",
                "mark": 1,
                "id": "pleenglishcategory6-15_129",
                "type": "text"
            },
            {
                "question": "Before his _____  , he visited many hospitals. (die)",
                "answer": "death",
                "mark": 1,
                "id": "pleenglishcategory6-15_130",
                "type": "text"
            },
            {
                "question": "The headmaster's _____ was very complicated. (sign)",
                "answer": "signature",
                "mark": 1,
                "id": "pleenglishcategory6-15_131",
                "type": "text"
            },
            {
                "question": "Lwantale _____ to the teacher for coming late. (apology)",
                "answer": "apologised",
                "mark": 1,
                "id": "pleenglishcategory6-15_132",
                "type": "text"
            },
            {
                "question": "Warm clothes are _____ by people in cold areas. (wear)",
                "answer": "worn",
                "mark": 1,
                "id": "pleenglishcategory6-15_133",
                "type": "text"
            },
            {
                "question": "It rained _____ yesterday. (heavy)",
                "answer": "heavily",
                "mark": 1,
                "id": "pleenglishcategory6-15_134",
                "type": "text"
            },
            {
                "question": "Our class prefect is a very _____ girl. (beauty)",
                "answer": "beautiful",
                "mark": 1,
                "id": "pleenglishcategory6-15_135",
                "type": "text"
            },
            {
                "question": "Bad weather delayed the _____ of the plane. (depart)",
                "answer": "departure",
                "mark": 1,
                "id": "pleenglishcategory6-15_136",
                "type": "text"
            },
            {
                "question": "I _____ very happy to receive your invitation letter last week. (be)",
                "answer": "was",
                "mark": 1,
                "id": "pleenglishcategory6-15_137",
                "type": "text"
            },
            {
                "question": "I told him to stop _____ rotten mangoes. (eat)",
                "answer": "eating",
                "mark": 1,
                "id": "pleenglishcategory6-15_138",
                "type": "text"
            },
            {
                "question": "We should not be afraid of snakes because most of them are not _____ (poison)",
                "answer": "poisonous",
                "mark": 1,
                "id": "pleenglishcategory6-15_139",
                "type": "text"
            },
            {
                "question": "The priest prayed _____ in his heart.(silent)",
                "answer": "silently",
                "mark": 1,
                "id": "pleenglishcategory6-15_140",
                "type": "text"
            },
            {
                "question": "Ofwono's son passed in _____ .(divide)",
                "answer": "division",
                "mark": 1,
                "id": "pleenglishcategory6-15_141",
                "type": "text"
            },
            {
                "question": "He got lost because he had _____ the way to the clinic. (forget)",
                "answer": "forgotten",
                "mark": 1,
                "id": "pleenglishcategory6-15_142",
                "type": "text"
            },
            {
                "question": "This book is _____ than that one. (good)",
                "answer": "better",
                "mark": 1,
                "id": "pleenglishcategory6-15_143",
                "type": "text"
            },
            {
                "question": "The more he becomes hungry the _____ he pays attention to the teacher. (little)",
                "answer": "less",
                "mark": 1,
                "id": "pleenglishcategory6-15_144",
                "type": "text"
            },
            {
                "question": "Our parents talk with _____ about our school. (pride)",
                "answer": "less",
                "mark": 1,
                "id": "pleenglishcategory6-15_145",
                "type": "text"
            },
            {
                "question": "His wife ran away because of his _____ .(cruel)",
                "answer": "cruelty",
                "mark": 1,
                "id": "pleenglishcategory6-15_146",
                "type": "text"
            },
            {
                "question": "My father always _____ me to work hard in class. (courage)",
                "answer": "encourages",
                "mark": 1,
                "id": "pleenglishcategory6-15_147",
                "type": "text"
            },
            {
                "question": "My sister\u2019s handwriting is _____ good. (exception)",
                "answer": "exceptionally",
                "mark": 1,
                "id": "pleenglishcategory6-15_148",
                "type": "text"
            },
            {
                "question": "Opolot\u2019s house has _____ windows. (wood)",
                "answer": "wooden",
                "mark": 1,
                "id": "pleenglishcategory6-15_149",
                "type": "text"
            },
            {
                "question": "He _____ on the old man\u2019s face accidentally. (spit).",
                "answer": "spat",
                "mark": 1,
                "id": "pleenglishcategory6-15_150",
                "type": "text"
            },
            {
                "question": "She told him not to _____ her book .(dirt)",
                "answer": "dirty",
                "mark": 1,
                "id": "pleenglishcategory6-15_151",
                "type": "text"
            },
            {
                "question": "It is the job of the _____ to prepare the food early. (cooking)",
                "answer": "cook",
                "mark": 1,
                "id": "pleenglishcategory6-15_152",
                "type": "text"
            },
            {
                "question": "Of all the stories I have heard, this one is the _____ .(funny)",
                "answer": "funniest",
                "mark": 1,
                "id": "pleenglishcategory6-15_153",
                "type": "text"
            },
            {
                "question": "John locked the room and counted his money _____ .(private)",
                "answer": "privately",
                "mark": 1,
                "id": "pleenglishcategory6-15_154",
                "type": "text"
            },
            {
                "question": "It is important to have a high standard of _____ in a home. (clean)",
                "answer": "cleanliness",
                "mark": 1,
                "id": "pleenglishcategory6-15_155",
                "type": "text"
            },
            {
                "question": "Sironko is one of the _____ districts in Uganda (mountain)",
                "answer": "mountaineous",
                "mark": 1,
                "id": "pleenglishcategory6-15_156",
                "type": "text"
            },
            {
                "question": "She got a _____ from the shopkeeper after paying for the dress. (receive)",
                "answer": "receipt",
                "mark": 1,
                "id": "pleenglishcategory6-15_157",
                "type": "text"
            },
            {
                "question": "That food is very _____ .(salt)",
                "answer": "salty",
                "mark": 1,
                "id": "pleenglishcategory6-15_158",
                "type": "text"
            },
            {
                "question": "She dressed _____ for the party. (smart)",
                "answer": "smartly",
                "mark": 1,
                "id": "pleenglishcategory6-15_159",
                "type": "text"
            },
            {
                "question": "The candidates answered the questions _____ .(proper)",
                "answer": "properly",
                "mark": 1,
                "id": "pleenglishcategory6-15_160",
                "type": "text"
            },
            {
                "question": "He was advised to change his _____ .(behave)",
                "answer": "behaviour",
                "mark": 1,
                "id": "pleenglishcategory6-15_161",
                "type": "text"
            },
            {
                "question": "Mutumba _____ a good song last week. (sing)",
                "answer": "sang",
                "mark": 1,
                "id": "pleenglishcategory6-15_162",
                "type": "text"
            },
            {
                "question": "The new students of Kings College Buddo have received their _____ letters (admit)",
                "answer": "admission",
                "mark": 1,
                "id": "pleenglishcategory6-15_163",
                "type": "text"
            },
            {
                "question": "She is the _____ of the two girls. (clever)",
                "answer": "cleverest",
                "mark": 1,
                "id": "pleenglishcategory6-15_164",
                "type": "text"
            },
            {
                "question": "The new words were _____ spelt by her. (correct)",
                "answer": "correctly",
                "mark": 1,
                "id": "pleenglishcategory6-15_165",
                "type": "text"
            },
            {
                "question": "The boy was sent away from school because he was _____ .(hope)",
                "answer": "hopeless",
                "mark": 1,
                "id": "pleenglishcategory6-15_166",
                "type": "text"
            },
            {
                "question": "The more the teachers talked, the _____ I understood. (little)",
                "answer": "least",
                "mark": 1,
                "id": "pleenglishcategory6-15_167",
                "type": "text"
            },
            {
                "question": "Woolen clothes are mostly _____ by people. (wear)",
                "answer": "worn",
                "mark": 1,
                "id": "pleenglishcategory6-15_168",
                "type": "text"
            },
            {
                "question": "It is _____ to rain today. (like)",
                "answer": "likely",
                "mark": 1,
                "id": "pleenglishcategory6-15_169",
                "type": "text"
            },
            {
                "question": "I can not wear this because it is not _____ .(my)",
                "answer": "mine",
                "mark": 1,
                "id": "pleenglishcategory6-15_170",
                "type": "text"
            },
            {
                "question": "A mango is more _____ than an apple. (juice)",
                "answer": "juicy",
                "mark": 1,
                "id": "pleenglishcategory6-15_171",
                "type": "text"
            },
            {
                "question": "My brother\u2019s _____ with Mary did not last long. (marry)",
                "answer": "marriage",
                "mark": 1,
                "id": "pleenglishcategory6-15_172",
                "type": "text"
            },
            {
                "question": "The old woman has been _____ on her bed for the last two days.(lie)",
                "answer": "lying",
                "mark": 1,
                "id": "pleenglishcategory6-15_173",
                "type": "text"
            },
            {
                "question": "Our chief was _____ injured in the accident. (critical)",
                "answer": "critically",
                "mark": 1,
                "id": "pleenglishcategory6-15_174",
                "type": "text"
            },
            {
                "question": "John could not tell the _____ between the two books. (similar)",
                "answer": "similarlity",
                "mark": 1,
                "id": "pleenglishcategory6-15_175",
                "type": "text"
            },
            {
                "question": "A lion is a _____ animal.(danger)",
                "answer": "dangerous",
                "mark": 1,
                "id": "pleenglishcategory6-15_176",
                "type": "text"
            },
            {
                "question": "The _____ of the old man has helped people in the village to develop. (wise)",
                "answer": "wisdom",
                "mark": 1,
                "id": "pleenglishcategory6-15_177",
                "type": "text"
            },
            {
                "question": "A snake _____ him last week. (bite)",
                "answer": "bit",
                "mark": 1,
                "id": "pleenglishcategory6-15_178",
                "type": "text"
            },
            {
                "question": "The girl _____ down and greeted her parents. (kneel)",
                "answer": "knelt",
                "mark": 1,
                "id": "pleenglishcategory6-15_179",
                "type": "text"
            }
        ]
    },
    {
        "instructions": "In each of the questions 16 to 17, drag and drop the given words in alphabetical order.",
        "category": 16,
        "numberOfQuestions ": 2,
        "questions": [
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_1",
                "type": "dragAndDrop",
                "question": [
                    "near",
                    "far",
                    "after",
                    "before"
                ],
                "answer": [
                    "after",
                    "before",
                    "far",
                    "near"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_2",
                "type": "dragAndDrop",
                "question": [
                    "purple",
                    "brown",
                    "pink",
                    "blue"
                ],
                "answer": [
                    "blue",
                    "brown",
                    "pink",
                    "purple"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_3",
                "type": "dragAndDrop",
                "question": [
                    "potato",
                    "yam",
                    "rice",
                    "millet"
                ],
                "answer": [
                    "millet",
                    "potato",
                    "rice",
                    "yam"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_4",
                "type": "dragAndDrop",
                "question": [
                    "chalkboard",
                    "cupboard",
                    "cup",
                    "chalk"
                ],
                "answer": [
                    "chalk",
                    "chalkboard",
                    "cup",
                    "cupboard"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_5",
                "type": "dragAndDrop",
                "question": [
                    "scared",
                    "happy",
                    "worried",
                    "unhappy"
                ],
                "answer": [
                    "happy",
                    "scared",
                    "unhappy",
                    "worried"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_6",
                "type": "dragAndDrop",
                "question": [
                    "revise",
                    "resource",
                    "recall",
                    "receive"
                ],
                "answer": [
                    "recall",
                    "receive",
                    "revise",
                    "resource"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_7",
                "type": "dragAndDrop",
                "question": [
                    "receive",
                    "respect",
                    "receipt",
                    "restaurant"
                ],
                "answer": [
                    "receipt",
                    "receive",
                    "restaurant",
                    "respect"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_8",
                "type": "dragAndDrop",
                "question": [
                    "potatoes",
                    "peas",
                    "pawpaws",
                    "pineapples"
                ],
                "answer": [
                    "pawpaws",
                    "peas",
                    "pineapples",
                    "potatoes"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_9",
                "type": "dragAndDrop",
                "question": [
                    "shine",
                    "shape",
                    "shade",
                    "ship"
                ],
                "answer": [
                    "shade",
                    "shape",
                    "shine",
                    "ship"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_10",
                "type": "dragAndDrop",
                "question": [
                    "Thursday",
                    "Sunday",
                    "Saturday",
                    "Tuesday"
                ],
                "answer": [
                    "Saturday",
                    "Sunday",
                    "Thursday",
                    "Tuesday"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_11",
                "type": "dragAndDrop",
                "question": [
                    "cassava",
                    "yam",
                    "potatoes",
                    "maize"
                ],
                "answer": [
                    "cassava",
                    "maize",
                    "potatoes",
                    "yam"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_12",
                "type": "dragAndDrop",
                "question": [
                    "river",
                    "rain",
                    "roof",
                    "rise"
                ],
                "answer": [
                    "rain",
                    "rise",
                    "river",
                    "roof"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_13",
                "type": "dragAndDrop",
                "question": [
                    "sand",
                    "sugar",
                    "soap",
                    "sink"
                ],
                "answer": [
                    "sand",
                    "sink",
                    "soap",
                    "sugar"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_14",
                "type": "dragAndDrop",
                "question": [
                    "begin",
                    "befriend",
                    "because",
                    "belong"
                ],
                "answer": [
                    "because",
                    "befriend",
                    "begin",
                    "belong"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_15",
                "type": "dragAndDrop",
                "question": [
                    "Mbarara",
                    "Kampala",
                    "Jinja",
                    "Lira"
                ],
                "answer": [
                    "Jinja",
                    "Kampala",
                    "Lira",
                    "Mbarara"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_16",
                "type": "dragAndDrop",
                "question": [
                    "blunt",
                    "blink",
                    "blast",
                    "block"
                ],
                "answer": [
                    "blast",
                    "blink",
                    "block",
                    "blunt"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_17",
                "type": "dragAndDrop",
                "question": [
                    "smoke",
                    "sky",
                    "snow",
                    "scream"
                ],
                "answer": [
                    "scream",
                    "sky",
                    "smoke",
                    "snow"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_18",
                "type": "dragAndDrop",
                "question": [
                    "double",
                    "donate",
                    "doze",
                    "dose"
                ],
                "answer": [
                    "donate",
                    "dose",
                    "double",
                    "doze"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_19",
                "type": "dragAndDrop",
                "question": [
                    "orange",
                    "lemon",
                    "pawpaw",
                    "mango"
                ],
                "answer": [
                    "lemon",
                    "mango",
                    "orange",
                    "pawpaw"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_20",
                "type": "dragAndDrop",
                "question": [
                    "display",
                    "dislike",
                    "distance",
                    "discuss"
                ],
                "answer": [
                    "discuss",
                    "dislike",
                    "distance",
                    "display"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_21",
                "type": "dragAndDrop",
                "question": [
                    "turkey",
                    "dove",
                    "pigeon",
                    "eagle"
                ],
                "answer": [
                    "dove",
                    "eagle",
                    "pigeon",
                    "turkey"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_22",
                "type": "dragAndDrop",
                "question": [
                    "reality",
                    "reason",
                    "readily",
                    "reaction"
                ],
                "answer": [
                    "reaction",
                    "readily",
                    "reason",
                    "reality"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_23",
                "type": "dragAndDrop",
                "question": [
                    "dislike",
                    "district",
                    "discover",
                    "disobey"
                ],
                "answer": [
                    "disobey",
                    "district",
                    "discover",
                    "dislike"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_24",
                "type": "dragAndDrop",
                "question": [
                    "corporation",
                    "correction",
                    "corner",
                    "curtain"
                ],
                "answer": [
                    "corner",
                    "corporation",
                    "correction",
                    "curtain"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_25",
                "type": "dragAndDrop",
                "question": [
                    "blank",
                    "blade",
                    "black",
                    "blanket"
                ],
                "answer": [
                    "black",
                    "blade",
                    "blanket",
                    "blank"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_26",
                "type": "dragAndDrop",
                "question": [
                    "pain",
                    "pair",
                    "pail",
                    "paint"
                ],
                "answer": [
                    "pail",
                    "pair",
                    "pain",
                    "paint"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_27",
                "type": "dragAndDrop",
                "question": [
                    "pain",
                    "hair",
                    "care",
                    "fare"
                ],
                "answer": [
                    "care",
                    "fare",
                    "hair",
                    "pain"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_28",
                "type": "dragAndDrop",
                "question": [
                    "bun",
                    "bake",
                    "biscuit",
                    "banana"
                ],
                "answer": [
                    "bake",
                    "banana",
                    "biscuit",
                    "bun"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_29",
                "type": "dragAndDrop",
                "question": [
                    "some",
                    "seed",
                    "sad",
                    "sun"
                ],
                "answer": [
                    "sad",
                    "seed",
                    "some",
                    "sun"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_30",
                "type": "dragAndDrop",
                "question": [
                    "unhappy",
                    "disobey",
                    "unkind",
                    "dishonest"
                ],
                "answer": [
                    "disobey",
                    "dishonest",
                    "unkind",
                    "unhappy"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_31",
                "type": "dragAndDrop",
                "question": [
                    "pardon",
                    "parent",
                    "parcel",
                    "parallel"
                ],
                "answer": [
                    "parallel",
                    "parent",
                    "parcel",
                    "pardon"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_32",
                "type": "dragAndDrop",
                "question": [
                    "steel",
                    "steal",
                    "steam",
                    "steep"
                ],
                "answer": [
                    "steep",
                    "steal",
                    "steam",
                    "steel"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_33",
                "type": "dragAndDrop",
                "question": [
                    "different",
                    "distance",
                    "difficult",
                    "diffuse"
                ],
                "answer": [
                    "difficult",
                    "diffuse",
                    "different",
                    "distance"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_34",
                "type": "dragAndDrop",
                "question": [
                    "council",
                    "conceal",
                    "counsel",
                    "consumers"
                ],
                "answer": [
                    "council",
                    "conceal",
                    "counsel",
                    "consumers"
                ]
            }
        ]
    },
    {
        "instructions": "In each of the questions 18 to 20, give one word for the underlined group of words.",
        "category": 18,
        "numberOfQuestions ": 3,
        "questions": [
            {
                "question": "We visited <u>sick people</u> at Mulago hospital last sunday.",
                "answer": "patients",
                "mark": 1,
                "id": "pleenglishcategory18-20_1",
                "type": "text"
            },
            {
                "question": "Some of his supporters <u>went on</u> rejoicing even after he had lost the election.",
                "answer": "continued",
                "mark": 1,
                "id": "pleenglishcategory18-20_2",
                "type": "text"
            },
            {
                "question": "The windows were <u>not closed</u> when the visitors arrived.",
                "answer": "open",
                "mark": 1,
                "id": "pleenglishcategory18-20_3",
                "type": "text"
            },
            {
                "question": "Ann Mary's mother hates <u>people who tell lies</u>.",
                "answer": "liars",
                "mark": 1,
                "id": "pleenglishcategory18-20_4",
                "type": "text"
            },
            {
                "question": "Our school is <u>not far from</u> the police post.",
                "answer": "nearby",
                "mark": 1,
                "id": "pleenglishcategory18-20_5",
                "type": "text"
            },
            {
                "question": "Some of our classmates are <u>from Kenya</u>.",
                "answer": "Kenyan",
                "mark": 1,
                "id": "pleenglishcategory18-20_6",
                "type": "text"
            },
            {
                "question": "The referee has told the player to <u>start again</u> the game.",
                "answer": "restart",
                "mark": 1,
                "id": "pleenglishcategory18-20_7",
                "type": "text"
            },
            {
                "question": "<u>In addition to</u> being a carpenter, he is also a farmer.",
                "answer": "Besides",
                "mark": 1,
                "id": "pleenglishcategory18-20_8",
                "type": "text"
            },
            {
                "question": "Amita drove safely through a road that was <u>covered with a lot of dust</u>.",
                "answer": "dusty",
                "mark": 1,
                "id": "pleenglishcategory18-20_9",
                "type": "text"
            },
            {
                "question": "The cleanest <u>man who sells meat</u> in our village was arrested.",
                "answer": "butcher",
                "mark": 1,
                "id": "pleenglishcategory18-20_10",
                "type": "text"
            },
            {
                "question": "You must <u>take back</u> that book to the library after reading it.",
                "answer": "return",
                "mark": 1,
                "id": "pleenglishcategory18-20_11",
                "type": "text"
            },
            {
                "question": "The referee told the players to <u>start again</u> the game.",
                "answer": "restart",
                "mark": 1,
                "id": "pleenglishcategory18-20_12",
                "type": "text"
            },
            {
                "question": "I always eat a lot of <u>pawpaws , oranges, guavas and ovacados</u>",
                "answer": "fruits",
                "mark": 1,
                "id": "pleenglishcategory18-20_13",
                "type": "text"
            },
            {
                "question": "Most birds make <u>homes of grass </u> in which to lay their eggs.",
                "answer": "nests",
                "mark": 1,
                "id": "pleenglishcategory18-20_14",
                "type": "text"
            },
            {
                "question": "The children ran to the kitchen <u>at once</u> ",
                "answer": "immediately",
                "mark": 1,
                "id": "pleenglishcategory18-20_15",
                "type": "text"
            },
            {
                "question": "He did not answer the question <u>in a correct way.</u> ",
                "answer": "correctly",
                "mark": 1,
                "id": "pleenglishcategory18-20_16",
                "type": "text"
            },
            {
                "question": "The teacher <u>does not like </u> children who steal. ",
                "answer": "dislikes",
                "mark": 1,
                "id": "pleenglishcategory18-20_17",
                "type": "text"
            },
            {
                "question": "The young girl <u> did not accept </u> the gift from the old man.",
                "answer": "refused",
                "mark": 1,
                "id": "pleenglishcategory18-20_18",
                "type": "text"
            },
            {
                "question": "I have been attending school <u> every day. </u> ",
                "answer": "daily",
                "mark": 1,
                "id": "pleenglishcategory18-20_19",
                "type": "text"
            },
            {
                "question": "<u>Mothers and fathers </u> need to be respected.",
                "answer": "Parents",
                "mark": 1,
                "id": "pleenglishcategory18-20_20",
                "type": "text"
            },
            {
                "question": "The teacher told us to face the <u> direction where the sun rises from. </u>",
                "answer": "east",
                "mark": 1,
                "id": "pleenglishcategory18-20_21",
                "type": "text"
            },
            {
                "question": "The class monitor has put up the <u> list of responsibilities to be done and the class members to do them. </u>",
                "answer": "rota",
                "mark": 1,
                "id": "pleenglishcategory18-20_22",
                "type": "text"
            },
            {
                "question": "Do all birds <u> move in the air? </u>",
                "answer": "fly",
                "mark": 1,
                "id": "pleenglishcategory18-20_23",
                "type": "text"
            },
            {
                "question": "The thief was handled <u> without mercy </u> by the villagers.",
                "answer": "mercilessly",
                "mark": 1,
                "id": "pleenglishcategory18-20_24",
                "type": "text"
            },
            {
                "question": "The figure is <u> shaped in form of a circle. </u>",
                "answer": "circular",
                "mark": 1,
                "id": "pleenglishcategory18-20_25",
                "type": "text"
            },
            {
                "question": "<u>Catching fish </u> is one of the activities in my district",
                "answer": "Fishing",
                "mark": 1,
                "id": "pleenglishcategory18-20_26",
                "type": "text"
            },
            {
                "question": "Bright sister solves problems <u> with ease. </u>",
                "answer": "easily",
                "mark": 1,
                "id": "pleenglishcategory18-20_27",
                "type": "text"
            },
            {
                "question": "Mapenzi joined secondary school because <u> he didnot fail </u> P.L.E.",
                "answer": "passed",
                "mark": 1,
                "id": "pleenglishcategory18-20_28",
                "type": "text"
            },
            {
                "question": "He went to a hotel which didnot have a <u> list of food served at a meal. </u>",
                "answer": "menu",
                "mark": 1,
                "id": "pleenglishcategory18-20_29",
                "type": "text"
            },
            {
                "question": "The woman who has been imprisoned is <u> not guilty. </u>",
                "answer": "innocent",
                "mark": 1,
                "id": "pleenglishcategory18-20_30",
                "type": "text"
            },
            {
                "question": "Kawudha is my <u> brother\u2019s daughter. </u>",
                "answer": "niece",
                "mark": 1,
                "id": "pleenglishcategory18-20_31",
                "type": "text"
            },
            {
                "question": "Every employee expects a good <u> amount of money for the work he does. </u>",
                "answer": "salary",
                "mark": 1,
                "id": "pleenglishcategory18-20_32",
                "type": "text"
            },
            {
                "question": "The stubborn girl was punished for <u> refusing to obey </u> her mother.",
                "answer": "disobeying",
                "mark": 1,
                "id": "pleenglishcategory18-20_33",
                "type": "text"
            },
            {
                "question": "Our teacher will visit the <u> place where aeroplanes land. </u>",
                "answer": "airport",
                "mark": 1,
                "id": "pleenglishcategory18-20_34",
                "type": "text"
            },
            {
                "question": "Susan works as a <u> person who serves customers at their tables </u> in a hotel.",
                "answer": "waitress",
                "mark": 1,
                "id": "pleenglishcategory18-20_35",
                "type": "text"
            },
            {
                "question": "Semagga was <u> sent away </u> from school for his misconduct.",
                "answer": "expelled",
                "mark": 1,
                "id": "pleenglishcategory18-20_36",
                "type": "text"
            },
            {
                "question": "Beside fish, Mathew enjoy <u> sheep\u2019s meat.</u> ",
                "answer": "mutton",
                "mark": 1,
                "id": "pleenglishcategory18-20_37",
                "type": "text"
            },
            {
                "question": "The second scene of the play amused the <u> people watching it. </u>",
                "answer": "spectators",
                "mark": 1,
                "id": "pleenglishcategory18-20_38",
                "type": "text"
            },
            {
                "question": "The old woman walked to the <u> place where bread and cakes are made. </u>",
                "answer": "bakery",
                "mark": 1,
                "id": "pleenglishcategory18-20_39",
                "type": "text"
            },
            {
                "question": "There were many <u> people riding bicycles </u> in the town.",
                "answer": "cyclists",
                "mark": 1,
                "id": "pleenglishcategory18-20_40",
                "type": "text"
            },
            {
                "question": "The <u> place where vehicles are repaired </u> was empty.",
                "answer": "garage",
                "mark": 1,
                "id": "pleenglishcategory18-20_41",
                "type": "text"
            },
            {
                "question": "The government  has built a school for the <u> people who cannot hear. </u>",
                "answer": "deaf",
                "mark": 1,
                "id": "pleenglishcategory18-20_42",
                "type": "text"
            },
            {
                "question": "There were many <u> sick people </u> in the hospital.",
                "answer": "patients",
                "mark": 1,
                "id": "pleenglishcategory18-20_43",
                "type": "text"
            },
            {
                "question": "He stayed in Tanzania for a <u> period of seven days. </u>",
                "answer": "week",
                "mark": 1,
                "id": "pleenglishcategory18-20_44",
                "type": "text"
            },
            {
                "question": "All the <u> children whose parents are dead </u> were given blankets at school.",
                "answer": "orphans",
                "mark": 1,
                "id": "pleenglishcategory18-20_45",
                "type": "text"
            },
            {
                "question": "The lazy girl was <u> not successful </u> in the final examinations.",
                "answer": "unsuccessful",
                "mark": 1,
                "id": "pleenglishcategory18-20_46",
                "type": "text"
            },
            {
                "question": "Primary five class made boxes that are <u> shaped like a triangle. </u>",
                "answer": "triangular",
                "mark": 1,
                "id": "pleenglishcategory18-20_47",
                "type": "text"
            }
        ]
    },
    {
        "instructions": "In each of the questions 21 to 22, choose the sentence where the given word is used correctly.",
        "category": 21,
        "numberOfQuestions ": 2,
        "questions": [
            {
                "question": "wear",
                "options": [
                    "His mother told him to wear a Jacket.",
                    "She was asked wear she was.",
                    "Wear are you going?"
                ],
                "answer": "His mother told him to wear a Jacket.",
                "mark": 1,
                "id": "pleenglishcategory21-22_1",
                "type": "multipleChoice"
            },
            {
                "question": "where",
                "options": [
                    "His mother told him to where a Jacket.",
                    "She was asked where she was.",
                    "Where are you going?"
                ],
                "answer": "Where are you going?",
                "mark": 1,
                "id": "pleenglishcategory21-22_2",
                "type": "multipleChoice"
            },
            {
                "question": "roots",
                "options": [
                    "You can use different roots to go to town.",
                    "Plants are supported by roots.",
                    "Which roots do you take to your village?"
                ],
                "answer": "Plants are supported by roots.",
                "mark": 1,
                "id": "pleenglishcategory21-22_3",
                "type": "multipleChoice"
            },
            {
                "question": "routes",
                "options": [
                    "You can use different routes to go to town.",
                    "Plants are supported by routes.",
                    "Routes are found below ground under a tree."
                ],
                "answer": "You can use different routes to go to town.",
                "mark": 1,
                "id": "pleenglishcategory21-22_4",
                "type": "multipleChoice"
            },
            {
                "question": "weight",
                "options": [
                    "The bus can not weight for you.",
                    "You can weight for me at the bus stop.",
                    "What is the weight of that stone?"
                ],
                "answer": "What is the weight of that stone?",
                "mark": 1,
                "id": "pleenglishcategory21-22_5",
                "type": "multipleChoice"
            },
            {
                "question": "wait",
                "options": [
                    "What is the wait of the three brothers.",
                    "You can wait for me at the bus stop.",
                    "What is the wait of that stone?"
                ],
                "answer": "You can wait for me at the bus stop.",
                "mark": 1,
                "id": "pleenglishcategory21-22_6",
                "type": "multipleChoice"
            },
            {
                "question": "sauce",
                "options": [
                    "She had a sauce of inspiration for her artwork.",
                    "I need to put sauce on my shoes.",
                    "Mom prepared a delicious spaghetti sauce for dinner.",
                    "He likes to sauce the soccer ball into the net."
                ],
                "answer": "Mom prepared a delicious spaghetti sauce for dinner.",
                "mark": 1,
                "id": "pleenglishcategory21-22_7",
                "type": "multipleChoice"
            },
            {
                "question": "source",
                "options": [
                    "The library is an important sauce of knowledge.",
                    "We need to find a source to fix the car.",
                    "He likes to source the books at the bookstore.",
                    "The internet is a great source of information."
                ],
                "answer": "The internet is a great source of information.",
                "mark": 1,
                "id": "pleenglishcategory21-22_8",
                "type": "multipleChoice"
            },
            {
                "question": "need",
                "options": [
                    "I need to knead some money to buy toys.",
                    "She doesn't need to read this book.",
                    "We need to finish our homework before playing.",
                    "He likes to need the dog at the park."
                ],
                "answer": "We need to finish our homework before playing.",
                "mark": 1,
                "id": "pleenglishcategory21-22_9",
                "type": "multipleChoice"
            },
            {
                "question": "knead",
                "options": [
                    "The bread was delicious because they served it with a special knead.",
                    "She found a great knead for her research paper.",
                    "To prepare the pizza, they need the dough and add the toppings.",
                    "You can knead the dough to make pizza."
                ],
                "answer": "You can knead the dough to make pizza.",
                "mark": 1,
                "id": "pleenglishcategory21-22_10",
                "type": "multipleChoice"
            },
            {
                "question": "meat",
                "options": [
                    "They went to meet their grandparents during the holidays.",
                    "The chef prepared a meat that complemented the salad.",
                    "Dad bought some fresh meat from the butcher.",
                    "He likes to meat new friends at the playground."
                ],
                "answer": "Dad bought some fresh meat from the butcher.",
                "mark": 1,
                "id": "pleenglishcategory21-22_11",
                "type": "multipleChoice"
            },
            {
                "question": "meet",
                "options": [
                    "The students met the story during the class.",
                    "She meat her favorite author at the book signing event.",
                    "I need to meet my homework before going out to play.",
                    "They went to meet their grandparents during the holidays."
                ],
                "answer": "They went to meet their grandparents during the holidays.",
                "mark": 1,
                "id": "pleenglishcategory21-22_12",
                "type": "multipleChoice"
            },
            {
                "question": "read",
                "options": [
                    "The reed rustled by the lake.",
                    "To make bread, you need to read the dough.",
                    "Can you read this story aloud to the class?",
                    "He likes to read the ball during the game."
                ],
                "answer": "Can you read this story aloud to the class?",
                "mark": 1,
                "id": "pleenglishcategory21-22_13",
                "type": "multipleChoice"
            },
            {
                "question": "reed",
                "options": [
                    "The wind rustled the reeds by the lake.",
                    "She had a reed of inspiration for her artwork.",
                    "In baking, you must reed the dough to develop gluten.",
                    "The pasta was cooked perfectly, and they served it with a delicious reed."
                ],
                "answer": "The wind rustled the reeds by the lake.",
                "mark": 1,
                "id": "pleenglishcategory21-22_14",
                "type": "multipleChoice"
            },
            {
                "question": "fare",
                "options": [
                    "The cat chased the yarn across the room, creating a playful fare.",
                    "He built a tall tower with his building blocks, showing his engineering fare.",
                    "The chef prepared a delicious cake for the party, displaying his baking fare.",
                    "She paid the bus fare and found a seat for the journey."
                ],
                "answer": "She paid the bus fare and found a seat for the journey.",
                "mark": 1,
                "id": "pleenglishcategory21-22_15",
                "type": "multipleChoice"
            },
            {
                "question": "fair",
                "options": [
                    "She was fair when we visited her in the hospital.",
                    "He paid the bus fair and found a seat for the journey.",
                    "The baker's pies at the fair were a real treat for everyone.",
                    "She refused to pay the bus fair"
                ],
                "answer": "She was fair when we visited her in the hospital.",
                "mark": 1,
                "id": "pleenglishcategory21-22_16",
                "type": "multipleChoice"
            },
            {
                "question": "sea",
                "options": [
                    "The sea is a beautiful sight.",
                    "I sea the boat coming.",
                    "I can sea from here.",
                    "I sea the top of the cliff."
                ],
                "answer": "The sea is a beautiful sight.",
                "mark": 1,
                "id": "pleenglishcategory21-22_17",
                "type": "multipleChoice"
            },
            {
                "question": "see",
                "options": [
                    "I can sea the see from here.",
                    "I saw the see from the top of the cliff.",
                    "I see the boat coming.",
                    "The see is a beautiful sight."
                ],
                "answer": "I see the boat coming.",
                "mark": 1,
                "id": "pleenglishcategory21-22_18",
                "type": "multipleChoice"
            },
            {
                "question": "sows",
                "options": [
                    "The farmer sows seeds in the field.",
                    "I can see the sow in the field.",
                    "I saw the sow from the top of the cliff.",
                    "The sow is a beautiful sight."
                ],
                "answer": "The farmer sows seeds in the field.",
                "mark": 1,
                "id": "pleenglishcategory21-22_19",
                "type": "multipleChoice"
            },
            {
                "question": "hear",
                "options": [
                    "I hear you clearly.",
                    "I'm hear for you.",
                    "I can hear the birds singing.",
                    "The cat is hear."
                ],
                "answer": "I hear you clearly.",
                "mark": 1,
                "id": "pleenglishcategory21-22_20",
                "type": "multipleChoice"
            },
            {
                "question": "here",
                "options": [
                    "I can hear you here.",
                    "I'm here for you.",
                    "I can here the birds singing.",
                    "I hear you"
                ],
                "answer": "I'm here for you.",
                "mark": 1,
                "id": "pleenglishcategory21-22_21",
                "type": "multipleChoice"
            },
            {
                "question": "break",
                "options": [
                    "The train won't break for you.",
                    "I need to break for the stop sign.",
                    "Don't break the window.",
                    "I'm going to break the car."
                ],
                "answer": "Don't break the window.",
                "mark": 1,
                "id": "pleenglishcategory21-22_22",
                "type": "multipleChoice"
            },
            {
                "question": "brake",
                "options": [
                    "I need to take a brake.",
                    "I need to brake for the stop sign.",
                    "He smashed the window and broke it.",
                    "I'm going to brake the law."
                ],
                "answer": "I need to brake for the stop sign.",
                "mark": 1,
                "id": "pleenglishcategory21-22_23",
                "type": "multipleChoice"
            },
            {
                "question": "piece",
                "options": [
                    "I need a piece of paper.",
                    "I need a piece of mind.",
                    "The warlords don't want piece",
                    "I need to make piece with my enemy."
                ],
                "answer": "I need a piece of paper.",
                "mark": 1,
                "id": "pleenglishcategory21-22_24",
                "type": "multipleChoice"
            },
            {
                "question": "peace",
                "options": [
                    "I need a peace of mind.",
                    "That's a large peace of meat.",
                    "I need to peace together the puzzle.",
                    "I need to make peace with my enemy."
                ],
                "answer": "I need to make peace with my enemy.",
                "mark": 1,
                "id": "pleenglishcategory21-22_25",
                "type": "multipleChoice"
            },
            {
                "question": "die",
                "options": [
                    "I need to die my hair. ",
                    "Soildiers are ready to die for my country.",
                    "The plant died when I forgot to water it.",
                    "The light bulb died."
                ],
                "answer": "Soildiers are ready to die for my country.",
                "mark": 1,
                "id": "pleenglishcategory21-22_26",
                "type": "multipleChoice"
            },
            {
                "question": "dye",
                "options": [
                    "I need to dye my hair.",
                    "Soildiers are ready to dye for my country.",
                    "Young plants need water so that they don't dye.",
                    "The light bulb dyed."
                ],
                "answer": "I need to dye my hair.",
                "mark": 1,
                "id": "pleenglishcategory21-22_27",
                "type": "multipleChoice"
            },
            {
                "question": "lack",
                "options": [
                    "You need lack to win the lottery.",
                    "I lack confidence for public speaking.",
                    "Lack was not on his side.",
                    "He is among the few who have lack."
                ],
                "answer": "I lack confidence for public speaking.",
                "mark": 1,
                "id": "pleenglishcategory21-22_28",
                "type": "multipleChoice"
            },
            {
                "question": "luck",
                "options": [
                    "I luck the motivation to exercise.",
                    "I luck the time to finish this project.",
                    "I had no luck finding a parking spot.",
                    "He lucks the basic needs."
                ],
                "answer": "I had no luck finding a parking spot.",
                "mark": 1,
                "id": "pleenglishcategory21-22_29",
                "type": "multipleChoice"
            },
            {
                "question": "live",
                "options": [
                    "I live in a small town.",
                    "He is living for the airport.",
                    "You are free to live anytime.",
                    "He hates people who live early"
                ],
                "answer": "I live in a small town.",
                "mark": 1,
                "id": "pleenglishcategory21-22_30",
                "type": "multipleChoice"
            },
            {
                "question": "leave",
                "options": [
                    "Get ready to leave in a few minutes.",
                    "Good health is essential for one to leave.",
                    "Food is not the only essential need for one to leave.",
                    "You cannot leave without oxygen."
                ],
                "answer": "Get ready to leave in a few minutes.",
                "mark": 1,
                "id": "pleenglishcategory21-22_31",
                "type": "multipleChoice"
            },
            {
                "question": "flour",
                "options": [
                    "The flour is slippery.",
                    "He is mopping the flour.",
                    "This flour is beautiful.",
                    "I need flour to make bread."
                ],
                "answer": "I need flour to make bread.",
                "mark": 1,
                "id": "pleenglishcategory21-22_32",
                "type": "multipleChoice"
            },
            {
                "question": "flower",
                "options": [
                    "I need flowers to make bread.",
                    "The flower is beautiful.",
                    "The flowers are used to make dough.",
                    "The flower needs mopping."
                ],
                "answer": "The flower is beautiful.",
                "mark": 1,
                "id": "pleenglishcategory21-22_33",
                "type": "multipleChoice"
            },
            {
                "question": "quiet",
                "options": [
                    "The library is quiet.",
                    "The weather is quiet nice today.",
                    "I'm quiet sure about that.",
                    "The library is quite."
                ],
                "answer": "The library is quiet.",
                "mark": 1,
                "id": "pleenglishcategory21-22_34",
                "type": "multipleChoice"
            },
            {
                "question": "quite",
                "options": [
                    "He was so quite in class today.",
                    "Can you be quite for once.",
                    "I'm quite happy.",
                    "If you don't keep quite you will be punished."
                ],
                "answer": "I'm quite happy.",
                "mark": 1,
                "id": "pleenglishcategory21-22_35",
                "type": "multipleChoice"
            }
        ]
    },
    {
        "instructions": "In each of the questions 23 to 24, write the full form of the given abbreviation.",
        "category": 23,
        "numberOfQuestions ": 2,
        "questions": [
            {
                "question": "it's",
                "answer": "it is",
                "mark": 1,
                "id": "pleenglishcategory23-24_1",
                "type": "text"
            },
            {
                "question": "Wed",
                "answer": "Wednesday",
                "mark": 1,
                "id": "pleenglishcategory23-24_2",
                "type": "text"
            },
            {
                "question": "Prof",
                "answer": "Proffesor",
                "mark": 1,
                "id": "pleenglishcategory23-24_3",
                "type": "text"
            },
            {
                "question": "MC",
                "answer": "Master of ceremony",
                "mark": 1,
                "id": "pleenglishcategory23-24_4",
                "type": "text"
            },
            {
                "question": "doz",
                "answer": "dozen",
                "mark": 1,
                "id": "pleenglishcategory23-24_5",
                "type": "text"
            },
            {
                "question": "vs",
                "answer": "versus",
                "mark": 1,
                "id": "pleenglishcategory23-24_6",
                "type": "text"
            },
            {
                "question": "Rd",
                "answer": "Road",
                "mark": 1,
                "id": "pleenglishcategory23-24_7",
                "type": "text"
            },
            {
                "question": "Shan't",
                "answer": "Shall not",
                "mark": 1,
                "id": "pleenglishcategory23-24_8",
                "type": "text"
            },
            {
                "question": "temp",
                "answer": "temperature",
                "mark": 1,
                "id": "pleenglishcategory23-24_9",
                "type": "text"
            },
            {
                "question": "mustn't",
                "answer": "must not",
                "mark": 1,
                "id": "pleenglishcategory23-24_10",
                "type": "text"
            },
            {
                "question": "P.T.O",
                "answer": "Please Turn Over",
                "mark": 1,
                "id": "pleenglishcategory23-24_11",
                "type": "text"
            },
            {
                "question": "Rev",
                "answer": "Reverend",
                "mark": 1,
                "id": "pleenglishcategory23-24_12",
                "type": "text"
            },
            {
                "question": "He'll",
                "answer": "He will",
                "mark": 1,
                "id": "pleenglishcategory23-24_13",
                "type": "text"
            },
            {
                "question": "cc",
                "answer": "carbon copy",
                "mark": 1,
                "id": "pleenglishcategory23-24_14",
                "type": "text"
            },
            {
                "question": "They're",
                "answer": "They are",
                "mark": 1,
                "id": "pleenglishcategory23-24_15",
                "type": "text"
            },
            {
                "question": "bro",
                "answer": "brother",
                "mark": 1,
                "id": "pleenglishcategory23-24_16",
                "type": "text"
            },
            {
                "question": "shan't",
                "answer": "shall not",
                "mark": 1,
                "id": "pleenglishcategory23-24_17",
                "type": "text"
            },
            {
                "question": "gov't",
                "answer": "government",
                "mark": 1,
                "id": "pleenglishcategory23-24_18",
                "type": "text"
            },
            {
                "question": "Tue",
                "answer": "Tuesday",
                "mark": 1,
                "id": "pleenglishcategory23-24_19",
                "type": "text"
            },
            {
                "question": "Tue",
                "answer": "Tuesday",
                "mark": 1,
                "id": "pleenglishcategory23-24_20",
                "type": "text"
            },
            {
                "question": "cert",
                "answer": "certificate",
                "mark": 1,
                "id": "pleenglishcategory23-24_21",
                "type": "text"
            },
            {
                "question": "Dr",
                "answer": "Doctor",
                "mark": 1,
                "id": "pleenglishcategory23-24_22",
                "type": "text"
            },
            {
                "question": "We've",
                "answer": "We have",
                "mark": 1,
                "id": "pleenglishcategory23-24_23",
                "type": "text"
            },
            {
                "question": "Hon",
                "answer": "Honourable",
                "mark": 1,
                "id": "pleenglishcategory23-24_24",
                "type": "text"
            },
            {
                "question": "Tel",
                "answer": "Telephone",
                "mark": 1,
                "id": "pleenglishcategory23-24_25",
                "type": "text"
            },
            {
                "question": "Jan",
                "answer": "January",
                "mark": 1,
                "id": "pleenglishcategory23-24_26",
                "type": "text"
            },
            {
                "question": "asst",
                "answer": "assistant",
                "mark": 1,
                "id": "pleenglishcategory23-24_27",
                "type": "text"
            },
            {
                "question": "won't",
                "answer": "will not",
                "mark": 1,
                "id": "pleenglishcategory23-24_28",
                "type": "text"
            },
            {
                "question": "Capt",
                "answer": "Captain",
                "mark": 1,
                "id": "pleenglishcategory23-24_29",
                "type": "text"
            },
            {
                "question": "Miss",
                "answer": "Mistress",
                "mark": 1,
                "id": "pleenglishcategory23-24_30",
                "type": "text"
            },
            {
                "question": "Mr",
                "answer": "Master",
                "mark": 1,
                "id": "pleenglishcategory23-24_31",
                "type": "text"
            }
        ]
    },
    {
        "instructions": "In each of the questions 25 to 26, drag and drop the given words to from a sentence.",
        "category": 25,
        "numberOfQuestions ": 2,
        "questions": [
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_1",
                "type": "dragAndDrop",
                "question": [
                    "people",
                    "Many",
                    "attended",
                    "graduation",
                    "his."
                ],
                "answer": [
                    "Many",
                    "people",
                    "attended",
                    "his.",
                    "graduation"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_2",
                "type": "dragAndDrop",
                "question": [
                    "doing",
                    "tomorrow",
                    "anything",
                    "Are",
                    "morning",
                    "you?"
                ],
                "answer": [
                    "Are",
                    "you?",
                    "doing",
                    "anything",
                    "tomorrow",
                    "morning"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_3",
                "type": "dragAndDrop",
                "question": [
                    "marriages",
                    "avoid",
                    "should",
                    "We",
                    "early"
                ],
                "answer": [
                    "We",
                    "should",
                    "avoid",
                    "early",
                    "marriages"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_4",
                "type": "dragAndDrop",
                "question": [
                    "bake",
                    "Do",
                    "cakes",
                    "know",
                    "you",
                    "to",
                    "how?"
                ],
                "answer": [
                    "Do",
                    "you",
                    "know",
                    "how?",
                    "to",
                    "bake",
                    "cakes"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_5",
                "type": "dragAndDrop",
                "question": [
                    "how",
                    "to",
                    "know",
                    "I",
                    "ride",
                    "a",
                    "bicycle."
                ],
                "answer": [
                    "I",
                    "know",
                    "how",
                    "to",
                    "ride",
                    "a",
                    "bicycle."
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_6",
                "type": "dragAndDrop",
                "question": [
                    "nephew",
                    "old",
                    "is",
                    "How",
                    "your?"
                ],
                "answer": [
                    "How",
                    "old",
                    "is",
                    "your?",
                    "nephew"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_7",
                "type": "dragAndDrop",
                "question": [
                    "the",
                    "sauce.",
                    "tasted",
                    "She"
                ],
                "answer": [
                    "She",
                    "tasted",
                    "the",
                    "sauce."
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_8",
                "type": "dragAndDrop",
                "question": [
                    "unknown.",
                    "is",
                    "The",
                    "source"
                ],
                "answer": [
                    "The",
                    "source",
                    "is",
                    "unknown."
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_9",
                "type": "dragAndDrop",
                "question": [
                    "beautiful",
                    "sunset.",
                    "I",
                    "a",
                    "see"
                ],
                "answer": [
                    "I",
                    "see",
                    "a",
                    "beautiful",
                    "sunset."
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_10",
                "type": "dragAndDrop",
                "question": [
                    "calm.",
                    "sea",
                    "was",
                    "The"
                ],
                "answer": [
                    "The",
                    "sea",
                    "was",
                    "calm."
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_11",
                "type": "dragAndDrop",
                "question": [
                    "how",
                    "to",
                    "She",
                    "sew.",
                    "knows"
                ],
                "answer": [
                    "She",
                    "knows",
                    "how",
                    "to",
                    "sew."
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_12",
                "type": "dragAndDrop",
                "question": [
                    "decided",
                    "garden.",
                    "He",
                    "the",
                    "to",
                    "sow",
                    "in",
                    "seeds"
                ],
                "answer": [
                    "He",
                    "decided",
                    "to",
                    "sow",
                    "seeds",
                    "in",
                    "the",
                    "garden."
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_13",
                "type": "dragAndDrop",
                "question": [
                    "driver",
                    "stop",
                    "to",
                    "applied",
                    "The",
                    "brake",
                    "the",
                    "car.",
                    "the"
                ],
                "answer": [
                    "The",
                    "driver",
                    "applied",
                    "the",
                    "brake",
                    "to",
                    "stop",
                    "the",
                    "car."
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_14",
                "type": "dragAndDrop",
                "question": [
                    "break",
                    "relax.",
                    "He",
                    "a",
                    "took",
                    "work",
                    "from",
                    "to"
                ],
                "answer": [
                    "He",
                    "took",
                    "a",
                    "break",
                    "from",
                    "work",
                    "to",
                    "relax."
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_15",
                "type": "dragAndDrop",
                "question": [
                    "played",
                    "tiny",
                    "The",
                    "hut.",
                    "children",
                    "the",
                    "in"
                ],
                "answer": [
                    "The",
                    "children",
                    "played",
                    "in",
                    "the",
                    "tiny",
                    "hut."
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_16",
                "type": "dragAndDrop",
                "question": [
                    "bike",
                    "her",
                    "knee.",
                    "fell",
                    "off",
                    "her",
                    "She",
                    "and",
                    "hurt"
                ],
                "answer": [
                    "She",
                    "fell",
                    "off",
                    "her",
                    "bike",
                    "and",
                    "hurt",
                    "her",
                    "knee."
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_17",
                "type": "dragAndDrop",
                "question": [
                    "good",
                    "a",
                    "Can",
                    "life?",
                    "man",
                    "a",
                    "lead",
                    "lazy"
                ],
                "answer": [
                    "Can",
                    "a",
                    "lazy",
                    "man",
                    "lead",
                    "a",
                    "good",
                    "life?"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_18",
                "type": "dragAndDrop",
                "question": [
                    "wife",
                    "neighbour's",
                    "gave",
                    "My",
                    "twins.",
                    "to",
                    "birth"
                ],
                "answer": [
                    "My",
                    "neighbour's",
                    "wife",
                    "gave",
                    "birth",
                    "to",
                    "twins."
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_19",
                "type": "dragAndDrop",
                "question": [
                    "school",
                    "father.",
                    "a",
                    "My",
                    "is",
                    "teacher",
                    "this",
                    "in"
                ],
                "answer": [
                    "My",
                    "in",
                    "is",
                    "this",
                    "school",
                    "a",
                    "teacher",
                    "father."
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_20",
                "type": "dragAndDrop",
                "question": [
                    "mechanic",
                    "vehicle?",
                    "did",
                    "When",
                    "your",
                    "the",
                    "repair"
                ],
                "answer": [
                    "When",
                    "did",
                    "the",
                    "mechanic",
                    "repair",
                    "your",
                    "vehicle?"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_21",
                "type": "dragAndDrop",
                "question": [
                    "quiet.",
                    "is",
                    "baby",
                    "The",
                    "very"
                ],
                "answer": [
                    "The",
                    "baby",
                    "is",
                    "very",
                    "quiet."
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_22",
                "type": "dragAndDrop",
                "question": [
                    "tired.",
                    "I",
                    "am",
                    "quite"
                ],
                "answer": [
                    "I",
                    "am",
                    "quite",
                    "tired."
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_23",
                "type": "dragAndDrop",
                "question": [
                    "the",
                    "flour.",
                    "sifted",
                    "She"
                ],
                "answer": [
                    "She",
                    "sifted",
                    "the",
                    "flour."
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_24",
                "type": "dragAndDrop",
                "question": [
                    "bloomed",
                    "beautifully.",
                    "The",
                    "flower"
                ],
                "answer": [
                    "The",
                    "flower",
                    "bloomed",
                    "beautifully."
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_25",
                "type": "dragAndDrop",
                "question": [
                    "going",
                    "to",
                    "I",
                    "the",
                    "am",
                    "bakery."
                ],
                "answer": [
                    "I",
                    "am",
                    "going",
                    "to",
                    "the",
                    "bakery."
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_26",
                "type": "dragAndDrop",
                "question": [
                    "your",
                    "brother",
                    "live?",
                    "does",
                    "Where"
                ],
                "answer": [
                    "Where",
                    "does",
                    "your",
                    "brother",
                    "live?"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_27",
                "type": "dragAndDrop",
                "question": [
                    "the",
                    "road",
                    "my",
                    "to",
                    "How",
                    "village!",
                    "bushy",
                    "is"
                ],
                "answer": [
                    "How",
                    "bushy",
                    "is",
                    "the",
                    "road",
                    "to",
                    "my",
                    "village!"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_28",
                "type": "dragAndDrop",
                "question": [
                    "your",
                    "ears",
                    "use",
                    "What",
                    "do",
                    "for?",
                    "you"
                ],
                "answer": [
                    "What",
                    "do",
                    "you",
                    "use",
                    "your",
                    "ears",
                    "for?"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_29",
                "type": "dragAndDrop",
                "question": [
                    "our",
                    "school",
                    "where",
                    "shows",
                    "signpost",
                    "is",
                    "The",
                    "located."
                ],
                "answer": [
                    "The",
                    "signpost",
                    "shows",
                    "where",
                    "our",
                    "school",
                    "is",
                    "located."
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_30",
                "type": "dragAndDrop",
                "question": [
                    "help.",
                    "some",
                    "need",
                    "I"
                ],
                "answer": [
                    "I",
                    "need",
                    "some",
                    "help."
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_31",
                "type": "dragAndDrop",
                "question": [
                    "make",
                    "bread.",
                    "I",
                    "knead",
                    "to",
                    "dough",
                    "the"
                ],
                "answer": [
                    "I",
                    "knead",
                    "the",
                    "dough",
                    "to",
                    "make",
                    "bread."
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_32",
                "type": "dragAndDrop",
                "question": [
                    "smart",
                    "teachers.",
                    "are",
                    "Our",
                    "very"
                ],
                "answer": [
                    "Our",
                    "teachers.",
                    "are",
                    "very",
                    "smart"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_33",
                "type": "dragAndDrop",
                "question": [
                    "how",
                    "Does",
                    "mother",
                    "your",
                    "know",
                    "clothes?",
                    "to",
                    "sew"
                ],
                "answer": [
                    "Does",
                    "your",
                    "mother",
                    "know",
                    "how",
                    "to",
                    "sew",
                    "clothes?"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_34",
                "type": "dragAndDrop",
                "question": [
                    "the",
                    "latrine.",
                    "You",
                    "after",
                    "wash",
                    "visiting",
                    "should",
                    "hands",
                    "your"
                ],
                "answer": [
                    "You",
                    "should",
                    "wash",
                    "your",
                    "hands",
                    "after",
                    "visiting",
                    "the",
                    "latrine."
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_35",
                "type": "dragAndDrop",
                "question": [
                    "Kato",
                    "the",
                    "classroom?",
                    "mopped",
                    "Has"
                ],
                "answer": [
                    "Has",
                    "Kato",
                    "mopped",
                    "the",
                    "classroom?"
                ]
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_36",
                "type": "dragAndDrop",
                "question": [
                    "a",
                    "teacher.",
                    "My",
                    "wife",
                    "is"
                ],
                "answer": [
                    "My",
                    "wife",
                    "is",
                    "a",
                    "teacher."
                ]
            }
        ]
    },
    {
        "instructions": "In each of the questions 27 to 28, give the opposite of the underlined word(s) or given word.",
        "category": 27,
        "numberOfQuestions ": 2,
        "questions": [
            {
                "question": "Chesuro always turns <u>off</u> the security lights before going to bed.",
                "answer": "on",
                "mark": 1,
                "id": "pleenglishcategory27-28_1",
                "type": "text"
            },
            {
                "question": "The head teacher's office is on the <u>upper</u> floor of the new building.",
                "answer": "lower",
                "mark": 1,
                "id": "pleenglishcategory27-28_2",
                "type": "text"
            },
            {
                "question": "Brenda walked <u>downstairs</u> to collect her clothes.",
                "answer": "upstairs",
                "mark": 1,
                "id": "pleenglishcategory27-28_3",
                "type": "text"
            },
            {
                "question": "The cyclist who <u>intentionally</u> knocked down the traffic officer has appeared in court.",
                "answer": "accidentally",
                "mark": 1,
                "id": "pleenglishcategory27-28_4",
                "type": "text"
            },
            {
                "question": "Our home is two kilometers <u>after</u> the market.",
                "answer": "before",
                "mark": 1,
                "id": "pleenglishcategory27-28_5",
                "type": "text"
            },
            {
                "question": "Arthur likes staying in <u>urban</u> areas during holidays.",
                "answer": "rural",
                "mark": 1,
                "id": "pleenglishcategory27-28_6",
                "type": "text"
            },
            {
                "question": "Are all the mangoes in your basket <u>raw</u> ?",
                "answer": "ripe",
                "mark": 1,
                "id": "pleenglishcategory27-28_7",
                "type": "text"
            },
            {
                "question": "There are many people who are employed in <u>rural</u> areas.",
                "answer": "urban",
                "mark": 1,
                "id": "pleenglishcategory27-28_8",
                "type": "text"
            },
            {
                "question": "Opany\u2019s shirt was very <u>dirty</u> yesterday.",
                "answer": "clean",
                "mark": 1,
                "id": "pleenglishcategory27-28_9",
                "type": "text"
            },
            {
                "question": "Children come to school very <u>late</u> .",
                "answer": "early",
                "mark": 1,
                "id": "pleenglishcategory27-28_10",
                "type": "text"
            },
            {
                "question": "Mzee Kija rears <u>bulls</u> on his farm.",
                "answer": "cows",
                "mark": 1,
                "id": "pleenglishcategory27-28_11",
                "type": "text"
            },
            {
                "question": "It is not good to <u>undress</u> in public.",
                "answer": "dress",
                "mark": 1,
                "id": "pleenglishcategory27-28_12",
                "type": "text"
            },
            {
                "question": "Banks allow cash <u>deposits</u> anytime of the day.",
                "answer": "withdraws",
                "mark": 1,
                "id": "pleenglishcategory27-28_13",
                "type": "text"
            },
            {
                "question": "It is <u>legal</u> to hunt animals in games parks.",
                "answer": "illegal",
                "mark": 1,
                "id": "pleenglishcategory27-28_14",
                "type": "text"
            },
            {
                "question": "He is a very <u>disciplined</u> student.",
                "answer": "undisciplined",
                "mark": 1,
                "id": "pleenglishcategory27-28_15",
                "type": "text"
            },
            {
                "question": "The <u>guest</u> provided light refreshments.",
                "answer": "host",
                "mark": 1,
                "id": "pleenglishcategory27-28_16",
                "type": "text"
            },
            {
                "question": "Lydia has gone to the bank to <u>withdraw</u> some money.",
                "answer": "deposit",
                "mark": 1,
                "id": "pleenglishcategory27-28_17",
                "type": "text"
            },
            {
                "question": "Please <u>sir,</u> may I come in?",
                "answer": "madam",
                "mark": 1,
                "id": "pleenglishcategory27-28_18",
                "type": "text"
            },
            {
                "question": "The baby was <u>awake</u> when the Reverend arrived.",
                "answer": "asleep",
                "mark": 1,
                "id": "pleenglishcategory27-28_19",
                "type": "text"
            },
            {
                "question": "The mechanic left the car door <u>closed.</u>",
                "answer": "open",
                "mark": 1,
                "id": "pleenglishcategory27-28_20",
                "type": "text"
            },
            {
                "question": "The <u>incoming</u> prefects are organizing a concert at school.",
                "answer": "outgoing",
                "mark": 1,
                "id": "pleenglishcategory27-28_21",
                "type": "text"
            },
            {
                "question": "There is a <u>narrow</u> road leading to our home.",
                "answer": "wide",
                "mark": 1,
                "id": "pleenglishcategory27-28_22",
                "type": "text"
            },
            {
                "question": "The cows were sold very <u>expensively.</u>",
                "answer": "cheaply",
                "mark": 1,
                "id": "pleenglishcategory27-28_23",
                "type": "text"
            },
            {
                "question": "The water in the lake was <u>rough</u> despite the strong storm.",
                "answer": "calm",
                "mark": 1,
                "id": "pleenglishcategory27-28_24",
                "type": "text"
            },
            {
                "question": "Bbosa is a very <u>responsible</u> perfect.",
                "answer": "irresponsible",
                "mark": 1,
                "id": "pleenglishcategory27-28_25",
                "type": "text"
            },
            {
                "question": "Asil's <u>uncle</u> bought a new bicycle.",
                "answer": "aunt",
                "mark": 1,
                "id": "pleenglishcategory27-28_26",
                "type": "text"
            },
            {
                "question": "The <u>bestman</u> looked smart during the wedding.",
                "answer": "matron",
                "mark": 1,
                "id": "pleenglishcategory27-28_27",
                "type": "text"
            },
            {
                "question": "The food you gave me was <u>adequate.</u>",
                "answer": "inadequate",
                "mark": 1,
                "id": "pleenglishcategory27-28_28",
                "type": "text"
            },
            {
                "question": "Caroline is the <u>ugliest</u> girl of the three.",
                "answer": "prettiest",
                "mark": 1,
                "id": "pleenglishcategory27-28_29",
                "type": "text"
            },
            {
                "question": "He has got a <u>permanent</u> job.",
                "answer": "temporary",
                "mark": 1,
                "id": "pleenglishcategory27-28_30",
                "type": "text"
            },
            {
                "question": "<u>Success</u> in the examination is brought about by many factors.",
                "answer": "Failure",
                "mark": 1,
                "id": "pleenglishcategory27-28_31",
                "type": "text"
            },
            {
                "question": "There were beggars at the <u>exit</u> of the Church.",
                "answer": "entry",
                "mark": 1,
                "id": "pleenglishcategory27-28_32",
                "type": "text"
            },
            {
                "question": "The city is full of <u>ancient</u> buildings.",
                "answer": "modern",
                "mark": 1,
                "id": "pleenglishcategory27-28_33",
                "type": "text"
            },
            {
                "question": "The <u>king</u> had a golden ring on one of the fingers.",
                "answer": "queen",
                "mark": 1,
                "id": "pleenglishcategory27-28_34",
                "type": "text"
            },
            {
                "question": "His <u>nephew</u> came last week.",
                "answer": "niece",
                "mark": 1,
                "id": "pleenglishcategory27-28_35",
                "type": "text"
            }
        ]
    },
    {
        "instructions": "In each of the questions 29 to 30, give the plural of the underlined or given word(s).",
        "category": 29,
        "numberOfQuestions ": 2,
        "questions": [
            {
                "question": "bench",
                "answer": "benches",
                "mark": 1,
                "id": "pleenglishcategory29-30_1",
                "type": "text"
            },
            {
                "question": "dining room",
                "answer": "dining rooms",
                "mark": 1,
                "id": "pleenglishcategory29-30_2",
                "type": "text"
            },
            {
                "question": "ferry",
                "answer": "ferries",
                "mark": 1,
                "id": "pleenglishcategory29-30_3",
                "type": "text"
            },
            {
                "question": "potato",
                "answer": "potatoes",
                "mark": 1,
                "id": "pleenglishcategory29-30_4",
                "type": "text"
            },
            {
                "question": "potato",
                "answer": "potatoes",
                "mark": 1,
                "id": "pleenglishcategory29-30_5",
                "type": "text"
            },
            {
                "question": "thief",
                "answer": "thieves",
                "mark": 1,
                "id": "pleenglishcategory29-30_6",
                "type": "text"
            },
            {
                "question": "handkerchief ",
                "answer": "handkerchiefs",
                "mark": 1,
                "id": "pleenglishcategory29-30_7",
                "type": "text"
            },
            {
                "question": "Luggage ",
                "answer": "Luggage",
                "mark": 1,
                "id": "pleenglishcategory29-30_8",
                "type": "text"
            },
            {
                "question": "Leaf ",
                "answer": "Leaves",
                "mark": 1,
                "id": "pleenglishcategory29-30_9",
                "type": "text"
            },
            {
                "question": "ream of paper",
                "answer": "reams of paper",
                "mark": 1,
                "id": "pleenglishcategory29-30_10",
                "type": "text"
            },
            {
                "question": "hero",
                "answer": "heroes",
                "mark": 1,
                "id": "pleenglishcategory29-30_11",
                "type": "text"
            },
            {
                "question": "shoelace",
                "answer": "shoelaces",
                "mark": 1,
                "id": "pleenglishcategory29-30_12",
                "type": "text"
            },
            {
                "question": "glass",
                "answer": "glasses",
                "mark": 1,
                "id": "pleenglishcategory29-30_13",
                "type": "text"
            },
            {
                "question": "wife",
                "answer": "wives",
                "mark": 1,
                "id": "pleenglishcategory29-30_14",
                "type": "text"
            },
            {
                "question": "brother-in-law",
                "answer": "brothers-in-law",
                "mark": 1,
                "id": "pleenglishcategory29-30_15",
                "type": "text"
            },
            {
                "question": "news",
                "answer": "news",
                "mark": 1,
                "id": "pleenglishcategory29-30_16",
                "type": "text"
            },
            {
                "question": "syllabus",
                "answer": "syllabuses",
                "mark": 1,
                "id": "pleenglishcategory29-30_17",
                "type": "text"
            },
            {
                "question": "country",
                "answer": "countries",
                "mark": 1,
                "id": "pleenglishcategory29-30_18",
                "type": "text"
            },
            {
                "question": "cloth",
                "answer": "clothes",
                "mark": 1,
                "id": "pleenglishcategory29-30_19",
                "type": "text"
            },
            {
                "question": "Please Jane, put that <u>diary</u> on my table.",
                "answer": "diaries",
                "mark": 1,
                "id": "pleenglishcategory29-30_20",
                "type": "text"
            },
            {
                "question": "The Government gave some nets to the <u>fisherman.</u>",
                "answer": "fishermen",
                "mark": 1,
                "id": "pleenglishcategory29-30_21",
                "type": "text"
            },
            {
                "question": "wolf",
                "answer": "wolves",
                "mark": 1,
                "id": "pleenglishcategory29-30_22",
                "type": "text"
            },
            {
                "question": "lady",
                "answer": "ladies",
                "mark": 1,
                "id": "pleenglishcategory29-30_23",
                "type": "text"
            },
            {
                "question": "match",
                "answer": "matches",
                "mark": 1,
                "id": "pleenglishcategory29-30_24",
                "type": "text"
            },
            {
                "question": "myself",
                "answer": "ourselves",
                "mark": 1,
                "id": "pleenglishcategory29-30_25",
                "type": "text"
            },
            {
                "question": "head of department",
                "answer": "heads of department",
                "mark": 1,
                "id": "pleenglishcategory29-30_26",
                "type": "text"
            },
            {
                "question": "sheep",
                "answer": "sheep",
                "mark": 1,
                "id": "pleenglishcategory29-30_27",
                "type": "text"
            },
            {
                "question": "goose",
                "answer": "geese",
                "mark": 1,
                "id": "pleenglishcategory29-30_28",
                "type": "text"
            },
            {
                "question": "sister-in-law",
                "answer": "sisters-in-law",
                "mark": 1,
                "id": "pleenglishcategory29-30_29",
                "type": "text"
            },
            {
                "question": "buffalo",
                "answer": "buffaloes",
                "mark": 1,
                "id": "pleenglishcategory29-30_30",
                "type": "text"
            },
            {
                "question": "box",
                "answer": "boxes",
                "mark": 1,
                "id": "pleenglishcategory29-30_31",
                "type": "text"
            },
            {
                "question": "point of order",
                "answer": "points of order",
                "mark": 1,
                "id": "pleenglishcategory29-30_32",
                "type": "text"
            },
            {
                "question": "tomato",
                "answer": "tomatoes",
                "mark": 1,
                "id": "pleenglishcategory29-30_33",
                "type": "text"
            },
            {
                "question": "butterfly",
                "answer": "butterflies",
                "mark": 1,
                "id": "pleenglishcategory29-30_34",
                "type": "text"
            },
            {
                "question": "She told him to remove the <u>flea</u> from his hair.",
                "answer": "fleas",
                "mark": 1,
                "id": "pleenglishcategory29-30_35",
                "type": "text"
            },
            {
                "question": "He bought the <u>ox</u> at a very high price.",
                "answer": "oxen",
                "mark": 1,
                "id": "pleenglishcategory29-30_36",
                "type": "text"
            },
            {
                "question": "Okello bought the new <u>badge</u> for Waya Nursery School uniforms.",
                "answer": "badges",
                "mark": 1,
                "id": "pleenglishcategory29-30_37",
                "type": "text"
            },
            {
                "question": "That donkey has a problem with its <u>hoof.</u>",
                "answer": "hooves",
                "mark": 1,
                "id": "pleenglishcategory29-30_38",
                "type": "text"
            }
        ]
    },
    {
        "instructions": "In each of the questions 31 to 50, select the sentence with the correct rewritten option according to the instruction provided in the brackets.",
        "category": 31,
        "numberOfQuestions ": 20,
        "questions": [
            {
                "question": "Isabella's handwriting is very good. Sam's handwriting is good. (Rewrite as one sentence using:....as....as....)",
                "options": [
                    "Isabella's handwriting is not as good as Sam's.",
                    "Isabella's handwriting is not as very good as Sam's.",
                    "Isabella's handwriting is as good as Sam's.",
                    "Isabella's handwriting is as better as Sam's."
                ],
                "answer": "Isabella's handwriting is as good as Sam's.",
                "mark": 1,
                "id": "pleenglishcategory31-50_1",
                "type": "multipleChoice"
            },
            {
                "question": "Mukulo is carrying Ariho on a bicycle. (Rewrite the sentence beginning: Ariho.....)",
                "options": [
                    "Ariho is being carried by Mukulo on a bicycle.",
                    "Ariho is riding on a bicycle with Mukulo.",
                    "Ariho and Mukulo are cycling together.",
                    "Ariho is carrying Mukulo on a bicycle."
                ],
                "answer": "Ariho is being carried by Mukulo on a bicycle.",
                "mark": 1,
                "id": "pleenglishcategory31-50_2",
                "type": "multipleChoice"
            },
            {
                "question": "Jatel has borrowed my mathematical set. (Rewrite the sentence beginning: I.......)",
                "options": [
                    "I have lent my mathematical set to Jatel.",
                    "I am using Jatel's mathematical set.",
                    "I had borrowed Jatel's mathematical set.",
                    "I want to borrow Jatel's mathematical set."
                ],
                "answer": "I have lent my mathematical set to Jatel.",
                "mark": 1,
                "id": "pleenglishcategory31-50_3",
                "type": "multipleChoice"
            },
            {
                "question": "Here is the boy. His photograph appeared in the newspaper. (Rewrite as one sentence using: ....whose....)",
                "options": [
                    "Here is the boy whose photograph appeared in the newspaper.",
                    "Here is the boy whose photograph is appearing in the newspaper.",
                    "Here is the boy whose newspaper appeared in the photograph.",
                    "Here is the boy whose photograph the newspaper appeared in."
                ],
                "answer": "Here is the boy whose photograph appeared in the newspaper.",
                "mark": 1,
                "id": "pleenglishcategory31-50_4",
                "type": "multipleChoice"
            },
            {
                "question": "\"Where has my aunt gone, Mary?\" asked John. (Rewrite the sentence beginning: John asked.......)",
                "options": [
                    "John asked where has my aunt gone, Mary.",
                    "John asked Mary where his aunt had gone.",
                    "John asked Mary where my aunt has gone.",
                    "John asked where Mary has gone, his aunt."
                ],
                "answer": "John asked Mary where his aunt had gone.",
                "mark": 1,
                "id": "pleenglishcategory31-50_5",
                "type": "multipleChoice"
            },
            {
                "question": "The storm was very strong. It destroyed the new bridge. (Rewrite as one sentence using: ....such....that.....)",
                "options": [
                    "The storm was such strong that it destroyed the new bridge.",
                    "The storm was such that the new bridge was destroyed by it.",
                    "The storm was such that it did not destroy the new bridge.",
                    "The storm was such that it destroyed the new bridge."
                ],
                "answer": "The storm was such strong that it destroyed the new bridge.",
                "mark": 1,
                "id": "pleenglishcategory31-50_6",
                "type": "multipleChoice"
            },
            {
                "question": "The bell rang and the teacher started the lesson later. (Rewrite the sentence beginning: By the time....)",
                "options": [
                    "By the time the lesson started, the bell rang.",
                    "By the time the bell rang, the teacher started the lesson.",
                    "By the time the teacher started the lesson, the bell rang later.",
                    "By the time the teacher rang the bell, the lesson started later."
                ],
                "answer": "By the time the bell rang, the teacher started the lesson.",
                "mark": 1,
                "id": "pleenglishcategory31-50_7",
                "type": "multipleChoice"
            },
            {
                "question": "All children hate parents who are quarrelsome. (Rewrite the sentence beginning: Every child....)",
                "options": [
                    "Every child dislikes parents who are quarrelsome.",
                    "Every child loves parents who are quarrelsome.",
                    "Every child avoids parents who are quarrelsome.",
                    "Every child tolerates parents who are quarrelsome."
                ],
                "answer": "Every child dislikes parents who are quarrelsome.",
                "mark": 1,
                "id": "pleenglishcategory31-50_8",
                "type": "multipleChoice"
            },
            {
                "question": "The Primary Leaving Examination results may be released in January. The Primary Leaving Examination results may be released in February. (Rewrite as one sentence using: ....either....)",
                "options": [
                    "The Primary Leaving Examination results may be released either in January or February.",
                    "The Primary Leaving Examination results may be released either in January nor February.",
                    "The Primary Leaving Examination results may be released either in January but not February.",
                    "The Primary Leaving Examination results may be released either in February but not January."
                ],
                "answer": "The Primary Leaving Examination results may be released either in January or February.",
                "mark": 1,
                "id": "pleenglishcategory31-50_9",
                "type": "multipleChoice"
            },
            {
                "question": "The patient is strong. He can bathe himself. (Rewrite as one sentence using: ....enough....)",
                "options": [
                    "The patient is strong, so he can bathe himself.",
                    "The patient is strong enough to bathe himself.",
                    "The patient is strong enough, and he can bathe himself.",
                    "The patient is strong to bathe himself enough."
                ],
                "answer": "The patient is strong enough to bathe himself.",
                "mark": 1,
                "id": "pleenglishcategory31-50_10",
                "type": "multipleChoice"
            },
            {
                "question": "Cindy measured a box. It was a yellow box. It was a big box. (Rewrite as one sentence without using: \"which\", \"that\" or \"and\")",
                "options": [
                    "Cindy measured a yellow, big box.",
                    "Cindy measured a box that was yellow and big.",
                    "Cindy measured a box so yellow and big.",
                    "Cindy measured a big yellow box."
                ],
                "answer": "Cindy measured a big yellow box.",
                "mark": 1,
                "id": "pleenglishcategory31-50_11",
                "type": "multipleChoice"
            },
            {
                "question": "Bruno likes to sit next to his twin sister. (Rewrite the sentence using: .....besides.....)",
                "options": [
                    "Bruno likes to sit next to his twin sister besides.",
                    "Bruno likes to sit besides his twin sister.",
                    "Bruno likes to sit next to his twin sister besides him.",
                    "Bruno likes to sit next to his twin sister besides her."
                ],
                "answer": "Bruno likes to sit besides his twin sister.",
                "mark": 1,
                "id": "pleenglishcategory31-50_12",
                "type": "multipleChoice"
            },
            {
                "question": "Apolot might write a letter inviting her aunt to come for a meeting. (Rewrite the sentence using: ....likely.....)",
                "options": [
                    "Apolot is likely writing a letter inviting her aunt to come for a meeting.",
                    "Apolot likely writes a letter inviting her aunt to come for a meeting.",
                    "Apolot likely wrote a letter inviting her aunt to come for a meeting.",
                    "Apolot is likely to write a letter inviting her aunt to come for a meeting."
                ],
                "answer": "Apolot is likely to write a letter inviting her aunt to come for a meeting.",
                "mark": 1,
                "id": "pleenglishcategory31-50_13",
                "type": "multipleChoice"
            },
            {
                "question": "I am not a wild animal. I do not live in the bush. (Rewrite as one sentence beginning: If I....)",
                "options": [
                    "If I am a wild animal, I live in the bush.",
                    "If I live in the bush, I am a wild animal.",
                    "If I were a wild animal, I would live in the bush.",
                    "If I am not a wild animal, I live in the bush."
                ],
                "answer": "If I were a wild animal, I would live in the bush.",
                "mark": 1,
                "id": "pleenglishcategory31-50_14",
                "type": "multipleChoice"
            },
            {
                "question": "There was no charcoal in the store. Mother managed to prepare supper. (Rewrite as one sentence using: Much as ....)",
                "options": [
                    "Much as Mother managed to prepare supper, there was no charcoal in the store.",
                    "There was no charcoal in the store, much as Mother managed to prepare supper.",
                    "Much as there was no charcoal in the store, Mother managed to prepare supper.",
                    "Mother managed to prepare supper, much as there was no charcoal in the store."
                ],
                "answer": "Much as there was no charcoal in the store, Mother managed to prepare supper.",
                "mark": 1,
                "id": "pleenglishcategory31-50_15",
                "type": "multipleChoice"
            },
            {
                "question": "No sooner had I reached home than it started raining. (Rewrite the sentence beginning: Immediately....)",
                "options": [
                    "Immediately, I reached home, and it started raining.",
                    "Immediately, it started raining as I reached home.",
                    "Immediately, I reached home, and then it started raining.",
                    "Immediately, it started raining than I reached home."
                ],
                "answer": "Immediately, it started raining as I reached home.",
                "mark": 1,
                "id": "pleenglishcategory31-50_16",
                "type": "multipleChoice"
            },
            {
                "question": "The candidate was charged with rigging elections. (Rewrite the sentence using: ....accused....)",
                "options": [
                    "The candidate was accused of rigging elections.",
                    "The candidate was accused to rig elections.",
                    "The candidate accused rigging elections.",
                    "The candidate rigging elections accused."
                ],
                "answer": "The candidate was accused of rigging elections.",
                "mark": 1,
                "id": "pleenglishcategory31-50_17",
                "type": "multipleChoice"
            },
            {
                "question": "Eliana writes very well. Musa also writes very well. (Rewrite as one sentence using: .....and so .....)",
                "options": [
                    "Eliana writes very well and so Musa does too.",
                    "Eliana writes very well, and so does Musa.",
                    "Eliana writes very well, and so also Musa.",
                    "Eliana writes very well, and so is Musa."
                ],
                "answer": "Eliana writes very well, and so does Musa.",
                "mark": 1,
                "id": "pleenglishcategory31-50_18",
                "type": "multipleChoice"
            },
            {
                "question": "Peter should wear a school uniform. He should tuck in his shirt. (Rewrite as one sentence beginning: Not only.....)",
                "options": [
                    "Not only should Peter wear a school uniform, he should also tuck in his shirt.",
                    "Not only Peter should wear a school uniform, but also tuck in his shirt.",
                    "Not only Peter should wear a school uniform, but he should also tuck in his shirt.",
                    "Not only Peter should wear a school uniform, also tuck in his shirt."
                ],
                "answer": "Not only should Peter wear a school uniform, he should also tuck in his shirt.",
                "mark": 1,
                "id": "pleenglishcategory31-50_19",
                "type": "multipleChoice"
            },
            {
                "question": "Elizabeth likes swimming better than jogging. (Rewrite the sentence using: ....prefers....)",
                "options": [
                    "Elizabeth prefers swimming rather than jogging.",
                    "Elizabeth prefers jogging to swimming.",
                    "Elizabeth prefers swimming over jogging.",
                    "Elizabeth prefers jogging more than swimming."
                ],
                "answer": "Elizabeth prefers jogging to swimming.",
                "mark": 1,
                "id": "pleenglishcategory31-50_20",
                "type": "multipleChoice"
            },
            {
                "question": "What children need is to observe rules and regulations. (Rewrite the sentence beginning: Children\u2026.)",
                "options": [
                    "Children require following rules and regulations.",
                    "Children must be following rules and regulations.",
                    "Children should observe rules and regulations.",
                    "Children want to observe rules and regulations."
                ],
                "answer": "Children should observe rules and regulations.",
                "mark": 1,
                "id": "pleenglishcategory31-50_21",
                "type": "multipleChoice"
            },
            {
                "question": "Playing netball is interesting. Playing football is very interesting. (Rewrite as one sentence using:\u2026.more\u2026.than\u2026)",
                "options": [
                    "Playing netball is more interesting than playing football.",
                    "Playing football is more interesting than playing netball.",
                    "Playing netball is interesting, but playing football is very interesting.",
                    "Playing football is very interesting, but playing netball is more interesting."
                ],
                "answer": "Playing football is more interesting than playing netball.",
                "mark": 1,
                "id": "pleenglishcategory31-50_22",
                "type": "multipleChoice"
            },
            {
                "question": "The primary seven class cleaned the examination room. (Rewrite the sentence ending: \u2026..class.)",
                "options": [
                    "The examination room was cleaned by the primary seven class.",
                    "The primary seven class cleaned the examination room themselves.",
                    "The examination room was cleaned, and the primary seven class.",
                    "The examination room was cleaned for the primary seven class."
                ],
                "answer": "The examination room was cleaned by the primary seven class.",
                "mark": 1,
                "id": "pleenglishcategory31-50_23",
                "type": "multipleChoice"
            },
            {
                "question": "\"I am going for holidays next week,\" said Brenda. (Rewrite the sentence beginning: Brenda said\u2026..)",
                "options": [
                    "Brenda said she will be going for holidays next week.",
                    "Brenda said she went for holidays last week.",
                    "Brenda said she is going for holidays this week.",
                    "Brenda said she is going for holidays next week."
                ],
                "answer": "Brenda said she will be going for holidays next week.",
                "mark": 1,
                "id": "pleenglishcategory31-50_24",
                "type": "multipleChoice"
            },
            {
                "question": "Our school has a very beautiful compound. (Rewrite the sentence beginning: What\u2026.!)",
                "options": [
                    "What has our school a very beautiful compound!",
                    "What our school has a very beautiful compound!",
                    "What does our school have, a very beautiful compound!",
                    "What a very beautiful compound our school has!"
                ],
                "answer": "What a very beautiful compound our school has!",
                "mark": 1,
                "id": "pleenglishcategory31-50_25",
                "type": "multipleChoice"
            },
            {
                "question": "I am not a cat. I will not hunt for rats. (Rewrite as one sentence beginning: If\u2026.)",
                "options": [
                    "If I am not a cat, I will hunt for rats.",
                    "If I were a cat, I will hunt for rats.",
                    "If I am not a cat, I will not hunt for rats.",
                    "If I were a cat, I would hunt for rats."
                ],
                "answer": "If I were a cat, I would hunt for rats.",
                "mark": 1,
                "id": "pleenglishcategory31-50_26",
                "type": "multipleChoice"
            },
            {
                "question": "We usually eat a balanced diet so that we can grow up well. (Rewrite the sentence using:\u2026.. in order to\u2026..)",
                "options": [
                    "We usually eat a balanced diet in order to grow up well.",
                    "We usually eat a balanced diet so that growing up well can happen.",
                    "We usually eat a balanced diet to grow up well.",
                    "We usually eat a balanced diet so that we grow up well."
                ],
                "answer": "We usually eat a balanced diet in order to grow up well.",
                "mark": 1,
                "id": "pleenglishcategory31-50_27",
                "type": "multipleChoice"
            },
            {
                "question": "The baby can take milk. The baby can eat porridge. (Rewrite as one sentence using: \u2026.either\u2026.)",
                "options": [
                    "The baby can either take milk or eat porridge.",
                    "The baby can either take milk and eat porridge.",
                    "The baby can either take milk nor eat porridge.",
                    "The baby can either take milk but eat porridge."
                ],
                "answer": "The baby can either take milk or eat porridge.",
                "mark": 1,
                "id": "pleenglishcategory31-50_28",
                "type": "multipleChoice"
            },
            {
                "question": "Teachers should guide children against immoral dancing. This act embarrasses the nation. (Rewrite as one sentence using:\u2026..avoid\u2026.)",
                "options": [
                    "Teachers should guide children to avoid immoral dancing, which embarrasses the nation.",
                    "Teachers should guide children against immoral dancing; this act embarrasses the nation.",
                    "Teachers should guide children to avoid immoral dancing because it embarrasses the nation.",
                    "Teachers should guide children to avoid immoral dancing, thereby embarrassing the nation."
                ],
                "answer": "Teachers should guide children to avoid immoral dancing because it embarrasses the nation.",
                "mark": 1,
                "id": "pleenglishcategory31-50_29",
                "type": "multipleChoice"
            },
            {
                "question": "Hardly had the rains begun when we planted beans. (Rewrite the sentence beginning: No sooner\u2026.)",
                "options": [
                    "No sooner the rains had begun than we planted beans.",
                    "No sooner we planted beans than the rains had begun.",
                    "No sooner had the rains begun than we had planted beans.",
                    "No sooner had the rains begun than we planted beans."
                ],
                "answer": "No sooner had the rains begun than we planted beans.",
                "mark": 1,
                "id": "pleenglishcategory31-50_30",
                "type": "multipleChoice"
            },
            {
                "question": "Magu does not know how to weave a basket. Rose does not know how to weave a basket. (Rewrite as one sentence using:\u2026.and neither\u2026..)",
                "options": [
                    "Neither Magu nor Rose know how to weave a basket.",
                    "Magu and Rose neither know how to weave a basket.",
                    "Magu does not know how to weave a basket and neither does Rose.",
                    "Neither Magu nor Rose both know how to weave a basket."
                ],
                "answer": "Magu does not know how to weave a basket and neither does Rose.",
                "mark": 1,
                "id": "pleenglishcategory31-50_31",
                "type": "multipleChoice"
            },
            {
                "question": "There isn't some food in the dish. (Rewrite the sentence using: \u2026.any\u2026.)",
                "options": [
                    "There is any food in the dish.",
                    " There is some food in the dish.",
                    "There isn't any food in the dish.",
                    "There is not any food in the dish."
                ],
                "answer": "There isn't any food in the dish.",
                "mark": 1,
                "id": "pleenglishcategory31-50_32",
                "type": "multipleChoice"
            },
            {
                "question": "The nurse will treat the patients in the evening. (Rewrite the sentence beginning: The patients\u2026..)",
                "options": [
                    "The patients will treat the nurse in the evening.",
                    "The patients will be treated by the nurse in the evening.",
                    "The patients will treat in the evening by the nurse.",
                    "The patients will treat in the evening the nurse."
                ],
                "answer": "The patients will be treated by the nurse in the evening.",
                "mark": 1,
                "id": "pleenglishcategory31-50_33",
                "type": "multipleChoice"
            },
            {
                "question": "Who has broken my cup? (Rewrite the sentence using: By whom....)",
                "options": [
                    "By whom has my cup been broken?",
                    "By whom my cup has been broken?",
                    "By whom broken my cup?",
                    "By whom has broken my cup?"
                ],
                "answer": "By whom has my cup been broken?",
                "mark": 1,
                "id": "pleenglishcategory31-50_34",
                "type": "multipleChoice"
            },
            {
                "question": "What is the cost of a bull in your area? (Rewrite the sentence beginning: How\u2026)",
                "options": [
                    "How is the cost of a bull in your area?",
                    "How much is the cost of a bull in your area?",
                    "How is the cost of a bull in your area?",
                    "How much a bull cost in your area?"
                ],
                "answer": "How much is the cost of a bull in your area?",
                "mark": 1,
                "id": "pleenglishcategory31-50_35",
                "type": "multipleChoice"
            },
            {
                "question": "A television is good and so is a radio (Rewrite the sentence using: Both)",
                "options": [
                    "Both a television and a radio are good.",
                    "Both a television and a radio are not good.",
                    "Both a television and a radio are better.",
                    "Both a television and a radio are bad."
                ],
                "answer": "Both a television and a radio are good.",
                "mark": 1,
                "id": "pleenglishcategory31-50_36",
                "type": "multipleChoice"
            },
            {
                "question": "I would like to use the washroom. (Rewrite the sentence beginning: May\u2026.)",
                "options": [
                    "May I like to use the washroom?",
                    "May I have used the washroom?",
                    "May I use the washroom?",
                    "May I use the washroom I would like?"
                ],
                "answer": "May I use the washroom?",
                "mark": 1,
                "id": "pleenglishcategory31-50_37",
                "type": "multipleChoice"
            },
            {
                "question": "Fighting fellow pupils is bad. (Rewrite the sentence beginning: To\u2026.)",
                "options": [
                    "To fight fellow pupils can be good.",
                    "To fight fellow pupils, is it bad?",
                    "To fight fellow pupils, which is bad?",
                    "To fight fellow pupils is bad."
                ],
                "answer": "To fight fellow pupils is bad.",
                "mark": 1,
                "id": "pleenglishcategory31-50_38",
                "type": "multipleChoice"
            },
            {
                "question": "The leather bag is mine. It is maroon. It is small. (Rewrite as one sentence without using: \u201cwho\u201d, \u201cwhich\u201d, \u201cthat\u201d, \u201cand\u201d)",
                "options": [
                    "The leather bag, maroon and small, is mine.",
                    "The leather bag is mine, it is maroon and small.",
                    "The small leather maroon bag is mine.",
                    "The leather bag is mine, it is small and maroon."
                ],
                "answer": "The small leather maroon bag is mine.",
                "mark": 1,
                "id": "pleenglishcategory31-50_39",
                "type": "multipleChoice"
            },
            {
                "question": "The bus reached the school compound twenty minutes late. (Rewrite the sentence using: \u2026 arrived\u2026)",
                "options": [
                    "The bus arrived the school compound twenty minutes late.",
                    "The bus arrived at the school compound twenty minutes late.",
                    "The bus arrived the school compound twenty minutes later.",
                    "The bus arrived at the school compound twenty minutes after."
                ],
                "answer": "The bus arrived at the school compound twenty minutes late.",
                "mark": 1,
                "id": "pleenglishcategory31-50_40",
                "type": "multipleChoice"
            },
            {
                "question": "I have to pass this examination highly. (Rewrite the sentence using \u2026\u2026\u2026\u2026\u2026\u2026must \u2026\u2026\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "I must pass this examination highly.",
                    "Must pass this examination highly.",
                    "This examination must be passed highly."
                ],
                "answer": "I must pass this examination highly.",
                "mark": 1,
                "id": "pleenglishcategory31-50_41",
                "type": "multipleChoice"
            },
            {
                "question": "If Mirriam had gone to the internet cafe, she would have sent an e-mail to Teddy. (Rewrite the sentence using \u2026\u2026\u2026\u2026went\u2026\u2026\u2026\u2026)",
                "options": [
                    "If Mirriam went to the internet cafe, she would sent an e-mail to Teddy.",
                    "Teddy would have received an e-mail if Mirriam had gone to the cafe.",
                    "If Teddy went to the internet cafe, she would sent an e-mail to Mirriam."
                ],
                "answer": "If Mirriam went to the internet cafe, she would sent an e-mail to Teddy.",
                "mark": 1,
                "id": "pleenglishcategory31-50_42",
                "type": "multipleChoice"
            },
            {
                "question": "She was talking to someone, wasn\u2019t she? (Rewrite the sentence ending: \u2026\u2026\u2026\u2026 was she?)",
                "options": [
                    "She is talking to someone, was she?",
                    "She was talking to someone, was she?",
                    "She was not talking to someone, was she?"
                ],
                "answer": "She was not talking to someone, was she?",
                "mark": 1,
                "id": "pleenglishcategory31-50_43",
                "type": "multipleChoice"
            },
            {
                "question": "The proposer say that water is better than fire. (Rewrite the sentence ending: \u2026\u2026\u2026\u2026\u2026\u201d the proposers say.)",
                "options": [
                    "\u201cFire is better than water.\u201d the opposers say.",
                    "\u201cWater is better than fire.\u201d the proposers say.",
                    "\u201cFire is better than water.\u201d the proposers say."
                ],
                "answer": "\u201cWater is better than fire.\u201d the proposers say.",
                "mark": 1,
                "id": "pleenglishcategory31-50_44",
                "type": "multipleChoice"
            },
            {
                "question": "My little sister is very ill. She can\u2019t eat any food. (Rewrite as one sentence using: \u2026\u2026\u2026so\u2026\u2026that\u2026\u2026\u2026)",
                "options": [
                    "My little sister is so ill that she can\u2019t eat any food.",
                    "My little sister is so ill that she can eat any food.",
                    "My little sister is so ill that she didn't eat any food."
                ],
                "answer": "My little sister is so ill that she can\u2019t eat any food.",
                "mark": 1,
                "id": "pleenglishcategory31-50_45",
                "type": "multipleChoice"
            },
            {
                "question": "Brenda Junior was sewing. The needle pricked him. (Rewrite as one sentence using: \u2026\u2026\u2026\u2026while\u2026\u2026\u2026\u2026)",
                "options": [
                    "While Brenda was sewing, the needle pricked him.",
                    "The needle pricked him while he was sewing.",
                    "The needle pricked Brenda junior while he was sewing."
                ],
                "answer": "The needle pricked Brenda junior while he was sewing.",
                "mark": 1,
                "id": "pleenglishcategory31-50_46",
                "type": "multipleChoice"
            },
            {
                "question": "Obonyo has a very kind mother. (Rewrite the sentence beginning: What\u2026\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "What a kind mother, Obonyo\u2019s mother is!",
                    "What Obonyo's mother a kind woman she is!",
                    "She is a kind woman, Obonyo's mother."
                ],
                "answer": "What a kind mother, Obonyo\u2019s mother is!",
                "mark": 1,
                "id": "pleenglishcategory31-50_47",
                "type": "multipleChoice"
            },
            {
                "question": "You should say sorry whenever you make a mistake. Rewrite the sentence using: \u2026\u2026\u2026\u2026\u2026apologise\u2026\u2026\u2026\u2026)",
                "options": [
                    "Apologise whenever you make a mistake.",
                    "You should apologise whenever you make a mistake.",
                    "When you make a mistake, apologise."
                ],
                "answer": "You should apologise whenever you make a mistake.",
                "mark": 1,
                "id": "pleenglishcategory31-50_48",
                "type": "multipleChoice"
            },
            {
                "question": "None of the garments was clean. (Rewrite the sentence beginning: All\u2026\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "All the garments were clean.",
                    "All the garments were not dirty.",
                    "All the garments were dirty."
                ],
                "answer": "All the garments were dirty.",
                "mark": 1,
                "id": "pleenglishcategory31-50_49",
                "type": "multipleChoice"
            },
            {
                "question": "Some cartoons that appear in the newspaper are drawn by Clare.(Rewrite the sentence using: .....some of......)",
                "options": [
                    "In the newspaper, some of the cartoons are drawn by Clare.",
                    "Clare draws some of the cartoons that appear in the newspaper",
                    "Some of the cartoons that appear in the newspaper are drawn by Clare."
                ],
                "answer": "In the newspaper, some of the cartoons are drawn by Clare.",
                "mark": 1,
                "id": "pleenglishcategory31-50_50",
                "type": "multipleChoice"
            },
            {
                "question": "Michael is short. Mathew is very short. (Rewrite as one sentence using: \u2026\u2026\u2026\u2026than\u2026\u2026\u2026\u2026)",
                "options": [
                    "Mathew is shorter than Michael.",
                    "Michael is shorter than Mathew."
                ],
                "answer": "Mathew is shorter than Michael.",
                "mark": 1,
                "id": "pleenglishcategory31-50_51",
                "type": "multipleChoice"
            },
            {
                "question": "Nancy is a hard-working girl. Jane is also a hard-working girl.(Rewrite as one sentence using \u2026\u2026\u2026\u2026both\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "Both Nancy and Jane are hard-working girls.",
                    "Nancy and Jane are both hard-working girls."
                ],
                "answer": "Nancy and Jane are both hard-working girls.",
                "mark": 1,
                "id": "pleenglishcategory31-50_52",
                "type": "multipleChoice"
            },
            {
                "question": "The mechanic repaired my father\u2019s car. (Rewrite the sentence and end: \u2026\u2026\u2026\u2026by the mechanic.)",
                "options": [
                    "My father\u2019s car was repaired by the mechanic.",
                    "My father repaired the car by the mechanic."
                ],
                "answer": "My father\u2019s car was repaired by the mechanic.",
                "mark": 1,
                "id": "pleenglishcategory31-50_53",
                "type": "multipleChoice"
            },
            {
                "question": "Nakubulwa Shalom prefers reading storybooks to playing. (Rewrite the sentence using: \u2026\u2026\u2026\u2026more interested\u2026\u2026\u2026\u2026)",
                "options": [
                    "Nakubulwa is more interested in reading storybooks.",
                    "Nakubulwa is more interested in playing than reading storybooks.",
                    "Nakubulwa is more interested in reading storybooks than playing."
                ],
                "answer": "Nakubulwa is more interested in reading storybooks than playing.",
                "mark": 1,
                "id": "pleenglishcategory31-50_54",
                "type": "multipleChoice"
            },
            {
                "question": "Our school has not organized any tour for two years. (Rewrite the sentence ending \u2026\u2026\u2026\u2026\u2026ago)",
                "options": [
                    "Our school has not organized any tour since two years ago.",
                    "Our school has organized any tour since two years ago."
                ],
                "answer": "Our school has not organized any tour since two years ago.",
                "mark": 1,
                "id": "pleenglishcategory31-50_55",
                "type": "multipleChoice"
            },
            {
                "question": "The journalist needn\u2019t have written false reports.(Rewrite the sentence using \u2026\u2026\u2026\u2026necessary\u2026\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "It was not necessary for the Journalist to write false report.",
                    "It was necessary for the Journalist to write false report."
                ],
                "answer": "It was not necessary for the Journalist to write false report.",
                "mark": 1,
                "id": "pleenglishcategory31-50_56",
                "type": "multipleChoice"
            },
            {
                "question": "We shall have fantastic carols in the afternoon. (Rewrite the sentence using: \u2026\u2026going to \u2026\u2026)",
                "options": [
                    "We are going to have fantastic carols.",
                    "We are going to have fantastic carols in the afternoon"
                ],
                "answer": "We are going to have fantastic carols in the afternoon",
                "mark": 1,
                "id": "pleenglishcategory31-50_57",
                "type": "multipleChoice"
            },
            {
                "question": "Every time I fall sick, I see a doctor. (Rewrite the sentence using \u2026\u2026\u2026whenever\u2026\u2026\u2026\u2026)",
                "options": [
                    "I see a doctor whenever I fall sick.",
                    "Whenever I fall sick, I see a doctor."
                ],
                "answer": "I see a doctor whenever I fall sick.",
                "mark": 1,
                "id": "pleenglishcategory31-50_58",
                "type": "multipleChoice"
            },
            {
                "question": "If Lubanga does not obey the school rules, he will be expelled from school. (Rewrite the sentence beginning: Lubanga will not\u2026\u2026\u2026\u2026)",
                "options": [
                    "Lubanga will be expelled from school when he obeys school rules.",
                    "Lubanga will not be expelled from school when he obeys school rules."
                ],
                "answer": "Lubanga will not be expelled from school when he obeys school rules.",
                "mark": 1,
                "id": "pleenglishcategory31-50_59",
                "type": "multipleChoice"
            },
            {
                "question": "That book is Mary\u2019s.  (Rewrite the sentence using \u2026\u2026\u2026\u2026belongs\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "That book belongs to Mary.",
                    "That book doesnot belong to Mary."
                ],
                "answer": "That book belongs to Mary.",
                "mark": 1,
                "id": "pleenglishcategory31-50_60",
                "type": "multipleChoice"
            },
            {
                "question": "The boy came late. The boy missed the lesson. (Re-write as one sentence using\u2026\u2026because\u2026\u2026\u2026)",
                "options": [
                    "The boy came late beause he missed the lesson.",
                    "The boy missed the lesson beause he came late."
                ],
                "answer": "The boy missed the lesson beause he came late.",
                "mark": 1,
                "id": "pleenglishcategory31-50_61",
                "type": "multipleChoice"
            },
            {
                "question": "The thieves were arrested. They stole the carpenter\u2019s furniture. (Re-write as one sentence using\u2026\u2026\u2026\u2026\u2026who\u2026\u2026\u2026\u2026)",
                "options": [
                    "The thieves who stole the carpenter\u2019s furniture were arrested.",
                    "The carpenter\u2019s furniture which was stolen, the thieves were arrested."
                ],
                "answer": "The thieves who stole the carpenter\u2019s furniture were arrested.",
                "mark": 1,
                "id": "pleenglishcategory31-50_62",
                "type": "multipleChoice"
            },
            {
                "question": "The priest will pray with the orphans. The priest will pray with the widow. (re-write as one sentence using\u2026\u2026\u2026\u2026both\u2026\u2026\u2026\u2026)",
                "options": [
                    "The priest will pray with both the orphans and widow.",
                    "The priest will pray with both the orphans, widow."
                ],
                "answer": "The priest will pray with both the orphans and widow.",
                "mark": 1,
                "id": "pleenglishcategory31-50_63",
                "type": "multipleChoice"
            },
            {
                "question": "The audience was excited after the guest of honor had spoken.(Re-write as one sentence ending: \u2026\u2026\u2026\u2026\u2026speech.)",
                "options": [
                    "The audience was excited after the guest of honor speech.",
                    "The audience was excited after the guest of honor's speech."
                ],
                "answer": "The audience was excited after the guest of honor's speech.",
                "mark": 1,
                "id": "pleenglishcategory31-50_64",
                "type": "multipleChoice"
            },
            {
                "question": "Immediately mother reached home, the guest of honour had spoken. (Re-write sentence beginning: No sooner\u2026\u2026\u2026\u2026)",
                "options": [
                    "No sooner.",
                    "The audience was excited after the guest of honor's speech."
                ],
                "answer": "The audience was excited after the guest of honor's speech.",
                "mark": 1,
                "id": "pleenglishcategory31-50_65",
                "type": "multipleChoice"
            },
            {
                "question": "This bicycle belongs to Sekabi. (Rewrite the sentence beginning This is\u2026\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "This is the bicycle that belongs to Sekabi.",
                    "This is Sekabi's bicycle."
                ],
                "answer": "This is Sekabi's bicycle.",
                "mark": 1,
                "id": "pleenglishcategory31-50_66",
                "type": "multipleChoice"
            },
            {
                "question": "The head teacher has asked me to call you. (Rewrite the sentence beginning: I\u2026\u2026\u2026\u2026)",
                "options": [
                    "I was asked by the head teacher to call you.",
                    "I called you when the head teacher asked me."
                ],
                "answer": "I was asked by the head teacher to call you.",
                "mark": 1,
                "id": "pleenglishcategory31-50_67",
                "type": "multipleChoice"
            },
            {
                "question": "The elephant is a very big animal. (Re-write then sentence beginning, What \u2026\u2026\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "What a big animal an elephant is.",
                    "What a big animal an elephant is!"
                ],
                "answer": "What a big animal an elephant is!",
                "mark": 1,
                "id": "pleenglishcategory31-50_68",
                "type": "multipleChoice"
            },
            {
                "question": "When will Daniel go to the library? (Re-write the sentence beginning: on which\u2026\u2026\u2026\u2026)",
                "options": [
                    "What a big animal an elephant is.",
                    "What a big animal an elephant is!"
                ],
                "answer": "What a big animal an elephant is!",
                "mark": 1,
                "id": "pleenglishcategory31-50_69",
                "type": "multipleChoice"
            },
            {
                "question": "The choir sang very well. Everybody enjoyed the music. (Re- write as one sentence using \u2026\u2026\u2026\u2026\u2026so\u2026\u2026\u2026\u2026\u2026that\u2026\u2026\u2026\u2026)",
                "options": [
                    "The choir sang so well that everybody enjoyed the music.",
                    "The choir sang so very well that everybody enjoyed the music."
                ],
                "answer": "The choir sang so well that everybody enjoyed the music.",
                "mark": 1,
                "id": "pleenglishcategory31-50_70",
                "type": "multipleChoice"
            },
            {
                "question": "Does Mary prefer tailoring to baking? (Re- write the sentence using\u2026\u2026like\u2026\u2026)",
                "options": [
                    "Does Mary like tailoring to baking?",
                    "Does Mary like tailoring more than baking?"
                ],
                "answer": "Does Mary like tailoring more than baking?",
                "mark": 1,
                "id": "pleenglishcategory31-50_71",
                "type": "multipleChoice"
            },
            {
                "question": "\u201cI will not come to school tomorrow,\u201d said John. (Re-write the sentence beginning: John said that\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "John said that he will not come to school tomorrow.",
                    "John said that he will come to school tomorrow."
                ],
                "answer": "John said that he will not come to school tomorrow.",
                "mark": 1,
                "id": "pleenglishcategory31-50_72",
                "type": "multipleChoice"
            },
            {
                "question": "If Ivan completes primary seven, he will train as a baker. (Re-write sentence using \u2026\u2026would train\u2026\u2026)",
                "options": [
                    "If Ivan completed primary seven, he would train as a baker.",
                    "If Ivan completes primary seven, he would train as a baker."
                ],
                "answer": "If Ivan completed primary seven, he would train as a baker.",
                "mark": 1,
                "id": "pleenglishcategory31-50_73",
                "type": "multipleChoice"
            },
            {
                "question": "The letter is being written by Josephine now. (Re- write the sentence: beginning Josephine\u2026\u2026)",
                "options": [
                    "Josephine is writing the letter now.",
                    "Josephine is writing the letter."
                ],
                "answer": "Josephine is writing the letter.",
                "mark": 1,
                "id": "pleenglishcategory31-50_74",
                "type": "multipleChoice"
            },
            {
                "question": "After saying our prayer, we went to bed. (Re-write the sentence using\u2026\u2026\u2026 before \u2026\u2026)",
                "options": [
                    "We said our parayer after going to bed.",
                    "We said our parayer before going to bed."
                ],
                "answer": "We said our parayer before going to bed.",
                "mark": 1,
                "id": "pleenglishcategory31-50_75",
                "type": "multipleChoice"
            },
            {
                "question": "Kapere bought some sugar from Mr. Owich. ( Rewrite the sentence beginning Mr. Owich \u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "Mr. Owich bought some sugar from Kapere.",
                    "Mr. Owich sold some sugar to Kapere."
                ],
                "answer": "Mr. Owich sold some sugar to Kapere.",
                "mark": 1,
                "id": "pleenglishcategory31-50_76",
                "type": "multipleChoice"
            },
            {
                "question": "The teachers did not come to the party. The parents did not come to the party. (Re-write as one sentence beginning: Neither\u2026\u2026\u2026\u2026)",
                "options": [
                    "Neither the teachers nor the parents came to the party.",
                    "Neither the teachers or the parents came to the party."
                ],
                "answer": "Neither the teachers nor the parents came to the party.",
                "mark": 1,
                "id": "pleenglishcategory31-50_77",
                "type": "multipleChoice"
            },
            {
                "question": "She is a very honest seamstress. All the ladies trust her. (Re-write the sentence using \u2026 such\u2026that\u2026\u2026)",
                "options": [
                    "She is such that a honest seamstress all ladies trust her.",
                    "She is such a honest seamstress that all ladies trust her."
                ],
                "answer": "She is such a honest seamstress that all ladies trust her.",
                "mark": 1,
                "id": "pleenglishcategory31-50_78",
                "type": "multipleChoice"
            },
            {
                "question": "They reached the zoo late because their bus had broken down. (Re write the sentence using \u2026.arrived\u2026\u2026\u2026)",
                "options": [
                    "They arrived at the zoo late because their bus had broken down.",
                    "They arrived the zoo late because their bus had broken down."
                ],
                "answer": "They arrived at the zoo late because their bus had broken down.",
                "mark": 1,
                "id": "pleenglishcategory31-50_79",
                "type": "multipleChoice"
            },
            {
                "question": "Jacob and James like biscuits more than buns. (Re-write sentence using \u2026\u2026.prefer\u2026.)",
                "options": [
                    "Jacob and James prefer biscuit to burns.",
                    "Jacob and James prefer burns to biscuit."
                ],
                "answer": "Jacob and James prefer biscuit to burns.",
                "mark": 1,
                "id": "pleenglishcategory31-50_80",
                "type": "multipleChoice"
            },
            {
                "question": "My brother was a fast runner. He won medals. (Re- write sentence using \u2026\u2026\u2026such\u2026\u2026\u2026that\u2026\u2026\u2026)",
                "options": [
                    "My brother was such a fast runner he won medals.",
                    "My brother was such a fast runner that he won medals."
                ],
                "answer": "My brother was such a fast runner that he won medals.",
                "mark": 1,
                "id": "pleenglishcategory31-50_81",
                "type": "multipleChoice"
            },
            {
                "question": "\u201cWhere is the puppy, Tom?\u201d Topista asked.( Re- write sentence using  \u2026\u2026\u2026\u2026\u2026asked\u2026\u2026\u2026\u2026) ",
                "options": [
                    "Tom asked Topsita where the puppy was.",
                    "Topsita asked Tom where the puppy was."
                ],
                "answer": "Topsita asked Tom where the puppy was.",
                "mark": 1,
                "id": "pleenglishcategory31-50_82",
                "type": "multipleChoice"
            },
            {
                "question": "Nsubuga read many books. He became very bright. (Re-write the sentence beginning: The more \u2026\u2026\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "The more books Nsubuga read the brighter he became.",
                    "The more brighter Nsubuga became, the more books he read."
                ],
                "answer": "The more books Nsubuga read the brighter he became.",
                "mark": 1,
                "id": "pleenglishcategory31-50_83",
                "type": "multipleChoice"
            },
            {
                "question": "Despite the fact that Alice was sick during the examination, she got a first grade.(Rewrite sentence using\u2026\u2026\u2026\u2026but\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "Alice was sick during the examination but she got a first grade.",
                    "Alice was sick during the examination but she did not get a first grade."
                ],
                "answer": "Alice was sick during the examination but she got a first grade.",
                "mark": 1,
                "id": "pleenglishcategory31-50_84",
                "type": "multipleChoice"
            },
            {
                "question": "The shopkeeper said that he had no sugar. (Re-write sentence \u2026\u2026\u2026any\u2026\u2026)",
                "options": [
                    "The shopkeeper said that he had any sugar.",
                    "The shopkeeper said that he did not have any sugar."
                ],
                "answer": "The shopkeeper said that he did not have any sugar.",
                "mark": 1,
                "id": "pleenglishcategory31-50_85",
                "type": "multipleChoice"
            },
            {
                "question": "No sooner had the customer entered the restaurant than the waiter collapsed. (Re -write sentence using:\u2026\u2026\u2026immediately\u2026\u2026\u2026)",
                "options": [
                    "The waiter collapsed immediately had the customer entered the restaurant.",
                    "The waiter collapsed immediately the customer entered the restaurant."
                ],
                "answer": "The waiter collapsed immediately the customer entered the restaurant.",
                "mark": 1,
                "id": "pleenglishcategory31-50_86",
                "type": "multipleChoice"
            },
            {
                "question": "Mrs. Oda made a cake. It was circular. It was tasty. (Re- write as one sentence without using: which, and, that)",
                "options": [
                    "Mrs. Oda made a tasty circular cake.",
                    "Mrs. Oda made a circular tasty cake."
                ],
                "answer": "Mrs. Oda made a tasty circular cake.",
                "mark": 1,
                "id": "pleenglishcategory31-50_87",
                "type": "multipleChoice"
            },
            {
                "question": "Jeremiah is a disciplined boy so is Jesse. (Re- write the sentence beginning Both\u2026)",
                "options": [
                    "Both Jesse and Jeremiah are disciplined boys.",
                    "Both Jesse is as disciplined as Jeremiah."
                ],
                "answer": "Both Jesse and Jeremiah are disciplined boys.",
                "mark": 1,
                "id": "pleenglishcategory31-50_88",
                "type": "multipleChoice"
            },
            {
                "question": "He reached the railway station before noon. (Re- write the sentence using\u2026arrived\u2026.)",
                "options": [
                    "He arrived to the railway station before noon.",
                    "He arrived at the railway station before noon."
                ],
                "answer": "He arrived at the railway station before noon.",
                "mark": 1,
                "id": "pleenglishcategory31-50_89",
                "type": "multipleChoice"
            },
            {
                "question": "If you participate in debating, you develop confidence. ( Re- write  the sentence using\u2026\u2026\u2026\u2026\u2026would develop\u2026\u2026\u2026\u2026)",
                "options": [
                    "You would develop confidence if you participated in debate.",
                    "You would develop confidence if you debate."
                ],
                "answer": "You would develop confidence if you participated in debate.",
                "mark": 1,
                "id": "pleenglishcategory31-50_90",
                "type": "multipleChoice"
            },
            {
                "question": "Asiimwe is the hunter. I told you about him. (Re- write  as one  sentence using \u2026\u2026\u2026\u2026whom\u2026\u2026\u2026\u2026)",
                "options": [
                    "I told you about Assimwe whom is a hunter.",
                    "Assimwe is the hunter whom I told you."
                ],
                "answer": "Assimwe is the hunter whom I told you.",
                "mark": 1,
                "id": "pleenglishcategory31-50_91",
                "type": "multipleChoice"
            },
            {
                "question": "She drew some water from the well, didn't she?(Re- write the sentence ending\u2026\u2026did she?)",
                "options": [
                    "She drew some water from the well, did she?",
                    "She didn't draw any water from the well, did she?"
                ],
                "answer": "She didn't draw any water from the well, did she?",
                "mark": 1,
                "id": "pleenglishcategory31-50_92",
                "type": "multipleChoice"
            },
            {
                "question": "All baskets will be woven by the pupils today. (Re- write the sentence beginning:  Will\u2026\u2026\u2026\u2026\u2026?)",
                "options": [
                    "Will all baskets be woven by the pupil's today",
                    "Will all baskets be woven by the pupil's today?"
                ],
                "answer": "Will all baskets be woven by the pupil's today?",
                "mark": 1,
                "id": "pleenglishcategory31-50_93",
                "type": "multipleChoice"
            },
            {
                "question": "You needn't have recorded many songs on the CD. (Re- write the sentence using\u2026\u2026\u2026\u2026\u2026\u2026necessary\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "It was necessary for you to record many songs on the CD.",
                    "It was not necessary for you to record many songs on the CD."
                ],
                "answer": "It was not necessary for you to record many songs on the CD.",
                "mark": 1,
                "id": "pleenglishcategory31-50_94",
                "type": "multipleChoice"
            },
            {
                "question": "All the candidates who were in that school passed the examinations. (Re-write the sentence beginning: None\u2026\u2026\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "None of the candidates who were in that school failed the examinations.",
                    "None of the candidates who were in that school passed the examinations."
                ],
                "answer": "None of the candidates who were in that school failed the examinations.",
                "mark": 1,
                "id": "pleenglishcategory31-50_95",
                "type": "multipleChoice"
            },
            {
                "question": "Opaya is two metres tall, Oringo is three metres tall. (Re-write as one using \u2026\u2026\u2026\u2026\u2026\u2026not as\u2026\u2026\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "Opaya is not as tall as Oringo.",
                    "Oringo is not as tall as Opoya."
                ],
                "answer": "Opaya is not as tall as Oringo.",
                "mark": 1,
                "id": "pleenglishcategory31-50_96",
                "type": "multipleChoice"
            },
            {
                "question": "What a kind woman her mother is! (Re- write the sentence ending\u2026\u2026\u2026\u2026\u2026\u2026\u2026kind woman.)",
                "options": [
                    "Her mother is not a very kind woman.",
                    "Her mother is an extremely kind woman."
                ],
                "answer": "Her mother is an extremely kind woman.",
                "mark": 1,
                "id": "pleenglishcategory31-50_97",
                "type": "multipleChoice"
            },
            {
                "question": "The farmer has succeeded in watering crops. (Re- write the sentence \u2026\u2026\u2026managed\u2026\u2026\u2026)",
                "options": [
                    "The farmer has managed to water the crops.",
                    "The farmer has not managed to water the crops."
                ],
                "answer": "The farmer has managed to water the crops.",
                "mark": 1,
                "id": "pleenglishcategory31-50_98",
                "type": "multipleChoice"
            },
            {
                "question": "The police didn't have handcuffs. The police didn't arrest the thief. (Rewrite as one sentence beginning: If\u2026\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "If the police didn't have handcuffs, they would have arrested the thief.",
                    "If the police had had handcuffs, they would have arrested the thief."
                ],
                "answer": "If  the police had had handcuffs, they would have arrested the thief.",
                "mark": 1,
                "id": "pleenglishcategory31-50_99",
                "type": "multipleChoice"
            },
            {
                "question": "The pupils who speak good English will be rewarded by the head teacher (Re-write the sentence beginning; The headteacher\u2026\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "The headteacher will reward good English speakers.",
                    "The headteacher will reward the pupils who speak good English."
                ],
                "answer": "The headteacher will reward the pupils who speak good English.",
                "mark": 1,
                "id": "pleenglishcategory31-50_100",
                "type": "multipleChoice"
            },
            {
                "question": "The audience was excited when the minister arrived (Re-write the sentence beginning: The arrival\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "The arrival of the audience excited the minister.",
                    "The arrival of the minister excited the audience."
                ],
                "answer": "The arrival of the minister excited the audience.",
                "mark": 1,
                "id": "pleenglishcategory31-50_101",
                "type": "multipleChoice"
            },
            {
                "question": "The house belongs to her. (Re write the sentence ending\u2026\u2026\u2026\u2026\u2026\u2026hers.)",
                "options": [
                    "The house is hers.",
                    "It is hers."
                ],
                "answer": "The house is hers.",
                "mark": 1,
                "id": "pleenglishcategory31-50_102",
                "type": "multipleChoice"
            },
            {
                "question": "Your father speaks Kiswahili very well, doesn't he? (Re-write the sentence ending \u2026\u2026\u2026\u2026\u2026\u2026does he?)",
                "options": [
                    "Your father does not speak Kwiswahili very well, doesn't he?",
                    "Your father does not speak Kwiswahili very well, does he?"
                ],
                "answer": "Your father does not speak Kwiswahili very well, does he?",
                "mark": 1,
                "id": "pleenglishcategory31-50_103",
                "type": "multipleChoice"
            },
            {
                "question": "Pamela is wearing a short dress. It is pink. It is pretty (Re- write as one sentence without using \u201cand\u201d, \u201cwhich\u201d or \u201cthat\u201d)",
                "options": [
                    "Pamela is wearing a pretty short dress whose color is pink.",
                    "Pamela is wearing a pretty short pink dress."
                ],
                "answer": "Pamela is wearing a pretty short pink dress.",
                "mark": 1,
                "id": "pleenglishcategory31-50_104",
                "type": "multipleChoice"
            },
            {
                "question": "Bbosa has to go Kampala tomorrow (Re-write the sentence using\u2026..will be\u2026\u2026.)",
                "options": [
                    "Bbosa will be going to Kampala tomorrow.",
                    "Bbosa will be gone to Kampala tomorrow."
                ],
                "answer": "Bbosa will be going to Kampala tomorrow.",
                "mark": 1,
                "id": "pleenglishcategory31-50_105",
                "type": "multipleChoice"
            },
            {
                "question": "She always dances wonderfully (Re-write the sentence using \u2026\u2026\u2026\u2026wonderful\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "What a wonderful dancer she is!",
                    "She is a wonderful dancer."
                ],
                "answer": "She is a wonderful dancer.",
                "mark": 1,
                "id": "pleenglishcategory31-50_106",
                "type": "multipleChoice"
            },
            {
                "question": "Geraldine tore my dress because she wanted to annoy me. (Re-write the sentence beginning\u2026\u2026so as \u2026\u2026\u2026)",
                "options": [
                    "Geraldine tore my dress so as to annoy me",
                    "Geraldine tore my dress so that she can annoy me."
                ],
                "answer": "Geraldine tore my dress so as to annoy me.",
                "mark": 1,
                "id": "pleenglishcategory31-50_107",
                "type": "multipleChoice"
            },
            {
                "question": "Did he tell you how he managed to locate the church? (Re- write the sentence using \u2026\u2026\u2026succeeded\u2026\u2026\u2026\u2026)",
                "options": [
                    "Did he tell you how he succeeded in locate the church?",
                    "Did he tell you how he succeeded and managed to locate the church?",
                    "Did he tell you how he succeeded in locating the church?"
                ],
                "answer": "Did he tell you how he succeeded in locating the church?",
                "mark": 1,
                "id": "pleenglishcategory31-50_108",
                "type": "multipleChoice"
            },
            {
                "question": "All of us have not had a meal since yesterday. (Re-write the sentence using: None\u2026\u2026\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "None of us have not taken food since yesterday.",
                    "None of us have taken food since yesterday."
                ],
                "answer": "None of us have taken food since yesterday.",
                "mark": 1,
                "id": "pleenglishcategory31-50_109",
                "type": "multipleChoice"
            },
            {
                "question": "You should tell your parents about the boy who keeps disturbing you to school. (Re-write the sentence using\u2026\u2026ought\u2026\u2026)",
                "options": [
                    "You ought to tell your parents about that boy.",
                    "You ought to tell your parents about the boy who keeps disturbing you to school."
                ],
                "answer": "You ought to tell your parents about the boy who keeps disturbing you to school.",
                "mark": 1,
                "id": "pleenglishcategory31-50_110",
                "type": "multipleChoice"
            },
            {
                "question": "If you read many books. You become knowledgeable. (Re-write the sentence using beginning. The more\u2026\u2026\u2026\u2026)",
                "options": [
                    "The more books you read, the more knowledgeable you become.",
                    "The more books you read, the less knowledge you get."
                ],
                "answer": "The more books you read, the more knowledgeable you become.",
                "mark": 1,
                "id": "pleenglishcategory31-50_111",
                "type": "multipleChoice"
            },
            {
                "question": "The chairperson of the debate was giving the opening remarks. Musa made noise. (Re-write beginning: While\u2026\u2026\u2026\u2026)",
                "options": [
                    "While the chairperson of the debate was giving the opening remarks, Musa made noise.",
                    "While the chairperson of the debate was giving the opening remarks, Musa was talking."
                ],
                "answer": "While the chairperson of the debate was giving the opening remarks, Musa made noise.",
                "mark": 1,
                "id": "pleenglishcategory31-50_112",
                "type": "multipleChoice"
            },
            {
                "question": "Gerald said he would write his Primary Leaving Examination the following year. (Re-write the sentence beginning: Gerald said\u2026.)",
                "options": [
                    "Gerald said \u201cI will write my Primary Leaving Examination next year.\u201d",
                    "Gerald said I will write my Primary Leaving Examination next year."
                ],
                "answer": "Gerald said \u201cI will write my Primary Leaving Examination next year.\u201d",
                "mark": 1,
                "id": "pleenglishcategory31-50_113",
                "type": "multipleChoice"
            },
            {
                "question": "Does this luggage belong to you? (Re-write sentence beginning! Is this\u2026\u2026\u2026\u2026)",
                "options": [
                    "Is this your luggage.",
                    "Is this your luggage?"
                ],
                "answer": "Is this your luggage?",
                "mark": 1,
                "id": "pleenglishcategory31-50_114",
                "type": "multipleChoice"
            },
            {
                "question": "Despite his wisdom, many people do not respect him. (Re-write sentence beginning: Although \u2026\u2026\u2026)",
                "options": [
                    "Although he is wise, many people do not respect him.",
                    "Although he is wise, many people respect him."
                ],
                "answer": "Although he is wise, many people do not respect him.",
                "mark": 1,
                "id": "pleenglishcategory31-50_115",
                "type": "multipleChoice"
            },
            {
                "question": "If that girls does not stop receiving gifts from Mr. Tumbo, she will be expelled from school. (Re-write the  sentence using \u2026\u2026\u2026\u2026unless\u2026\u2026\u2026\u2026)",
                "options": [
                    "That girl will not be expelled from school unless she stops receiving gifts from Mr. Tumbo.",
                    "That girl will be expelled from school unless she stops receiving gifts from Mr. Tumbo."
                ],
                "answer": "That girl will be expelled from school unless she stops receiving gifts from Mr. Tumbo.",
                "mark": 1,
                "id": "pleenglishcategory31-50_116",
                "type": "multipleChoice"
            },
            {
                "question": "The mechanic repaired my bicycle. I paid him (Re-write as one sentence beginning After \u2026\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "After the mechanic repaired my bicycle, I paid him.",
                    "After the mechanic repaired my bicycle, he left."
                ],
                "answer": "After the mechanic repaired my bicycle, I paid him.",
                "mark": 1,
                "id": "pleenglishcategory31-50_117",
                "type": "multipleChoice"
            },
            {
                "question": "The waitress had served some customers when I came (Re-write the sentence beginning: Some customers\u2026\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "Some customers are being served by the waitress when I came.",
                    "Some customers had been served by the waitress when I came."
                ],
                "answer": "Some customers had been served by the waitress when I came.",
                "mark": 1,
                "id": "pleenglishcategory31-50_118",
                "type": "multipleChoice"
            },
            {
                "question": "This is the village. I was born here (Re-write as one sentence using\u2026\u2026\u2026\u2026\u2026where\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "This is the village I was born.",
                    "This is the village where I was born."
                ],
                "answer": "This is the village where I was born.",
                "mark": 1,
                "id": "pleenglishcategory31-50_119",
                "type": "multipleChoice"
            },
            {
                "question": "Animals need protection. People need protection (Join as one sentence using \u2026\u2026\u2026\u2026as well as\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "Animals and people need protection.",
                    "Animals as well as people need protection."
                ],
                "answer": "Animals as well as people need protection.",
                "mark": 1,
                "id": "pleenglishcategory31-50_120",
                "type": "multipleChoice"
            },
            {
                "question": "Mother did not withdraw the money, father did not withdraw the money. (Re-write as one sentence using\u2026\u2026\u2026\u2026\u2026\u2026.and neither\u2026\u2026\u2026\u2026.)",
                "options": [
                    "Mother withdrew the money and neither did father.",
                    "Mother did not withdraw the money and neither did father."
                ],
                "answer": "Mother did not withdraw the money and neither did father.",
                "mark": 1,
                "id": "pleenglishcategory31-50_121",
                "type": "multipleChoice"
            },
            {
                "question": "That is the policewomen, I am related to her. (Re-write as one sentence using\u2026\u2026\u2026\u2026\u2026to whom\u2026\u2026)",
                "options": [
                    "That is the policewoman to whom I am related.",
                    "That is the policewoman to whom she is related."
                ],
                "answer": "That is the policewoman to whom I am related.",
                "mark": 1,
                "id": "pleenglishcategory31-50_122",
                "type": "multipleChoice"
            },
            {
                "question": "The fruits will be eaten by the birds. (Re-write the sentence ending: \u2026\u2026\u2026\u2026\u2026\u2026\u2026fruits)",
                "options": [
                    "The birds will be eating the fruits.",
                    "The birds will eat the fruits."
                ],
                "answer": "The birds will eat the fruits.",
                "mark": 1,
                "id": "pleenglishcategory31-50_123",
                "type": "multipleChoice"
            },
            {
                "question": "We didn't go for a tour, did we? (Re-write sentence ending: \u2026\u2026\u2026\u2026\u2026\u2026 didn't we?)",
                "options": [
                    "We didn't go for a tour, didn't we?",
                    "We went for a tour, didn't we?"
                ],
                "answer": "We went for a tour, didn't we?",
                "mark": 1,
                "id": "pleenglishcategory31-50_124",
                "type": "multipleChoice"
            },
            {
                "question": "As the girls were reciting a poem, the boys were reading a story book. (Re-write the sentence using: \u2026\u2026\u2026\u2026while\u2026\u2026\u2026\u2026)",
                "options": [
                    "The girls were reciting a poem while the boys were reading a story book.",
                    "The boys were reciting a poem while the girls were reading a story book."
                ],
                "answer": "The girls were reciting a poem while the boys were reading a story book.",
                "mark": 1,
                "id": "pleenglishcategory31-50_125",
                "type": "multipleChoice"
            },
            {
                "question": "You will not fail the examination, I will not fail the examination. (Re-write as one sentence beginning: None\u2026\u2026\u2026\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "None of us will pass the examination.",
                    "None of us will fail the examination."
                ],
                "answer": "None of us will fail the examination.",
                "mark": 1,
                "id": "pleenglishcategory31-50_126",
                "type": "multipleChoice"
            },
            {
                "question": "The visitors arrived at the bus park at noon. (Re-write  the sentence beginning: \u2026\u2026\u2026\u2026reached\u2026\u2026\u2026\u2026)",
                "options": [
                    "The visitors reached the bus park before noon.",
                    "The visitors reached the bus park at noon."
                ],
                "answer": "The visitors reached the bus park at noon.",
                "mark": 1,
                "id": "pleenglishcategory31-50_127",
                "type": "multipleChoice"
            },
            {
                "question": "What a busy road that is? (Re-write the sentence beginning: That is\u2026\u2026\u2026\u2026)",
                "options": [
                    "That is a very busy road.",
                    "That is a very busy road, Isn't it?"
                ],
                "answer": "That is a very busy road.",
                "mark": 1,
                "id": "pleenglishcategory31-50_128",
                "type": "multipleChoice"
            },
            {
                "question": "A car knocked down a goat. (Re-write the sentence beginning: A goat \u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "A goat was knocked down by a car.",
                    "A goat knocked down by a car."
                ],
                "answer": "A goat was knocked down by a car.",
                "mark": 1,
                "id": "pleenglishcategory31-50_129",
                "type": "multipleChoice"
            },
            {
                "question": "\u201cWhat is your name?\u201d the shopkeeper asked Brenda. (Re-write the sentence using: \u2026\u2026\u2026\u2026wanted to know\u2026\u2026\u2026\u2026)",
                "options": [
                    "The shopkeeper wanted to know from Brenda what her name was.",
                    "The shopkeeper wanted to know Brenda's name."
                ],
                "answer": "The shopkeeper wanted to know from Brenda what her name was.",
                "mark": 1,
                "id": "pleenglishcategory31-50_130",
                "type": "multipleChoice"
            },
            {
                "question": "The knife is too blunt to cut. (Re-write the sentence using: \u2026\u2026\u2026so\u2026\u2026\u2026that\u2026\u2026)",
                "options": [
                    "The knife is so blunt that it can cut.",
                    "The knife is so blunt that it can not cut."
                ],
                "answer": "The knife is so blunt that it can not cut.",
                "mark": 1,
                "id": "pleenglishcategory31-50_131",
                "type": "multipleChoice"
            },
            {
                "question": "That bag of rice has been sold expensively. (Re-write the sentence beginning: Those\u2026\u2026\u2026\u2026)",
                "options": [
                    "Those bags of rice have been sold expensively.",
                    "Those bag of rice has been sold expensively."
                ],
                "answer": "Those bags of rice have been sold expensively.",
                "mark": 1,
                "id": "pleenglishcategory31-50_132",
                "type": "multipleChoice"
            },
            {
                "question": "Who swept our classroom today? (Re-write the sentence beginning: By whom\u2026\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "By whom was our classroom swept today?",
                    "By whom was our classroom swept today."
                ],
                "answer": "By whom was our classroom swept today?",
                "mark": 1,
                "id": "pleenglishcategory31-50_133",
                "type": "multipleChoice"
            },
            {
                "question": "The old man did not buy palm leaves, He did not make mats.(Re-write as one sentence beginning: if\u2026\u2026\u2026\u2026\u2026.would have\u2026\u2026\u2026.)",
                "options": [
                    "If the old man had bought palm leaves, he would not have made mats.",
                    "If the old man had bought palm leaves, he would have made mats."
                ],
                "answer": "If the old man had bought palm leaves, he would have made mats.",
                "mark": 1,
                "id": "pleenglishcategory31-50_134",
                "type": "multipleChoice"
            },
            {
                "question": "Juma owns the two taxis. (Re- write the sentence using\u2026\u2026\u2026\u2026belong\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "The two taxis belong to Juma.",
                    "One taxi belongs to Juma."
                ],
                "answer": "The two taxis belong to Juma.",
                "mark": 1,
                "id": "pleenglishcategory31-50_135",
                "type": "multipleChoice"
            },
            {
                "question": "The tailor made a suit. The tailor also ironed a suit. (Re-write as on sentence beginning: Besides\u2026\u2026\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "Besides making a suit, the tailor also made it.",
                    "Besides making a suit, the tailor also ironed it."
                ],
                "answer": "Besides making a suit, the tailor also ironed it.",
                "mark": 1,
                "id": "pleenglishcategory31-50_136",
                "type": "multipleChoice"
            },
            {
                "question": "Both Ichat and Bbosa are clever. (Re-write the sentence using\u2026\u2026\u2026\u2026.as clever\u2026\u2026\u2026\u2026)",
                "options": [
                    "Ichat is as clever as Manja.",
                    "Ichat is not as clever as Manja."
                ],
                "answer": "Ichat is as clever as Manja.",
                "mark": 1,
                "id": "pleenglishcategory31-50_137",
                "type": "multipleChoice"
            },
            {
                "question": "Although you loaded airtime on the phone, it was not necessary. (Re-write the sentence using\u2026\u2026\u2026needn't\u2026\u2026\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "You needn't have not loaded airtime on the phone.",
                    "You needn't have loaded airtime on the phone."
                ],
                "answer": "You needn't have loaded airtime on the phone.",
                "mark": 1,
                "id": "pleenglishcategory31-50_138",
                "type": "multipleChoice"
            },
            {
                "question": "Girls who leave school for early marriage usually get problems in future. Rewrite the sentence using \u2026\u2026\u2026\u2026often\u2026\u2026\u2026\u2026)",
                "options": [
                    "Girls who leave school for early marriage often get problems in future.",
                    "Girls who leave school for early marriage often don't get problems in future."
                ],
                "answer": "Girls who leave school for early marriage often get problems in future.",
                "mark": 1,
                "id": "pleenglishcategory31-50_139",
                "type": "multipleChoice"
            },
            {
                "question": "The pupils were tired. They could not go on with their work. (Re-write as one sentence using: \u2026\u2026\u2026too\u2026to\u2026\u2026\u2026)",
                "options": [
                    "The pupils were too tired to go on with their work.",
                    "The pupils were not too tired to go on with their work."
                ],
                "answer": "The pupils were too tired to go on with their work.",
                "mark": 1,
                "id": "pleenglishcategory31-50_140",
                "type": "multipleChoice"
            },
            {
                "question": "Physical Education is a very good subject. It made Kiprotich win a gold medal. (Re-write sentence as one sentence using: \u2026\u2026\u2026\u2026because\u2026\u2026\u2026)",
                "options": [
                    "Physical Education is not good subject because it made Kiprotich win a gold medal.",
                    "Physical Education is very good subject because it made Kiprotich win a gold medal."
                ],
                "answer": "Physical Education is very good subject because it made Kiprotich win a gold medal.",
                "mark": 1,
                "id": "pleenglishcategory31-50_141",
                "type": "multipleChoice"
            },
            {
                "question": "Joyce has not seen Cate for two years. (Re-write the sentence beginning: It is\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "It is two years since Joyce saw Cate.",
                    "It is two years since Cate saw Joyce."
                ],
                "answer": "It is two years since Joyce saw Cate.",
                "mark": 1,
                "id": "pleenglishcategory31-50_142",
                "type": "multipleChoice"
            },
            {
                "question": "Ali does not have a bicycle. Ali does not have a motorcycle. (Re-write as one sentence using: \u2026\u2026\u2026\u2026\u2026\u2026neither\u2026\u2026or\u2026\u2026\u2026\u2026)",
                "options": [
                    "Ali does not have neither a bicycle nor a motorcycle.",
                    "Ali has neither a bicycle nor a motorcycle."
                ],
                "answer": "Ali has neither a bicycle nor a motorcycle.",
                "mark": 1,
                "id": "pleenglishcategory31-50_143",
                "type": "multipleChoice"
            },
            {
                "question": "That is the health centre. My little brother was born there. (Re-write as one sentence using: \u2026\u2026\u2026where\u2026\u2026)",
                "options": [
                    "That is the health center where my little brother stays.",
                    "That is the health center where my little brother was born."
                ],
                "answer": "That is the health center where my little brother was born.",
                "mark": 1,
                "id": "pleenglishcategory31-50_144",
                "type": "multipleChoice"
            },
            {
                "question": "What a wise decision the head boy took! (Re-write the sentence beginning:  The head boy took\u2026\u2026\u2026\u2026)",
                "options": [
                    "The head boy took a very wise decision.",
                    "The head boy did not take a very wise decision."
                ],
                "answer": "The head boy took a very wise decision.",
                "mark": 1,
                "id": "pleenglishcategory31-50_145",
                "type": "multipleChoice"
            },
            {
                "question": "Waiswa plays the guitar very well and so does Kawudha. (Re-write the sentence using: Both\u2026\u2026\u2026\u2026)",
                "options": [
                    "Both Waiswa and Kawundha do not play the guitar very well.",
                    "Both Waiswa and Kawundha play the guitar very well."
                ],
                "answer": "Both Waiswa and Kawundha play the guitar very well.",
                "mark": 1,
                "id": "pleenglishcategory31-50_146",
                "type": "multipleChoice"
            },
            {
                "question": "If you start the journey late, you won't be on time for the examination. (Re-write the sentence using: \u2026\u2026early\u2026\u2026)",
                "options": [
                    "Unless you start the journey early, you will be on time for examination.",
                    "Unless you start the journey early, you won't be on time for examination."
                ],
                "answer": "Unless you start the journey early, you won't be on time for examination.",
                "mark": 1,
                "id": "pleenglishcategory31-50_147",
                "type": "multipleChoice"
            },
            {
                "question": "Jeroma preferred sweat potatoes to cassava. (Re-write the sentence using: \u2026\u2026more\u2026\u2026)",
                "options": [
                    "Jerome liked cassava more than sweet potatoes.",
                    "Jerome liked sweet potatoes more than cassava."
                ],
                "answer": "Jerome liked sweet potatoes more than cassava.",
                "mark": 1,
                "id": "pleenglishcategory31-50_148",
                "type": "multipleChoice"
            },
            {
                "question": "My mother borrowed five thousand shillings from Mrs. Kibirige. (Re-write the sentence beginning: Mrs. Kibirige\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "Mrs. Kibirige lent five thousand shillings to my mother.",
                    "Mrs. Kibirige borrowed five thousand shillings from my mother."
                ],
                "answer": "Mrs. Kibirige lent five thousand shillings to my mother.",
                "mark": 1,
                "id": "pleenglishcategory31-50_149",
                "type": "multipleChoice"
            },
            {
                "question": "There is almost no water left in bucket. (Re-write the sentence using: \u2026\u2026\u2026\u2026\u2026hardly\u2026\u2026\u2026\u2026)",
                "options": [
                    "There is hardly no water left in the bucket.",
                    "There is hardly any water left in the bucket."
                ],
                "answer": "There is hardly any water left in the bucket.",
                "mark": 1,
                "id": "pleenglishcategory31-50_150",
                "type": "multipleChoice"
            },
            {
                "question": "We had lunch before we gathered the rubbish. (Re-write sentence beginning: By the time\u2026\u2026\u2026\u2026)",
                "options": [
                    "By the time we gathered the rubbish, we had had lunch.",
                    "By the time we gathered the rubbish, we had not had lunch."
                ],
                "answer": "By the time we gathered the rubbish, we had had lunch.",
                "mark": 1,
                "id": "pleenglishcategory31-50_151",
                "type": "multipleChoice"
            },
            {
                "question": "Our neighbor said that she had reported the case to police. (Re-write the sentence ending  \u201c\u2026\u2026\u2026\u2026\u2026\u201d said our neighbours.)",
                "options": [
                    "I reported the case to the police our neighbour said.",
                    "\u201cI reported the case to the police,\u201d said our neighbour."
                ],
                "answer": "\u201cI reported the case to the police,\u201d said our neighbour.",
                "mark": 1,
                "id": "pleenglishcategory31-50_152",
                "type": "multipleChoice"
            },
            {
                "question": "The parents liked the drama. (Re-write the sentence using: \u2026\u2026\u2026\u2026interested\u2026\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "The parents were not interested in the drama.",
                    "The parents were interested in the drama."
                ],
                "answer": "The parents were interested in the drama.",
                "mark": 1,
                "id": "pleenglishcategory31-50_153",
                "type": "multipleChoice"
            },
            {
                "question": "The black dictionary belongs to Tito. (Re-write the sentence beginning: Tito is \u2026\u2026\u2026\u2026)",
                "options": [
                    "Tito is does not own the black dictionary.",
                    "Tito is the owner of the black dictionary."
                ],
                "answer": "Tito is the owner of the black dictionary.",
                "mark": 1,
                "id": "pleenglishcategory31-50_154",
                "type": "multipleChoice"
            },
            {
                "question": "It is six year since I last travelled to Kampala. (Re-write the sentence  beginning: I last\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "I last travelled to Kampala in six years.",
                    "I last travelled to Kampala six years ago."
                ],
                "answer": "I last travelled to Kampala six years ago.",
                "mark": 1,
                "id": "pleenglishcategory31-50_155",
                "type": "multipleChoice"
            },
            {
                "question": "We should feed animals; we should treat them when they fall sick. (Re-write as one sentence beginning: Not only\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "Not only should we feed the animals when they fall sick.",
                    "Not only should we feed the animals but also treat them when they fall sick."
                ],
                "answer": "Not only should we feed the animals but also treat them when they fall sick.",
                "mark": 1,
                "id": "pleenglishcategory31-50_156",
                "type": "multipleChoice"
            },
            {
                "question": "My grandfather was very rich although he was blind. (Re-write the sentence using: \u2026\u2026but\u2026\u2026)",
                "options": [
                    "My grandfather was blind and very rich.",
                    "My grandfather was blind but very rich."
                ],
                "answer": "My grandmother was blind but very rich.",
                "mark": 1,
                "id": "pleenglishcategory31-50_157",
                "type": "multipleChoice"
            },
            {
                "question": "All the children in our class speak English fluently. (Re-write the sentence using: Every\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "Every child in my class speak English fluently.",
                    "Every children in my class speak English fluently."
                ],
                "answer": "Every child in my class speak English fluently.",
                "mark": 1,
                "id": "pleenglishcategory31-50_158",
                "type": "multipleChoice"
            },
            {
                "question": "Sam in short. John is very short. (Re-write as one sentence using: \u2026\u2026\u2026\u2026\u2026shorter\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "Sam is shorter than John.",
                    "John is shorter than Sam."
                ],
                "answer": "John is shorter than Sam.",
                "mark": 1,
                "id": "pleenglishcategory31-50_159",
                "type": "multipleChoice"
            },
            {
                "question": "Mr. kato is a builder. Mr. kato is a carpenter. (Re-write end join the sentence using: \u2026\u2026\u2026both\u2026\u2026\u2026)",
                "options": [
                    "Mr. Kato is both a builder and a carpenter.",
                    "Mr. Kato is a builder and both a carpenter."
                ],
                "answer": "Mr. Kato is both a builder and a carpenter.",
                "mark": 1,
                "id": "pleenglishcategory31-50_160",
                "type": "multipleChoice"
            },
            {
                "question": "The secretary is writing the invitation letters now. (Re-write the sentence beginning :The invitation\u2026\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "The invitation letters is being written by the secretary now.",
                    "The invitation letters are being written by the secretary now."
                ],
                "answer": "The invitation letters are being written by the secretary now.",
                "mark": 1,
                "id": "pleenglishcategory31-50_161",
                "type": "multipleChoice"
            },
            {
                "question": "The food was very tasty. We ate it all. (Re-write  the sentence using: \u2026\u2026\u2026\u2026\u2026.because\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "We ate all the food because it was tasty.",
                    "The food was tasty beacuse we ate it all."
                ],
                "answer": "We ate all the food because it was tasty.",
                "mark": 1,
                "id": "pleenglishcategory31-50_162",
                "type": "multipleChoice"
            },
            {
                "question": "Mr. Hare is a very cunning animal. Mr. fox is a cunning animal. (Re-write as one sentence using: \u2026\u2026\u2026\u2026as\u2026\u2026\u2026\u2026as\u2026\u2026\u2026)",
                "options": [
                    "Mr. Fox is not as cunning as Mr. Hare.",
                    "Mr. Hare is not as cunning as Mr. Fox."
                ],
                "answer": "Mr. Fox is not as cunning as Mr. Hare.",
                "mark": 1,
                "id": "pleenglishcategory31-50_163",
                "type": "multipleChoice"
            },
            {
                "question": "Dumba is not young. Kato is not young. (Re-write and join the sentence beginning: Neither\u2026\u2026\u2026)",
                "options": [
                    "Neither Dumba and Kato is Young.",
                    "Neither Dumba nor Kato is Young."
                ],
                "answer": "Neither Dumba nor Kato is Young.",
                "mark": 1,
                "id": "pleenglishcategory31-50_164",
                "type": "multipleChoice"
            },
            {
                "question": "We said a prayer before having a meal. (Re-write the sentence using: \u2026\u2026\u2026\u2026\u2026after\u2026\u2026\u2026\u2026)",
                "options": [
                    "We had a meal after saying a prayer.",
                    "We said a prayer after having a meal."
                ],
                "answer": "We had a meal after saying a prayer.",
                "mark": 1,
                "id": "pleenglishcategory31-50_165",
                "type": "multipleChoice"
            },
            {
                "question": "Bbosa prefers posho to sweet potatoes. (Re-write  the sentence using:\u2026\u2026\u2026\u2026\u2026\u2026more\u2026\u2026\u2026\u2026)",
                "options": [
                    "Bbosa likes sweet potatoes more than posho.",
                    "Bbosa likes posho more than sweet potatoes."
                ],
                "answer": "Bbosa likes posho more than sweet potatoes.",
                "mark": 1,
                "id": "pleenglishcategory31-50_166",
                "type": "multipleChoice"
            },
            {
                "question": "I cannot buy a storybook because I don't have enough money. (Re-write beginning: Since\u2026\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "Since I don't have enough money, I cannot buy a storybook.",
                    "Since I cannot buy a storybook, I don't have enough money."
                ],
                "answer": "Since I don't have enough money, I cannot buy a storybook.",
                "mark": 1,
                "id": "pleenglishcategory31-50_167",
                "type": "multipleChoice"
            },
            {
                "question": "This beautiful basket was woven by my sister. (Re-write the sentence ending: \u2026 this beautiful basket)",
                "options": [
                    "My sister woven this beautiful basket.",
                    "My sister wove this beautiful basket."
                ],
                "answer": "My sister wove this beautiful basket.",
                "mark": 1,
                "id": "pleenglishcategory31-50_168",
                "type": "multipleChoice"
            },
            {
                "question": "\u201cWhere are you going, Sam?\u201d asked the mother. (Re-write as one sentence beginning: The mother\u2026\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "The mother asked Sam where he was going.",
                    "The mother asked where he was going."
                ],
                "answer": "The mother asked Sam where he was going.",
                "mark": 1,
                "id": "pleenglishcategory31-50_169",
                "type": "multipleChoice"
            },
            {
                "question": "Bruno may bake the cakes. Brenda may bake the cakes. (Re-write as one sentence beginning: Either\u2026\u2026\u2026\u2026)",
                "options": [
                    "Either Bruno nor Brenda will bake the cakes.",
                    "Either Bruno or Brenda will bake the cakes."
                ],
                "answer": "Either Bruno or Brenda will bake the cakes.",
                "mark": 1,
                "id": "pleenglishcategory31-50_170",
                "type": "multipleChoice"
            },
            {
                "question": "The boy said that he would not report to the class teacher that afternoon. (Re-write the sentence ending: \u2026\u2026\u2026\u2026\u2026\u2026said the boy.)",
                "options": [
                    "\u201cI will not report to the class teacher this afternoon.\u201d said the boy.",
                    "\u201cI will report to the class teacher this afternoon.\u201d said the boy."
                ],
                "answer": "\u201cI will not report to the class teacher this afternoon.\u201d said the boy.",
                "mark": 1,
                "id": "pleenglishcategory31-50_171",
                "type": "multipleChoice"
            },
            {
                "question": "Who teaches you? (Re-write the sentence ending: \u2026\u2026\u2026\u2026\u2026\u2026\u2026teacher?)",
                "options": [
                    "Who is my teacher?",
                    "Who is your teacher?"
                ],
                "answer": "Who is your teacher?",
                "mark": 1,
                "id": "pleenglishcategory31-50_172",
                "type": "multipleChoice"
            },
            {
                "question": "Mark is very lazy. Mark cannot finish the work in time. (Re-write the sentence using: \u2026\u2026\u2026\u2026so\u2026\u2026\u2026\u2026that\u2026\u2026\u2026\u2026)",
                "options": [
                    "Mark is so lazy that he cannot finish the work on time.",
                    "Mark is so lazy that he can finish the work on time."
                ],
                "answer": "Mark is so lazy that he cannot finish the work on time.",
                "mark": 1,
                "id": "pleenglishcategory31-50_173",
                "type": "multipleChoice"
            },
            {
                "question": "Here comes the carpenter. His workshop was burnt down. (Re-write as one sentence using: \u2026\u2026\u2026\u2026whose\u2026\u2026\u2026\u2026)",
                "options": [
                    "That is the carpenter whose workshop was burnt down.",
                    "Here comes the carpenter whose workshop was burnt down."
                ],
                "answer": "Here comes the carpenter whose workshop was burnt down.",
                "mark": 1,
                "id": "pleenglishcategory31-50_174",
                "type": "multipleChoice"
            },
            {
                "question": "Ann bought a dress. It was green. It was big. (Re-write the sentence without using and which)",
                "options": [
                    "Ann bought a big green dress.",
                    "Ann bought a dress that was big green."
                ],
                "answer": "Ann bought a big green dress.",
                "mark": 1,
                "id": "pleenglishcategory31-50_175",
                "type": "multipleChoice"
            },
            {
                "question": "Both Rose and Mary are musicians. (Re-write the sentence using: \u2026\u2026\u2026\u2026\u2026\u2026and so\u2026\u2026\u2026\u2026)",
                "options": [
                    "Rose is a musician but Mary is not.",
                    "Rose is a musician and so is Mary."
                ],
                "answer": "Rose is a musician and so is Mary.",
                "mark": 1,
                "id": "pleenglishcategory31-50_176",
                "type": "multipleChoice"
            },
            {
                "question": "If I go to Kalangala, I will eat fish. (Re-the sentence using: \u2026\u2026\u2026went\u2026\u2026\u2026)",
                "options": [
                    "If I went to Kalangala, I would eat fish.",
                    "If I went to Kalangala, I would not eat fish."
                ],
                "answer": "If I went to Kalangala, I would eat fish.",
                "mark": 1,
                "id": "pleenglishcategory31-50_177",
                "type": "multipleChoice"
            },
            {
                "question": "This hill is very high. (Re-write the sentence beginning: How\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "How high this hill is.",
                    "How high this hill is!"
                ],
                "answer": "How high this hill is!",
                "mark": 1,
                "id": "pleenglishcategory31-50_178",
                "type": "multipleChoice"
            },
            {
                "question": "This is police officer that you should adress your letter to. (Re-write the sentence using: \u2026\u2026\u2026\u2026\u2026\u2026to whom\u2026\u2026\u2026\u2026\u2026)",
                "options": [
                    "This is the police officer to whom you should address your letter.",
                    "This is the police officer to whom you should give your letter."
                ],
                "answer": "This is the police officer to whom you should address your letter.",
                "mark": 1,
                "id": "pleenglishcategory31-50_179",
                "type": "multipleChoice"
            }
        ]
    },
    {
        "category": 51,
        "numberOfQuestions ": 1,
        "questions": [
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/51/2005/index.html",
                "id": "pleenglishcategory51_1",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/51/2006/index.html",
                "id": "pleenglishcategory51_2",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/51/2007/index.html",
                "id": "pleenglishcategory51_3",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/51/2008/index.html",
                "id": "pleenglishcategory51_4",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/51/2009/index.html",
                "id": "pleenglishcategory51_5",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/51/2010/index.html",
                "id": "pleenglishcategory51_6",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/51/2011/index.html",
                "id": "pleenglishcategory51_7",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/51/2012/index.html",
                "id": "pleenglishcategory51_8",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/51/2013/index.html",
                "id": "pleenglishcategory51_9",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/51/2014/index.html",
                "id": "pleenglishcategory51_10",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/51/2015/index.html",
                "id": "pleenglishcategory51_11",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/51/2016/index.html",
                "id": "pleenglishcategory51_12",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/51/2017/index.html",
                "id": "pleenglishcategory51_13",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/51/2018/index.html",
                "id": "pleenglishcategory51_14",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/51/2019/index.html",
                "id": "pleenglishcategory51_15",
                "type": "iframe"
            }
        ]
    },
    {
        "category": 52,
        "numberOfQuestions ": 1,
        "questions": [
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/52/2005/index.html",
                "id": "pleenglishcategory52_1",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/52/2006/index.html",
                "id": "pleenglishcategory52_2",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/52/2007/index.html",
                "id": "pleenglishcategory52_3",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/52/2008/index.html",
                "id": "pleenglishcategory52_4",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/52/2009/index.html",
                "id": "pleenglishcategory52_5",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/52/2010/index.html",
                "id": "pleenglishcategory52_6",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/52/2011/index.html",
                "id": "pleenglishcategory52_7",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/52/2012/index.html",
                "id": "pleenglishcategory52_8",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/52/2013/index.html",
                "id": "pleenglishcategory52_9",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/52/2014/index.html",
                "id": "pleenglishcategory52_10",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/52/2015/index.html",
                "id": "pleenglishcategory52_11",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/52/2016/index.html",
                "id": "pleenglishcategory52_12",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/52/2017/index.html",
                "id": "pleenglishcategory52_13",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/52/2018/index.html",
                "id": "pleenglishcategory52_14",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/52/2019/index.html",
                "id": "pleenglishcategory52_15",
                "type": "iframe"
            }
        ]
    },
    {
        "category": 53,
        "numberOfQuestions ": 1,
        "questions": [
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/53/2005/index.html",
                "id": "pleenglishcategory53_1",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/53/2006/index.html",
                "id": "pleenglishcategory53_2",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/53/2007/index.html",
                "id": "pleenglishcategory53_3",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/53/2008/index.html",
                "id": "pleenglishcategory53_4",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/53/2009/index.html",
                "id": "pleenglishcategory53_5",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/53/2010/index.html",
                "id": "pleenglishcategory53_6",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/53/2011/index.html",
                "id": "pleenglishcategory53_7",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/53/2012/index.html",
                "id": "pleenglishcategory53_8",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/53/2013/index.html",
                "id": "pleenglishcategory53_9",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/53/2014/index.html",
                "id": "pleenglishcategory53_10",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/53/2015/index.html",
                "id": "pleenglishcategory53_11",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/53/2016/index.html",
                "id": "pleenglishcategory53_12",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/53/2017/index.html",
                "id": "pleenglishcategory53_13",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/53/2018/index.html",
                "id": "pleenglishcategory53_14",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/53/2019/index.html",
                "id": "pleenglishcategory53_15",
                "type": "iframe"
            }
        ]
    },
    {
        "category": 54,
        "numberOfQuestions ": 1,
        "questions": [
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/54/2005/index.html",
                "id": "pleenglishcategory54_1",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/54/2006/index.html",
                "id": "pleenglishcategory54_2",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/54/2007/index.html",
                "id": "pleenglishcategory54_3",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/54/2008/index.html",
                "id": "pleenglishcategory54_4",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/54/2009/index.html",
                "id": "pleenglishcategory54_5",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/54/2010/index.html",
                "id": "pleenglishcategory54_6",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/54/2011/index.html",
                "id": "pleenglishcategory54_7",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/54/2012/index.html",
                "id": "pleenglishcategory54_8",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/54/2013/index.html",
                "id": "pleenglishcategory54_9",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/54/2014/index.html",
                "id": "pleenglishcategory54_10",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/54/2015/index.html",
                "id": "pleenglishcategory54_11",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/54/2016/index.html",
                "id": "pleenglishcategory54_12",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/54/2017/index.html",
                "id": "pleenglishcategory54_13",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/54/2018/index.html",
                "id": "pleenglishcategory54_14",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/54/2019/index.html",
                "id": "pleenglishcategory54_15",
                "type": "iframe"
            }
        ]
    },
    {
        "category": 55,
        "numberOfQuestions ": 1,
        "questions": [
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/55/2005/index.html",
                "id": "pleenglishcategory55_1",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/55/2006/index.html",
                "id": "pleenglishcategory55_2",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/55/2007/index.html",
                "id": "pleenglishcategory55_3",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/55/2008/index.html",
                "id": "pleenglishcategory55_4",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/55/2009/index.html",
                "id": "pleenglishcategory55_5",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/55/2010/index.html",
                "id": "pleenglishcategory55_6",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/55/2011/index.html",
                "id": "pleenglishcategory55_7",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/55/2012/index.html",
                "id": "pleenglishcategory55_8",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/55/2013/index.html",
                "id": "pleenglishcategory55_9",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/55/2014/index.html",
                "id": "pleenglishcategory55_10",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/55/2015/index.html",
                "id": "pleenglishcategory55_11",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/55/2016/index.html",
                "id": "pleenglishcategory55_12",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/55/2017/index.html",
                "id": "pleenglishcategory55_13",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/55/2018/index.html",
                "id": "pleenglishcategory55_14",
                "type": "iframe"
            },
            {
                "question": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/English/55/2019/index.html",
                "id": "pleenglishcategory55_15",
                "type": "iframe"
            }
        ]
    }
]

const sst_ple_ans = [
    {
        "instructions": null,
        "questions": [
            {
                "question": "Name one inland port found on Lake Victoria in Uganda",
                "type": "text",
                "answer": [
                    "Port bell",
                    "Port Bukakata",
                    "Jinja port"
                ],
                "id": "ple_sst_sect_A1_11",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 1,
        "$id": "document_0"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Give any one traditional means of communication.",
                "type": "text",
                "answer": [
                    "Drumming",
                    "Clapping",
                    "Ululation"
                ],
                "id": "ple_sst_sect_A2_12",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 2,
        "$id": "document_1"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Name one basic need provided by a school.",
                "type": "text",
                "answer": [
                    "Food",
                    "Shelter"
                ],
                "id": "ple_sst_sect_A3_8",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 3,
        "$id": "document_2"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "State any one way in which weather records are important to a farmer,",
                "type": "multipleChoice",
                "answer": [
                    "They help a farmer schedule his planting and harvesting season"
                ],
                "options": [
                    "They help a farmer to know whether to carry an umbrella or not",
                    "They help a farmer schedule his planting and harvesting season"
                ],
                "id": "ple_sst_sect_A4_19",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 4,
        "$id": "document_3"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Which element gives detailed information about a map?",
                "type": "text",
                "answer": [
                    "Key",
                    "The key"
                ],
                "id": "ple_sst_sect_A5_8",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 5,
        "$id": "document_4"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Mention any one activity that the Bachwezi are remembered for.",
                "type": "text",
                "answer": [
                    "Animal rearing",
                    "Rearing cattle",
                    "Cattle rearing",
                    "Rearing animals"
                ],
                "id": "ple_sst_sect_A6_10",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 6,
        "$id": "document_5"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "How did the people in Kenya struggle to get independence",
                "type": "multipleChoice",
                "answer": [
                    "By forming political parties"
                ],
                "options": [
                    "By staging the Maji Maji rebellion",
                    "By forming political parties"
                ],
                "id": "ple_sst_sect_A7_5",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 7,
        "$id": "document_6"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Why is road construction difficult in Kigezi sub region?",
                "type": "multipleChoice",
                "answer": [
                    "Kigezi sub region is mountaineous in nature"
                ],
                "options": [
                    "Kigezi sub region is swampy",
                    "Kigezi sub region is mountaineous in nature"
                ],
                "id": "ple_sst_sect_A8_1",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 8,
        "$id": "document_7"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Give any one reason why people make plans of what to buy before shopping.",
                "type": "multipleChoice",
                "answer": [
                    "To cater for priorities"
                ],
                "options": [
                    "To buy better things",
                    "To cater for priorities"
                ],
                "id": "ple_sst_sect_A9_1",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 9,
        "$id": "document_8"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "How did Johann Ludwig Krapf make it easy for the coastal people to understand the Bible?",
                "type": "multipleChoice",
                "answer": [
                    "He built the first mission station in East Africa at Rabai Mpya"
                ],
                "options": [
                    "He built fort portal in Kabarole to fight enemies",
                    "He built the first mission station in East Africa at Rabai Mpya"
                ],
                "id": "ple_sst_sect_A10_1",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 10,
        "$id": "document_9"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Mention any one of the citrus fruits grown in the Mediterranean climate region of Africa",
                "type": "text",
                "answer": [
                    "Oranges",
                    "Lemons",
                    "Grapefruits",
                    "Limes"
                ],
                "id": "ple_sst_sect_A11_6",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 11,
        "$id": "document_10"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Which ministry is responsible for major roads in Uganda?",
                "type": "multipleChoice",
                "answer": [
                    "Ministry of works and transport"
                ],
                "options": [
                    "Ministry of health",
                    "Ministry of internal affairs",
                    "Ministry of works and transport"
                ],
                "id": "ple_sst_sect_A12_15",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 12,
        "$id": "document_11"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "What is meant by a tributary?",
                "type": "multipleChoice",
                "answer": [
                    "A tributary is a small river that joins the main river"
                ],
                "options": [
                    "A tributary is a big river that drains a small river",
                    "A tributary is a small river that joins the main river"
                ],
                "id": "ple_sst_sect_A13_2",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 13,
        "$id": "document_12"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Give any one main crop grown in Doha Irrigation Scheme",
                "type": "text",
                "answer": [
                    "Rice"
                ],
                "id": "ple_sst_sect_A14_10",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 14,
        "$id": "document_13"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "How can a small scale sugarcane grower in Jinja benefit from the nearby Kakira sugar factory?",
                "type": "multipleChoice",
                "answer": [
                    "It provides market to sugar cane from out growers",
                    "Kakira sugar factory provide employment to the workers"
                ],
                "options": [
                    "It provides education services to the sugarcane grower in Jinja",
                    "It provides market to sugar cane from out growers",
                    "Kakira sugar factory provide employment to the workers",
                    "It provides health services to the sugarcane grower in Jinja"
                ],
                "id": "ple_sst_sect_A15_16",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 15,
        "$id": "document_14"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "What is Bwindi National Park famous for?",
                "type": "multipleChoice",
                "answer": [
                    "It is famous for mountain gorillas"
                ],
                "options": [
                    "It is famous for Chimpanzee",
                    "It is famous for mountain gorillas"
                ],
                "id": "ple_sst_sect_A16_16",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 16,
        "$id": "document_15"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "How does a good road network promote development in an area?",
                "type": "multipleChoice",
                "answer": [
                    "It promotes trade"
                ],
                "options": [
                    "It helps to increase taxes",
                    "It promotes trade"
                ],
                "id": "ple_sst_sect_A17_5",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 17,
        "$id": "document_16"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Why is cattle keeping one of the most important economic activities in Uganda?",
                "type": "multipleChoice",
                "answer": [
                    "It is a primary source of income"
                ],
                "options": [
                    "It is a primary source of income",
                    "Its because many Ugandans own cattle"
                ],
                "id": "ple_sst_sect_A18_6",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 18,
        "$id": "document_17"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Why was the railway line extended from Kampala to Kasese?",
                "type": "multipleChoice",
                "answer": [
                    "To transport coper ore",
                    "To transport goods"
                ],
                "options": [
                    "To transport coper ore",
                    "To transport oil",
                    "To transport goods"
                ],
                "id": "ple_sst_sect_A19_11",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 19,
        "$id": "document_18"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Why is a sign post important?",
                "type": "multipleChoice",
                "answer": [
                    "It directs people to the place where the company or institution is found"
                ],
                "options": [
                    "It is used to show directions on a map",
                    "It directs people to the place where the company or institution is found"
                ],
                "id": "ple_sst_sect_A20_5",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 20,
        "$id": "document_19"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Ghana lies on the prime Meridian. If the time in Ghana is 1:00p.m what time will it be East Africa standard time?",
                "type": "multipleChoice",
                "answer": [
                    "It will be 4:00p.m",
                    "It will be4 o'clock"
                ],
                "options": [
                    "It will be 4:00p.m",
                    "It will be4 o'clock",
                    "It will be 1:00p.m",
                    "It will be 3:00p.m"
                ],
                "id": "ple_sst_sect_A21_12",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 21,
        "$id": "document_20"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "State one way in which feeder roads promote development in rural areas of Uganda",
                "type": "multipleChoice",
                "answer": [
                    "They ease the movement of traders in rural areas"
                ],
                "options": [
                    "They reduce congestion in rural areas",
                    "They ease the movement of traders in rural areas"
                ],
                "id": "ple_sst_sect_A22_3",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 22,
        "$id": "document_21"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Which type of timber is used in the ship building industry?",
                "type": "text",
                "answer": [
                    "Mangrove timber",
                    "Waterproof timber"
                ],
                "id": "ple_sst_sect_A23_1",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 23,
        "$id": "document_22"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Apart from controlling soil erosion, give any one other reason why a school compound should have vegetation cover",
                "type": "multipleChoice",
                "answer": [
                    "To beautify the compound"
                ],
                "options": [
                    "Its a requirement by the ministry",
                    "To beautify the compound"
                ],
                "id": "ple_sst_sect_A24_5",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 24,
        "$id": "document_23"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Mention any one way through which poverty can be reduced in your community. ",
                "type": "multipleChoice",
                "answer": [
                    "By creating more jobs"
                ],
                "options": [
                    "By creating more jobs",
                    "By increasing taxes"
                ],
                "id": "ple_sst_sect_A25_6",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 25,
        "$id": "document_24"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Mention any one way through which the government of Uganda is able to find out the problems affecting its people",
                "type": "multipleChoice",
                "answer": [
                    "Through talk shows"
                ],
                "options": [
                    "Through talk shows",
                    "Through elections"
                ],
                "id": "ple_sst_sect_A26_4",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 26,
        "$id": "document_25"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "State any one advantage tarmac roads have over murram roads",
                "type": "multipleChoice",
                "answer": [
                    "Tarmac roads cannot easily be spoilt by bad weather unlike murram roads"
                ],
                "options": [
                    "Tarmac roads cannot easily be spoilt by bad weather unlike murram roads",
                    "Murram roads cannot easily be spoilt by bad weather unlike tarmac roads"
                ],
                "id": "ple_sst_sect_A27_1",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 27,
        "$id": "document_26"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Mention any one through which parents can ensure equal treatment of boys and girls at home",
                "type": "multipleChoice",
                "answer": [
                    "By giving them similar tasks to perform"
                ],
                "options": [
                    "By teaching the cultural values",
                    "By giving them similar tasks to perform"
                ],
                "id": "ple_sst_sect_A28_2",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 28,
        "$id": "document_27"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Give any one economic result of the movement of the Boers to the interior of South Africa",
                "type": "multipleChoice",
                "answer": [
                    "It led to discovery of gold"
                ],
                "options": [
                    "It led to discovery of gold",
                    "It led to infrastructure development"
                ],
                "id": "ple_sst_sect_A29_4",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 29,
        "$id": "document_28"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Give any one reason why a country should maintain peace and security",
                "type": "multipleChoice",
                "answer": [
                    "To promote trade"
                ],
                "options": [
                    "To fight corruption",
                    "To promote trade"
                ],
                "id": "ple_sst_sect_A30_4",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 30,
        "$id": "document_29"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Give one way in which forceful registration of guns led to Lamogi rebellion",
                "type": "multipleChoice",
                "answer": [
                    "The Acholi never wanted to be disarmed"
                ],
                "options": [
                    "The Acholi wanted to fight the government",
                    "The Acholi never wanted to be disarmed"
                ],
                "id": "ple_sst_sect_A31_4",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 31,
        "$id": "document_30"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Write UWEC in full",
                "type": "text",
                "answer": [
                    "Uganda Wildlife Conservation Education Center"
                ],
                "id": "ple_sst_sect_A32_1",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 32,
        "$id": "document_31"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "State any one problem that has made plantation farming difficult in rural areas of Uganda.",
                "type": "multipleChoice",
                "answer": [
                    "Shortage of capital"
                ],
                "options": [
                    "Lack of enough land",
                    "Shortage of capital "
                ],
                "id": "ple_sst_sect_A33_4",
                "user_answer": null,
                "sub_questions": []
            }
        ],
        "category": 33,
        "$id": "document_32"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Which mountain forms a natural boundary between Rwanda and Uganda",
                "type": "text",
                "answer": [
                    "Mountain Mufumbiro"
                ],
                "id": "ple_sst_sect_A34_4",
                "user_answer": "re",
                "sub_questions": []
            }
        ],
        "category": 34,
        "$id": "document_33"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Name any one common market in which Uganda is a member.",
                "type": "text",
                "answer": [
                    "East African Community"
                ],
                "id": "ple_sst_sect_A35_5",
                "user_answer": "tert",
                "sub_questions": []
            }
        ],
        "category": 35,
        "$id": "document_34"
    },
    {
        "instructions": "For each of the questions 36 to 40, answer EITHER the Christian OR Islamic question but not both. No marks will be awarded to a candidate who attempts both alternatives in a particular number.",
        "questions": [
            {
                "question": "In what way did God punish the human race because of their disobedience during Noah's time?",
                "type": "multipleChoice",
                "answer": [
                    "He sent a flood"
                ],
                "options": [
                    "He killed all first born sons",
                    "He sent a flood"
                ],
                "id": "ple_sst_sectA_either_12",
                "user_answer": "He sent a flood",
                "sub_questions": []
            },
            {
                "question": "How should you as a Muslim treat those who mistreat you?",
                "type": "multipleChoice",
                "answer": [
                    "A Muslim should love those who mistreat him/her"
                ],
                "options": [
                    "A Muslim should not pray for those who mistreat him",
                    "A Muslim should love those who mistreat him/her"
                ],
                "id": "ple_sst_sectA_or_22",
                "user_answer": "A Muslim should love those who mistreat him/her",
                "sub_questions": []
            },
            {
                "question": "State any one role of angels in Christianity",
                "type": "multipleChoice",
                "answer": [
                    "They protect Christians"
                ],
                "options": [
                    "They blow the trumpet on the judgement day",
                    "They protect Christians"
                ],
                "id": "ple_sst_sectA_either_8",
                "user_answer": "They blow the trumpet on the judgement day",
                "sub_questions": []
            },
            {
                "question": "Select any one pratice that can enable a muslim to live a fruitful life",
                "type": "multipleChoice",
                "answer": [
                    "Reading the Holy Quran"
                ],
                "options": [
                    "Visiting Mecca",
                    "Reading the Holy Quran",
                    "Reading the Holy Bible"
                ],
                "id": "ple_sst_sectA_or_6",
                "user_answer": "Reading the Holy Bible",
                "sub_questions": []
            },
            {
                "question": "What term is used by christians to mean the act of asking for forgiveness after wrong doing?",
                "type": "text",
                "answer": [
                    "Repentance"
                ],
                "id": "ple_sst_sectA_either_14",
                "user_answer": "treter",
                "sub_questions": []
            }
        ],
        "category": 36,
        "$id": "document_35"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Name the body that is responsible for organizing population census in Uganda.",
                "type": "text",
                "answer": [
                    "Uganda Bureau of Statistics",
                    "UBOS"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two reasons why people migrate from villages to towns.",
                        "type": "check_box",
                        "answer": [
                            "They migrate to look for better employment opportunities",
                            "They migrate to look for better education",
                            "They migrate to look for better healthcare"
                        ],
                        "options": [
                            "They migrate to look for better employment opportunities",
                            "They migrate to look for cheaper cost of living",
                            "They migrate to look for better agricultural opportunities",
                            "They migrate to look for better healthcare",
                            "They migrate to look for better education"
                        ],
                        "id": "ple_sst_sect_B41_6_sub_0",
                        "user_answer": [
                            "They migrate to look for cheaper cost of living"
                        ]
                    },
                    {
                        "question": "Select any one challenges faced by the enumerators during census.",
                        "type": "multipleChoice",
                        "answer": [
                            "Language barrier",
                            "Resistance and non-cooperation"
                        ],
                        "options": [
                            "Resistance and non-cooperation",
                            "High cost of living",
                            "Language barrier"
                        ],
                        "id": "ple_sst_sect_B41_6_sub_1",
                        "user_answer": "High cost of living"
                    }
                ],
                "id": "ple_sst_sect_B41_6",
                "user_answer": "tre"
            }
        ],
        "category": 41,
        "$id": "document_36"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "The diagram below shows a school budget. Use it to answer the questions that follow",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2013_42.png",
                "sub_questions": [
                    {
                        "question": "Give the type of Budget shown in the diagram above",
                        "type": "text",
                        "answer": [
                            "Deficit budget",
                            "Loss budget"
                        ],
                        "id": "ple_sst_sect_B42_11_sub_0",
                        "user_answer": "ferer"
                    },
                    {
                        "question": "Give any two problems a school may face when  it has a budget shown above.",
                        "type": "check_box",
                        "answer": [
                            "It leads to debts",
                            "It leads to shortages in school"
                        ],
                        "options": [
                            "It leads to debts",
                            "It leads to shortages in school",
                            "It leads to late closure of the school term",
                            "It leads to unnecessary spending"
                        ],
                        "id": "ple_sst_sect_B42_11_sub_1",
                        "user_answer": [
                            "It leads to late closure of the school term"
                        ]
                    },
                    {
                        "question": "State any one reason a school should have a budget.",
                        "type": "multipleChoice",
                        "answer": [
                            "To identify priorities for proper planning"
                        ],
                        "options": [
                            "To identify priorities for proper planning",
                            "To avoid budget constraints"
                        ],
                        "id": "ple_sst_sect_B42_11_sub_2",
                        "user_answer": "To avoid budget constraints"
                    }
                ],
                "id": "ple_sst_sect_B42_11",
                "user_answer": null
            }
        ],
        "category": 42,
        "$id": "document_37"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Select any two reasons why a family needs to make a budget.",
                "type": "check_box",
                "answer": [
                    "To identify sources of income",
                    "To avoid over spending"
                ],
                "options": [
                    "To be rich",
                    "To identify sources of income",
                    "To avoid over spending",
                    "To help the government"
                ],
                "sub_questions": [
                    {
                        "question": "What type of budget can bring development in a family?",
                        "type": "text",
                        "answer": [
                            "Surplus budget"
                        ],
                        "id": "ple_sst_sect_B43_13_sub_0",
                        "user_answer": null
                    },
                    {
                        "question": "In which one way can the budget you have mentioned above bring development in the family?",
                        "type": "multipleChoice",
                        "answer": [
                            "It  helps the family to save for the future"
                        ],
                        "options": [
                            "It  helps the family to save for the future",
                            "It helps the family to pay more taxes"
                        ],
                        "id": "ple_sst_sect_B43_13_sub_1",
                        "user_answer": null
                    }
                ],
                "id": "ple_sst_sect_B43_13",
                "user_answer": [
                    "To be rich"
                ]
            }
        ],
        "category": 43,
        "$id": "document_38"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Why was Kabaka Mwanga afraid for foreigner coming to Buganda through the East?",
                "type": "multipleChoice",
                "answer": [
                    "He thought they would overthrow him"
                ],
                "options": [
                    "He didn't want their gifts",
                    "He thought they would overthrow him"
                ],
                "sub_questions": [
                    {
                        "question": "Where did Bishop Hannington meet his death on his way to Buganda?",
                        "type": "text",
                        "answer": [
                            "Busoga",
                            "Mayuge",
                            "Kyanda",
                            "Luba's Fort",
                            "Bunya"
                        ],
                        "id": "ple_sst_sect_B44_14_sub_0",
                        "user_answer": null
                    },
                    {
                        "question": "State any one way in which the imperial British East Africa company (IBEACO) helped missionaries to work in Uganda.",
                        "type": "multipleChoice",
                        "answer": [
                            "They protected the missionaries"
                        ],
                        "options": [
                            "They helped them to promote christianity",
                            "They protected the missionaries"
                        ],
                        "id": "ple_sst_sect_B44_14_sub_1",
                        "user_answer": null
                    },
                    {
                        "question": "Give any one way the missionaries helped in the development of Uganda.",
                        "type": "multipleChoice",
                        "answer": [
                            "They built hospitals"
                        ],
                        "options": [
                            "They built hospitals",
                            "They fought slave trade"
                        ],
                        "id": "ple_sst_sect_B44_14_sub_2",
                        "user_answer": null
                    }
                ],
                "id": "ple_sst_sect_B44_14",
                "user_answer": null
            }
        ],
        "category": 44,
        "$id": "document_39"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Name the policy practiced in South Africa which separated the blacks from the whites.",
                "type": "text",
                "answer": [
                    "Apartheid policy",
                    "Apartheid"
                ],
                "sub_questions": [
                    {
                        "question": "Name the first black president of South Africa who fought against the above mentioned policy.",
                        "type": "text",
                        "answer": [
                            "Nelson Mandela",
                            "Nelson Rolihlahla Mandela"
                        ],
                        "id": "ple_sst_sect_B45_8_sub_0",
                        "user_answer": null
                    },
                    {
                        "question": "Select any two ways in which the policy mentioned above negatively affected the blacks in South Africa",
                        "type": "check_box",
                        "answer": [
                            "Africans were not allowed to study in the same schools with the whites",
                            "Africans were not allowed to participate in politics"
                        ],
                        "options": [
                            "Africans were not allowed to work",
                            "Africans were not allowed to study in the same schools with the whites",
                            "Africans were not allowed to get health care",
                            "Africans were not allowed to participate in politics"
                        ],
                        "id": "ple_sst_sect_B45_8_sub_1",
                        "user_answer": null
                    }
                ],
                "id": "ple_sst_sect_B45_8",
                "user_answer": null
            }
        ],
        "category": 45,
        "$id": "document_40"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Apart from Russia, select any other two permanent member states on the United Nations Security Council",
                "type": "check_box",
                "answer": [
                    "United States of America (USA)",
                    "France"
                ],
                "options": [
                    "United States of America (USA)",
                    "Italy",
                    "Israel",
                    "France"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two challenges faced by the United Nations",
                        "type": "check_box",
                        "answer": [
                            "Shortage of funds to run its programmes effectively",
                            "Differences in political systems among the member states"
                        ],
                        "options": [
                            "Corruption among member states",
                            "Shortage of funds to run its programmes effectively",
                            "Wars among member states",
                            "Differences in political systems among the member states"
                        ],
                        "id": "ple_sst_sect_B46_1_sub_0",
                        "user_answer": null
                    }
                ],
                "id": "ple_sst_sect_B46_1",
                "user_answer": null
            }
        ],
        "category": 46,
        "$id": "document_41"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Study the sketch map below and answer the questions that follow.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2018_47.png",
                "sub_questions": [
                    {
                        "question": "Name feature marked with letter A",
                        "type": "text",
                        "answer": [
                            "Niger delta"
                        ],
                        "id": "ple_sst_sect_B47_6_sub_0",
                        "user_answer": null
                    },
                    {
                        "question": "Name feature marked with letter B",
                        "type": "text",
                        "answer": [
                            "River Benue"
                        ],
                        "id": "ple_sst_sect_B47_6_sub_1",
                        "user_answer": null
                    },
                    {
                        "question": "Name any one other river in Africa that ends the same way as the river marked C above",
                        "type": "text",
                        "answer": [
                            "River Nile",
                            "River Zambezi",
                            "River Rufiji",
                            "River Tana"
                        ],
                        "id": "ple_sst_sect_B47_6_sub_2",
                        "user_answer": null
                    },
                    {
                        "question": "How is the part marked A economically important to Nigeria",
                        "type": "multipleChoice",
                        "answer": [
                            "It is a source of water for irrigation"
                        ],
                        "options": [
                            "It provides the raw material for salt mining",
                            "It is a source of water for irrigation"
                        ],
                        "id": "ple_sst_sect_B47_6_sub_3",
                        "user_answer": null
                    }
                ],
                "id": "ple_sst_sect_B47_6",
                "user_answer": null
            }
        ],
        "category": 47,
        "$id": "document_42"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "On which type of goods is customs tax charged?",
                "type": "text",
                "answer": [
                    "Imports",
                    "Imported goods"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two reasons why some people in Uganda are not willing to pay taxes.",
                        "type": "check_box",
                        "answer": [
                            "Due to ignorance about the importance of paying taxes",
                            "Due to the high taxes charged on goods"
                        ],
                        "options": [
                            "Due to ignorance about the importance of paying taxes",
                            "Because of poor transport networks",
                            "Due to the high taxes charged on goods",
                            "Due to under development"
                        ],
                        "id": "ple_sst_sect_B48_3_sub_0",
                        "user_answer": null
                    },
                    {
                        "question": "Why does the government set up revenue check points on roads?",
                        "type": "multipleChoice",
                        "answer": [
                            "To control smuggling"
                        ],
                        "options": [
                            "To collect taxes",
                            "To control smuggling"
                        ],
                        "id": "ple_sst_sect_B48_3_sub_1",
                        "user_answer": null
                    }
                ],
                "id": "ple_sst_sect_B48_3",
                "user_answer": null
            }
        ],
        "category": 48,
        "$id": "document_43"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "Select any two factors that have made South Africa the most industrialised country in Africa",
                "type": "check_box",
                "answer": [
                    "High levels of technology",
                    "Presence of skilled labour"
                ],
                "options": [
                    "High levels of technology",
                    "The apartheid policy",
                    "Presence of skilled labour",
                    "The large coast of South Africa"
                ],
                "sub_questions": [
                    {
                        "question": "In which two ways are South African's industries helpful to the growth of other sectors of her economy?",
                        "type": "check_box",
                        "answer": [
                            "Industries provide processed goods to other sectors",
                            "Industries create market for produce from other sectors"
                        ],
                        "options": [
                            "Industries provide processed goods to other sectors",
                            "Industries help to reduce the taxes in other sectors",
                            "Industries help to reduce criminal rates",
                            "Industries create market for produce from other sectors"
                        ],
                        "id": "ple_sst_sect_B49_2_sub_0",
                        "user_answer": null
                    }
                ],
                "id": "ple_sst_sect_B49_2",
                "user_answer": null
            }
        ],
        "category": 49,
        "$id": "document_44"
    },
    {
        "instructions": null,
        "questions": [
            {
                "question": "tudy the sketch map of Uganda below and answer the questions that follow:",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/sst/2018_50.png",
                "sub_questions": [
                    {
                        "question": "Name the National Game Park found at place marked P",
                        "type": "text",
                        "answer": [
                            "Lake Mburo National Park"
                        ],
                        "id": "ple_sst_sect_B50_6_sub_0",
                        "user_answer": null
                    },
                    {
                        "question": "State any one reason why there are no crocodiles in the National Game Park marked S.",
                        "type": "multipleChoice",
                        "answer": [
                            "Due to the absence of a permanent waterbody"
                        ],
                        "options": [
                            "Because the area is a desert",
                            "Due to the absence of a permanent waterbody"
                        ],
                        "id": "ple_sst_sect_B50_6_sub_1",
                        "user_answer": null
                    },
                    {
                        "question": "Select any two economic benefits of national game parks in Uganda",
                        "type": "check_box",
                        "answer": [
                            "They promote tourism",
                            "They are source of employment"
                        ],
                        "options": [
                            "They promote tourism",
                            "They are source of employment",
                            "They help to promote human rights",
                            "They help to fight corruption"
                        ],
                        "id": "ple_sst_sect_B50_6_sub_2",
                        "user_answer": null
                    }
                ],
                "id": "ple_sst_sect_B50_6",
                "user_answer": null
            }
        ],
        "category": 50,
        "$id": "document_45"
    },
    {
        "instructions": "For each of the questions 51 to 55, answer EITHER the Christian OR Islamic question but not both. No marks will be awarded to a candidate who attempts both alternatives in a particular number.",
        "questions": [
            {
                "question": "Select any two ways in which christians prepare themselves for christmas day",
                "type": "check_box",
                "answer": [
                    "By decorating their houses",
                    "By attending prayers"
                ],
                "options": [
                    "By healing the sick",
                    "By decorating their houses",
                    "By going to the mosque",
                    "By attending prayers"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two ways in which christians share joy of christmas with other people",
                        "type": "check_box",
                        "answer": [
                            "By inviting other people to eat with them",
                            "By exchanging gifts with others"
                        ],
                        "options": [
                            "By going to church",
                            "By decorating their houses",
                            "By inviting other people to eat with them",
                            "By exchanging gifts with others"
                        ],
                        "id": "ple_sst_sectB_either_17_sub_0",
                        "user_answer": [
                            "By going to church"
                        ]
                    }
                ],
                "id": "ple_sst_sectB_either_17",
                "user_answer": [
                    "By healing the sick"
                ]
            },
            {
                "question": "Name the Kabaka of Buganda who welcomed the first Arab traders to Uganda",
                "type": "text",
                "answer": [
                    "Kabaka Ssuuna II"
                ],
                "sub_questions": [
                    {
                        "question": "Select any two contributions of Islam to the development of Uganda",
                        "type": "check_box",
                        "answer": [
                            "It has led to the building of hospitals",
                            "It has led to the building of schools"
                        ],
                        "options": [
                            "It has led to the fighting of corruption",
                            "It has led to the building of hospitals",
                            "It has led to the building of roads",
                            "It has led to the building of schools"
                        ],
                        "id": "ple_sst_sectB_or_24_sub_0",
                        "user_answer": [
                            "It has led to the building of hospitals"
                        ]
                    },
                    {
                        "question": "Give any one similarity between the first Arab traders and Christian missionaries in Uganda",
                        "type": "multipleChoice",
                        "answer": [
                            "Both spread a foreign religion"
                        ],
                        "options": [
                            "Both built roads",
                            "Both spread a foreign religion"
                        ],
                        "id": "ple_sst_sectB_or_24_sub_1",
                        "user_answer": "Both built roads"
                    }
                ],
                "id": "ple_sst_sectB_or_24",
                "user_answer": "rteer"
            }
        ],
        "category": 51,
        "$id": "document_46"
    }
]

const math_ple_ans = [
    {
        "category": 1,
        "instruction": null,
        "questions": [
            {
                "question_text": "Work out : 22 x 4",
                "type": "text",
                "answer": "88",
                "id": "ple_math_sect_A1_7",
                "user_answer": "48"
            }
        ]
    },
    {
        "category": 2,
        "instruction": null,
        "questions": [
            {
                "question_text": "Write XCVII IN Hindu Arabic numerals",
                "type": "text",
                "answer": "97",
                "id": "ple_math_sect_A2_1",
                "user_answer": "97"
            }
        ]
    },
    {
        "category": 3,
        "instruction": null,
        "questions": [
            {
                "question_text": "Work out 5/9 ÷ 2/3",
                "type": "multipleChoice",
                "answer": "5/6",
                "explanation": "5/9 x 3/2<br> = 5/3 x 1/2<br> = 5/6",
                "options": [
                    "5/6",
                    "1",
                    "5/9"
                ],
                "id": "ple_math_sect_A3_4",
                "user_answer": "5/6"
            }
        ]
    },
    {
        "category": 4,
        "instruction": null,
        "questions": [
            {
                "question_text": "A teacher counted pupils without school uniform in a class and tallied them as follows: How many pupils were withpout unifom?",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2018/4.png",
                "type": "text",
                "answer": "24 pupils",
                "id": "ple_math_sect_A4_2",
                "user_answer": "25"
            }
        ]
    },
    {
        "category": 5,
        "instruction": null,
        "questions": [
            {
                "question_text": "The clock face shows time in afternoon. What's the time shown in 24-hour clock.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2018/5.png",
                "type": "multipleChoice",
                "answer": "16:50 hours",
                "explanation": "4:50p.m + 12hours<br> = 16:50 or 16 hours 50 minutes",
                "options": [
                    "04:50 hours",
                    "16:50 hours",
                    "12:50 hours"
                ],
                "id": "ple_math_sect_A5_2",
                "user_answer": "16:50 hours"
            }
        ]
    },
    {
        "category": 6,
        "instruction": null,
        "questions": [
            {
                "question_text": "Work out 110<sub>two</sub> x 11<sub>two</sub>",
                "type": "multipleChoice",
                "answer": "10010<sub>two</sub>",
                "options": [
                    "1210<sub>two</sub>",
                    "10010<sub>two</sub>",
                    "1010<sub>two</sub>"
                ],
                "id": "ple_math_sect_A6_4",
                "user_answer": "10010<sub>two</sub>"
            }
        ]
    },
    {
        "category": 7,
        "instruction": null,
        "questions": [
            {
                "question_text": "Find the number which has been expanded below: (3x10<sup>2</sup>) + (5x10<sup>-1</sup>)",
                "type": "multipleChoice",
                "answer": "300.5",
                "explanation": "(3 x 10 x 10) + (5 x 1/10)<br> = 300 + 0.5<br> = 300.5",
                "options": [
                    "30.5",
                    "300.5",
                    "300.2"
                ],
                "id": "ple_math_sect_A7_3",
                "user_answer": "300.5"
            }
        ]
    },
    {
        "category": 8,
        "instruction": null,
        "questions": [
            {
                "question_text": "Simplify 4t - 2k + 5k - t",
                "type": "multipleChoice",
                "answer": "3t + 3k",
                "explanation": "4t - 2k + 5k - t<br> = 4t - t + 5k - 2k<br> = 3t + 3k",
                "options": [
                    "4t + 3k",
                    "3t + 2k",
                    "3t + 3k"
                ],
                "id": "ple_math_sect_A8_6",
                "user_answer": "3t + 3k"
            }
        ]
    },
    {
        "category": 9,
        "instruction": null,
        "questions": [
            {
                "question_text": "Which of the following images show Twenty five minutes to eleven on the clock face",
                "type": "multipleChoice",
                "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/9.png",
                "options": [
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/9_1.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/9_2.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/9.png"
                ],
                "id": "ple_math_sect_A9_3",
                "user_answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/9_1.png"
            }
        ]
    },
    {
        "category": 10,
        "instruction": null,
        "questions": [
            {
                "question_text": "In the diagram below, find the value of b in degrees",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/Symbol 6.png",
                "type": "multipleChoice",
                "answer": "b = 50<sup>o</sup>",
                "explanation": "b + 20 + 110 = 180<br> b + 130 = 180<br> b + 130 - 130 = 180 - 130<br> b = 50",
                "options": [
                    "b = 90<sup>o</sup>",
                    "b = 50<sup>o</sup>",
                    "b = 160<sup>o</sup>"
                ],
                "id": "ple_math_sect_A10_1",
                "user_answer": "b = 50<sup>o</sup>"
            }
        ]
    },
    {
        "category": 11,
        "instruction": null,
        "questions": [
            {
                "question_text": "The area of a square flower garden is 196m <sub>2</sub> . find the length of each side.",
                "type": "multipleChoice",
                "answer": "14 m",
                "explanation": "Area of a square = Side x Side<br> = S<sub>2</sub><br> 196 = S<sub>2</sub><br> √196 = √S<sub>2</sub><br> 14 = S<br> ∴ S = 14",
                "options": [
                    "14 m",
                    "49 m",
                    "98 m"
                ],
                "id": "ple_math_sect_A11_4",
                "user_answer": "49 m"
            }
        ]
    },
    {
        "category": 12,
        "instruction": null,
        "questions": [
            {
                "question_text": "Convert 12½% to fraction in its lowest term",
                "type": "multipleChoice",
                "answer": "= 1/8",
                "explanation": "12½/100<br> convert 12½ to an improper fraction<br> = (25/2)/100<br> = 25/2 ÷ 100<br> = 25/2 ÷ 100/1<br> = 25/2 x 1/100<br> = 1/2 x 1/4<br> = 1/8",
                "options": [
                    "= 1/4",
                    "= 1/2",
                    "= 1/8"
                ],
                "id": "ple_math_sect_A12_4",
                "user_answer": "= 1/2"
            }
        ]
    },
    {
        "category": 13,
        "instruction": null,
        "questions": [
            {
                "question_text": "Change 8<sub>ten</sub> to binary system.",
                "type": "multipleChoice",
                "answer": "1000",
                "explanation": "Divide 8 by 2. The quotient is 4 and the remainder is 0<br> Divide 4 by 2. The quotient is 2 and the remainder is 0<br> Divide 2 by 2. The quotient is 1 and the remainder is 0<br> Write down the remainders in reverse order as 0001<br> Therefore, 8 in binary is 1000",
                "options": [
                    "100",
                    "1000",
                    "10000"
                ],
                "id": "ple_math_sect_A13_1",
                "user_answer": "1000"
            }
        ]
    },
    {
        "category": 14,
        "instruction": null,
        "questions": [
            {
                "question_text": "Write the number whose scientific notation is 9.85 x 10<sup>3</sup>",
                "type": "multipleChoice",
                "answer": "9850",
                "explanation": "9.85 x (10 x 10 x 10)<br> = 9.85 x 1000<br> = 9850",
                "options": [
                    "9850",
                    "985",
                    "98500"
                ],
                "id": "ple_math_sect_A14_6",
                "user_answer": "9850"
            }
        ]
    },
    {
        "category": 15,
        "instruction": null,
        "questions": [
            {
                "question_text": "Find the length of the arc DK in the diagram below. (Use π = 22/7)",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/15.png",
                "type": "multipleChoice",
                "answer": "99 cm",
                "explanation": "From circumference of a circle;<br> C = 2πr<br> Since we finding the length of the arc<br> Length = 2πr/2<br> = πr<br> = 22/7 x 63/2<br> = 99",
                "options": [
                    "99 cm",
                    "126 cm",
                    "31.5 cm"
                ],
                "id": "ple_math_sect_A15_3",
                "user_answer": "126 cm"
            }
        ]
    },
    {
        "category": 16,
        "instruction": null,
        "questions": [
            {
                "question_text": "Find the bearing of point Q from point P in the diagram below.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2014/16.png",
                "type": "multipleChoice",
                "answer": "Bearing = 245<sup>o</sup>",
                "explanation": "180 + (90 - 25)<br> = 180 + 65<br> = 245",
                "options": [
                    "Bearing = 75<sup>o</sup>",
                    "Bearing = 25<sup>o</sup>",
                    "Bearing = 245<sup>o</sup>"
                ],
                "id": "ple_math_sect_A16_6",
                "user_answer": "Bearing = 25<sup>o</sup>"
            }
        ]
    },
    {
        "category": 17,
        "instruction": null,
        "questions": [
            {
                "question_text": "A train left station K at 11 38 hours and reached station M at 14 27 hours. How long did the train take to travel from K to M?",
                "type": "multipleChoice",
                "answer": "2 hours and 49 minutes",
                "explanation": "Time taken = Arrival time - Departure time<br> = 14:27 - 11:38<br> Subtract the minutes first<br> 27 - 38 = -11 (carry over 1)<br> Now, subtract the hours<br> (14 - 1) - 11 = 2",
                "options": [
                    "2 hours and 11 minutes",
                    "1 hours and 49 minutes",
                    "2 hours and 49 minutes"
                ],
                "id": "ple_math_sect_A17_1",
                "user_answer": "1 hours and 49 minutes"
            }
        ]
    },
    {
        "category": 18,
        "instruction": null,
        "questions": [
            {
                "question_text": "Gidudu has goats and sheep in the ratio of 3:2. If he has 24 goats, how many sheep does he have?",
                "type": "multipleChoice",
                "answer": "16 sheep",
                "explanation": "Let the total number of animals be x<br> Total parts = 3+2 = 5<br> 3x/5 = 24<br> Multipy both sides by 5<br> 3x = 24x5<br> x = (24x5)/3<br> x = 40 animals<br> Number of sheep = Total number of animals - Number of goats<br> = 40 - 24<br> = 16 sheep",
                "options": [
                    "16 sheep",
                    "10 sheep",
                    "24 sheep"
                ],
                "id": "ple_math_sect_A18_5",
                "user_answer": "10 sheep"
            }
        ]
    },
    {
        "category": 19,
        "instruction": null,
        "questions": [
            {
                "question_text": "A shopkeeper bought 19 plates at sh 34,200. At what price must the shopkeeper sell each plate in order to raise a profit of sh 3,800?",
                "type": "multipleChoice",
                "answer": "2,000",
                "explanation": "Selling Price per Plate = (Total Cost + Profit)/ Number of plates<br> = (34200+3800)/19<br> = 38000/19<br> = 2000",
                "options": [
                    "1,500",
                    "2,000",
                    "2,500"
                ],
                "id": "ple_math_sect_A19_1",
                "user_answer": "1,500"
            }
        ]
    },
    {
        "category": 20,
        "instruction": null,
        "questions": [
            {
                "question_text": "A man got a loan of sh 120,000 from a savings and credit co-operative society at a simple interest rate of 8% per anunm. He paid an interest of sh7,200 on the loan. How long was the loan?",
                "type": "multipleChoice",
                "answer": "9 months",
                "explanation": "Interest = Principal x Rate x Time<br> 7200 = 120000 x 8/100 x T<br> 7200 = 120000 x 0.08T<br> 7200 = 9600T<br> T = 7200/9600<br> = 3/4<br> Convert to months<br> = 3/4 x 12<br> = 9",
                "options": [
                    "90 months",
                    "9 months",
                    "9 years"
                ],
                "id": "ple_math_sect_A20_6",
                "user_answer": "9 months"
            }
        ]
    },
    {
        "category": 21,
        "instruction": null,
        "questions": [
            {
                "question_text": "In a class of 41 pupils, 30 play football (F), t play Netball (N) and 5 play both football and Netball. 3 pupils do not play any of the two games.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/21.png",
                "sub_questions": [
                    {
                        "question_text": "Which of the following show the complete venn diagram",
                        "type": "multipleChoice",
                        "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/21C.png",
                        "options": [
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/21C.png",
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/21W.png",
                            "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/21W1.png"
                        ],
                        "mark": 2,
                        "id": "ple_math_sect_A21_4_sub_0",
                        "user_answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/21C.png"
                    },
                    {
                        "question_text": "Find the value t",
                        "type": "multipleChoice",
                        "answer": "t = 13",
                        "explanation": "25 + 5 + t - 5 + 3 = 41<br> 25 + 5 - 5 + 3 + t = 41<br> 28 + t = 41<br> 28 - 28 + t = 41 - 28<br> t = 13",
                        "options": [
                            "t = 12",
                            "t = 10",
                            "t = 13"
                        ],
                        "mark": 2,
                        "id": "ple_math_sect_A21_4_sub_1",
                        "user_answer": null
                    }
                ],
                "id": "ple_math_sect_A21_4",
                "user_answer": null
            }
        ]
    },
    {
        "category": 22,
        "instruction": null,
        "questions": [
            {
                "question_text": "Akot went to the market and bought the following items:<br> 3 liters of milk at sh2,400 per liter<br> 250g of salt at sh2,000 per kg.<br> 18 oranges at sh1,500 for every 6 oranges",
                "sub_questions": [
                    {
                        "question_text": "Calculate the total cost of the items",
                        "type": "multipleChoice",
                        "answer": "Total cost = shs 12,200",
                        "explanation": "Cost of milk = 3 x 2400<br> =  shs 7,200<br> Cost of salt;<br> We first convert the 250 grammes to kilogrammes<br> 1 gram = (1/1000) kilogrammes<br> 250 g = (250 x 1/1000) kg<br> = 1/4 kg<br> Cost of salt = 1/4 x 2000<br> =  shs 500<br> Cost of oranges: <br> We first need to find the cost of 1 orange<br> 6 oranges = 1500<br> 1 orange = 1500/6<br> = shs 250<br> 18 oranges = 250 x 18<br> = shs 4,500<br> Total cost = 7,200 + 500 + 4,500<br> = 12,200",
                        "options": [
                            "Total cost = shs 511,700",
                            "Total cost = shs 12,200",
                            "Total cost = shs 27,000"
                        ],
                        "mark": 4,
                        "id": "ple_math_sect_A22_3_sub_0",
                        "user_answer": "Total cost = shs 511,700"
                    },
                    {
                        "question_text": "Akot paid sh 12,000 for the items. What discount was she given?",
                        "type": "multipleChoice",
                        "answer": "Discount = sh 200",
                        "explanation": "Discount = Total cost - Amount Paid<br> = 12,200 - 12,000<br> = 200",
                        "options": [
                            "Discount = sh 499,700",
                            "Discount = sh 200",
                            "Discount = sh 15,000"
                        ],
                        "mark": 1,
                        "id": "ple_math_sect_A22_3_sub_1",
                        "user_answer": "Discount = sh 15,000"
                    }
                ],
                "id": "ple_math_sect_A22_3",
                "user_answer": null
            }
        ]
    },
    {
        "category": 23,
        "instruction": null,
        "questions": [
            {
                "question_text": "Work out: (2.4 x 0.5)/0.12",
                "type": "multipleChoice",
                "answer": "10",
                "explanation": "(2.4 x 0.5)/0.12<br> = (24/10 x 5/10)/12/100<br> = (120/100)/12/100<br> = 120/100 ÷ 12/100<br> = 120/100 x 100/12<br> = 120/12<br> = 10",
                "options": [
                    "10",
                    "20",
                    "30"
                ],
                "mark": 3,
                "sub_questions": [
                    {
                        "question_text": "Simplify: 0.37 - 1.03 + 2.6",
                        "type": "multipleChoice",
                        "answer": "1.94",
                        "explanation": "Using BODMAS<br> = 0.37 + 2.6 - 1.03<br> = 2.97 - 1.03<br> = 1.94",
                        "options": [
                            "2.97",
                            "1.94",
                            "-3.26"
                        ],
                        "mark": 2,
                        "id": "ple_math_sect_A23_1_sub_0",
                        "user_answer": "2.97"
                    }
                ],
                "id": "ple_math_sect_A23_1",
                "user_answer": "20"
            }
        ]
    },
    {
        "category": 24,
        "instruction": null,
        "questions": [
            {
                "question_text": "In the figure below, PQ is parallel to RS and AB is perpendicular to AR. Study the figure and answer the question that follows.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/2019_24.png",
                "sub_questions": [
                    {
                        "question_text": "Find the size of angle c.",
                        "type": "multipleChoice",
                        "answer": "c = 29°",
                        "explanation": "Angle ARS<br> ∠ARS + 90° + 55° = 180°( interior angle sum of a triangle)<br> ∠ARS + 145° = 180°<br> ∠ARS + 145°- 145° = 180° - 145°<br> ∠ARS = 35°<br> c + 35° + 116° = 180° (co-interior angles)<br> c + 151° = 180°<br> c + 151°- 151° = 180°- 151°<br> c = 29°",
                        "options": [
                            "c = 39°",
                            "c = 30°",
                            "c = 29°"
                        ],
                        "id": "ple_math_sect_A24_1_sub_0",
                        "user_answer": "c = 30°"
                    }
                ],
                "id": "ple_math_sect_A24_1",
                "user_answer": null
            }
        ]
    },
    {
        "category": 25,
        "instruction": null,
        "questions": [
            {
                "question_text": "The diagram below represents a container which a shopkeeper uses to sell cooking oil.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2019/Symbol 13.png",
                "sub_questions": [
                    {
                        "question_text": "On a certain day, the shopkeeper sold 15.4 litres of cooking oil. How many such containers of cooking oil were sold that day? (Use π = 22/7)",
                        "type": "multipleChoice",
                        "answer": "20 containers",
                        "explanation": "Get the volume of the container;<br> Volume = π x radius<sup>2</sup> x height<br> = 22/7 x (7/2)<sup>2</sup> x 20<br> = 22/7 x 7/2 x 7/2 x 20<br> = 770 cm<sup>3</sup><br> Convert litres to cm<sup>3</sup><br> 1 litre = 1000 cm<sup>3</sup><br> 15.4 litres = (15.4 x 1000)cm<sup>3</sup><br> = 15400cm<sup>3</sup><br> Number of containers = (Volume of litres sold in cm<sup>3</sup>)/(Volume of the container)<br> = 15400/770 = 20 containers",
                        "options": [
                            "70 containers",
                            "20 containers",
                            "50 containers"
                        ],
                        "mark": 5,
                        "id": "ple_math_sect_A25_1_sub_0",
                        "user_answer": "20 containers"
                    }
                ],
                "id": "ple_math_sect_A25_1",
                "user_answer": null
            }
        ]
    },
    {
        "category": 26,
        "instruction": null,
        "questions": [
            {
                "question_text": "A watch loses 5 seconds every hour. How many minutes will it lose in two days?",
                "type": "multipleChoice",
                "answer": "4 minutes",
                "explanation": "1 day =  24hrs<br> 2days = (24 x 2) hours<br> = 48 hours<br> In 1 hour the clock loses 5 second<br> In 1 day (24hours) it loses 5 x 24<br> =  120 seconds<br>  In 2 day (48hours) it loses  5 x 48<br> = 240 seconds<br> But 60 seconds = 1 minute<br> 240 seconds = 240/60 minutes<br> = 4 minutes",
                "options": [
                    "40 minutes",
                    "14 minutes",
                    "4 minutes"
                ],
                "mark": 2,
                "sub_questions": [
                    {
                        "question_text": "Express 5m/sec in km/hr",
                        "type": "multipleChoice",
                        "answer": "18 km/hr",
                        "explanation": "5m/sec = km/hr<br> 1000m = 1km<br> 5m = 5/1000 km<br> 3600 seconds = 1hr<br> 1 second = 1/3600 hours<br> Speed = Distance ÷ Time<br> = 5/1000 ÷ 1/3600<br> = 5/1000 x 3600/1<br> = 5/10 x 36/1<br> = 36/2<br> = 18km/hr",
                        "options": [
                            "18 km/hr",
                            "18 km/hr",
                            "18 km/hr"
                        ],
                        "mark": 3,
                        "id": "ple_math_sect_A26_6_sub_0",
                        "user_answer": "18 km/hr"
                    }
                ],
                "id": "ple_math_sect_A26_6",
                "user_answer": "14 minutes"
            }
        ]
    },
    {
        "category": 27,
        "instruction": null,
        "questions": [
            {
                "question_text": "Arafat deposited money in a bank which offers a simple interest rate of 2½% per year. After 9 months, his account had an amount of sh 163,000. Calculate the money Arafat deposited in the bank.",
                "type": "multipleChoice",
                "answer": "Amount = 160,000",
                "explanation": "Amount deposited = Principal + Simple interest<br> Simple interest = Principal x Rate x Time<br> 163,000 = P + P x R x T<br> 163,000 = P + P x 2.5/100 x 9/12<br> 163,000 = P(1 + 0.025 x 0.75)<br> 163,000 = 1.01875P<br> P = 160,000",
                "options": [
                    "Amount = 150,000",
                    "Amount = 160,000",
                    "Amount = 166,000"
                ],
                "mark": 5,
                "id": "ple_math_sect_A27_3",
                "user_answer": "Amount = 150,000"
            }
        ]
    },
    {
        "category": 28,
        "instruction": null,
        "questions": [
            {
                "question_text": "The exchange rate of Kenya shillings (Ksh) to Uganda shillings (Ug.sh) and the United states dollars (US$) to Uganda shillings are shown below<br> 1 Ksh = Ug.sh 30<br> US $1 = Ug.shs 2,580",
                "sub_questions": [
                    {
                        "question_text": "How many United States dollars will one get from 21,500 kenya shillings?",
                        "type": "multipleChoice",
                        "answer": "US$ 250",
                        "explanation": "Convert Ksh 21,500 to Ug.sh<br> Ksh 21,500 = (21,500 x 30)<br> = Ug.sh 645,000<br> Convert Ug.sh 645,000 to US$<br> = 645,000/2,580<br> = 64,500/258<br> = US$ 250",
                        "options": [
                            "US$ 2500",
                            "US$ 25",
                            "US$ 250"
                        ],
                        "mark": 3,
                        "id": "ple_math_sect_A28_6_sub_0",
                        "user_answer": "US$ 2500"
                    },
                    {
                        "question_text": "If the cost of a new bicycle is 90 United States dollars, how much would this be in Uganda shillings?",
                        "type": "multipleChoice",
                        "answer": "Ug.sh 232,200",
                        "explanation": "US$ 90 = (90 x 2,580)<br> = Ug.sh 232,200",
                        "options": [
                            "Ug.sh 28.67",
                            "Ug.sh 232,200",
                            "Ug.sh 232,000"
                        ],
                        "mark": 2,
                        "id": "ple_math_sect_A28_6_sub_1",
                        "user_answer": null
                    }
                ],
                "id": "ple_math_sect_A28_6",
                "user_answer": null
            }
        ]
    },
    {
        "category": 29,
        "instruction": null,
        "questions": [
            {
                "question_text": "Joyce, Peter and Hannah shared pencils in the ratio 3:5:7 respectively.",
                "sub_questions": [
                    {
                        "question_text": "If Hannah got 12 more pencils than Joyce, how many pencils did they share altogether?",
                        "type": "multipleChoice",
                        "answer": "45 pencils",
                        "explanation": "Lets use a common multiplier x<br> ∴ The ratios are 3x:5x:7x<br> Since Hannah got 12 more pencils than Joyce<br> 7x = 3x + 12<br> 7x - 3x = 3x - 3x + 12<br> 4x = 12<br> 4x/4 = 12/4<br> x = 3<br> Now that we have the value of x, we can find the number of pencils each person got<br> Joyce: 3x = 3 x 3 = 9 pencils<br> Peter: 5x = 5 x 3 = 15 pencils<br> Hannah: 7x = 7 x 3 = 21 pencils<br> Total number of pencils;<br> = 9 + 15 + 21<br> = 45",
                        "options": [
                            "45 pencils",
                            "40 pencils",
                            "35 pencils"
                        ],
                        "mark": 4,
                        "sub_questions": [
                            {
                                "question_text": "Find the number of pencils Peter got.",
                                "type": "multipleChoice",
                                "answer": "15 pencils",
                                "options": [
                                    "9 pencils",
                                    "15 pencils",
                                    "21 pencils"
                                ],
                                "mark": 2
                            }
                        ],
                        "id": "ple_math_sect_A29_2_sub_0",
                        "user_answer": "45 pencils"
                    }
                ],
                "id": "ple_math_sect_A29_2",
                "user_answer": null
            }
        ]
    },
    {
        "category": 30,
        "instruction": null,
        "questions": [
            {
                "question_text": "In the figure below, BCD is a straight line. Line BX bisects angle ABC. Line AB is parallel to line XC. Angle BCE = 50<sup>oo</sup> and angle BAC = 70<sup>oo</sup>.",
                "image": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2013/30.png",
                "sub_questions": [
                    {
                        "question_text": "Find the size of the angle CEX",
                        "type": "multipleChoice",
                        "answer": "Angle CEX  = 80<sup>o</sup>",
                        "explanation": "Considering triangle ABC<br> a + a + 50 + 70 = 180 (angle sum of a triangle<br> 2a + 120 = 180<br> 2a = 60<br> a = 30<br> Using triangle BAE<br> a + ∠BEA + 70 = 180<br> 30 + ∠BEA + 70 = 180<br> ∠BEA = 80<sup>o</sup><br> But ∠BEA = ∠CEX<br> Therefore ∠CEX = 80<sup>o</sup>)",
                        "options": [
                            "Angle CEX  = 30<sup>o</sup>",
                            "Angle CEX  = 40<sup>o</sup>",
                            "Angle CEX  = 80<sup>o</sup>"
                        ],
                        "mark": 4,
                        "id": "ple_math_sect_A30_7_sub_0",
                        "user_answer": "Angle CEX  = 40<sup>o</sup>"
                    },
                    {
                        "question_text": "Find the size of the angle DCX",
                        "type": "multipleChoice",
                        "answer": "Angle DCX = 60<sup>o</sup>",
                        "explanation": " Angel XCA = angle BAC = 70<sup>o</sup> (alternative angles)<br> Angle BCA + angle ACX + angle DCX = 180<sup>o</sup> (angle sum on a straight line)<br> 50 + 70 + ∠DCX = 180<br> ∠DCX = 60<sup>o</sup>",
                        "options": [
                            "Angle DCX = 30<sup>o</sup>",
                            "Angle DCX = 60<sup>o</sup>",
                            "Angle DCX = 75<sup>o</sup>"
                        ],
                        "mark": 1,
                        "id": "ple_math_sect_A30_7_sub_1",
                        "user_answer": null
                    }
                ],
                "id": "ple_math_sect_A30_7",
                "user_answer": null
            }
        ]
    },
    {
        "category": 31,
        "instruction": null,
        "questions": [
            {
                "question_text": "Given that m = 3k and k = 5, find the value of 2k + 6m",
                "type": "multipleChoice",
                "answer": "100",
                "explanation": "2k + 6m<br> 2 x 5 + 6 x 3k<br> 10 + 6 x 3 x 5<br> 10 + 90<br> = 100",
                "options": [
                    "10",
                    "100",
                    "90"
                ],
                "mark": 3,
                "sub_questions": [
                    {
                        "question_text": "Write the solution set for the inequality: 6 < x < 10",
                        "type": "multipleChoice",
                        "answer": "x = {7, 8, 9}",
                        "explanation": "x are integers between 6 and 10",
                        "options": [
                            "x = {6, 8, 9}",
                            "x = {7, 8, 9}",
                            "x = {7, 8, 10}"
                        ],
                        "mark": 1,
                        "id": "ple_math_sect_A31_4_sub_0",
                        "user_answer": null
                    }
                ],
                "id": "ple_math_sect_A31_4",
                "user_answer": "10"
            }
        ]
    },
    {
        "category": 32,
        "instruction": null,
        "questions": [
            {
                "question_text": "A school library is 70 metres east of the main hall. The staff room is 60 metres from the library on a bearing of 240<sup>o</sup>. Using a scale of 1cm to represent 10 metres, which of the following represent the accurate diagram",
                "type": "multipleChoice",
                "answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/32.png",
                "options": [
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/32.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/32W.png",
                    "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/32W1.png"
                ],
                "mark": 6,
                "id": "ple_math_sect_A32_4",
                "user_answer": "https://v1q1.c10.e2-4.dev/exam-prep/primaryseven/images/math/2016/32W.png"
            }
        ]
    }
]

const eng_ple_ans = [
    {
        "instructions": "In each of the questions 1 to 5, fill in the blank space with a suitable word.",
        "category": 1,
        "numberOfQuestions ": 5,
        "questions": [
            {
                "question": "_____ Opio buys new tyres for his car, he will not be arrested by traffic police officers.",
                "answer": "If",
                "mark": 1,
                "id": "pleenglishcategory1-5_24",
                "type": "text",
                "user_answer": null,
                "sub_questions": []
            },
            {
                "question": "Most people enjoy _____ to radio talk shows.",
                "answer": "listening",
                "mark": 1,
                "id": "pleenglishcategory1-5_12",
                "type": "text",
                "user_answer": null,
                "sub_questions": []
            },
            {
                "question": "That is the journalist to _____ we gave our articles to publish.",
                "answer": "whom",
                "mark": 1,
                "id": "pleenglishcategory1-5_4",
                "type": "text",
                "user_answer": "dhjh",
                "sub_questions": []
            },
            {
                "question": "Lazy peeple _____ eating to working.",
                "answer": "prefer",
                "mark": 1,
                "id": "pleenglishcategory1-5_36",
                "type": "text",
                "user_answer": null,
                "sub_questions": []
            },
            {
                "question": "A lion is _____ fierce than a leopard.",
                "answer": "more",
                "mark": 1,
                "id": "pleenglishcategory1-5_3",
                "type": "text",
                "user_answer": null,
                "sub_questions": []
            }
        ]
    },
    {
        "instructions": "In each of the questions 6 to 15, use the correct form of the word given in the brackets to complete the sentence.",
        "category": 6,
        "numberOfQuestions ": 5,
        "questions": [
            {
                "question": "The triplets opened the door by _____ .(them)",
                "answer": "themselves",
                "mark": 1,
                "id": "pleenglishcategory6-15_92",
                "type": "text",
                "user_answer": null,
                "sub_questions": []
            },
            {
                "question": "My classmates behaved very _____ on our sports day. (good)",
                "answer": "well",
                "mark": 1,
                "id": "pleenglishcategory6-15_28",
                "type": "text",
                "user_answer": null,
                "sub_questions": []
            }
        ]
    },
    {
        "instructions": "In each of the questions 16 to 17, drag and drop the given words in alphabetical order.",
        "category": 16,
        "numberOfQuestions ": 2,
        "questions": [
            {
                "mark": 1,
                "id": "pleenglishcategory16-17_1",
                "type": "dragAndDrop",
                "question": [
                    "near",
                    "far",
                    "after",
                    "before"
                ],
                "answer": [
                    "after",
                    "before",
                    "far",
                    "near"
                ],
                "user_answer": null,
                "sub_questions": []
            }
        ]
    },
    {
        "instructions": "In each of the questions 18 to 20, give one word for the underlined group of words.",
        "category": 18,
        "numberOfQuestions ": 3,
        "questions": [
            {
                "question": "Some of our classmates are <u>from Kenya</u>.",
                "answer": "Kenyan",
                "mark": 1,
                "id": "pleenglishcategory18-20_6",
                "type": "text",
                "user_answer": null,
                "sub_questions": []
            },
            {
                "question": "Most birds make <u>homes of grass </u> in which to lay their eggs.",
                "answer": "nests",
                "mark": 1,
                "id": "pleenglishcategory18-20_14",
                "type": "text",
                "user_answer": null,
                "sub_questions": []
            },
            {
                "question": "The lazy girl was <u> not successful </u> in the final examinations.",
                "answer": "unsuccessful",
                "mark": 1,
                "id": "pleenglishcategory18-20_46",
                "type": "text",
                "user_answer": null,
                "sub_questions": []
            }
        ]
    },
    {
        "instructions": "In each of the questions 21 to 22, choose the sentence where the given word is used correctly.",
        "category": 21,
        "numberOfQuestions ": 2,
        "questions": [
            {
                "question": "brake",
                "options": [
                    "I need to take a brake.",
                    "I need to brake for the stop sign.",
                    "He smashed the window and broke it.",
                    "I'm going to brake the law."
                ],
                "answer": "I need to brake for the stop sign.",
                "mark": 1,
                "id": "pleenglishcategory21-22_23",
                "type": "multipleChoice",
                "user_answer": null,
                "sub_questions": []
            },
            {
                "question": "source",
                "options": [
                    "The library is an important sauce of knowledge.",
                    "We need to find a source to fix the car.",
                    "He likes to source the books at the bookstore.",
                    "The internet is a great source of information."
                ],
                "answer": "The internet is a great source of information.",
                "mark": 1,
                "id": "pleenglishcategory21-22_8",
                "type": "multipleChoice",
                "user_answer": null,
                "sub_questions": []
            }
        ]
    },
    {
        "instructions": "In each of the questions 23 to 24, write the full form of the given abbreviation.",
        "category": 23,
        "numberOfQuestions ": 2,
        "questions": [
            {
                "question": "it's",
                "answer": "it is",
                "mark": 1,
                "id": "pleenglishcategory23-24_1",
                "type": "text",
                "user_answer": null,
                "sub_questions": []
            },
            {
                "question": "shan't",
                "answer": "shall not",
                "mark": 1,
                "id": "pleenglishcategory23-24_17",
                "type": "text",
                "user_answer": null,
                "sub_questions": []
            }
        ]
    },
    {
        "instructions": "In each of the questions 25 to 26, drag and drop the given words to from a sentence.",
        "category": 25,
        "numberOfQuestions ": 2,
        "questions": [
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_36",
                "type": "dragAndDrop",
                "question": [
                    "a",
                    "teacher.",
                    "My",
                    "wife",
                    "is"
                ],
                "answer": [
                    "My",
                    "wife",
                    "is",
                    "a",
                    "teacher."
                ],
                "user_answer": null,
                "sub_questions": []
            },
            {
                "mark": 1,
                "id": "pleenglishcategory25-26_2",
                "type": "dragAndDrop",
                "question": [
                    "doing",
                    "tomorrow",
                    "anything",
                    "Are",
                    "morning",
                    "you?"
                ],
                "answer": [
                    "Are",
                    "you?",
                    "doing",
                    "anything",
                    "tomorrow",
                    "morning"
                ],
                "user_answer": null,
                "sub_questions": []
            }
        ]
    },
    {
        "instructions": "In each of the questions 27 to 28, give the opposite of the underlined word(s) or given word.",
        "category": 27,
        "numberOfQuestions ": 2,
        "questions": [
            {
                "question": "Lydia has gone to the bank to <u>withdraw</u> some money.",
                "answer": "deposit",
                "mark": 1,
                "id": "pleenglishcategory27-28_17",
                "type": "text",
                "user_answer": null,
                "sub_questions": []
            },
            {
                "question": "Brenda walked <u>downstairs</u> to collect her clothes.",
                "answer": "upstairs",
                "mark": 1,
                "id": "pleenglishcategory27-28_3",
                "type": "text",
                "user_answer": null,
                "sub_questions": []
            }
        ]
    },
    {
        "instructions": "In each of the questions 29 to 30, give the plural of the underlined or given word(s).",
        "category": 29,
        "numberOfQuestions ": 2,
        "questions": [
            {
                "question": "Okello bought the new <u>badge</u> for Waya Nursery School uniforms.",
                "answer": "badges",
                "mark": 1,
                "id": "pleenglishcategory29-30_37",
                "type": "text",
                "user_answer": null,
                "sub_questions": []
            },
            {
                "question": "potato",
                "answer": "potatoes",
                "mark": 1,
                "id": "pleenglishcategory29-30_5",
                "type": "text",
                "user_answer": null,
                "sub_questions": []
            }
        ]
    },
    {
        "instructions": "In each of the questions 31 to 50, select the sentence with the correct rewritten option according to the instruction provided in the brackets.",
        "category": 31,
        "numberOfQuestions ": 20,
        "questions": [
            {
                "question": "\"Where has my aunt gone, Mary?\" asked John. (Rewrite the sentence beginning: John asked.......)",
                "options": [
                    "John asked where has my aunt gone, Mary.",
                    "John asked Mary where his aunt had gone.",
                    "John asked Mary where my aunt has gone.",
                    "John asked where Mary has gone, his aunt."
                ],
                "answer": "John asked Mary where his aunt had gone.",
                "mark": 1,
                "id": "pleenglishcategory31-50_5",
                "type": "multipleChoice",
                "user_answer": null,
                "sub_questions": []
            },
            {
                "question": "It is six year since I last travelled to Kampala. (Re-write the sentence  beginning: I last……………)",
                "options": [
                    "I last travelled to Kampala in six years.",
                    "I last travelled to Kampala six years ago."
                ],
                "answer": "I last travelled to Kampala six years ago.",
                "mark": 1,
                "id": "pleenglishcategory31-50_155",
                "type": "multipleChoice",
                "user_answer": null,
                "sub_questions": []
            },
            {
                "question": "The tailor made a suit. The tailor also ironed a suit. (Re-write as on sentence beginning: Besides…………………)",
                "options": [
                    "Besides making a suit, the tailor also made it.",
                    "Besides making a suit, the tailor also ironed it."
                ],
                "answer": "Besides making a suit, the tailor also ironed it.",
                "mark": 1,
                "id": "pleenglishcategory31-50_136",
                "type": "multipleChoice",
                "user_answer": null,
                "sub_questions": []
            },
            {
                "question": "Bbosa prefers posho to sweet potatoes. (Re-write  the sentence using:………………more…………)",
                "options": [
                    "Bbosa likes sweet potatoes more than posho.",
                    "Bbosa likes posho more than sweet potatoes."
                ],
                "answer": "Bbosa likes posho more than sweet potatoes.",
                "mark": 1,
                "id": "pleenglishcategory31-50_166",
                "type": "multipleChoice",
                "user_answer": null,
                "sub_questions": []
            },
            {
                "question": "Who has broken my cup? (Rewrite the sentence using: By whom....)",
                "options": [
                    "By whom has my cup been broken?",
                    "By whom my cup has been broken?",
                    "By whom broken my cup?",
                    "By whom has broken my cup?"
                ],
                "answer": "By whom has my cup been broken?",
                "mark": 1,
                "id": "pleenglishcategory31-50_34",
                "type": "multipleChoice",
                "user_answer": null,
                "sub_questions": []
            },
            {
                "question": "What is the cost of a bull in your area? (Rewrite the sentence beginning: How…)",
                "options": [
                    "How is the cost of a bull in your area?",
                    "How much is the cost of a bull in your area?",
                    "How is the cost of a bull in your area?",
                    "How much a bull cost in your area?"
                ],
                "answer": "How much is the cost of a bull in your area?",
                "mark": 1,
                "id": "pleenglishcategory31-50_35",
                "type": "multipleChoice",
                "user_answer": null,
                "sub_questions": []
            },
            {
                "question": "Both Ichat and Bbosa are clever. (Re-write the sentence using………….as clever…………)",
                "options": [
                    "Ichat is as clever as Manja.",
                    "Ichat is not as clever as Manja."
                ],
                "answer": "Ichat is as clever as Manja.",
                "mark": 1,
                "id": "pleenglishcategory31-50_137",
                "type": "multipleChoice",
                "user_answer": null,
                "sub_questions": []
            },
            {
                "question": "Our neighbor said that she had reported the case to police. (Re-write the sentence ending  “……………” said our neighbours.)",
                "options": [
                    "I reported the case to the police our neighbour said.",
                    "“I reported the case to the police,” said our neighbour."
                ],
                "answer": "“I reported the case to the police,” said our neighbour.",
                "mark": 1,
                "id": "pleenglishcategory31-50_152",
                "type": "multipleChoice",
                "user_answer": null,
                "sub_questions": []
            },
            {
                "question": "The proposer say that water is better than fire. (Rewrite the sentence ending: ……………” the proposers say.)",
                "options": [
                    "“Fire is better than water.” the opposers say.",
                    "“Water is better than fire.” the proposers say.",
                    "“Fire is better than water.” the proposers say."
                ],
                "answer": "“Water is better than fire.” the proposers say.",
                "mark": 1,
                "id": "pleenglishcategory31-50_44",
                "type": "multipleChoice",
                "user_answer": null,
                "sub_questions": []
            },
            {
                "question": "Eliana writes very well. Musa also writes very well. (Rewrite as one sentence using: .....and so .....)",
                "options": [
                    "Eliana writes very well and so Musa does too.",
                    "Eliana writes very well, and so does Musa.",
                    "Eliana writes very well, and so also Musa.",
                    "Eliana writes very well, and so is Musa."
                ],
                "answer": "Eliana writes very well, and so does Musa.",
                "mark": 1,
                "id": "pleenglishcategory31-50_18",
                "type": "multipleChoice",
                "user_answer": null,
                "sub_questions": []
            },
            {
                "question": "The parents liked the drama. (Re-write the sentence using: …………interested………………)",
                "options": [
                    "The parents were not interested in the drama.",
                    "The parents were interested in the drama."
                ],
                "answer": "The parents were interested in the drama.",
                "mark": 1,
                "id": "pleenglishcategory31-50_153",
                "type": "multipleChoice",
                "user_answer": null,
                "sub_questions": []
            },
            {
                "question": "All the children in our class speak English fluently. (Re-write the sentence using: Every……………)",
                "options": [
                    "Every child in my class speak English fluently.",
                    "Every children in my class speak English fluently."
                ],
                "answer": "Every child in my class speak English fluently.",
                "mark": 1,
                "id": "pleenglishcategory31-50_158",
                "type": "multipleChoice",
                "user_answer": null,
                "sub_questions": []
            },
            {
                "question": "Hardly had the rains begun when we planted beans. (Rewrite the sentence beginning: No sooner….)",
                "options": [
                    "No sooner the rains had begun than we planted beans.",
                    "No sooner we planted beans than the rains had begun.",
                    "No sooner had the rains begun than we had planted beans.",
                    "No sooner had the rains begun than we planted beans."
                ],
                "answer": "No sooner had the rains begun than we planted beans.",
                "mark": 1,
                "id": "pleenglishcategory31-50_30",
                "type": "multipleChoice",
                "user_answer": null,
                "sub_questions": []
            },
            {
                "question": "There is almost no water left in bucket. (Re-write the sentence using: ……………hardly…………)",
                "options": [
                    "There is hardly no water left in the bucket.",
                    "There is hardly any water left in the bucket."
                ],
                "answer": "There is hardly any water left in the bucket.",
                "mark": 1,
                "id": "pleenglishcategory31-50_150",
                "type": "multipleChoice",
                "user_answer": null,
                "sub_questions": []
            },
            {
                "question": "That is the health centre. My little brother was born there. (Re-write as one sentence using: ………where……)",
                "options": [
                    "That is the health center where my little brother stays.",
                    "That is the health center where my little brother was born."
                ],
                "answer": "That is the health center where my little brother was born.",
                "mark": 1,
                "id": "pleenglishcategory31-50_144",
                "type": "multipleChoice",
                "user_answer": null,
                "sub_questions": []
            },
            {
                "question": "Girls who leave school for early marriage usually get problems in future. Rewrite the sentence using …………often…………)",
                "options": [
                    "Girls who leave school for early marriage often get problems in future.",
                    "Girls who leave school for early marriage often don't get problems in future."
                ],
                "answer": "Girls who leave school for early marriage often get problems in future.",
                "mark": 1,
                "id": "pleenglishcategory31-50_139",
                "type": "multipleChoice",
                "user_answer": null,
                "sub_questions": []
            },
            {
                "question": "Mukulo is carrying Ariho on a bicycle. (Rewrite the sentence beginning: Ariho.....)",
                "options": [
                    "Ariho is being carried by Mukulo on a bicycle.",
                    "Ariho is riding on a bicycle with Mukulo.",
                    "Ariho and Mukulo are cycling together.",
                    "Ariho is carrying Mukulo on a bicycle."
                ],
                "answer": "Ariho is being carried by Mukulo on a bicycle.",
                "mark": 1,
                "id": "pleenglishcategory31-50_2",
                "type": "multipleChoice",
                "user_answer": null,
                "sub_questions": []
            },
            {
                "question": "Jeroma preferred sweat potatoes to cassava. (Re-write the sentence using: ……more……)",
                "options": [
                    "Jerome liked cassava more than sweet potatoes.",
                    "Jerome liked sweet potatoes more than cassava."
                ],
                "answer": "Jerome liked sweet potatoes more than cassava.",
                "mark": 1,
                "id": "pleenglishcategory31-50_148",
                "type": "multipleChoice",
                "user_answer": null,
                "sub_questions": []
            },
            {
                "question": "Mark is very lazy. Mark cannot finish the work in time. (Re-write the sentence using: …………so…………that…………)",
                "options": [
                    "Mark is so lazy that he cannot finish the work on time.",
                    "Mark is so lazy that he can finish the work on time."
                ],
                "answer": "Mark is so lazy that he cannot finish the work on time.",
                "mark": 1,
                "id": "pleenglishcategory31-50_173",
                "type": "multipleChoice",
                "user_answer": null,
                "sub_questions": []
            },
            {
                "question": "All children hate parents who are quarrelsome. (Rewrite the sentence beginning: Every child....)",
                "options": [
                    "Every child dislikes parents who are quarrelsome.",
                    "Every child loves parents who are quarrelsome.",
                    "Every child avoids parents who are quarrelsome.",
                    "Every child tolerates parents who are quarrelsome."
                ],
                "answer": "Every child dislikes parents who are quarrelsome.",
                "mark": 1,
                "id": "pleenglishcategory31-50_8",
                "type": "multipleChoice",
                "user_answer": null,
                "sub_questions": []
            }
        ]
    },
    {
        "category": 51,
        "numberOfQuestions ": 1,
        "questions": []
    },
    {
        "category": 52,
        "numberOfQuestions ": 1,
        "questions": []
    },
    {
        "category": 53,
        "numberOfQuestions ": 1,
        "questions": []
    },
    {
        "category": 54,
        "numberOfQuestions ": 1,
        "questions": []
    },
    {
        "category": 55,
        "numberOfQuestions ": 1,
        "questions": []
    }
]

export { sst_ple, math_ple, sci_ple, eng_ple, sst_ple_ans, math_ple_ans };