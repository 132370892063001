// src/pages/RegisteredStudents.js
import React from 'react';
import TransactionContainer from '../components/admin/TransactionContainer';

function Transactions() {

    return (
        <div>
            <TransactionContainer />
        </div>
    );
}

export default Transactions;
