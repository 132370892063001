// src/pages/RegisteredStudents.js
import React, { useState, useEffect } from 'react';
import StudentContainer from '../components/admin/StudentContainer';

function RegisteredStudents() {

    return (
        <div>
            <StudentContainer />
        </div>
    );
}

export default RegisteredStudents;
