import React from 'react';
import TransactionDetails from '../components/TransactionDetails.js';

const TransactionDetailsPage = () => {
    return (
        <div>
            <TransactionDetails />
        </div>
    );
};

export default TransactionDetailsPage;
